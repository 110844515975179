import React from 'react'
import { theme,Image } from 'ui'
import Agriculture from 'assets/icons/emptyGroupIcons/agriculture.svg'
import Communication from 'assets/icons/emptyGroupIcons/communication.svg'
import Construction from 'assets/icons/emptyGroupIcons/construction.svg'
import Culture from 'assets/icons/emptyGroupIcons/culture.svg'
import Education from 'assets/icons/emptyGroupIcons/education.svg'
import Finances from 'assets/icons/emptyGroupIcons/finances.svg'
import Food from 'assets/icons/emptyGroupIcons/food.svg'
import Health from 'assets/icons/emptyGroupIcons/health.svg'
import Housing from 'assets/icons/emptyGroupIcons/housing.svg'
import Industry from 'assets/icons/emptyGroupIcons/industry.svg'
import Management from 'assets/icons/emptyGroupIcons/management.svg'
import Religion from 'assets/icons/emptyGroupIcons/religion.svg'
import Security from 'assets/icons/emptyGroupIcons/security.svg'
import Services from 'assets/icons/emptyGroupIcons/services.svg'
import Social from 'assets/icons/emptyGroupIcons/social.svg'
import Sports from 'assets/icons/emptyGroupIcons/sports.svg'
import Tourism from 'assets/icons/emptyGroupIcons/tourism.svg'
import Default from 'assets/icons/emptyGroupIcons/default.svg'
import TotzeretEmptyGroupIcon from 'assets/TotzeretEmptyGroupIcon'

import Melonit from 'assets/melonit.svg'
import { TotzeretColorsByCategory, isTotzeret } from 'service/whiteLable'

export const getEmptyGroupIcon = (name: string) => {
  if (isTotzeret) {
    const colors = TotzeretColorsByCategory[name as 'blue'] || TotzeretColorsByCategory.default;
    return <TotzeretEmptyGroupIcon color={colors.main} backgroundColor={colors.secondary} />
  }
  switch (name) {
    case 'agriculture':
      return  Agriculture
    case 'communication':
      return Communication
    case 'construction':
      return Construction
    case 'culture':
      return Culture
    case 'education':
      return Education
    case 'finances':
      return Finances
    case 'food':
      return Food
    case 'health':
      return Health
    case 'housing':
      return Housing
    case 'industry':
      return Industry
    case 'management':
      return Management
    case 'religion':
      return Religion
    case 'security':
      return Security
    case 'services':
      return Services
    case 'social':
      return Social
    case 'sports':
      return Sports
    case 'tourism':
      return Tourism
    default:
    return Default
  }
  
}
