import React, { FC, useEffect } from 'react'
import styled from 'styled-components'
import { theme, Text, Space, Spinner, Icon, Row } from 'ui'
import useStore from 'store'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Close, ChevronRight } from '@material-ui/icons'
import ProfileIcon from 'assets/icons/profileIcon.svg'
import Switch from '@material-ui/core/Switch'
import { formatIfPhoneNumber } from 'utils/converts'
import { ExitToApp } from '@material-ui/icons'
import type { UserCommunity, UserGroup } from 'utils/types'
import { toJS } from 'mobx'
import { Collapse, IconButton, ListItemIcon } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grow from 'ui/Grow'
import api from 'service/api'
import deepClone from 'lodash/cloneDeep'
import { isTotzeret } from 'service/whiteLable'

const SpinnerContainer = styled.div`
  border-top-width:2px;
  border-top-color:${theme.color.backgroundGray};
  border-top-style:solid;
  `
const StyledCollapse = styled(Collapse)`
  margin-left:-8px;
  margin-right:-8px;
  `
const StyledSpinner = styled(Spinner)`
  margin:16px 32px 32px 32px;
`
const IconBg = styled.div`
  height:60px;
  width:45px;
  background: ${p => isTotzeret ? 'transparent' : p.color};
  display: flex;
  align-items: ${isTotzeret ? 'center;' : 'flex-end;'}
  justify-content: center;
  padding:0.33rem 0;
  ${p => isTotzeret ? `border-left: 2px solid ${p.color}; border-radius: 0px;` : 'border-radius: 8px;'}
  /* margin-left:1rem; */
`

const ImgArrow = styled.div<{ color: string }>`
  border-style: solid;
  border-top-width: 7px;
  border-top-color: transparent;
  border-right-width: 8px;
  border-right-color: ${(p) => p.color};
  border-bottom-width: 7px;
  border-bottom-color: transparent;
  border-left-width: 0;
  border-left-color: transparent;
`
const ClickableRow = styled(Row)`
  cursor:pointer;
`
const Col = styled.div`
  display:flex;
  flex:1;
  flex-direction:column;
`
const GroupItem = styled.div`
  display:flex;
  flex-direction:row;
  border-top-width:2px;
  border-top-color:${theme.color.backgroundGray};
  border-top-style:solid;
  padding:8px;
`
const CommunityCheckboxContainer = styled(Col)`
  align-items:stretch;
  padding-bottom:8px;
`
const StyledExpandMoreIcon = styled(ExpandMoreIcon) <{ isCollapsed: boolean }>`
  transform:rotateZ(${p => p.isCollapsed ? '180deg' : '0deg'});
  transition: transform 0.25s;
`
const StyledListItemIcon = styled(ListItemIcon)`
  align-items:center;
`
const StyledCommunityLineContainer = styled.div`
  &>:not(:first-child){
    margin-top:8px
  }
`
const Slide = styled.div`
  display:flex;
  flex:1;
  flex-direction:column;
  background: ${theme.color.white};
  border:1px solid ${theme.color.black + 33};
`
const HeaderContainer = styled.div`
  display:flex;
  height:150px;
  width:100%;
  background: ${theme.color.grayBackground};
  flex-direction:column;
  justify-content:center;
  align-items:center;
  padding:15px 0;
`
const DetailsContainer = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  margin:10px;
  padding:10px;

  border:0 solid ${theme.color.black + '1A'};
  border-bottom-width:1px;
  /* background: ${theme.color.red}; */

`
const MediaContainer = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:stretch;
  margin:10px;
  padding:10px;

  border:0 solid ${theme.color.black + '1A'};
  border-bottom-width:1px;
  /* background: ${theme.color.red}; */

`
const LogoutContainer = styled.div`
  display:flex;
  flex:1;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  color:${theme.color.red};
  cursor:pointer;
  margin:25px 0 ;
`
const Chevron = styled(ChevronRight)`
  color:${theme.color.black};
  position:absolute;
  top:10px;
  right:10px;
  font-size:30px;
  cursor: pointer;
  /* padding:15px; */
`
const SlideWrapper = styled.div<{ right: boolean }>`
  display:flex;
  position: relative;
  /* right:${p => p.right ? 0 : -420}px; */
 /* min-width:330px; */
   width:${p => p.right ? 350 : 0}px;
   opacity:${p => p.right ? 1 : 0};
  height: 100%;
  background: ${theme.color.whiteBackground};
  transition: 0.5s;
  /* z-index:10; */
  overflow:auto;
`

const StyledHiddenSectionSubTitle = styled(Text)`
  margin-top:8px;
  margin-bottom:8px;
  font-size:16px;
  font-weight:400;
  opacity:0.6;
`

const StyledHiddenSectionTitle = styled(Text)`
  margin-top:8px;
  font-size:16px;
  font-weight:900;
`
const StyledHiddenLineText = styled(Text)`
  font-size:18px;
  font-weight:500;
`

const StyledCommunity = styled.div`
    padding:0 8px;
    display:flex;
    flex:1;
    flex-direction:column;
    border: 1px solid ${theme.color.gray};
    border-radius:8px;
    /* justify-content:flex-start; */
    /* align-items:center; */
`
const DetailsSection = ({ text, secondText }: { text: string, secondText: string }) => {
  return (
    <DetailsContainer>
      <Text
        textcolor={theme.color.black}
        weight={'medium'}
        size={'lg'}
      >{text + ':'}</Text>
      <Space />
      <Text
        textcolor={theme.color.black + '99'}
        weight={'normal'}
        size={'md'}
      >{secondText}</Text>
    </DetailsContainer>
  )
}

const MediaSection = ({ text, list }: { text: string, list: Array<string> }) => {
  return (
    <MediaContainer>
      <Text
        textcolor={theme.color.black}
        weight={'medium'}
        size={'lg'}
      >{text + ':'}</Text>

      {list.map(text => <Text
        // style={{paddingRight:50}}
        key={text}
        textcolor={theme.color.black + '99'}
        weight={'normal'}
        size={'md'}
      >
        {formatIfPhoneNumber(text)}
      </Text>)}

    </MediaContainer>
  )
}

interface ISectionProps {
  orgs: { [key: string]: UserCommunity },
  show_in_alphones_title: string,
  show_in_alphones_sub_title: string,
  show_in_alphone_title: string,
  show_in_alphone_sub_title: string,
}

const HideCommuntiySection: React.FC<ISectionProps> = ({ orgs, show_in_alphones_title, show_in_alphones_sub_title, show_in_alphone_title, show_in_alphone_sub_title, updateUserShowInAlphon }) => {

  const currentOrgs = orgs ? Object.values(orgs) : []
  return (
    <MediaContainer>
      {currentOrgs.length > 1 &&
        <>
          <StyledHiddenSectionTitle>{show_in_alphones_title}</StyledHiddenSectionTitle>
          <StyledHiddenSectionSubTitle>{show_in_alphones_sub_title}</StyledHiddenSectionSubTitle>
          <StyledCommunityLineContainer>
            {currentOrgs.map((org: UserCommunity) => <CommunityLine orgId={org.id} title={org.name} checked={org.is_showInAlphon} key={org.id} />)}
          </StyledCommunityLineContainer>
        </>
      }
      {currentOrgs.length == 1 &&
        <>
          <CommunityLine title={show_in_alphone_title} checked={currentOrgs[0].is_showInAlphon} orgId={currentOrgs[0].id} />
          <StyledHiddenSectionSubTitle>{show_in_alphone_sub_title}</StyledHiddenSectionSubTitle>
        </>
      }
    </MediaContainer>
  )
}
const GroupLine = ({ group }: { group: UserGroup }) => {
  const [isShown, setIsShown] = React.useState(group.is_favourite)
  const { groups } = useStore()
  const handleSwitch = async () => {
    const newValue = !group.is_favourite
    group.is_favourite = newValue
    await groups.hideShowUserGroup(group, newValue)
    // await api.updateGroupUsers([group])
    setIsShown(newValue)
  }
  const { t } = useTranslation('members')
  return (
    <GroupItem>
      <StyledListItemIcon style={{ alignItems: 'center' }}>
        <IconBg color={group.group.description?.iconColor || '#d9d9d9'}><Icon size={30} name={group.group.description?.icon || 'default'} /></IconBg>
        {isTotzeret && <ImgArrow color={group.group.description?.iconColor || '#d9d9d9'}/>}
      </ StyledListItemIcon>
      <Col>
        <b>
          {group.group.name}
        </b>
        {/* <Grow /> */}
        <Row align='center' justify='start'>
          <Switch
            checked={isShown}
            onChange={handleSwitch}
            color='primary'
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <Text>{t(isShown ? 'group_notification_on' : 'group_notification_off')}</Text>
        </Row>
      </Col>
    </GroupItem>
  )
}
const CommunityLine = observer(({ title, orgId }: { title: string, orgId: number }) => {
  const { orgs } = useStore()
  const org = orgs.orgs[orgId]
  const isShownInAlphon = org?.is_showInAlphon
  const isDisabled = orgs.data.filter(el => el.is_enabled).length === 1 && org.is_enabled

  const { t } = useTranslation('members')
  const [communityEnabled, setCommunityEnabled] = React.useState(org.is_enabled)
  const [isCollapsed, setIsCollapsed] = React.useState(false)
  const [communityGroupsLoading, setCommunityGroupsLoading] = React.useState(true)
  const [communityGroups, setCommunityGroups] = React.useState<UserGroup[]>([])

  const toggleShowInAlphon = async () => {
    const newValue = !isShownInAlphon
    await orgs.updateUserShowInAlphon(orgId, newValue)
  }
  const toggleHideCommunity = async () => {
    // await orgs.updateUserShowInAlphon(orgId, !isShownInAlphon)
    await orgs.showHideUserCommunity(orgId, !org.is_enabled)
    setCommunityEnabled(!communityEnabled)
  }
  const getCommunityGroups = async () => {
    //get groups api call
    const groups = await api.getGroups(orgId) || []
    setCommunityGroups(groups)
    setCommunityGroupsLoading(false)
  }
  const toggleCollapse = () => {
    if (!isCollapsed) {
      getCommunityGroups()
    }
    setIsCollapsed(p => !p)
  }
  return (
    <StyledCommunity>
      {/* <Switch
          checked={checked}
          onChange={onChange}
          color='primary'
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
        <StyledHiddenLineText>{title}</StyledHiddenLineText> */}
      <ClickableRow align='center' onClick={toggleCollapse}>
        <StyledHiddenLineText>{title}</StyledHiddenLineText>
        <Grow />
        <IconButton>
          <StyledExpandMoreIcon isCollapsed={isCollapsed} />
        </IconButton>
      </ClickableRow>
      <CommunityCheckboxContainer>
        <Row align='center'>
          <Text>{t(org.is_showInAlphon ? 'community_alphon_visible' : 'community_alphon_hidden')}</Text>
          <Grow />
          <Switch
            checked={org.is_showInAlphon}
            onChange={toggleShowInAlphon}
            color='primary'
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </Row>
        <Row align='center'>
          <Text>{t(org.is_enabled ? 'community_notification_on' : 'community_notification_off')}</Text>
          <Grow />
          <Switch
            checked={org.is_enabled}
            onChange={toggleHideCommunity}
            color='primary'
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            disabled={isDisabled}
          />
        </Row>
      </CommunityCheckboxContainer>
      {/* <Space height={1}/> */}
      <StyledCollapse timeout='auto' in={isCollapsed} >
        {communityGroupsLoading ? (
          <SpinnerContainer>
            <StyledSpinner />
          </SpinnerContainer>
        ) : (
            <>
              {communityGroups.map(el => (
                <GroupLine group={el} />
              ))}
            </>
          )
        }
      </StyledCollapse>
    </StyledCommunity>
  )
})
const ProfilePage: FC = () => {
  const { user, orgs, members } = useStore()
  const { t } = useTranslation('members')
  const onClick = () => {
    user.closeUserProfile()
  }

  useEffect(() => {
    const updatedUser = members.data.get(user.user?.user_id)
    if (updatedUser) {
      const newUserData = { ...user.user, ...updatedUser }
      user.setUser(newUserData)
    }

  }, [members.data])

  const { nick_name, given_name, family_name, emails, phoneNumbers } = user.user
  const fullName = `${given_name} ${nick_name ? '(' + nick_name + ')' : ''} ${family_name}`
  const currentOrgs: { [key: string]: UserCommunity } = toJS(orgs.orgs)


  return (
    <SlideWrapper right={user.userProfileOpen || false}>
      <Slide>
        <HeaderContainer>
          <Chevron onClick={onClick} />
          <img src={ProfileIcon} />
          <Space />
          <Text
            textcolor={theme.color.black}
            weight={'medium'}
            size={'xl'}
          >{fullName}</Text>
        </HeaderContainer>
        <DetailsSection
          text={t`firstName`}
          secondText={given_name}
        />
        <DetailsSection
          text={t`lastName`}
          secondText={family_name}
        />
        {nick_name && <DetailsSection
          text={t`nickName`}
          secondText={nick_name}
        />}
        {emails && emails.length > 0 && <MediaSection
          text={t`email`}
          list={emails}
        />}
        {phoneNumbers && phoneNumbers.length > 0 && <MediaSection
          text={t`phoneNumber`}
          list={phoneNumbers}
        />}
        <div style={{ backgroundColor: theme.color.white }}>
          <HideCommuntiySection
            orgs={currentOrgs}
            show_in_alphones_title={t`show_in_alphones_title`}
            show_in_alphones_sub_title={t`show_in_alphones_sub_title`}
            show_in_alphone_title={t`show_in_alphone_title`}
            show_in_alphone_sub_title={t`show_in_alphone_sub_title`}
          />
          <LogoutContainer
            onClick={() => user.logout()}>
            <ExitToApp />
            <div style={{ width: 10 }} />
            <Text
              textcolor={theme.color.red}
              weight={'medium'}
              size={'xl'}
            >{t`app:logout`}</Text>
          </LogoutContainer>
        </div>
      </Slide>
    </SlideWrapper>)
}


export default observer(ProfilePage)
