import React from 'react'
import { Space, Text, theme } from 'ui'
import useStore from 'store'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ChevronLeft } from '@material-ui/icons'
import { Card } from '@material-ui/core'
import Money from './Money'
import { PosData, BudgetTransaction, PaymentType } from 'store/budget.store'
import { useHistory } from 'react-router'
import { Path } from 'core/Routes'
import { BudgetSumCommunity, BudgetDebitName } from './BudgetTab'

const StyledContainer = styled(Card) <{ disabled: boolean }>`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  cursor:${p => p.disabled ? 'auto' : 'pointer'};
  padding:12px;
  margin:8px;
`
const StyledContainerMoney = styled.div`
  display:flex;
  flex-direction:row;
  padding-bottom:8px;
`
const StyledTitle = styled(Text)`
  line-height:1.3;
  flex:1;
`

const StyledColumn = styled.div`
    display: flex; 
    flex-direction: column;
`
interface IPaymentItem {
    index: number
    item: PosData | BudgetTransaction
    total: number
    transactionsLength: number | undefined
    title: string
    date: string
}

const PaymentItem: React.FC<IPaymentItem> = 
    ({ index, item, total, transactionsLength, title, date }) => {
    const { budgetStore } = useStore()
    const h = useHistory()
    const { t } = useTranslation('budget')
    const moneyColor = total > 0 ? theme.color.budgetDarkGreen : theme.color.budgetRed

    const handlePress = () => {
        if (!transactionsLength) {
            return
        }
        h.push(Path.USER_BUDGET + '/' + index)
    }
    return (
        <StyledContainer disabled={!transactionsLength} onClick={handlePress}>
            <StyledColumn>
                <StyledTitle weight='bold' size='xl'>{title}</StyledTitle>
                <Space width={1} grow />
                <StyledContainerMoney>
                    <Money amount={budgetStore.agorotToShekel(total)}
                        moneySize={32}
                        currencySize={20}
                        moneyColor={moneyColor}
                        agorotColor={moneyColor}
                    />
                </StyledContainerMoney>
                {Boolean(!!transactionsLength) ? (
                    <Text textcolor={theme.color.grey} align='right' size='lg' >
                        {t('summery.purchases', { amount: transactionsLength || 0 })}
                    </Text>
                ) : <BudgetSumCommunity item={item as BudgetTransaction} />
                }
            </StyledColumn>
            <StyledColumn>
                <Text textcolor={theme.color.grey} align='right'>{date}</Text>
                {budgetStore.paymentType === PaymentType.BUDGET ?
                    <BudgetDebitName item={item as BudgetTransaction} />
                    : <div style={{ display: 'flex', flex: 0.5 }}></div>
                }
                {!!transactionsLength &&
                    <div style={{ alignSelf: 'flex-end' }}>
                        <ChevronLeft style={{ color: theme.color.darkerGray, fontSize: 8 * 5 }} />
                    </div>
                }
            </StyledColumn>
        </StyledContainer>
    )
}

export default observer(PaymentItem)