import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton, ListItemIcon, Menu, MenuItem, PopoverOrigin, Slide
} from '@material-ui/core'
import { ExpandMore, Delete, Edit, TextFields, Person, ChatBubble, EditLocationOutlined } from '@material-ui/icons'
import useStore from '../../../store'
import type { Message, UserMessage } from '../../../utils/models'
import { observer } from 'mobx-react'
import React, { FC, forwardRef, ReactElement, Ref, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { extractMessagePayload } from '../../../utils/messageUtils'
import Text from '../../../ui/Text/Text'
import { theme } from '../../../ui'
import type { TransitionProps } from '@material-ui/core/transitions'
import { format, isDate, parseISO } from 'date-fns'
import { formatDateTime } from 'utils/date'
import { canEditMessage, canDeleteMessage, canSeeMessageDetails, canSeeAuthoerDetails, userIsSuperAdmin, userIsDirectorOrSuperAdmin } from 'utils/roles'

interface MessageItemActionsProps {
  userMessage: UserMessage
}


const ConfirmButton = styled(Button)`
  color: ${theme.color.white};
  background-color: ${theme.color.dangerRedLight};
  margin-left: 1rem;
  body[dir=rtl] & {
    margin-left: unset;
    margin-right: 1rem;
  }
  &:hover {
    background-color: ${theme.color.dangerRed}
  }
`

const ActionsMenu = styled(Menu)`
  .MuiList-padding {
    padding: 0;
  }
`

const ActionItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    padding: 0.8rem;
}
`
const ActionDate = styled.div`
  background-color:${theme.color.grayBackground};
    padding: 0.8rem;
    

`
const EditIcon = styled(Edit)`
  color: ${theme.color.iconButton};
`
const DeleteIcon = styled(Delete)`
  color: ${theme.color.iconButton};
`
const TextFieldsIcon = styled(TextFields)`
  color: ${theme.color.iconButton};
`
const EditLocationOutlinedIcon = styled(EditLocationOutlined)`
  color: ${theme.color.iconButton};
`
const PersonIcon = styled(Person)`
  color: ${theme.color.iconButton};
`
const ChatBubbleIcon = styled(ChatBubble)`
  color: ${theme.color.iconButton};
`
const ActionsArrowButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    opacity: 0.8;
    right: 0;
    body[dir=rtl] & {
      right: unset;
      left: 0;
    }
  }`

const ActionText = styled(Text)`
  color: ${theme.color.iconButton};
  margin-left: 1rem;
  body[dir=rtl] & {
    margin-left: unset;
    margin-right: 1rem;
  }
`

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children?: ReactElement<any, any>
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})


const MessageItemActions: FC<MessageItemActionsProps> = ({ userMessage }) => {
  const { t } = useTranslation('messageActions')
  const { messages, members, newMessage, memberStore, modulesStore, mapStore } = useStore()
  const message = userMessage.message
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const extractedPayload = extractMessagePayload(message)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const canEdit = canEditMessage(userMessage)
  const canDelete = canDeleteMessage(userMessage)
  const userCannSeeMessageDetails = canSeeMessageDetails(userMessage)
  const userCanSeeAuthoerDetails = canSeeAuthoerDetails()

  const onEditActionClick = () => {
    newMessage.setCurrentUserMessage(message.id)
    messages.openNewMessageDialog()
    handleClose()
  }
  const onDeleteActionClick = () => {
    setConfirmDeleteOpen(true)
    handleClose()
  }
  const onMessageDetailsClick = () => {
    messages.openMessageDetails(message.id)
    handleClose()
  }

  const onLocationRequestDetailsClick = () => {
    mapStore.openMessageLocationRequestDetailModal(userMessage)
    handleClose()
  }

  const onUserDetailsClick = () => {
    messages.openUserDetailsModal(userMessage.message.senderId)
    handleClose()
  }
  const onCopyActionClick = () => {
    const survey = extractedPayload?.survey
    const initText = extractedPayload?.text

    if (message?.msg_strategy?.startsWith("QS") && survey) {
      const surveyAnswers = survey.answers.map((item: { text: string }, index: number) => `\n  ${index + 1 + '.'} ${item.text} `)
      navigator.clipboard.writeText(`${survey.question}\n${surveyAnswers.join('')}`)
    } else {
      navigator.clipboard.writeText(initText || '')
    }
    handleClose()

  }

  const closeConfirmDelete = () => setConfirmDeleteOpen(false)

  const onConirmDelete = () => {
    messages.remove(message.id)
    closeConfirmDelete()
  }
  return (
    <>
      <ActionsArrowButton onClick={handleClick} size='small'>
        <ExpandMore />
      </ActionsArrowButton>
      <ActionsMenu
        id="message-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ActionDate>
          <ActionText>{`${t`valid`} ${t`until`} ${formatDateTime(new Date(message.expiry), t)}`}</ActionText>
        </ActionDate>
        {canEdit &&
          <ActionItem onClick={onEditActionClick}>
            <EditIcon />
            <ActionText>{t`editMessage`}</ActionText>
          </ActionItem>}
        {canEdit && canDelete && <Divider />}
        {canDelete && <ActionItem onClick={onDeleteActionClick}>
          <DeleteIcon />
          <ActionText>{t`deleteMessage`}</ActionText>
        </ActionItem>}
        <Divider />
        <ActionItem onClick={onCopyActionClick}>
          <TextFieldsIcon />
          <ActionText>{t`copy`}</ActionText>
        </ActionItem>
        <Divider />
        {userCannSeeMessageDetails && <ActionItem onClick={onMessageDetailsClick}>
          <ChatBubbleIcon />
          <ActionText>{t`message_details`}</ActionText>
        </ActionItem>}
        <Divider />
        {(message.locationRequest && modulesStore.isEnabledLocationFeature && userIsDirectorOrSuperAdmin()) && 
          <ActionItem onClick={onLocationRequestDetailsClick}>
            <EditLocationOutlinedIcon />
            <ActionText>{t`locationRequest`}</ActionText>
          </ActionItem>}
        <Divider />
        {userCanSeeAuthoerDetails && <ActionItem onClick={onUserDetailsClick}>
          <PersonIcon />
          <ActionText>{t`author_details`}</ActionText>
        </ActionItem>}
      </ActionsMenu>
      <Dialog
        open={confirmDeleteOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
        aria-labelledby="alert-dialog-delete-message-title"
        aria-describedby="alert-dialog-delete-message-description"
      >
        <DialogTitle id="alert-dialog-delete-message-title">
          {t`confirmDeleteTitle`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-delete-message-description">
            {t`confirmDeleteBody`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDelete}>{t`cancel`}</Button>
          <ConfirmButton onClick={onConirmDelete}>{t`ok`}</ConfirmButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default observer(MessageItemActions)
