//import screen from 'utils/screen'

import { ID, StrategyType, StatusType, Group, UserGroup } from 'utils/types'

export enum SyncType {
  MyData = 'MyData',
  OthersData = 'OthersData',
  GroupData = 'MyGroupData',
  MessageResponse = 'MessageResponse'
}

export enum uiErrorType {
  israeliIDError = 'israeliIDError',
  emailError = 'emailError',
  familyNameError = 'familyNameError',
  nickNameError = 'nickNameError',
  giveanNameError = 'giveanNameError',
  phone1Error = 'phone1Error',
  phone2Error = 'phone2Error'
}

export interface serverErrorType {
  key: errorType,
  description: string,
  content: { value: string, key: errorType },
  userId?: number
}

export enum errorType {
  InvalidIsraeliID = 'InvalidIsraeliID',
  InvalidEmailAddress = 'InvalidEmailAddress',
  EmailAlreadyExists = 'EmailAlreadyExists',
  EmptyPushTokens = 'EmptyPushTokens',
  FamilyNameIsMandatory = 'FamilyNameIsMandatory',
  NickNameIsMandatory = 'NickNameIsMandatory',
  NameIsMandatory = 'NameIsMandatory',
  InvalidPhoneNumber = 'InvalidPhoneNumber',
  PhoneNumberAlreadyExists = 'PhoneNumberAlreadyExists'
}

export interface CsvUser {
  user: User
  selected: boolean
  errors: Array<serverErrorType>
  status: CsvUserStatus
  addedToGroup: { status: boolean, description: string }
  id: number
}

export enum CsvUserStatus {
  New = 'New',
  Created = 'Created',
  Duplicated = 'Duplicated',
  Existing = 'Existing',
}


export enum AssociatedGroupsLabel {
  security = 'בטחון',
  culture = 'תרבות',
  general = 'כללי',
  remove = 'ללא',
}
export enum GroupType {
  REGULAR = 'regular',
  SUPPORT = 'support',
}
export enum ForchUpdateType {
  NO = 'NO',
  OPTIONAL = 'OPTIONAL',
  FORCE = 'FORCE'
}

export enum Role {
  member = 'member',
  organizer = 'organizer',
  operator = 'operator',
  director = 'director',
  sender = 'sender',
}

export enum AppType {
  ANDROID = 'android',
  IOS = 'ios',
  NONE = 'none'
}
// export interface ExtendedUser extends User {
//   appSettings: {
//     isNew: boolean,
//     showPopups: boolean,
//     currentOrgId: number,
//     name: string,
//     authStep: screen,
//     isAuth: boolean,
//     loading: boolean,
//     isUserFromOldApp?: boolean,
//     appVersion: string | null | undefined,
//   }
// }

export interface NextVersion {
  versionNumber: string,
  forchUpdateType?: ForchUpdateType,
  askedOnceUser?: boolean
}

export interface Org {
  id: number,
  name: string
}


export interface Auth {
  phone?: string
  pinNumber?: string
}

export interface UserCommunity {
  community: Community
  director: number,
  operator: number,
  sms: number,
  hide: number,
  groups?: UserGroup[]
}


export interface LastMessage {
  lastMessage: UserMessage
  unread: number
}
export interface UserMessage {
  user: number
  message: Message
  status: StatusType
  response: number
}
export interface Message {
  author_name: string
  id: number
  create_guid: string
  created: Date
  expiry?: Date | undefined
  schedule?: Date | undefined
  received?: Date | undefined
  group: number
  payload: string
  senderName: string
  senderId: number
  updated: Date
  msg_strategy: StrategyType
  file?: File,
  survey?: any
  locationRequest?: Boolean
}

export interface SurveyAnswer {
  id: string
  text: string
  color: number
}

export interface PayloadRequest {
  files: any,
  payload: any,
  type: any,
  blob: any
}

export interface MessageDistribution {
  total: number
  push?: { total?: number, muted?: number, outgoing?: number, sent?: number, failed?: number, received?: number, read?: number, responded?: number, responses?: Response[] }
  application?: { total: number, sent?: number, read: number }
  email?: { total: number, outgoing?: number, sent?: number, failed?: number, received?: number }
  sms?: { total: number, outgoing?: number, sent?: number, failed?: number, received?: number }
}

export interface Response {
  index: number
  count: number
}

export interface MessageSummary {
  created: Date | null
  distribution: MessageDistribution
  message: number
  updated: Date | null
  valid: number
}
// export interface MessageResponse {
//   user: number
//   userName: string
//   response: number
//   deliveryType: number
// }
export interface MessageResponse {
  created: string
  userMessageId: number
  message_ref: number
  status: number
  updated: string
  version: number
  delivery_type: number
  received: string
  response: number
  user_ref: number
}

export interface Community {
  community: number
  name: string
}
export interface ServerResponseUser {
  emails: string[]
  family_name: string
  given_name: string
  nick_name: string
  phones: string[]
  userID: number

}
export interface User {
  app?: AppType,
  communities?: Community[]
  device_details: string
  displayName: string
  emails: string[]
  family_name: string
  fullName: string,
  israeliID: string,
  given_name: string
  is_community_enabled: boolean
  is_director: boolean
  is_operator: boolean
  is_showInAlphon: boolean
  nick_name: string
  phones: string[]
  user_token?: string
  updated?: string
  user_id: number
  version?: number
  guid?: string
  eulaSigned?: boolean
  sendSMS: boolean
  sendEmail: boolean
  role: Role
}

export interface SupportCallGroup {
  parentGroup: Group,
  groups: UserGroup[],
  community: number
}


export interface CommunityInfo {
  community: number,
  unreadGroup: number,
  unreadSupport: number
  groups: GroupInfo[],
  supports: GroupInfo[]
}
export interface GroupInfo {
  group: number,
  last_message: Message,
  unread: number
}

export interface CommunityUnreadCount {
  community: number,
  newMessageCount: number,
  newSupportCount: number
}


export interface GroupCategory {
  icon: string
  color: string
  iconColor: string
  category: string
  subCategory: string
  subCategoryKey: string
  fabColor: string
}

export interface PodUpdatePush {
  type: number,
  user: number,
  server: string,
  device: string,
  token: string,
  details: string,
  valid: boolean,
  app_version: string,
  op: string//always UpdatePush
}

export enum PushType {
  sms = 1,
  email = 2,
  notification = 3,
  silent = 4
}

export enum Role {
  ADMIN = "Admin",
  REGULAR = "Regular"
}

