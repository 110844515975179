import { Component } from 'react';
import { withSnackbar } from 'notistack';
import { observer } from 'mobx-react';
import { autorun, } from 'mobx';
import useStore from 'store';


class Notifier extends Component {
    displayed = [];

    storeDisplayed = (id) => {
        this.displayed = [...this.displayed, id];
    };

    componentDidMount() {
        autorun(() => {
            const { notifications = [], latestNotification } = this.props.store;
            if (latestNotification) {
                this.props.enqueueSnackbar(latestNotification.message, latestNotification.options);
            }
        });
        autorun(() => {
            const { latestRemove } = this.props.store;
            if (latestRemove) {
                this.props.closeSnackbar(latestRemove);
            }
        })
    }

    render() {
        return null;
    }
}
const NotifierComponent = observer(Notifier)
const Wrap = observer((props) => {
    const { snackBar } = useStore()
    // const { enqueueSnackbar, removeSnackbar } = snackBar
    return <NotifierComponent
        store={snackBar}
        {...props}
    />
})
export default withSnackbar((Wrap));
