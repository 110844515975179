import React, { FC } from 'react'
import styled from 'styled-components'
import { Button, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Close, Send, LiveHelp } from '@material-ui/icons'
import { theme, Text, Row, Space } from 'ui'
import { observer } from 'mobx-react'
import useStore from 'store'
import Switch from '@material-ui/core/Switch'
import { isValidNumber } from 'libphonenumber-js'
import { validId } from 'utils/validations'
import { errorsType } from 'store/budget.store'

const SwitchComponent = styled(Switch)`

.MuiSwitch-colorPrimary{
    color:${theme.color.budgetPurple};
}
.MuiSwitch-track{
    background-color:${theme.color.budgetPink};
}
`
const Content = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:space-around;
  width:80%;
  height:70%;
  margin-top:10px;
`
const StyledSend = styled(Send)`
  color:white;
  transform: rotate(180deg);
`
const StyledButton = styled(Button)`
  height:50px;
  width:100%;
  background-color: ${theme.color.cerulean};
  border-radius: 5px;
  color:white;
  :hover {background-color: ${theme.color.cerulean};}
`
const StyledTextField = styled(TextField)`
    width:100%;
    margin:10px;
    direction:rtl;
    @media (max-width: 768px) {
      width: unset;
    }
`


const PhoneStep: FC = ({ }) => {
  const { budgetStore } = useStore()
  const { t } = useTranslation('budget')
  const canSend = () => {
    if (isValidNumber(budgetStore.phoneNumber, 'IL') && validId(Number(budgetStore.idNumber))) {
      return false
    }
    return false
  }

  const setNumber = (value: string) => {
    if (budgetStore.errors.get(errorsType.PHONE)) {
      budgetStore.errors.delete(errorsType.PHONE)
    }
    budgetStore.setPhoneNumber(value)
  }
  const setId = (value: string) => {
    if (budgetStore.errors.get(errorsType.ID_NUMBER)) {
      budgetStore.errors.delete(errorsType.ID_NUMBER)
    }
    budgetStore.setIdNumber(value)
  }
  const handleSend = () => {
    budgetStore.login()
  }
  const handleRememberMeClick = () => {
    budgetStore.toggleRememberMe()
  }
  const onEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSend()
    }
  }
  return (

    <Content>
      <StyledTextField
        value={budgetStore.phoneNumber}
        error={budgetStore.errors.get(errorsType.PHONE)}
        id='standard-helperText1'
        label={t`phoneNumber`}
        variant='outlined'
        onChange={(event) => setNumber(event.target.value)}
        onKeyUp={onEnter}
        helperText={budgetStore.errors.get(errorsType.PHONE)}
        InputProps={{
          endAdornment: budgetStore.phoneNumber != '' ?
            <Close
              style={{ color: theme.color.black + 99 }}
              onClick={() => budgetStore.setPhoneNumber('')}
            /> : <div />
        }}
      />
      <StyledTextField
        value={budgetStore.idNumber}
        error={budgetStore.errors.get(errorsType.ID_NUMBER)}
        id='standard-helperText1'
        label={t`idNumber`}
        variant='outlined'
        onChange={(event) => setId(event.target.value)}
        onKeyUp={onEnter}
        helperText={budgetStore.errors.get(errorsType.ID_NUMBER)}
        InputProps={{
          endAdornment: budgetStore.idNumber != '' ?
            <Close
              style={{ color: theme.color.black + 99 }}
              onClick={() => budgetStore.setIdNumber('')}
            /> : <div />
        }}
      />
      <Text
        align={'center'}
        textcolor={theme.color.black + 66}
        weight={'medium'}
        size={'sm'}
      >{t`BudgetCode`}</Text>
      <div>
        <StyledButton
          disabled={canSend()}
          type="submit"
          onClick={handleSend}
          variant='contained'>
          <Text
            size={'md'}
            textAlign={'center'}
            textcolor={'white'}
            weight='normal'>
            {t`app:send`}
          </Text>
          <Space width={0.7} />
          <StyledSend />
        </StyledButton>
        <Space height={1} />

        <Row>
          <LiveHelp style={{ color: theme.color.budgetPink }} />
          <Row>
            <Text
              textcolor={theme.color.black + 66}
              weight={'medium'}
              size={'md'}
            >{t`RememberMeBudget`}</Text>
            <SwitchComponent
              checked={budgetStore.rememberMe}
              onChange={handleRememberMeClick}
              color='primary'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Row>
        </Row>
      </div>
    </Content>
  )
}

export default observer(PhoneStep)


