import React, { FC, useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { formatIfPhoneNumber } from 'utils/converts'
import EmailIcon from 'assets/icons/contactCardEmail.svg'
import PhoneIcon from 'assets/icons/contactCardPhone.svg'
import LogoIcon from 'assets/icons/contactCardLogo.svg'
import PersonIcon from 'assets/icons/contactCardPerson.svg'
import ArrowIcon from 'assets/icons/contactCardArrow.svg'
import Dialog from 'components/Dialog'
import { theme, Text, Space, Button, Image } from 'ui'
import type { User } from 'utils/models'
import useStore from 'store'
import { useTranslation } from 'react-i18next'
import { memberErrorsType } from 'store/member.store'

const DuplicatedContainer = styled.div`
  background-color:${theme.color.CommunityYellow};
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items:center;
`
const StyledDuplicatedRowText = styled(Text)`
  font-size:${theme.fontSize.normal};
  font-weight:500;
  text-align:center;
  margin-right:16px;
`
const StyledDuplicatedTitle = styled(Text)`
  font-size:${theme.fontSize.dialogHeader};
  font-weight:500;
  text-align:center;
`

const DuplictedRowContainer = styled.div<{ isSelected: boolean }>`
  height:56px;
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  align-items:center;
  background-color:${p => p.isSelected ? theme.color.CommunityYellow : theme.color.white};
  padding:0px 8px 0px 17px;
  margin:0px 10px 5px 10px;
  border-radius:10px;
`
const DuplictedRowContainerRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items:center;
`
const StyledDuplicatedSubTitle = styled(Text)`
  font-size:${theme.fontSize.accordionBody};
  font-weight:500;
  text-align:center;
  margin-right:22px;
  margin-left:22px;
`

interface DialogRowProps { title: string, icon?: string, isSelected: boolean, isPhoneNumber: boolean }

const DialogRow: FC<DialogRowProps> = ({ title, isSelected, isPhoneNumber, icon = undefined }) => {
  return (
    <DuplictedRowContainer isSelected={isSelected}>
      <DuplictedRowContainerRight>
        {icon && <Image src={icon} />}
        <StyledDuplicatedRowText>
          {isPhoneNumber ? formatIfPhoneNumber(title) : title}
        </StyledDuplicatedRowText>
      </DuplictedRowContainerRight>
      {isSelected && <Image src={ArrowIcon} />}
    </DuplictedRowContainer>

  )
}

const getDisplayName = (user: User) => {
  const firstName = !user.given_name ? user.nick_name : (`${user.given_name} ${user.nick_name ? ('(' + user.nick_name + ')') : ''}`)
  return `${firstName} ${user.family_name} `
}

const DuplicatedDialog: FC = () => {
  const { memberStore } = useStore()
  const { t } = useTranslation('members')
  const duplicatedUser = memberStore.duplicatedUser
  const displayName = duplicatedUser ? getDisplayName(duplicatedUser) : ''
  return (
    <Dialog
      removeCancelButton={true}
      customFunction={() => memberStore.closeDuplicatedModal()}
      customButtonText={t`back_to_edit`}
      withFooterButtons={true}
      customOkButtonText={t`use_existing_card`}
      customBackground={theme.color.white}
      dialogSize="auto"
      open={memberStore.duplicatedModalState}
      keepMounted
      onClose={() => memberStore.closeDuplicatedModal()}
      onOk={() => memberStore.importDuplicatedUser()}
      aria-labelledby="alert-dialog-delete-message-title"
      aria-describedby="alert-dialog-delete-message-description"
      style={{ zIndex: 2000 }}
      customHeaderBackground={theme.color.CommunityYellow}
    >
      {
        duplicatedUser != null && (

          <>
            <DuplicatedContainer>
              <Image src={LogoIcon} />
              <Space />
              <StyledDuplicatedTitle>
                {t`found_existing_user`}
              </StyledDuplicatedTitle>
              <Space />
              <StyledDuplicatedSubTitle>
                {t`found_existing_user_deatils`}
              </StyledDuplicatedSubTitle>
              <Space />
            </DuplicatedContainer>
            <Space />
            <DialogRow title={displayName} icon={PersonIcon} isSelected={false} isPhoneNumber={false} />
            {
              duplicatedUser.phones &&
              duplicatedUser.phones.map((phone: string, index: number) => {
                const setSelected = (Boolean(memberStore.errors.get(memberErrorsType.phoneNumber1)) && index === 0) ||
                  (Boolean(memberStore.errors.get(memberErrorsType.phoneNumber2)) && index === 1) ||
                  ((Boolean(memberStore.errors.get(memberErrorsType.phoneNumber1) || Boolean(memberStore.errors.get(memberErrorsType.phoneNumber2)))) && duplicatedUser.phones.length === 1)
                return (
                  <DialogRow
                    isPhoneNumber={true}
                    title={phone}
                    icon={PhoneIcon}
                    isSelected={setSelected}
                  />
                )
              })
            }
            {
              duplicatedUser.emails &&
              duplicatedUser.emails.map((email: string, index: number) =>
                <DialogRow
                  title={email}
                  icon={EmailIcon}
                  isSelected={Boolean(memberStore.errors.get(memberErrorsType.email))}
                />)
            }
          </>
        )
      }
    </Dialog>
  )
}

export default observer(DuplicatedDialog)

