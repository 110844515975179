import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { theme, Text } from 'ui'
import useStore from 'store'
import { useTranslation } from 'react-i18next'
import { User } from 'utils/models'
import { UserGroup } from 'utils/types'
interface Props {
  isContacts?: boolean
  textColor?: string
  disabledClick?: boolean
  textSize?: string,
  hide?: boolean
}




const MembersCounter: FC<Props> = ({ isContacts, textColor, textSize, disabledClick, hide = false }) => {

  const { members, groups } = useStore()
  const { t } = useTranslation('members')
  const orgManagersCount = members.orgMembers.filter((member: User) => {
    return member.is_operator || member.is_director
  }).length
  const groupManagersCount = groups.groupMembers.filter((member: UserGroup) => {
    return member.is_organizer || member.is_organizer
  }).length
  const groupMemberCount = (groups.currentGroup?.group?.parent_id && !groups.currentGroup.is_organizer) ? (groupManagersCount + 1) : groups.groupMembers.length
  return (
    <span onClick={disabledClick ? () => { } : () => groups.openMembersModal()} style={{ textDecoration: disabledClick ? 'none' : 'underline', color: 'white', cursor: disabledClick ? 'default' : 'pointer', flex: 1 }}>
      {isContacts && members.orgMembers.length != 0 && orgManagersCount != 0 && <Text
        textcolor={textColor || theme.color.whiteBackground}
        size={textSize ? textSize : 'sm'}
        weight='normal'
      >
        {!hide && <span>
          {',\xa0'}
        </span>}
        {`${members.orgMembers.length} ${t`members`}, ${orgManagersCount} ${t`communityManagers`}`}</Text>}
      {!isContacts && /*groupMembers?.length != 0 && groupManagersCount != 0 &&*/ <Text
        textcolor={textColor || theme.color.whiteBackground}
        size={textSize ? textSize : 'sm'}
        weight='normal'
        style={{ display: 'flex', flex: 1 }}
      >
        {!hide && <span>
          {',\xa0'}
        </span>}
        {(groups.groupMembers?.length != 0) && `${groupMemberCount} ${t`members`}` + (groupManagersCount != 0 ? ', ' : '')}{groupManagersCount != 0 && `${groupManagersCount} ${t`groupManagers`}`}</Text>}
    </span>

  )
}

export default observer(MembersCounter)
