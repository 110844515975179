import React from 'react'
import styled from 'styled-components'
import { theme,Text } from 'ui'

// const Container = styled.div`
// `

// const RedCounter = styled.div`
//   border:1px solid ${theme.color.black + 33} ;
//   border-radius:12px;
//   height:20px;
//   width:20px;
//   background-color:${theme.color.dangerRed};
//   display:flex;
//   align-items:center;
//   justify-content:center;
// `
const CustomBadge = styled.span`
  display:flex;
  background-color:${theme.color.dangerRed};
  border-radius:10px;
  height:20px;
  width:20px;
  color:white;
  font-size:0.75rem;
  align-items:center;
  justify-content:center;
  padding:0 6px;
  line-height:0;
  box-shadow: 0px 1px 1px 1px ${theme.color.shadow};
`

export default function NotificationCounter({ number }: { number: number }) {
  if (!number) {
    return null
  }
  return (
    <CustomBadge>{number}</CustomBadge>
  )
}
