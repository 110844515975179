import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { theme, Text, Row, Space, Icon as UiIcon } from 'ui'
import type { ModuleType } from 'utils/types'
import { ExpandMore } from '@material-ui/icons'
import { Menu as MuiMenu, MenuItem, } from '@material-ui/core'
import useStore from 'store'
import { useTranslation } from 'react-i18next'
import { canEditCommunityModules } from 'utils/roles'

const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}
const IconBg = styled.div<{ bgColor: string }>`
    display:flex;
    flex:1;
    background-color:${p => p.bgColor};
    align-items:center;
    justify-content:center;
    border-top-left-radius:12px;
    border-top-right-radius:12px;

`
const Container = styled.div`
    flex-direction:row;
    display:flex;
    width:100%;
    max-width:1300px;
    align-self:center;
    flex:1;
    flex-wrap:wrap;
    word-break:break-word;
    justify-content: space-evenly;
    `
const TextContainer = styled.div`
      align-items:center;
    justify-content:center;
    display:flex;
    text-align:center;
    flex:0.6;


    `
const ItemText = styled(Text)`
    padding:5px;
    `
const ItemContainer = styled.div`
    background-color:${theme.color.white};
    display:flex;
    flex-direction:column;
    width:150px;
    height:170px;
    border-radius:12px;
    box-shadow: 0 2px 8px 0 ${theme.color.shadow};
    margin:10px;
    `
const Contant = styled.div<{ hasUrl: boolean }>`
    background-color:${theme.color.white};
    display:flex;
    flex-direction:column;
    flex:1;
    border-radius:12px;
    cursor:${p => p.hasUrl ? 'pointer' : 'not-allowed'};
    `
const SideComponentMenu = styled.div`
    justify-content:center;
    align-items:center;
    display:flex;
    z-index:2000px;
    position:absolute;
    border-radius:12px;
    left:0;
    width:40px;
    height:40px;
    background-color:${theme.color.white};
    cursor:pointer;
`

const ListItem = ({ item }: { item: ModuleType }) => {
  const { t } = useTranslation(['budget'])

  const [showActionArrow, setShowActionArrow] = useState(false)
  const onEnter = () => setShowActionArrow(true)
  const onLeave = () => setShowActionArrow(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const { modulesStore } = useStore()

  const openActionMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const openModuleModal = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    // event.preventDefault()
    handleClose()
    modulesStore.openModuleModalEditMode(item.module_id)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const userCanEditCommunityModules = canEditCommunityModules()
  const handleClick = (url: string = '') => {
    if (url?.length) {
      openInNewTab(url)
    }
  }
  return (
    <ItemContainer
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
    >
      {userCanEditCommunityModules && showActionArrow && (
        <div style={{ position: 'relative' }}>
          <>
            <SideComponentMenu onClick={(event) => openActionMenu(event)}>
              <ExpandMore />
            </SideComponentMenu>
            <MuiMenu
              id="delivery-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              onBackdropClick={handleClose}
            >
              <MenuItem
                onClick={openModuleModal}>
                {t`edit`}
              </MenuItem>
            </MuiMenu>
          </>
        </div>
      )}
      <Contant onClick={() => handleClick(item.external_url || '')} hasUrl={Boolean(item.external_url)}>
        <IconBg bgColor={item.icon_color}>
          {item.icon_name ? <UiIcon size={50} name={item.icon_name} /> : item.icon ? <img src={item.icon} /> : <div />}
        </IconBg>
        <TextContainer>
          <ItemText
            textcolor={theme.color.black + 80}
            weight={'bold'}
            size={'md'}
          >{item.title}</ItemText>
        </TextContainer>
      </Contant>
    </ItemContainer >
  )
}


interface props {
  list: Array<ModuleType>
}
const DeliveryList: FC<props> = ({ list }) => {
  return (
    <Container>
      {list.map((item: ModuleType) => <ListItem item={item} key={item.module_id} />)}
    </Container>
  )
}


export default DeliveryList
