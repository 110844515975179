import React, { FunctionComponent, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import useStore from 'store'
import { Text, theme } from 'ui'


const StyledRow = styled.div`
  display:flex;
  flex-direction:row;
`
const StyledMoney = styled.div<{ isRTL: boolean }>`
  display:flex;
  align-items:flex-end;
  flex-direction:${p => p.isRTL ? 'row-reverse' : 'row'};
`
const View = styled.div`
  display:flex;
`
interface MoneyProps {
  amount: number | string,
  agorotColor?: string,
  moneyColor?: string
  agorotOpacity?: number,
  moneySize?: number,
  agorotSize?: number,
  showCurrency?: boolean,
  currencySize?: number,
  dontShowAgorot?: boolean,
  forceShowAgorot?: boolean,
  adjustSizeByAmount?: boolean,
  [props: string]: any,
}

const Money: React.FC<MoneyProps> = ({ amount, agorotColor, moneyColor, agorotOpacity = 1, agorotSize, moneySize, showCurrency = true, currencySize = 24, dontShowAgorot = false, forceShowAgorot = false, adjustSizeByAmount, ...props }) => {
  let variableSize = moneySize
  if (!variableSize && adjustSizeByAmount) {
    const absAmount = Math.abs(Number(amount))
    if (absAmount <= 9999) {
      variableSize = 30
    }
    else if (absAmount <= 99999) {
      variableSize = 27
    }
    else {
      variableSize = 25
    }
  }
  if (!moneyColor) {
    if (amount < 0) {
      moneyColor = theme.color.budgetRed
    } else {
      moneyColor = theme.color.budgetDarkGreen
    }
  }
  if (!agorotColor) {
    if (amount < 0) {
      agorotColor = theme.color.budgetRed
    } else {
      agorotColor = theme.color.budgetDarkGreen
    }
  }
  const { budgetStore } = useStore()
  const ltrMark = '\u200E'
  const ltrMoney = `${ltrMark}${budgetStore.currencyType} ${ltrMark}`
  if (!agorotColor) {
    return (
      <View>
        <Text textcolor={moneyColor} {...props}>{budgetStore.getMoneyString(amount)}</Text>
        {showCurrency && <Text textcolor={moneyColor} {...props}>{budgetStore.currencyType}</Text>}
      </View>
    )
  }
  let fixedAmountNumber = Number(Number(amount).toFixed())
  if(budgetStore.showAgorot || forceShowAgorot){
    fixedAmountNumber = Number(Math.trunc(Number(amount)))
  }
  let fixedAmount = fixedAmountNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  if (amount < 0 && !fixedAmount.startsWith('-') && (forceShowAgorot || budgetStore.showAgorot)) {
    fixedAmount = '-' + fixedAmount
  }
  const isRtl = true
  if (((forceShowAgorot) || (budgetStore.showAgorot && !dontShowAgorot))) {
    fixedAmount = fixedAmount.concat((Number(amount) % 1)?.toFixed(2).replace(/^0|^-0|^-1|^1/, '').toLocaleString())
  }
  
  return (
    <StyledRow >
      <StyledMoney isRTL={isRtl}>
        <div style={{ display: 'inline-block', direction: 'ltr' }} {...props}>
          {showCurrency && <Text style={{ fontSize: currencySize, alignSelf: 'center', display: 'inline-block' }} textcolor={moneyColor}>{ltrMoney}</Text>}
          <Text style={{ fontSize: variableSize || 40, display: 'inline-block', lineHeight: 1 }} textcolor={moneyColor} {...props}>{fixedAmount}</Text>

        </div>
      </StyledMoney>
    </StyledRow >
  )
}

export default observer(Money)