import { randomIntFromInterval } from '../../../utils/number'
import { sortByDate, dateTimeFormat } from '../../../utils/date'

const kolbolitMockData = {
    transactions: [
        {
            items: [
                {
                    description: "ס.יפני קטן",
                    quantity: 2,
                    sumPerProduct: 3,
                    totalSum: 6
                }
            ]
        },
        {
            items: [
                {
                    description: "סיגריות פאל מאל",
                    quantity: 20,
                    sumPerProduct: 27,
                    totalSum: 540
                },
                {
                    description: "משקה שבולת שועל להקצפה",
                    quantity: 1,
                    sumPerProduct: 11.9,
                    totalSum: 11.9
                },
                {
                    description: "עלי וופל טעם חמאה",
                    quantity: 1,
                    sumPerProduct: 8.8,
                    totalSum: 8.8
                }

            ]
        },
        {
            items: [
                {
                    description: "סיגריות כאמל לייט",
                    quantity: 1,
                    sumPerProduct: 29,
                    totalSum: 29
                },
                {
                    description: "טבק באלי גולדן לגלגול",
                    quantity: 1,
                    sumPerProduct: 83,
                    totalSum: 83
                }

            ]
        },
        {
            items: [
                {
                    description: "נבטים / אלפלפא",
                    quantity: 2,
                    sumPerProduct: 4.8,
                    totalSum: 9.6
                },
                {
                    description: "רוטב צ'ילי מתוק",
                    quantity: 1,
                    sumPerProduct: 13.2,
                    totalSum: 13.2
                }

            ]
        }
    ]
}

const diningRoomMockData = {
    transactions: [
        {
            items: [
                {
                    description: "אורז מאודה",
                    quantity: 1,
                    sumPerProduct: 0.72,
                    totalSum: 0.72
                },
                {
                    description: "שעועית ברוטב עגבניות",
                    quantity: 1,
                    sumPerProduct: 2,
                    totalSum: 2
                }
            ]
        },
        {
            items: [
                {
                    description: "צ'יפס",
                    quantity: 1,
                    sumPerProduct: 5.92,
                    totalSum: 5.92
                }
            ]
        },
        {
            items: [
                {
                    description: "המבורגר צימחי",
                    quantity: 2,
                    sumPerProduct: 7.65,
                    totalSum: 15.3
                },
                {
                    description: "צלחת סלט",
                    quantity: 1,
                    sumPerProduct: 2.6,
                    totalSum: 2.6
                }
            ]
        },
        {
            items: [
                {
                    description: "קוביות עוף",
                    quantity: 1,
                    sumPerProduct: 22.20,
                    totalSum: 22.20
                },
            ]
        },
        {
            items: [
                {
                    description: "ירק מבושל לדיאטה",
                    quantity: 1,
                    sumPerProduct: 23.30,
                    totalSum: 23.30
                },
            ]
        }
    ]
}

function getDateRandom(year, month) {
    const day = randomIntFromInterval(1, 28)
    const hour = randomIntFromInterval(0, 23)
    const minute = randomIntFromInterval(0, 59)
    const time = `${hour < 10 ? '0' + hour : hour}${minute < 10 ? '0' + minute : minute}`
    return `${day}/${month}/${year} ${time}`
}

function getUniqueDate(transactions, year, month) {
    let date = dateTimeFormat(getDateRandom(year, month), '00:00', 'DD/MM/YYYY HH:mm')
    const dateExist = transactions.find(t => t.dateOfCharge === date)
    if (dateExist) {
        date = getUniqueDate()
    }
    return date
}

function replacePosMock(transactionsMock, year, month) {
    const transactions = []
    transactionsMock.forEach(transaction => {
        const date = getUniqueDate(transactions, year, month)
        transactions.push({
            ...transaction,
            dateOfCharge: date,
            details: '',
            quantity: transaction.items.length,
            totalSum: Number(transaction.items.reduce((a, b) => a + b.totalSum, 0).toFixed(2)),
            items: transaction.items.map(i => {
                i.date = date
                return i
            })
        })
    })
    return transactions
}

function getTotalTransactions(transactions) {
    return (transactions.reduce((a, b) => a + b.totalSum, 0).toFixed(2)).toString()
}

function getDemoPOSResponse(year, month) {
    try {
        let transactions = replacePosMock(kolbolitMockData.transactions, year, month)
        const kolbolitObj = {
            name: {
                he: "כלבולית",
                en: "kolbolit"
            },
            total: getTotalTransactions(transactions),
            lastDate: sortByDate('dd/MM/yyyy HH:mm', transactions, 'dateOfCharge')[0].dateOfCharge,
            transactions: transactions
        }
        transactions = replacePosMock(diningRoomMockData.transactions, year, month)
        const diningRoomObj = {
            name: {
                he: "חדר אוכל",
                en: "Dining Room"
            },
            total: getTotalTransactions(transactions),
            lastDate: sortByDate('dd/MM/yyyy HH:mm', transactions, 'dateOfCharge')[0].dateOfCharge,
            transactions: transactions
        }
        const response = {
            success: true,
            data: [kolbolitObj, diningRoomObj]
        }
        return response
    } catch (err) {
        console.log("Create mock error = ", err)
    }

}

export default getDemoPOSResponse