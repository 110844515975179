import { IconButton, Step, StepConnector, StepContent, StepLabel, Stepper } from '@material-ui/core'
import { Add, Clear } from '@material-ui/icons'
import { theme } from 'ui'
import { GeneralInput } from 'ui/Input/Input'
import { newGuid } from 'utils/generates'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import useStore from 'store'

const StyledStepConnector = styled(StepConnector)`
  padding: 0;
  &.MuiStepConnector-vertical {
    body[dir=rtl] & {
      margin-left: unset;
      margin-right: 12px;
    }
  }
  .MuiStepConnector-lineVertical {
    border-left-width: 1px;
    position: relative;
    left: -2px;
    body[dir=rtl] & {
      /* border-left-width: unset; */
      left: unset;
      right: -2px;
      border-left: none;
      border-right-style: solid;
      border-right-width: 1px;
    }
  }
`

const AddIcon = styled(Add)`
  background-color: ${theme.color.pinkPurple};
  border-radius: 50%;
  color: ${theme.color.white};
`

const SurveyInput = styled(GeneralInput)`
  background: ${theme.color.white};
    border-radius: 20px;
    border: 1px solid transparent;
`

const StyledStep = styled(Step)`
  .MuiInputBase-input::placeholder {
    color: ${theme.color.inputGrayBorder};
  }
  body[dir=rtl] & {
    padding-right: unset;
    padding-left: 8px;
    .MuiStepLabel-iconContainer {
      padding-right: unset;
      padding-left: 8px;
    }
  }
`

const AddAnswerStep = styled(StyledStep)`
.Mui-disabled {
      cursor: pointer;
      .MuiStepLabel-iconContainer {
        opacity: 0.5;
      }
      ${SurveyInput} {
        background: ${theme.color.white};
        border-radius: 20px;
        border: 1px dashed ${theme.color.gray};
      }
    }
`

const StyledStepper = styled(Stepper)`
  background-color: ${theme.color.dialogBackground};
  border-radius: 10px;
`
export interface SurveyAnswer {
  id?: string
  color?: string
  text: string
}

export interface Survey {
  answers: SurveyAnswer[]
}


interface SurveyProps {
  setNumOfSurveyStart:(item:number) => void
}
const survayColors = [16620858, 1414786, 12813542]

export const item = { id: newGuid(), text: '' }
const answerLimit = 20

const SurveyList: FC<SurveyProps> = ({setNumOfSurveyStart}) => {
  const { t } = useTranslation('Survey')
  const {  newMessage } = useStore()
  const updateMessage = newMessage?.currentUserMessage?.message?.id != 0

  const initial = newMessage.surveyAnswers?.map(i => { return { id: newGuid(), text: i.text } }) || []
  const [items, setItems] = useState<Array<any>>(updateMessage ? [...initial,{ id: newGuid(), text: '' }] : [...initial])

  useEffect(() => {
    if (updateMessage) {
      setNumOfSurveyStart(newMessage?.surveyAnswers?.length || 0)
    }
  }, [])

  useEffect(() => {
    let surveyAnswers: Array<any> = []
    items.map((item: any, index) => {
      if (item.text && item.text != '') {
        surveyAnswers.push({ text: item.text, color: survayColors[index] || survayColors[Math.floor(Math.random() * survayColors.length)] })
      }
    })
    newMessage.setSurveyAnswers([...surveyAnswers])
  }, [items])

  const onChangeAnswer = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const index = items.findIndex(a => a.id === e.target.name)
    if (index > -1) {
      let newAnswers = [...items]
      newAnswers[index].text = e.target.value
      if (!!e.target.value?.length && index === items.length - 1 && items.length <= answerLimit) {
        newAnswers.push({ id: newGuid(), text: '' })
      }
      if (index === items.length - 2 && !e.target.value?.length) {
        if (items[index + 1] && !items[index + 1]?.text?.length) {
          newAnswers.pop()
        }
      }
      setItems([...newAnswers])
    }
  }

  const onRemoveAnswer = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, answerId: string) => {
    let newSurvey = [...items]
    newSurvey = items.length > 1 ? items.filter(a => a.id !== answerId) : items
    setItems(newSurvey)
  }

  const removeButton = (answerId: string) => {
    return <IconButton
      disabled={items.length === 1}
      size='small'
      onClick={(e) => onRemoveAnswer(e, answerId)}
    >
      <Clear />
    </IconButton>
  }

  return (
    <StyledStepper
      connector={<StyledStepConnector />}
      orientation="vertical">
      {items.map((answer, index) => (
        <StyledStep key={answer.id}>
          <StepLabel disabled={false} StepIconComponent={() => <AddIcon />}>
            <SurveyInput
              placeholder={index === 0 ? t`firstAnswer` : t('answer', { number: index + 1 })}
              InputProps={{ endAdornment: removeButton(answer.id) }}
              fullWidth
              name={answer.id}
              value={answer.text}
              onChange={onChangeAnswer}
            />
          </StepLabel>
        </StyledStep>
      ))}
    </StyledStepper>
  )
}

export default SurveyList
