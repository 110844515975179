/* eslint-disable func-names */
import { observable, action, computed, IObservableArray, ObservableMap, toJS, IObservableObject } from 'mobx'
import orgsStore from 'store/orgs.store'
import membersStore from 'store/members.store'
import type { User, CsvUser, serverErrorType } from 'utils/models'
import { CsvUserStatus, errorType, uiErrorType } from 'utils/models'
import api from 'service/api'
import { getUsersFromCsv, geIsraelIdsFromCsv } from 'service/importer'
import type { UserGroup, Group } from 'utils/types'
import groupsStore from './groups.store'
import { formatIfPhoneNumber } from 'utils/converts'

class CsvStore {

  constructor() {
  }

  @observable CSVModalOpen: boolean = false
  @observable CSVMapSocialIdwithCntactModalOpen: boolean = false
  @action closeCSVModal = () => this.CSVModalOpen = false
  @action openCSVModal = () => this.CSVModalOpen = true
  @action openCSV = () => {
    this.openCSVModal()
  }
  @action handleCSVMapSocialIdwithCntactModal = (bool: boolean) => {
    this.CSVMapSocialIdwithCntactModalOpen = bool
    this.clearCsvFileIsraelIds()
    this.clearCsvFileIsraelIdErrors()
  }


  @observable uploaded: boolean = false;
  @observable selectedCount: number = 0;
  @observable rawUsersToImport: any[] = [];
  @observable csvFileUsers: CsvUser[] = [];
  @observable csvRawFileSocialIds: any[] = [];
  @observable csvFileSocialIds: any[] = [];
  @observable csvFileUsersErrors: IObservableArray<ObservableMap<uiErrorType, string>> = []
  @observable csvFileIsraelIdErrors: ObservableMap<string, any> = new Map()
  @observable relateToGroupsId: null | UserGroup = null

  @action setUploaded = (val) => {
    this.uploaded = val
  }

  @action setCsvFileIsraelIdErrors = (uploadErrors) => {
    this.csvFileIsraelIdErrors = uploadErrors
  }

  @action clearCsvFileIsraelIdErrors = () => {
    this.csvFileIsraelIdErrors = new Map()
  }

  @action resolveCsvUserList = (page, rowsPerPage) => {
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;

    const errorsArray: ObservableMap<uiErrorType, string>[] = []
    const csvUsersList: CsvUser[] = this.rawUsersToImport.slice(start, end).map((u: User, i: number) => {
      errorsArray.push(new ObservableMap<uiErrorType, string>())
      return {
        user: u,
        selected: true,
        errors: [],
        status: CsvUserStatus.New,
        id: i,
        addedToGroup: { status: false, description: '' }
      }
    })
    
    this.csvFileUsersErrors.replace(errorsArray)
    this.csvFileUsers.replace(csvUsersList)
    this.selectedCount = csvUsersList.length
  }

  @action setCsvFileUsers = async (file: any) => {
    this.setUploaded(false)
    let result = await getUsersFromCsv(file)
    let hasErrors = false;
    this.rawUsersToImport.replace(result)
    this.setUploaded(true) 

    const errors = result
    .map(item => {
      return {
        family_name: item.family_name,
        given_name: item.given_name,
        nick_name: item.nick_name,
        israeliID: item.israeliID,
        phone1: item.phones != null && item.phones.length > 0 ? item.phones[0] : '',
        phone2: item.phones != null && item.phones.length > 1 ? item.phones[1] : '',
        email: item.emails != null && item.emails.length > 0 ? item.emails[0] : '',
        error: ''
      }
    })
    .filter(item => {
      if (item.phone1 == '' && item.phone2 == '' && item.email == '') {
        hasErrors = true
        item.error = "מספר טלפון ואימיל נמצאים ריקים, צריך לפחות אחד מהם"
      }

      return true;
    })

  return { hasErrors, errors }
  }

  @action processCsvFileIsraelIds = async (file: any) => {
    this.setUploaded(false)
    let result = await geIsraelIdsFromCsv(file)
    let hasErrors = false;
    this.csvRawFileSocialIds = result 
    this.setUploaded(true) 

    const ids = result.reduce((acc, item) => {
      if (item.israeliID in acc) {
        acc[item.israeliID] += 1
      } else {
        acc[item.israeliID] = 1
      }

      return acc;
    }, {})

    const phones = result.reduce((acc, item) => {
      if (item.phone in acc) {
        acc[item.phone] += 1
      } else {
        acc[item.phone] = 1
      }

      return acc;
    }, {})

    const errors = result
      .map(item => {
        return {
          israeliID: item.israeliID,
          phone: item.phone,
          email: item.email,
          error: ''
        }
      })
      .filter(item => {
        if (item.israeliID.trim() == '') {
          item.error = "מספר תעודת הזהות אינו חוקי"
          hasErrors = true
          return true;
        }

        if (item.phone.trim() == '' && item.email.trim() == '') {
          item.error = "מספר טלפון ואימיל נמצאים ריקים, צריך לפחות אחד מהם"
          hasErrors = true
          return true;
        }

        if (ids[item.israeliID] > 1) {
          hasErrors = true
          item.error = "קיימים מספרי תעודת זהות כפולים יש לטפל בבעיה ולטעון את הקובץ מחדש"
          return true;
        }

        if (phones[item.phone] > 1) {
          hasErrors = true
          item.error = "קיימים מספרי טלפון כפולים יש לטפל בבעיה ולטעון את הקובץ מחדש"
          return true;
        }

        return true;
      })

    return { hasErrors, errors }
  }

  @action resolveCsvIsraelIdList = (page, rowsPerPage) => {
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;

    const csvSocialIdsChunk: any[] = toJS(this.csvRawFileSocialIds).slice(start, end)

    this.csvFileSocialIds.replace(csvSocialIdsChunk)
  }

  @action clearCsvFileIsraelIds = () => {
    this.csvFileSocialIds = []
    this.csvRawFileSocialIds = []
  }

  @action cleanCsvUsers = async () => {
    this.csvFileUsers.splice(0, this.csvFileUsers.length)
    this.rawUsersToImport.replace([])
  }

  @computed get getCsvUsers() {
    return toJS(this.csvFileUsers)
  }

  get getCsvSocialIds() {
    return toJS(this.csvFileSocialIds)
  }

  get getRawCsvSocialIds() {
    return toJS(this.csvRawFileSocialIds)
  }

  @action changeStatusAllSocialIds = (status) => {
    this.csvFileSocialIds.map((csvRow) => {
      csvRow.selected = status
    })
  }

  @action changeSocialIdStatus = (id) => {
    const index = this.csvFileSocialIds.findIndex((csvRow) => csvRow.guid === id)
    if (index > -1) {
      this.csvFileSocialIds[index].selected = !this.csvFileSocialIds[index].selected
    }
  }

  @action updateSocialId = (csvUser: any, id) => {
    const index = this.csvFileSocialIds.findIndex((csvRow) => csvRow.guid === id)
    if (index > -1) {
      this.csvFileSocialIds[index] = { ...csvUser }
    }
  }

  @action setRelateToGroup = (userGroup: UserGroup) => {
    this.relateToGroupsId = userGroup
  }

  @action clearRelateToGroup = () => {
    this.relateToGroupsId = null
  }

  @action changeStatusAllUsers = (status: boolean) => {
    // const newArray = [...fileUsers]
    this.csvFileUsers.map((csvUser: CsvUser) => {
      if (csvUser.status === CsvUserStatus.New)
      csvUser.selected = status
    })
    // this.csvFileUsers.replace(newArray)
  }

  @action changeUserStatus = (id: number) => {
    const index = this.csvFileUsers.findIndex((csvUser: CsvUser) => csvUser.id === id)
    if (index > -1) {
      this.csvFileUsers[index].selected = !this.csvFileUsers[index].selected
    }

    this.selectedCount = this.csvFileUsers.filter(item => item.selected).length
  }

  @action updateUser = (csvUser: CsvUser) => {
    const index = this.csvFileUsers.findIndex((csvUserList: CsvUser) => csvUserList.id === csvUser.id)
    if (index > -1) {
      this.csvFileUsers[index] = { ...csvUser }
    }
  }

  @action removeError = (id: number, err: uiErrorType) => {
    this.csvFileUsersErrors[id].delete(err)
  }
  
  @action createUsers = async () => {
    const addUsers = toJS(this.csvFileUsers).filter((csvUser: CsvUser) => csvUser.selected === true)
    let splitArray = []

    for (let i = 0; i < addUsers.length; i += 75) {
      splitArray.push(addUsers.slice(i, i + 75))
    }

    const results: { createdUsers: number[]; errorMessages: string[][]; }[] = []
    await Promise.all(splitArray.map(async (el) => {
      const response = await api.createUsers(el, orgsStore.currentOrgId || -1);
      results.push(response)
    }))
    
    const resultData = [...results.map(el => [...el.createdUsers, ...el.errorMessages]).flat()]

    const newUsers: number[] = [];
    const duplicatedUsers: number[] = [];
    const allUsers: number[] | any[][] = [];

    resultData.forEach((value) => {
      if (typeof value === 'number') {
        allUsers.push(value as any);
        newUsers.push(value as any);
        return;
      }

      let uId: number | null = null;
      const errors: any = [];

      value.forEach((error) => {
        try {
          const parsedError = JSON.parse(error)
          errors.push(parsedError);
          uId = parsedError.userId as number | null
        } catch {
          uId = null;
        }
      })
      
      if (typeof uId === 'number') {
        duplicatedUsers.push(uId)
        allUsers.push(uId)
      } else {
        allUsers.push(errors);
      }
    })

    if (duplicatedUsers.length > 0) {
      await api.updateUsersCommunityRelate(duplicatedUsers, orgsStore.currentOrgId || 0)
    }

    allUsers.map((createUserResult, index) => {
      if (typeof createUserResult === 'number') {
        this.csvFileUsers[addUsers[index].id].status = CsvUserStatus.Created
        this.csvFileUsers[addUsers[index].id].user.user_id = createUserResult
      } else if (createUserResult.length > 0) {
        try {
          createUserResult.map((error) => {
            if (error?.content?.key === errorType.InvalidIsraeliID) {
              this.csvFileUsersErrors[addUsers[index].id].set(uiErrorType.israeliIDError, error?.description)
            }
            if (error?.content?.key === errorType.InvalidEmailAddress) {
              this.csvFileUsersErrors[addUsers[index].id].set(uiErrorType.emailError, error?.description)
            }
            if (error?.content?.key === errorType.EmailAlreadyExists) {
              this.csvFileUsersErrors[addUsers[index].id].set(uiErrorType.emailError, error?.description)
            }
            if (error?.content?.key === errorType.EmptyPushTokens) {
              this.csvFileUsersErrors[addUsers[index].id].set(uiErrorType.emailError, error?.description)
            }
            if (error?.content?.key === errorType.FamilyNameIsMandatory) {
              this.csvFileUsersErrors[addUsers[index].id].set(uiErrorType.familyNameError, error?.description)
            }
            if (error?.content?.key === errorType.NickNameIsMandatory) {
              this.csvFileUsersErrors[addUsers[index].id].set(uiErrorType.nickNameError, error?.description)
            }
            if (error?.content?.key === errorType.NameIsMandatory) {
              this.csvFileUsersErrors[addUsers[index].id].set(uiErrorType.giveanNameError, error?.description)
            }
            if (error?.key === errorType.InvalidPhoneNumber) {
              if (this.csvFileUsers[addUsers[index].id]?.user.phones[0]?.localeCompare(error?.content?.value) >= 0) {
                this.csvFileUsersErrors[addUsers[index].id].set(uiErrorType.phone1Error, error?.description)
              }
              if (this.csvFileUsers[addUsers[index].id]?.user.phones[1]?.localeCompare(error?.content?.value) >= 0) {
                this.csvFileUsersErrors[addUsers[index].id].set(uiErrorType.phone2Error, error?.description)
              }
            }
          })
        } catch (err) {
          console.log('error parse server error', err)
        }

      }
    })

    if (Boolean(this.relateToGroupsId)) {
      let relateUserArray: number[] = []

      if (newUsers?.length > 0) {
        relateUserArray = relateUserArray.concat(newUsers)
      }
      if (duplicatedUsers?.length > 0) {
        relateUserArray = relateUserArray.concat(duplicatedUsers)
      }
      if (relateUserArray.length > 0) {
        const addUserToGroupArray: number[][] = []
        for (let i = 0; i < relateUserArray.length; i += 75) {
          addUserToGroupArray.push(relateUserArray.slice(i, i + 75))
        }
        for (let i = 0; i < addUserToGroupArray.length; i++) {
          this.addUsersToGroup(addUserToGroupArray[i])
        }
        // this.addUsersToGroup(relateUserArray)

    }

    }
    this.csvFileUsers.map((csvUser: CsvUser) => csvUser.selected = false)
    // this.csvFileUsersErrors.replace(this.csvFileUsersErrors)
    return
  }

  @action addUsersToGroup = async (users: number[]) => {
    const localStoreGroup = groupsStore.currentUserGroups?.find(g => g.group.id === this.relateToGroupsId.group.id || 0)
    let addUsersToGroup: number[] = []
    if (!this.relateToGroupsId || !localStoreGroup) {
      return
    }

    let groupDestinationUsers: User[] = await api.getGroupUsers(this.relateToGroupsId.group.id)

    users.map((user: number) => {

      const existInGroup = groupDestinationUsers.find(u => u.user_id === user)
      const userInFileIndex = this.csvFileUsers.findIndex((csvUser: CsvUser) => csvUser.user.user_id === user)

      if (userInFileIndex < 0) {
        return;
      }

      if (!existInGroup) {
        addUsersToGroup.push(user)
        this.csvFileUsers[userInFileIndex].addedToGroup = { status: true, description: 'משתמש נוסף לקבוצה' }
      } else {
        this.csvFileUsers[userInFileIndex].addedToGroup = { status: false, description: 'משתמש קיים בקבוצה' }
      }

    })


    const newGroupUsers: UserGroup[] = addUsersToGroup.map(el => {
      return {
        is_subscriber: localStoreGroup?.group.group_is_subscriber,
        is_favourite: localStoreGroup?.group.group_is_favourite,
        user: el,
        is_member: true,
        is_sender: localStoreGroup?.group.group_is_sender,
        is_organizer: false,
        group: localStoreGroup.group,
        community: orgsStore.currentOrgId || 0,
        id: 0
      }
    })
    await api.updateGroupUsers(newGroupUsers)
    this.clearRelateToGroup()
  }
}

const csvStore = new CsvStore()
export default csvStore
