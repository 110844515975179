import React, { useState } from 'react';
import { Page } from "react-pdf";
import styled from 'styled-components'
import { ChevronRight, ChevronLeft, Close } from '@material-ui/icons'
import { Spinner } from 'ui'
import useStore from 'store'
import { Document, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const NumPages = styled.div`
  color:black;
  margin-top:10px;
  `
const PDFWrapper = styled.div<{ height?: number }>`
canvas {
  width: 100% !important;
  height: auto !important;
}
  min-height:300px;
  max-height:${p => p.height ? p.height : '500px'};
  overflow:hidden;
  :hover {
  cursor: pointer;
}
`

function PdfPreview({ file, height }: { file: any, height?: number }) {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [finishLoading, setfinishLoading] = useState(false)
  const { messages } = useStore()

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setfinishLoading(true)
  }

  return (
    <PDFWrapper
      height={height}
      onClick={() => messages.openViewerModal('pdf', file)}
    >
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
        loading={() => <div style={{ flex: 1 }}><Spinner /></div>}
      >

        <Page
          // height={500}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          loading={() => <div style={{ flex: 1 }}><Spinner /></div>}
          pageNumber={1}
        />
      </Document>
      {numPages && <NumPages>{`${numPages} עמודים`}</NumPages>}
    </PDFWrapper>
  )
}

export default PdfPreview
