export const WHITE_LABLE_APP_NAME = process.env.REACT_APP_WHITE_LABLE_APP_NAME;

export const TOTZERET_NAME = "Totzeret";

export const isTotzeret = WHITE_LABLE_APP_NAME === TOTZERET_NAME;

export const TotzeretColorsByCategory = {
    blue: { main: '#173d70', secondary: '#dae8fa'},
    grey: { main: '#4c708c', secondary: '#dee7f0'},
    lightblue: { main: '#a7ccf0', secondary: '#dfedfa'},
    yellow: { main: '#d4b554', secondary: '#fae8b8'},
    brown: { main: '#ba8a5d', secondary: '#fbdec6'},
    red: { main: '#703e38', secondary: '#c9aba8'},
    green: { main: '#657352', secondary: '#d1d6c7'},
    lightgreen: { main: '#9ca659', secondary: '#e7ebd2'},
    darkgreen: { main: '#0f402e', secondary: '#b7d9cc'},
    default: { main: '#b3b3b3', secondary: '#efefef'}
}

export const getTotzeretColorsByCategory = (category: keyof typeof TotzeretColorsByCategory) => (TotzeretColorsByCategory[category] || TotzeretColorsByCategory.default);
