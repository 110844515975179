
import validator from 'validator'

export const isValidDate = (d: any) => {
  return validator.isDate(d)
}

export const validId = (id: number) => {
  const idString = String(id);
  if (!idString || /^.{0,8}$/.test(idString))
    return false;
  const isNineDigits = /^\d{9}$/.test(idString);
  const isDivided = Array.from(idString, Number).reduce((counter, digit, i) => {
    const step = digit * ((i % 2) + 1);
    return counter + (step > 9 ? step - 9 : step);
  }) % 10 === 0;
  return isNineDigits && isDivided;
}

export function validURL(str: string = '') {
  let url;
  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:"
}

export function validEmail(str: string = '') {
  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  try {
    return validRegex.test(str)
  } catch (_) {
    return false
  }
}

export function validMobileSimple(str: string = '') {
  const validRegex = /$/;

  try {
    return /^(\+972)?\d{5,}$/.test(str)
  } catch (_) {
    return false
  }
}

