import React, { FC, useState, useEffect, useRef } from 'react'
import { Button, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { Appear, Icon, Row, Space, theme } from 'ui'
import { useTranslation } from 'react-i18next'
import { Direction } from 'ui/Appear/Appear'
import { DateTimePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import { enUS, he } from 'date-fns/locale'
import { observer } from 'mobx-react'
import useStore from 'store'
import isMobile from 'utils/isMobile'
import * as moment from 'moment'

interface MessagesDateTimePickerProps {
  onValueChanged: () => void,
}

const ApplyButton = styled(Button) `
    &.MuiButtonBase-root {
    font-size: ${theme.fontSize.large};
    font-weight: 500;
    flex: auto;
    min-width: 140px;
    height: 50px;
    margin: 0.5rem;
    background: white;
    &:hover {
      background: #E8E8E8;
    }
    color: #007eff;
    &.Mui-disabled {
      opacity: 0.5;
    }
  }
`

const CustomDateTimeContainer = styled.div`
`
const DatePickerStartArrow = styled(ChevronLeft)`
  body[dir='rtl'] & {
    transform: scaleX(-1)
  }
`
const DatePickerEndArrow = styled(ChevronRight)`
  body[dir='rtl'] & {
    transform: scaleX(-1)
  }
`

const DateTimeButton = styled(Button)`
  color: ${theme.color.blueText};
  margin-right: 2rem;
  body[dir='rtl'] & {
    margin-right: unset;
    margin-left: 2rem;
  }
`
const DateTimeButton2 = styled(Button)`
background: white;
width: 20px;
margin: 0px auto 11px auto;

`
const StyledDatePicker = styled(DateTimePicker)`
  background-color:green !important;
  &.MuiPickersDay-daySelected{
    background-color:green !important;
  }
`
const TP = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media (max-width: 768px) {
    transform: scale(0.8);
    transform-origin: center right;
    max-width: calc(100% - 24px);
    margin-left: -30px;
  }
`
const DateRangeButton = styled(Button)`
  &.MuiButtonBase-root {
    font-size: 14px;
    color: white;
    border: 1px solid white;
    font-weight: 500;
    border-radius: 25px; 
    margin: 3px 5px 7px 5px;
    padding-top:3px;
    background: none;
    &:hover {
      box-shadow:  0 0 10px 5px rgba(255,255,255,0.6);
      -moz-box-shadow: 0 0 10px  5px rgba(255,255,255,0.6);
      -webkit-box-shadow: 0 0 10px 5px rgba(255,255,255,0.6);
      -o-box-shadow: 0 0 10px 5px rgba(255,255,255,0.6);
    }
    color: ${theme.color.white};
    &.Mui-disabled {
      opacity: 0.5;
    }

    & span.MuiButton-label {
      margin-top: 6px
    }
  }
`

const MessagesDateTimePicker: FC<MessagesDateTimePickerProps> = ({ onValueChanged }) => {
  const { t, i18n } = useTranslation('messagesDateTimeSelector')
  const { user, mapStore } = useStore()
  
  const [showFromDate, setShowFromDate] = useState(true)
  const [showToDate, setShowToDate] = useState(true)
  const [showDateTimePicker, setShowDateTimePicker] = useState(false) 
  const myRef = useRef();

  useEffect(() => {
    mapStore.initDefaultDateRange()
  }, [])

  const isFromDateCalendarEnabled = () => {
    return showFromDate
  }

  const isToDateCalendarEnabled = () => {
    return showToDate
  }

  const applyDateRangeByLastHours = (hours: number) => {
    mapStore.filterDates = {
      from: moment().subtract(hours, 'hours'),
      to: moment()
    }
  }
 
  const onShowDate = () => {
    setShowFromDate(true)
    setShowToDate(true)
    setShowDateTimePicker(!showDateTimePicker)
  }

  const onClose = () => {
    setShowDateTimePicker(false)
  }
 
  const handleDateChanged = (date: Date | null, rangeType: string) => {
    const data = mapStore.filterDates 
    data[rangeType] = moment(date);
    
    mapStore.filterDates = {
      from: data.from,
      to: data.to
    }
  }

  useEffect(() => {
    document.addEventListener('click', (e) => {
      if (myRef?.current && !myRef.current.contains(e.target) && e.target.parentElement?.id != 'map-date-filter') {
        onClose()
      }
    }, true);
  });
  

  const getDateRangeStr = () => {
    return `${mapStore.filterDates.from.local().format('DD/MM/YYYY H:m')} - ${mapStore.filterDates.to.local().format('DD/MM/YYYY H:m')}`
  }

  return (
    <Appear direction={Direction.down}>
      <CustomDateTimeContainer>
        <Row>
          <DateTimeButton
            id='map-date-filter'
            variant='text'
            startIcon={<Icon namespace='mekome' name='schedule' size={16} />}
            onClick={onShowDate}
          >
            <span style={{marginTop: '3px'}}>{getDateRangeStr()} {t`selectDate`}</span>
          </DateTimeButton>
        </Row>
        <div style={{ position: 'absolute', zIndex: 999, backgroundColor: '#007eff' }}>
          
          <MuiPickersUtilsProvider locale={user.dateFnsLocal} utils={DateFnsUtils}>
            {showDateTimePicker && (
              <>
              <div style={{ marginTop: '20px', display: 'flex', color: 'white', alignItems: 'center', justifyContent: 'center' }}>
                <ApplyButton onClick={() => {  
                  onValueChanged()
                  onClose() 
                  }}>אישור
                </ApplyButton>
                <span style={{ fontSize: '22px',  margin: '0.5rem' }}>{getDateRangeStr()}</span> 
              </div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <TP>
                  <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', paddingTop: "20px", marginRight: "20px" }}>
                    <DateRangeButton onClick={() => { applyDateRangeByLastHours(1) }}>שעה אחרונה</DateRangeButton>
                    <DateRangeButton onClick={() => { applyDateRangeByLastHours(4) }}>4 שעות אחרונות</DateRangeButton>
                    <DateRangeButton onClick={() => { applyDateRangeByLastHours(8) }}>8 שעות אחרונות</DateRangeButton>
                    <DateRangeButton onClick={() => { applyDateRangeByLastHours(24) }}>24 שעות אחרונות</DateRangeButton>
                  </div>
                  
                </TP>
                <TP>
                  {showFromDate && (
                  <StyledDatePicker
                    autoOk
                    value={mapStore.filterDates.from.toDate()}
                    open={showFromDate}
                    onChange={date => handleDateChanged(date, 'from')}
                    variant='static'
                    views={['date']}
                    hiddenLabel
                    disableToolbar
                    leftArrowIcon={<DatePickerStartArrow />}
                    rightArrowIcon={<DatePickerEndArrow />}
                  />
                  )}

                  {!showFromDate && (
                      <TimePicker
                       autoOk
                       ampm={false}
                       variant="static"
                       openTo="hours"
                       value={mapStore.filterDates.from.toDate()}
                       hiddenLabel
                       disableToolbar
                       onChange={date => {
                        handleDateChanged(date, 'from')
                       }}
                     />
                  )}

                  <DateTimeButton2 variant='text' onClick={() => { setShowFromDate(!showFromDate) }}>
                    <Icon namespace='mekome' name={isFromDateCalendarEnabled() ? 'clock' : 'schedule'} size={16} />
                  </DateTimeButton2>
                </TP>
                <TP>
                {showToDate && (
                  <StyledDatePicker
                    autoOk
                    value={mapStore.filterDates.to.toDate()}
                    open={!showToDate}
                    onChange={date => handleDateChanged(date, 'to')}
                    variant='static'
                    views={['date']}
                    hiddenLabel
                    disableToolbar
                    leftArrowIcon={<DatePickerStartArrow />}
                    rightArrowIcon={<DatePickerEndArrow />}
                  />
                )}

                  {!showToDate && (
                      <TimePicker
                       autoOk
                       ampm={false}
                       variant="static"
                       openTo="hours"
                       hiddenLabel
                       disableToolbar
                       value={mapStore.filterDates.to.toDate()}
                       onChange={date => handleDateChanged(date, 'to')}
                     />
                  )}      
                  <DateTimeButton2 variant='text' onClick={() => { setShowToDate(!showToDate) }}>
                    <Icon namespace='mekome' name={isToDateCalendarEnabled() ? 'clock' : 'schedule'} size={16} />
                  </DateTimeButton2>
                </TP>
                </div>
                </>
            )}
          </MuiPickersUtilsProvider>
        </div>
      </CustomDateTimeContainer>
    </Appear>
  )
}

export default observer(MessagesDateTimePicker)
