import React,{FC} from 'react'
import Highlighter from "react-highlight-words";
import { theme } from 'ui'

interface props{
    searchText: string
    text: string
    backgroundColor?: string
    textColor?:string
}

const WordsHighlighter: FC<props> = ({ searchText, text, backgroundColor = 'yellow', textColor = theme.color.black }) => {
    return (
      <Highlighter
        searchWords={searchText.split(" ")}
        autoEscape={true}
            textToHighlight={text}
            highlightStyle={{backgroundColor:backgroundColor, color:textColor}}
    ></Highlighter>
    )
}


export default WordsHighlighter