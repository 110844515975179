import React,{FC} from 'react'
import { Dialog, Button } from '@material-ui/core'
import styled from 'styled-components'
import {  theme, Text } from 'ui'


const InfoText = styled(Text)`
  font-size:18px;
  font-weight:500;
  text-align:center;
`
const Container = styled.div`
    display:flex;
    width:400px;
    height:200px;
    justify-content:space-around;
    align-items:center;
    flex-direction:column;
    padding:20px;
    word-break: break-word;

`
const WarningDialog: FC<any> = ({ isOpen, okAction, cancelAction, singleText }) => {
    return (
        <Dialog
            open={isOpen}
            onClose={cancelAction}
        >
            <Container

            >    {singleText &&
                <InfoText >
                    {singleText}
                </InfoText>
                }
                <Button
                    style={{backgroundColor:theme.color.azureBlue, color:theme.color.white}}
                    onClick={okAction}>{'אישור'}</Button>
            </Container>
        </Dialog>
    )
}

export default WarningDialog