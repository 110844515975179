import React, { FC, ReactElement, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Dialog, IconButton, DialogContent, DialogTitle } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Close, DockRounded } from '@material-ui/icons'
import { theme, Text, Row, Space, Icon, Spinner } from 'ui'
import { observer } from 'mobx-react'
import useStore from 'store'
import MessageItem from '../Messages/MessagesFeed/MessageItem'
import MessagesHeader from 'components/Messages/MessagesHeader'
import { Email, EmailOutlined, Send, ExpandMore, ErrorSharp, ExpandLess, ChevronLeftSharp, ChevronRightSharp } from '@material-ui/icons'
import { Collapse, UnmountClosed } from 'react-collapse'
import { ReactComponent as PhoneIcon } from 'assets/icons/detailsIcon/phone_icon.svg'
import mobileChat from 'assets/icons/detailsIcon/mobile_chat.svg'
import newsLetter from 'assets/icons/detailsIcon/newsletter.svg'
import textSms from 'assets/icons/detailsIcon/textsms.svg'
import { formatDate } from 'utils/date'
import { formatIfPhoneNumber } from 'utils/converts'
import { StatusType } from 'utils/types'
import { Tooltip } from '@material-ui/core'
import ExportCSV from 'assets/icons/export_csv.svg'
import { Responses, saveMessageDetailsCsv } from 'utils/csvUtils'
import { GroupType, deliveryType } from 'utils/types'
import type { User, MessageResponse, UserMessage } from 'utils/models'
import { canSeeMessageDetails, userIsDirectorOrSuperAdmin, canSeeAdditionalMessageDetails, canExportSurveyResults } from 'utils/roles'

const ContainerDialog = styled.div<{ color: string }>`
    align-items:center;
    /* justify-content:flex-start;; */
    flex-direction:column;
    display:flex;
    background-color:${p => p.color + '1A'};
    padding:20px 20px;
    max-width:700px;
    .ReactCollapse--collapse {
        transition: height 500ms;
        }
        overflow:auto;
        height:80vh;
        min-width:550px;
  `

const ButtonsContainer = styled.div`
  width:100%;
  min-width:500px;
  @media(max-width: 768px) {
    min-width: unset;
  }
  display: flex;
  border-radius: 12px;
  background-color:white;
  flex-direction:column;
  /* margin:20px; */
   border:1px solid ${theme.color.grayBackground};
   box-shadow: 0 2px 8px 0 ${theme.color.shadow};

  `

const SendIcon = styled(Send)`
    transform: rotate(-40deg);

  `
const ButtonContainer = styled.div<{ bgColor: string }>`
  width:100%;
  display: flex;
  align-items:center;
  background-color:${p => p.bgColor};
  /* justify-content:space-between; */
  flex-direction:row;
  padding:15px;
  border-bottom:2px solid ${theme.color.grayBackground};

  `

const IconBg = styled.div<{ color: string }>`
    background-color:${p => p.color};
    display:flex;
    justify-content:center;
    align-items:center;
    width:25px;
    height:25px;
    border-radius:8px;
  `

const MessageDetailsButton = ({ Icon, text, sum, precent, userslist }: { Icon: any, text: string, sum: number, precent: number, userslist: MessageResponse[] }) => {
    const [open, toggle] = useState(false)
    const { messages, groups } = useStore()
    const groupColor = groups.currentGroup?.group.description?.color || theme.color.black
    if (userslist.length === 0) {
        return null
    }
    return (
        <>
            <ButtonContainer bgColor={open ? groupColor + 'A1' : 'transparent'} onClick={userslist.length === 0 ? () => { } : () => toggle(s => !s)}>
                <Row style={{ width: '50%', justifyContent: 'flex-start', }} >
                    <Icon open={open} />
                    <Space width={1} />
                    <Text
                        weight={'normal'}
                        size={'xl'}
                        textcolor={open ? theme.color.whiteBackground : theme.color.black}
                    >{`${text}: ${sum}`}</Text>
                </Row>
                <Row style={{ flex: 1, justifyContent: 'space-between', }}>
                    <Row>
                        <Text
                            style={{ color: open ? theme.color.whiteBackground : theme.color.black }}
                        >|</Text>
                        <Space width={0.5} />
                        <Text
                            textcolor={open ? theme.color.whiteBackground : theme.color.black}
                            weight={'normal'}
                            size={'lg'}

                        >{precent}%</Text>
                    </Row>
                    {(userslist?.length > 0) && (open ? <ExpandLess style={{ color: theme.color.whiteBackground }} /> : <ExpandMore />)}
                </Row>
            </ButtonContainer>
            <Collapse
                isOpened={open}>
                {userslist.map((u: MessageResponse) => <SectionComponent item={u} />)}
            </Collapse>
        </>
    )
}

const SectionContainer = styled.div`
  display: flex;
  align-items:center;
  justify-content:space-between;
  flex-direction:row;
  padding:15px;
  border-bottom:2px solid ${theme.color.white};
  background-color:${p => p.color};
  
  @media(max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const SectionRow = styled(Row)`
  width:30%;
  @media(max-width: 768px) {
    width: unset;
  }
  display: flex;
  align-items:center;
  justify-content:center;
  text-align:center;

  `
const PhonesContainer = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
  text-align:center;
  word-break:break-word;
  flex:1;
  flex-direction:column;
  @media(max-width: 768px) {
    align-items: flex-start;
    justify-content: unset;
  }
`

const HeaderButtonsRow = styled(Row) <{ bgColor: string }>`
  justify-content: flex-end;
  padding-left: 30px;
  background-color: ${p => p.bgColor}};
  `
const StyledIconButton = styled(IconButton)`
background-color: ${theme.color.azureBlue};
border-radius:8px;
height: 30px;
width: 30px;
&:hover {
      background:${theme.color.red};
      } 
&.MuiIconButton-root:hover {
    background-color: ${theme.color.azureBlue + 66}
}
`


const SectionComponent = ({ item }: { item: MessageResponse }) => {
    const { members, groups } = useStore()
    const groupColor = groups.currentGroup?.group.description?.color || theme.color.black
    const { t } = useTranslation('messageDetails')

    if (!item) {
        return <div />
    }

    const member: User | undefined = members?.getById(item.user_ref)
    if (!member) {
        return <div />
    }
    const name = `${member.family_name} ${member.nick_name ? '(' + member.nick_name + ')' : ''} ${member.given_name}`
    let phones: Array<string> = []
    if (member.phones) {
        phones = [...member.phones]
    }
    if (member.emails) {
        phones = [...phones, ...member.emails]
    }
    return (
        <SectionContainer color={groupColor + '33'} >
            <SectionRow>
                <Text
                    weight={'normal'}
                    size={'xl'}
                >{name}</Text>
            </SectionRow>
            <PhonesContainer >
                {phones.map(i => <Text
                    weight={'normal'}
                    size={'xl'}>{formatIfPhoneNumber(i || '')}</Text>)}
            </PhonesContainer>
            <Row style={{ width: '17%' }}>
                <Row >
                    <Space width={0.5} />
                    <Text>{formatDate(new Date(item.updated), t)}</Text>
                    <Space width={0.5} />
                    {item.delivery_type == deliveryType.MOBILE && <img src={mobileChat} />}
                    {item.delivery_type == deliveryType.MOBILE2 && <img src={mobileChat} />}
                    {item.delivery_type == deliveryType.MAIL && <img src={newsLetter} />}
                    {item.delivery_type == deliveryType.SMS && <img src={textSms} />}
                </Row>
            </Row>
        </SectionContainer>
    )
}


const MessageDetailsModal: FC = ({ }) => {
    const { messages, groups, newMessage, orgs, user: userStore } = useStore()
    const { t } = useTranslation('messageDetails')
    const { t: t2 } = useTranslation('csvHeaders')
    const supportCallType = groups.currentGroup?.group?.type === GroupType.SUPPORT

    const open = messages.messageDetailsModalOpen
    const userMessage = messages.currentMessage
    const [responses, setResponses] = useState<Responses | []>([])
    const closeModal = () => {
        messages.setMessageDetailsModalOpen(false)
    }
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getResponses = async () => {
            setLoading(true)
            const responses = await newMessage.getMessageResponses(userMessage.message.id)
            setResponses(responses)
            setLoading(false)
        }
        if (userMessage?.message?.id && open) {
            getResponses()
        }

    }, [open])

    const groupColor = groups.currentGroup?.group.description?.color || theme.color.black
    const iconColor = groups.currentGroup?.group.description?.iconColor || 'gray'
    const outGoingList = responses[StatusType.OUTGOING] || []
    const sentList = responses[StatusType.SENT] || []
    const failedList = responses[StatusType.FAILED] || []
    const receivedList = responses[StatusType.RECEIVED] || []
    const responsedList = responses[StatusType.RESPONSED] || []
    const readList = [...(responses[StatusType.READ] || []), ...(responses[StatusType.RESPONSED] || [])]
    const handledNumber = (outGoingList.length + sentList.length + failedList.length + receivedList.length + readList.length) || 1
    const membersCount = groups.groupMembers.filter(el => el.is_member).length || 0
    //const membersCount = handledNumber
    return (
        <Dialog
            open={open}
            onClose={closeModal}
            maxWidth={'xl'}
        >
            <MessagesHeader
                textVarient={'black'}
                backColor={groupColor + '1A'}
                mainText={supportCallType ? `${(orgs.currentOrg?.name || '')} ${t`serviceCall:services`} | ${groups?.currentGroup?.group?.name} ` : groups?.currentGroup?.group?.name}
                secondText={t`messageDetails`}
                onClose={closeModal}
            />
            {canExportSurveyResults() && (
                <HeaderButtonsRow bgColor={groupColor + '1A'}>
                    <Tooltip title={userMessage?.message?.msg_strategy?.startsWith('QS') ? t`export` : t`exportFreinds`} arrow>
                        <div>
                            <StyledIconButton onClick={() => { saveMessageDetailsCsv(responses as Responses, userMessage, `${t2`csvFileNameMessage`}${groups.currentGroup.group.name.split(' ').join('_')}`, t2) }}>
                                <img src={ExportCSV} />
                            </StyledIconButton>
                        </div>
                    </Tooltip>
                </HeaderButtonsRow>
            )}
            <ContainerDialog
                color={groupColor}>
                {loading ? <Spinner /> : (
                    <>
                        <MessageItem
                            userMessage={userMessage}
                            disabledActions={true}
                            fullWidth
                            detailsMode
                        />
                        {canSeeAdditionalMessageDetails() && (
                            <>
                                <Space width={1} />
                                {(userMessage?.message?.msg_strategy?.startsWith('QS')) ? <Row style={{ marginBottom: 10 }}><Text

                                    textcolor={theme.color.black}
                                >
                                    {`${t('answer_the_survey')}\xa0`}
                                </Text>
                                    <Text

                                        textcolor={theme.color.black}
                                    >
                                        {`\xa0 ${handledNumber}/\xa0`}
                                    </Text>
                                    <Text

                                        textcolor={theme.color.black}
                                    >
                                        {`${responsedList.length}`}
                                    </Text>
                                    <Text

                                        textcolor={theme.color.black}
                                    >
                                        {`\xa0${t('friends')}`}
                                    </Text>
                                </Row> : <Text

                                    opacity={0.6}
                                    textcolor={theme.color.black}
                                >
                                        {`${t('num_friends_in_group')} :${membersCount}`}
                                    </Text>}
                                <ButtonsContainer>
                                    <MessageDetailsButton
                                        Icon={({ open }: { open: boolean }) => <EmailOutlined style={{ color: open ? 'white' : theme.color.blueTwo }} />}
                                        text={t`read`}
                                        sum={readList.length}
                                        precent={Math.round(readList.length / handledNumber * 100)}
                                        userslist={readList}
                                    />
                                    <MessageDetailsButton
                                        Icon={({ open }: { open: boolean }) => <Email style={{ color: open ? 'white' : theme.color.blueTwo }} />}
                                        text={t`recived`}
                                        sum={receivedList.length}
                                        precent={Math.round(receivedList.length / handledNumber * 100)}
                                        userslist={receivedList}

                                    />
                                    <MessageDetailsButton
                                        Icon={({ open }: { open: boolean }) => <SendIcon style={{ color: open ? 'white' : '#c8a0d2' }} />}
                                        text={t`send`}
                                        sum={sentList.length}
                                        precent={Math.round(sentList.length / handledNumber * 100)}
                                        userslist={sentList}

                                    />
                                    <MessageDetailsButton
                                        Icon={({ open }: { open: boolean }) => <Send style={{ color: open ? 'white' : theme.color.gray }} />}
                                        text={t`ongoing`}
                                        sum={outGoingList.length}
                                        precent={Math.round(outGoingList.length / handledNumber * 100)}
                                        userslist={outGoingList}

                                    />
                                    <MessageDetailsButton
                                        Icon={({ open }: { open: boolean }) => <ErrorSharp style={{ color: open ? 'white' : theme.color.dangerRed }} />}
                                        text={t`failed`}
                                        sum={failedList.length}
                                        precent={Math.round(failedList.length / handledNumber * 100)}
                                        userslist={failedList}

                                    />
                                </ButtonsContainer>
                            </>
                        )}
                    </>
                )}
            </ContainerDialog>
        </Dialog>
    )
}

export default observer(MessageDetailsModal)


