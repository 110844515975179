import React from 'react'
import styled from 'styled-components'
import { theme, Image, Text, Space } from 'ui'
import { getEmptyGroupIcon } from 'utils/images'
import { useTranslation } from 'react-i18next'
import { isTotzeret } from 'service/whiteLable'


const EmptyContainer = styled.div`
  align-items:center;
  justify-content:center;
  display:flex;
  flex:1;
  flex-direction:column;
  height:100%;
  .MuiTypography-root {
    max-width: 80vw !important;
  }
  ${isTotzeret ? 'background-color: #f4f4f2;' : ''}
`
export default function EmptyComponent({ category,icon,title,description,action }:{category?:string,icon?:any,title?:string,description?:string}) {
    const { t } = useTranslation('emptyGroup')

    return (
        <EmptyContainer>
            {icon ? icon : isTotzeret ? getEmptyGroupIcon(category || '') : <Image src={getEmptyGroupIcon(category || '')} />}
            <Text
                style={{ paddingTop: 10, paddingBottom: 10 }}
                textcolor={theme.color.black}
                size='xxl'
                weight='medium'>{title ? title : t`title`}</Text>
            <Text
                style={{ paddingTop: 10, paddingBottom: 10 }}
                textcolor={theme.color.black + 33}
                size='lg'
                weight='medium'>{description ? description : t`description`}</Text>
            <Space height={2} />
            {action ? action : <div/>}
        </EmptyContainer>
    )
}
