const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()
const PNF = require('google-libphonenumber').PhoneNumberFormat

const re = /(\*\d+(?:[-\. ]+\d+)*|\(?\+?\d+(?:[-\. \(\)]+\d+)*)([#\d]*(?:,+[#\d]+)*)?/g

function findPhones(text) {
  if (!text) return
  let matches = []
  let match
  while (match = re.exec(text)) {
    try {
      let phone = phoneUtil.parseAndKeepRawInput(match[1], 'IL')
      if (!phoneUtil.isValidNumber(phone)) continue
      let ext = match[2] == null ? '' : match[2]
      let link = 'tel:' + phoneUtil.formatInOriginalFormat(phone) + ext
      matches.push({start: match.index, length: match[0].length, text: match[0], link: link})
    } catch(e) {}
  }
  return matches
}

function replacePhones(text, func = createHtmlLink) {
  if (!func) return text
  let matches = findPhones(text)
  let result = []
  let lastIndex = 0
  for (let i = 0, n = matches.length; i < n; ++i) {
    let match = matches[i]
    if (match.start > 0 && match.start > lastIndex) result.push(text.slice(lastIndex, match.start))
    lastIndex = match.start + match.length
    let link = func(match.text, match.link)
    result.push(link)
  }
  if (lastIndex < text.length) result.push(text.slice(lastIndex, text.length))
  return result.join('')
}

function createHtmlLink(text, link) {
  return `[${text}](${link})`
}

exports.findPhones = findPhones
exports.replacePhones = replacePhones
exports.createHtmlLink = createHtmlLink