import React, { FC, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import Page from 'core/Page'
import { Android, Apple, GroupAddTwoTone, LaptopChromebook, PersonAdd, NoteAdd, ListAlt, ImportExport } from '@material-ui/icons'
import { Row, Space, Text, Spinner } from 'ui'
import { Tooltip } from '@material-ui/core'
import AlphoneList from 'components/Members/AlphoneList'
import MembersInGroupDialog from 'components/Members/MembersInGroupDialog'
import useStore, { uiStore } from 'store'
import Search from 'components/Search'
import { useTranslation } from 'react-i18next'
import { IconButton, theme } from 'ui'
import { canEditCommunityUsers, canAddUserToCommunity, canExportCommunityUsers } from 'utils/roles'
import { AppType } from 'utils/models'
import MembersCounter from 'components/MembersCounter'
import { formatIfPhoneNumber, intToBool } from 'utils/converts'
import { saveCsv } from 'utils/csvUtils'
import ExportCSV from 'assets/icons/export_csv.svg'
import Recycle from 'assets/icons/recycle.svg'
import exportDataIcon from 'assets/icons/exportDataIcon.svg'
import isMobile from 'utils/isMobile'
import MobileMembersList from 'components/Members/MobileMembersList'
import type { User } from 'utils/models'

const ButtonsContainer = styled.div`
  display: flex;
`

const SearchContainer = styled.div`
  width: 25vw;
  max-width:100%;
  @media (max-width: 768px) {
    width: 100%;
  }
`
const AppTypeContainer = styled.div`
  display:flex;
  flex-direction:row;
  margin-top:5px;
`

const SmallApple = styled(Apple)`
  font-size:1rem;
  color:${theme.color.black + 99};
`
const SmallAndroid = styled(Android)`
  font-size:1rem;
  color:${theme.color.black + 99};
`

const SmallLaptopChromebook = styled(LaptopChromebook)`
  font-size:1rem;
  color:${theme.color.black + 99};
`

const AppTypeText = styled(Text)`
  font-size: 0.8rem;
`

const CounterContainer = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
`

const Content = styled.div`
  background:${theme.color.white};
  border-radius: 0 0 5px 5px;
  display:flex;
  flex:1;
  flex-direction:column;
`

const ListContainer = styled.div`
  flex: 1;
  display:flex;
  flex-direction:column;
`

const SpinnerContainer = styled.div`
  flex:1;
  align-items:center;
  justify-content:center;
  display:flex;
`

const List = isMobile()
  ? MobileMembersList
  : AlphoneList


const Contacts: FC = () => {
  const { t } = useTranslation('members')
  const { t: t2 } = useTranslation('csvHeaders')
  const { members, groups, orgs, memberStore, csvStore } = useStore()
  // const canAddMemebers = canEditCommunityUsers()
  const [searchText, onChangeText] = React.useState<string>('')
  const canEditUsers = canEditCommunityUsers()
  const [membersList, setMembersList] = useState(members.orgMembersSorted)
  const userView = !canAddUserToCommunity()
  const [groupAppleUsersCount, setGroupAppleUsersCount] = useState(0)
  const [groupAndroidUsersCount, setGroupAndroidUsersCounte] = useState(0)

  useEffect(() => {
    let android = 0
    let ios = 0
    members.orgMembers.forEach(user => {
      if (user.app == AppType.IOS) {
        ios++
      } else if (user.app == AppType.ANDROID) {
        android++
      }
    })
    setGroupAppleUsersCount(ios)
    setGroupAndroidUsersCounte(android)
    setMembersList(members.orgMembersSorted)
  }, [members.orgMembersSorted])

  useEffect(() => {

    if (searchText === '') {
      setMembersList(members.orgMembersSorted)

    } else {
      const splitSearchText = searchText?.toLocaleLowerCase().split(' ')
      const filterdData = members.orgMembersSorted.filter((user: User) => {
        const splitFullName = [user.given_name?.trim().toLowerCase(), user.family_name?.trim().toLowerCase()]
        if (splitSearchText.every(el => splitFullName.some(el2 => el2.includes(el)))) {
          return true
        } else if (
          user.phones && user.phones.find((phone: string) => formatIfPhoneNumber(phone).includes(searchText)) ||
          user.phones && user.phones.find((phone: string) => formatIfPhoneNumber(phone).replaceAll('-', '').includes(searchText)) ||
          user.phones && user.phones.find((phone: string) => phone.includes(searchText))
        ) {
          return true
        } else if (
          user.emails && user.emails.find((email: string) => email.toLowerCase().includes(searchText.toLowerCase()))
        ) {
          return true
        } else if (
          user.nick_name && user.nick_name.toLowerCase().includes(searchText.toLowerCase())
        ) {
          return true
        }
        else if (
          user.is_operator && t`membersTable:communityManager`.toLowerCase().includes(searchText) ||
          user.is_director && t`membersTable:communityManager`.toLowerCase().includes(searchText)

        ) {
          return true
        }
      })
      setMembersList(filterdData)

    }
  }, [searchText, members.orgMembersSorted])

  //clear searchText on community change
  React.useEffect(() => {
    onChangeText('')
  }, [orgs.currentOrgId])

  const resetData = () => {
    uiStore.openWarningModal({
        text: `${t`remove:resetUser`}`,
        okAction: () => members.removeCache()
    })
  }

  return (
    <>
      {members.loadingMembers || membersList === undefined
        ? <SpinnerContainer><Spinner /></SpinnerContainer>
        : <Page key={orgs.currentOrgId + "alphon"} flex>
          <Content>
            <Row style={{ justifyContent: 'space-between', padding: '1rem' }}>
              <Row>
                <SearchContainer>
                  <Search onChange={onChangeText} backgroundColor={theme.color.black + '1A'} searchText={searchText} />
                </SearchContainer>
                {!isMobile() && (
                  <>
                    <Space />
                    {canEditUsers &&
                      <CounterContainer>
                        <MembersCounter hide disabledClick isContacts textColor={theme.color.black + 99} />
                        <Space />
                        <AppTypeContainer>
                          <SmallApple style={{ color: '#5eb0e5', fontSize: '1.1rem' }} />
                          <AppTypeText textcolor={theme.color.black + 99}>{`= ${(((groupAppleUsersCount / members.orgMembers.length) || 0) * 100).toFixed(0)}% `}</AppTypeText>
                          <Space width={0.5} />
                          <SmallAndroid style={{ color: '#78C257', fontSize: '1.1rem' }} />
                          <AppTypeText textcolor={theme.color.black + 99} >{`= ${(((groupAndroidUsersCount / members.orgMembers.length) || 0) * 100).toFixed(0)}% `}</AppTypeText>
                          <Space width={0.5} />
                          <SmallLaptopChromebook style={{ color: theme.color.black, fontSize: '1.1rem' }} />
                          <AppTypeText textcolor={theme.color.black + 99}>
                            {`= ${((((members.orgMembers.length - groupAndroidUsersCount - groupAppleUsersCount) / members.orgMembers.length) || 0) * 100).toFixed(0)}% `}
                          </AppTypeText>
                        </AppTypeContainer>
                      </CounterContainer>

                    }
                  </>
                )}
              </Row>
              {canExportCommunityUsers() && !userView
                ? <ButtonsContainer>
                  <Tooltip title={t`recycle` as string} arrow>
                    <div>
                      <IconButton style={{ backgroundColor: theme.color.azureBlue }} onClick={resetData}>
                        <img src={Recycle} />
                      </IconButton>
                    </div>
                  </Tooltip>
                  <Space height={0.5} />
                  <Tooltip title={t`export` as string} arrow>
                    <div>
                      <IconButton style={{ backgroundColor: theme.color.azureBlue }} onClick={() => {
                        saveCsv(members.orgMembers.sort((a, b) => !a.family_name ? 1 : (!b.family_name ? -1 : (a.family_name.localeCompare(b.family_name, 'he', { sensitivity: 'base' })))), `${t2`csvFileNameCommunity`}${orgs.currentOrg.name.split(' ').join('_')}`, t2)
                      }}>
                        <img src={ExportCSV} />
                      </IconButton>
                    </div>
                  </Tooltip>
                  <Space height={0.5} />
                  <Tooltip title={t`add` as string} arrow>
                    <div>
                      <IconButton
                        style={{ backgroundColor: theme.color.azureBlue }}
                        onClick={() => memberStore.openCreateModal()}><PersonAdd style={{ color: 'white' }} /></IconButton>
                    </div>
                  </Tooltip>

                </ButtonsContainer> : <Space width={1} />
              }
            </Row>
            <ListContainer>
              <List
                list={membersList}
                userView={userView}
                searchText={searchText}
              />
            </ListContainer>
          </Content>
          <MembersInGroupDialog />
        </Page>}
    </>
  )
}

export default observer(Contacts)
