import { Typography, TypographyProps } from '@material-ui/core'
import React, { FC } from 'react'
import styled from 'styled-components'
import { theme } from 'ui'


export type TextSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
export type TextWeight = 'thin' | 'light' | 'normal' | 'medium' | 'bold' | 'heavy'
// thin = 100 | light = 300 | normal = 300 | medium = 500 | bold = 700 | heavy = 900
interface TextProps {
  size?: TextSize
  weight?: TextWeight
  textcolor?: string
  opacity?: number
  textAlign?: string
}

const weights: { [key: string]: number } = {
  thin: 100,
  light: 300,
  normal: 400,
  medium: 500,
  bold: 700,
  heavy: 900
}

const sizes: { [key: string]: string } = {
  xxs: theme.fontSize.xxs,
  xs: theme.fontSize.xs,
  sm: theme.fontSize.sm,
  md: theme.fontSize.md,
  lg: theme.fontSize.lg,
  xl: theme.fontSize.xl,
  xxl: theme.fontSize.xxl
}

const BaseText = styled(Typography) <TextProps & TypographyProps>`
  font-weight: ${p => weights[p.weight || weights.normal]};
  font-size: ${p => sizes[p.size || sizes.md]};
  ${p => p.textcolor ? `color: ${p.textcolor};` : ''};
  word-spacing: normal;
  letter-spacing: normal;
  opacity:${p => p.opacity || null};
  text-align:${p => p.textAlign || null};
`

const Text: FC<TextProps & TypographyProps> = ({ children, ...props }) => {
  return (
    <BaseText {...props}>
      {children}
    </BaseText>
  )
}

export default Text
