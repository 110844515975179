import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Switch from '@material-ui/core/Switch'
import { Button, Table, TableBody, TableCell, TableRow, TableHead, Checkbox, IconButton,  Select, InputLabel, MenuItem } from '@material-ui/core'
import { theme } from 'ui'
import useStore from 'store'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import * as moment from 'moment'
import * as XLSX from 'xlsx';

const StyledSelect = styled(Select)`
  margin-left: 50px;
  &.MuiInput-underline:after{
    border: none !important
  };
  &.MuiInput-underline:before{
    border: none !important
  };
  & .MuiSelect-select.MuiSelect-select{
    padding-left: 24px;
    padding-right: 24px;
    background: #E0E0E0;
    padding-top: 9px;
    padding-bottom: 9px;
    border-radius: 4px;
  }
`
const ExportButton = styled(Button) `
    &.MuiButtonBase-root {
    font-size: 14px;
    font-weight: 500;
    flex: auto;
    min-width: 140px;
    margin: 0.5rem;
    background: #2A5BA8;
    &:hover {
      background: ${theme.color.darkBlue};
    }
    color: white;
    &.Mui-disabled {
      opacity: 0.5;
    }
  }
`

const CustomTableCell = ({ ...props }) => <TableCell size='small' {...props} />
export const SmallTableCell = styled(CustomTableCell)`
  overflow: hidden;
  /* max-width: 0px; */
  text-overflow: ellipsis;
`

const Actions = styled(SmallTableCell)`
  white-space: nowrap;
  overflow: hidden;
  /* max-width: 0px; */
  text-overflow: ellipsis;
`

export const NoWrap = styled(SmallTableCell)`
  white-space: nowrap;
  overflow: hidden;
  /* max-width: 0px; */
  text-overflow: ellipsis;
`

export const Wrap = styled.div`
  /* max-height: 100%; */
  max-height: max-content;
  /* height:200px; */
  overflow: auto;
  display:flex;
  flex:1;
`

export const TableCheckboxCell = styled(SmallTableCell)`
  font-weight: 400;
  background-color: ${p => p.hover ? theme.color.tableHover : 'transparent'};
  border-top-left-radius: ${p => p.hover ? '10px' : '0px'};
  border-bottom-left-radius: ${p => p.hover ? '10px' : '0px'};
  border-bottom: ${p => p.hover ? '1px solid white' : `1px solid ${theme.color.tableBorder} `};
  body[dir=rtl] & {
    border-top-left-radius: 0px;
   border-bottom-left-radius: 0px;
    border-top-right-radius: ${p => p.hover ? '10px' : '0px'};
    border-bottom-right-radius: ${p => p.hover ? '10px' : '0px'};
    }
`
export const TableFullnameCell = styled(SmallTableCell)`
  font-weight: 400;
  background-color: ${p => p.hover ? theme.color.tableHover : 'transparent'};
  border-top-left-radius: ${p => p.hover ? '10px' : '0px'};
  border-bottom-left-radius: ${p => p.hover ? '10px' : '0px'};
  border-bottom: ${p => p.hover ? '1px solid white' : `1px solid ${theme.color.tableBorder} `};
  body[dir=rtl] & {
    border-top-left-radius: 0px;
   border-bottom-left-radius: 0px;
    border-top-right-radius: ${p => p.hover ? '10px' : '0px'};
    border-bottom-right-radius: ${p => p.hover ? '10px' : '0px'};
    }
`
export const TableCenterCell = styled(NoWrap)`
  font-weight: 400;
  /* height: 48px; */
  background-color: ${p => p.hover ? theme.color.tableHover : 'transparent'};
  border-bottom: ${p => p.hover ? '1px solid white' : `1px solid ${theme.color.tableBorder} `};
`
export const TableButtonsCell = styled(NoWrap)`
  font-weight: 400;
  background-color: ${p => p.hover ? theme.color.tableHover : 'transparent'};
  border-top-right-radius: ${p => p.hover ? '10px' : '0px'};
  border-bottom-right-radius: ${p => p.hover ? '10px' : '0px'};
  border-bottom: ${p => p.hover ? '1px solid white' : `1px solid ${theme.color.tableBorder} `};
  body[dir=rtl] & {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: ${p => p.hover ? '10px' : '0px'};
    border-bottom-left-radius: ${p => p.hover ? '10px' : '0px'};
    }
`

const isPayloadJSON = (str) => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return false;
    }
  }

const DataRow = ({ row, poligonCoords, filterVal, checkedAll, onCheckedEvent }) => {
  const { t, i18n } = useTranslation('map')
  const { mapStore } = useStore()
  const [checked, setChecked] = useState(true)
  const payload = isPayloadJSON(row.payload);

  const toggleShowMarker = (checked) => {
    setChecked(checked)
    mapStore.toggleMarker(checked, row.userId)
    onCheckedEvent(checked, row.userId)
  }  

  useEffect(() => {
    if (row.userId in mapStore.checkedMarkers) {
      const defaultChecked = mapStore.checkedMarkers[row.userId]
      toggleShowMarker(defaultChecked)
    } else if (checkedAll.changeItems) {
      toggleShowMarker(checkedAll.value)
    }
  }, [checkedAll.value])

  const source = row.interfaceUsed 
    ? t(`source.${row.interfaceUsed.toLowerCase()}`) 
    : '' 

  const location = (row.lat && row.lng) 
    ? mapStore.containsLocation(row.lat, row.lng, poligonCoords) ? 'בישוב' : 'מחוץ לישוב' 
    : ''

  const message = row.note 
    ? row.note
    : (payload && 'text' in payload) ? payload.text : row.payload

  return (
    <TableRow>
      <TableCell width={200} key="checkbox">
        {filterVal == 'last_location' && 
        <div style={{paddingRight: '75px'}}>
          <Switch
            checked={checked}
            onChange={(e) => { toggleShowMarker(e.target.checked) }}
            color='primary'
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </div>}
      </TableCell>
      <TableCell key="date">{moment.utc(row.messageSentDate).local().format('DD/MM/YYYY HH:mm:ss')}</TableCell>
      <TableCell key="first_name">{row.givenName}</TableCell>
      <TableCell key="last_name">{row.familyName}</TableCell>
      <TableCell key="location">{location}</TableCell>
      <TableCell key="source">{source}</TableCell>
      <TableCell key="message">{message}</TableCell>
      <TableCell key="export"></TableCell>
    </TableRow>
  )
}

const Head = ({ data, handleFilter, filterVal, handleCheckedAll, checkedAll }) => {
  const { t, i18n } = useTranslation('map')
  const { mapStore } = useStore()

  const exportAction = () => {

    const xlsxData = [[
        t`table.date`,
        t`table.first_name`,
        t`table.last_name`,
        t`table.location`,
        t`table.source`,
        t`table.message`,
    ]]

    let poligonCoords = []

    if (mapStore.poligons.length > 0) {
      poligonCoords = mapStore.poligons[0].getPath().getArray().map(item => {
        return { lat: item.lat(), lng: item.lng() }
      })
    }

    data.map(row => {
        const payload = isPayloadJSON(row.payload);
        const message = row.note 
          ? row.note
          : (payload && 'text' in payload) ? payload.text : row.payload
        
        const location = (row.lat && row.lng) 
          ? mapStore.containsLocation(row.lat, row.lng, poligonCoords) ? 'בישוב' : 'מחוץ לישוב' 
          : ''  

        xlsxData.push([
            moment.utc(row.messageSentDate).local().format('DD/MM/YYYY HH:mm:ss'),
            row.givenName,
            row.familyName,
            location,
            row.interfaceUsed ? t(`source.${row.interfaceUsed.toLowerCase()}`) : '',
            message 
        ])
    })

    const workSheet = XLSX.utils.json_to_sheet(xlsxData, { skipHeader: true });
    const workBook = XLSX.utils.book_new();
    
    XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet 1");
    XLSX.writeFile(workBook, "map_log.xlsx");
  }

  return (
  <TableHead>
    <TableRow>
      <TableCell width={200}>
      {filterVal == 'last_location' && 
        <>
          {t`table.show_on_map`}
          <Switch
            checked={checkedAll.value}
            onChange={handleCheckedAll}
            color='primary'
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </>
      }
      </TableCell>
      <TableCell>{t`table.date`}</TableCell>
      <TableCell>{t`table.first_name`}</TableCell>
      <TableCell>{t`table.last_name`}</TableCell>
      <TableCell>{t`table.location`}</TableCell>
      <TableCell>{t`table.source`}</TableCell>
      <TableCell>{t`table.message`}</TableCell>
      <TableCell key="export" width={500}>
        <StyledSelect
          onChange={handleFilter}
          value={filterVal}>
          <MenuItem value={'all'}>{t`filter.all_logs`}</MenuItem>
          <MenuItem value={'last_location'}>{t`filter.users_with_last_location`}</MenuItem>
        </StyledSelect>
        <ExportButton onClick={() => exportAction()}>{t`table.export_to_xlsx`}</ExportButton>
      </TableCell>
    </TableRow>
  </TableHead>)
}

const Body = ({ data, filterVal, checkedAll, onCheckedEvent }) => {
  const { mapStore } = useStore()
  let poligonCoords = []

  if (mapStore.poligons.length > 0) {
    poligonCoords = mapStore.poligons[0].getPath().getArray().map(item => {
      return { lat: item.lat(), lng: item.lng() }
    })
  }

  return (<TableBody>
    {data.length > 0 
      ? data.map((row, i) => <DataRow key={`map_log_row_${i}`} row={row} filterVal={filterVal} poligonCoords={poligonCoords} checkedAll={checkedAll} onCheckedEvent={onCheckedEvent}/>) 
      : <TableRow><TableCell colSpan={7}>לא נמצאו דיווחים, ניתן להגדיל את תקופת התצוגה על מנת לראות יותר דיווחים.</TableCell></TableRow>}
  </TableBody>)
}

const LogTable: FC = () => {
  const { t, i18n } = useTranslation('map')
  const [filterVal, setFilterValue] = useState('last_location')
  const [checkedAll, setCheckedAll] = useState({ value: true, changeItems: true })
  const { mapStore } = useStore()

  const handleCheckedAll = (e) => {
    setCheckedAll({ value: e.target.checked, changeItems: true })
    mapStore.checkedMarkers = {}
  }

  const applyFilter = (data) => {
    mapStore.checkedItems = {}

    if (filterVal == 'all') {
      return data.sort((x, y) => new Date(y.messageSentDate) - new Date(x.messageSentDate));
    }

    const filteredDataObj = data.reduce((acc, item) => {
      if (!item.lat || !item.lng) {
        return acc
      }

      if (item.userId in acc) {
        const itemDate = moment(item.messageSentDate)
        if (itemDate > acc[item.userId].date) {
          acc[item.userId] = { item,  date: itemDate }  
        }
      } else {
        acc[item.userId] = { item,  date: moment(item.messageSentDate) }
      }

      return acc
    }, {})
   
    return mapStore.recentLocations.map(item => {
      let payload = ''

      if (item.userId in filteredDataObj && filteredDataObj[item.userId].item?.payload) {
        payload = filteredDataObj[item.userId].item.payload
      }

      return {
        userId: item.userId,
        givenName: item.givenName,
        familyName: item.familyName,
        interfaceUsed: item.interfaceUsed,
        lat: item.lat, 
        lng: item.lng,
        note: item.note,
        messageSentDate: item.updatedDate,
        payload: payload
      }
    }).sort((x, y) => new Date(y.messageSentDate) - new Date(x.messageSentDate))
  }
  
  const handleFilter = (e) => {
    setFilterValue(e.target.value)
  }

  const onCheckedEvent = (checked, userId) => {
    const checkedItems = mapStore.checkedItems
    
    checkedItems[userId] = checked
    mapStore.checkedItems = checkedItems 

    const notChecked = Object.values(checkedItems).filter(i => !i)
    
    setCheckedAll({ value: notChecked.length > 0 ? false : true, changeItems: false })
  }

  return (
  <>
    <Table>
      <Head 
        data={applyFilter(mapStore.logs)} 
        handleFilter={handleFilter} 
        handleCheckedAll={handleCheckedAll}
        checkedAll={checkedAll}
        filterVal={filterVal} 
      />
      <Body 
        data={applyFilter(mapStore.logs)} 
        filterVal={filterVal} 
        checkedAll={checkedAll}
        onCheckedEvent={onCheckedEvent}
      />
    </Table>
  </>
)
}
export default observer(LogTable)
