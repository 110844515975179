import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { theme, Text } from 'ui'
import useStore from 'store'
import { observer } from 'mobx-react'
import {
    useLocation
} from "react-router-dom";
import { Path } from 'core/Routes'
import { canEditCommunityModules } from 'utils/roles'
import { useTranslation } from 'react-i18next'
import IframePortal from '../ServiceCall/IframePortal'

const ServiceCallIframe: FC = () => {
    const { modulesStore } = useStore()
    const support = modulesStore?.communityModules.find(i => i.type === 'support')
    if (support) {
        return <IframePortal type='support' />
    }
    return <></>
}


export default observer(ServiceCallIframe)
