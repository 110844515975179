import React, { FC } from 'react'
import styled from 'styled-components'
import ProfilePage from 'components/ProfilePage'

import Appbar from './Appbar'
import Accessibility from './Accessibility'
import Emergency from './Emergency/Emergency'


const LayoutContainer = styled.div`
  display: flex;
  flex:1;
  flex-direction: column;
`

const Main = styled.div`
  display: flex;
  flex: 1;
`
const Content = styled.div`
  display: flex;
  flex-direction:row;
  flex:1;
  /* padding:0.5rem; */
  overflow:hidden;
`

const Layout: FC = ({ children }) => (
  <LayoutContainer>
    <Appbar />
    <Accessibility />
    <Emergency />
    <Content >
      <ProfilePage />
      <Main>
        {children}
      </Main>
    </Content>
  </LayoutContainer>
)

export default Layout
