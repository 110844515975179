import React, { FC } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { ListItem, ListItemIcon, Tooltip } from '@material-ui/core'
import useStore from 'store'
import { Info } from '@material-ui/icons'
import { Icon, Text, theme, Row, Space } from 'ui'
import { useHistory } from 'react-router'
import { Path } from 'core/Routes'
import { extractMessagePayload } from 'utils/messageUtils'
import { useTranslation } from 'react-i18next'
import { useLocation } from "react-router-dom";
import NotificationCounter from 'core/NotificationCounter'
import isMobile from 'utils/isMobile'
import LastMessageCamera from 'assets/icons/lastMessageCamera.svg'
import LastMessagePdf from 'assets/icons/lastMessagePdf.svg'
import LastMessageSurvey from 'assets/icons/lastMessageSurvey.svg'
import Alarm from 'assets/icons/alarm.svg'
import { userCanSeeAssociatedGroups } from 'utils/roles'
import { GroupType, McEntity } from 'utils/types'
import { isTotzeret } from 'service/whiteLable'

const Item = styled(ListItem) <{ selected: boolean }>`
  margin-bottom: 1rem;
  border-radius: 10px;
  box-shadow:${p => p.selected ? '0px 1px 1px 1px ' + p.color : 'none'};
  background-color: ${theme.color.white};
  max-width: calc(100vw - 2rem + 4px);

 &.MuiListItem-root.Mui-selected{
  background-color: white;
  border: 1px solid ${theme.color.black + 33};

 }
  &:hover{
    background-color: ${theme.color.sidebarHover};
  }
  &:after {
    content: "";
    display: flex;
    position: absolute;
    left: 0;
    right: 10px;
    bottom: 0;
    width: 95%;
    border-bottom: 1px solid ${theme.color.sidebarHover};
  }
  &:before {
    content: "";
    display: flex;
    position: absolute;
    left: 0;
    right: 10px;
    top: 0;
    width: 95%;
    border-bottom: 1px solid ${theme.color.sidebarHover};
  }
`

const IconBg = styled.div`
  height:60px;
  width:45px;
  background: ${p => isTotzeret ? 'transparent' : p.color};
  display: flex;
  align-items: ${isTotzeret ? 'center;' : 'flex-end;'}
  justify-content: center;
  padding:0.33rem 0;
  ${p => isTotzeret ? `border-left: 2px solid ${p.color}; border-radius: 0px;` : 'border-radius: 8px;'}
  /* margin-left:1rem; */
`

const ImgArrow = styled.div<{ color: string }>`
  border-style: solid;
  border-top-width: 7px;
  border-top-color: transparent;
  border-right-width: 8px;
  border-right-color: ${(p) => p.color};
  border-bottom-width: 7px;
  border-bottom-color: transparent;
  border-left-width: 0;
  border-left-color: transparent;
`

const InfoIcon = styled(Info)`

  color:${theme.color.blue};
`
const StyledTooltip = styled(Tooltip)`
    position: absolute;
    bottom: 0;
    right: 0;
`
const StyledLastMessage = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
const LastMessageText = styled(StyledLastMessage)`
  &::after{
    content: '.';
    visibility: hidden;
  }
`
const GroupHeaderContainer = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  flex:1;
  justify-content:space-between;
`

const StyledOverflowDiv = styled.div`
  overflow:hidden;
  flex:1;
`
enum IconName {
  survey,
  pdf,
  image,
  none
}

const GetIcon = ({ icon }: { icon: IconName }) => {
  switch (icon) {
    case IconName.survey:
      return <img src={LastMessageSurvey} />
    case IconName.pdf:
      return <img src={LastMessagePdf} />
    case IconName.image:
      return <img src={LastMessageCamera} />
    default:
      return null
  }
}


const Group: FC<{type: GroupType, defaultGroup?: boolean, description: { iconColor: string, icon: string }, color: string, mcEntity: McEntity, name: string, icon: string, id: number }> = (props) => {
  const { id, name, icon, defaultGroup, description, groupLabels, mcEntity, color, type } = props
  const { groups } = useStore()
  const h = useHistory()
  const { t } = useTranslation('sidebar')
  const onClick = () => {
    groups.setCurrentGroupType(type)
    groups.setCurrentGroupId(id)
    if (isMobile()) {
      if (type === GroupType.SUPPORT) {
        h.push(Path.SERVICECALL)
      } else {
        h.push(Path.MESSAGES)
      }
    }
  }
  const lastMessage = groups.lastMessages.get(id)
  let lastMessageText = ''
  let lsatMessageIcon = IconName.none
  if (lastMessage?.lastMessage) {
    const { text, mediaType } = extractMessagePayload(lastMessage?.lastMessage.message)
    if (text) {
      lastMessageText = text
    }
    if (mediaType === "IMAGE") {
      lsatMessageIcon = IconName.image
      if (lastMessageText === '') {
        lastMessageText = t`image`
      }
    }
    if (mediaType === "PDF") {
      lsatMessageIcon = IconName.pdf
      if (lastMessageText === '') {
        lastMessageText = t`file`
      }
    }
  }
  if (lastMessage?.lastMessage.message.msg_strategy.startsWith('QS')) {
    lsatMessageIcon = IconName.survey
    if (lastMessageText === '') {
      lastMessageText = t`survey`
    }
  }
  let location = useLocation();
  const serviceCall = location.pathname === Path.SERVICECALL
  const canSeeAssociatedGroups = Boolean(userCanSeeAssociatedGroups() && mcEntity && groupLabels && groupLabels?.length > 0)
  const isGroupAssociatedSupport = Boolean(props.providerId)
  return (
    <>
      <Item
        selected={groups.currentGroupId === id || (defaultGroup && !groups.currentGroupId) || false}
        color={description?.iconColor + 66 || '#d9d9d9'}
        button
        onClick={onClick}
      >
        {(canSeeAssociatedGroups || isGroupAssociatedSupport) &&
          <StyledTooltip title={
            <>
              {canSeeAssociatedGroups && <Text size='xs'>{t('groupDialog:associatedGroup', { groupName: groupLabels[0], concil: mcEntity })}</Text>}
              {isGroupAssociatedSupport && <Text size='xs'>{t('groupDialog:associatedSupport', { systemName: props.providerName || '' })}</Text>}
            </>
          } arrow>
            {/* <div> */}
            <InfoIcon />
            {/* </div> */}
          </StyledTooltip>
        }
        <ListItemIcon style={{ alignItems: 'center' }}>
            <IconBg color={color || description?.iconColor || '#d9d9d9'}>
              <Icon size={30} name={icon || description?.icon || 'default'} />
            </IconBg>
            {isTotzeret && <ImgArrow color={color || description?.iconColor || '#d9d9d9'}/>}
        </ ListItemIcon>
        <StyledOverflowDiv>
          <GroupHeaderContainer>
            <Text
              textcolor={theme.color.black}
              weight={'medium'}
              size={'lg'}
            >{name}</Text>
            {/* <Row style={{ alignItems: 'center', justifyContent: 'center', }}> */}
            {/* <Space width={0.5} /> */}
            {Boolean(lastMessage?.unread || 0) && <NotificationCounter number={lastMessage?.unread || 1} />}
            {/* </Row> */}
          </GroupHeaderContainer>
          <>
            {lastMessage ? (
              <>
                <StyledLastMessage
                  weight={'normal'}
                  size={'sm'}
                  textcolor={theme.color.black + 66}>{lastMessage.lastMessage.message.author_name}</StyledLastMessage>

                <Row justify='start' align='center'>
                  {lsatMessageIcon != IconName.none &&
                    <Row justify='start' align='center'>
                      <GetIcon icon={lsatMessageIcon} />
                      <div style={{ width: 5 }} />
                    </Row>}
                  <LastMessageText
                    textcolor={theme.color.black}
                    weight={'normal'}
                    size={'md'}
                  >{lastMessageText}</LastMessageText></Row>
              </>
            ) : (
              <>
                {!serviceCall && <StyledLastMessage textcolor={theme.color.osloGray}>{t`no_new_messages`}</StyledLastMessage>}
              </>
            )
            }
          </>
          {/* <StyledLastMessage textcolor={theme.color.CommunityYellow}>{lastMessage?.lastMessage.message.author_name}</StyledLastMessage> */}
        </StyledOverflowDiv>
      </Item>
    </>
  )
}

export default observer(Group)
