import React, { FC } from 'react'
import EmptyComponent from 'components/Empty'
import communityEmpty from 'assets/icons/communityEmpty.svg'
import { Image } from 'ui'
import useStore from 'store'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'


const Community: FC = () => {
    const { modulesStore } = useStore()
    const { t } = useTranslation(['budget'])
    const supportFound = modulesStore?.communityModules.find(i => i.type === 'portal')

    return (
        <>
            {supportFound ? <></> :
                <EmptyComponent
                    style={{ height: '100%' }}
                    icon={<Image src={communityEmpty} />}
                    title={'עוד מעט זה מגיע…'}
                    description={'בקרוב תוכלו להגיע מכאן למגוון שירותי הקהילה כמו זמני פתיחה, סידור רכב, הזמנת תורים וכן הלאה…'}
                />

            }
        </>
    )
}


export default observer(Community)