import React, {FC} from 'react'
import {observer} from 'mobx-react'
import {useTranslation} from 'react-i18next'
import {MapFormState} from './MapSettingsModal'
import styled from 'styled-components'
import {Space} from '../../ui'
import {Switch} from '@material-ui/core'
import TextField from "@material-ui/core/TextField";
import {
    ModalRow,
    InfoWrapper,
    ModalText,
    InputWrapper,
    InputContainer,
    OutlinedInput,
    ButtonWrapper,
    InputErrorText,
    ConfirmButton,
} from './styles'

const StyledTextField = styled(TextField)`
 width:100%;
 padding: 0 10px;
`

const GeoDataExample = styled.div`
  direction: ltr;
  color: grey;
  font-style: italic;
`

interface MapSectionProps {
    mapFormState: MapFormState
    mapFormValidState: any
    isMapFormChanged: boolean,
    onMapSettingsSave: () => void
    onMapSettingsLocationToggleEnable: (value: boolean) => void
    onMapSettingsEmergencyToggleEnable: (value: boolean) => void
    onMapSettingsToggleEnable: (value: boolean) => void
    onMapSettingsChangeAccuracy: (value: boolean) => void
    onMapSettingsChangeFromKM: (value: boolean) => void
    onMapSettingsChangeToKM: (value: boolean) => void
    onMapSettingsChangeLat: (value: boolean) => void
    onMapSettingsChangeLng: (value: boolean) => void
    onMapSettingsChangeZoom: (value: boolean) => void
    onCommunityChangeInfo: (value: boolean) => void
    onCommunityChangeGeoData: (value: boolean) => void

}

const MapSection: FC<MapSectionProps> = (props) => {
    const {t} = useTranslation('mapSettings')
    const {
        mapFormState,
        mapFormValidState,
        isMapFormChanged,
        onMapSettingsSave,
        onCommunityChangeInfo,
        onMapSettingsToggleEnable,
        onMapSettingsLocationToggleEnable,
        onMapSettingsEmergencyToggleEnable,
        onMapSettingsChangeAccuracy,
        onMapSettingsChangeFromKM,
        onMapSettingsChangeToKM,
        onMapSettingsChangeLat,
        onMapSettingsChangeLng,
        onMapSettingsChangeZoom,
        onCommunityChangeGeoData,
    } = props

    return (
        <>
            <ButtonWrapper>
                <ConfirmButton
                    disabled={!isMapFormChanged}
                    onClick={onMapSettingsSave}
                    transparent
                >{t`save`}
                </ConfirmButton>
            </ButtonWrapper>
            <Space/>
            <ModalRow>
                <InfoWrapper>
                    <>
                      <ModalText>{t`showMapTab`}:</ModalText>
                      <Space/>
                      <Switch
                        color="primary"
                        value={mapFormState.enabled}
                        onChange={({target}) => {
                            onMapSettingsToggleEnable(target.checked)
                        }}
                        checked={mapFormState.enabled}
                      />
                    </>
                    <>
                      <ModalText>{t`showEmergencyBtn`}:</ModalText>
                      <Switch
                        color="primary"
                        value={mapFormState.emergencyEnabled}
                        onChange={({target}) => {
                            onMapSettingsEmergencyToggleEnable(target.checked)
                        }}
                        checked={mapFormState.emergencyEnabled}
                      />
                    </>
                    <>
                      <ModalText>{t`showLocationRequestToogle`}:</ModalText>
                      <Switch
                        color="primary"
                        value={mapFormState.locationEnabled}
                        onChange={({target}) => {
                            onMapSettingsLocationToggleEnable(target.checked)
                        }}
                        checked={mapFormState.locationEnabled}
                      />
                    </>
                </InfoWrapper>
            </ModalRow>

            <InputWrapper>
                <InputContainer style={{ width: '100%' }}>
                    <StyledTextField
                        id={'lat'}
                        label={t`lat`}
                        variant="outlined"
                        value={mapFormState.lat}
                        onChange={({target}) => {
                            onMapSettingsChangeLat(target.value)
                        }}
                    />
                    {
                        !mapFormValidState['lat'].valid &&
                        <InputErrorText>{t`validationError`}</InputErrorText>
                    }
                </InputContainer>
                <InputContainer style={{ width: '100%' }}>
                <StyledTextField
                    id={'lng'}
                    variant="outlined"
                    label={t`lng`}
                    value={mapFormState.lng}
                    onChange={({target}) => {
                        onMapSettingsChangeLng(target.value)
                    }}
                />
                 {
                        !mapFormValidState['lng'].valid &&
                        <InputErrorText>{t`validationError`}</InputErrorText>
                    }
                 </InputContainer>
            </InputWrapper>
            <Space/>

            <InputWrapper>
                <InputContainer style={{ width: '100%' }}>
                    <StyledTextField
                        id={'accuracy'}
                        variant="outlined"
                        label={t`accuracy`}
                        value={mapFormState.accuracy}
                        onChange={({target}) => {
                            onMapSettingsChangeAccuracy(target.value)
                        }}
                    />
                     {
                        !mapFormValidState['accuracy'].valid &&
                        <InputErrorText>{t`validationError`}</InputErrorText>
                    }
                </InputContainer>
                <InputContainer style={{ width: '100%' }}>
                <StyledTextField
                    id={'zoom'}
                    variant="outlined"
                    label={t`zoom`}
                    value={mapFormState.zoom}
                    onChange={({target}) => {
                        onMapSettingsChangeZoom(target.value)
                    }}
                />
                 {
                        !mapFormValidState['zoom'].valid &&
                        <InputErrorText>{t`validationError`}</InputErrorText>
                    }
                 </InputContainer>
            </InputWrapper>
            <Space/>

            <InputWrapper>
                <InputContainer style={{ width: '100%' }}>
                    <StyledTextField
                        id={'from-km'}
                        variant="outlined"
                        label={t`fromKM`}
                        value={mapFormState.fromKM}
                        onChange={({target}) => {
                            onMapSettingsChangeFromKM(target.value)
                        }}
                    />
                    {
                        !mapFormValidState['fromKM'].valid &&
                        <InputErrorText>{t`validationError`}</InputErrorText>
                    }
                </InputContainer>
                <InputContainer style={{ width: '100%' }}>
                <StyledTextField
                    id={'to-km'}
                    variant="outlined"
                    label={t`toKM`}
                    value={mapFormState.toKM}
                    onChange={({target}) => {
                        onMapSettingsChangeToKM(target.value)
                    }}
                />
                {
                        !mapFormValidState['toKM'].valid &&
                        <InputErrorText>{t`validationError`}</InputErrorText>
                    }
                 </InputContainer>
                 <InputContainer style={{ width: '100%' }}>
                <StyledTextField
                    id={'more-info'}
                    variant="outlined"
                    label={t`moreInformation`}
                    value={mapFormState.moreInformation}
                    onChange={({target}) => {
                        onCommunityChangeInfo(target.value)
                    }}
                />
                 </InputContainer>
            </InputWrapper>
            <Space/>

            <GeoDataExample>
            {JSON.stringify({"coordinates": [[[-72.2777867,42.9282112],[-72.2781086,42.9255873]]]})}
            </GeoDataExample>
            <InputWrapper>
              <InputContainer style={{ width: '100%' }}>
                <StyledTextField
                    multiline
                    minRows={2}
                    style={{ direction: 'ltr' }}
                    id={'geo-data'}
                    variant="outlined"
                    label={t`geoData`}
                    value={mapFormState.geoData}
                    onChange={({target}) => {
                        onCommunityChangeGeoData(target.value)
                    }}
                />
                 </InputContainer>
            </InputWrapper>
            <a style={{ margin: "0px 15px" }} target="_blank" href="https://www.keene.edu/campus/maps/tool/">פתיחת כלי לציור גבולות הישוב</a>
            
        </>
    )
}

export default observer(MapSection)