import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import { Button, Dialog, TextField, Tooltip, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, IconButton, ListSubheader, Grid, Divider, GridList, GridListTile, Badge } from '@material-ui/core'
import { Space, theme, Row, Icon, Text, IconButton as UiIconButton } from 'ui'
import { Clear, CheckCircle, RadioButtonUnchecked, Remove, Add } from '@material-ui/icons'
import type { Group } from 'utils/types'
import type { User } from 'utils/models'

import useStore from 'store'
import Search from 'components/Search'
import { useTranslation } from 'react-i18next'
import addMembersIcon from 'assets/icons/addMembersIcon.svg'
import addMembersIconDisabled from 'assets/icons/addMembersIconDisabled.svg'

import { truncate } from 'utils/strings'
import { formatIfPhoneNumber } from 'utils/converts'
import WordsHighlighter from 'components/WordsHighlighter'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { UserGroup } from 'utils/types'
import { isTotzeret } from 'service/whiteLable'

interface StyledGridProps {
  withBottomRadios: boolean,
  withTopRadios: boolean
}

interface MemebersProps {
  groupMembers: User[],
  notInGroupMembers: User[],
  toggleSelectUser: (arg0: User) => void,
  mainColor: string
  searchText: string
}
interface SelectedMemebersProps {
  members: User[],
  toggleSelectUser: (arg0: User) => void
  mainColor: string
}
interface MemeberProps {
  isMember: boolean,
  member: User,
  changeStateAction: (arg0: User) => void,
  isFirstItem: boolean,
  isLastItem: boolean,
  iconColor: string,
  searchText: string
}
interface HeaderProps {
  group: Group,
  allMembersSize: number,
  groupSize: number,
  headerText: string,
  addText: string,
  onClose: () => void
}

const StyledGrid = styled(Grid) <{ withBottomRadios: boolean, withTopRadios: boolean }>`
  display: flex;
  flex-direction: column;
  align-items:center;
  padding-left:5px;
  padding-right:5px;
  background-color:${theme.color.whiteBackground};
  border-bottom-left-radius: ${(props: StyledGridProps) => props.withBottomRadios ? '10px' : '0'};
  border-bottom-right-radius: ${(props: StyledGridProps) => props.withBottomRadios ? '10px' : '0'};
  border-top-left-radius: ${(props: StyledGridProps) => props.withTopRadios ? '10px' : '0'};
  border-top-right-radius: ${(props: StyledGridProps) => props.withTopRadios ? '10px' : '0'};
`
const StyledDivder = styled(Divider)`
    height: 1px;
    width:98%;
    /* background-color:${theme.color.lightBlueBackground}; */
    align-self:center;
  `
const ScrollList = styled.div`
  flex: 1;
  display:flex;
  flex-direction:column;
  overflow:auto;
/* max-height:540px; */

`

const CircelsHeader = styled(Row)`
background-color: ${theme.color.whiteBackground};
align-items:center;
justify-content:flex-start;
padding:10px;
display:flex;
flex-wrap:wrap;
width:100%;
`
const HorzontalGridList = styled(GridList)`
    flex-wrap: nowrap;
    align-items:center;
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    /* transform: translateZ(0); */
`

const MemberInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items:flex-start;
`
const StyledMember = styled(ListItem)`
/* width:95%; */
  flex:1;
  display: flex;
  align-items:center;
  justify-content:center;
  padding: 0 12px 0 12px;
  /* background-color:${theme.color.lightBlueBackground}; */
`

const StyledInfo = styled(Row) <{ isLastItem: boolean }>`
  padding-right: 20px;
  border-bottom:2px solid ${theme.color.grayBackground};
  border-width:${p => p.isLastItem ? 0 : 1};
  /* align-items: center; */
  /* justify-content: flex-start; */
  /* background-color: ${theme.color.whiteBackground}; */
  /* border-radius:10px; */
`
const GroupContainer = styled.div`

  background-color: ${theme.color.whiteBackground};
  border-radius:10px;
`
const StyledCheckCircle = styled(CheckCircle)`
  color: ${theme.color.blueButton};
`
const StyledRadioButtonUnchecked = styled(RadioButtonUnchecked)`
  color: ${theme.color.blueButton};
`
const StyledListSubheader = styled(ListSubheader)`
  font-size: ${theme.fontSize.normal};
  font-weight: 600;
  color:${theme.color.black};
`

const StyledDialogContent = styled.div`
  padding:0;
  background-color:${theme.color.lightBlueBackground};
  height:80%;
`

const StyledDialogTitle = styled(DialogTitle)`
  background-color:${theme.color.whiteBackground};
  padding:15px;
`
const StyledHeaderContiner = styled.div`
  display: flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
`
const StyledHeaderNoramlText = styled.div`
  font-size: ${theme.fontSize.normal};
  color:${theme.color.azureBlue};
  font-weight: 600;
`

const StyledHeaderSmallText = styled.div`
  font-size: ${theme.fontSize.small};
  color:${theme.color.darkGray};
  font-weight: 600;
`
const StyledHeaderLargeText = styled.div`
  font-size: ${theme.fontSize.dialogHeader};
  color:${theme.color.black};
  font-weight: 600;
`
const StyledSearchContainer = styled.div`
  /* background-color:${theme.color.whiteBackground}; */
flex-direction:row;
width: 25vw;
  max-width:100%;
  `
const StyledHeaderInfoContiner = styled.div`
  display: flex;
  flex-direction:row;
  /* justify-content:flex-start; */
  /* align-items:center; */
`
const AddMembersIcon = styled.img`
  right: unset;
  left: 7px;
  top: unset;
  color: ${theme.color.azureBlue};
`
const IconBg = styled.div`
  background: ${p => isTotzeret ? 'transparent' : p.color};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0.33rem;
  ${p => isTotzeret ? `border-left: 2px solid ${p.color}; border-radius: 0px;` : 'border-radius: 8px;'}
`

const ImgArrow = styled.div<{ color: string }>`
  border-style: solid;
  border-top-width: 7px;
  border-top-color: transparent;
  border-right-width: 8px;
  border-right-color: ${(p) => p.color};
  border-bottom-width: 7px;
  border-bottom-color: transparent;
  border-left-width: 0;
  border-left-color: transparent;
`

const StyledListItemIcon = styled.div`
  margin: 0 9px 20px 9px ;
`

const TextContainer = styled.div`
  display: flex;
  justify-content:center;
  align-items: center;
  flex:1;
`
const StyledIconButton = styled(IconButton)`
  padding:0;
  width:10px;
  height:10px;
`
const StyledRemove = styled(Remove)`
color:white;
`
const Circel = styled.div<{ mainColor }>`
    background-color: ${p => p.mainColor + 40};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  flex-direction:row;
  justify-content:center;
  align-items: center;
`

const StyledBadge = styled(Badge)`
  .MuiBadge-colorPrimary{
    background-color:${theme.color.osloGray};
  }
`

const StyledCircleLargeText = styled.div`
  font-size: ${theme.fontSize.dialogHeader};
  color:${theme.color.black};
  font-weight: 300;
`

const StyledCircleSmallText = styled.div`
  margin-top:6px;
  font-size: ${theme.fontSize.verySmall};
  color:${theme.color.black};
  font-weight: 500;
`


const MembersList: FC<MemebersProps> = (props) => {
  const toggleSelectUser = async (member: User) => await props.toggleSelectUser(toJS(member))

  const allMemebersSorted = props.notInGroupMembers.sort(function (a: User, b: User) {
    if (a.family_name < b.family_name) { return -1 }
    if (a.family_name > b.family_name) { return 1 }
    return 0
  })
  const allMemebersGroupByFirstLetter = allMemebersSorted.sort((a, b) => !a.family_name ? 1 : (!b.family_name ? -1 : (a.family_name.localeCompare(b.family_name, 'he', { sensitivity: 'base' })))).reduce((result, member) => {
    const letter = member.family_name[0]
    result[letter] = result[letter] || []
    result[letter].push(member)
    return result
  }, {})

  return (
    <ScrollList subheader={<li />} style={{ padding: 10 }}>
      {Object.keys(allMemebersGroupByFirstLetter).map((key: string) => {
        return (
          <>
            <StyledListSubheader disableSticky>{`${key === 'undefined' ? '' : key}`}</StyledListSubheader>
            <GroupContainer>

              {allMemebersGroupByFirstLetter[key].map((member: User, index: number) => {
                const groupMember = props.groupMembers.find((gmember) => {
                  return gmember.user_id == member.user_id
                })
                return (
                  <Member
                    searchText={props.searchText}
                    iconColor={props.mainColor} key={member.user_id} member={member} changeStateAction={(u: User) => {
                      if (groupMember != null) {
                        toggleSelectUser(u)
                      }
                      else {
                        toggleSelectUser(u)
                      }
                    }} isMember={groupMember != null} isFirstItem={index == 0}
                    isLastItem={index == allMemebersGroupByFirstLetter[key].length - 1}></Member>
                )
              })
              }
            </GroupContainer>
          </>
        )
      })}
    </ScrollList>
  )
}

const PhonesContainer = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
  text-align:center;
    word-break:break-word;
    flex:1;
    flex-direction:column;
  `
const Member: FC<MemeberProps> = ({ member, changeStateAction, isMember, isFirstItem, isLastItem, iconColor, searchText }) => {
  const name = `${member.family_name} ${member.nick_name ? '(' + member.nick_name + ')' : ''} ${member.given_name}`

  let phones: Array<string> = []
  if (member.phones) {
    phones = [...member.phones]
  }
  if (member.emails) {
    phones = [...phones, ...member.emails]
  }
  return (
    <StyledInfo
      onClick={() => changeStateAction(member)}
      isLastItem={isLastItem}>
      <div
        style={{ width: '10%', alignItems: 'center', justifyContent: 'center', display: 'flex' }}

      >
        <IconButton
          edge='end'
        >
          {isMember ? <CheckCircle style={{ color: iconColor }} /> : <RadioButtonUnchecked style={{ color: iconColor }} />}
        </IconButton>
      </div>
      <Text
        style={{ width: '30%' }}
        textcolor={theme.color.black}
        size='xl'
        weight='normal'
      >{<WordsHighlighter
        searchText={searchText}
        text={name || ''} />}</Text>

      <PhonesContainer >
        {phones.map(i => <Text
          textcolor={theme.color.black + 66}
          weight={'normal'}
          size={'xl'}>{<WordsHighlighter
            searchText={searchText}
            text={formatIfPhoneNumber(i) || ''} />}</Text>)}
      </PhonesContainer>
    </StyledInfo>

  )
}

const ButtonContainer = styled(Text)`
  display:flex;
  flex-direction:column;
`
const ClearButton = styled(Button)`
  .MuiButton-label{
   justify-content:flex-end;
  }
`
//TODO: remove Header (VVV)
const Header: FC<HeaderProps> = ({ group, allMembersSize, groupSize, headerText, addText, onClose, onOk }) => {
  return (
    <StyledDialogTitle>
      <StyledHeaderContiner>
        <StyledHeaderInfoContiner>
          <StyledListItemIcon style={{ alignItems: 'center' }}>
            <IconBg color={group.group.description?.iconColor || '#d9d9d9'}><Icon name={group.icon || group.group.description?.icon || 'default'} /></IconBg>
            {isTotzeret && <ImgArrow color={group.group.description?.iconColor || '#d9d9d9'}/>}
          </ StyledListItemIcon>
          <div>
            <Text
              textcolor={theme.color.black + 'A1'}
              size='sm'
              weight='normal'
            >
              {`${group?.group.name}`}
            </Text>
            <Text
              textcolor={theme.color.black}
              size='xxl'
              weight='bold'
            >
              {`${headerText}`}
            </Text>
            <Text
              textcolor={theme.color.black + 80}
              size='sm'
              weight='medium'
            >
              {`${groupSize}/${allMembersSize}`}
            </Text>
          </div>
        </StyledHeaderInfoContiner>
        <ButtonContainer>
          <ClearButton startIcon={<Clear />} onClick={onClose} />
          <Space />
          <Button startIcon={<AddMembersIcon src={addMembersIcon} />} onClick={onOk} >{<StyledHeaderNoramlText>{addText}</StyledHeaderNoramlText>}</Button>
        </ButtonContainer>
      </StyledHeaderContiner>
    </StyledDialogTitle>
  )
}

const SelectedMembersList: FC<SelectedMemebersProps> = (props) => {
  const toggleSelectUser = async (member: User) => await props.toggleSelectUser(toJS(member))

  return (
    <CircelsHeader>
      {props.members && props.members.length > 0 && props.members.map((user) => {
        return (
          <div>
            {user && <div style={{ margin: 13 }} key={user.user_id}  >
              <StyledBadge color='primary' overlap="circle" badgeContent={
                <StyledIconButton aria-label="delete" onClick={() => { toggleSelectUser(user) }}  >
                  <StyledRemove />
                </StyledIconButton>
              } anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }} >
                {
                  <Circel mainColor={props.mainColor}>
                    <StyledCircleLargeText>{(user.given_name[0] ? user.given_name[0] : '') + (user.family_name[0] ? user.family_name[0] : '')}</StyledCircleLargeText>
                  </Circel>
                }
              </StyledBadge>
              <StyledCircleSmallText>{truncate(user.given_name, 11)}</StyledCircleSmallText>
            </div>
            }
          </div>
        )
      })}
    </CircelsHeader>
  )
}
const DialogContainer = styled(Dialog)`
  .MuiPaper-root{
    height:80%;
  }
`

interface Props {
  onClose: any
}

const AddMembersToGroup: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation('app')
  const { members, groups, snackBar } = useStore()
  const [selectedUsers, setselectedUsers] = React.useState<User[]>([])
  const [searchText, onChangeText] = React.useState<string>('')
  const splitSearchText = searchText.toLocaleLowerCase().split(' ')
  const membersLeft = members.orgMembers.filter(member => !groups.groupMembers.find(gmember => gmember.user === member.user_id))
  const mainColor = groups.currentGroup?.group?.description?.fabColor || '#000000'
  const [filterdData, setFilterdData] = useState<User[]>([])
  const [importMembersGroup, setImportMembersGroup] = useState<UserGroup | null>(null)

  const handleChangeImport = async (_: any, v: UserGroup | null) => {
    setImportMembersGroup(v)
    if (v?.group?.id) {
      let groupUsers: User[] = await groups.getGroupMembers(v?.group.id || 0)
      const membersNotInGroup = groupUsers.filter(member => (!groups.groupMembers.find(gmember => gmember.user === member.user_id) && !selectedUsers.find(gmember => gmember.user_id === member.user_id)))
      if (membersNotInGroup.length === 0) {
        snackBar.showError(t`members:allUsersInGroup`)
      } else {
        setselectedUsers(prev => [...prev, ...membersNotInGroup])
      }
    }
  }

  useEffect(() => {
    if (searchText === '') {
      setFilterdData(membersLeft)
    } else {
      const splitSearchText = searchText?.toLocaleLowerCase().split(' ')



      const filterdData = membersLeft.filter((user: User) => {
        const splitFullName = [user.given_name?.trim().toLowerCase(), user.family_name?.trim().toLowerCase()]
        if (splitSearchText.every(el => splitFullName.some(el2 => el2.includes(el)))) {
          return true
        } else if (
          user.phones && user.phones.find((phone: string) => formatIfPhoneNumber(phone).includes(searchText)) ||
          user.phones && user.phones.find((phone: string) => formatIfPhoneNumber(phone).replaceAll('-', '').includes(searchText)) ||
          user.phones && user.phones.find((phone: string) => phone.includes(searchText))
        ) {
          return true
        } else if (
          user.emails && user.emails.find((email: string) => email.toLowerCase().includes(searchText))
        ) {
          return true
        } else if (
          user.nick_name && user.nick_name.toLowerCase().includes(searchText)
        ) {
          return true
        }
      })
      setFilterdData(filterdData)

    }
  }, [searchText])

  const toggleSelectUser = (user: User) => {
    const sUser = selectedUsers.find(smember => smember.user_id === user.user_id)
    if (selectedUsers.length == 0 || sUser == null) {
      setselectedUsers(prev => [...prev, user])
    }
    else {
      setselectedUsers(selectedUsers.filter((a: User) => a.user_id != sUser.user_id))
    }
  }

  const handelOkPress = () => {
    groups.addMembers(selectedUsers)
    setselectedUsers([])
    onClose()
  }
  return (
    <>
      <Row justify='between' style={{ marginRight: 10, marginLeft: 10 }}>
        <Row>
          <StyledSearchContainer>
          {membersLeft.length != 0 && <Search
            onChange={onChangeText}
            backgroundColor={theme.color.whiteBackground + 'CC'}
            searchText={searchText}
          />}
          </StyledSearchContainer>
          <Space width={0.5} />
        {groups.currentGroup?.group.group_is_member === false &&
          <Autocomplete
            style={{ width: 200}}
            value={importMembersGroup}
            onChange={handleChangeImport}
            options={groups.currentUserGroups.filter(ug => (ug.group.group_is_member === false && ug.group.members > 0))}
            placeholder={t`groupDialog:importMembers`}
            renderOption={option => option?.group?.name}
            getOptionLabel={option => option?.group?.name}
            renderInput={(params) =>
              <TextField
                {...params} value={importMembersGroup || ''} label={t`groupDialog:importMembers`} variant="outlined" />}
          />
          }
          </Row>
        <Button
          disabled={selectedUsers.length === 0}
          startIcon={<AddMembersIcon src={selectedUsers.length === 0 ? addMembersIconDisabled : addMembersIcon} />}
          onClick={handelOkPress} >
          {<Text
            size={'lg'}
            textcolor={selectedUsers.length === 0 ? theme.color.azureBlue + 33 : theme.color.azureBlue}
            weight={'medium'}
          >{t`add`}</Text>}
        </Button>
        {/* <ClearButton startIcon={<ChevronLeft style={{ fontSize: 30 }} />} onClick={onClose} /> */}
      </Row>
      <Space height={0.5} />
      {selectedUsers.length != 0 && <SelectedMembersList mainColor={mainColor} members={selectedUsers} toggleSelectUser={toggleSelectUser} ></SelectedMembersList>}
      <Space height={0.5} />
      {(membersLeft.length > 0 && filterdData.length === 0) && <TextContainer>
        <Text
          size={'xl'}
          textcolor={theme.color.black + 66}
          weight={'medium'}
        >{t`notFound`}</Text></TextContainer>}
      {membersLeft.length === 0 ? <TextContainer>
        <Text
          size={'xl'}
          textcolor={theme.color.black + 66}
          weight={'medium'}
        >{t`allMembers`}</Text></TextContainer> : <MembersList
          mainColor={mainColor}
          notInGroupMembers={toJS(filterdData)}
          groupMembers={selectedUsers}
          searchText={searchText}
          toggleSelectUser={toggleSelectUser}></MembersList>}
    </>
  )
}

export default observer(AddMembersToGroup)
