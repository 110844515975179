import type { User } from 'utils/models'
import { UserGroup } from './types'

export const getMembersAndManagersCount = (members: UserGroup[]) => {
  let membersCount = members?.length || 0
  let managersCount = members?.reduce((count, currentMember) => currentMember.userData?.is_director || currentMember.userData?.is_operator ? count + 1 : count, 0) || 0
  return { members: membersCount, managers: managersCount }
}

export const sortPeriod = (periods:[number, number][]) => {
  const yearIndex = 0
  const monthIndex = 1
  return periods.slice().sort((a, b) => {
    if(a[yearIndex] < b[yearIndex]){
      return -1
    }
    if(a[yearIndex] > b[yearIndex]){
      return 1
    }
    if(a[monthIndex] < b[monthIndex]){
      return -1
    }
    if(a[monthIndex] > b[monthIndex]){
      return 1
    }
    return 0
  }).reverse()
}

export const onlyUniqueArrays = (data: number[][]) => data.filter((period, periodIndex, allPeriods) => (
  allPeriods.findIndex((allPeriodElement) => (
    allPeriodElement.reduce((accum, current, i) => accum && current === period[i], true)
  )) === periodIndex
))