import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { theme, Text, Row, Image, TextSize, TextWeight } from 'ui'
import { useTranslation } from 'react-i18next'
import pigIcon from 'assets/icons/budgetIcon.svg'
import { Dialog, Menu as MuiMenu, MenuItem, } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import useStore from 'store'
import { observer } from 'mobx-react'
import { ModuleEnum } from 'utils/types'
import { canEditCommunityModules } from 'utils/roles'
import ComingSoonAd from './ComingSoonAd'

const SideComponentMenu = styled(Row)`
    justify-content:center;
    align-items:center;
    display:flex;
    z-index:2000px;
    position:absolute;
    border-radius:12px;
    left:0;
    width:40px;
    height:40px;
    background-color:${theme.color.white};
    cursor:pointer;
`
const BannerContainer = styled.div<{ backgroundColor: string, isEmbedded: boolean, isButton: boolean }>`
    display:flex;
    flex-direction:row;
    /* align-items:center;
    justify-content:center; */
    background-color:${p => p.backgroundColor};
    ${p => !p.isEmbedded && `border-radius:10px;`}
    ${p => p.isEmbedded && `border-bottom-right-radius:0px; border-bottom-left-radius:0px;`}
    align-self: center;
    ${p => p.isEmbedded ? `align-self: stretch` : `width:60vw`};
    justify-self:center;
    ${p => !p.isEmbedded && 'margin:10px;'}
    border:1px solid ${theme.color.black + 33};
    ${p => !p.isEmbedded && `box-shadow: 0 2px 8px 0 ${theme.color.shadow};`}
    cursor:${p => p.isButton ? 'pointer' : 'unset'};
    overflow:hidden;
`
const IconWrapper = styled.div`
    background-color:${theme.color.budgetPurple};
    width:20%;
    max-width:150px;
    display:flex;
    align-items:center;
    justify-content:center;
    /* border-bottom-right-radius:10px;
    border-top-right-radius:10px; */
`
const TextContainer = styled.div`
    display:flex;
    flex:1;
    flex-direction:column;
    padding:10px;
`
export const Editor: React.FC = () => {
  const { modulesStore } = useStore()
  const { t } = useTranslation(['budget'])
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const hasBudget = Boolean(modulesStore.budgets?.length)
  const openActionMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    event.stopPropagation()
    return null;
  }
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null)
    event.stopPropagation()
  }
  const editSlika = () => {
    const slika = modulesStore?.communityModules.filter(i => i.type === ModuleEnum.SLIKA)
    modulesStore.openModuleModalEditMode(slika[0].module_id)
  }
  const createSlika = () => {
    modulesStore.openModuleModalSlikaMode()
  }
  return (
    <div>
      <SideComponentMenu
        onClick={openActionMenu}
        aria-controls="third-menu"
      ><ExpandMore />
      </SideComponentMenu>
      <MuiMenu
        id="third-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={hasBudget ? editSlika : createSlika}>
          {hasBudget ? t`edit` : t`app:add`}
        </MenuItem>
      </MuiMenu>
    </div>
  )
}

interface IBudgetButton {
  onClick?: () => void
  textcolor: string
  headerWeight?: TextWeight
  footerWeight?: TextWeight
  headerSize?: TextSize
  footerSize?: TextSize
  headerText: string
  footerText: string
  canEdit: boolean
  backgroundColor: string
  isEmbedded?: boolean
}
export const BudgetButton: React.FC<IBudgetButton> = ({ isEmbedded = false, canEdit, onClick, textcolor, backgroundColor, footerSize, footerWeight, headerSize, headerWeight, headerText, footerText }) => {
  const [showActionArrow, setShowActionArrow] = useState(false)
  const onEnter = () => setShowActionArrow(true)
  const onLeave = () => setShowActionArrow(false)
  return (
    <BannerContainer
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
      backgroundColor={backgroundColor}
      isEmbedded={isEmbedded}
      isButton={Boolean(onClick)}
    >
      <IconWrapper onClick={onClick} >
        <Image style={{ height: 55 }} src={pigIcon} />
      </IconWrapper>
      <TextContainer onClick={onClick}>
        <Text
          textcolor={textcolor}
          weight={headerWeight}
          size={headerSize}
        >{headerText}</Text>
        <Text
          textcolor={textcolor}
          weight={footerWeight}
          size={footerSize}
        >{footerText}</Text>
      </TextContainer>
      {/* {canEdit && type && showActionArrow && <div style={{ position: 'relative'}}> */}
      {canEdit && showActionArrow && <div style={{ position: 'relative' }}>
        {/*hide edit slika org ID*/}
        {/*<Editor />*/}
      </div>}
    </BannerContainer>
  )
}
const SlikaModule: FC = () => {
  const { t } = useTranslation(['budget'])
  const { modulesStore, budgetStore } = useStore()
  const hasBudget = Boolean(modulesStore.budgets?.length)
  const canEdit = canEditCommunityModules()
  const headerText = hasBudget ? t`personalBudgetTitle` : t`bannerTitle`
  const footerText = hasBudget ? t`SeeBudgethere` : t`bannerDescription`
  const [budgetAdDialogOpen, setBudgetAdDialogOpen] = useState(false)

  const handleOpenAd = () => {
    setBudgetAdDialogOpen(true)
  }
  const closeAd = () => {
    setBudgetAdDialogOpen(false)
  }
  const handleBudgetClick = () => {
    if (hasBudget) {
      budgetStore.openBudgetLogin()
    }
    else {
      handleOpenAd()
    }
  }
  return (
    <>
      <Dialog open={budgetAdDialogOpen} onBackdropClick={closeAd}>
        <ComingSoonAd onClose={closeAd} />
      </Dialog>
      <BudgetButton
        onClick={handleBudgetClick}
        textcolor={theme.color.black}
        headerText={headerText}
        headerSize='xl'
        headerWeight='bold'
        footerText={footerText}
        footerWeight='normal'
        footerSize='lg'
        backgroundColor={hasBudget ? theme.color.whiteBackground : theme.color.budgetPink}
        canEdit={canEdit}
      />
    </>
  )
  // return (
  //   <BannerContainer
  //     onMouseEnter={onEnter}
  //     onMouseLeave={onLeave}
  //     type={hasBudget}
  //   >
  //     <IconWrapper onClick={() => budgetStore.openBudgetLogin()} >
  //       <Image style={{ height: 55 }} src={pigIcon} />
  //     </IconWrapper>
  //     <TextContainer onClick={() => budgetStore.openBudgetLogin()}>
  //       <Text
  //         textcolor={theme.color.black}
  //         weight={'bold'}
  //         size={'xl'}
  //       >{hasBudget ? t`personalBudgetTitle` : t`bannerTitle`}</Text>
  //       <Text
  //         textcolor={theme.color.black}
  //         weight={'normal'}
  //         size={'lg'}
  //       >{hasBudget ? t`SeeBudgethere` : t`bannerDescription`}</Text>
  //     </TextContainer>
  //     {/* {canEdit && type && showActionArrow && <div style={{ position: 'relative'}}> */}
  //     {canEdit && showActionArrow && <div style={{ position: 'relative' }}>
  //       <Editor />
  //     </div>}
  //   </BannerContainer>
  // )
}


export default observer(SlikaModule)
