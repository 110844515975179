import React from 'react'
import useStore from 'store'
import { observer } from 'mobx-react'
import { useParams } from 'react-router'
import PaymentTransactions from './PaymentTransactions'
import { sortByDate } from '../../../utils/date'

const PosTransactions: React.FC = () => {
    let { id }: { id: string } = useParams()
    const { budgetStore } = useStore()
    const payment = budgetStore.pos()[Number(id)]
    const transactions = sortByDate('dd/MM/yyyy HH:mm', payment.transactions, 'dateOfCharge')
    return (
        <PaymentTransactions
            payment={payment}
            transactions={transactions}
            title={payment.name.he}
            total={payment.total}
        />
    )
}

export default observer(PosTransactions)