import {observable, action, computed, IObservableArray} from 'mobx'
import api from 'service/api'
import type {ModuleType} from 'utils/types'
import orgs from 'store/orgs.store'

interface warningType {
    text?: string
    secondText?: string
    okAction?: () => void
    cancelAction?: () => void
    onBackdropClick?: () => void
    removeCancelButton?: boolean
    okText?: string
    cancelText?: string
}


class UiStore {

    constructor() {
    }

    @observable warningModalFlag: boolean = false
    @observable warningObject: warningType = {}

    @action openWarningModal(info: warningType) {
        this.warningObject = info
        this.warningModalFlag = true

    }

    @action closeWarningModal() {
        this.warningModalFlag = false

    }

}

const uiStore = new UiStore()
export default uiStore
