import React, {FC} from 'react'
import {FormControl, InputLabel, MenuItem, Select, Switch} from '@material-ui/core'
import {Row, Space} from '../../ui'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import Grow from 'ui/Grow'
import {ConfirmButtonSm, DeleteButtonSm, DialogHeaderText, ModalText, ModalRow, ButtonGroup} from './styles'
import {observer} from 'mobx-react'
import {SupportFormState} from './CommunitySettingsModal'
import {IObservableArray} from "mobx";
import { Provider } from 'utils/types'

const StyledSelect = styled(Select)`
  .MuiSelect-iconOutlined {
    left: 7px;
    right: unset;
  }
`
const CustomSelect = styled(FormControl)`
  max-width: 210px;
  width: 100%;
`
const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 5px;
  white-space: nowrap;
`

interface SupportSectionProps {
    onSupportSave: () => void
    isSupportFormChanged: boolean
    supportFormState: SupportFormState
    onChangeProvider: (e: any) => void
    onSupportToggleVisible: (e: boolean) => void
    onDeleteProvider: () => void
    providers: IObservableArray<Provider>
    orgProvider: Provider | null
}


const SupportSection: FC<SupportSectionProps> = (props) => {
    const {t} = useTranslation('communitySettings')
    const {
        isSupportFormChanged,
        supportFormState,
        onSupportToggleVisible,
        onChangeProvider,
        onSupportSave,
        onDeleteProvider,
        providers,
        orgProvider,
    } = props

    return (
        <>
        <Space/>
            <ModalRow>
              <DialogHeaderText>{t`supportTab`}</DialogHeaderText>
              <ButtonGroup>
                <DeleteButtonSm
                    disabled={!orgProvider}
                    onClick={onDeleteProvider}>
                    {t`common:deletion`}
                </DeleteButtonSm>
                <ConfirmButtonSm
                    disabled={!isSupportFormChanged}
                    onClick={onSupportSave}>
                    {t`budget:save`}
                </ConfirmButtonSm>
              </ButtonGroup>
            </ModalRow>
            
            <InfoWrapper style={{justifyContent: 'start'}}>
                <ModalText>{t`visible`}:</ModalText>
                <Switch
                    color="primary"
                    onChange={({target}) => {
                        onSupportToggleVisible(target.checked)
                    }}
                    checked={supportFormState.isVisible}
                />
            </InfoWrapper>

            <InfoWrapper style={{justifyContent: 'start'}}>
                <CustomSelect variant="outlined">
                    <InputLabel id="SystemSelectLabel">{t('associateDialog:externalSystem')}</InputLabel>
                    <StyledSelect
                        labelId="SystemSelectLabel"
                        id="SystemSelect"
                        value={supportFormState.providerId}
                        onChange={onChangeProvider}
                        label={t('associateDialog:externalSystem')}
                        fullWidth
                    >
                        <MenuItem value={-1} key={'empty'} disabled>
                            {t`budget:none`}
                        </MenuItem>
                        {providers.map(prov => (
                            <MenuItem value={prov.providerId} key={prov.providerId}>
                                {prov.providerName}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                </CustomSelect>
            </InfoWrapper>
            <Space/>
        </>
    )
}

export default observer(SupportSection)
