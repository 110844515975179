import React, { FC, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Button, Dialog, List } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { theme, Text, Row, Space, Image } from 'ui'
import { observer } from 'mobx-react'
import useStore from 'store'
import MessagesHeader from 'components/Messages/MessagesHeader'
import MessageItem from './MessagesFeed/MessageItem'
import { UserMessage } from 'utils/models'
import { isAfter } from 'date-fns'
import CalendarIcon from 'assets/icons/event_create.svg'
import { promiseMaker } from 'utils/promiseMaker'
import { getTitleForDeliveryType } from 'utils/messageUtils'
import { DeliveryType } from './MessagesDialog'
import CalendarIconTotzeret from 'assets/TotzeretCalendarIcon';
import { TOTZERET_NAME, TotzeretColorsByCategory, WHITE_LABLE_APP_NAME } from 'service/whiteLable'

const StyledDialog = styled(Dialog)`
`
const ContainerDialog = styled.div<{ color: string, alignCenter: boolean }>`
    width:600px;
    @media (max-width: 768px) {
      width: unset;
    }
    justify-content:flex-start;
    flex-direction:column;
    display:flex;
    ${p => p.alignCenter && 'flex:1;'}
    background-color:${p => p.color + '1A'};
    padding:20px 20px;
    .ReactCollapse--collapse {
        transition: height 500ms;
        }
        overflow:auto;
        height:80vh;
  `
const MessageList = styled(List)`
  & li > ul {
    padding: 0;
  }
  display:flex;
  flex:1;
  flex-direction:column;
  overflow: auto;

`

const StyledButton = styled(Button) <{ borderColor: string }>`
  border-color:${p => p.borderColor};
  color:${p => p.borderColor};
  border-width:1px;
`
const Wrap = styled.div`
padding: 1px 1rem;
display:flex;
flex:1;
`
const PointerEndOfList = styled.div`
 float: left;
 clear:both;
`
const GreenDot = styled.div`
  width:4px;
  height:4px;
  background-color:green;
  border-radius:2px;
  margin:8px;
`
const StyledEmptySchedule = styled.div`
    padding: 32px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    align-items:center;
`
const Container = styled.div`
    display:flex;
    width:400px;
    height:200px;
    justify-content:space-around;
    align-items:stretch;
    flex-direction:column;
    padding:20px;
    word-break: break-word;

`
export interface IModal {
  isOpen: boolean,
  title: string,
  backText: string,
  buttonText: string,
  confirmColor?: string,
  declineColor?: string
  promise: {
    promise: Promise<any>;
    res: any;
    rej: any;
  }
}
interface ModalProps {
  onConfirm: () => void,
  onDecline: () => void,
  onClose: () => void,
  isOpen: boolean
  title: string
  buttonText: string
  backText: string
  confirmColor?: string
  declineColor?: string
}

const renderEmpthyIcon = (name: string) => {
  switch (WHITE_LABLE_APP_NAME) {
    case TOTZERET_NAME:
      const colors = TotzeretColorsByCategory[name as 'blue'] || TotzeretColorsByCategory.default;
      return <CalendarIconTotzeret color={colors.main} backgroundColor={colors.secondary}/>
    default:
      return <Image src={CalendarIcon} />
  }
}

const WarningDialog: FC<ModalProps> = ({ isOpen, onConfirm, onDecline, onClose, title, buttonText, backText, declineColor = 'red', confirmColor = 'green' }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Container>
        <Text align='center' weight='bold'>{title}</Text>
        <Space grow />
        <Row >
          <StyledButton
            style={{ flex: 2 }}
            variant='outlined'
            borderColor={declineColor}
            onClick={onDecline}>{backText}</StyledButton>
          <Space grow />
          <StyledButton
            style={{ flex: 2 }}
            borderColor={confirmColor}
            variant='outlined'
            onClick={onConfirm}>{buttonText}</StyledButton>
        </Row>
      </Container>
    </Dialog>
  )
}
const MessagesFeed: React.FC<{ closeModal: () => void }> = observer(({ closeModal }) => {
  const { messages, groups, newMessage } = useStore()
  const { t } = useTranslation('messages')
  const [modalData, setModalData] = useState<IModal>({
    isOpen: false,
    title: '',
    backText: '',
    buttonText: '',
    promise: promiseMaker(),
  })
  const scheduleButtonColor = groups.currentGroup?.group.description.fabColor || groups.defaultGroupDescription.fabColor
  const scrollableListRef = useRef<HTMLDivElement | null>(null)
  const list = messages.groupMessages.filter((el: any) => !isAfter(new Date(), el.message.schedule || 0))
  const groupIsEmpty = list.length === 0

  useEffect(() => {
    if (!groupIsEmpty && messages.scrollFeed) {
      scrollableListRef.current?.scrollIntoView({ behavior: 'smooth' })
      messages.ScrollFeedToEnd(false)
    }
  }, [messages.scrollFeed])
  const onModalConfirm = () => {
    modalData.promise.res(true)
  }
  const onModalDecline = () => {
    modalData.promise.res(false)
  }
  const toggleModal = () => {
    setModalData((prev) => {
      prev.isOpen = false
      return { ...prev }
    })
  }
  const publishMessage = (userMessage: UserMessage) => {
    const myPromise = promiseMaker()
    setModalData(
      {
        isOpen: true,
        title: getTitleForDeliveryType(DeliveryType.CustomDateTime, new Date(userMessage.message.expiry || 0), new Date(userMessage.message.schedule || 0), t),
        backText: t('nevermind'),
        buttonText: t('publish'),
        promise: myPromise,
        confirmColor: theme.color.blue,
        declineColor: theme.color.darkGray
      }
    )
    myPromise.promise.then((hasConfirmed: boolean) => {
      if (hasConfirmed) {
        const date = new Date()
        userMessage.message.schedule = date;
        userMessage.message.created = date;
        newMessage.sendMessageAgain(userMessage)
        closeModal()
      }
      toggleModal()
    }).catch(() => {
      toggleModal();
    })

  }
  const editMessage = (messageId: number) => {

    newMessage.setCurrentUserMessage(messageId)
    messages.openNewMessageDialog()

    // closeModal()
  }
  return (
    <>
      {groupIsEmpty ? (
        <StyledEmptySchedule>
          {renderEmpthyIcon(groups.currentGroup?.group.description?.subCategoryKey)}
          <Space height={2} />
          <Text align='center' weight='heavy' size={'xxl'}>{t("scheduledEmptyHeader")}</Text>
          <Space height={2} />
          <Text align='center' weight='bold' size={'md'} textcolor={theme.color.black + '80'}>{t("scheduledEmptyDescription1")}</Text>
          <GreenDot />
          <Text align='center' weight='bold' size={'md'} textcolor={theme.color.black + '80'}>{t("scheduledEmptyDescription2")}</Text>
        </StyledEmptySchedule>
      )
        :
        <Wrap>
          <MessageList id='message-list' subheader={<li />}>
            <PointerEndOfList ref={scrollableListRef}></PointerEndOfList>
            {list?.map((userMessage: UserMessage) => {
              return <MessageItem
                key={`item-${userMessage.message.create_guid}`}
                userMessage={userMessage}
                scheduleButtonColor={scheduleButtonColor}
                fullWidth
                publishMessage={() => publishMessage(userMessage)}
                onScheduleEditPressed={() => editMessage(userMessage.message.id)}
              />
            })}
          </MessageList>
        </Wrap>
      }
      <WarningDialog
        isOpen={modalData.isOpen}
        onClose={onModalDecline}
        onConfirm={onModalConfirm}
        onDecline={onModalDecline}
        title={modalData.title}
        backText={modalData.backText}
        buttonText={modalData.buttonText}
        confirmColor={modalData.confirmColor}
        declineColor={modalData.declineColor}
      />
    </>
  )
})
const MessageDetailsModal: FC = ({ }) => {
  const { messages, groups } = useStore()
  const { t } = useTranslation('messages')
  const open = messages.messageScheduleModalOpen
  const [] = useState([])
  const closeModal = () => {
    messages.setMessageScheduleModalOpen(false)
  }
  const [] = useState(true)

  const groupColor = groups.currentGroup?.group.description?.color || groups.defaultGroupDescription.color
  const isEmpty = messages.groupMessages.filter((el) => !isAfter(new Date(), el.message.schedule || 0)).length === 0
  return (
    <StyledDialog
      open={open}
      onClose={closeModal}
    >
      <MessagesHeader
        textVarient={'black'}
        backColor={groupColor + '1A'}
        mainText={groups?.currentGroup?.group?.name}
        secondText={t`scheduledMessages`}
        onClose={closeModal}
      />
      <ContainerDialog color={groupColor} alignCenter={isEmpty}>
        <MessagesFeed closeModal={closeModal} />
      </ContainerDialog>
    </StyledDialog>
  )
}

export default observer(MessageDetailsModal)


