import React, { FunctionComponent, ReactText } from 'react'
import styled from 'styled-components'

interface IProps {
  width?: ReactText,
  height?: ReactText
  grow?: number
}

const StyledGrow = styled.div`
  display:flex;
  flex:1;
`

const Grow: FunctionComponent<IProps> = ({ ...props }) => <StyledGrow {...props} />

export default Grow
