import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { AppBar, Toolbar, Tooltip } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { Face } from '@material-ui/icons'
import { Menu, MenuItem, Button, ListItem, Icon } from '@material-ui/core'
import { ExitToApp } from '@material-ui/icons'
import packageJson from '../../../package.json'
import useStore from 'store'
import { Space, Image, theme, Row } from 'ui'
import logo from 'assets/logo.png'
import logoIcon from 'assets/icons/Logo.svg'
import logoIconTotzeret from 'assets/TotzeretSubLogoColored.png'
import userIcon from 'assets/icons/UserLogo.svg'
import { canCreateCommunity } from 'utils/roles'
import Langs from 'components/Languages'
import { useTranslation } from 'react-i18next'
import Tabs from 'pages/Home/Tabs'
import OrgsSearch from 'components/OrgsSearch/OrgsSearch'
import { Add } from '@material-ui/icons'
import SuperUserMenu from './SuperUserMenu'
import { TOTZERET_NAME, WHITE_LABLE_APP_NAME, isTotzeret } from 'service/whiteLable'

const AddCommunity = styled.div`
  width: 30px;
  height: 30px;
  background-color: #ffdb1c;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
  margin: 0px 7px 0px 7px;
`
const StyledAdd = styled(Add)`
  display: block;
  margin: auto;
`
const Communities = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
`
const SmallText = styled.div`
  color: ${theme.color.white};
  font-size: ${theme.fontSize.small};
`

const NewCommunityContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const StyledAppBar = styled(AppBar)`
  box-shadow: none;
  background-color: ${isTotzeret ? 'white' : 'var(--dark-back)'};
  color: ${isTotzeret ? '#394248' : 'var(--white)'};
`
const StyledTopbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  color: ${isTotzeret ? '#394248' : 'var(--white)'};
  /* @media (max-width: 768px) {
    flex-direction: column;
  } */
`

interface Props { }

const MenuButton = styled(Button)`
  color: ${isTotzeret ? '#394248' : 'var(--white)'};
  font-size: 1.2rem;
  background: rgba(255,255,255,0.05);
  :hover {
    background: rgba(255,255,255,0.1);
  }
  padding: 4px 24px;
`
const UserContainer = styled.div`
  display:flex;
  flex-direction: row;
  :hover {
    cursor: pointer;
  }
`
const UserText = styled.div`
 font-size: ${theme.fontSize.small};
`
// const Logo = styled.img`
//   height: 6vh;
//   padding: 1rem;
// `
const Logo = styled.img`
`

const UserIcon = styled(Image)`
  padding: 0 20px 0 20px;
`

const renderLogoByBrand = () => {
  switch (WHITE_LABLE_APP_NAME) {
    case TOTZERET_NAME:
      return <Logo style={{ width: 96, height: 50 }} width={96} height={50} src={logoIconTotzeret} alt='Totzeret logo' />
    default:
      return <Logo src={logoIcon} alt='Mekome logo' />
  }
}

const Appbar: FC<Props> = () => {
  const { t } = useTranslation('app')
  const { user, members, orgs } = useStore()
  const userCanCreateCommunity = canCreateCommunity()
  const [anchorEl, setAnchorEl] = useState(null)
  // const handleClick = (event: any) => setAnchorEl(event.currentTarget)
  const handleClick = () => { user.toggleUserProfile() }

  const handleClose = () => setAnchorEl(null)
  const { nick_name, given_name, family_name } = user.user
  const fullName = `${given_name} ${nick_name ? '(' + nick_name + ')' : ''} ${family_name}`

  return (
    <StyledAppBar id='app-bar' position='static'>
      <StyledTopbar>
        {/* <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => edit(user?.user_id)}>{t`profile`}</MenuItem>
            <MenuItem onClick={() => logout()}><ExitToApp /><Space width={0.75} />{t`logout`}</MenuItem>
          </Menu> */}
        {/* <MenuButton onClick={handleClick} startIcon={<Icon ><Image src={userIcon} /></Icon>}>{`${t`hi`}, ${name}!`}</MenuButton> */}
        <Row
          style={{ cursor: 'pointer' }}
          onClick={handleClick}>
          <UserText>{`${t`hi`}, ${fullName}!`}</UserText>
          <UserIcon src={userIcon} />
        </Row>
        <Space />
        <OrgsSearch />
        <Space />
        <Tabs />
        <Space grow />
        <Communities>
          {userCanCreateCommunity && <SuperUserMenu />}
        </Communities>
        
        <Space width={1} />
        <Tooltip title={<span style={{ fontSize: "15px" }}>{packageJson.version}</span>}>
          {renderLogoByBrand()}
        </Tooltip>
      </StyledTopbar>
    </StyledAppBar>
  )
}

export default observer(Appbar)
