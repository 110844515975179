export default ({color = '#183d71', backgroundColor = '#fff'}) => (
<svg width="85" height="85" style={{ height: 85, width: 85 }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140">
  <g>
    <path fill={color} d="m102.23,15.8h-6.44v8.59c0,1.18-.97,2.15-2.15,2.15s-2.15-.97-2.15-2.15v-8.59H22.81v8.59c0,1.18-.97,2.15-2.15,2.15s-2.15-.97-2.15-2.15v-8.59h-6.44c-4.74,0-8.59,3.84-8.59,8.59v15.02h107.32v-15.02c0-4.74-3.84-8.59-8.59-8.59Z"/>
    <path fill={color} d="m3.5,43.71v55.8c0,4.74,3.84,8.59,8.59,8.59h90.15c4.74,0,8.59-3.84,8.59-8.59v-55.8H3.5Zm25.76,51.51c0,1.18-.97,2.15-2.15,2.15h-8.59c-1.18,0-2.15-.97-2.15-2.15v-8.59c0-1.18.97-2.15,2.15-2.15h8.59c1.18,0,2.15.97,2.15,2.15v8.59Zm0-17.17c0,1.18-.97,2.15-2.15,2.15h-8.59c-1.18,0-2.15-.97-2.15-2.15v-8.59c0-1.18.97-2.15,2.15-2.15h8.59c1.18,0,2.15.97,2.15,2.15v8.59Zm17.17,17.17c0,1.18-.97,2.15-2.15,2.15h-8.59c-1.18,0-2.15-.97-2.15-2.15v-8.59c0-1.18.97-2.15,2.15-2.15h8.59c1.18,0,2.15.97,2.15,2.15v8.59Zm0-17.17c0,1.18-.97,2.15-2.15,2.15h-8.59c-1.18,0-2.15-.97-2.15-2.15v-8.59c0-1.18.97-2.15,2.15-2.15h8.59c1.18,0,2.15.97,2.15,2.15v8.59Zm0-17.17c0,1.18-.97,2.15-2.15,2.15h-8.59c-1.18,0-2.15-.97-2.15-2.15v-8.59c0-1.18.97-2.15,2.15-2.15h8.59c1.18,0,2.15.97,2.15,2.15v8.59Zm17.17,34.34c0,1.18-.97,2.15-2.15,2.15h-8.59c-1.18,0-2.15-.97-2.15-2.15v-8.59c0-1.18.97-2.15,2.15-2.15h8.59c1.18,0,2.15.97,2.15,2.15v8.59Zm0-17.17c0,1.18-.97,2.15-2.15,2.15h-8.59c-1.18,0-2.15-.97-2.15-2.15v-8.59c0-1.18.97-2.15,2.15-2.15h8.59c1.18,0,2.15.97,2.15,2.15v8.59Zm0-17.17c0,1.18-.97,2.15-2.15,2.15h-8.59c-1.18,0-2.15-.97-2.15-2.15v-8.59c0-1.18.97-2.15,2.15-2.15h8.59c1.18,0,2.15.97,2.15,2.15v8.59Zm17.17,0c0,1.18-.97,2.15-2.15,2.15h-8.59c-1.18,0-2.15-.97-2.15-2.15v-8.59c0-1.18.97-2.15,2.15-2.15h8.59c1.18,0,2.15.97,2.15,2.15v8.59Zm17.17,0c0,1.18-.97,2.15-2.15,2.15h-8.59c-1.18,0-2.15-.97-2.15-2.15v-8.59c0-1.18.97-2.15,2.15-2.15h8.59c1.18,0,2.15.97,2.15,2.15v8.59Z"/>
    <path fill={color} d="m22.81,11.51c0-1.18-.97-2.15-2.15-2.15s-2.15.97-2.15,2.15v4.29h4.29v-4.29Z"/>
    <path fill={color} d="m95.79,11.51c0-1.18-.97-2.15-2.15-2.15s-2.15.97-2.15,2.15v4.29h4.29v-4.29Z"/>
  </g>
  <g>
    <circle fill={backgroundColor} stroke={color} stroke-miterlimit="10" stroke-width="3px" cx="103.84" cy="98.98" r="31.66"/>
    <g>
      <path fill={color} d="m99.12,113.82s.12.59.31,1.17c.59,1.76,2.26,3.03,4.22,3.03s3.63-1.27,4.22-3.03q.19-.57.31-1.17"/>
      <path fill={color} d="m120.44,109.97l-4.57-3.67v-10.58c0-5.65-4.02-10.42-9.39-11.7v-1.28c0-1.54-1.29-2.81-2.86-2.81s-2.86,1.26-2.86,2.81v1.27c-5.4,1.26-9.44,6.05-9.44,11.71v10.54h-.02l-4.13,3.79c-.89,1.21.09,2.8,1.72,2.8h29.91c1.68,0,2.65-1.69,1.65-2.89"/>
      <path fill={color} d="m91.97,82.96c-6.37,3.07-8.21,9.96-7.61,16.53.18,1.94,3.07-.01,2.93-1.54-.44-4.78.46-9.97,5.17-12.24,1.57-.76,1.61-3.76-.49-2.75Z"/>
      <path fill={color} d="m115.71,82.96c-2.1-1.01-2.06,2-.49,2.75,4.71,2.27,5.61,7.46,5.17,12.24-.14,1.53,2.76,3.48,2.93,1.54.6-6.57-1.24-13.46-7.61-16.53Z"/>
    </g>
  </g>
</svg>)
