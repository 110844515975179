
import { Typography } from '@material-ui/core'
import type { TFunction } from 'i18next'
import React, { FC } from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

interface TranslateProps {
  t: TFunction
  i18nKey: string
  onClick?: any
}


const Text = styled(Typography)`
  display: inline-block;
`

const Text100 = styled(Text)`font-weight: 100;`
const Text200 = styled(Text)`font-weight: 200;`
const Text300 = styled(Text)`font-weight: 300;`
const Text400 = styled(Text)`font-weight: 400;`
const Text500 = styled(Text)`font-weight: 500;`
const Text600 = styled(Text)`font-weight: 600;`
const Text700 = styled(Text)`font-weight: 700;`
const Text800 = styled(Text)`font-weight: 800;`
const Text900 = styled(Text)`font-weight: 900;`

const Translate: FC<TranslateProps> = ({ t, i18nKey }) => (
  <Trans
    t={t}
    i18nKey={i18nKey}
    components={{
      b100: <Text100 />,
      b200: <Text200 />,
      b300: <Text300 />,
      b400: <Text400 />,
      b500: <Text500 />,
      b600: <Text600 />,
      b700: <Text700 />,
      b800: <Text800 />,
      b900: <Text900 />
    }}
  />
)

export default Translate