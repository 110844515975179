import React, { FC } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, IconButton, ListSubheader, Grid, Divider, GridList, GridListTile, Badge } from '@material-ui/core'
import { Space, theme, Row, Icon, Text } from 'ui'
import { Clear, CheckCircle, RadioButtonUnchecked, Remove } from '@material-ui/icons'
import type { Group } from 'utils/types'
import type { User } from 'utils/models'

import useStore from 'store'
import Search from 'components/Search'
import { useTranslation } from 'react-i18next'
import addMembersIcon from 'assets/icons/addMembersIcon.svg'
import { truncate } from 'utils/strings'
import { formatIfPhoneNumber } from 'utils/converts'
import { isTotzeret } from 'service/whiteLable'
interface StyledGridProps {
  withBottomRadios: boolean,
  withTopRadios: boolean
}
interface Props { }
interface MemebersProps {
  groupMembers: User[],
  notInGroupMembers: User[],
  toggleSelectUser: (arg0: User) => void
}
interface SelectedMemebersProps {
  members: User[],
  toggleSelectUser: (arg0: User) => void
}
interface MemeberProps {
  isMember: boolean,
  member: User,
  changeStateAction: (arg0: User) => void,
  isFirstItem: boolean,
  isLastItem: boolean,
}
interface HeaderProps {
  group: Group,
  allMembersSize: number,
  groupSize: number,
  headerText: string,
  addText: string,
  onClose: () => void
}

const StyledGrid = styled(Grid) <{ withBottomRadios: boolean, withTopRadios: boolean }>`
  display: flex;
  flex-direction: column;
  align-items:center;
  padding-left:5px;
  padding-right:5px;
  background-color:${theme.color.whiteBackground};
  border-bottom-left-radius: ${(props: StyledGridProps) => props.withBottomRadios ? '10px' : '0'};
  border-bottom-right-radius: ${(props: StyledGridProps) => props.withBottomRadios ? '10px' : '0'};
  border-top-left-radius: ${(props: StyledGridProps) => props.withTopRadios ? '10px' : '0'};
  border-top-right-radius: ${(props: StyledGridProps) => props.withTopRadios ? '10px' : '0'};
`
const StyledDivder = styled(Divider)`
    height: 1px;
    width:98%;
    background-color:${theme.color.lightBlueBackground};
    align-self:center;
  `
const ScrollList = styled(List)`
  flex: 1;
  max-height: 60vh;
  overflow: auto;
  background-color:${theme.color.lightBlueBackground};
`
const CircelsHeader = styled.div`
background-color: ${theme.color.whiteBackground};
`
const HorzontalGridList = styled(GridList)`
    flex-wrap: nowrap;
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    /* transform: translateZ(0); */
`

const MemberInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items:flex-start;
`
const StyledMember = styled(ListItem)`
/* width:95%; */
  flex:1;
  display: flex;
  align-items:center;
  justify-content:center;
  padding: 0 12px 0 12px;
  background-color:${theme.color.lightBlueBackground};
`

const StyledInfo = styled(Grid)`
  padding-right: 11px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  background-color: ${theme.color.whiteBackground};
  border-radius:10px;
`
const StyledCheckCircle = styled(CheckCircle)`
  color: ${theme.color.blueButton};
`
const StyledRadioButtonUnchecked = styled(RadioButtonUnchecked)`
  color: ${theme.color.blueButton};
`
const StyledListSubheader = styled(ListSubheader)`
  font-size: ${theme.fontSize.normal};
  font-weight: 600;
  color:${theme.color.black};
`

const StyledDialogContent = styled(DialogContent)`
  padding:0;
  background-color:${theme.color.lightBlueBackground};
  height:80%;
`

const StyledDialogTitle = styled(DialogTitle)`
  background-color:${theme.color.whiteBackground};
  padding:15px;
`
const StyledHeaderContiner = styled.div`
  display: flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
`
const StyledHeaderNoramlText = styled.div`
  font-size: ${theme.fontSize.normal};
  color:${theme.color.azureBlue};
  font-weight: 600;
`

const StyledHeaderSmallText = styled.div`
  font-size: ${theme.fontSize.small};
  color:${theme.color.darkGray};
  font-weight: 600;
`
const StyledHeaderLargeText = styled.div`
  font-size: ${theme.fontSize.dialogHeader};
  color:${theme.color.black};
  font-weight: 600;
`
const StyledSearchContainer = styled.div`
  padding: 0 9px 0 9px ;
  background-color:${theme.color.whiteBackground};

`
const StyledHeaderInfoContiner = styled.div`
  display: flex;
  flex-direction:row;
  /* justify-content:flex-start; */
  /* align-items:center; */
`
const AddMembersIcon = styled.img`
  right: unset;
  left: 7px;
  top: unset;
  color: ${theme.color.azureBlue};
`
const IconBg = styled.div`
  background: ${p => isTotzeret ? 'transparent' : p.color};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0.33rem;
  ${p => isTotzeret ? `border-left: 2px solid ${p.color}; border-radius: 0px;` : 'border-radius: 8px;'}
`

const ImgArrow = styled.div<{ color: string }>`
  border-style: solid;
  border-top-width: 7px;
  border-top-color: transparent;
  border-right-width: 8px;
  border-right-color: ${(p) => p.color};
  border-bottom-width: 7px;
  border-bottom-color: transparent;
  border-left-width: 0;
  border-left-color: transparent;
`

const StyledListItemIcon = styled.div`
  margin: 0 9px 20px 9px ;
`

const StyledCell = styled.div`
  height:90px;
  width:76px;
  display: flex;
  flex-direction:column;
  justify-content:center;
  align-items: center;
`
const StyledIconButton = styled(IconButton)`
  padding:0;
  width:10px;
  height:10px;
`
const StyledRemove = styled(Remove)`
color:white;
`
const Circel = styled.div`
  background-color:${theme.color.lightGrayBackground};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  flex-direction:row;
  justify-content:center;
  align-items: center;
`

const StyledBadge = styled(Badge)`
  .MuiBadge-colorPrimary{
    background-color:${theme.color.osloGray};
  }
`

const StyledCircleLargeText = styled.div`
  font-size: ${theme.fontSize.dialogHeader};
  color:${theme.color.black};
  font-weight: 300;
`

const StyledCircleSmallText = styled.div`
  margin-top:6px;
  font-size: ${theme.fontSize.verySmall};
  color:${theme.color.black};
  font-weight: 500;
`


const MembersList: FC<MemebersProps> = (props) => {
  const toggleSelectUser = async (member: User) => await props.toggleSelectUser(toJS(member))

  const allMemebersSorted = props.notInGroupMembers.sort(function (a: User, b: User) {
    if (a.given_name < b.given_name) { return -1 }
    if (a.given_name > b.given_name) { return 1 }
    return 0
  })
  const allMemebersGroupByFirstLetter = allMemebersSorted.reduce((result, member) => {
    const letter = member.given_name[0]
    result[letter] = result[letter] || []
    result[letter].push(member)
    return result
  }, {})

  return (
    <ScrollList subheader={<li />}>
      {Object.keys(allMemebersGroupByFirstLetter).map((key: string) => {
        return (
          <>
            <StyledListSubheader>{`${key}`}</StyledListSubheader>
            {
              allMemebersGroupByFirstLetter[key].map((member: User, index: number) => {
                const groupMember = props.groupMembers.find((gmember) => {
                  return gmember.user_id == member.user_id
                })
                return (
                  <Member key={member.user_id} member={member} changeStateAction={(u: User) => {
                    if (groupMember != null) {
                      toggleSelectUser(u)
                    }
                    else {
                      toggleSelectUser(u)
                    }
                  }} isMember={groupMember != null} isFirstItem={index == 0} isLastItem={index == allMemebersGroupByFirstLetter[key].length - 1}></Member>
                )
              })
            }
          </>
        )
      })}
    </ScrollList>
  )
}


const Member: FC<MemeberProps> = ({ member, changeStateAction, isMember, isFirstItem, isLastItem }) => {
  return (
    <StyledMember button={true} >
      <StyledGrid container withBottomRadios={isLastItem} withTopRadios={isFirstItem}>
        <StyledInfo container item   >
          <MemberInfo>
            <Text
              textcolor={theme.color.black}
              size='xxl'
              weight='medium'
            >{`${member.given_name} ${member.family_name} ` || 'Unknown'}</Text>
            <Text
              textcolor={theme.color.black + 'A1'}
              size='md'
              weight='normal'
            >{formatIfPhoneNumber(member?.phones[0]) || member?.emails[0] || ''}</Text>
          </MemberInfo>
          <>
            <IconButton edge='end' onClick={() => changeStateAction(member)}>
              {isMember ? <StyledCheckCircle /> : <StyledRadioButtonUnchecked />}
            </IconButton>
          </>
        </StyledInfo>
        {!isLastItem && <StyledDivder />}
      </StyledGrid>
    </StyledMember>
  )
}

const ButtonContainer = styled(Text)`
  display:flex;
  flex-direction:column;
`
const ClearButton = styled(Button)`
  .MuiButton-label{
   justify-content:flex-end;
  }
`
const Header: FC<HeaderProps> = ({ group, allMembersSize, groupSize, headerText, addText, onClose, onOk }) => {
  return (
    <StyledDialogTitle>
      <StyledHeaderContiner>
        <StyledHeaderInfoContiner>
          <StyledListItemIcon style={{ alignItems: 'center' }}>
            <IconBg color={group.group.description?.iconColor || '#d9d9d9'}><Icon name={group.icon || group.group.description?.icon || 'default'} /></IconBg>
            {isTotzeret && <ImgArrow color={group.group.description?.iconColor || '#d9d9d9'}/>} 
          </StyledListItemIcon>
          <div>
            <Text
              textcolor={theme.color.black + 'A1'}
              size='sm'
              weight='normal'
            >
              {`${group?.group.name}`}
            </Text>
            <Text
              textcolor={theme.color.black}
              size='xxl'
              weight='bold'
            >
              {`${headerText}`}
            </Text>
            <Text
              textcolor={theme.color.black + 80}
              size='sm'
              weight='medium'
            >
              {`${groupSize}/${allMembersSize}`}
            </Text>
          </div>
        </StyledHeaderInfoContiner>
        <ButtonContainer>
          <ClearButton startIcon={<Clear />} onClick={onClose} />
          <Space />
          <Button startIcon={<AddMembersIcon src={addMembersIcon} />} onClick={onOk} >{<StyledHeaderNoramlText>{addText}</StyledHeaderNoramlText>}</Button>
        </ButtonContainer>
      </StyledHeaderContiner>
    </StyledDialogTitle>
  )
}

const SelectedMembersList: FC<SelectedMemebersProps> = (props) => {
  const toggleSelectUser = async (member: User) => await props.toggleSelectUser(toJS(member))

  return (
    <CircelsHeader >
      <HorzontalGridList cols={6} cellHeight={90} spacing={0}>
        {props.members && props.members.length > 0 && props.members.map((user) => {
          return (
            <div>
              {user && <GridListTile key={user.user_id}  >
                <StyledCell>
                  <StyledBadge color='primary' overlap="circle" badgeContent={
                    <StyledIconButton aria-label="delete" onClick={() => { toggleSelectUser(user) }}  >
                      <StyledRemove />
                    </StyledIconButton>
                  } anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }} >
                    {
                      <Circel>
                        {user.given_name != null && user.family_name && <StyledCircleLargeText>{user.given_name[0] + user.family_name[0]}</StyledCircleLargeText>}
                      </Circel>
                    }
                  </StyledBadge>
                  <StyledCircleSmallText>{truncate(user.given_name, 11)}</StyledCircleSmallText>
                </StyledCell>
              </GridListTile>
              }
            </div>
          )
        })}
      </HorzontalGridList>
    </CircelsHeader>
  )
}
const DialogContainer = styled(Dialog)`
  .MuiPaper-root{
    height:80%;
  }
`



const MembersInGroupDialog: FC<Props> = () => {
  const { t } = useTranslation('app')
  const { t: tMembers } = useTranslation('members')
  const { members, groups } = useStore()
  const [selectedUsers, setselectedUsers] = React.useState<User[]>([])
  const [searchText, onChangeText] = React.useState<string>('')
  const splitSearchText = searchText.toLocaleLowerCase().split(' ')
  const membersLeft = members.orgMembers.filter(member => !groups.groupMembers.find(gmember => gmember.user === member.user_id))

  const filterdData = membersLeft.filter((a: User) => {
    const splitFullName = [a.given_name != null ? a.given_name.trim().toLowerCase() : undefined, a.family_name != null ? a.family_name.trim().toLowerCase() : undefined]
    return splitSearchText.every(el => splitFullName.some(el2 => el2 != null && el2.includes(el)))
  })

  const toggleSelectUser = (user: User) => {
    const sUser = selectedUsers.find(smember => smember.user_id === user.user_id)
    if (selectedUsers.length == 0 || sUser == null) {
      setselectedUsers(prev => [...prev, user])
    }
    else {
      setselectedUsers(selectedUsers.filter((a: User) => a.user_id != sUser.user_id))
    }
  }

  const handelOkPress = () => {
    groups.addMembers(selectedUsers)
    setselectedUsers([])
    groups.close()
  }

  return (
    <DialogContainer PaperProps={{ elevation: 10 }} open={groups.isManageOpen} onClose={groups.close} fullWidth maxWidth='sm'>
      <Header group={groups.currentGroup} allMembersSize={members.orgMembers.length} groupSize={groups.groupMembers.length + selectedUsers.length} headerText={tMembers`manage`} addText={t`add`} onClose={groups.close} onOk={handelOkPress} />
      <StyledSearchContainer>
        <Space />
        <Search onChange={onChangeText} backgroundColor={theme.color.searchGrayDialog} searchText={searchText} />
        <Space />
        <SelectedMembersList members={selectedUsers} toggleSelectUser={toggleSelectUser} ></SelectedMembersList>
      </StyledSearchContainer>
      <StyledDialogContent>
        <Space />
        <Row justify='between' align='start'>
          <MembersList notInGroupMembers={toJS(filterdData)} groupMembers={selectedUsers} toggleSelectUser={toggleSelectUser}></MembersList>
        </Row>
      </StyledDialogContent>
    </DialogContainer>
  )
}

export default observer(MembersInGroupDialog)
