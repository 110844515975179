import React, { FC, useState } from 'react'
import styled from 'styled-components'
import EmergencyDialog from './EmergencyDialog'
import Alarm from '../../assets/icons/alarmWhite.svg'
import api from 'service/api'
import { newGuid } from '../../utils/generates';
import {  userIsSuperAdmin, userIsDirector } from '../../utils/roles'
import { observer } from 'mobx-react'
import { AlarmMessage, GroupType } from '../../utils/types'
import { Path } from 'core/Routes'
import { useHistory } from 'react-router'
import useStore from 'store'

const ButtonContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
`

const Img = styled.img`
  color: white;
  margin-left: 3px;
`
const Wrapper = styled.div`
  direction: ltr;
  position: fixed;
  left: 0px;
  top: 200px;
  z-index: 2;
  display: flex;
  transition: all 0.3s ease-in-out;
  align-items: flex-end;
`

const Button = styled.div`
  cursor: pointer;
  border: 4px solid white;
  border-radius: 23px;
  margin: 10px;
  background: red;
  padding-top: 3px;
  height: 46px;
  width: 46px;
  color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  &:hover {
    box-shadow:  0 0 10px 5px rgba(255,255,255,0.6);
    -moz-box-shadow: 0 0 10px  5px rgba(255,255,255,0.6);
    -webkit-box-shadow: 0 0 10px 5px rgba(255,255,255,0.6);
    -o-box-shadow: 0 0 10px 5px rgba(255,255,255,0.6);
  }
`

const Emergency: FC = () => {
  const [ open, setOpen ] = useState(false)
  const [loading, setLoading] = useState(false);
  const { orgs: orgsStore, groups: groupStore, modulesStore, messages: messageStore } = useStore()
  const userAgent = navigator.userAgent
  const h = useHistory()

  const MAX_ACCURACY = 50;

  const sendAlertWithLoction = (payloadLocation: AlarmMessage, attemptsToGetLocation: number, groupId: null | number) => {
    navigator.geolocation.getCurrentPosition((position) => {
      payloadLocation.lat = position.coords.latitude;
      payloadLocation.lng = position.coords.longitude;
      payloadLocation.accuracy = position.coords.accuracy;

      console.log("Accurancy", payloadLocation.accuracy);

      if (position.coords.accuracy > MAX_ACCURACY && attemptsToGetLocation !== 1 && attemptsToGetLocation < 10) {
        setTimeout(() => {
          sendAlertWithLoction(payloadLocation, ++attemptsToGetLocation, groupId);
        }, 2000);
        return; 
      }

      if (attemptsToGetLocation == 1) {
        api.sendAlarmMessage(payloadLocation).then(data => {
          if (!Array.isArray(data) && ("groupId" in data)) {
            console.log(`Go to group`, data.groupId);
              
            groupStore.setCurrentGroupType(GroupType.SUPPORT)
            groupStore.setCurrentGroupId('subGroupId' in data ? data.subGroupId : data.groupId);
            h.push(Path.SERVICECALL)
  
            if (position.coords.accuracy > MAX_ACCURACY) {
              payloadLocation.messageId = data.messageId
              setTimeout(() => {
                sendAlertWithLoction(payloadLocation, ++attemptsToGetLocation, 'subGroupId' in data ? data.subGroupId : data.groupId);
              }, 2000);
            }
          }
        }) 
      } else {
        api.updateDeviceLocation(payloadLocation.messageId, payloadLocation).then(data => {
          console.log('Updated device location.')
          if (groupId != null && groupStore.currentGroupId == groupId) {
            messageStore.getMessages(groupId)
          }
        })
      }
    }, (error) => {
      if (error.code == error.PERMISSION_DENIED) {
        console.log("Location not allowed");
      }

      sendAlertWithoutLoction(payloadLocation);
    });
  }

  const sendAlertWithoutLoction = (payloadLocation: AlarmMessage) => {
    api.sendAlarmMessage(payloadLocation).then(data => {
      if (!Array.isArray(data) && ("groupId" in data)) {
        console.log(`Go to group`, data.groupId);
        groupStore.setCurrentGroupType(GroupType.SUPPORT)
        groupStore.setCurrentGroupId('subGroupId' in data ? data.subGroupId : data.groupId);
        h.push(Path.SERVICECALL)
      }
    })
  }

  const okAction = () => {
    setLoading(true)
    const payloadLocation: AlarmMessage = {
      guid: newGuid(),
      communityId: orgsStore.currentOrgId == undefined ? null : orgsStore.currentOrgId,
      deviceId: "",
      deviceDetails: userAgent,
      accuracy: -1,
      lat: null,
      lng: null,
      address: '',
      note: "התקבל דיווח מצוקה",  
    }

    if (!("geolocation" in navigator)) {
      sendAlertWithoutLoction(payloadLocation);
    } else {
      sendAlertWithLoction(payloadLocation, 1);
    }

    setLoading(false);
    setOpen(false)
  }

  return (modulesStore.isEnabledEmergencyFeature && (userIsDirector() || userIsSuperAdmin())) ? 
      <Wrapper>
      <EmergencyDialog  
        isOpen={open} 
        loading={loading}
        okAction={okAction} 
        cancelAction={() => {setOpen(false)}} 
        singleText="האם בטוח שהינך במצב מצוקה ורוצה לדווח על מיקומך" 
      />
      <ButtonContainer className='new-alert-message'>
      <Button 
        onClick={() => {
          setOpen(true);
        }}>
          <Img src={Alarm} />
      </Button>
      </ButtonContainer>
    </Wrapper> : <></> 
}

export default observer(Emergency)
