import React, { FC, useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react'

import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { Space, Input, theme } from 'ui'
import useStore from 'store'
import { useInput } from 'utils/hooks'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const DialogContainer = styled(Dialog)`
  .MuiDialog-paperFullWidth{
    border-radius: 8px;
    width: 700px;
  }
`
const InputContainer = styled(Input)`
  border-radius: 6px;
  .MuiOutlinedInput-notchedOutline{
      border: solid 2px ${theme.color.inputGrayBorder};
      border-radius: 6px;
    }
  background-color: ${theme.color.inputBackground};
`
const DialogHeader = styled.div`
  .MuiDialogTitle-root {
    display:flex;
    height: 64px;
    background: ${theme.color.dialogBackground};;
    align-items: center;
    justify-content:space-between;
    padding: 16px 36px;
  }
  .MuiIconButton-root{
    padding:0;
  }
`
const CustomButton = styled(Button)`
  width: 140px;
  height: 50px;
  border-radius: 9px;
  background-color: ${p => p.transparent ? 'transparent' : theme.color.confirmButton};
  color: ${p => p.transparent ? theme.color.black : theme.color.white};
`

const DialogFooter = styled(DialogActions)`
  background: ${theme.color.dialogBackground};
  height: 84px;
  padding: 16px 36px;
`
const DialogBody = styled(DialogContent)`
  background: ${theme.color.dialogBackground};
  border-top-width: ${p => p.isTop ? '1px' : '0px'};
  padding: 16px 36px;
`
const DialogHeaderText = styled.p`
  font-size: ${theme.fontSize.dialogHeader};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: ${theme.color.black};;
`

const OrgDialog: FC = () => {

  const { t } = useTranslation('orgDialog')
  const { t: t2 } = useTranslation('app')

  const { orgs } = useStore()

  const [desc, setDesc] = useState('')
  const inputRef = useRef()

  const name = useInput({
    placeholder: t`orgName`,
    value: ''
  })

  const handleOk = () => {
    if (name.value) {
      orgs.createNewCommunity(name.value)
    }
    orgs.closeNewCommunityModal()
  }

  const [isTop, setIsTop] = useState(false)

  return (
    <DialogContainer
      open={orgs.isNewCommunityModalOpen}
      // onClose={orgs.closeNewCommunityModal}
      fullWidth maxWidth='md'>
      <DialogHeader>
        <DialogTitle disableTypography>
          <DialogHeaderText>{t`orgDetails`}</DialogHeaderText>
          <IconButton onClick={orgs.closeNewCommunityModal} >
            <Close />
          </IconButton>
        </DialogTitle>
      </DialogHeader>
      <DialogBody ref={inputRef} dividers onScroll={() => {
        setIsTop(!!inputRef?.current?.scrollTop)
      }} isTop={isTop}>
        <InputContainer fullWidth {...name} />
        <Space />
      </DialogBody>
      <DialogFooter disableSpacing>
        <CustomButton transparent onClick={orgs.closeNewCommunityModal}>{t2`cancel`}</CustomButton>
        <CustomButton onClick={handleOk}>{t2`ok`}</CustomButton>
      </DialogFooter>
    </DialogContainer>
  )
}

export default observer(OrgDialog)
