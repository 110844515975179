export default ({color = '#183d71', backgroundColor = '#fff'}) => (
  <svg width="200" height="250" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 230 230">
    <g>
      <path fill={backgroundColor} d="m23.17,120.1v18.29c0,4.55,5.5,6.82,8.71,3.61l21.9-21.9h25.51c7.51,0,13.6-6.09,13.6-13.6v-49.32c0-7.51-6.09-13.6-13.6-13.6H23.17c-7.51,0-13.6,6.09-13.6,13.6v49.32c0,7.51,6.09,13.6,13.6,13.6Z"/>
      <path fill={backgroundColor} d="m206.83,120.1v18.29c0,4.55-5.5,6.82-8.71,3.61l-21.9-21.9h-25.51c-7.51,0-13.6-6.09-13.6-13.6v-49.32c0-7.51,6.09-13.6,13.6-13.6h56.12c7.51,0,13.6,6.09,13.6,13.6v49.32c0,7.51-6.09,13.6-13.6,13.6Z"/>
      <path fill="#fff" d="m140.51,220.43v-5.1c0-9.39,7.61-17.01,17.01-17.01h30.61c9.39,0,17.01,7.61,17.01,17.01v5.1"/>
      <path fill="#fff" d="m24.87,220.43v-5.1c0-9.39,7.61-17.01,17.01-17.01h30.61c9.39,0,17.01,7.61,17.01,17.01v5.1"/>
    </g>
    <g>
      <g>
        <g>
          <g>
            <circle fill="#fff" cx="172.82" cy="166.02" r="18.71"/>
            <path fill={color} d="m172.82,188.12c-2.98,0-5.88-.59-8.61-1.74-2.63-1.11-5-2.71-7.03-4.74s-3.62-4.39-4.74-7.03c-1.15-2.73-1.74-5.62-1.74-8.61s.58-5.88,1.74-8.61c1.11-2.63,2.71-5,4.74-7.03s4.39-3.62,7.03-4.74c2.73-1.15,5.62-1.74,8.61-1.74s5.88.58,8.61,1.74c2.63,1.11,5,2.71,7.03,4.74,2.03,2.03,3.62,4.39,4.74,7.03,1.15,2.73,1.74,5.62,1.74,8.61s-.58,5.88-1.74,8.61c-1.11,2.63-2.71,5-4.74,7.03-2.03,2.03-4.39,3.62-7.03,4.74-2.73,1.15-5.62,1.74-8.61,1.74Zm0-37.41c-8.44,0-15.3,6.87-15.3,15.3s6.87,15.3,15.3,15.3,15.3-6.87,15.3-15.3-6.87-15.3-15.3-15.3Z"/>
          </g>
          <path fill={color} d="m205.13,223.83c-1.88,0-3.4-1.52-3.4-3.4v-5.1c0-7.5-6.1-13.6-13.6-13.6h-27.21c-1.88,0-3.4-1.52-3.4-3.4s1.52-3.4,3.4-3.4h27.21c2.75,0,5.43.54,7.94,1.6,2.43,1.03,4.61,2.5,6.49,4.37,1.87,1.87,3.34,4.05,4.37,6.49,1.07,2.52,1.61,5.19,1.61,7.94v5.1c0,1.88-1.52,3.4-3.4,3.4Z"/>
        </g>
        <g>
          <g>
            <circle fill="#fff" cx="57.18" cy="166.02" r="18.71"/>
            <path fill={color} d="m57.18,188.12c-2.98,0-5.88-.59-8.61-1.74-2.63-1.11-5-2.71-7.03-4.74-2.03-2.03-3.62-4.39-4.74-7.03-1.15-2.73-1.74-5.62-1.74-8.61s.58-5.88,1.74-8.61c1.11-2.63,2.71-5,4.74-7.03,2.03-2.03,4.39-3.62,7.03-4.74,2.73-1.15,5.62-1.74,8.61-1.74s5.88.58,8.61,1.74c2.63,1.11,5,2.71,7.03,4.74s3.62,4.39,4.74,7.03c1.15,2.73,1.74,5.62,1.74,8.61s-.58,5.88-1.74,8.61c-1.11,2.63-2.71,5-4.74,7.03s-4.39,3.62-7.03,4.74c-2.73,1.15-5.62,1.74-8.61,1.74Zm0-37.41c-8.44,0-15.3,6.87-15.3,15.3s6.87,15.3,15.3,15.3,15.3-6.87,15.3-15.3-6.87-15.3-15.3-15.3Z"/>
          </g>
          <path fill={color} d="m24.87,223.83c-1.88,0-3.4-1.52-3.4-3.4v-5.1c0-2.75.54-5.43,1.61-7.94,1.03-2.43,2.5-4.61,4.37-6.49,1.87-1.87,4.05-3.34,6.49-4.37,2.52-1.07,5.19-1.6,7.94-1.6h27.21c1.88,0,3.4,1.52,3.4,3.4s-1.52,3.4-3.4,3.4h-27.21c-7.5,0-13.6,6.1-13.6,13.6v5.1c0,1.88-1.52,3.4-3.4,3.4Z"/>
        </g>
        <g>
          <g>
            <circle fill="#fff" cx="115" cy="152.41" r="18.71"/>
            <path fill={color} d="m115,174.52c-2.98,0-5.88-.59-8.61-1.74-2.63-1.11-5-2.71-7.03-4.74s-3.62-4.39-4.74-7.03c-1.15-2.73-1.74-5.62-1.74-8.61s.58-5.88,1.74-8.61c1.11-2.63,2.71-5,4.74-7.03s4.39-3.62,7.03-4.74c2.73-1.15,5.62-1.74,8.61-1.74s5.88.58,8.61,1.74c2.63,1.11,5,2.71,7.03,4.74s3.62,4.39,4.74,7.03c1.15,2.73,1.74,5.62,1.74,8.61s-.58,5.88-1.74,8.61c-1.11,2.63-2.71,5-4.74,7.03s-4.39,3.62-7.03,4.74c-2.73,1.15-5.62,1.74-8.61,1.74Zm0-37.41c-8.44,0-15.3,6.87-15.3,15.3s6.87,15.3,15.3,15.3,15.3-6.87,15.3-15.3-6.87-15.3-15.3-15.3Z"/>
          </g>
          <g>
            <path fill="#fff" d="m82.69,220.43v-18.71c0-9.39,7.61-17.01,17.01-17.01h30.61c9.39,0,17.01,7.61,17.01,17.01v18.71"/>
            <path fill={color} d="m147.31,223.83c-1.88,0-3.4-1.52-3.4-3.4v-18.71c0-7.5-6.1-13.6-13.6-13.6h-30.61c-7.5,0-13.6,6.1-13.6,13.6v18.71c0,1.88-1.52,3.4-3.4,3.4s-3.4-1.52-3.4-3.4v-18.71c0-2.75.54-5.43,1.61-7.94,1.03-2.43,2.5-4.61,4.37-6.49,1.87-1.87,4.05-3.34,6.49-4.37,2.52-1.07,5.19-1.61,7.94-1.61h30.61c2.75,0,5.43.54,7.94,1.61,2.43,1.03,4.61,2.5,6.49,4.37,1.87,1.87,3.34,4.05,4.37,6.49,1.07,2.52,1.61,5.19,1.61,7.94v18.71c0,1.88-1.52,3.4-3.4,3.4Z"/>
          </g>
        </g>
      </g>
      <g>
        <path fill={color} d="m28.31,146.91c-1.12,0-2.23-.22-3.29-.66-1.51-.62-2.79-1.65-3.72-2.96-1-1.42-1.53-3.11-1.53-4.89v-15.23c-3.25-.66-6.23-2.25-8.62-4.64-3.21-3.21-4.98-7.48-4.98-12.02v-49.32c0-4.54,1.77-8.81,4.98-12.02,3.21-3.21,7.48-4.98,12.02-4.98h27.21c1.88,0,3.4,1.52,3.4,3.4s-1.52,3.4-3.4,3.4h-27.21c-5.63,0-10.2,4.58-10.2,10.2v49.32c0,5.63,4.58,10.2,10.2,10.2,1.88,0,3.4,1.52,3.4,3.4v18.29c0,1.03.73,1.44,1.05,1.57.32.13,1.13.36,1.85-.37l21.9-21.9c.64-.64,1.5-1,2.4-1h25.51c3.4,0,6.56-1.68,8.46-4.5,1.05-1.56,3.17-1.97,4.72-.91,1.56,1.05,1.97,3.17.91,4.72-3.17,4.69-8.44,7.5-14.1,7.5h-24.1l-20.9,20.9c-1.26,1.26-2.83,2.08-4.54,2.38-.48.08-.96.12-1.44.12Z"/>
        <path fill={color} d="m201.69,146.91c-.48,0-.96-.04-1.44-.12-1.71-.3-3.28-1.12-4.54-2.38l-20.9-20.9h-24.1c-5.65,0-10.92-2.8-14.09-7.48-1.05-1.56-.65-3.67.91-4.72,1.56-1.05,3.67-.65,4.72.91,1.9,2.81,5.07,4.49,8.46,4.49h25.51c.9,0,1.77.36,2.4,1l21.9,21.9c.73.73,1.54.5,1.85.37.32-.13,1.05-.55,1.05-1.57v-18.29c0-1.88,1.52-3.4,3.4-3.4,5.63,0,10.2-4.58,10.2-10.2v-49.32c0-5.63-4.58-10.2-10.2-10.2h-27.21c-1.88,0-3.4-1.52-3.4-3.4s1.52-3.4,3.4-3.4h27.21c4.54,0,8.81,1.77,12.02,4.98,3.21,3.21,4.98,7.48,4.98,12.02v49.32c0,4.54-1.77,8.81-4.98,12.02-2.39,2.39-5.38,3.99-8.62,4.64v15.23c0,1.78-.53,3.47-1.53,4.89-.93,1.31-2.21,2.34-3.72,2.96-1.05.44-2.17.66-3.29.66Z"/>
        <g>
          <g>
            <path fill={backgroundColor} d="m133.71,94.59l-14.42,21.08c-2.02,2.95-6.37,2.96-8.41.02l-14.58-21.1h-18.71c-7.51,0-13.6-6.09-13.6-13.6V23.17c0-7.51,6.09-13.6,13.6-13.6h74.82c7.51,0,13.6,6.09,13.6,13.6v57.82c0,7.51-6.09,13.6-13.6,13.6h-18.71Z"/>
            <path fill={color} d="m115.07,121.3c-2.79,0-5.41-1.37-6.99-3.67l-13.57-19.64h-16.92c-4.54,0-8.81-1.77-12.02-4.98-3.21-3.21-4.98-7.48-4.98-12.02V23.17c0-4.54,1.77-8.81,4.98-12.02,3.21-3.21,7.48-4.98,12.02-4.98h74.82c4.54,0,8.81,1.77,12.02,4.98,3.21,3.21,4.98,7.48,4.98,12.02v57.82c0,4.54-1.77,8.81-4.98,12.02-3.21,3.21-7.48,4.98-12.02,4.98h-16.91l-13.41,19.6c-1.58,2.31-4.2,3.7-7,3.7h-.02ZM77.59,12.97c-5.63,0-10.2,4.58-10.2,10.2v57.82c0,5.63,4.58,10.2,10.2,10.2h18.71c1.12,0,2.16.55,2.8,1.47l14.58,21.1c.44.64,1.06.73,1.4.73h0c.33,0,.96-.1,1.4-.74l14.42-21.08c.63-.93,1.68-1.48,2.81-1.48h18.71c5.63,0,10.2-4.58,10.2-10.2V23.17c0-5.63-4.58-10.2-10.2-10.2h-74.82Z"/>
          </g>
          <path fill={color} d="m142.21,43.58h-54.42c-1.88,0-3.4-1.52-3.4-3.4s1.52-3.4,3.4-3.4h54.42c1.88,0,3.4,1.52,3.4,3.4s-1.52,3.4-3.4,3.4Z"/>
          <path fill={color} d="m142.21,65.68h-27.21c-1.88,0-3.4-1.52-3.4-3.4s1.52-3.4,3.4-3.4h27.21c1.88,0,3.4,1.52,3.4,3.4s-1.52,3.4-3.4,3.4Z"/>
        </g>
      </g>
    </g>
  </svg>
)