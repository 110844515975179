import React, { FC } from 'react'
import { Dialog, Button } from '@material-ui/core'
import styled from 'styled-components'
import { theme, Text, Row, Space } from 'ui'
import useStore from 'store'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Error } from '@material-ui/icons'


const MuiButton = styled(Button)`
  &.MuiButton-containedPrimary{
      background-color:${theme.color.azureBlue};
  };
  &:hover {
      background: ${theme.color.darkBlue + 99};
    }
    width:120px;

`
const CancelButton = styled(Button)`
  &.MuiButton-containedSecondary{
      background-color:${theme.color.dangerRed};
  };
  &:hover {
      background: ${theme.color.dangerRed + 99};
    }
    width:120px;

`
const Container = styled.div`
    display:flex;
    width:500px;
    max-width: 80vw;
    height:300px;
    flex-direction:column;
    padding:15px;
    /* word-break: break-word; */
    
`
const ButtonsContainer = styled.div`
display:flex;
flex-direction:row;
/* justify-content:flex-end; */
justify-content: space-around;
`
const TextContainer = styled.div`
    flex:1;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    text-align:center;
`
const WarningHomeModal: FC<any> = () => {
    const { t } = useTranslation('remove')
    const { uiStore } = useStore()
    const { text, secondText, okAction, cancelAction, onBackdropClick, removeCancelButton, okText, cancelText } = uiStore.warningObject

    const onClickOk = () => {
        if (okAction) {
            okAction()
        }
        uiStore.closeWarningModal()
    }

    const onClickCancel = () => {
        if (cancelAction) {
            cancelAction()
        }
        uiStore.closeWarningModal()
    }

    const onBackdrop = onBackdropClick ? () => {
        onBackdropClick()
        uiStore.closeWarningModal()
    } : null

    return (
        <Dialog
            style={{ zIndex: 9999 }}
            open={uiStore.warningModalFlag}
            onBackdropClick={onBackdrop || onClickCancel}
        >
            <Container>
                <TextContainer>
                    <Error style={{ color: theme.color.dangerRed, width: 30, height: 30 }} />
                    <Space />
                    <div>
                        <Text
                            textcolor={theme.color.black}
                            weight={'normal'}
                            size={'xl'}
                        >{text || t`app:sure`}
                        </Text>
                        {secondText && <Text
                            textcolor={theme.color.black + 'A1'}
                            weight={'medium'}
                            size={'lg'}
                        >{secondText}</Text>}
                    </div>
                </TextContainer>
                <ButtonsContainer >
                    {!removeCancelButton && <MuiButton
                        color={'primary'}
                        size='large'
                        variant='contained'
                        onClick={onClickCancel}>{cancelText ? cancelText : t`dontRemove`}</MuiButton>}
                    <Space />
                    <CancelButton
                        color={'secondary'}
                        variant='contained'
                        size='large'
                        onClick={onClickOk}>{okText ? okText : t`removeOk`}</CancelButton>
                </ButtonsContainer>
            </Container>
        </Dialog>
    )
}

export default observer(WarningHomeModal)
