import React, { FC } from 'react'
import EmptyComponent from 'components/Empty'
import serviceEmpty from 'assets/icons/serviceEmpty.svg'
import styled from 'styled-components'
import { Image, Spinner } from 'ui'
import useStore from 'store'
import { observer } from 'mobx-react'
import Messages from 'pages/Home/Messages'
import { useTranslation } from 'react-i18next'
import { validURL } from 'utils/validations'
import {
    BrowserRouter as Router,
    Switch,
    useLocation
} from "react-router-dom";
import IframePortal from './IframePortal'
const Icon = styled.img`

`
const SpinnerContainer = styled.div`
  flex:1;
  align-items:center;
  justify-content:center;
  width:100%;
  display:flex;
    `
const ServiceCall: FC = () => {
    const { modulesStore, groups, messages } = useStore()
    const { t } = useTranslation('emptyGroup')
    const portal = modulesStore?.communityModules.filter(i => i.type === 'support')
    if (portal.length) {
        return <></>
    }
    else if (messages.lastMessagesLoading || groups.supportGroupsLoading) {
        return <SpinnerContainer><Spinner /></SpinnerContainer>
    }
    else if (groups?.currentSupportGroups.length === 0) {
        return (
            <EmptyComponent
                icon={<Image src={serviceEmpty} />}
                title={t`groups_list_title`}
                description={t`groups_list_description`}
            />
        )
    }
    else return <Messages />

}


export default observer(ServiceCall)
