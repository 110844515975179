import React, { FC } from 'react'
import { Row, Text, theme } from 'ui'
import useStore from 'store'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ChevronLeft } from '@material-ui/icons'
import Grow from 'ui/Grow'
import { AppBar, IconButton, Switch, Tab, Tabs, Select, InputLabel, MenuItem } from '@material-ui/core'
import { SortTypes, PaymentType } from 'store/budget.store'

const View = styled.div`
  display:flex;
  flex-direction:column;
`
const StyledNavBar = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center; 
  /* justify-content:center; */
  /* padding-right:16px; */
  padding: 0 1rem 0.5rem 0;
  body[dir=rtl] & {
    padding: 0 0 0.5rem 1rem;
    direction: rtl;
  }
  /* background-color:red; */
`
const StyledSelect = styled(Select)`
  &.MuiInput-underline:after{
    border: none !important
  };
  &.MuiInput-underline:before{
    border: none !important
  };
  & .MuiSelect-select.MuiSelect-select{
    padding-left: 24px
  }
`
interface NavBarItemProps {
  paymentType: PaymentType
  selectedPaymentType: PaymentType
  onPress: any
  title: string
  isDisabled: boolean
}

const StyledNavItem = styled(View) <{ disabled: boolean, paymentType: PaymentType, selectedPaymentType: PaymentType }>`
  cursor:${p => p.disabled ? 'not-allowed' : 'pointer'};
  padding-left:10px;
  padding-right:10px;
  border-style:solid;
  border-width:0px;
  border-bottom-width:${p => p.disabled ? '2px' : (p.paymentType === p.selectedPaymentType ? '3px' : '2px')};
  border-bottom-color:${p => p.disabled ? theme.color.blandGray : (p.paymentType === p.selectedPaymentType ? theme.color.loginPurple : theme.color.blandGray)};
  /* margin:auto; */
`
export const NavBarItem: React.FC<NavBarItemProps> = ({ paymentType, selectedPaymentType, onPress, title, isDisabled }) => {
  return (
    <StyledNavItem
      paymentType={paymentType}
      selectedPaymentType={selectedPaymentType}
      disabled={isDisabled}
      onClick={onPress}>
      <Text
        weight={isDisabled ? 'normal' : (selectedPaymentType === paymentType ? 'bold' : 'normal')}
        textcolor={isDisabled ? theme.color.grey : (selectedPaymentType === paymentType ? theme.color.loginPurple : undefined)}
      >{title}</Text>
    </StyledNavItem>
  )
}
const BudgetNav: FC = () => {
  const { budgetStore } = useStore()
  const { t } = useTranslation('budget')
  const onAgorotSwitchPress = () => {
    !isFilesTab && budgetStore.setShowAgorot(!budgetStore.showAgorot)
  }
  const handleNavPress = (type: PaymentType) => {
    return () => {
      budgetStore.setPaymentType(type)
    }
  }

  const handleSortChange = (event) => {
    const { value } = event.target
    budgetStore.setSortType(value as SortTypes)
  }

  const selectedNav = budgetStore.paymentType
  const selectedSort = budgetStore.sortType
  const isFilesTab = selectedNav === PaymentType.FILES
  return (
    <StyledNavBar>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <NavBarItem isDisabled={budgetStore.isDisabled} onPress={handleNavPress(PaymentType.POS)} paymentType={PaymentType.POS} selectedPaymentType={selectedNav} title={'תנועות בחשבון'} />
        <NavBarItem isDisabled={budgetStore.isDisabled} paymentType={PaymentType.BUDGET} onPress={handleNavPress(PaymentType.BUDGET)} selectedPaymentType={selectedNav} title={'תקציב'} />
        <NavBarItem isDisabled={budgetStore.isDisabled} paymentType={PaymentType.FILES} onPress={handleNavPress(PaymentType.FILES)} selectedPaymentType={selectedNav} title={'קבצים'} />
      </div>
      <Grow />
      <Row style={{ cursor: 'pointer',marginLeft:'20px' }}>
        <StyledSelect
          disabled={isFilesTab}
          onChange={handleSortChange}
          value={selectedSort}>
          <MenuItem value={SortTypes.SUM}>{t`bySum`}</MenuItem>
          <MenuItem value={SortTypes.DATE}>{t`byDate`}</MenuItem>
          <MenuItem disabled={budgetStore.paymentType === PaymentType.POS} value={SortTypes.STORE}>{t`byStore`}</MenuItem>
        </StyledSelect>
      </Row>
      <Row onClick={onAgorotSwitchPress} style={{ cursor: 'pointer' }}>
        <Text textcolor={budgetStore.isDisabled || isFilesTab ? theme.color.grey : theme.color.black}> {t`summery.agorot`} </Text>
        <Switch checked={budgetStore.showAgorot} disabled={budgetStore.isDisabled || isFilesTab} />
      </Row>
    </StyledNavBar>
  )
}


export default observer(BudgetNav)


