import React, { FC, useEffect } from 'react'
import { Text, theme } from 'ui'
import useStore from 'store'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IFiles, PaymentType } from 'store/budget.store'
import EmptyList from './EmptyList'
import EmptyPdfIcon from 'assets/png/emptyPdf.png'
import Pdf from 'assets/png/pdf.png'
import ViewerModal from 'components/Messages/ViewerModal'

const StyledFlex = styled.div`
  display:flex;
  flex-direction:column;
  background-color: #fff;
  margin: 15px;
  border-radius: 5px;
  box-shadow: 1px 2px 10px 0 rgba(0,0,0,0.2);
`
const StyledBody = styled.div<{ paddingTop?: number, isDisabled: boolean }>`
  display:flex;
  flex-direction:column;
  background-color:${p => p.isDisabled ? theme.color.back : theme.color.backgroundGray};
  flex:1;
  padding-top:${p => p.paddingTop || 0}px;
  overflow:auto;
  padding-bottom: 20px;
`
const StyledSummaryItem = styled.div`
  display:flex;
  flex-direction:row;
  cursor:'pointer';
  align-items: center;
  padding:12px;
  border-bottom: 1px solid ${theme.color.disable};
`
const StyledImage = styled.img`
  height: 15vh;
  object-fit: contain;
`

const StyledPdfImage = styled.img`
  height: 25px;
  object-fit: contain;
  margin-left: 10px;
`



const FilesTab: FC = () => {
  const { t } = useTranslation('budget')
  const { budgetStore } = useStore()
  const { files } = budgetStore

  useEffect(() => {
    if(budgetStore.availablePeriods[budgetStore.selectedPeriodIndex] && budgetStore.isFilesTab) {
      const selectedPeriod = budgetStore.availablePeriods[budgetStore.selectedPeriodIndex]
      const selectedYear = selectedPeriod[0]
      const selectedMonth = selectedPeriod[1]

      budgetStore.loadFiles(selectedYear, selectedMonth)
    }
    return () => budgetStore.removeFileDates()
  }, [budgetStore.selectedPeriodIndex, budgetStore.paymentType])

  if (!files || !files.length) {
    return <EmptyList
    icon={<StyledImage src={EmptyPdfIcon} />}
    title={t`summery.budget_unavailable_title_pdf`}
    content={t`summery.budget_unavailable_content_pdf`}
  />
  }

  const FileItem: React.FC<{ item: IFiles }> = ({ item }) => {
    const { id, name } = item
   
    const handlePress = () => {
      budgetStore.loadFile(id)
    }

    return (
      <StyledSummaryItem key={id} onClick={handlePress}>
        <StyledPdfImage src={Pdf} />
        <Text color="textSecondary" weight="medium" size='xl'>{name}</Text>
      </StyledSummaryItem>
    )
  }

  return <StyledBody isDisabled={false} paddingTop={0}>
      <StyledFlex>
        {files.map(item => <FileItem key={item.id} item={item}/>)}
      </StyledFlex>
      <ViewerModal />
    </StyledBody>
 
  

}

export default observer(FilesTab)