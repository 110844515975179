import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { Dialog, IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { theme } from 'ui'
import { observer } from 'mobx-react'
import useStore from 'store'
import PdfViewer from 'components/Messages/PdfViewer'
import MessagesHeader from 'components/Messages/MessagesHeader'
import { SystemUpdateAlt, Print } from '@material-ui/icons'
import print from 'print-js'


const ContainerDialog = styled.div<{ color: string }>`
    min-height:400px;
    min-width:300px;
    max-height: 100vh;
    height: 900px;
    background-color:${p => p.color + '1A'};
    padding:5px;
    display:flex;
    flex:1;
    flex-direction:column;

  `
const PdfContainer = styled.div`
    flex:1;
    display:flex;
    max-height:80vh;
    overflow:auto;
    flex-direction:column;
    /* justify-content:center;
    align-items:center; */
    align-items: stretch;
    justify-content: stretch;
  `
const ImageContainer = styled.div`
    /* border-radius: 10px;     */
  /* .MuiDialog-paperScrollPaper{
      max-height:none;
  } */
  height: 100%;
      `
const StyledImage = styled.img`
    object-fit:contain;
    width:100%;
    height:100%;

`
const StyledDialog = styled(Dialog)`
    max-height:none;
    .MuiDialog-paperScrollPaper{
        max-height:none;
    }

    cursor:pointer;
`
const PostionAbsolute = styled.div`
    position:absolute;
    top:0;
    z-index:200;
    width:100%;
`
const Footer = styled.div`
  position: absolute;
  width:120px; 
  background-color:${theme.color.black + 80};
  bottom:0;
  justify-content: center;
    display: flex;
    padding: 20px;
    right:0;
    border-top-left-radius:20px;
`
const DownloadIcon = styled(SystemUpdateAlt)`
  color:white;
  font-size:30px;
`
const PrintIcon = styled(Print)`
  color:white;
  font-size:30px;
`
const toDataURL = (url: string) => {
    return fetch(url).then((response) => {
        return response.blob();
    }).then(blob => {
        return URL.createObjectURL(blob);
    });
}
const ViewerModal: FC = ({ }) => {
    const { messages, groups } = useStore()
    const { t } = useTranslation('messageDetails')
    const open = messages.viewerModalOpen
    const { type, file } = messages.viewerModalFile
    const [hideHeader, setHideHeader] = useState(false)

    const closeModal = () => {
        setHideHeader(false)
        messages.closeViewerModal()
    }

    const groupColor = groups.currentGroup?.group.description?.color || 'white'

    const download = async () => {
        const a = document.createElement("a");
        a.href = await toDataURL(file);
        a.download = type === 'image' ? "myImage.png" : "myPdf.pdf"
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    const onEnter = () => setHideHeader(false)
    const onLeave = () => setHideHeader(true)

    return (
        <StyledDialog
            open={open}
            onClose={closeModal}
            maxWidth={'md'}
        >
            {/* {!hideHeader && <PostionAbsolute>
                <MessagesHeader
                    backColor={theme.color.black + 80}
                    mainText={groups?.currentGroup?.group?.name}
                    secondText={''}
                    onClose={closeModal}
                />
            </PostionAbsolute>} */}
            <ContainerDialog
                onMouseEnter={onEnter}
                onMouseLeave={onLeave}
                // onClick={() => setHideHeader(!hideHeader)}
                color={groupColor}>

                {type === 'image' &&
                    <ImageContainer>
                        <StyledImage
                            id="printImage"
                            alt={file}
                            src={file}
                        /></ImageContainer>}
                {type === 'pdf' &&
                    <PdfContainer>
                        <PdfViewer
                            file={file}
                            disablePages={false}
                        /> </PdfContainer>}
                {!hideHeader && <Footer>
                    {/* <PrintIcon style={{ marginLeft: 10 }}
                        onClick={type === 'pdf' ? () => print(file) : () => print('printImage', 'html')}

                    /> */}
                    <IconButton
                        onClick={type === 'pdf' ? () => print(file) : () => print('printImage', 'html')}
                    >
                        <PrintIcon />
                    </IconButton>
                    <IconButton onClick={download} >
                        <DownloadIcon />
                    </IconButton>
                </Footer>}
            </ContainerDialog>

        </StyledDialog>
    )
}

export default observer(ViewerModal)
