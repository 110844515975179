import { parsePhoneNumberFromString } from 'libphonenumber-js'


export const intToBool = (num: number | boolean) => {
  if (num === true || num === false) {
    return num
  }
  else return (num === 1 || num === 3) ? true : false
}
export const boolToInt = (bool: boolean | number) => {
  return bool ? 1 : 0
}

export function isNumber(number: number) {
  if (number && !Number.isNaN(number)) {
    return number
  }
  return 0
}

export function formatIfPhoneNumber(data: string) {
  if (!data) return ''
  if (data.includes('@'))
    return data
  else if (data) {
    const number = parsePhoneNumberFromString(data, 'IL')
    if (number)
      return number.formatNational()
  }
  return data
}

export const intToHex = (color: number) => {
  return '#' + color.toString(16)
}