import React, { FC, useState, useEffect } from 'react'
import styled from 'styled-components'
import { theme, Text, Row, Space, Icon, Image, TextSize, TextWeight } from 'ui'
import { useTranslation } from 'react-i18next'
import pigIcon from 'assets/icons/budgetIcon.svg'
import { Dialog, IconButton, Menu as MuiMenu, MenuItem, } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import useStore from 'store'
import { observer } from 'mobx-react'
import { ModuleEnum } from 'utils/types'
import { canEditCommunityModules } from 'utils/roles'
import BudgetSoonPNG from 'assets/png/budgetSoon.png'
import { ChevronLeft, Close } from '@material-ui/icons'
import Grow from 'ui/Grow'
import logoIcon from 'assets/header_logo_silver.svg'
import { useHistory } from 'react-router'
import { Path } from 'core/Routes'

const StyledLogoContainer = styled.footer`
  display:flex;
  align-items:center;
  justify-content:center;
  min-height:48px;
`
const StyledImage = styled.img`
  height: 30vh;
  object-fit: contain;
`
const ComingSoonDialogBody = styled.div`
  min-height:68vh;
  background-color:${theme.color.blockedPurple};
  padding:8px;
  display:flex;
  overflow:auto;
  flex-direction:column;
`
const StyledHeaderTextContainer = styled.div`
  padding-inline:16px;
`
const StyledButtonAndDescription = styled.div`
  display:flex;
  background-color:${theme.color.white};
  margin:0 -8px -8px -8px;
  padding:8px;
  flex:1;
  flex-direction:column;
`
const ButtonContainer = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
`
const ChevronContainer = styled.div`
  padding:16px;
  background-color:${theme.color.blockedPurple};
  align-self:stretch;
  display:flex;
  align-items:center;
  justify-content:center;
  border-top-left-radius:8px;
  border-bottom-left-radius:8px;
`
interface Props {
  onClose: () => void,
}
const ComingSoonAd: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation(['budget'])
  const { budgetStore } = useStore()
  const h = useHistory()
  const handlePress = () => {
    budgetStore.setUseDemo(true)
    budgetStore.setIsLoading(true)
    budgetStore.init().then(() => {
      h.push(Path.USER_BUDGET)
    })
    onClose()
  }
  return (
    <ComingSoonDialogBody>
      <Row justify='end'>
        <IconButton onClick={onClose}>
          <Close style={{ color: theme.color.white, fontSize: 8 * 4 }} />
        </IconButton>
      </Row>
      <StyledHeaderTextContainer>
        <Text textcolor={theme.color.white} align='center' size='xxl' weight='bold'>{t('budgetAd.budget_soon_budget_header1')}</Text>
        <Text textcolor={theme.color.white} align='center' size='lg'>{t('budgetAd.budget_soon_budget_description')}</Text>
      </StyledHeaderTextContainer>
      <StyledImage src={BudgetSoonPNG} />
      <StyledButtonAndDescription>
        <Space height={1} />
        <Text textcolor={theme.color.black} align='center' size='lg' >{t('budgetAd.budget_soon_budget_demo')}</Text>
        <Space height={2} />
        <ButtonContainer onClick={handlePress} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Row style={{ backgroundColor: theme.color.budgetPurple, cursor: 'pointer', borderRadius: 8 }}>
            <div style={{ padding: 16 }}>
              <Text textcolor={theme.color.white} align='center'>{t('budgetAd.budget_soon_budget_demo_button')}</Text>
            </div>
            <ChevronContainer>
              <ChevronLeft />
            </ChevronContainer>
          </Row>
        </ButtonContainer>
        <Grow />
        <StyledLogoContainer>
          <img src={logoIcon} alt='Mekome logo' />
        </StyledLogoContainer>
      </StyledButtonAndDescription>
    </ComingSoonDialogBody>
  )
}

export default ComingSoonAd