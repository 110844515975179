import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { theme, Text, Row, Icon, Spinner } from 'ui'
import { observer } from 'mobx-react'
import useStore from 'store'
import { UserGroup } from 'utils/types'
import { isTotzeret } from 'service/whiteLable'

const GroupsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow:auto;
  flex:1;
  height:580px;
    /* align-items:center; */
    justify-content:flex-start;
`
const SpinnerContainer = styled.div`
  display: flex;
  flex:1;
  /* max-height:570px; */
    align-items:center;
    justify-content:center;
`
const WarningManager = styled.div`
  position:absolute;
  flex:1;
  display:flex;
  background-color:${theme.color.mekomiBlack + 'CC'};
  padding:10px;
  border-radius:8px;
  border:1px solid black;
  opacity:0;
  &:hover {
    opacity:1;
    }
`
const IconBg = styled.div`
  height:45px;
  width:30px;
  background: ${p => isTotzeret ? 'transparent' : p.color};
  display: flex;
  align-items: ${isTotzeret ? 'center;' : 'flex-end;'}
  justify-content: center;
  padding:0.33rem 0;
  ${p => isTotzeret ? `border-left: 2px solid ${p.color}; border-radius: 0px;` : 'border-radius: 8px;'}
  ${p => isTotzeret ? '' : 'margin-left:1rem;'}
`
const IconComponent = styled.div`
  display: flex;
  align-items: center;
  ${p => isTotzeret ? 'margin-left:1rem;' : ''}
`

const ImgArrow = styled.div<{ color: string }>`
  border-style: solid;
  border-top-width: 7px;
  border-top-color: transparent;
  border-right-width: 8px;
  border-right-color: ${(p) => p.color};
  border-bottom-width: 7px;
  border-bottom-color: transparent;
  border-left-width: 0;
  border-left-color: transparent;
`
const GroupContainer = styled(Row) <{ selected: boolean, color: string }>`
   /* border:1px solid ${theme.color.black + 33};
  border-width:${p => p.selected ? 1 : 0}; */
  box-shadow:${p => p.selected ? '1px 1px 2px 1px' + p.color : 'none'};
  /* box-shadow:${p => p.selected ? '1px1px 2px 1px gray' : 'none'}; */
  border: 1px solid ${theme.color.black + 33};
  border-width:${p => p.selected ? 1 : 0};
  justify-content:flex-start;
  align-items:center;
  display:flex;
  margin:5px;
  margin-left:15px;
  padding:5px;
  cursor:pointer;
  border-radius:8px;
  word-break: break-word;
    position: relative;
`
function dynamicSort(property: string, subProperty?: string) {
  return function (a: any, b: any) {
    /* next line works with strings and numbers, 
     * and you may want to customize it to your needs
     */
    if (subProperty) {
      let result = !a[subProperty][property] ? 1 :
        (!b[subProperty][property] ? -1 :
          (a[subProperty][property].localeCompare(b[subProperty][property], 'he', { sensitivity: 'base' })))
      return result
    }

    let result = !a[property] ? 1 :
      (!b[property] ? -1 :
        (a[property].localeCompare(b[property], 'he', { sensitivity: 'base' })))
    return result
  }
}

const UserGroupsList: React.FC = () => {
  const { memberStore } = useStore()
  const currentMember = memberStore.getCurrentMemebr

  const userIsDirector = (currentMember?.is_director || currentMember?.is_operator)
  const { t } = useTranslation('members')
  if (!memberStore.currentUserGroups) {
    return null
  }
  if (memberStore.loadingGroups) {
    return <SpinnerContainer><Spinner /></SpinnerContainer>
  }
  if (memberStore.currentUserGroups && memberStore.currentUserGroups.length === 0) {
    return <SpinnerContainer><Text>{'אין קבוצות בקהילה'}</Text></SpinnerContainer>
  }

  return (
    <GroupsContainer>
      {memberStore.currentUserGroups?.sort(dynamicSort('name', 'group')).map((g: UserGroup) => {

        const setSelected = () => {
          if (g.is_member) {
            memberStore.removeGroup(g)
          } else {
            memberStore.addGroup(g)
          }
        }
        return (
          <GroupContainer
            color={g?.group?.description?.iconColor + 80 || theme.color.defaultThemeColorLightGray}
            selected={userIsDirector ? true : g.is_member}
            onClick={userIsDirector ? () => { } : setSelected}
          >
            {userIsDirector && (
              <WarningManager>
                <Text
                  style={{ cursor: 'pointer' }}
                  weight={'normal'}
                  size={'sm'}
                  textcolor={'white'}
                >{t`removeGroupManager`}</Text>
              </WarningManager>
            )}
            <IconComponent>
              <IconBg color={g.group.description.iconColor || theme.color.defaultThemeColorLightGray}><Icon size={18} name={g.group.description.icon || 'default'} /></IconBg>
              {isTotzeret && <ImgArrow color={g.group.description.iconColor || theme.color.defaultThemeColorLightGray}/>}
            </IconComponent>
            <Text
              style={{ cursor: 'pointer' }}
              weight={'normal'}
              size={'md'}
              textcolor={g.is_member ? theme.color.azureBlue : theme.color.black}
            >{g.group.name}</Text>
          </GroupContainer>
        )
      }
      )}
    </GroupsContainer>
  )
}

export default observer(UserGroupsList)


