import React, { FC } from 'react'
import styled from 'styled-components'
import { AutoSizer, List } from 'react-virtualized'

import { Phone as PhoneIcon, Mail as MailIcon } from '@material-ui/icons'
import { Edit, Apple, Android, VisibilityOff } from '@material-ui/icons'

import { Row, Space, theme } from 'ui'
import { Menu as MuiMenu, MenuItem } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import useStore from 'store'

interface Props {

}

const Wrapper = styled.div`
  flex: 1;
  background: rgba(0,0,0,0.05);
  padding: 1rem;
`

const SMember = styled.div`
  direction: ltr;
  body[dir=rtl] & {
    direction: rtl
  }
  background: white;
  border-radius: 8px;
  padding: 1rem 1rem 0.5rem 1rem;
`

const Name = styled.div`
  font-size: 1.6rem;
`

const Phone = styled.div`
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`

const NumberLink = styled.a`
  direction: ltr;
  margin-inline-end: 0.5rem;
`

const Mail = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`

const MailLink = styled.a`
  direction: ltr;
  margin-inline-start: 0.5rem;
`

const E = styled(Edit)`
  padding: 0;
`

function getRowHeight({ phones, emails }) {
  const h = Math.max(phones.length * 26.5, emails.length * 19.5)
  return 62 + h + 13
}

const Menu = observer(({ member }) => {
  const { user_id, fullName, is_operator } = member
  console.log('--- MobileMembersList.tsx ->  -> member', member)
  
  const { t } = useTranslation('membersTable')
  
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  
  const { members, uiStore } = useStore()
  
  const handleClose = () => {
    setAnchorEl(null)
  }
  
  const openActionMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  
  const changeManager = () => {
    members.changeManagerState(user_id, member?.is_operator ? true : false)
    setAnchorEl(null)
  }
  const removeFromCommunity = () => {
    uiStore.openWarningModal({
      text: `${t`remove:removeCommunity`}`,
      secondText: fullName,
      okAction: () => members.removeFromCommunity(user_id)
    })
    setAnchorEl(null)
  }
  
  return (
    <>
      <E onClick={openActionMenu} />
    <MuiMenu
      id="lock-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem
        onClick={() => {
          members.edit(user_id)
          handleClose()
        }}>
          {t`editUserDetails`}
      </MenuItem>
      <MenuItem onClick={changeManager}                >
          {is_operator ? t`removeCommunityOrganiser` : t`makeCommunityOrgnaiser`}
      </MenuItem>
      <MenuItem
        style={{ color:theme.color.red}}
        onClick={removeFromCommunity}
      >
          {t`removeFromCommunity`}
      </MenuItem>
  </MuiMenu>
      </>
  )
})

const Member = ({ style, member }) => {
  const { given_name, family_name, phones, emails } = member
  return (
    <div style={style}>
      <SMember>
        <Row justify='between'>
          <Name>{given_name + ' ' + family_name}</Name>
          <Menu member={member} />
        </Row>
        <Space />
        <Row align='start'>
          <div>{phones.map(p => <Phone><PhoneIcon /><NumberLink href={`tel:${p}`}>{p}</NumberLink></Phone>)}</div>
          <div>{emails.map(e => <Mail><MailLink href={`mailto:${e}`}>{e}</MailLink><MailIcon /></Mail>)}</div>
        </Row>
      </SMember>
    </div>
  )
}

const MobileMembersList: FC<Props> = ({ list, userView = false, searchText }) => {
  
  const rr = ({ index, key, style }) => {
    return <Member key={key} style={style} member={list[index]}>{index}</Member>
  }
  
  return (
    <Wrapper>
      <AutoSizer>{({ width, height }) => (
        <List
          height={height}
          width={width}
          rowRenderer={rr}
          rowHeight={({ index }) => getRowHeight(list[index])}
          rowCount={list.length}
        />
      )}</AutoSizer>
    </Wrapper>
  )
}

export default MobileMembersList
