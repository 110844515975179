import React, {FC, useState} from 'react'
import {observer} from 'mobx-react'
import api from 'service/api'
import {useTranslation} from 'react-i18next'
import { Add, Close } from '@material-ui/icons'
import styled from 'styled-components'
import {theme, Space, Row, Text, Icon, Image, Button } from '../../ui'
import { TextField, Table, TableBody, TableCell, TableContainer } from '@material-ui/core'
import useStore from 'store'
import CouncilSectionItem from './CouncilSectionItem'
import UserSectionItem from './UserSectionItem'
import CouncilSectionNewItem from './CouncilSectionNewItem'
import LabelSectionItem from './LabelSectionItem'
import LabelSectionNewItem from './LabelSectionNewItem'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { ModalRow } from './styles'
import UserSectionNewItem from './UserSectionNewItem'

const AutoCompleteContainer = styled(Autocomplete)`
  margin: 0px 0px;
  width: 50%;
`
const Wrapper = styled.div`
  width:100%;
`
const AddButton = styled(Button)`
  border-radius: 50%;
  min-width: 32px;
  width: 48px;
  height: 48px;
  margin-bottom: 10px;
`
const SectionTitle = styled.div`
  width:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px 15px 0px;
`
const CouncilWrapper = styled.div`
  direction: ltr;
  body[dir=rtl] & {
    direction: rtl
  }
  background: white;
  border-radius: 8px;
  padding: 1rem 1rem 0.5rem 1rem;
`

const CouncilSection: FC = (props) => {
    const {t} = useTranslation('groupDialog')
    const { groups } = useStore()

    const [selectedCouncil, setSelectedCouncil] = useState(null)
    const [labels, setLabels] = useState([])
    const [users, setUsers] = useState([])
    const [isCreateMode, setCreateMode] = useState(false)
    const [isCreateUserMode, setCreateUserMode] = useState(false)
    const [isCreateLabelMode, setCreateLabelMode] = useState(false)

    const openEditDialog = (council) => {
      setSelectedCouncil(council)
      setCreateMode(false)
    }

    const closeEditDialog = () => {
      setSelectedCouncil(null)
      setCreateMode(false)
    }

    const onCloseLabelDialog = () => {
      setCreateLabelMode(false)
    }

    const onCloseUserDialog = () => {
      setCreateUserMode(false)
    }

    const createLabel = () => {
      setCreateLabelMode(!isCreateLabelMode)
    }

    const createUser = () => {
      setCreateUserMode(!isCreateUserMode)
    }

    const create = () => {
      setSelectedCouncil(null)
      setCreateMode(!isCreateMode)
    }

    const onRefreshUsers = async (entityId) => {
      const userRes = await api.getAssociatedUsers(entityId)
      setUsers(userRes?.data || [])
    }

    const onRefreshLabels = async (entityId) => {
      const res = await api.getAssociatedLabelsV2(entityId)
      setLabels(res || [])
    }
   
    return (
        <>    
          <ModalRow>
            <SectionTitle>
            {t`concileName`}
            <AutoCompleteContainer
              value={selectedCouncil}
              defaultValue={groups.getDefaultRegionalCouncil()}
              onChange={async (value: any, v: any) => {
                setSelectedCouncil(v)
                setCreateMode(false)
                setCreateUserMode(false)
                setCreateLabelMode(false)
             
                onRefreshLabels(v.id)
                onRefreshUsers(v.id) 
              }}
              options={groups.regionalCouncil}
              placeholder={`בחירת אירגון`}
              renderOption={(option: any) => option?.name}
              getOptionLabel={(option: any) => option?.name}
              renderInput={(params) =>
                <TextField {...params} value={selectedCouncil || ''} label={`בחירת אירגון`} variant="outlined" />}
            />
            <AddButton onClick={create}>
              {isCreateMode ? <Close /> : <Add />}
            </AddButton>
            </SectionTitle>
            <Wrapper> 
            {isCreateMode && <CouncilSectionNewItem onClose={closeEditDialog} />}
            {!isCreateMode && selectedCouncil && <CouncilSectionItem onEdit={openEditDialog} onClose={closeEditDialog} council={selectedCouncil}/>}
            </Wrapper>
        
            {selectedCouncil &&
              <>
                <SectionTitle>
                  {t`concileLabels`}
                  <AddButton onClick={createLabel}>
                  {isCreateLabelMode ? <Close /> : <Add />}
                  </AddButton>
                </SectionTitle>

                <Wrapper>
                  <CouncilWrapper>
                    <TableContainer>
                      <Table size="small">
                        <TableBody>
                          {isCreateLabelMode && selectedCouncil && <LabelSectionNewItem onClose={onCloseLabelDialog} onRefresh={onRefreshLabels} entityId={selectedCouncil.id} entityLabels={labels} />} 
                          {labels.map((label) => <LabelSectionItem label={label} entityId={selectedCouncil.id} onRefresh={onRefreshLabels} entityLabels={labels} />)}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CouncilWrapper> 
                </Wrapper>
              </>
            }

            {selectedCouncil &&
              <>
                <SectionTitle>
                  {t`senders`}
                  <AddButton onClick={createUser}>
                  {isCreateUserMode ? <Close /> : <Add />}
                  </AddButton>
                </SectionTitle>
                <Wrapper>
                <CouncilWrapper>
                  <TableContainer>
                    <Table size="small">
                      <TableBody>
                        {isCreateUserMode && selectedCouncil && <UserSectionNewItem onClose={onCloseUserDialog} onRefresh={onRefreshUsers} entityId={selectedCouncil.id} />} 
                        {users.map((user) => <UserSectionItem user={user} entityId={selectedCouncil.id} onRefresh={onRefreshUsers} />)}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CouncilWrapper> 
                </Wrapper>
              </>
            }
          </ModalRow>
        </>
    )
}

export default observer(CouncilSection)