import React from 'react'
import { theme, Text } from 'ui'
import phone from './phone'
import ReactMarkdown from 'react-markdown'
import MarkdownIt from 'markdown-it'
//const renderHTML =  require('react-render-html');
import parse from 'html-react-parser';

const mdParser = new MarkdownIt({ typographer: true }).disable(['image', 'list'])
// Remember old renderer, if overridden, or proxy to default renderer
var defaultRender = mdParser.renderer.rules.link_open || function (tokens, idx, options, env, self) {
    return self.renderToken(tokens, idx, options);
};
mdParser.renderer.rules.link_open = function (tokens, idx, options, env, self) {
    // If you are sure other plugins can't add `target` - drop check below
    var aIndex = tokens[idx].attrIndex('target');

    if (aIndex < 0) {
        tokens[idx].attrPush(['target', '_blank']); // add new attribute
    } else {
        tokens[idx].attrs[aIndex][1] = '_blank';    // replace value of existing attr
    }

    // pass token to default renderer.
    return defaultRender(tokens, idx, options, env, self);
};
var linkify = require('linkify-it')();
const LinkStyleText = (url: string, text: string) => {
    const urlRemoveWhiteSpace = url.replace(/\s/g, '')
    return `[${text}](${urlRemoveWhiteSpace})`
}
const LinkText = ({ messageText = '', messageFailedToSend }: { messageText: string, messageFailedToSend?: boolean }) => {
    let textWithLinks = []

    const matches = linkify.match(messageText || '')
    if (matches?.length > 0) {
        if (matches[0].index != 0) {
            textWithLinks.push(messageText.slice(0, matches[0].index))
        }
        matches.forEach((match: { url: string, text: string, index: number, lastIndex: number }, index: number) => {
            const oneCharBeforeStart = messageText.charAt(match.index - 1)
            const oneCharAfterEnd = messageText.charAt(match.lastIndex + 1)
            if (match.text?.toLowerCase() === 'https://www' || match.text?.toLowerCase() === 'http://www' || oneCharBeforeStart.includes('(') || oneCharAfterEnd.includes(')')) {
                textWithLinks.push(match.text)
            } else {
                textWithLinks.push(LinkStyleText(match.url, match.text))
            }
            if (matches[index + 1] && match.lastIndex != matches[index + 1].index) {
                textWithLinks.push(messageText.slice(match.lastIndex, matches[index + 1].index))
            } else {
                textWithLinks.push(messageText.slice(match.lastIndex, messageText.length))
            }
        });
    } else {
        textWithLinks.push(messageText)
    }

    textWithLinks.forEach((element, index) => {
        if (element && !element.startsWith('[')) {
            const TextWithPhones = phone.replacePhones(element)
            textWithLinks[index] = TextWithPhones
        }
    });

    return parse(mdParser.render(textWithLinks.join('')))


}
export default LinkText

// return (
//     <ReactMarkdown
//         linkTarget={(url, text, title) => {
//             if (url.startsWith('mailto') || url.startsWith('tel')) {
//                 return ''
//             } else {
//                 return '_blank'
//             }
//         }}
//     >{textWithLinks.join('')}</ReactMarkdown>
// )
