import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Dialog, TextField, TablePagination } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { theme, Button, Row, Spinner } from 'ui'
import { observer } from 'mobx-react'
import useStore from 'store'
import MessagesHeader from 'components/Messages/MessagesHeader'
import AddCsvScreen from './AddCsvScreen'
import CsvListScreen from './CsvListScreen'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { UserGroup } from 'utils/types'

const AutoCompleteContainer = styled(Autocomplete)`
  /* border-radius: 8px; */
  /* box-shadow: 0 2px 8px 0 ${theme.color.shadow}; */
    margin:10px 0 ;
`
const ContainerDialog = styled.div`
    flex-direction:column;
    display:flex;
    height:80vh;
    max-height:1000px;
    width:90vw;
    max-width:1400px;
    `
const ConfirmButton = styled(Button) <{ bgColor: string, hoverColor?: string }>`
  &.MuiButtonBase-root {
    font-size: ${theme.fontSize.large};
    font-weight: 500;
    min-width: 140px;
    height: 50px;
    margin: 0.5rem;
    background:${p => p.bgColor};
    &:hover {
      background: ${p => p.hoverColor ? p.hoverColor : theme.color.darkBlue};
    }
    color: ${theme.color.white};
    &.Mui-disabled {
      opacity: 0.5;
    }
  }

`
const ConfirmButtonsContainer = styled.div`
    display:flex;
    justify-content:space-between;
    width:100%;
    padding:10px;
    align-items:flex-end;
`

const AddMemberFromCSV: FC = ({ }) => {
    const { orgs, csvStore, groups } = useStore()
    const { t } = useTranslation('members')

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (csvStore.uploaded) {
            csvStore.resolveCsvUserList(page, rowsPerPage);
        }
    }, [csvStore.uploaded])

    const closeModal = () => {
        csvStore.closeCSVModal()
        csvStore.cleanCsvUsers()
    }

    const handleOk = async () => {
        setLoading(true)
        await csvStore.createUsers()
        setLoading(false)
    }

    const isSendDisabled = () => {
        let noOfSelectedUsers = csvStore.getCsvUsers.filter((csvUser: CsvUser) => {
            return csvUser.selected
        }).length
    
        if (noOfSelectedUsers === 0) {
            return true
        }
        return false
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        csvStore.resolveCsvUserList(newPage, rowsPerPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);

        csvStore.resolveCsvUserList(1, parseInt(event.target.value, 10));
    }

    return (
        <Dialog
            style={{ zIndex: 1200, overflow: 'hidden' }}
            open={csvStore.CSVModalOpen}
            maxWidth={'xl'}
        >
            <ContainerDialog>
                <MessagesHeader
                    disableIcon
                    textVarient={'black'}
                    backColor={theme.color.azureBlue + 33}
                    mainText={orgs.currentOrg.name}
                    secondText={t`addMany`}
                    // removeCloseIcon
                    onClose={closeModal}
                />
                {csvStore.rawUsersToImport.length === 0 ?
                    <AddCsvScreen /> :
                    <CsvListScreen />}

                <TablePagination
                  disabled={csvStore.rawUsersToImport.length === 0}
                  style={{ direction: 'ltr'}}
                  labelRowsPerPage=''
                  rowsPerPageOptions={[50, 100]}
                  component="div"
                  count={csvStore.rawUsersToImport.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />    

                <ConfirmButtonsContainer>
                    {csvStore.rawUsersToImport.length != 0 && <Row>
                        <AutoCompleteContainer
                            style={{ width: 200 }}
                            value={csvStore.relateToGroupsId}
                            onChange={(_: any, v: any) => csvStore.setRelateToGroup(v)}
                            options={groups.currentUserGroups?.sort((ug: UserGroup) => ug.group.group_is_member === false)}
                            placeholder={t`csvHeaders:addToGroup`}
                            renderOption={(option: any) => option?.group?.name}
                            getOptionLabel={(option: any) => option?.group?.name}
                            renderInput={(params) =>
                                <TextField {...params} value={csvStore.relateToGroupsId || ''} label={t`csvHeaders:addToGroup`} variant="outlined" />}
                        />
                        <ConfirmButton
                            disabled={loading ? true : isSendDisabled()}
                            bgColor={isSendDisabled() ? theme.color.confirmButton + 33 : theme.color.confirmButton}
                            onClick={handleOk}>
                             {loading && <Spinner color="white" size="2rem"/>}   
                             {!loading && t`app:add`}
                        </ConfirmButton>
                    </Row>}
                </ConfirmButtonsContainer>
            </ContainerDialog>
        </Dialog>
    )
}

export default observer(AddMemberFromCSV)
