import React from 'react'
import { observer } from 'mobx-react'
import useStore from 'store'
import Loading from 'core/Loading'
import HomeRoutes from 'pages/Home/HomeRoutes'
import MembersModal from 'components/Members/MembersModal'
import Layout from '../../core/Layout'
import { OrgDialog } from 'components/Orgs/index'
import { CommunitySettingsModal } from 'components/CommunitySettingsModal/index'
import { CouncilSettingsModal } from 'components/CouncilSettingsModal'
import { MapSettingsModal } from 'components/MapSettingsModal'
import CreateModuleModal from 'pages/Home/Budget/CreateModuleModal'
import Community from 'pages/Home/Community/Community'
import CreateGroupModal from 'components/Members/CreateGroupModal'
import WarningHomeModal from '../../components/WarningHomeModal'
import ServiceCallIframe from './ServiceCall/ServiceCallIframe'
import UserDetailsModal from 'components/Messages/UserDetailsModal'
import CreateEditModal from 'components/Members/createEditModal/createEditModal'
import RequestLocationModal from 'components/Messages/RequestLocationModal'
import RemoveGroupMembersDialog from 'components/Members/RemoveGroupMembersDialog'
import AddMemberFromCSV from 'components/Members/addMemberFromCsv/AddMemberFromCSV'
import MapMemberWithSocialId from 'components/Members/mapMemberWithSocialId/MapMemberWithSocialId'


function Home() {
  const { orgs, members } = useStore()

  if (!orgs.initialized) return <Loading />

  return (
    <Layout>
      <HomeRoutes />
      <Community />
      <WarningHomeModal />
      <ServiceCallIframe />
      <MembersModal />
      <CreateGroupModal />
      <OrgDialog />
      <RemoveGroupMembersDialog />
      <CreateEditModal />
      <UserDetailsModal />
      <RequestLocationModal />
      <AddMemberFromCSV />
      <MapMemberWithSocialId />
      <CommunitySettingsModal />
      <CouncilSettingsModal />
      <MapSettingsModal />
      <CreateModuleModal />
    </Layout>
  )
}

export default observer(Home)
