import React from 'react'

const icons = {}

import StopWatch from './mekome/icon_stopwatch.svg'
icons['stopWatch'] = StopWatch
import Schedule from './mekome/icon_schedule.svg'
icons['schedule'] = Schedule
import Clock from './mekome/icon_clock.svg'
icons['clock'] = Clock
import DocumentRoundBlue from './mekome/icon_document_round_blue.svg'
icons['documentRoundBlue'] = DocumentRoundBlue
import GalleryRoundGreen from './mekome/icon_gallery_round_green.svg'
icons['galleryRoundGreen'] = GalleryRoundGreen
import FoldedHands from './mekome/icon_folded_hands.svg'
icons['foldedHands'] = FoldedHands

export default icons