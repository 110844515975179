import React, { FC, useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react'
import { Close } from '@material-ui/icons'
import { Space, Input, theme, Row, Text } from 'ui'
import useStore from 'store'
import uiStore from '../../store/ui.store'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    IconButton, 
    Select, 
    MenuItem, 
    LinearProgress
  } from '@material-ui/core'
  

const StyledSelect = styled(Select)`
  .MuiSelect-iconOutlined {
    left: 7px;
    right: unset;
  }
`
const DialogContainer = styled(Dialog)`
  .MuiDialog-paperFullWidth{
    border-radius: 8px;
    width: 700px;
  }
`
const DialogHeader = styled.div`
  .MuiDialogTitle-root {
    display:flex;
    height: 64px;
    background: ${theme.color.dialogBackground};;
    align-items: center;
    justify-content:space-between;
    padding: 16px 36px;
  }
  .MuiIconButton-root{
    padding:0;
  }
`
const CustomButton = styled(Button)`
  width: 140px;
  height: 50px;
  border-radius: 9px;
  background-color: ${p => p.transparent ? 'transparent' : theme.color.confirmButton};
  color: ${p => p.transparent ? theme.color.black : theme.color.white};
  &.MuiButton-containedSecondary{
    background-color:${theme.color.blue};
  };

  &:hover {
    background: ${theme.color.blue + 99};
  }

  &.Mui-disabled {
    background: ${theme.color.blue + 99};
    color: ${theme.color.white};
}
`

const DialogFooter = styled(DialogActions)`
  display: flex;
  justify-content: space-between;
  background: ${theme.color.dialogBackground};
  height: 84px;
  padding: 16px 36px;
`
const DialogBody = styled(DialogContent)`
  background: ${theme.color.dialogBackground};
  border-top-width: ${p => p.isTop ? '1px' : '0px'};
  padding: 16px 36px;
`
const DialogHeaderText = styled.p`
  font-size: ${theme.fontSize.dialogHeader};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: ${theme.color.black};;
`
const SMember = styled.div`
  direction: ltr;
  body[dir=rtl] & {
    direction: rtl
  }
  background: white;
  border-radius: 8px;
  padding: 1rem 1rem 0.5rem 1rem;
`
const Name = styled.div`
  font-size: 1.6rem;
`
const Wrapper = styled.div`
  padding: 1rem;
  height: 300px;
  overflow: scroll;
`

const CancelButton = styled(Button)`
width: 140px;
height: 50px;
border-radius: 9px;
background-color: ${p => theme.color.red};
color: ${p => theme.color.white};

&.MuiButton-containedSecondary{
      background-color:${theme.color.dangerRed};
};
  
&:hover {
    background: ${theme.color.dangerRed + 99};
}

&.Mui-disabled {
    background: ${theme.color.dangerRed + 99};
    color: ${theme.color.white};
}
`

const RemoveGroupMembersDialog: FC = () => {
  const [countOfUsers, setCountOfUsers] = useState(5)
  const [deletingProgress, setDeletingProgress] = useState(-1)
  const [usersToRemove, setUsersToRemove] = useState([])
  const { t } = useTranslation('removeListOfUsers')

  const { user: userStore, members: membersStore, orgs: orgsStore } = useStore()

  const sleep = async (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  const handleOk = async () => {
    uiStore.openWarningModal({
        text: t`confirm_desc`,
        okText: t`ok`,
        cancelText: t`cancel`,
        okAction: async () => {
            setDeletingProgress(prevState => prevState + 1)

            for (const user of usersToRemove) {
                await  membersStore.removeFromCommunity(user.user_id);
                await sleep(500)
                setDeletingProgress(prevState => prevState + 1)
            }    
        
            await membersStore.getMembers(orgsStore.currentOrgId)

            setDeletingProgress(prevState => -1)
            setUsersToRemove(membersStore.orgMembersSorted.slice(0, countOfUsers))
        },
    })
  }

  useEffect(() => {
    if (userStore.isUserListToRemoveModalOpen) {
        setCountOfUsers(5)
        setUsersToRemove(membersStore.orgMembersSorted.slice(0, 5))
    }
  }, [userStore.isUserListToRemoveModalOpen])
  
  const selectCountfUsers = ({ target }) => {
    setCountOfUsers(target.value)
    setUsersToRemove(membersStore.orgMembersSorted.slice(0, target.value))
  } 

  return (
    <DialogContainer
      open={userStore.isUserListToRemoveModalOpen}
      fullWidth maxWidth='md'>
      <DialogHeader>
        <DialogTitle disableTypography>
          <DialogHeaderText>{t`title`}</DialogHeaderText>
          <IconButton onClick={() => {  userStore.setShowUserListToRemoveModal(false) }} >
            <Close />
          </IconButton>
        </DialogTitle>
      </DialogHeader>
      <DialogBody >
      {deletingProgress >-1 && usersToRemove.length > 0  && 
        <>
          {t`progress`}
          <LinearProgress variant="buffer" value={parseInt(deletingProgress * 100 / usersToRemove.length)} valueBuffer={100} />
          <Space/>
        </>
      }

      <Text>{t`count_of_users`}</Text>
      <StyledSelect
            labelId="SystemSelectLabel"
            id="SystemSelect"
            value={countOfUsers}
            onChange={selectCountfUsers}
            fullWidth
        >                
        {[5,10,20,30].filter(n => n == 5 || membersStore.orgMembersSorted.length > n).map(v => (
            <MenuItem value={v} key={`users_list_length${v}`}>{v}</MenuItem>
        ))}
        </StyledSelect>
        <Space />
        <Wrapper>
        {usersToRemove.map(member => (
            <SMember>
                <Row justify='between'>
                     <Name>{member.given_name + ' ' + member.family_name}</Name>
                </Row>
                <Space />
            </SMember>
        ))}
        </Wrapper>
      </DialogBody>
      <DialogFooter>
        <CustomButton disabled={deletingProgress != -1} onClick={() => {  userStore.setShowUserListToRemoveModal(false) }}>{t`cancel`}</CustomButton>
        <CancelButton disabled={deletingProgress != -1} onClick={handleOk}>{t`ok`}</CancelButton>
      </DialogFooter>
    </DialogContainer>
  )
}

export default observer(RemoveGroupMembersDialog)
