import styled from 'styled-components'
import {Input, Row, Text, theme} from '../../ui'
import {Button} from '@material-ui/core'

export const ModalText = styled(Text)`
  textcolor: theme.color.black + 99;
  weight: 'bold';
  font-size: 18px;
`

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 5px;
  white-space: nowrap;
`

export const ConfirmButton = styled(Button)`
  &.MuiButtonBase-root {
    font-size: ${theme.fontSize.large};
    font-weight: 500;
    min-width: 140px;
    height: 50px;
    margin: 0.5rem;
    background: ${theme.color.confirmButton};

    &:hover {
      background: ${theme.color.darkBlue};
    }

    color: ${theme.color.white};

    &.Mui-disabled {
      opacity: 0.5;
    }
  }
`

export const DeleteButton = styled(Button)`
  &.MuiButtonBase-root {
    font-size: ${theme.fontSize.large};
    font-weight: 500;
    min-width: 140px;
    height: 50px;
    margin: 0.5rem;
    background: ${theme.color.red};

    &:hover {
      background: ${theme.color.darkBlue};
    }

    color: ${theme.color.white};

    &.Mui-disabled {
      opacity: 0.5;
    }
  }
`

export const ModalRow = styled(Row)`
  flex-wrap: wrap;
`

export const DialogHeaderText = styled.p`
  font-size: ${theme.fontSize.dialogHeader};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: ${theme.color.black};
`

export const InputContainer = styled.div`
  position: relative;
`

export const OutlinedInput = styled(Input)`
  border-radius: 6px;

  .MuiOutlinedInput-notchedOutline {
    border: solid 2px ${theme.color.inputGrayBorder};
    border-radius: 6px;
  }

  background-color: ${theme.color.inputBackground};
`


export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
`

export const InputErrorText = styled.span`
  position: absolute;
  right: 6px;
  top: 60px;
  color: ${theme.color.dangerRed};
  font-size: 12px;
`

export const InputWrapper = styled(InfoWrapper)`
  width: 100%;
  flex-direction: row;
`