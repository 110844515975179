import React, { FC } from 'react'
import { observer } from 'mobx-react'

import styled from 'styled-components'

import { List, Icon, Button } from '@material-ui/core'
import { theme, Space } from 'ui'

import { Add } from '@material-ui/icons'
import useStore from 'store'

import OrgDialog from 'components/Orgs/OrgDialog'

import { useTranslation } from 'react-i18next'
import { canCreateCommunity } from 'utils/roles'

import GGroups from 'components/Groups'

interface Props { }

const StyledSidebar = styled.div`
  width: 25vw;
  max-width: 420px;
  min-width: 360px;
  text-align: center;
  overflow: hidden;
  margin: 19px 5px 19px 26px;
  box-shadow: 0 2px 4px 0 rgba(12, 0, 51, 0.1);
  border-radius: 5px;
  background: var(--white);
  body[dir=rtl] & {
    margin: 19px 26px 19px 5px;
  }
`


const StyledAdd = styled(Add)`
  display: block;
  margin: auto;
`
const Scroll = styled(List)`
  height: 100%;
  max-height: 77vh;
  overflow: auto;
`
const SidebarHeader = styled.div`
  background: ${theme.color.mekomiBlack};
  display: flex;
  flex-direction: column;
  padding: 0px 10px 10px 10px;
  justify-content: space-around;
  max-height: 109px;
`
const Communities = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
`
const CummunityAmountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
const LargeText = styled.p`
  color: ${theme.color.white};
  font-size: ${theme.fontSize.large};
  padding: 0px 7px 0px 7px;
`
const SmallText = styled.p`
  color: ${theme.color.white};
  font-size: ${theme.fontSize.small};
`

const NewCommunityContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

// TODO: replace placeholder "icon" when icon arrives
const AddCommunity = styled.div`
  width: 30px;
  height: 30px;
  background-color: #ffdb1c;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
  margin: 0px 7px 0px 7px;
`

const Wrap = styled.div`
  padding: 1rem;
  flex: 1;
  background: rgba(0,0,0,0.05);
  overflow: auto;
`


const Groups: FC<Props> = () => {
  const { groups } = useStore()
  return (
    <Wrap>
      <GGroups groups={groups.currentUserGroups} />
    </Wrap>
  )
}

export default observer(Groups)
