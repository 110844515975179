import React, { FC } from 'react'
import styled from 'styled-components'
import { Row, Space, theme, Icon as UiIcon } from 'ui'
import useStore, { messages } from 'store'
import { Dialog, IconButton, DialogContent, DialogTitle } from '@material-ui/core'
import { Close, ChevronLeft } from '@material-ui/icons'
import Grow from 'ui/Grow'
import { isTotzeret } from 'service/whiteLable'


const Info = styled.div`
  background: ${p => p.color || 'var(--light-gray)'};
  color: var(--text);
  padding: 0.7rem;
  /* border-radius: 5px 5px 0 0; */
  position:relative;
  flex-direction:row;
  justify-content:space-between;
  display:flex;
`
const InfoHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items:center;
`

const InfoCommunity = styled.div<{ textVarient?: string }>`
  color:${p => p.textVarient === 'black' ? theme.color.black + 'A1' : theme.color.white + 'A1'};
  margin-right: 8px;
  font-size: ${theme.fontSize.md};
  body[dir=rtl] & {
      margin-right: 0px;
      margin-left: 8px;
  }
`
const SecondText = styled.div<{ textVarient?: string }>`
  color:${p => p.textVarient === 'black' ? theme.color.black : theme.color.white};
  margin-right: 8px;
  font-size: ${theme.fontSize.extraLarge};
  body[dir=rtl] & {
      margin-right: 0px;
      margin-left: 8px;
  }
`

const HeaderTextContainer = styled.div`
  display:flex;
  flex-direction:column;
`

const IconBg = styled.div`
  height:60px;
  width:45px;
  background: ${p => isTotzeret ? 'transparent' : p.color};
  display: flex;
  align-items: ${isTotzeret ? 'center;' : 'flex-end;'}
  justify-content: center;
  padding:0.33rem 0;
  ${p => isTotzeret ? `border-left: 2px solid ${p.color}; border-radius: 0px;` : 'border-radius: 8px;'}
  ${p => isTotzeret ? '' : 'margin-left:1rem;'}
`

const IconComponent = styled.div`
  display: flex;
  align-items: center;
  ${p => isTotzeret ? 'margin-left:1rem;' : ''}
`

const ImgArrow = styled.div<{ color: string }>`
  border-style: solid;
  border-top-width: 7px;
  border-top-color: transparent;
  border-right-width: 8px;
  border-right-color: ${(p) => p.color};
  border-bottom-width: 7px;
  border-bottom-color: transparent;
  border-left-width: 0;
  border-left-color: transparent;
`
const StyledIconButton = styled(IconButton)`
  margin:auto;
`
interface props {
  mainText: string
  secondText: string
  onClose: any
  backColor?: string
  textVarient?: string
  showChevronIcon?: boolean
  disableIcon?: boolean
  removeCloseIcon?: boolean
}
const MessagesHeader: FC<props> = ({ mainText, secondText, onClose = () => { }, backColor, textVarient, showChevronIcon, disableIcon = false, removeCloseIcon = false }) => {
  const { groups } = useStore()
  const mainColor = groups?.currentGroup?.group?.description?.color
  const iconColor = groups?.currentGroup?.group?.description?.iconColor || '#d9d9d9'
  const iconName = groups.currentGroup?.group?.description?.icon || 'default'
  return (

    <Info color={backColor ? backColor : mainColor}>
      <InfoHeader>
        {!disableIcon && <IconComponent>
          <IconBg color={iconColor}><UiIcon size={30} name={iconName} /></IconBg>
          {isTotzeret && <ImgArrow color={iconColor}/>}
        </IconComponent>}
        <HeaderTextContainer>
          <Row style={{ marginBottom: 3 }}>
            <InfoCommunity textVarient={textVarient}>{mainText}</InfoCommunity>
          </Row>
          <SecondText textVarient={textVarient}>{secondText}</SecondText>
        </HeaderTextContainer>
      </InfoHeader>
      {!removeCloseIcon && (
        <div>
          <StyledIconButton onClick={onClose}>
            {showChevronIcon ? <ChevronLeft onClick={onClose} style={{ fontSize: 30 }} /> : <Close style={{ color: textVarient === 'black' ? 'black' : 'white' }} />}
          </StyledIconButton>
        </div>
      )}
    </Info>

  )
}

export default MessagesHeader
