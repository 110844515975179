import React, { FC, useState, useEffect, useMemo } from 'react'
import { Text, theme, Icon, Space, Row } from 'ui'
import styled from 'styled-components'
import useStore from 'store'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Description } from 'utils/types'

const StyledContainer = styled.div`
    flex:1;
    margin:0 10px;
    height:15px;
    background-color:${theme.color.searchGray};
    border-radius:20px;
    border-color:white;
    border-width:1px;
`
const StyledInner = styled.div<{ color: string }>`
    height:100%;
    background-color:${p => p.color};
    border-radius:20px;
    border-color:${theme.color.white};
    border-width:1px;
`
const RowItem = styled(Row) <{ bgColor: string }>`
    margin-bottom: 10px;
    border-top:1px solid ${theme.color.grayBackground};
    border-bottom:1px solid ${theme.color.grayBackground};
    border-radius: 12px;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.17);
    padding:20px 5px;
    background-color:white;
`

const MessageUserLocation: FC = () => {
  const { mapStore, groups } = useStore()
  const [data, setData] = useState([])

  const groupColor = groups.currentGroup?.group.description?.color || groups.defaultGroupDescription.color

  useEffect(() => {
    if (mapStore.showMessageLocationRequestDetailModal) {
        const data = []

        data.push( { title: 'בישוב', value: mapStore.groupMemberLocations.insideKibbutz, color: 'green' })
        data.push( { title: 'מחוץ לישוב', value: mapStore.groupMemberLocations.outsideKibbutz, color: 'red' })
        data.push( { title: 'דיווחו ללא מיקום', value: mapStore.groupMemberLocations.readMsgButDidntSendLocation, color: 'blue' })
        data.push( { title: 'לא ידוע', value: mapStore.groupMemberLocations.unknownLocation, color: '#fd9e3a' })

        setData(data)
    }
  }, [mapStore.showMessageLocationRequestDetailModal])
  
  return (
    <>
      { data.map((item, index: number) => {
        const precent = item.value / mapStore.groupMemberLocations.total * 100
        
        return (
          <>
            <RowItem bgColor={groupColor + 59}>
              <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <Row style={{ width: '100%' }}>
                  <Text>{item.title}</Text>
                  <Text style={{ width: 40, textAlign: 'center' }}>{precent.toFixed(0) + '%'}</Text>
                </Row>
                <Space height={0.7} />
                <Row style={{ width: '100%' }}>
                  <Row style={{ justifyContent: 'flex-start' }}>
                    <Text>{`${mapStore.groupMemberLocations.total}/`}</Text>
                    <Text textcolor={item.color}>{`${item.value}`}</Text>
                  </Row>
                  <StyledContainer>
                    <StyledInner style={{ width: `${precent}%` }} color={item.color} />
                  </StyledContainer>
                </Row>
              </div>
            </RowItem>
          </>
        )
      })}
    </>
  )
}

export default observer(MessageUserLocation)
