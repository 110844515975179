import { format, isWithinInterval, getDay, isToday, isYesterday, subDays, isThisYear, addDays } from 'date-fns'
import * as moment from 'moment'
import { isBefore, parse } from 'date-fns'

export function formatDate(date: Date | number, t: any) {
  const d = date
  if (isToday(d)) return t`date:today`
  if (isYesterday(d)) return t`date:yesterday`
  return isWithinInterval(d, { start: subDays(new Date(), 9), end: new Date() }) ? t(getDateFromNumber(getDay(d))) : isThisYear(d) ? format(d, 'dd.MM') : format(d, 'dd.MM.yyyy')
}
export function formatDateTime(date: Date, t: any) {
  const d = date
  if (isToday(d)) return t`date:today`
  if (isYesterday(d)) return t`date:yesterday`
  return isWithinInterval(d, { start: new Date(), end: addDays(new Date(), 9) }) ? t(getDateFromNumber(getDay(d))) : isThisYear(d) ? format(d, 'dd.MM') : format(d, 'dd.MM.yyyy')
}
const getDateFromNumber = (day: number) => {
  switch (day) {
    case 0: return 'date:sunday'
    case 1: return 'date:monday'
    case 2: return 'date:tuesday'
    case 3: return 'date:wednesday'
    case 4: return 'date:thursday'
    case 5: return 'date:friday'
    case 6: return 'date:saturday'
  }
}

export const dateFormat = (date: string, format: string) => {
  return moment(date, 'DD/MM/YYYY HH:mm').format(format)
}

export const sortByDate = (format: string, arr: any[], key: string) => {
  return arr.slice().sort((a, b) => {
    const A = parse(a[key], format, new Date())
    const B = parse(b[key], format, new Date())
    if (isBefore(A, B)) {
      return 1
    }
    if (isBefore(B, A)) {
      return -1
    }
    return 0
  }) || []
}

export const dateTimeFormat = (date: string, time: string, format: string) => {
  const dateTime = `${date} ${time}`
  return moment(dateTime, format).format(format)
}