import React, {FC, useEffect, useState} from 'react'
import {observer} from 'mobx-react'
import useStore from 'store'
import {ModuleEnum, ModuleType, ProviderEnum} from 'utils/types'
import {useTranslation} from 'react-i18next'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import {Space, Row, Icon, Text, theme} from '../../ui'
import {
    TextField,
    Dialog,
    IconButton,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Switch,
    Checkbox,
} from '@material-ui/core'
import { Close, ExpandMore } from '@material-ui/icons'
import { isTotzeret } from 'service/whiteLable'
import styled from 'styled-components'
import {
    ModalRow,
    InfoWrapper,
    ModalText,
    ConfirmButtonSm,
    DeleteButtonSm,
    ButtonGroup,
    DialogHeaderText
} from './styles'
import kehilanetData from '../../data/kehilanet.json';
import icons from 'components/Groups/groupCategories.json'
import { isAction } from 'mobx'

const StyledTextField = styled(TextField)`
  width: 100%;
`

const SelectContent = styled.div`
    align-items:center;
    flex-direction:row;
    display:flex;

`
const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  cursor:pointer;
`

const IconBg = styled.div<{ selected: boolean }>`
  background: ${p => isTotzeret ? 'transparent' : p.color};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0.33rem;
  border-radius: 8px;
  margin-right:35px;
  border-color: ${p => p.selected ? theme.color.select : theme.color.white};
  border-width: 4px;
  border-style: solid;
  body[dir=rtl] & {
    margin-right: unset;
    margin-left: 20px;
    }
`

export interface Express106FormState {
    moduleId: null,
    name: string,
    description: string,
    url: string,
    data: string,
    information: string,
    iconColor: string,
    iconText: string,
    orgId: string,
    isEnable: boolean
}

interface Express106SectionProps {
    currentOrgId: number | undefined
    resetForm: boolean
    isFormChanged: boolean
    Save: () => void
    Delete: () => void
    ToggleEnable: (value: boolean) => void
    ChangeName: (value: string) => void
    ChangeData: (value: string) => void
    ChangeInformation: (value: string) => void
    ChangeDescription: (value: string) => void
    ChangeUrl: (value: string) => void
    ChangeOrgId: (value: boolean) => void
    ChangeIconColor: (value: boolean) => void
}

const IconSelect: FC<{ icons: any, onChange: any, givenSelectedIcon: any }> = ({ icons, onChange, givenSelectedIcon }) => {
    const [selectedIcon, setSelectedIcon] = useState<string | undefined>(givenSelectedIcon)
    const clickHandler = (iconOptions: any) => {
        setSelectedIcon(iconOptions.icon)
        onChange(iconOptions)
    }
    return (
        <IconsContainer>
            {
                icons.map((iconOptions: any) => (
                    <IconBg selected={selectedIcon == iconOptions.icon} color={iconOptions.iconColor} onClick={() => clickHandler(iconOptions)}>
                        <Icon name={iconOptions.icon} />
                    </IconBg>
                ))
            }
        </IconsContainer >
    )
}

const Express106Section: FC<Express106SectionProps> = ({ currentOrgId, resetForm }) => {
    const {modulesStore, orgs} = useStore()
    const {t} = useTranslation('communitySettings')

    const [isFormChanged, setIsFormChanged] = useState<boolean>(false)
    const [module, setModule] = useState<any>(null)
    const [formState, setFormState] = useState<Express106FormState>({
        moduleId: null,
        name: '',
        description: '',
        url: '',
        iconColor: '',
        iconText: '',
        information: '',
        orgId: currentOrgId,
        data: '',
        isEnable: false
    }) 

    useEffect(() => {
        const module106 = modulesStore.communityModules
          .find((module) => module.type === ModuleEnum.EXPRESS_106)

        if (module106 != undefined) {
            setModule(module106)

            let information = ''
            if (module106.information) {
                const parseInformation = JSON.parse(module106.information);
                information = parseInformation[0]
            }

            setFormState({
                moduleId: module106.module_id,
                name: module106.title,
                description: module106.description,
                url: module106.external_url,
                iconColor: module106.icon_color,
                information: information,
                orgId: module106.org_id,
                isEnable: module106.enable,
                iconText: module106.icon_name,
                data: module106.data
            })
        }
    }, [orgs.currentOrgId])

    useEffect(() => {
        if (resetForm) onResetForm()
    }, [resetForm])

    useEffect(() => {
        let moduleInformation = ''
        if (module?.information) {
            const parseInformation = JSON.parse(module?.information);
            moduleInformation = parseInformation[0]
        }

        setIsFormChanged(
            formState.information !== (moduleInformation ?? '') ||
            formState.data !== (module?.data ?? '') ||
            formState.name !== (module?.title ?? '') ||
            formState.description !== (module?.description ?? '') ||
            formState.url !== (module?.external_url ?? '') ||
            formState.iconColor !== (module?.icon_color ?? '') ||
            formState.iconText !== (module?.icon_name ?? '') ||
            formState.orgId !== (module?.org_id ?? '') ||
            formState.isEnable !== (module?.enable ?? '')
        )
    }, [formState, module])


    const Save = async () => {
        const newModule: ModuleType = {
            communty_id: module.community_id,
            community_ref: module.community_ref,
            enable: formState.isEnable,
            module_id: module.module_id,
            type: ModuleEnum.EXPRESS_106, 
            title: formState.name, //string && nullable
            description: formState.description, //string && nullable
            external_url: formState.url, //string && nullable
            icon_name: formState.iconText, //string && nullable
            icon_text: '',
            icon_color:formState.iconColor,
            org_id: formState.orgId || null,
            provider: ProviderEnum.EXPRESS_106,
            information: JSON.stringify({ 0: formState.information }),
            data: formState.data,
            share: false,
            shareEmail: ''
        }

        await modulesStore.updateModule(newModule)
        await modulesStore.getCommunityModulesAndFeatures(currentOrgId)
        
        const module106 = modulesStore.communityModules
        .find((module) => module.type === ModuleEnum.EXPRESS_106)

        if (module106 != undefined) {
            setModule(module106)
        }
    }
    
    const Delete = async () => {
        await modulesStore.deleteModule(module.module_id as number)
        await modulesStore.getCommunityModulesAndFeatures(currentOrgId)

        setModule(null)
        onResetForm()
    }

    const onResetForm = () => setFormState({
        moduleId: null,
        name: '',
        description: '',
        url: '',
        iconColor: '',
        iconText: '',
        orgId: currentOrgId,
        data: '',
        information: '',
        isEnable: false
    })

    const ToggleEnable = (value: any) => setFormState({...formState, isEnable: value })
    const ChangeName = (value: any) => setFormState({...formState, name: value })
    const ChangeData = (value: any) => setFormState({...formState, data: value })
    const ChangeInformation = (value: any) => setFormState({...formState, information: value })
    const ChangeDescription = (value: any) => setFormState({...formState, description: value })
    const ChangeUrl = (value: any) => setFormState({...formState, url: value })

    const onChangeIcon = (icon) => {
        setFormState({...formState, iconText: icon.icon, iconColor: icon.iconColor })
    }

    return (
        <>
        {module && <>
            <hr/>
            <Space/>
            <ModalRow>
               <DialogHeaderText>{'106 אקספרס'}</DialogHeaderText>
               <ButtonGroup>
                 <DeleteButtonSm  disabled={module == null} onClick={Delete}>{t`common:deletion`}</DeleteButtonSm>
                 <ConfirmButtonSm disabled={!isFormChanged || module == null} onClick={Save} transparent>{t`save`}</ConfirmButtonSm>
               </ButtonGroup>
            </ModalRow>

            
                <>
                <ModalRow>
                    <InfoWrapper>
                        <ModalText>{t`isEnable`}:</ModalText>
                        <Space/>
                        <Switch
                            color="primary"
                            value={formState.isEnable}
                            onChange={({target}) => {
                                ToggleEnable(target.checked)
                            }}
                            checked={formState.isEnable}
                        />
                    </InfoWrapper>
                </ModalRow>

                <Space height={1}/>
                <StyledTextField
                    value={formState.name}
                    id="outlined-basic"
                    label={'כותרת על לחצן הצד ימין'} 
                    variant="outlined"
                    onChange={({target}) => {
                        ChangeName(target.value)
                    }}
                />

                <Space height={1}/>
                <StyledTextField
                    value={formState.description}
                    id="outlined-basic"
                    label={'כותרת משנה'} 
                    variant="outlined"
                    onChange={({target}) => {
                        ChangeDescription(target.value)
                    }}
                />

                <Space height={1}/>
                <StyledTextField
                    value={formState.url}
                    id="outlined-basic"
                    label={'תבנית לינק'} 
                    variant="outlined"
                    onChange={({target}) => {
                        ChangeUrl(target.value)
                    }}
                />

                <Space width={1} />
                <TextareaAutosize
                    style={{ width: '100%', minHeight: '80px' }}
                    rowsMin={4}
                    defaultValue={formState.data}
                    placeholder={'טקסט פתיחה במרכז המסך'}
                    variant="outlined"
                    onChange={(event) => ChangeData(event.target.value)}
                />

                <Space height={1}/>
                <StyledTextField
                    value={formState.information}
                    id="outlined-basic"
                    label={t`information`} 
                    variant="outlined"
                    onChange={({target}) => {
                        ChangeInformation(target.value)
                    }}
                />

               <Space height={1}/>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <SelectContent>
                            <Space width={1} />
                                {formState.iconText && 
                                <IconBg selected={false} color={formState.iconColor}>
                                    <Icon name={formState.iconText} />
                                </IconBg>}
                                <Text>{formState.iconText ? t`switchIcon` : t`icon`}</Text>
                        </SelectContent>
                    </AccordionSummary>
                        <AccordionDetails>
                            <IconSelect
                                icons={icons}
                                onChange={onChangeIcon}
                                givenSelectedIcon={formState.iconText} 
                            />
                        </AccordionDetails>
                </Accordion>
            </>
            
            <Space/>
            <Space/>
            </>}
        </>
    )
}

export default observer(Express106Section)