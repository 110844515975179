import React, { FC } from 'react'
import { Space, Spinner, Text, theme } from 'ui'
import useStore from 'store'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Money from './Money'
import BudgetNav from './BudgetNav'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Path } from 'core/Routes'
import BudgetTransactions from './BudgetTransactions'
import Header from './Header'
import { PaymentType } from 'store/budget.store'
import PosTab from './PosTab'
import BudgetTab from './BudgetTab'
import PosTransactions from './PosTransactions'
import Grow from 'ui/Grow'
import HelpIcon from '@material-ui/icons/Help'
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import { SortTypes } from 'store/budget.store'
import StoreTransactions from './StoreTransactions'
import FilesTab from './FilesTab'

const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items:center;
  justify-content:center;
  display:flex;
  background-color:rgba(0, 0, 0, 0.2)
`
const StyledColumn = styled.div`
  flex-direction:column;
`

const StyledFooter = styled.div`
  display:flex;
  background-color:${theme.color.footPurple};
  border-top-left-radius:1rem;
  border-top-right-radius:1rem;
  padding:0.5rem 1rem 0.5rem 1rem;
  flex:1;
`

const StyledDialogText = styled(DialogContentText)`
  &.MuiDialogContentText-root{
      text-align: center;
      font-weight: 500;
      padding-right: 1rem;
      padding-left: 1rem;
      width: 325px;
      line-height: 30px;
      padding-top: 15px;
  }
`

const StyledDialogActions = styled(DialogActions)`
  background: #f1f3f6;
  border: #bbbbbb solid 1px;
  height: 47px;
  justify-content: center;
  cursor: pointer;
`


const IncompatibilitiesDialog = observer(() => {
  const { t } = useTranslation('budget')
  const { budgetStore } = useStore()
  return (
    <Dialog
      maxWidth='sm'
      open={budgetStore.budgetIncompatibilitiesModalOpen}
      onClose={() => { budgetStore.budgetIncompatibilitiesModalOpen = false }}>
      <DialogContent>
        <StyledDialogText>
          {t`incompatibilitiesMessage`}
        </StyledDialogText>
      </DialogContent >
      <StyledDialogActions onClick={() => { budgetStore.budgetIncompatibilitiesModalOpen = false }}>
        {t`close`}
      </StyledDialogActions>
    </Dialog >
  )
})

const Footer: React.FC = observer(() => {
  const { budgetStore } = useStore()
  const { t } = useTranslation('budget')
  const sum = budgetStore.paymentType === PaymentType.BUDGET ? budgetStore.budgetTotal : budgetStore.posTotal
  const title = budgetStore.isCurrentPeriodSelected() ? t`summery.current_status` : t`summery.monthly_status`

  const isShowFooter = () => {
    if (budgetStore.paymentType === PaymentType.POS) {
      return budgetStore.posList.length
    }
    else if (budgetStore.paymentType === PaymentType.BUDGET) {
      return budgetStore._transactions && budgetStore._transactions.length
    }
    else if (budgetStore.paymentType === PaymentType.FILES) {
      return false
    }
    return true
  }

  if (budgetStore.isDisabled) {
    return null;
  }
  if (!isShowFooter()) {
    return null
  }

  return (
    <div style={{ display: 'flex', alignSelf: 'stretch', backgroundColor: theme.color.dialogBackground }}>
      <StyledFooter>
        <StyledColumn>
          <Text textcolor={theme.color.white60} style={{ fontSize: 8 * 3 }} >{title}</Text>
          <Space height={0.5} />
          <Money amount={budgetStore.agorotToShekel(sum * -1)} />
        </StyledColumn>
        <Grow />
        {budgetStore.paymentType === PaymentType.POS &&
          <StyledColumn style={{ justifyContent: 'end', display: 'flex' }}>
            <Text textcolor={theme.color.white60} style={{ fontSize: 8 * 3 }} >
              {t`incompatibilities`} &nbsp;
              <HelpIcon style={{ color: 'white', cursor: 'pointer' }} onClick={() => { budgetStore.budgetIncompatibilitiesModalOpen = true }} />
            </Text>
          </StyledColumn>
        }
      </StyledFooter>
      <IncompatibilitiesDialog />
    </div>
  )
})
const Container = styled.div`
  position: relative;
  margin-top:10px;
  display:flex;
  flex:1;
  flex-direction:column;
  align-items:center;
  max-width:calc(60vw - 20px);
  /* background-color:${theme.color.loginPurple}; */
`
const Wrap = styled.div`
    display:flex;
    flex:1;
    background-color:${theme.color.dialogBackground};
    justify-content:center;
`

const StyledContent = styled.div`
  position: relative;
  display:flex;
  flex-direction:column;
  flex:1;
  background-color:${theme.color.dialogBackground};
  width:100%;
  padding:0.75rem 1rem 0 1rem;
  /* border-top-left-radius:16px;
  border-top-right-radius:16px; */
  overflow:hidden;
`
const Content: React.FC = observer(() => {
  const { budgetStore } = useStore()
  const renderContent = () => {
    switch (budgetStore.paymentType) {
      case PaymentType.BUDGET:
        return <BudgetTab />
      case PaymentType.POS:
        return <PosTab />
      case PaymentType.FILES:
        return <FilesTab />
      default:
        return null
    }
  }

  return (
    <StyledContent>
      <BudgetNav />
      {renderContent()}
      {
        budgetStore.isLoading && (
          <SpinnerContainer>
             <Spinner />
          </SpinnerContainer>
        )
      }
    </StyledContent>
  )
})
const Budget: FC = () => {
  return (
    <Wrap>
      <Container>
        <Header />
        <Content />
        <Footer />
      </Container>
    </Wrap>
  )
}

const BudgetRouter: React.FC = () => {
  const { budgetStore } = useStore()
  React.useEffect(() => {
    return () => {
      // this will kick you out of the user budget page if you edit css while using demo
      if (budgetStore.useDemo) {
        budgetStore.setUseDemo(false)
        budgetStore.clear()
      }
      if (budgetStore.rememberMe === false) {
        budgetStore.clear()
      }
    }
  }, [])

  if (!budgetStore.isAuth && !budgetStore.useDemo) {
    return <Redirect to={Path.HOME} />
  }
  return (
    <Router>
      <Switch>
        <Route path={Path.USER_BUDGET + '/:id'}>
          <Wrap>
            <Container>
              {(() => {
                if (budgetStore.paymentType === PaymentType.BUDGET) {
                  return budgetStore.sortType === SortTypes.STORE ?
                    <StoreTransactions /> : <BudgetTransactions />
                }
                if (budgetStore.paymentType === PaymentType.POS) {
                  return <PosTransactions />
                }
                return null
              })()}
              {
                budgetStore.isLoading && !budgetStore.useDemo && (
                  <SpinnerContainer>
                    <Spinner />
                  </SpinnerContainer>
                )
              }
            </Container>
          </Wrap>
        </Route>
        <Route path="/">
          <Budget />
        </Route>
      </Switch>
    </Router>
  );
}
export default observer(BudgetRouter)
