import React, { FC, useEffect, useState } from 'react'
import useStore, { uiStore } from 'store'
import { saveCsvIsraeliIdErrors } from 'utils/csvUtils'
import styled from 'styled-components'
import { Dialog, TablePagination } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { theme, Button, Text, Spinner } from 'ui'
import { observer } from 'mobx-react'
import { snackBar } from 'store'
import MessagesHeader from 'components/Messages/MessagesHeader'
import Dropzone from 'react-dropzone'
import { Attachment } from '@material-ui/icons'
import api from 'service/api'
import CsvListScreen from './CsvListScreen'

const ContainerDialog = styled.div`
    flex-direction:column;
    display:flex;
    height:80vh;
    max-height:1000px;
    width:90vw;
    max-width:1400px;
`
const DropArea = styled.div`
    border-radius: 4px;
    color: rgba(0,0,0,0.6);
    background: #fafafa;
    border: 1px dashed ${theme.color.gray};
    display: flex;
    justify-content:center;
    align-items: center;
    flex:1;
    height: 100%;
`
const DropAreaContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`
const DropAreaIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const ConfirmButtonsContainer = styled.div<{ flex: boolean }>`
    display:flex;
    cursor: pointer;
    justify-content:space-between;
    flex: ${p => p.flex ? '2' : '1'};
    padding:10px;
    align-items:flex-end;
    height:  100%;
`
const Container = styled.div<{ height: boolean }>`
    display:flex;
    width: 100%;
    height: ${p => p.height ? 'calc(100% - 90px)' : '100%'};
    flex-direction: column;
`
const ConfirmButton = styled(Button) <{ bgColor: string, hoverColor?: string }>`
  display: flex;
  width: max-content;
  &.MuiButtonBase-root {
    font-size: ${theme.fontSize.large};
    font-weight: 500;
    min-width: 140px;
    height: 50px;
    margin: 0.5rem;
    background:${p => p.bgColor};
    &:hover {
      background: ${p => p.hoverColor ? p.hoverColor : theme.color.darkBlue};
    }
    color: ${theme.color.white};
    &.Mui-disabled {
      opacity: 0.5;
    }
  }
`                    

const DropCsvView = ({ handleUpload }: { handleUpload: (fils: File[]) => void }) => {
    const { t } = useTranslation('members')

    return (
        <Dropzone
            onDrop={handleUpload}
            maxFiles={1}
            onDropRejected={(e) => {
                if (e && e[0]?.errors && e[0]?.errors[0]?.code === 'too-many-files') {
                    snackBar.showError(t`fileLimit`)
                }

                if (e && e[0]?.errors && e[0]?.errors[0]?.code === 'file-invalid-type') {
                    snackBar.showError(t`MessageDialog:notCSV`)
                }
            }}
            multiple={false}
        // accept={['text/csv']}
        >
            {({ getRootProps, getInputProps }) => (
                <DropArea {...getRootProps()}>
                    <input {...getInputProps()} />
                    <DropAreaContent>
                        <DropAreaIconsContainer>
                            <Attachment />
                        </DropAreaIconsContainer>
                        <Text align={'center'} textcolor={theme.color.black + 'A1'}>{t`dropCsv`}</Text>
                    </DropAreaContent>
                </DropArea>
            )}
        </Dropzone>
    )
}

const MapMemberWithSocialId: FC = ({ }) => {
    const { orgs, csvStore } = useStore()
    const { t } = useTranslation('members')
  
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(100)
    const [loading, setLoading] = useState(false)

    const closeModal = () => {
        csvStore.handleCSVMapSocialIdwithCntactModal(false)
        csvStore.clearCsvFileIsraelIds()
        csvStore.clearCsvFileIsraelIdErrors()
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        csvStore.resolveCsvIsraelIdList(newPage, rowsPerPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);

        csvStore.resolveCsvIsraelIdList(0, parseInt(event.target.value, 10))
    }

    const handleOk = async () => {
        csvStore.clearCsvFileIsraelIdErrors()
        setLoading(true)

        try {
            const result = await api.uploadIsraelId(csvStore.getCsvSocialIds
                .filter(item => item.selected)
                .map(item => {
                  
                  let newItem = {
                    guid: item.guid,
                    israeliID: item.israeliID
                  }
                
                  if ('phone' in item) {
                    newItem['phone'] = item.phone
                  }

                  if ('email' in item) {
                    newItem['email'] = item.email
                  }
                    
                  return newItem
                }), 
                orgs.currentBrandId
            )

            const uploadErrors = result.reduce((acc, item) => {
                acc.set(item.guid, item)
                return acc;
            }, new Map()) 

            csvStore.setCsvFileIsraelIdErrors(uploadErrors)
            
            const failedRows = result.filter(item => item.errorKey !== null)

            if (failedRows.length > 0 ) {
                console.log("")
                snackBar.showError(t`MessageDialog:errorsList`)
            } else {
                snackBar.showSuccess("עודכן בהצלחה")
            }

            setLoading(false)
        } catch (error) {
            snackBar.showError(t`MessageDialog:errorsList`)
            setLoading(false)
        }
    }

    const handleUpload = async (files: File[]) => {
        if (files.length != 1) {
            return
        }

        try {
            const { hasErrors, errors } = await csvStore.processCsvFileIsraelIds(files[0])

            if (hasErrors) {
                uiStore.openWarningModal({
                    text: t`MessageDialog:errorsList`,
                    secondText: "על מנת לייצא את השגיאות לקובץ חדש יש ללחוץ על כפתור ייצוא",
                    okText: 'ייצוא',
                    cancelText: "סגור",
                    cancelAction: () => {
                        closeModal()
                    },
                    okAction: () => {
                        saveCsvIsraeliIdErrors(errors, t);
                        closeModal()
                    }
                })    
            } else {
                csvStore.resolveCsvIsraelIdList(page, rowsPerPage)
            }
        } catch (error) {
            console.error(error)
            snackBar.showError(t`MessageDialog:errorsList`)
        }
    }

    return (
        <Dialog
            style={{ zIndex: 1200, overflow: 'hidden' }}
            open={csvStore.CSVMapSocialIdwithCntactModalOpen}
            maxWidth={'xl'}
        >
            <ContainerDialog>
                <MessagesHeader
                    disableIcon
                    textVarient={'black'}
                    backColor={theme.color.azureBlue + 33}
                    mainText={orgs.currentOrg.name}
                    secondText={t`mapSociaNumber`}
                    onClose={closeModal}
                />
                <Container height={csvStore.getRawCsvSocialIds.length > 0}>
                    {csvStore.getRawCsvSocialIds.length == 0 &&
                        <ConfirmButtonsContainer flex={csvStore.getRawCsvSocialIds.length == 0}>
                             {<DropCsvView handleUpload={handleUpload} />}
                        </ConfirmButtonsContainer>
                    }

                    {csvStore.getRawCsvSocialIds.length > 0 &&
                      <>
                        <CsvListScreen />
                        <TablePagination
                            disabled={false}
                            style={{ direction: 'ltr'}}
                            labelRowsPerPage=''
                            rowsPerPageOptions={[50, 100, 200, 300]}
                            component="div"
                            count={csvStore.getRawCsvSocialIds.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />    

                        <ConfirmButton
                          disabled={loading}
                          bgColor={loading ? theme.color.confirmButton + 33 : theme.color.confirmButton}
                          onClick={handleOk}
                        >
                            {loading && <Spinner color="white" size="2rem"/>}   
                            {!loading && t`app:add`}
                        </ConfirmButton>
                      </>
                    }
                </Container>
            </ContainerDialog>
        </Dialog>
    )
}

export default observer(MapMemberWithSocialId)
