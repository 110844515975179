import React, { FC, useEffect, useState } from 'react'
import EmptyComponent from 'components/Empty'
import emptyBudget from 'assets/icons/emptyBudget.svg'
import styled from 'styled-components'
import { List, Button, Icon as MuIcon } from '@material-ui/core'
import { theme, Text, Row, Space, Icon, Image, Spinner } from 'ui'
import ThirdList from './ThirdList'
import DeliveryList from './DeliveryList'
import useStore, { budgetStore } from 'store'
import { observer } from 'mobx-react'
import type { ModuleType } from 'utils/types'
import { useTranslation } from 'react-i18next'
import { Add } from '@material-ui/icons'
import SlikaModule from './SlikaModule'
import { canEditCommunityModules } from 'utils/roles'
import { ModuleEnum } from 'utils/types'
import BudgetLoginModal from './budgetLognModal/BudgetLoginModal'
import BudgetSummery from './BudgetSummery'

const Container = styled.div`
    display:flex;
    flex:1;
    flex-direction:column;

    `
const SmallText = styled.p`
    color: ${theme.color.black};
    font-size: ${theme.fontSize.small};
`

const NewCommunityContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
const ButtonContainer = styled.div`
    display: flex;
    align-self:flex-start;
    margin-top:10px;
    flex-direction:column;
    position:absolute;
    top:80px;
    left:10px;
`
const StyledAdd = styled(Add)`
    display: block;
    margin: auto;
    color:white;
`
const AddCommunity = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${theme.color.sendingBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
  margin: 0px 7px 0px 7px;
`

const Wrap = styled.div`
  background-color:${theme.color.dialogBackground};
  @media (max-width: 768px) {
    background: rgba(0,0,0,0.05);
    padding: 1rem;
  }
  flex:1;
  overflow:auto;
`
const SpinnerContainer = styled.div`
  flex:1;
  align-items:center;
  justify-content:center;
  display:flex;
`

const Budget: FC = () => {
  const { t } = useTranslation(['budget'])

  const { modulesStore, budgetStore } = useStore()
  const { isInited, isReadyToInit } = budgetStore;
  const modules = modulesStore?.communityModules
  const listOfThird = modulesStore?.communityModules.filter(i => i.type === ModuleEnum.THIRD)
  const deliveryList = modulesStore?.communityModules.filter(i => i.type === ModuleEnum.DELIVERY)
  const hasBudget = Boolean(modulesStore.budgets?.length)

  const editPortal = () => {
    const portal = modulesStore?.communityModules.filter(i => i.type === ModuleEnum.PORTAL)
    modulesStore.openModuleModalEditMode(portal[0].module_id)
  }
  const userCanEditCommunityModules = canEditCommunityModules()

  useEffect(() => {
    if (!isInited && isReadyToInit) {
      budgetStore.initAction();
    }
  }, [isInited, isReadyToInit])

  if (modulesStore.isLoading || budgetStore.isLoading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    )
  }
  return (
    <Wrap>
      {/* {userCanEditCommunityModules && <ButtonContainer>
        <Button
          onClick={() => modulesStore.openModuleModal()}><NewCommunityContainer>
            <SmallText>{t`addNew`}</SmallText>
            <AddCommunity ><MuIcon>{<StyledAdd />}</MuIcon></AddCommunity>
          </NewCommunityContainer>
        </Button>
      </ButtonContainer>} */}
      {(deliveryList.length != 0 || listOfThird.length != 0 || hasBudget) ? (
        <Container>
          {(budgetStore.isAuth) ? <BudgetSummery /> : <SlikaModule />}
          <Space />
          <ThirdList list={listOfThird} />
          <Space />
          {deliveryList.length != 0 && <div className={'budgetBreak'}>
            <span>{t`ordering`}</span>
          </div>}
          <Space />
          <DeliveryList list={deliveryList} />
        </Container>
      ) :
        <EmptyComponent
          icon={<Image src={emptyBudget} />}
          description={'תוכלו לראות כאן את התקציב המנוייד שלכם ועוד שירותים פיננסיים של הקהילה'}
          title={'עוד קצת סבלנות…'}
        />
      }
      <BudgetLoginModal />
    </Wrap>
  )
}


export default observer(Budget)

