import React, { FC, useState, useEffect, useMemo } from 'react'
import { Text, theme, Icon, Space, Row } from 'ui'
import styled from 'styled-components'
import useStore from 'store'
import { useTranslation } from 'react-i18next'
import { ExpandMore, ExpandLess } from '@material-ui/icons'
import { Collapse, UnmountClosed } from 'react-collapse';
import mobileChat from 'assets/icons/detailsIcon/mobile_chat.svg'
import newsLetter from 'assets/icons/detailsIcon/newsletter.svg'
import textSms from 'assets/icons/detailsIcon/textsms.svg'
import { formatDate } from 'utils/date'
import { formatIfPhoneNumber, intToHex } from 'utils/converts'
import { MessageResponse, User, SurveyAnswer } from 'utils/models'
import { deliveryType, Description } from 'utils/types'

const StyledContainer = styled.div`
    flex:1;
    margin:0 10px;
    height:15px;
    background-color:${theme.color.searchGray};
    border-radius:20px;
    border-color:white;
    border-width:1px;
`

const StyledInner = styled.div<{ color: string }>`
    height:100%;
    background-color:${p => p.color};
    border-radius:20px;
    border-color:${theme.color.white};
    border-width:1px;
`

const RowItem = styled(Row) <{ bgColor: string }>`
       border-top:1px solid ${theme.color.grayBackground};
       border-bottom:1px solid ${theme.color.grayBackground};
       padding:20px 5px;
       background-color:${p => p.bgColor};

`
interface SurveyAnswerProps {
  answers?: SurveyAnswer[]
}

const SectionContainer = styled.div`
  display: flex;
  align-items:center;
  justify-content:space-between;
  flex-direction:row;
  padding:15px;
  border-bottom:2px solid ${theme.color.white};
    background-color:${p => p.color};
  `
const SectionRow = styled(Row)`
  width:30%;
  display: flex;
  align-items:center;
  justify-content:center;
  text-align:center;

  `
const PhonesContainer = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
  text-align:center;
    word-break:break-word;
    flex:1;
    flex-direction:column;
  `


const SectionComponent = ({ item }: { item: MessageResponse }) => {
  const { groups, members } = useStore()
  const groupColor = (groups.currentGroup?.group.description as Description)?.color || theme.color.defaultThemeColorGray
  const { t } = useTranslation('messageDetails')

  const member: User | undefined = members?.getById(item.user_ref)
  if (!member) {
    return <div />
  }

  const name = `${member.family_name} ${member.nick_name ? '(' + member.nick_name + ')' : ''} ${member.given_name}`;

  let phones: Array<string> = [];
  if (member.phones) {
    phones = [...member.phones]
  }
  if (member.emails) {
    phones = [...phones, ...member.emails]
  }
  return (
    <SectionContainer color={groupColor + '33'} >
      <SectionRow>
        <Space width={1} />
        <Text
          weight={'normal'}
          size={'md'}
        >{name}</Text>
      </SectionRow>
      <PhonesContainer >
        {phones.map(i => <Text
          weight={'normal'}
          size={'md'}>{formatIfPhoneNumber(i || '')}</Text>)}
      </PhonesContainer>
      <Row style={{ width: '15%' }}>
        <Row >
          <Space width={0.5} />
          <Text
            size={'sm'}
          >{formatDate(new Date(item.updated), t)}</Text>
          <Space width={0.5} />
          {item.delivery_type === deliveryType.MOBILE && <img src={mobileChat} />}
          {item.delivery_type === deliveryType.MOBILE2 && <img src={mobileChat} />}
          {item.delivery_type === deliveryType.MAIL && <img src={newsLetter} />}
          {item.delivery_type === deliveryType.SMS && <img src={textSms} />}
        </Row>
      </Row>
    </SectionContainer>
  )
}

const SurveyProgress: FC<SurveyAnswerProps> = ({ answers }) => {
  const { groups, newMessage } = useStore()
  let responsesCount = 0
  for (var key in newMessage.messageResponsesByResponse) {
    if (newMessage.messageResponsesByResponse.hasOwnProperty(key)) {
      responsesCount = responsesCount + newMessage.messageResponsesByResponse[key].length
    }
  }
  const groupColor = (groups.currentGroup?.group.description as Description)?.color || '#707070'
  const membersCount = groups.groupMembers.filter(el => el.is_member).length
  return (
    <>
      {  answers?.map((answer: SurveyAnswer, index: number) => {
        const [open, toggle] = useState(false)
        const precent = (newMessage.messageResponsesByResponse[index + 1]?.length || 0) / (responsesCount || 0) * 100 || 0
        return (
          <>
            <RowItem
              bgColor={open ? groupColor + 59 : 'transparent'}
              onClick={precent ? () => toggle(s => !s) : () => { }}>
              <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <Row style={{ width: '100%' }}>
                  <Text>{answer.text}</Text>
                  <Text
                    style={{ width: 40, textAlign: 'center' }}
                  >{precent.toFixed(0) + '%'}</Text>
                </Row>
                <Space height={0.7} />
                <Row style={{ width: '100%' }}>
                  <Row style={{ justifyContent: 'flex-start' }}>
                    <Text>{`${membersCount}/`}</Text>
                    <Text
                      textcolor={intToHex(answer.color) || theme.color.surveyBlue}
                    >{`${newMessage.messageResponsesByResponse[index + 1]?.length || 0}`}</Text>
                  </Row>
                  <StyledContainer>
                    <StyledInner
                      style={{ width: `${precent}%` }}
                      color={intToHex(answer.color) || theme.color.surveyBlue}
                    />
                  </StyledContainer>
                  {newMessage.messageResponsesByResponse[index + 1]?.length > 0 ? open ? <ExpandLess /> : <ExpandMore /> : <div style={{ width: 25 }} />}
                </Row>
              </div>
            </RowItem>
            <Collapse
              isOpened={open}>
              <div>
                {newMessage.messageResponsesByResponse[index + 1] && newMessage.messageResponsesByResponse[index + 1].map((u: MessageResponse) => <SectionComponent item={u} />)}
              </div>
            </Collapse>
          </>
        )
      })}
    </>
  )
}

export default SurveyProgress
