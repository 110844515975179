import React from 'react'

import { action, computed, IObservableArray, observable, ObservableMap, reaction, runInAction, toJS } from 'mobx'
import orgs from 'store/orgs.store'
import groups from 'store/groups.store'
import membersStore from 'store/members.store'
import api from 'service/api'
import * as moment from 'moment'
import { containsLocation } from 'utils/mapGeometry'
import { formatIfPhoneNumber, intToHex } from 'utils/converts'
import { StatusType } from 'utils/types'


export enum sendTypes {
  sendSmsAndEmailTrue,
  sendSmsFalse,
  sendEmailFalse,
  sendSmsAndEmailFalse
}
class MapStore {

  constructor() {
    reaction(
      () => orgs.currentOrgId,
      id => {
        if (id) {
          const timestamp = new Date().valueOf()
          this.initDefaultDateRange() 
          this.getLocationData(`${timestamp}`)
          //this.initMapPoligon()
        }
      },
      { fireImmediately: true }
    )

    reaction(
      () => orgs.settings,
      settings => {
        if (settings) {
          this.initMapPoligon()
        }
      },
      { fireImmediately: true }
    )
  }

  @observable checkedMarkers: IObservableArray<any> = observable({})
  @observable checkedItems: IObservableArray<any> = observable({})
  @observable markers: IObservableArray<any> = observable({})
  @observable poligons: IObservableArray<any> = observable([])
  @observable logs: IObservableArray<any> = observable([])
  @observable recentLocations: IObservableArray<any> = observable([])
  @observable map: IObservableArray<any> = null
  @observable maps: IObservableArray<any> = null
  @observable enableAutoRefresh: IObservableArray<boolean> = false
  @observable newLocationRequestKey: IObservableArray<string> = ''
  @observable filterDates: IObservableArray<any> = null
  @observable loading: IObservableArray<boolean> = false
  
  @observable showMessageLocationRequestDetailModal: IObservableArray<boolean> = false
  @observable locationMessage: IObservableArray<any> = null
  @observable locationMessageUsers: IObservableArray = observable([]) 
  @observable groupMemberLocations: IObservableArray<any> = observable({
    insideKibbutz: 0,
    outsideKibbutz: 0,
    unknownLocation: 0,
    readMsgButDidntSendLocation: 0,
    total: 0,
  })
  
  @action openMessageLocationRequestDetailModal = async (userMessage) => {
    this.locationMessage = userMessage
    
    if (!groups.currentGroupId || !orgs.currentOrgId) {
      this.showMessageLocationRequestDetailModal = true
      return
    }

    const communityMapSettings = orgs.settings
    if (!communityMapSettings.geoData) {
      this.showMessageLocationRequestDetailModal = true
      return
    }

    try {
      const geoData = JSON.parse(communityMapSettings.geoData);
      
      if (!('coordinates' in geoData)) {
        this.showMessageLocationRequestDetailModal = true
        return;
      }

      if (!(Array.isArray(geoData.coordinates) && geoData.coordinates.length > 0)) {
        this.showMessageLocationRequestDetailModal = true
        return;
      }

      const poligonCoords = geoData.coordinates[0]
      .filter(c => Array.isArray(c) && c.length == 2)
      .map(c => {  
        return { lat: c[1], lng: c[0] }
      })
 
      const fromDate =  moment(this.locationMessage.message.created)
      const toDate = fromDate.clone().add(1, 'hours')

      const queryParms = {
        fromDate: fromDate.utc().toISOString(),
        toDate: toDate.utc().toISOString()  
      }
      
      const res = await api.getLastUserLocation(orgs.currentOrgId, queryParms);
      const members = await groups.getGroupMembers(groups.currentGroupId)
      
      const messageDetails = await api.messageDetails(userMessage.message.id)
      const messageMembers = messageDetails.reduce((acc, item) => {
        acc[item.user_ref] = item
        return acc
      }, {})  
    
      const memberLocations = res.locations
        .filter(location => location.messageId == this.locationMessage.message.id)
        .reduce((acc, location) => {
          acc[location.userId] = {  lat: location.lat, lng: location.lng, date: location.updatedDate }
          return acc;
        }, {})

      const locationMessageUsers = []  
      const groupMemberLocations = members.reduce((acc, user) => {
        const userData = membersStore.data.get(user.user_id)
        
        let locationMessageUser = {
          firstName: user.given_name,
          lastName: user.family_name,
          phone1: userData != undefined && userData.phones != null && userData.phones.length > 0 ? formatIfPhoneNumber(userData.phones[0]) : '',
          phone2: userData != undefined && userData.phones != null && userData.phones.length > 1 ? formatIfPhoneNumber(userData.phones[1]) : '',
          email: userData != undefined && userData.emails != null && userData.emails.length > 0 ? userData.emails[0] : '',
          messageStatus: '', 
          location: '', 
          date: '',
        }
        
        if (!(user.user_id in memberLocations)) {
          if (user.user_id in messageMembers && messageMembers[user.user_id].status > StatusType.RECEIVED) {
            acc['readMsgButDidntSendLocation'] += 1
            locationMessageUser.location = 'לא זמין'
            locationMessageUser.messageStatus = 'קראו'
          } else {
            acc['unknownLocation'] += 1
            locationMessageUser.location = 'לא ידוע'
          }
          
          locationMessageUsers.push(locationMessageUser)
          return acc
        }

        const userLocation = memberLocations[user.user_id]

        if (!userLocation.lat || !userLocation.lng) {
          if (user.user_id in messageMembers && messageMembers[user.user_id].status > StatusType.RECEIVED) {
            acc['readMsgButDidntSendLocation'] += 1
            locationMessageUser.location = 'לא זמין'
            locationMessageUser.messageStatus = 'קראו'
          } else {
            acc['unknownLocation'] += 1
            locationMessageUser.location = 'לא ידוע'
          }
          
          locationMessageUsers.push(locationMessageUser)
          return acc
        }

        if (this.containsLocation(userLocation.lat, userLocation.lng, poligonCoords))  {
          acc['insideKibbutz'] += 1
          locationMessageUser.location = 'בישוב'
        } else {
          acc['outsideKibbutz'] += 1
          locationMessageUser.location = 'מחוץ לישוב' 
        }

        locationMessageUser.messageStatus = userMessage.status
        locationMessageUser.date = moment.utc(userLocation.date).format('DD/MM/YYYY HH:mm')
        locationMessageUsers.push(locationMessageUser)

        return acc
        }, {
          insideKibbutz: 0,
          outsideKibbutz: 0,
          unknownLocation: 0,
          readMsgButDidntSendLocation: 0,
          total: members.length
      })

      this.groupMemberLocations = groupMemberLocations
      this.locationMessageUsers = locationMessageUsers
      this.showMessageLocationRequestDetailModal = true

    } catch (e) {
      return console.error(e); 
    }
  }

  @action toggleMarker = (checked, userId) => {
    this.checkedMarkers[userId] = checked
    if(userId in this.markers) {
      this.markers[userId].setVisible(checked)
    }
  }

  @action closeMessageLocationRequestDetailModal = () => {
    this.showMessageLocationRequestDetailModal = false
  }

  getDefaultLocation = () => {
    return {
        lat: 32.06167168299145,
        lng: 35.03119539579602
    }
  }

  initDefaultDateRange = () => {
    this.filterDates = {
      from: moment().subtract(1, 'days'),
      to: moment()
    }  
  } 

  @action setMap = ({ map, maps }) => {
    this.showRefreshIcon = false 
    this.map = map
    this.maps = maps

    this.initMapPoligon()
  }

  @action initMapPoligon = () => {
    this.poligons.map(poligon => {
      poligon.setMap(null)
    })

    const communityMapSettings = orgs.settings
   
    if (!communityMapSettings.geoData) {
      return
    }

    try {
      const geoData = JSON.parse(communityMapSettings.geoData);
      if (!('coordinates' in geoData)) {
        return;
      }

      if (!(Array.isArray(geoData.coordinates) && geoData.coordinates.length > 0)) {
        return;
      }

      const triangleCoords = geoData.coordinates[0]
      .filter(c => Array.isArray(c) && c.length == 2)
      .map(c => {  
        return { lat: c[1], lng: c[0] }
      })

      if(this.map && this.maps) { 
        const poligon = new this.maps.Polygon({
          paths: triangleCoords,
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0,
        });

        poligon.setMap(this.map);
        this.poligons = [poligon]
      }

    } catch (e) {
      return console.error(e); 
    }
  }

  @action containsLocation = (lat, lng, poligonCoords) => {
    if (poligonCoords.length == 0) {
      return null
    }

    const position = { lat,lng };
    return containsLocation(position, poligonCoords)
  }

  @action getLastUserLocation = async (filterData: any, initCenterCoords: boolean = true) => {
    if (orgs.currentOrgId) {
      const queryParms = {
        fromDate: filterData.from.utc().toISOString(),
        toDate: filterData.to.utc().toISOString()  
      }
      const res = await api.getLastUserLocation(orgs.currentOrgId, queryParms);
      this.recentLocations = res.locations
      this.renderMarkers(res, initCenterCoords)
    }
  }
    
  @action getLocationLog = async (filterData: any) => {
    if (orgs.currentOrgId) {
      const queryParms = {
        fromDate: filterData.from.utc().toISOString(),
        toDate: filterData.to.utc().toISOString()  
      }

      const res = await api.getLocationLog(orgs.currentOrgId, queryParms);
      this.logs = res
      this.loading = false
    }
  }

  @action getLocationData = async (key: string,  refreshAttempt: number = 1, isManualRefresh: boolean = false) => {
    if (refreshAttempt == 1) {
      this.loading = true
      this.checkedMarkers = {}
      this.enableAutoRefresh = isManualRefresh ? false : true
      this.newLocationRequestKey = key
    }  

    // Disable refresh to old requests.
    if (this.newLocationRequestKey !== key) {
      this.loading = false
      return;
    }

    if (refreshAttempt > 1) {
      const data = this.filterDates 
      data['to'] = moment();
    
      this.filterDates = {
        from: data.from,
        to: data.to
      }
    }
    
    if(this.map && this.maps) { 
      const initCenterCoords = !isManualRefresh && refreshAttempt == 1
      await Promise.all([
        this.getLastUserLocation(this.filterDates, initCenterCoords),
        this.getLocationLog(this.filterDates)
      ]);

      if (refreshAttempt > 10) {
        this.enableAutoRefresh = false
      }
  
      if (this.enableAutoRefresh) {
        setTimeout(() => {
          this.getLocationData(key, ++refreshAttempt, isManualRefresh)
        }, refreshAttempt < 3 ? 10000 : (refreshAttempt < 5 ? 40000 : 60000))
      }
    }
  }

  renderMarkers = ({ locations, communityCenter }, initCenterCoords: boolean = true) => {
    // clear markers
    Object.values(this.markers).map(marker => {
        marker.setMap(null)
    })

    const markers = {}
    this.markers = {}

    const googleLocations = []
    let poligonCoords = []

    if (this.poligons.length > 0) {
      poligonCoords = this.poligons[0].getPath().getArray().map(item => {
        return { lat: item.lat(), lng: item.lng() }
      })
    }
    
    locations.forEach(location => {
        const googleMapLocation = new this.maps.LatLng(location.lat, location.lng)
        googleLocations.push(googleMapLocation)

        const isInsidePoligon = this.containsLocation(location.lat, location.lng, poligonCoords)

        let marker = new this.maps.Marker({
            position: googleMapLocation,
            icon: location.note == 'התקבל דיווח מצוקה' || (location.note === null && location.interfaceUsed && location.interfaceUsed.toLowerCase() == 'mobile') 
              ? '/marker.png' 
              : isInsidePoligon ? '/marker3.png' : '/marker2.png',
            map: this.map,
            title: location.fullName
        });

        if (location.userId in this.checkedMarkers) {
          marker.setVisible(this.checkedMarkers[location.userId])
        }

        markers[location.userId] = marker
    });

    this.markers = markers

    if (!initCenterCoords) {
      return
    }

    const key = `map_center${orgs.currentOrgId}`
    const mapCenterCoordsStr = localStorage.getItem(key)
    let mapCenterCoords = null

    if (mapCenterCoordsStr) {
      mapCenterCoords = JSON.parse(mapCenterCoordsStr)
      mapCenterCoords = mapCenterCoords.lat != null && mapCenterCoords.lng != null && mapCenterCoords.zoom != null 
        ? mapCenterCoords 
        : null
    }

    if (mapCenterCoords != null) {
      this.map.panTo(new this.maps.LatLng(mapCenterCoords.lat, mapCenterCoords.lng));
      this.map.setZoom(mapCenterCoords.zoom);

    // Define center view by kibbutz coords.
    } else if(communityCenter && communityCenter.lat && communityCenter.lng && communityCenter.zoom) {
      this.map.panTo(new this.maps.LatLng(communityCenter.lat, communityCenter.lng));
      this.map.setZoom(communityCenter.zoom);
    
      // Define center view by default coords.
    } else if (locations.length == 0) {
      const defaultLocation = this.getDefaultLocation()

      this.map.panTo(new this.maps.LatLng(defaultLocation.lat, defaultLocation.lng));
      this.map.setZoom(11);

    // Define center view by center of markers.
    } else {
      let bounds = new this.maps.LatLngBounds();

      googleLocations.map(location => {
        bounds.extend(location);
      })
      
      this.map.fitBounds(bounds)    
    }
  };
}

const mapStore = new MapStore()
export default mapStore


