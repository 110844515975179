import React from 'react'
import useStore from 'store'
import { observer } from 'mobx-react'
import { Redirect, useParams } from 'react-router'
import { Path } from 'core/Routes'
import PaymentTransactions from './PaymentTransactions'
import { sortByDate } from '../../../utils/date'

const BudgetTransactions: React.FC = () => {
  const { budgetStore } = useStore()
  let { id }: { id: string } = useParams();
  const payment = budgetStore.transactions()[Number(id)]
  if (!payment || payment.items?.length === 0) {
    return (
      <Redirect
        to={{
          pathname: Path.USER_BUDGET,
        }}
      />
    )
  }
  const transactions = sortByDate('dd/MM/yyyy hh:mm', payment.items || [], 'dateOfCharge')
  return (
    <PaymentTransactions
      payment={payment}
      transactions={transactions}
      title={budgetStore.getBudgetTitle(payment)}
      total={payment.totalSum}
    />
  )
}

export default observer(BudgetTransactions)