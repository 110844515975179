import React from 'react'
import useStore from 'store'
import { observer } from 'mobx-react'
import { useParams } from 'react-router'
import { sortByDate, dateFormat } from '../../../utils/date'
import { SortTypes, OuterItem } from 'store/budget.store'
import styled from 'styled-components'
import { theme } from 'ui'
import GlobalHeader from './Header'
import { Header, Footer, Transaction } from './PaymentTransactions'

const StyledContainer = styled.div`
  display:flex;
  flex:1;
  flex-direction:column;
  align-self:stretch;
  background-color:${theme.color.dialogBackground};
  overflow-y:auto;
`
const StyledContent = styled.div`
  display:flex;
  flex-direction:column;
  flex:1;
  background-color:${theme.color.dialogBackground};
  padding:16px;
  overflow:auto;
`

const Content: React.FC<{ transactions: OuterItem[] }> = ({ transactions }) => {
    const { budgetStore } = useStore()
    const shouldCollapseDefault = transactions.length != 1
    return (
        <StyledContent>
            {
                transactions.map(transaction => {
                    return <Transaction transaction={transaction} name={transaction.name || ''} description={transaction.description || ''} shouldCollapseDefault={shouldCollapseDefault} />
                })
            }
        </StyledContent>
    )
}

const StoreTransactions: React.FC = () => {
    const { budgetStore } = useStore()
    let { id }: { id: string } = useParams()
    const chargeeTransactions = budgetStore.transactions(SortTypes.DATE).filter(el => (el.chargee === id))
    const chargee = budgetStore.chargers.find(charger => charger.id === id)
    const total = budgetStore.summary?.find(el => el.chargeeId === id)?.chargingSum || '0'
    const getTransactionsList = () => {
        let dateList: OuterItem[] = []
        chargeeTransactions.forEach(t => {
            t.items?.forEach(i => {
                i.name = budgetStore.getBudgetName(t, i)
                i.description = budgetStore.getBudgetDescription(t, i)
                i.dateOfCharge = i.dateOfCharge || dateFormat(t.dateOfCharge, 'DD/MM/yyyy HH:mm')
            })
            dateList = [...dateList, ...(t.items || [])]
            if (!t.items?.length) {
                dateList.push({
                    ...t as OuterItem,
                    dateOfCharge: dateFormat(t.dateOfCharge, 'DD/MM/yyyy HH:mm'),
                    name: t.details,
                    description: budgetStore.getChargeeById(t.chargee)?.name['he']
                })
            }
        })
        dateList = sortByDate('dd/MM/yyyy HH:mm', dateList, 'dateOfCharge')
        return dateList
    }
    return (
        <StyledContainer>
            <GlobalHeader />
            <Header title={chargee?.name['he']} />
            <Content transactions={getTransactionsList()} />
            <Footer total={total} />
        </StyledContainer>

    )
}

export default observer(StoreTransactions)