import { useContext, createContext } from 'react'

import user from './user.store'
import orgs from './orgs.store'
import messages from './messages.store'
import members from './members.store'
import groups from './groups.store'
import newMessage from './newMessage.store'
import modulesStore from './modulesStore.store'
import uiStore from './ui.store'
import snackBar from './snackbars.store'
import budgetStore from './budget.store'
import memberStore from './member.store'
import csvStore from './csvStore.store'
import mapStore from './map.store'

export const stores = {
  user,
  orgs,
  messages,
  members,
  groups,
  newMessage,
  modulesStore,
  uiStore,
  snackBar,
  budgetStore,
  memberStore,
  csvStore,
  mapStore
}

export {
  user,
  orgs,
  messages,
  members,
  groups,
  newMessage,
  modulesStore,
  uiStore,
  snackBar,
  budgetStore,
  memberStore,
  csvStore,
  mapStore
}

const storesContext = createContext(stores)

export default function useStore() {
  return useContext<typeof stores>(storesContext)
}
