export const promiseMaker = (): { promise: Promise<any>, res: any, rej: any } => {
  let promise: Promise<any>
  let res: any, rej: any
  promise = new Promise((resolve, reject) => {
    res = resolve
    rej = reject
  })
  return {
    promise,
    res,
    rej,
  }
}