import React, { FC } from 'react'
import styled from 'styled-components'
import { Button, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Close, Send, LiveHelp } from '@material-ui/icons'
import { theme, Text, Row, Space } from 'ui'
import { observer } from 'mobx-react'
import useStore from 'store'
import Switch from '@material-ui/core/Switch'
import { errorsType } from 'store/budget.store'
import { useHistory } from 'react-router'
import { Path } from 'core/Routes'

const SwithComponent = styled(Switch)`

.MuiSwitch-colorPrimary{
    color:${theme.color.budgetPurple};
}
.MuiSwitch-track{
    background-color:${theme.color.budgetPink};
}
`
const Content = styled.div`
display:flex;
flex-direction:column;
justify-content:space-around;
width:80%;
height:50%;
margin-top:10px;
`
const StyledSend = styled(Send)`
  color:white;
  transform: rotate(180deg);
`

const StyledTextField = styled(TextField)`
    width:100%;
    margin:10px;
    direction:rtl;
    @media (max-width: 768px) {
      width: unset;
    }
`
const StyledButton = styled(Button)`
  height:50px;
  width:100%;
  background-color: ${theme.color.cerulean};
  border-radius: 5px;
  color:white;
  :hover {background-color: ${theme.color.cerulean};}
`
const CodeStep: FC = ({ }) => {
  const { budgetStore } = useStore()
  const { t } = useTranslation('budget')
  const h = useHistory()

  const setCode = (value: string) => {
    if (budgetStore.errors.get(errorsType.CODE)) {
      budgetStore.errors.delete(errorsType.CODE)
    }
    budgetStore.setCode(value)
  }
  const handleAuth = async () => {
    const res = await budgetStore.verify()
    if (res) {
      await budgetStore.init()
      if (budgetStore.isAuth) {
        h.push(Path.USER_BUDGET)
      }
      budgetStore.clearAll()
      budgetStore.closeBudgetLogin()
    }
  }
  const onEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleAuth()
    }
  }
  const handleAnotherPhone = () => {
    budgetStore.sendAnotherPhone()
  }
  return (

    <Content>
      <StyledTextField
        value={budgetStore.code}
        error={budgetStore.errors.get(errorsType.CODE)}
        label={t`auth:code`}
        variant='outlined'
        onChange={(event) => setCode(event.target.value)}
        onKeyUp={onEnter}
        helperText={budgetStore.errors.get(errorsType.CODE)}
        InputProps={{
          endAdornment: budgetStore.code != '' ?
            <Close
              style={{ color: theme.color.black + 99 }}
              onClick={() => budgetStore.setCode('')}
            /> : <div />
        }}
      />
      <Row>
        <Button
          onClick={() => budgetStore.sendAgain()}
        >
          <Text
            style={{ cursor: 'pointer' }}
            textcolor={theme.color.budgetBlue}
            weight={'medium'}
            size={'lg'}
          >{t`newCode`}</Text>
        </Button>
        <Text
          textcolor={theme.color.black}
          weight={'medium'}
          size={'lg'}
        >{' | '}</Text>
        <Button onClick={handleAnotherPhone}>
          <Text
            style={{ cursor: 'pointer' }}
            textcolor={theme.color.budgetBlue}
            weight={'medium'}
            size={'lg'}
          >{t`anotherPhone`}</Text>
        </Button>
      </Row>
      <StyledButton
        onClick={handleAuth}
        type="submit"
        variant='contained'>
        <Text
          size={'md'}
          textAlign={'center'}
          textcolor={'white'}
          weight='normal'>
          {t`enterBudget`}
        </Text>
        <Space width={0.7} />
      </StyledButton>
    </Content >
  )
}

export default observer(CodeStep)


