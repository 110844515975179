import { trimArray } from "utils/arrays"
import { validEmail, validMobileSimple, validId } from "utils/validations"
import type { User } from "utils/models"
import * as XLSX from 'xlsx';

enum UserInfo {
  LAST_NAME = 0,
  FIRST_NAME = 1,
  NICK = 2,
  ISRAELI_ID = 3,
  FIRST_PHONE = 4,
  SECOND_PHONE = 5,
  EMAIL = 6
}

enum CSVType {
  GROUP = 0
}

interface UserNewData {
  given_name: string
  family_name: string
  nick_name: string
  israeliID: string,
  phones: string[]
  emails: string[]
  has_app?: boolean
}

const isCSVvalid = (csvContent: string[][], type: number): boolean => {
  switch (type) {
    case CSVType.GROUP:
      return (csvContent[1][UserInfo.LAST_NAME] === 'שם משפחה' &&
        csvContent[1][UserInfo.FIRST_NAME] === 'שם פרטי' &&
        csvContent[1][UserInfo.NICK] === 'מידע נוסף' &&
        csvContent[1][UserInfo.FIRST_PHONE] === 'טלפון נייד 1' &&
        csvContent[1][UserInfo.SECOND_PHONE] === 'טלפון נייד 2' &&
        csvContent[1][UserInfo.EMAIL] === 'אימייל' &&
        csvContent[1][UserInfo.ISRAELI_ID] === "מס' זהות")
    default:
      return false
  }
}

const csvToArray = (csvStr: string): string[][] => {
  const csvContent = csvStr.split('\n').map(val => {
    return val.split('\t').map(str => {
      const arr = str.split('')

      if (arr.length > 0) {
        if (arr[arr.length - 1] === '' || arr[arr.length - 1].charCodeAt(0) === 8207) {
          arr.pop()
        }
      }

      return arr.join('')
    })
  })
  csvContent.pop()
  return csvContent
}

const readFile = async (file: File): string => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (e) => {
      resolve(e?.target?.result)
    }

    reader.onerror = function (event: Event) {
      reject(Error('TODO: more info about the error'))
    }

    reader.readAsText(file)
  })
}

const readExcelFile = async (file: File): string => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      resolve(data)
    }
    reader.onerror = function (event: Event) {
      reject(Error('TODO: more info about the error'))
    }

    reader.readAsBinaryString(file)
  })
}
const splitToRows = (csvStr: string): string[][] => {
  const split = csvStr.split('\n')
  //if \n appears at the end of file, we remove last item
  if (split.length && csvStr[csvStr.length - 1] === '\n') {
    split.splice(split.length - 1, 1)
  }

  return split.map(stringRow => stringRow.split(','))
}

enum csvMapData {
  FIRST_NAME = 0,
  LAST_NAME = 1,
  NICK = 2,
  ISRAELI_ID = 3,
  FIRST_PHONE = 4,
  SECOND_PHONE = 5,
  EMAIL = 6,
  IN_APP = 7,
  USER_ID = 8
}
const checkHeaders = (Headers: string[]): boolean => {
  const firstName = 0, lastName = 0, nickname = 0, phone1 = 0, phone2 = 0, email = 0, isUsingApp = 0
  Headers.map(stringItem => {
    stringItem.replace(/ +/g, "")
  })

  return true

}
const splitUsers = (rowCsv: string[][]): Array<User> => {
  let newUsers: Array<User> = []

  rowCsv.slice(3, rowCsv.length).forEach(csvRow => {
    let user: User = {
      given_name: csvRow[csvMapData.FIRST_NAME],
      family_name: csvRow[csvMapData.LAST_NAME],
      nick_name: csvRow[csvMapData.NICK],
      israeliID: csvRow[csvMapData.ISRAELI_ID],
      phones: [],
      emails: [],
      user_id: +csvRow[csvMapData.USER_ID]
      // has_app:csvRow[csvMapData.IN_APP].localeCompare('True') ? true : false
    }

    // Add mobile phone if it's not empty
    if (csvRow[csvMapData.FIRST_PHONE] !== '') {
      user.phones.push(csvRow[csvMapData.FIRST_PHONE])
    }

    // Add mobile phone if it's not empty
    if (csvRow[csvMapData.SECOND_PHONE] !== '') {
      user.phones.push(csvRow[csvMapData.SECOND_PHONE])
    }

    // Add email address if it's not empty
    if (csvRow[csvMapData.EMAIL] !== '') {
      user.emails.push(csvRow[csvMapData.EMAIL])
    }
    
    newUsers.push(user)
  });

  return newUsers
}

const formatPhone = (phone) => {
   return phone.trim().replace(/[-\(\)]/g, '') 
}

const splitIsraelIds = (rowCsv: string[][]): Array<{ israeliID: string, id: string }> => {
  let israelIds: Array<{ israelId: string, contact: string }> = []

  if (rowCsv.length < 3) {
    throw Error('invalid file format')
  }

  if (rowCsv[2].length < 6 || rowCsv[2][0] !== 'שם פרטי') {
    throw Error('invalid file format')
  }

  rowCsv.slice(3, rowCsv.length).forEach((csvRow,i) => {
    
    if (csvRow.length < 6) {
      return
    }

    let row: { guid: string, israeliID: string, email?: string|null, phone?: string|null, selected: boolean } = {
      israeliID: '',
      selected: true,
      guid: '',
      phone: '',
      email: ''
    }

    // Add israel id if it's not empty
    //if (csvRow[3].trim() !== '') {
    row['israeliID'] = csvRow[3].trim()
    //}

    const phone1 = formatPhone(csvRow[4])
    const phone2 = formatPhone(csvRow[5])

    // Add mobile phone 1 if it's not empty
    if (phone1 !== '') {
      row['phone'] = phone1 

       // Add mobile phone 2 if it's not empty
    } else if(phone2 !== '') {
      row['phone'] = phone2
      
       // Add mobile email if it's not empty
    }
    
    if (csvRow[6].trim() !== '' && validEmail(csvRow[6].trim())) {
      row['email'] = csvRow[6].trim()
    }

    //if (row['israeliID'] !== '') {
    row['guid'] = `${row['israeliID']}_${i}`
    israelIds.push(row)
    //} 
  });

  return israelIds
}

export const getUsersFromCsv = async (file: File) => {
  //  const  fileText = await readFile(file)
  const excelFileText = await readExcelFile(file)
  return getUsersFromStringArray(excelFileText)
}

export const geIsraelIdsFromCsv = async (file: File) => {
  const fileText = await readFile(file)
  return getIsraelIdsFromStringArray(fileText)
}

const getIsraelIdsFromStringArray = async (fileText: string) => {
  const rowsCsv = splitToRows(fileText)
  return splitIsraelIds(rowsCsv);
}

const getUsersFromStringArray = async (fileText: string) => {
  const rowsCsv = splitToRows(fileText)
  const validCsv = checkHeaders(rowsCsv[2])
  const csvUsers = splitUsers(rowsCsv)
  return csvUsers
}



// use in member dialog need to delete when remove member dialog
export const parseGroupImport = async (file: File, groupId: number, communityId: number) => {
  // return this.readFile(files).then(this.generateGroupImportArray.bind(this, group, community));
  let fileText: string
  try {
    fileText = await readFile(file)
    let importData = await generateGroupImportArray(groupId, communityId, fileText)
    return importData
  }
  finally {
    console.log(fileText)
  }
}

const generateGroupImportArray = async (groupId: number, communityId: number, result: string) => {
  const csvContent = trimArray(csvToArray(result))

  if (!isCSVvalid(csvContent, CSVType.GROUP)) {
    throw new Error('Headers don\'t match')
  }

  const newUsers = []

  for (let i = 2; i < csvContent.length; i++) {
    const row = csvContent[i]

    if (!row[UserInfo.FIRST_PHONE] && !row[UserInfo.SECOND_PHONE] && !row[UserInfo.EMAIL]) {
      continue
    }

    // Get existing user to know if we need to update or create a new user
    let newData: UserNewData = {
      given_name: '',
      family_name: '',
      nick_name: '',
      israeliID: '',
      phones: [],
      emails: []
    }

    newUsers.push({
      israeliID: row[UserInfo.ISRAELI_ID],
      given_name: row[UserInfo.FIRST_NAME],
      family_name: row[UserInfo.LAST_NAME],
      nick_name: row[UserInfo.NICK],
      israeliID: row[UserInfo.ISRAELI_ID],
      phones: [],
      emails: []
    })
    
    newData = newUsers[newUsers.length - 1]

    // Add mobile phone if it's not empty
    if (row[UserInfo.FIRST_PHONE] !== '') {
      newData.phones.push(row[UserInfo.FIRST_PHONE])
    }

    // Add mobile phone if it's not empty
    if (row[UserInfo.SECOND_PHONE] !== '') {
      newData.phones.push(row[UserInfo.SECOND_PHONE])
    }

    // Add email address if it's not empty
    if (row[UserInfo.EMAIL] !== '') {
      newData.emails.push(row[UserInfo.EMAIL])
    }
  }
}