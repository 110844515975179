import React, { FC, useState, FormEvent } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Button, TextField } from '@material-ui/core'
import Page from 'core/Page'
import { Space, theme, Spinner, Text } from 'ui'
import useStore from 'store'
import { useInput } from 'utils/hooks'
import { Path } from 'core/Routes'
import { useTranslation } from 'react-i18next'
import { Send } from '@material-ui/icons'
import FastInput from 'ui/FastInput'
interface ButtonProps {
  opacity?: number
}
const NO_USER = "Can't find user"
const INCORRECT_STRING = "The string supplied did not seem to be a phone number."

const StyledSend = styled(Send)`
  color:white;
  transform: rotate(180deg);
`
const Form = styled.form`
  width: 28vw;
  @media (max-width: 768px) {
    width: 80vw;
  }
  display: flex;
  flex-direction: column;
`

const StyledPage = styled(Page)`
  background-color:${theme.color.loginBackground};
  height:100%;
  width:100%;
  justify-content:center;
  align-items:center;
  `

const StyledTextField = styled(FastInput)`
  background: ${theme.color.mercury};
  border-radius: 5px;
  .MuiInputBase-input::placeholder {
    color: black;
    opacity: 0.5;
    font-size:16px;
  }
  font-size:16px;
  `
const StyledButton = styled(Button) <ButtonProps>`
  height:50px;
  background-color: ${theme.color.cerulean};
  border-radius: 5px;
  opacity:${p => p.opacity ? p.opacity : null};
  color:white;
  :hover {background-color: ${theme.color.cerulean};	}
`
const Login: FC = () => {
  const { user } = useStore()
  const [loginErrorMessage, setLoginErrorMessage] = useState('')
  const { t } = useTranslation('auth')
  const [isLoading, setIsLoading] = useState(false)
  const h = useHistory()
  const [text, setText] = useState('')
  const handleChange = React.useCallback((event: any) => {
    setLoginErrorMessage('')
    setText((event.target.value || '') as string)
  }, [])
  const phoneNumber = useInput({
    placeholder: t`phoneOrEmail`,
    onChange: handleChange,
    value: text
  })

  const InputContainer = styled.div`
      display: flex;
      flex-direction: column;
  `

  const handleOk = async (event: FormEvent) => {
    event.preventDefault()
    setIsLoading(true)
    if (phoneNumber.value) {
      let res = await user.sendPhoneOrEmail(phoneNumber.value)
      if (res === NO_USER) {
        res = t`noUser`
      } else if (res === INCORRECT_STRING) {
        res = t`phoneError`
      }
      if (res) {
        setLoginErrorMessage(res)
      } else {
        h.push(Path.CODE)
      }
    }
    setIsLoading(false)
  }
  return (
    <StyledPage flex={true} >
      <Form onSubmit={handleOk}>
        {phoneNumber.value && phoneNumber.value != '' &&
          <Text
            size={'sm'}
            textcolor={theme.color.mekomiBlack}
            weight='normal'>
            {t`phoneOrEmail`}
          </Text>

        }

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {isLoading ? <Spinner /> :
            <>
              <StyledTextField
                autoFocus
                placeholder={t`phoneOrEmail`}
                variant='outlined'
                // {...phoneNumber}
                onChange={handleChange}
              />
              {/* <StyledTextField onChange={handleChange} /> */}
            </>
          }
        </div>
        <Space height={1} />
        <Text
          textAlign={'center'}
          opacity={0.6}
          textcolor={loginErrorMessage ? 'red' : theme.color.mekomiBlack}
          weight='normal'>
          {loginErrorMessage ? loginErrorMessage : t`loginInfo`}
        </Text>
        <Space height={2} />
        <StyledButton disabled={isLoading} type="submit" variant='contained' opacity={phoneNumber.value && phoneNumber.value != '' ? 1 : 0.3}>
          <Text
            size={'md'}
            textAlign={'center'}
            textcolor={'white'}
            weight='normal'>
            {t`send`}
          </Text>
          <Space width={0.7} />
          <StyledSend />
        </StyledButton>
      </Form>
    </StyledPage>
  )
}

export default Login
