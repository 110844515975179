import React, { FC } from 'react'
import styled, { keyframes } from 'styled-components'

const appearUp = keyframes`
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0px);
  }
`

const appearDown = keyframes`
  0% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0px);
  }
`

interface Props {
  direction?: Direction
}

export enum Direction {
  down,
  up
}

const AppearWrap = styled.div<Props>`
  transition: all 300ms ease-in-out;
  animation: ${p => p.direction === Direction.up ? appearUp : appearDown} ease-in-out 300ms 1;
`

const Appear: FC<Props> = ({ direction = Direction.up, children }) => <AppearWrap>{children}</AppearWrap>

export default Appear
