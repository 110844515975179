import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { Dialog, Divider, Tooltip, IconButton } from '@material-ui/core'
import { Space, theme, Row, Text } from 'ui'
import useStore, { mapStore } from 'store'
import { useTranslation } from 'react-i18next'
import MessagesHeader from 'components/Messages/MessagesHeader'
import ExportCSV from 'assets/icons/export_csv.svg'
import MessageUserLocation from './MessageUserLocation'
import { Responses, saveMessageLocationDetailsCsv } from 'utils/csvUtils'
import * as moment from 'moment'

const ContainerInner = styled.div <{ color: string}>`
  padding: 10px;
  width:500px;
  background-color:${p => p.color};
`
const HeaderButtonsRow = styled(Row) <{ bgColor: string }>`
  justify-content: flex-end;
  padding-left: 30px;
  background-color: ${p => p.bgColor}};
`
const StyledIconButton = styled(IconButton)`
  background-color: ${theme.color.azureBlue};
  border-radius:8px;
  height: 30px;
  width: 30px;
  &:hover {
    background:${theme.color.red};
  } 
  &.MuiIconButton-root:hover {
    background-color: ${theme.color.azureBlue + 66}
  }
`

const RequestLocationModal: FC = () => {
  const { groups, mapStore } = useStore()
  const { t } = useTranslation('map')
  const { t: t2 } = useTranslation('csvHeaders')

  const groupColor = groups.currentGroup?.group.description?.color || groups.defaultGroupDescription.color

  return (
    <Dialog
      open={mapStore.showMessageLocationRequestDetailModal}
    >
      <MessagesHeader
        textVarient={'black'}
        backColor={groupColor + '1A'}
        mainText={groups.currentGroup?.group?.name || ''}
        secondText={t`locationRequest`}
        onClose={() => mapStore.closeMessageLocationRequestDetailModal()}
      />
      <HeaderButtonsRow bgColor={groupColor + '1A'}>
        <Tooltip title={'יצוא מיקומים'} arrow>
          <div>
            <StyledIconButton onClick={() => { 
              saveMessageLocationDetailsCsv(mapStore.locationMessageUsers, `message_location_${moment().format('YYYY_MM_DD_hh_mm')}`, t2) 
            }}>
              <img src={ExportCSV} />
            </StyledIconButton>
          </div>
        </Tooltip>
      </HeaderButtonsRow>
      <ContainerInner color={groupColor + '1A'}>
        <MessageUserLocation />
      </ContainerInner>
    </Dialog>
  )
}
export default observer(RequestLocationModal)
