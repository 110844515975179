import { action, computed, observable, ObservableMap, reaction } from 'mobx'
import { isBefore, parse, format, addHours } from 'date-fns'
// import mockdata from "fixtures/israelisraeli";

import camelcaseKeys from 'camelcase-keys'
import { messages, modulesStore, orgs, snackBar } from 'store'
import { ModuleEnum } from 'utils/types'
import api from 'service/api'
import user from './user.store'
import mock from 'pages/Home/Budget/mockData'
import getDemoBudgetResponse from 'pages/Home/Budget/budgetDemo'
import getDemoPOSResponse from 'pages/Home/Budget/posDemo'
import { dateFormat } from '../utils/date'
import { onlyUniqueArrays, sortPeriod } from '../utils/dataUtils'
// const useMock = false
const toCamelCase = camelcaseKeys
// const israelisraeli = toCamelCase(mockdata)
// console.log('israelisraeli', israelisraeli)

export const errorsType = {
  PHONE: 1,
  ID_NUMBER: 2,
  CODE: 3,
  OTHER: 4
}
export const steps = {
  LOGIN_PAGE: 1,
  CODE_PAGE: 2,
}

export interface Name {
  he: string;
  "enUs"?: string;
  en?: string;
}

export interface Chargee {
  id: string;
  name: Name;
  displayName: string;
}

export interface ExtraCharge {
  id: string;
  chargeeId: string;
  name: Name;
}

export interface MetaData {
  chargees: Chargee[];
  extraCharges: ExtraCharge[];
  financialBooksEntity: Chargee[];
}
export interface OuterItem {
  budgetNumber: string;
  accountNumber: string;
  accountName: string;
  accountGroup: string;
  reference: string;
  totalSum: string;
  items?: InnerItem[];
  dateOfCharge: string;
  quantity: string;
  extraChargeId?: any;
  chargee: string;
  details?: string;
  name?: string;
  description? : string
}
export interface BudgetTransaction {
  budgetNumber: string;
  docType?: string;
  accountNumber: string;
  accountName: string;
  accountGroup: string;
  reference: string;
  totalSum: string;
  items?: OuterItem[];
  dateOfCharge: string;
  sumPerProduct: string;
  quantity: string;
  details: string;
  balance: string;
  type: string;
  extraChargeId?: any;
  chargee: string;
  enUs?: string;
  netPrice?: string;
}

export interface Summary {
  chargeeId: string;
  chargingSum: string;
}

export interface ItemPosTransaction {
  accountNumber: string;
  date: string;
  description: string;
  quantity: number;
  totalSum: number;
  reference: string;
  sumPerProduct: string;
}

export interface PosTransaction {
  dateOfCharge: string;
  details: string;
  quantity: number;
  totalSum: number;
  items: ItemPosTransaction[];
  name?: string;
  description? : string
}

export interface PosData {
  total: string;
  name: Name;
  lastDate: string;
  transactions: PosTransaction[];
}

type Year = number
type Month = number
type Period = [Year, Month]
export interface Data {
  metaData: MetaData;
  period: Period
  transactions: BudgetTransaction[];
  summary: Summary[];
}
export interface InnerItem {
  details: string;
  quantity: string;
  price: string;
  totalSum: string;
  qrCode: string;
  discount: string;
  netPrice: string;
  unitType: string | null;
  vat?: string
}

export interface Item {
  accountNumber: string;
  accountName: string;
  accountGroup: string;
  reference: string;
  dateOfCharge: string;
  totalSum: string;
  quantity: string;
  budgetNumber: number;
  extraChargeId?: string;
  items: InnerItem[];
  details?: string;
  enUs?: string;
  sumPerProduct?: string;
}

export interface RootObject {
  success: boolean;
  data: Data[];
}

export interface AvailableMonth {
  budget: Period[]
  pos: Period[]
}

const mockData: Data = mock.data as any
//console.log('mockdata now is', mockData)
export enum SortTypes {
  SUM,
  DATE,
  STORE
}

export enum PaymentType {
  BUDGET,
  POS,
  FILES
}

export interface IFiles {
  id: string;
  name: string;
}

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
class BudgetStore {

  constructor() {
    reaction(() => orgs.currentOrgId, (orgId) => {
      modulesStore.initialized.promise.then(() => {
        if (orgId === orgs.currentOrgId) {
          if (this.isReadyToInit) {
            this.initAction()
          } else {
            this.setReadyToInit(true)
          }
        } else {
          this.setReadyToInit(false)
        }
      }).catch(e => {
        this.setIsLoading(false)
        console.log('exception:', e)
      })
    })
    reaction(() => user.isAuth, (isAuth) => {
      if (!isAuth) {
        this.clear()
      }
    })
    // this.init()
  }
  @observable useDemo = false;
  @action setUseDemo = (bool: boolean) => {
    this.useDemo = bool
  }

  @observable budgetLoginModalOpen: boolean = false
  @observable budgetIncompatibilitiesModalOpen: boolean = false
  @observable phoneNumber: string = ''
  @observable code: string = ''
  @observable idNumber: string = ''
  @observable rememberMe: boolean = true
  @observable errors: ObservableMap<number, string> = observable(new Map())
  @observable authStep: number = steps.LOGIN_PAGE

  /**auth is a map that knows in which communities the user is authorized to use budget */
  @observable auth = new ObservableMap<number, boolean>();
  /**
   * auth date is used to count time to know whether to reload budget data
   */
  @observable authDates = new ObservableMap<number, Date>();
  @observable _transactions: BudgetTransaction[] = []
  @observable isSelectedPeriodIndex: boolean = true;
  @observable showAgorot: boolean = false;
  @observable metaData: MetaData | null = null
  @observable files: IFiles[] | null = null;
  @observable period: Period | null = null
  @observable summary: Summary[] | null = null
  @observable currencyType: string = '₪';
  @observable sortType: SortTypes = SortTypes.STORE
  @observable availablePeriods: Period[] = []
  @observable selectedPeriodIndex: number = 0
  @observable posList: PosData[] = []
  @observable paymentType: PaymentType = PaymentType.BUDGET
  @observable budgetAvailablePeriods: Period[] = []
  @observable posAvailablePeriods: Period[] = []
  @action setSelectedPeriodIndex = (index: number) => {
    if (index < this.availablePeriods.length) {
      this.isSelectedPeriodIndex = true
      this.selectedPeriodIndex = index
    } else {
      this.isSelectedPeriodIndex = false
    }
      this.getPaymentsData()
  }

  initAction = () => {
    try {
      this.setIsLoading(true)
      this.clear()
      this.setAuth(false)
      this.init()
    } catch (e) {
      this.setIsLoading(false)
      console.log('exception:', e)
    }
  }

  getPaymentsData = async () => {
    try {
      this.setIsLoading(true)
      if (this.isSelectedPeriodIndex) {
        const selectedPeriod = this.availablePeriods[this.selectedPeriodIndex]

        if (selectedPeriod) {
          const year = selectedPeriod[0]
          const month = selectedPeriod[1]
          await Promise.all([
            this.budgetExist ? this.getBudget(year, month) : null,
            this.posExist ? this.getPos(year, month) : null
          ])
        } else if (this.availablePeriods[0]) {
          snackBar.showError("לצערנו אין נתונים לחודש זה, החודש הזמין הראשון מוצג אוטומטית.")
          this.setSelectedPeriodIndex(0)
        }

        if (!this.budgetExist) {
          this.metaData = { chargees: [], extraCharges: [], financialBooksEntity: [] }
          this._transactions = []
          this.summary = []
        }

        if (!this.posExist) {
          this.posList = []
        }
      } else {
        this.posList = []
      }

      this.setAuth(true)
      this.setIsLoading(false)
    } catch (err: any) {
      this.setIsLoading(false)
      this.setAuth(false)
      snackBar.showError(err?.errors?.[0]?.errorDescription || api.genericErrorText)
    }

  }

  @observable isInited: boolean = false
  @observable isReadyToInit: boolean = false
  @observable isLoading: boolean = true
  @action setIsLoading = (loading: boolean) => {
    this.isLoading = loading
  }
  @action setReadyToInit = (ready: boolean) => {
    this.isReadyToInit = ready
  }
  @action setInited = (inited: boolean) => {
    this.isInited = inited
  }
  setAuth = (isAuth: boolean) => {
    this.auth.set(orgs.currentOrgId, isAuth)
  }
  setAuthDate = (authDate: Date) => {
    this.authDates.set(orgs.currentOrgId, authDate)
  }
  get isAuth() {
    return this.auth.get(orgs.currentOrgId) || false
  }
  get isFilesTab() {
    return budgetStore.paymentType === PaymentType.FILES
  }
  get posTotal() {
    return this.posList.reduce((a, b) => a + Number(b.total), 0) || 0
  }
  get budgetTotal() {
    return this.summary?.reduce((a, b) => a + Number(b.chargingSum), 0) || 0
  }
  get year() {
    return this.availablePeriods[this.selectedPeriodIndex]?.[0] || new Date().getFullYear()
  }
  get month() {
    return (this.availablePeriods[this.selectedPeriodIndex]?.[1] || (new Date().getMonth() + 1)) - 1
  }
  get budgetAmount() {
    return this.agorotToShekel(this.budgetTotal * -1)
  }
  get posAmount() {
    return this.agorotToShekel(this.posTotal * -1)
  }
  get budgetExist() {
    if(!this.availablePeriods[this.selectedPeriodIndex]) {
      return false
    }
    const periodSelected = this.availablePeriods[this.selectedPeriodIndex]
    const year = periodSelected[0]
    const month = periodSelected[1]
    const isBudgetExist = this.budgetAvailablePeriods.find(budgetAvailablePeriod => {
      return budgetAvailablePeriod[0] === year &&
        budgetAvailablePeriod[1] === month
    })
    return !!isBudgetExist
  }
  get posExist() {
    if(!this.availablePeriods[this.selectedPeriodIndex]) {
      return false
    }
    const periodSelected = this.availablePeriods[this.selectedPeriodIndex]
    const year = periodSelected[0]
    const month = periodSelected[1]
    const isPosExist = this.posAvailablePeriods.find(posAvailablePeriod => {
      return posAvailablePeriod[0] === year &&
      posAvailablePeriod[1] === month
    })
    return !!isPosExist
  }
  dateTimeStr = (date: string) => {
    return `${dateFormat(date, 'DD/MM')} | ${dateFormat(date, 'HH:mm')}`
  }
  isCurrentPeriodSelected = () => {
    if (!this.availablePeriods[this.selectedPeriodIndex]) {
      return false
    }
    const periodSelected = this.availablePeriods[this.selectedPeriodIndex]
    return this.isCurrentPeriod(periodSelected)
  }
  isCurrentPeriod = (period: Period) => {
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1
    return period[0] === currentYear && period[1] === currentMonth
  }
  getBudgetTitle = (budget: BudgetTransaction) => {
    return budget.extraChargeId ? this.getExtraDataById(budget.extraChargeId)?.name.he : budget.details
  }
  getBudgetName = (budgetTransaction: BudgetTransaction, outerItem: OuterItem) => {
    if(!this.metaData?.extraCharges) {
      return outerItem.details || budgetTransaction.details
    }
    if (outerItem.extraChargeId) {
      return this.getExtraDataById(outerItem.extraChargeId)?.name.he
    }
    if (budgetTransaction.extraChargeId && this.metaData?.extraCharges) {
      return this.getExtraDataById(budgetTransaction.extraChargeId)?.name.he
    }
    return ''
  }

  getBudgetDescription = (budgetTransaction: BudgetTransaction, outerItem: OuterItem) => {
    if (outerItem.extraChargeId) {
      const chargeData = this.getExtraDataById(outerItem.extraChargeId)
      return this.getChargeeById(chargeData?.chargeeId)?.name.he
    }
    return this.getChargeeById(budgetTransaction.chargee)?.name.he
  }
  @action setSortType = (sortType: SortTypes = SortTypes.SUM) => {
    this.sortType = sortType;
  }
  @action setPaymentType = (paymentType: PaymentType = PaymentType.BUDGET) => {
    this.paymentType = paymentType
    if (this.sortType === SortTypes.STORE && paymentType === PaymentType.POS) {
      this.setSortType(SortTypes.SUM)
    }
  }
  @action setShowAgorot = (show: boolean = true) => {
    this.showAgorot = show;
  }
  @computed get debit() {
    return this._transactions.filter(
      transaction => Number(transaction.totalSum) > 0
    );
  }
  @computed get sum() {
    return this._transactions.reduce((a, b) => {
      return a + Number(b.totalSum)
    }, 0)
  }

  pos(sortType: SortTypes = this.sortType): PosData[] {
    switch (sortType) {
      case SortTypes.DATE:
        return this.posList.slice().sort((a, b) => {
          const A = parse(a.lastDate, "dd/MM/yyyy HH:mm", new Date());
          const B = parse(b.lastDate, "dd/MM/yyyy HH:mm", new Date());
          if (A.getDate() === B.getDate()) {
            const bTotal = Number(b.total)
            const aTotal = Number(a.total)
            if (aTotal > 0 && bTotal > 0) {
              return bTotal - aTotal
            }
            return aTotal - bTotal
          }
          return isBefore(A, B) ? 1 : -1;
        }) || []
      case SortTypes.SUM:
        return this.posList.slice().sort((a, b) => {
          const bTotal = Number(b.total)
          const aTotal = Number(a.total)
          if (aTotal > 0 && bTotal > 0) {
            return bTotal - aTotal
          }
          return aTotal - bTotal
        }) || []

      case SortTypes.STORE:
        return this.posList
      default:
        return this.posList
    }
  }

  transactions(sortType: SortTypes = this.sortType): BudgetTransaction[] {
    // console.log('groupbytransaction', GroupTransactionsBy('date_of_charge'))
    // return this._transactions
    switch (sortType) {
      case SortTypes.DATE:
        return this._transactions.slice().filter(el => Number(el.totalSum) != 0).sort((a, b) => {
          const A = parse(a.dateOfCharge, "dd/MM/yyyy", new Date());
          const B = parse(b.dateOfCharge, "dd/MM/yyyy", new Date());
          if (A.getDate() === B.getDate()) {
            const bTotal = Number(b.totalSum)
            const aTotal = Number(a.totalSum)
            if (aTotal > 0 && bTotal > 0) {
              return bTotal - aTotal
            }
            return aTotal - bTotal
          }
          return isBefore(A, B) ? 1 : -1;
        }) || []
      // return GroupTransactionsBy('date_of_charge')

      case SortTypes.SUM:
        return this._transactions.slice().filter(el => Number(el.totalSum) != 0).sort((a, b) => {
          const bTotal = Number(b.totalSum)
          const aTotal = Number(a.totalSum)
          if (aTotal > 0 && bTotal > 0) {
            return bTotal - aTotal
          }
          return aTotal - bTotal
        }) || []
      // return GroupTransactionsBy('date_of_charge')

      case SortTypes.STORE:
        return this._transactions || []
        // return GroupTransactionsBy('date_of_charge')

        break;

      default:
        return this._transactions || []
      // return GroupTransactionsBy('date_of_charge')
    }
  }

  @computed get transactionsSummary() {
    return this.summary
  }

  @computed get credit() {
    return this._transactions.filter(
      transaction => Number(transaction.totalSum) <= 0
    );
  }
  @observable isDisabled: boolean = false

  getExtraDataById(id: string) {
    return this.metaData?.extraCharges?.find(el => el.id === id)
  }

  getChargeeById(id: string) {
    return this.metaData?.chargees.find(el => el.id === id)
  }
  @computed get chargers() {
    return this.metaData?.chargees || []
  }

  @computed get entityChargers() {
    return this.metaData?.financialBooksEntity || []
  }

  getMoneyString(amount: number | string): string {
    if (this.showAgorot) {
      return Number(amount).toLocaleString('en-US')
    }
    return Number(Number(amount).toFixed()).toLocaleString('en-US')
  }

  @action setPeriod(year: Year, month: Month) {
    //TODO: figure out if month 0 is january or 1 is january.
    this.period = [year, month]
  }

  getExtraChargeString(extraChargeId: number) { // example: returns "[קיבוץ  |  מרכולית]"
    const chargeData = this.getExtraDataById(extraChargeId.toString());
    return [chargeData?.name['he'], this.getChargeeById(chargeData?.chargeeId)?.name.he]
  }


  // async login(id: number, phone: string, saveUser: boolean = false) {
  //   const res = await apiCalls.budgetOtp1(orgsStore.currentOrgId, phone, id, saveUser)
  //   return res
  //   // return await apiCalls.budgetOtp1(orgsStore.currentOrgId, phone, id, saveUser)
  // }
  // async verify(pin: number) {
  //   const res = await apiCalls.budgetVerify(orgsStore.currentOrgId, pin)
  //   return res
  // }
  // async sendAgain(id: number, phone: string, saveUser: boolean = false) {
  //   const res = await apiCalls.budgetResend(orgsStore.currentOrgId)
  //   return res
  // }
  async clear() {
    this.setAuth(false)
    this._transactions = []
    this.showAgorot = false;
    this.authDates.clear()
    this.metaData = null
    this.summary = null
    this.clearAll()
  }
  async forget() {
    try {
      this.clear()
      // const user = userStore.getUser;
      // if (user) {
      //   const budgetAuth = user.appSettings.budgetAuth || {}
      //   budgetAuth[orgsStore.currentOrgId] = false
      //   user.appSettings.budgetAuth = budgetAuth
      //   user.appSettings.budgetAuth.[orgsStore.currentOrgId] = false
      //   userStore.setUser(user)
      // }
      const res = await api.budgetForget(orgs.currentOrgId!)
      return res
    } catch (e) {
      console.log('error:', e)
    }
  }
  async getBudget(year?: number, month?: number) {
    if (orgs.currentOrgId) {
      if (this.useDemo) {
        const newDate = new Date()
        newDate.setMonth(newDate.getMonth() - 1)
        await sleep(1000)
        const demoBudgetResponse = getDemoBudgetResponse(year || newDate.getFullYear(), month || (newDate.getMonth() + 1)).data
        this.handleBudgetRes(demoBudgetResponse)
        const isInit = !year && !month
        if (isInit) {
          const demoPOSResponse = getDemoPOSResponse(new Date().getFullYear(), new Date().getMonth() + 1)
          this.handlePosRes(demoPOSResponse?.data as PosData[])
        }
        return
      }
      const info = modulesStore.budgets.find(el => el.community_ref === orgs.currentOrgId)
      if (info) {
        const res = await api.getBudget(info.module_id!, orgs.currentOrgId, ModuleEnum.SLIKA, year, month)
        if (res && res.errors?.length) {
          throw res;
        }
        this.handleBudgetRes(res)
        if (res?.pos_data) {
          this.handlePosRes(res.pos_data)
        }
      }
    }
  }

  removeFileDates() {
    if(!this.isFilesTab && this.availablePeriods && this.availablePeriods[this.selectedPeriodIndex]) {
      const filesSelectedPeriod = this.availablePeriods[this.selectedPeriodIndex]
      const filesYear = filesSelectedPeriod[0]
      const filesMonth = filesSelectedPeriod[1]
      const periods: Period[] = [...this.budgetAvailablePeriods, ...this.posAvailablePeriods]
      this.availablePeriods = sortPeriod(onlyUniqueArrays(periods) as [number, number][])
      const indexOfPeriod = this.availablePeriods.findIndex(e => filesYear === e[0] && filesMonth === e[1])
      if(indexOfPeriod === -1) {
        this.getPaymentsData();
        this.selectedPeriodIndex = 0;
      } else {
        this.selectedPeriodIndex = indexOfPeriod;
      }
    }
  }

  async loadFiles(year: number, month: number) {

    try {
      this.setIsLoading(true)
      const res = await api.getFiles(year, month)
      if(res !== undefined && 'files' in res) {
        if(!!res?.fileDates && res.fileDates.length > 0) {
          const periods = this.availablePeriods;
          res.fileDates.forEach(period => {
            const found = periods.find(a => a[0] === period[0] && a[1] === period[1])
            if (!found) {
              periods.push(period)
            }
          })
          this.availablePeriods = sortPeriod(periods)
          this.selectedPeriodIndex = this.availablePeriods.findIndex(e => year === e[0] && month === e[1])
        }
        this.files = res.files
      } else {
        snackBar.showError("הורדת הקובץ נכשלה, יש לנסות שוב מאוחר יותר")
      }
    } catch (error) {
      snackBar.showError("הורדת הקובץ נכשלה, יש לנסות שוב מאוחר יותר")
    } finally {
      this.setIsLoading(false)
    }
  }

  async loadFile(id: string) {
    const file = await api.getFile(id)
    if (file.moduleFile.url) {
      messages.openViewerModal('pdf', file.moduleFile.url)
    } else {
      snackBar.showError("הורדת הקובץ נכשלה, יש לנסות שוב מאוחר יותר")
    }
  }

  async getPos(year: number, month: number) {
    if (!orgs.currentOrgId) {
      return
    }
    if (this.useDemo) {
      const newDate = new Date()
      newDate.setMonth(newDate.getMonth() - 1)
      await sleep(1000)
      const res = getDemoPOSResponse(year || newDate.getFullYear(), month || newDate.getMonth() + 1)
      this.handlePosRes(res?.data as PosData[])
      return
    }
    const res = await api.getPos(year, month)
    this.handlePosRes(res.data)
  }

  initAvailablePeriods(availableMonth: AvailableMonth, budgetPeriod: Period) {
    this.budgetAvailablePeriods = availableMonth.budget || (budgetPeriod ? [budgetPeriod] : [])
    this.posAvailablePeriods = availableMonth.pos || []
    this.availablePeriods = this.posAvailablePeriods.slice()
    this.budgetAvailablePeriods.forEach(budgetPeriod => {
      const found = this.availablePeriods.find(a => a[0] === budgetPeriod[0] && a[1] === budgetPeriod[1])
      if (!found) {
        this.availablePeriods.push(budgetPeriod)
      }
    })
    this.availablePeriods = sortPeriod(this.availablePeriods)
  }

  handleBudgetRes(res: any) {
    if (!res) {
      //empty budget
      this.metaData = { chargees: [], extraCharges: [], financialBooksEntity: [] };
      this.period = null
      this.summary = []
      this._transactions = []
      // this.setAuth(false)
      //this.isDisabled = true
      this.setAuth(true)
    }
    else {
      this.isDisabled = false
      // add 1 hour, next budget entrance in 1 hr will re-trigger init.
      this.setAuthDate(addHours(new Date(), 1))
      // this.setAuthDate(new Date(new Date().getTime() + 1000 * 15))
      const camelData = toCamelCase(res, { deep: true })
      this.metaData = camelData?.metaData || { chargees: [], extraCharges: [], financialBooksEntity: [] };
      const period = camelData?.period || null
      this.period = period
      if (camelData.extraData?.budget && !this.isFilesTab) {
        this.initAvailablePeriods(camelData.extraData, period)
      }
      if (this.useDemo) {
        const demoPeriods: Period[] = []
        const date = new Date()
        const month = date.getMonth()
        for (let i = 0; i < 6; i++) {
          const newPeriod = new Date()
          newPeriod.setMonth(month - i)
          demoPeriods.push([newPeriod.getFullYear(), newPeriod.getMonth() + 1])
        }
        this.availablePeriods = demoPeriods
        this.budgetAvailablePeriods = demoPeriods.slice(1)//without current period
        this.posAvailablePeriods = demoPeriods
      }
      // this.availablePeriods = [camelData.extraData[0], [2021, 1]]//todo delete this line b4 pr
      // this.availablePeriods = [camelData.extraData[0], camelData.extraData[0]]//todo delete this line b4 pr
      this.summary = camelData?.summary || [];
      // this.availablePeriods = camelData.extraData
      if (camelData?.transactions) {
        (camelData.transactions as BudgetTransaction[]).forEach((el: BudgetTransaction) => {
          if (el.dateOfCharge.endsWith('Z')) {
            el.dateOfCharge = format(new Date(el.dateOfCharge), 'dd/MM/yyyy')
          }
          if (el.items?.length) {
            el.items.forEach(item => {
              if (item.dateOfCharge && item.dateOfCharge.endsWith('Z')) {
                item.dateOfCharge = format(new Date(item.dateOfCharge), 'dd/MM/yyyy hh:mm')
              }
            })
          }
        })
      }
      this._transactions = camelData?.transactions as BudgetTransaction[] || []
      this.setAuth(true)
    }
  }

  handlePosRes(posListRes: PosData[]) {
    if (!posListRes?.length) {
      this.posList = []
    }
    else {
      this.posList = toCamelCase(posListRes, { deep: true })
    }
  }
  // async init(rememberMeEnabled: boolean = false, isAfterLogin: boolean = false) {
  //   /* init placeholders */
  //   if (appInfoStore.budgets.length === 0 && !useMock) {
  //     this.setIsLoading(false)
  //     return;
  //   }
  //   try {
  //     const userJSON = await storage.get(USER_AUTH)
  //     if (userJSON || rememberMeEnabled || useMock) {
  //       const user: ExtendedUser = JSON.parse(userJSON)
  //       if (user.appSettings.budgetAuth?.[orgsStore.currentOrgId] || this.isAuth || isAfterLogin || useMock) {
  //         const res = useMock ? mockData.data[0] : await retrier(async () => await this.getBudget(), 3)
  //         if (!res) {
  //           //empty budget
  //           this.metaData = { chargees: [], extraCharges: [] };
  //           this.period = [new Date().getFullYear(), new Date().getMonth() + 1]
  //           this.summary = []
  //           this._transactions = []
  //           // this.setAuth(false)
  //           this.isDisabled = true
  //           this.setAuth(true)
  //         }
  //         else {
  //           // add 1 hour, next budget entrance in 1 hr will re-trigger init.
  //           this.setAuthDate(addHours(new Date(), 1))
  //           // this.setAuthDate(new Date(new Date().getTime() + 1000 * 15))
  //           const camelData = toCamelCase(res)
  //           this.metaData = camelData?.metaData || { chargees: [], extraCharges: [] };
  //           const period = camelData?.period || [new Date().getFullYear(), new Date().getMonth()]
  //           this.period = period
  //           this.summary = camelData?.summary || [];
  //           if (camelData?.transactions) {
  //             (camelData.transactions as Transaction[]).forEach((el: Transaction) => {
  //               if (el.dateOfCharge.endsWith('Z')) {
  //                 el.dateOfCharge = format(new Date(el.dateOfCharge), 'dd/MM/yyyy')
  //               }
  //               if (el.items?.length) {
  //                 el.items.forEach(item => {
  //                   if (item.dateOfCharge && item.dateOfCharge.endsWith('Z')) {
  //                     item.dateOfCharge = format(new Date(item.dateOfCharge), 'dd/MM/yyyy hh:mm')
  //                   }
  //                 })
  //               }
  //             })
  //           }
  //           this._transactions = camelData?.transactions as Transaction[] || []
  //           // this.metaData =  mockData.data[0].metaData
  //           // this.period =  mockData.data[0].period
  //           // this.summary =  mockData.data[0].summary
  //           // this._transactions = mockData.data[0].transactions

  //           // this.userData = {
  //           //   communication: [{ code: '1', value: '1' }],
  //           //   entity: 'sas',
  //           //   firstName: 'Roy',
  //           //   lastName: 'Sharon',
  //           //   memberId: '16845',
  //           //   org: '1',
  //           //   registrationStatus: 1,
  //           //   roles: [],
  //           //   teudatZehut: '123123123',
  //           //   type: 'habibi'
  //           // }
  //           if (rememberMeEnabled || user.appSettings.budgetAuth?.[orgsStore.currentOrgId]) {
  //             this.setAuth(true)
  //           }
  //         }
  //       }
  //     }

  //   } catch (e) {
  //     console.log('budgetStore init exception', e)
  //     this.setAuth(false)
  //     bugSnagErrorNotifier.notify(e)
  //   } finally {
  //     this.setIsLoading(false)
  //   }
  // }
  @action setAuthStep(step: number) {
    this.authStep = step
  }
  @action openBudgetLogin() {
    this.budgetLoginModalOpen = true
  }
  @action setPhoneNumber(value: string) {
    this.phoneNumber = value
  }
  @action setCode(value: string) {
    this.code = value
  }
  @action setIdNumber(value: string) {
    this.idNumber = value
  }
  @action toggleRememberMe() {
    this.rememberMe = !this.rememberMe
  }
  @action closeBudgetLogin() {
    this.budgetLoginModalOpen = false
  }

  // @action sendNewCode() {
  //   this.phoneNumber
  // }
  @action sendAnotherPhone() {
    this.clearAll()
  }
  @action clearAll() {
    this.authStep = steps.LOGIN_PAGE
    this.phoneNumber = ''
    this.code = ''
    this.idNumber = ''
    this.rememberMe = true

  }
  @action setErrors(errors: any) {
    this.errors = errors
  }
  @action setError(key: number, value: any) {
    this.errors.set(key, value)
  }

  // @action sendLoginReq() {
  //   console.log('send login req', this.phoneNumber, this.idNumber)
  //   const res = (this.phoneNumber === '1' && this.idNumber === '2')
  //   if (res) {
  //     this.authStep = steps.CODEPAGE

  //   } else {
  //     this.errors.set(errorsType.PHONE, 'טלפון שגוי')
  //     this.errors.set(errorsType.IDNUMBER, 'תעודת זהות לא במערכת')
  //   }
  // }

  // @action sendVerificationCode() {
  //   console.log('send VerificationCode', this.code)
  //   const res = (this.code === '3')
  //   if (res) {
  //     this.closeBudgetLogin()
  //     this.clearAll()
  //     this.setAuth(true)
  //   } else {
  //     this.errors.set(errorsType.CODE, 'קוד שגוי')
  //   }
  // }

  private authErrorHandler(error: any) {
    const err = error as { errorDescription: string, errorKey: string }[]
    console.log('budget auth errors:', err)
    if (err?.length) {
      err?.forEach(el => {
        if (el?.errorKey === 'InvalidID') {
          this.setError(errorsType.ID_NUMBER, el?.errorDescription)
        }
        else if (el?.errorKey === 'InvalidPhoneNumber') {
          this.setError(errorsType.PHONE, el?.errorDescription)
        }
        else if (el?.errorKey === "UnrelatedPhoneNumber") {
          this.setError(errorsType.PHONE, el?.errorDescription)
        }
        else snackBar.showError(el?.errorDescription || 'error')

      })
    }
  }
  async login() {
    // this.authStep = steps.CODE_PAGE
    const id = this.idNumber
    const phone = this.phoneNumber
    const saveUser = this.rememberMe
    if (orgs.currentOrgId) {
      try {
        const res = await api.budgetOtp1(orgs.currentOrgId, phone, id, saveUser)
        this.authStep = steps.CODE_PAGE
        return res
      } catch (error) {
        this.authErrorHandler(error);
      }
    }
    // return await apiCalls.budgetOtp1(orgsStore.currentOrgId, phone, id, saveUser)
  }
  async verify() {
    try {
      const pin = this.code;
      if (orgs.currentOrgId) {
        const res = await api.budgetVerify(orgs.currentOrgId, Number(pin))
        return res
      }
    } catch (e) {
      this.authErrorHandler(e);
    }
  }
  async sendAgain() {
    if (orgs.currentOrgId) {
      try {
        const res = await api.budgetResend(orgs.currentOrgId)
        return res
      } catch (e) {
        this.authErrorHandler(e)
      }
    }
  }
  async init() {
    /* init placeholders */
    if (modulesStore.budgets.length === 0 && !this.useDemo) {
      this.setIsLoading(false)
      this.setInited(true)
      return;
    }
    try {
      await this.getBudget()
      if (!this.budgetExist) {
        this.setPaymentType(PaymentType.POS)
        this.metaData = { chargees: [], extraCharges: [], financialBooksEntity: [] }
        this._transactions = []
        this.summary = []
      }
      this.setAuth(true)
    } catch (e) {
      // console.log('budgetStore init exception', e)
      this.setAuth(false)
      // bugSnagErrorNotifier.notify(e)
    } finally {
      this.setIsLoading(false)
      this.setInited(true)
    }
  }
  agorotToShekel(agorot: number) {
    return agorot
    // if (location.host !== 'web.mekome.net') {
    //   return agorot / 100
    // }
    // return agorot
  }
}

const budgetStore = new BudgetStore()
export default budgetStore
