import React, { FC, useState, useEffect } from 'react'
import { toJS } from 'mobx'
import styled from 'styled-components'
import { Tooltip, TablePagination } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { DeleteOutline } from '@material-ui/icons'
import { theme, Text, IconButton as UiIconButton, Row, Space } from 'ui'
import { observer } from 'mobx-react'
import useStore from 'store'
import type { CsvUser } from 'utils/models'
import Checkbox from '@material-ui/core/Checkbox';
import { CsvUserStatus, uiErrorType } from 'utils/models'

const Container = styled.div`
  display:flex;
  flex-direction:column;
  padding:5px;
  padding-bottom:0;
`
const OverflowContainer = styled.div`
  overflow:auto;
  flex:1;
  padding:0 5px;
  /* display:flex;
  justify-content:flex-start;
  align-items:flex-start; */
`
const UserContainer = styled.div`
  display:flex;
  flex:1;
  flex-direction:row;
  align-items:center;
  border-top:1px solid ${theme.color.black + 33};
  /* margin:10px 5px; */
`
const ItemContainer = styled.div<{ flex: number }>`
  display:flex;
  flex:${p => p.flex ? p.flex : 1};
  border:1px solid ${theme.color.black + 33};
  align-items:center;
  justify-content:center;
`
const UserItemContainer = styled.div<{ flex: number, width: string }>`
  display:flex;
  ${p => `flex: ${p.flex ? p.flex : 1};`}
  ${p => `width: ${p.width ? p.width + ';' : ''}`}
  flex-direction:column;
  padding:10px 0;
`
const HeaderContainer = styled.div`
  display:flex;
  flex-direction:row;

`
const BlueCheckbox = styled(Checkbox) <{ checked: boolean }>`
  *{
    color: ${p => !p.checked ? theme.color.blue + 80 : theme.color.blue};
  }
`

const ListContainer = styled.div`
  display:flex;
  flex:1;
  flex-direction:column-reverse;
`
const UserItems = ({ children, flex, textcolor, onChange, error = null, width = null }: { onChange: (v: string) => void, children: string, flex?: number | undefined, width?: string | undefined, textcolor?: string, error: string | null }) => {
    return (
        <UserItemContainer key={children} flex={flex} width={width}>
            <input
                autoFocus
                style={{ borderWidth: 0, backgroundColor: 'transparent', }}
                value={children}
                onChange={(event) => { onChange(event.target.value) }}
            />
            {error && error != '' && <Text
                align={'center'}
                textcolor={theme.color.dangerRed}
                weight={'normal'}
                size={'sm'}
            >{error}</Text>}
        </UserItemContainer>
    )
}

const HeaderItems = ({ children, flex }: { children: string, flex?: number | undefined }) => {
    return (
        <ItemContainer flex={flex}>
            <Text
                align={'center'}
                textcolor={theme.color.black}
                weight={'medium'}
                size={'md'}
            >{children}</Text>
        </ItemContainer>
    )
}


const UserView = observer(({ csvUser }: { csvUser: CsvUser }) => {
    const { csvStore } = useStore()
    const { t } = useTranslation('csvHeaders')

    const toggleUserState = () => {
        csvStore.changeUserStatus(csvUser.id)
    }

    const canAdd = () => {
        if (csvStore?.csvFileUsersErrors[csvUser.id]?.size > 0) {
            return false
        }
        return true
    }

    let status = '';
    if (csvUser.status === CsvUserStatus.Created) {
        status = t`createdNewUser`;
    }
    if (csvUser.status === CsvUserStatus.Duplicated) {
        status = t`addUserToCommunity`;
    }
    if (csvUser.status === CsvUserStatus.Existing) {
        status = t`userExistsInCommunity`;
    }

    return (

        <UserContainer style={{ backgroundColor: csvUser.status === CsvUserStatus.New ? theme.color.white : theme.color.mekomiBlack + '33', }}>
            <UserItemContainer flex={0.4} style={{ alignItems: 'center', justifyContent: 'center', }}>
                {csvUser.status === CsvUserStatus.New && <BlueCheckbox
                    disabled={!canAdd()}
                    style={{ zIndex: 10 }}
                    checked={csvUser.selected}
                    onChange={toggleUserState}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />}
            </UserItemContainer>
            <UserItems
                error={csvStore?.csvFileUsersErrors[csvUser.id]?.get(uiErrorType.giveanNameError) || null}
                onChange={(v) => {
                    csvUser.user.given_name = v
                    csvStore.updateUser(csvUser)
                    csvStore.removeError(csvUser.id, uiErrorType.giveanNameError)
                }}>{csvUser.user.given_name}</UserItems>
            <UserItems
                error={csvStore?.csvFileUsersErrors[csvUser.id]?.get(uiErrorType.familyNameError) || null}
                onChange={(v) => {
                    csvUser.user.family_name = v
                    csvStore.updateUser(csvUser)
                    csvStore.removeError(csvUser.id, uiErrorType.familyNameError)
                }}>{csvUser.user.family_name}</UserItems>
            <UserItems
                error={csvStore?.csvFileUsersErrors[csvUser.id]?.get(uiErrorType.nickNameError) || null}
                onChange={(v) => {
                    csvUser.user.nick_name = v
                    csvStore.updateUser(csvUser)
                    csvStore.removeError(csvUser.id, uiErrorType.nickNameError)
                }}>{csvUser.user.nick_name}</UserItems>
            <UserItems
                error={csvStore?.csvFileUsersErrors[csvUser.id]?.get(uiErrorType.israeliIDError) || null}
                onChange={(v) => {
                    csvUser.user.israeliID = v
                    csvStore.updateUser(csvUser)
                    csvStore.removeError(csvUser.id, uiErrorType.israeliIDError)
                }}>{csvUser.user.israeliID}
            </UserItems>
            <UserItems
                error={csvStore?.csvFileUsersErrors[csvUser.id]?.get(uiErrorType.phone1Error) || null}
                onChange={(v) => {
                    csvUser.user.phones[0] = v
                    csvStore.updateUser(csvUser)
                    csvStore.removeError(csvUser.id, uiErrorType.phone1Error)
                }} textcolor={theme.color.blueText} flex={1.3}>{csvUser.user.phones[0]}</UserItems>
            <UserItems
                error={csvStore?.csvFileUsersErrors[csvUser.id]?.get(uiErrorType.phone2Error) || null}
                onChange={(v) => {
                    csvUser.user.phones[1] = v
                    csvStore.updateUser(csvUser)
                    csvStore.removeError(csvUser.id, uiErrorType.phone2Error)
                }} textcolor={theme.color.blueText} flex={1.3}>{csvUser.user.phones[1]}</UserItems>
            <UserItems
                error={csvStore?.csvFileUsersErrors[csvUser.id]?.get(uiErrorType.emailError) || null}
                onChange={(v) => {
                    csvUser.user.emails[0] = v
                    csvStore.updateUser(csvUser)
                    csvStore.removeError(csvUser.id, uiErrorType.emailError)
                }} textcolor={theme.color.blueText} flex={1.3}>{csvUser.user.emails[0]}</UserItems>
            <div style={{ flex: 1, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                {csvUser.status != CsvUserStatus.New && <>
                    <Text
                        style={{ cursor: 'pointer' }}
                        weight={'normal'}
                        size={'md'}
                        textcolor={theme.color.green}
                    >{status}</Text>
                    {csvUser.addedToGroup.description != '' && <Text
                        style={{ cursor: 'pointer' }}
                        weight={'normal'}
                        size={'md'}
                        textcolor={theme.color.green}
                    >{csvUser.addedToGroup.description}</Text>}
                </>
                }
            </div>
        </UserContainer>

    )
})

const UsersList = observer(() => {
    const { csvStore } = useStore()
    return (
        <ListContainer>
            {csvStore.getCsvUsers.reverse().map((csvUser, index) => <UserView key={csvUser.id} csvUser={csvUser} />)}
        </ListContainer>
    )
})

const CsvListScreen = () => {
    const { t } = useTranslation('members')
    const { csvStore } = useStore()
    const [selectAll, setSelectAll] = useState(false)

    const changSelectAll = () => {
        csvStore.changeStatusAllUsers((!selectAll))
        setSelectAll(s => !s)
    }

    const clearAll = () => {
        csvStore.cleanCsvUsers()
        csvStore.clearRelateToGroup()
    }

    useEffect(() => {
        if (csvStore.uploaded) {
            const countOfAll = csvStore.getCsvUsers.length
            const countOfSelected = csvStore.getCsvUsers.filter((csvUser: CsvUser) => {
                return csvUser.selected
            }).length

            setSelectAll(countOfAll == countOfSelected)
        }
    }, [csvStore.uploaded])

    useEffect(() => {
        const countOfAll = csvStore.getCsvUsers.length
        setSelectAll(countOfAll == csvStore.selectedCount)
        
    }, [csvStore.selectedCount])

    const noOfSelectedUsers = () => csvStore.getCsvUsers.filter((csvUser: CsvUser) => {
        return csvUser.selected
    }).length

    return (
        <>
            <Container>
                <Row style={{ justifyContent: 'space-between', }}>
                    <Row>
                        <Text
                            align={'center'}
                            textcolor={theme.color.black + 99}
                            weight={'medium'}
                            size={'lg'}
                        >{`${noOfSelectedUsers()}/${csvStore.getCsvUsers.filter(u => u.status === CsvUserStatus.New).length} ${t`members:newMembers`} `}</Text>
                        <Space width={0.5} />
                        <Text
                            align={'center'}
                            textcolor={theme.color.black + 99}
                            weight={'medium'}
                            size={'lg'}
                        >{` - ${csvStore.getCsvUsers.filter(u => u.status != CsvUserStatus.New).length} ${t`members:newMembersAdded`} `}</Text>
                    </Row>
                    <Row>
                        <Tooltip title={t`change_form`} arrow>
                            <div>
                                <UiIconButton style={{ backgroundColor: theme.color.azureBlue }} onClick={clearAll}>
                                    <DeleteOutline style={{ color: 'white' }} />
                                </UiIconButton>
                            </div>
                        </Tooltip>
                    </Row>
                </Row>
                <Space height={0.5} />
                <HeaderContainer>
                    <ItemContainer flex={0.4}>
                        <BlueCheckbox
                            checked={selectAll}
                            onChange={() => changSelectAll()}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </ItemContainer>
                    <HeaderItems>{t`firstName`}</HeaderItems>
                    <HeaderItems>{t`lastName`}</HeaderItems>
                    <HeaderItems>{t`nickName`}</HeaderItems>
                    <HeaderItems>{t`israeliID`}</HeaderItems>
                    <HeaderItems flex={1.3}>{t`csvHeaders:phone1`}</HeaderItems>
                    <HeaderItems flex={1.3}>{t`csvHeaders:phone2`}</HeaderItems>
                    <HeaderItems flex={1.3}>{t`email`}</HeaderItems>
                    <HeaderItems flex={1.3}>{t`csvHeaders:status`}</HeaderItems>
                </HeaderContainer>
            </Container>
            <OverflowContainer>
                <UsersList />
            </OverflowContainer>
        </>
    )
}

export default observer(CsvListScreen)





