import React, { } from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { Space, Text, theme } from 'ui'
import Account from 'assets/account.svg'

const StyledContainer = styled.div`
  display:flex;
  flex:1;
  justify-content:center;
  align-items:center;
  background-color:${theme.color.back};
  padding: 0 50px;
`
const StyledInnerContainer = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
`

const EmptyList: React.FC<{ title: string, content: string, moreContent?: string, icon?: JSX.Element }> = ({ title, content, moreContent, icon }) => {
  return (
    <StyledContainer>
      <StyledInnerContainer>
        <Space height={8} />
        {icon ? icon: <img src={Account} />}
        <Space height={2} />
        <Text weight='bold' size='xl'>{title}</Text>
        <Space />
        <Text textAlign="center" size='xl'>{content}</Text>
        {moreContent && <Text size='xl'>{moreContent}</Text>}
        <Space height={4} />
      </StyledInnerContainer>
    </StyledContainer>
  )
}

export default observer(EmptyList)