import React, { FC } from 'react'
import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'
import type { CircularProgressProps } from '@material-ui/core'

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`
const Spinner: FC<CircularProgressProps> = ({ ...props }) => <SpinnerContainer><CircularProgress {...props} /></SpinnerContainer>

export default Spinner
