import React, { FC } from 'react'
import styled from 'styled-components'
import { theme, Text, Space } from 'ui'
import useStore from 'store'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Close, ChevronRight } from '@material-ui/icons'
import ProfileIcon from 'assets/icons/profileIcon.svg'
import Switch from '@material-ui/core/Switch'
import { formatIfPhoneNumber } from 'utils/converts'
import { ExitToApp } from '@material-ui/icons'
import type { UserCommunity } from 'utils/types'
import { toJS } from 'mobx'


const Slide = styled.div`
  display:flex;
  flex:1;
  flex-direction:column;
  background: ${theme.color.white};
  border:1px solid ${theme.color.black + 33};
`
const HeaderContainer = styled.div`
  display:flex;
  height:150px;
  width:100%;
  background: ${theme.color.grayBackground};
  flex-direction:column;
  justify-content:center;
  align-items:center;

`
const DetailsContainer = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  margin:10px;
  padding:10px;

  border:0 solid ${theme.color.black + '1A'};
  border-bottom-width:1px;
  /* background: ${theme.color.red}; */

`
const MediaContainer = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
  margin:10px;
  padding:10px;

  border:0 solid ${theme.color.black + '1A'};
  border-bottom-width:1px;
  /* background: ${theme.color.red}; */

`
const LogoutContainer = styled.div`
  display:flex;
  flex:1;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  color:${theme.color.red};
  cursor:pointer;
  margin-bottom:30px;
`
const Chevron = styled(ChevronRight)`
  color:${theme.color.black};
  position:absolute;
  top:10px;
  right:10px;
  font-size:30px;
  cursor: pointer;
  /* padding:15px; */
`
const SlideWrapper = styled.div<{ right: boolean }>`
  display:flex;
  position: relative;
  /* right:${p => p.right ? 0 : -420}px; */
 /* min-width:330px; */
   width:${p => p.right ? 350 : 0}px;
   opacity:${p => p.right ? 1 : 0};
  height: 100%;
  background: ${theme.color.whiteBackground};
  transition: 0.5s;
  /* z-index:10; */
  overflow:auto;
`

const StyledHiddenSectionSubTitle = styled(Text)`
  margin-top:8px;
  margin-bottom:8px;
  font-size:16px;
  font-weight:400;
  opacity:0.6;
`

const StyledHiddenSectionTitle = styled(Text)`
  margin-top:8px;
  font-size:16px;
  font-weight:900;
`
const StyledHiddenLineText = styled(Text)`
  font-size:18px;
  font-weight:500;
`

const StyledHiddenLineContiner = styled.div`
    display:flex;
    flex:1;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
`
const DetailsSection = ({ text, secondText }: { text: string, secondText: string }) => {
  return (
    <DetailsContainer>
      <Text
        textcolor={theme.color.black}
        weight={'medium'}
        size={'lg'}
      >{text + ':'}</Text>
      <Space />
      <Text
        textcolor={theme.color.black + '99'}
        weight={'normal'}
        size={'md'}
      >{secondText}</Text>
    </DetailsContainer>
  )
}

const MediaSection = ({ text, list }: { text: string, list: Array<string> }) => {
  return (
    <MediaContainer>
      <Text
        textcolor={theme.color.black}
        weight={'medium'}
        size={'lg'}
      >{text + ':'}</Text>

      {list.map(text => <Text
        // style={{paddingRight:50}}
        key={text}
        textcolor={theme.color.black + '99'}
        weight={'normal'}
        size={'md'}
      >
        {formatIfPhoneNumber(text)}
      </Text>)}

    </MediaContainer>
  )
}

interface ISectionProps {
  orgs: { [key: string]: UserCommunity },
  show_in_alphones_title: string,
  show_in_alphones_sub_title: string,
  show_in_alphone_title: string,
  show_in_alphone_sub_title: string,
  updateUserShowInAlphon: (orgId: number, showInAlphone: boolean) => void
}

const HideCommuntiySection: React.FC<ISectionProps> = ({ orgs, show_in_alphones_title, show_in_alphones_sub_title, show_in_alphone_title, show_in_alphone_sub_title, updateUserShowInAlphon }) => {

  const currentOrgs = orgs ? Object.values(orgs) : []
  return (
    <MediaContainer>
      {currentOrgs.length > 1 &&
        <>
          <StyledHiddenSectionTitle>{show_in_alphones_title}</StyledHiddenSectionTitle>
          <StyledHiddenSectionSubTitle>{show_in_alphones_sub_title}</StyledHiddenSectionSubTitle>
          {currentOrgs.map((org: UserCommunity) => (
            <HideCommuntiyLine
              title={org.name}
              checked={org.is_showInAlphon}
              key={org.id}
              onChange={() => { updateUserShowInAlphon(parseInt(org.id), !org.is_showInAlphon) }}
            />)
          )}
        </>
      }
      {currentOrgs.length == 1 &&
        <>
          <HideCommuntiyLine
            title={show_in_alphone_title}
            checked={currentOrgs[0].is_showInAlphon}
            onChange={() => { updateUserShowInAlphon(parseInt(currentOrgs[0].id), !currentOrgs[0].is_showInAlphon) }}
          />
          <StyledHiddenSectionSubTitle>{show_in_alphone_sub_title}</StyledHiddenSectionSubTitle>
        </>
      }
    </MediaContainer>
  )
}
const HideCommuntiyLine = ({ checked, onChange, title }: { checked: boolean, onChange: any, title: string }) => {
  return (
    <StyledHiddenLineContiner>
      <Switch
        checked={checked}
        onChange={onChange}
        color='primary'
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
      <StyledHiddenLineText>{title}</StyledHiddenLineText>
    </StyledHiddenLineContiner>
  )
}
const Profile: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { user, orgs } = useStore()
  const { t } = useTranslation('members')
  const onClick = () => {
    onClose && onClose()
    user.closeUserProfile()
  }
  const { nick_name, given_name, family_name, emails, phones } = user.user
  const fullName = `${given_name} ${nick_name ? '(' + nick_name + ')' : ''} ${family_name}`
  const currentOrgs: { [key: string]: UserCommunity } = toJS(orgs.orgs)

  const updateUserShowInAlphon = async (orgId: number, showInAlphone: boolean) => {
    await orgs.updateUserShowInAlphon(orgId, showInAlphone)
  }
  return (
    <>
      <Slide>
        <HeaderContainer>
          <Chevron onClick={onClick} />
          <img src={ProfileIcon} />
          <Space />
          <Text
            textcolor={theme.color.black}
            weight={'medium'}
            size={'xl'}
          >{fullName}</Text>
        </HeaderContainer>
        <DetailsSection
          text={t`firstName`}
          secondText={given_name}
        />
        <DetailsSection
          text={t`lastName`}
          secondText={family_name}
        />
        {nick_name && <DetailsSection
          text={t`nickName`}
          secondText={nick_name}
        />}
        {emails && emails.length > 0 && <MediaSection
          text={t`email`}
          list={emails}
        />}
        {phones && phones.length > 0 && <MediaSection
          text={t`phoneNumber`}
          list={phones}
        />}
        <div style={{ backgroundColor: theme.color.white }}>
          <HideCommuntiySection
            orgs={currentOrgs}
            updateUserShowInAlphon={(orgId: number, showInAlphone: boolean) => { updateUserShowInAlphon(orgId, showInAlphone) }}
            show_in_alphones_title={t`show_in_alphones_title`}
            show_in_alphones_sub_title={t`show_in_alphones_sub_title`}
            show_in_alphone_title={t`show_in_alphone_title`}
            show_in_alphone_sub_title={t`show_in_alphone_sub_title`}
          />
        </div>
        <LogoutContainer onClick={user.logout}>
          <ExitToApp />
          <Space />
          ֿ<Text textcolor={theme.color.red} weight={'medium'} size={'xl'}>{t`app:logout`}</Text>
        </LogoutContainer>
      </Slide>
    </>)
}


export default observer(Profile)
