import React from 'react'
import styled from 'styled-components'
import { theme, Text, Space } from 'ui'
import { useTranslation } from 'react-i18next'
import ServiceCallGroup from './ServiceCallGroup'
import { GroupType, UserGroup, ProviderEnum, ModuleEnum } from 'utils/types'
import { userIsDirector } from 'utils/roles'
import GroupComponent from 'components/Groups/Group'
import { observer } from 'mobx-react'
import useStore from 'store'
import { useEffect, useState } from 'react'
import KehilaNetGroup from './KehilaNetGroup'

const Container = styled.div`

`
const Header = styled.div`
    /* border:1px solid ${theme.color.black + 66}; */
    padding:5px;
    border-radius:8px;
    /* box-shadow:0px 1px 1px 1px ${theme.color.shadow}; */
    margin:3px;
`
export default observer(function ServiceCallListSidebar({ list, serviceCallList }: { list: UserGroup[], serviceCallList: UserGroup[] }) {
  const { t } = useTranslation('serviceCall')
  const { groups, orgs, modulesStore } = useStore()
  const [module106, setModule106] = useState(modulesStore.communityModules.find(el => el.type === ModuleEnum.EXPRESS_106 && el.enable))

  const myCallsUnread = list.reduce((sum: { unread: number, groupCount: number }, g: UserGroup) => {
    const unread = (groups.currentOpenSupportGroups.filter(el => el.group.parent_id === g.group.parent_id).reduce((a: number, b: UserGroup) => {
      return a + (groups.lastMessages.get(b.group.id)?.unread || 0)
    }, 0) || 0)
    if (unread > 0) {
      sum.groupCount++
      sum.unread += unread
    }
    return sum
  }, { unread: 0, groupCount: 0 })

  const ServiceUnreadSum = serviceCallList.reduce((sum: { unread: number, groupCount: number }, g) => {
    const unread = (groups.lastMessages.get(g.group.id)?.unread || 0)
    if (unread > 0) {
      sum.groupCount++
      sum.unread += unread
    }
    return sum
  }, { unread: 0, groupCount: 0 })

  useEffect(() => {
    setModule106(modulesStore.communityModules.find(el => el.type === ModuleEnum.EXPRESS_106 && el.enable))
  }, [modulesStore.currentModule])

  return (
    <Container>
      {modulesStore.communityModules
          .filter((module) => module.provider === ProviderEnum.KEHILANET && module.enable).length > 0 && (
        <>
          <Header>
              <Text
                textcolor={theme.color.black}
                weight={'medium'}
                size={'xl'}
              >{t`kehilnet_list`}
            </Text>
          </Header>
            
          {modulesStore.communityModules
            .filter((module) => module.provider === ProviderEnum.KEHILANET && module.enable)
            .map((module) => <KehilaNetGroup key={module.module_id} module={module}/>)
          }
          <Space />
        </>
      )}

      {!!list.length && (
        <>
          <Header>
            <Text
              textcolor={theme.color.black}
              weight={'medium'}
              size={'xl'}
            >{t`my_calls`}
            </Text>
            {!!myCallsUnread && <Text textcolor={theme.color.darkGray}>{myCallsUnread.unread === 0 ? t('no_calls_for_you') : t('waiting_calls', { groups: myCallsUnread.groupCount, number: myCallsUnread.unread })}</Text>}
          </Header>
        </>
      )}
      {list.map((g: UserGroup) => <ServiceCallGroup
        description={g.group.description}
        name={g.group.name}
        id={g.group.id}
        parent_id={g.group.parent_id}
        key={g.group.id}
      />)}

      <Space />
      <Header>
        <Text textcolor={theme.color.black} weight={'medium'} size={'xl'}>{t`open_community_calls`}</Text>
        {!!ServiceUnreadSum?.unread && <Text textcolor={theme.color.darkGray}>{t('waiting_calls', { groups: ServiceUnreadSum.groupCount, number: ServiceUnreadSum.unread })}</Text>}
      </Header>
      {module106 != undefined && 
        <GroupComponent 
          key={module106.module_id} 
          name={module106.title} 
          id={1} 
          color={module106.icon_color} 
          icon={module106.icon_name}
          type={GroupType.ENTITY} 
        />
      }
      {(serviceCallList ?? []).map((g: UserGroup) => userIsDirector()
        ? 
          <ServiceCallGroup
            description={g.group.description}
            name={g.group.name}
            id={g.group.id}
            parent_id={g.group.parent_id}
            key={g.group.id}
          /> 
        : 
          <GroupComponent 
            key={g.group.id + g.group.name} 
            {...g.group} 
          />
      )}
      
    </Container>
  )
})