import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { theme, Text, IconButton as UiIconButton, Row, Space } from 'ui'
import { observer } from 'mobx-react'
import useStore from 'store'
import Checkbox from '@material-ui/core/Checkbox';

const Container = styled.div`
  display:flex;
  flex-direction:column;
  padding:5px;
  padding-bottom:0;
`
const OverflowContainer = styled.div`
  overflow:auto;
  flex:1;
  padding:0 5px;
  /* display:flex;
  justify-content:flex-start;
  align-items:flex-start; */
`
const UserContainer = styled.div`
  display:flex;
  flex:1;
  flex-direction:row;
  align-items:center;
  border-top:1px solid ${theme.color.black + 33};
  /* margin:10px 5px; */
`
const ItemContainer = styled.div<{ flex: number }>`
  display:flex;
  flex:${p => p.flex ? p.flex : 1};
  border:1px solid ${theme.color.black + 33};
  align-items:center;
  justify-content:center;
`
const UserItemContainer = styled.div<{ flex: number, width: string }>`
  display:flex;
  ${p => `flex: ${p.flex ? p.flex : 1};`}
  ${p => `width: ${p.width ? p.width + ';' : ''}`}
  flex-direction:column;
  padding:10px 0;
`
const HeaderContainer = styled.div`
  display:flex;
  flex-direction:row;
`
const BlueCheckbox = styled(Checkbox) <{ checked: boolean }>`
  *{
    color: ${p => !p.checked ? theme.color.blue + 80 : theme.color.blue};
  }
`
const ListContainer = styled.div`
  display:flex;
  flex:1;
  flex-direction:column-reverse;
`
const UserNoteItems = observer(({ flex, csvRow,  error = null }: { flex?: number | undefined, csvRow?: any, textcolor?: string, error: string | null }) => {
    const { csvStore } = useStore()

    const formatMessage = (message) => {
        try {
            const jsonData = JSON.parse(message)
            return jsonData.description
        }catch (e) {
            return message
        }
    }


    const item = csvStore.csvFileIsraelIdErrors.has(csvRow.guid) ? csvStore.csvFileIsraelIdErrors.get(csvRow.guid): null;
    const errorMessage = item !== null && !item.success ? item.errorDescription : '' 
    
    return (
        <UserItemContainer flex={flex}>
            {item !== null && errorMessage != '' && <Text
                align={'center'}
                textcolor={theme.color.dangerRed}
                weight={'normal'}
                size={'sm'}
            >{formatMessage(errorMessage)}</Text>}

            {item !== null && errorMessage == '' && <Text
                align={'center'}
                textcolor={theme.color.green}
                weight={'normal'}
                size={'sm'}
            >{'IsraelId was updated successfully'}</Text>}
        </UserItemContainer>
    )
})

const UserItems = ({ children, flex, textcolor, onChange, error = null, width = null }: { onChange: (v: string) => void, children: string, flex?: number | undefined, width?: string | undefined, textcolor?: string, error: string | null }) => {
    return (
        <UserItemContainer key={children} flex={flex} width={width}>
            <input
                autoFocus
                style={{ borderWidth: 0, backgroundColor: 'transparent', }}
                value={children}
                onChange={(event) => { onChange(event.target.value) }}
            />
        </UserItemContainer>
    )
}

const HeaderItems = ({ children, flex }: { children: string, flex?: number | undefined }) => {
    return (
        <ItemContainer flex={flex}>
            <Text
                align={'center'}
                textcolor={theme.color.black}
                weight={'medium'}
                size={'md'}
            >{children}</Text>
        </ItemContainer>
    )
}

const UserView = observer(({ csvUser }: { csvUser: any }) => {
    const { csvStore } = useStore()
    const { t } = useTranslation('csvHeaders')

    const toggleUserState = () => {
        csvStore.changeSocialIdStatus(csvUser.guid)
    }

    return (
        <UserContainer>
            <UserItemContainer flex={0.4} style={{ alignItems: 'center', justifyContent: 'center', }}>
                {<BlueCheckbox
                    disabled={false}
                    style={{ zIndex: 10 }}
                    checked={csvUser.selected}
                    onChange={toggleUserState}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />}
            </UserItemContainer>
            <UserItems
                error={''}
                onChange={(v) => {
                    const israeliID = csvUser.israeliID
                    csvUser.israeliID = v
                    csvStore.updateSocialId(csvUser, csvUser.guid)
                }}>{csvUser.israeliID}
            </UserItems>
            <UserItems
                error={''}
                onChange={(v) => {
                    csvUser.phone = v
                    csvStore.updateSocialId(csvUser, csvUser.guid)
                }} textcolor={theme.color.blueText} flex={1.3}>{csvUser.phone}
            </UserItems>
            <UserItems
                error={''}
                onChange={(v) => {
                    csvUser.email = v
                    csvStore.updateSocialId(csvUser, csvUser.guid)
                }} textcolor={theme.color.blueText} flex={1.3}>{csvUser.email}
            </UserItems>
            <UserNoteItems
                csvRow={csvUser}
                textcolor={theme.color.blueText} flex={1.3}>
            </UserNoteItems>
        </UserContainer>
    )
})

const UsersList = observer(() => {
    const { csvStore } = useStore()
    return (
        <ListContainer>
            {csvStore.getCsvSocialIds.map((csvUser) => <UserView csvUser={csvUser} />)}
        </ListContainer>
    )
})

const CsvListScreen = () => {
    const { t } = useTranslation('members')
    const { csvStore } = useStore()
    const [selectAll, setSelectAll] = useState(true)

    const changSelectAll = () => {
        csvStore.changeStatusAllSocialIds((!selectAll))
        setSelectAll(s => !s)
    }

    return (
        <>
            <Container>
                <Space height={0.5} />
                <HeaderContainer>
                    <ItemContainer flex={0.4}>
                        <BlueCheckbox
                            checked={selectAll}
                            onChange={() => changSelectAll()}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </ItemContainer>
                    <HeaderItems>{t`israeliID`}</HeaderItems>
                    <HeaderItems flex={1.3}>{t`csvHeaders:phone1`}</HeaderItems>
                    <HeaderItems flex={1.3}>{t`email`}</HeaderItems>
                    <HeaderItems flex={1.3}>{''}</HeaderItems>
                </HeaderContainer>
            </Container>
            <OverflowContainer>
                <UsersList />
            </OverflowContainer>
        </>
    )
}

export default observer(CsvListScreen)