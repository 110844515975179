import React, {FC, useEffect, useState} from 'react'
import {observer} from 'mobx-react'
import useStore from 'store'
import {ModuleEnum, ModuleType, ProviderEnum} from 'utils/types'
import {useTranslation} from 'react-i18next'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {Space, Row, Icon, Text, theme} from '../../ui'
import {
    TextField,
    Dialog,
    IconButton,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Switch,
    Checkbox,
} from '@material-ui/core'
import { Close, ExpandMore } from '@material-ui/icons'
import { isTotzeret } from 'service/whiteLable'
import styled from 'styled-components'
import {
    ModalRow,
    InfoWrapper,
    ModalText,
    ConfirmButtonSm,
    DeleteButtonSm,
    ButtonGroup,
    DialogHeaderText
} from './styles'
import kehilanetData from '../../data/kehilanet.json';
import icons from 'components/Groups/groupCategories.json'
import { isAction } from 'mobx'

const StyledTextField = styled(TextField)`
  width: 100%;
`



const SelectContent = styled.div`
    align-items:center;
    flex-direction:row;
    display:flex;

`
const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  cursor:pointer;
`

const IconBg = styled.div<{ selected: boolean }>`
  background: ${p => isTotzeret ? 'transparent' : p.color};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0.33rem;
  border-radius: 8px;
  margin-right:35px;
  border-color: ${p => p.selected ? theme.color.select : theme.color.white};
  border-width: 4px;
  border-style: solid;
  body[dir=rtl] & {
    margin-right: unset;
    margin-left: 20px;
    }
`


export interface KehilanetFormState {
    moduleId: null,
    name: string,
    description: string,
    url: string,
    iconColor: string,
    iconText: string,
    orgId: string,
    isEnable: boolean
}

interface KehilanetSectionProps {
    currentOrgId: number | undefined
    resetForm: boolean
    isFormChanged: boolean
    Save: () => void
    Delete: () => void
    ToggleEnable: (value: boolean) => void
    ChangeName: (value: string) => void
    ChangeDescription: (value: string) => void
    ChangeUrl: (value: string) => void
    ChangeOrgId: (value: boolean) => void
    ChangeIconColor: (value: boolean) => void
}

const IconSelect: FC<{ icons: any, onChange: any, givenSelectedIcon: any }> = ({ icons, onChange, givenSelectedIcon }) => {
    const [selectedIcon, setSelectedIcon] = useState<string | undefined>(givenSelectedIcon)
    const clickHandler = (iconOptions: any) => {
        setSelectedIcon(iconOptions.icon)
        onChange(iconOptions)
    }
    return (
        <IconsContainer>
            {
                icons.map((iconOptions: any) => (
                    <IconBg selected={selectedIcon == iconOptions.icon} color={iconOptions.iconColor} onClick={() => clickHandler(iconOptions)}>
                        <Icon name={iconOptions.icon} />
                    </IconBg>
                ))
            }
        </IconsContainer >
    )
}

const KehilanetSection: FC<KehilanetSectionProps> = ({ currentOrgId, resetForm }) => {
    const {modulesStore, orgs} = useStore()
    const {t} = useTranslation('communitySettings')

    const [isFormChanged, setIsFormChanged] = useState<boolean>(false)
    const [module, setModule] = useState<any>(null)
    const [modules, setModules] = useState<[]>([])
    const [formState, setFormState] = useState<KehilanetFormState>({
        moduleId: null,
        name: '',
        description: '',
        url: '',
        iconColor: '',
        iconText: '',
        orgId: '',
        isEnable: false
    }) 

    useEffect(() => {
        const modulesFiltered = modulesStore.communityModules
          .filter((module) => module.provider === ProviderEnum.KEHILANET)

        setModules(modulesFiltered)  

        if (modulesFiltered.length > 0) {
            const moduleFirst = modulesFiltered[0] 

            setModule(moduleFirst)
            setFormState({
                moduleId: moduleFirst.module_id,
                name: moduleFirst.title,
                description: moduleFirst.description,
                url: moduleFirst.external_url,
                iconColor: moduleFirst.icon_color,
                orgId: moduleFirst.org_id,
                isEnable: moduleFirst.enable,
                iconText: moduleFirst.icon_name
            })
        }
    }, [orgs.currentOrgId])

    useEffect(() => {
        if (resetForm) onResetForm()
    }, [resetForm])

    useEffect(() => {
        setIsFormChanged(
            formState.name !== (module?.title ?? '') ||
            formState.description !== (module?.description ?? '') ||
            formState.url !== (module?.external_url ?? '') ||
            formState.iconColor !== (module?.icon_color ?? '') ||
            formState.iconText !== (module?.icon_name ?? '') ||
            formState.orgId !== (module?.org_id ?? '') ||
            formState.isEnable !== (module?.enable ?? '')
        )
    }, [formState, module])


    const Save = async () => {
        const newModule: ModuleType = {
            communty_id: module.community_id,
            community_ref: module.community_ref,
            enable: formState.isEnable,
            module_id: module.module_id,
            type: ModuleEnum.KEHILANET, 
            title: formState.name, //string && nullable
            description: formState.description, //string && nullable
            external_url: formState.url, //string && nullable
            icon_name: formState.iconText, //string && nullable
            icon_text: '',
            icon_color:formState.iconColor,
            org_id: formState.orgId || null,
            provider:  ProviderEnum.KEHILANET,
            information: '',
            data: '',
            share: false,
            shareEmail: ''
        }

        await modulesStore.updateModule(newModule)
        await modulesStore.getCommunityModulesAndFeatures(currentOrgId)
        const modules = modulesStore.communityModules
          .filter((module) => module.provider === ProviderEnum.KEHILANET)

        setModules(modules)  

        const selectedModule = modules.find(m => m.module_id == module.module_id)
        setModule(selectedModule)
    }
    
    const Delete = async () => {
        await modulesStore.deleteModule(module.module_id as number)
        await modulesStore.getCommunityModulesAndFeatures(currentOrgId)

        const modules = modulesStore.communityModules
          .filter((module) => module.provider === ProviderEnum.KEHILANET)

        setModules(modules)  
        setModule(null)
        onResetForm()
    }

    const onResetForm = () => setFormState({
        moduleId: null,
        name: '',
        description: '',
        url: '',
        iconColor: '',
        iconText: '',
        orgId: '',
        isEnable: false
    })

    const ToggleEnable = (value: any) => setFormState({...formState, isEnable: value })
    const ChangeName = (value: any) => setFormState({...formState, name: value })
    const ChangeDescription = (value: any) => setFormState({...formState, description: value })
    const ChangeUrl = (value: any) => setFormState({...formState, url: value })
    const ChangeOrgId = (value: any) => setFormState({...formState, orgId: value })

    const onChangeIcon = (icon) => {
        setFormState({...formState, iconText: icon.icon, iconColor: icon.iconColor })
    }

    const selectModule = (moduleId) => {
      const module = modules.find(m => m.module_id == moduleId)

      if (module === undefined) {
         return
      }

      setModule(module)
      setFormState({
        moduleId,
        name: module.title,
        description: module.description,
        url: module.external_url,
        iconColor: module.icon_color,
        orgId: module.org_id,
        isEnable: module.enable,
        iconText: module.icon_name
      })
    }

    const interfaceValue = () => {
        if(!module) return ''
        if (!module.external_url) return ''

        const interfaceMatch = module.external_url.match(/interfaceId=(\d+)/)
        if (!interfaceMatch || interfaceMatch.length < 2) return ''

        const interfaceId = parseInt(interfaceMatch[1])
        const item = kehilanetData.interfaces.find(i => i.value == interfaceId)
        if (item == undefined) return ''

        return item
    }

    if (modules.length == 0)  {
        return (<></>)
    }

    return (
        <>
            <hr/>
            <Space/>
            <ModalRow>
               <DialogHeaderText>{t`kehilanet`}</DialogHeaderText>
               <ButtonGroup>
                 <DeleteButtonSm  disabled={module == null} onClick={Delete}>{t`common:deletion`}</DeleteButtonSm>
                 <ConfirmButtonSm disabled={!isFormChanged || module == null} onClick={Save} transparent>{t`save`}</ConfirmButtonSm>
               </ButtonGroup>
            </ModalRow>

            <Autocomplete
                value={module ? { value: module?.module_id, text: module?.title } : null}
                onChange={(event, value) => {
                    selectModule(value?.value || null)
                }}
                id="combo-box-provider"
                options={modules.map(({ module_id, title }) => ({ text: title, value: module_id  }))}
                placeholder={t`kehilanet_module`}
                getOptionLabel={(option) => option.text}
                style={{width: '100%'}}
                renderInput={(params) => <TextField {...params} label={t`kehilanet_module`} variant="outlined"/>}
            />

            {module && 
                <>
                <ModalRow>
                    <InfoWrapper>
                        <ModalText>{t`isEnable`}:</ModalText>
                        <Space/>
                        <Switch
                            color="primary"
                            value={formState.isEnable}
                            onChange={({target}) => {
                                ToggleEnable(target.checked)
                            }}
                            checked={formState.isEnable}
                        />
                    </InfoWrapper>
                </ModalRow>

                <Space height={1}/>
                <StyledTextField
                    value={formState.name}
                    id="outlined-basic"
                    label={t`name`} 
                    variant="outlined"
                    onChange={({target}) => {
                        ChangeName(target.value)
                    }}
                />

                <Space height={1}/>
                <StyledTextField
                    value={formState.description}
                    id="outlined-basic"
                    label={t`description`} 
                    variant="outlined"
                    onChange={({target}) => {
                        ChangeDescription(target.value)
                    }}
                />

                <Space height={1}/>
                <StyledTextField
                    value={formState.orgId}
                    id="outlined-basic"
                    label={t`orgId`} 
                    variant="outlined"
                    onChange={({target}) => {
                        ChangeOrgId(target.value)
                    }}
                />

                <Space height={1}/>
                <Autocomplete
                    value={interfaceValue()}
                    onChange={(event, value) => {
                        const baseUrl = 'https://www.kehilanet.co.il/mekome/login.asp'
                        ChangeUrl(value == null ? '' : `${baseUrl}?interfaceId=${value?.value}`)
                    }}
                    id="combo-box-provider"
                    options={kehilanetData.interfaces}
                    placeholder={t`kehilanet_module`}
                    getOptionLabel={(option) => option.text}
                    style={{width: '100%'}}
                    renderInput={(params) => <TextField {...params} label={t`kehilanet_module`} variant="outlined"/>}
                />

               <Space height={1}/>
                <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <SelectContent>
                                <Space width={1} />
                                {formState.iconText && <IconBg
                                    selected={false}
                                    color={formState.iconColor}
                                >
                                    <Icon name={formState.iconText} />
                                </IconBg>}
                                <Text>{formState.iconText ? t`switchIcon` : t`icon`}</Text>
                            </SelectContent>
                        </AccordionSummary>
                        <AccordionDetails>
                            <IconSelect
                                icons={icons}
                                onChange={onChangeIcon}
                                givenSelectedIcon={formState.iconText} />
                        </AccordionDetails>
                    </Accordion>
            </>
            }
            <Space/>
            <Space/>
        </>
    )
}

export default observer(KehilanetSection)