import React, { FC, useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { Space, theme, Row, Text, Image } from 'ui'
import { Attachment, AddCircle, RemoveCircle, ExpandMore, VisibilityOff } from '@material-ui/icons'
import {
  Switch, IconButton, Checkbox, Accordion, AccordionSummary, AccordionDetails,
} from '@material-ui/core'

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items:center;
`
const StyledHiddenSectionTitle = styled(Text)`
  font-size:16px;
  font-weight:900;
  /* margin-right:24px; */
`
const StyledHiddenSectionSubTitle = styled(Text)`
  font-size:16px;
  font-weight:300;
  opacity:0.6;
`
const HideTextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items:flex-start;
  margin-right:24px;
`

const CustomAccordion = styled(Accordion) <{ IsHidden: boolean }>`
  /* border-radius: 8px; */
  background-color:${p => p.IsHidden ? theme.color.HalfSpaniceWhite : theme.color.white};
  .MuiAccordionSummary-content{
    flex-direction:row;
    /* justify-content:space-between; */
    align-items:center;
  }
`
const SmallVisibilityOff = styled(VisibilityOff)`
  font-size:1rem;
  color:${theme.color.black + 99};
  margin-left:31px;
`

const StyledAccordionSummary = styled(AccordionSummary)`
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
`

const StyledSwitch = styled(Switch)`
`

interface UserHideFromAlphoneLineProps {
  title: string,
  subTitle: string,
  checked?: boolean
  handleChange: (e: any) => void
}
const UserHideFromAlphoneLine: FC<UserHideFromAlphoneLineProps> = ({ title, subTitle, checked = false, handleChange }) => {
  const [open, setOpen] = useState(false)
  return (
    <CustomAccordion expanded={open} IsHidden={checked}>
      <StyledAccordionSummary expandIcon={<ExpandMore onClick={() => setOpen(s => !s)} />}>
        <StyledHiddenSectionTitle>{title}</StyledHiddenSectionTitle>
        <StyledSwitch
          checked={checked}
          onChange={handleChange}
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
        {checked && <SmallVisibilityOff />}
      </StyledAccordionSummary>
      <AccordionDetails>
        <HideTextsContainer>
          <StyledHiddenSectionSubTitle>{subTitle}</StyledHiddenSectionSubTitle>
        </HideTextsContainer>
      </AccordionDetails>
    </CustomAccordion>
  )
}

export default UserHideFromAlphoneLine