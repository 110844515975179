import React, { FC, useState } from 'react'
import styled from 'styled-components'
import {
  Menu as MuiMenu,
  MenuItem,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  TableHead as MuiTableHeader,
  IconButton,
} from '@material-ui/core'
import { Mail as MailIcon, Menu, Phone as PhoneIcon, Edit, VisibilityOff } from '@material-ui/icons'
import { observer } from 'mobx-react'
import useStore from 'store'
import { SmallTableCell } from 'ui/Table/Table'
import { useTranslation } from 'react-i18next'
import { theme, Text, Row } from 'ui'
import { formatIfPhoneNumber, intToBool } from 'utils/converts'
import WordsHighlighter from 'components/WordsHighlighter'
import { User } from 'utils/models'
import { UserGroup } from 'utils/types'
import Alert from '@material-ui/lab/Alert'
import isMobile from 'utils/isMobile'

const TableCell = styled(SmallTableCell)`
`
const StyledMuiTable = styled(MuiTable)`
  flex:1;
`
const StyledColumn = styled.div`
  flex:1;
`
const HeaderItem = styled(MuiTableCell)`
  font-size: ${theme.fontSize.small};;
  color: ${theme.color.darkGray};
`
const GetRole = ({ member, searchText }: { member: any, searchText: string }) => {
  const { t } = useTranslation('membersTable')
  let role = ''
  if (member?.is_organizer) role = t`groupManager`
  return <TableCell style={{ minWidth: 130 }}>
    <WordsHighlighter searchText={searchText} text={role} />
  </TableCell>

}

const MediaContainer = styled.div`
    justify-content:space-around;
    align-items:center;
    display: flex;
    flex-direction: column;
`
const SmallVisibilityOff = styled(VisibilityOff)`
  font-size:1.2rem;
  color:${theme.color.black + 99};
`
const ShowInAlphoneComponent = ({ showInAlphone = false }: { showInAlphone?: boolean }) => {
  return (
    <TableCell style={{ flex: 0.5, justifyContent: 'center' }}>
      {!showInAlphone && <SmallVisibilityOff />}
    </TableCell>
  )
}

const MediaList = ({ list, type, searchText }: { list: Array<string>, type?: string, searchText: string }) => {
  if (!list) {
    return <TableCell></TableCell>
  }
  return (
    <TableCell style={{ minWidth: 150 }}>
      <MediaContainer style={{ display: 'flex', alignItems: type == 'mail' ? 'flex-end' : 'flex-start', }}>
        {
          list?.map((text: string) =>
            <div key={text}>
              <a
                href={type === 'mail' ? `mailto:${text}` : `tel:${text}`}
                data-rel='external'
              >
                <Text
                  textcolor={theme.color.blueText}
                  style={{ marginBottom: 5 }}
                >
                  <WordsHighlighter
                    searchText={searchText}
                    text={formatIfPhoneNumber(text) || ''} />

                </Text>
              </a>
            </div>
          )
        }
      </MediaContainer>
    </TableCell>
  )
}

interface IMemberView {
  member: UserGroup,
  userView: boolean,
  searchText: string
}

const MemberView: React.FC<IMemberView> = ({ member, userView, searchText }) => {
  const { t } = useTranslation('membersTable')
  const { nick_name, family_name, given_name, phones, emails, user_id, app, is_showInAlphon } = member.userData!
  const [showActionArrow, setShowActionArrow] = useState(false)
  const onEnter = () => setShowActionArrow(true)
  const onLeave = () => setShowActionArrow(false)
  const { groups, uiStore } = useStore()
  const fullName = `${family_name} ${nick_name ? ' (' + nick_name + ') ' : ''} ${given_name}`
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const openActionMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const changeManager = async () => {
    try {
      await groups.changeManagerState(user_id, member?.is_organizer ? true : false)
    } catch (e) {
      console.log('change manager error:', e)
    }
    setAnchorEl(null)
  }
  const removeMember = () => {
    uiStore.openWarningModal({
      text: t`remove:removeGroup`,
      secondText: fullName,
      okAction: () => groups.removeMembers(user_id)
    })
    setAnchorEl(null)
  }
  return (
    <>
      <MuiTableRow
        key={user_id}
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
        hover
      >
        <TableCell style={{ textAlign: 'right' }}>
          <WordsHighlighter
            searchText={searchText}
            text={fullName}
          ></WordsHighlighter>
        </TableCell>
        <MediaList searchText={searchText} list={phones} />
        <MediaList searchText={searchText} type='mail' list={emails} />
        {!userView && <ShowInAlphoneComponent showInAlphone={is_showInAlphon} />}
        <GetRole searchText={searchText} member={member} />
        {!userView && (
          <>
            <TableCell
              style={{ cursor: 'pointer' }}
              onClick={openActionMenu}
            >
            <Menu
              style={{ opacity: showActionArrow ? 1 : 0 }}
              color={'primary'}
            />
          </TableCell>
          <MuiMenu
            id='lock-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {!!anchorEl && (
              <MenuItem onClick={changeManager}>
                {member?.is_organizer ? t`removeOrganiser` : t`makeOrgnaiser`}
              </MenuItem>
            )}
            <MenuItem style={{ color: theme.color.red }} onClick={removeMember}>
              {t`remove`}
            </MenuItem>
          </MuiMenu>
        </>
        )}
      </MuiTableRow>
    </>
  )
}

interface Props {
  userView?: boolean
  list: UserGroup[]
  searchText: string
}

const Wrap = styled.div`
  width: 100%;
`

const SMember = styled.div`
  direction: ltr;
  body[dir=rtl] & {
    direction: rtl
  }
  background: white;
  border-radius: 8px;
  padding: 1rem 1rem 0.5rem 1rem;
  margin-bottom: 0.5rem;
`

const Name = styled.div`
  font-size: 1.2rem;
  margin-bottom: 1rem;
`

const Phone = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`

const NumberLink = styled.a`
  direction: ltr;
  margin-inline-end: 0.5rem;
`

const Mail = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`

const MailLink = styled.a`
  direction: ltr;
  margin-inline-start: 0.5rem;
`

const IB = styled(IconButton)`
  margin-left: -1rem;
  margin-top:  -1rem;
`

const MobileUser = ({ member, userView }: { member: UserGroup, userView: boolean }) => {
  const { t } = useTranslation('membersTable')
  const { nick_name, family_name, given_name, phones, emails, user_id, app } = member.userData!
  const [showActionArrow, setShowActionArrow] = useState(false)
  const onEnter = () => setShowActionArrow(true)
  const onLeave = () => setShowActionArrow(false)
  const { groups, uiStore } = useStore()
  const fullName = `${family_name} ${nick_name ? ' (' + nick_name + ') ' : ''} ${given_name}`
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const openActionMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const changeManager = async () => {
    try {
      await groups.changeManagerState(user_id || member.user, member?.is_organizer ? true : false)
    } catch (e) {
      console.log('change manager error:', e)
    }
    setAnchorEl(null)
  }
  const removeMember = () => {
    uiStore.openWarningModal({
      text: t`remove:removeGroup`,
      secondText: fullName,
      okAction: () => groups.removeMembers(user_id)
    })
    setAnchorEl(null)
  }
  return (
    <SMember>
      <Row justify='between'>
        <Name>{given_name + ' ' + family_name}</Name>
        {!userView && <div><IB onClick={openActionMenu}><Edit /></IB></div>}
      </Row>
      <Row align='start'>
        <div>{phones.map(p => <Phone><PhoneIcon /><NumberLink href={`tel:${p}`}>{p}</NumberLink></Phone>)}</div>
        <div>{emails.map(e => <Mail><MailLink href={`mailto:${e}`}>{e}</MailLink><MailIcon /></Mail>)}</div>
      </Row>
      {!userView && (
        <MuiMenu
          id='lock-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={changeManager}>
            {member?.is_organizer ? t`removeOrganiser` : t`makeOrgnaiser`}
          </MenuItem>
          <MenuItem onClick={removeMember}>
            {t`remove`}
          </MenuItem>
        </MuiMenu>
      )}
    </SMember>
  )
}

const MembersModalList: FC<Props> = ({ list, userView = false, searchText }) => {
  const { t } = useTranslation('membersTable')
  // const usersList = list.filter(u => intToBool(u.is_organizer) === false).sort((a, b) => !a.family_name ? 1 : (!b.family_name ? -1 : (a.family_name.localeCompare(b.family_name, 'he', { sensitivity: 'base' }))))
  // const managerList = list.filter(u => intToBool(u.is_organizer) === true).sort((a, b) => !a.family_name ? 1 : (!b.family_name ? -1 : (a.family_name.localeCompare(b.family_name, 'he', { sensitivity: 'base' }))))
  // const sortList = managerList.concat(usersList)
  //let sortList = list.sort((a, b) => !a.userData?.family_name ? 1 : (!b.userData?.family_name ? -1 : (a.userData?.family_name.localeCompare(b.userData?.family_name, 'he', { sensitivity: 'base' }))))
  // if (userView) {
  //   sortList = sortList.filter(u => u?.userData?.is_showInAlphon === true)
  // }
  return (isMobile()
    ? <Wrap>{list.map((member: (UserGroup), index) =>
      <MobileUser
        key={member.userData?.user_id}
        userView={userView}
        member={member}
        searchText={searchText}
      />)}</Wrap>
    : (
    <StyledColumn>
      <StyledMuiTable
        size={'small'}
        stickyHeader>
        <MuiTableHeader>
          <HeaderItem>{t`userName`}</HeaderItem>
          <HeaderItem>{t`phone`}</HeaderItem>
          <HeaderItem>{t`email`}</HeaderItem>
          {!userView && <HeaderItem>{''}</HeaderItem>}
          <HeaderItem>{t`role`}</HeaderItem>
          {!userView && <HeaderItem>{''}</HeaderItem>}
        </MuiTableHeader>

        <MuiTableBody>
          {list.filter(el => el.userData).map((member: (UserGroup), index) =>
            <MemberView
              key={member.userData?.user_id}
              userView={userView}
              member={member}
              searchText={searchText}
            />)}
        </MuiTableBody>
      </StyledMuiTable>
    </StyledColumn>
    )
  )
}

export default observer(MembersModalList)
