import React, {FC, useEffect, useRef, useState} from 'react'
import TextField from "@material-ui/core/TextField";
import {useTranslation} from 'react-i18next'
import { Checkbox } from '@material-ui/core'
import styled from 'styled-components'
import {observer} from 'mobx-react'
import Grow from 'ui/Grow'
import useStore from '../../store';
import {Row, Space} from '../../ui'
import { EmergencyManagerFormState } from './CommunitySettingsModal'
import {ConfirmButtonSm, DeleteButtonSm, DialogHeaderText, ModalText, ModalRow, ButtonGroup} from './styles'

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 5px;
  white-space: nowrap;
`

interface EmergencyManagerProps {
  onEmergencyManagerSave: () => void
  onEmergencyManagerDelete: () => void
  isEmergencyManagerFormChanged: boolean
  emergencyManagerFormState: EmergencyManagerFormState
  onEmergencyManagerToggleDirectorPermission: (e: boolean) => void
}

const EmergencyManagerSection: FC<EmergencyManagerProps> = ({
 onEmergencyManagerSave,
 onEmergencyManagerDelete,
 onEmergencyManagerToggleDirectorPermission,
 isEmergencyManagerFormChanged,
 emergencyManagerFormState,
}) => {
  const {t} = useTranslation('communitySettings')
  const { t: t2 } = useTranslation('budget')

  const { modulesStore } = useStore()
  
 return (
    <>
     <ModalRow>
       <DialogHeaderText>{t2`emergency_manager`}</DialogHeaderText>
       <ButtonGroup>
         <DeleteButtonSm
            onClick={onEmergencyManagerDelete}>
            {t`common:deletion`}
          </DeleteButtonSm>
          <ConfirmButtonSm
            disabled={!isEmergencyManagerFormChanged}
            onClick={onEmergencyManagerSave}>
            {t`budget:save`}
          </ConfirmButtonSm>
       </ButtonGroup>
     </ModalRow>
      
      <InfoWrapper style={{justifyContent: 'start'}}>
        <ModalText>{t2`allow_director_to_manage`}:</ModalText>
        <Checkbox 
          checked={emergencyManagerFormState.directorPermission}
          onChange={(e) => {
            onEmergencyManagerToggleDirectorPermission(e.target.checked)
          }}
        />
      </InfoWrapper>
      <Space/>
    </>
  )
}

export default observer(EmergencyManagerSection)
