import React, { FC, useState, FormEvent } from 'react'
import styled from 'styled-components'
import { Redirect } from 'react-router-dom'
import { Button, TextField } from '@material-ui/core'
import Page from 'core/Page'
import { Space, Input, theme, Spinner, Text } from 'ui'
import useStore from 'store'
import { useInput } from 'utils/hooks'
import { Path } from 'core/Routes'
import { useTranslation } from 'react-i18next'
import { Send } from '@material-ui/icons'
import FastInput from 'ui/FastInput'
interface ButtonProps {
  opacity?: number
}

const StyledSend = styled(Send)`
  color:white;
  transform: rotate(180deg);
`
const Form = styled.form`
  width: 28vw;
    @media (max-width: 768px) {
    width: 80vw;
  }
  display: flex;
  flex-direction: column;
`

const StyledPage = styled(Page)`
  background-color:${theme.color.loginBackground};
  height:100%;
  width:100%;
  justify-content:center;
  align-items:center;
`

const StyledTextField = styled(FastInput)`
  background: ${theme.color.mercury};
  border-radius: 5px;
  .MuiInputBase-input::placeholder {
    color: black;
    opacity: 0.5;
    font-size:16px;
  }
  font-size:16px;
  `
const StyledButton = styled(Button) <ButtonProps>`
  height:50px;
  background-color: ${theme.color.cerulean};
  border-radius: 5px;
  opacity:${p => p.opacity ? p.opacity : null};
  color:white;
  :hover {background-color: ${theme.color.cerulean};}
`

const InputContainer = styled.div`
display: flex;
flex-direction: column;
`

const INCORRECT_CODE = (code: string) => `Could not register user,the verificaion code ${code} is wrong, this was try#`
const Failed_CODE = "Could not register user - no verificaion code exists for user with telephpne/email:"

const Code: FC = () => {
  const { t } = useTranslation('auth')
  const { user } = useStore()
  const [loginErrorMessage, setLoginErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const code = useInput({
    placeholder: t('verify_numbers', { numbers: 'XXXXXX' }),
  })
  const handleOk = async (event: FormEvent) => {
    event.preventDefault()
    setIsLoading(true)
    if (code.value) {
      let res = await user.sendVerificationCode(code.value)
      if (res.indexOf(Failed_CODE) != -1) {
        res = `${t`codeFailed`} ${user.phone}`
      } else
        if (res.indexOf(INCORRECT_CODE(code.value)) != -1) {
          res = t`incorrectCode`
        }

      if (res) {
        setLoginErrorMessage(res)
      }
    }
    setIsLoading(false)
  }
  const handleSendAgain = async () => {
    if (user.email != '')
      await user.sendPhoneOrEmail(user.email)
    else if (user.phone != '') {
      await user.sendPhoneOrEmail(user.phone)
    }
  }

  if (!user.phone && !user.email) return <Redirect to={Path.LOGIN} />
  if (user.hasToken) return <Redirect to={Path.CONFIRM} />

  return (
    <StyledPage flex={true} >
      <Form onSubmit={handleOk}>
        {code.value && code.value != '' &&
          <Text
            size={'sm'}
            textcolor={theme.color.mekomiBlack}
            weight='normal'>
            {t('verify_numbers', { numbers: 'XXXXXX' })}
          </Text>

        }
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {isLoading ? <Spinner /> :
            <>
              <StyledTextField
                autoFocus
                variant='outlined'
                onChange={code.onChange}
              />
              {/* <FastInput onChange={code.onChange} /> */}
            </>
          }
        </div>
        <Space height={1} />
        <Text
          textAlign={'center'}
          opacity={0.6}
          textcolor={loginErrorMessage ? 'red' : theme.color.mekomiBlack}
          weight='normal'>
          {loginErrorMessage ? loginErrorMessage : t`codeInfo`}
        </Text>
        <Space height={2} />
        <StyledButton disabled={isLoading} type="submit" variant='contained' opacity={code.value && code.value != '' ? 1 : 0.3}>
          <Text
            size={'md'}
            textAlign={'center'}
            textcolor={'white'}
            weight='normal'>
            {t`send`}
          </Text>
          <Space width={0.7} />
          <StyledSend />
        </StyledButton>
        <Space height={3} />
        <Text
          textAlign={'center'}
          textcolor={theme.color.codeInfoText}
          weight='normal'>
          {user.phone != '' ? `${t`sentToPhone`} ${user.phone}` : user.email != '' ? `${t`sentToEmail`} ${user.email}` : ''}
        </Text>
        <Space height={3} />
        <Button onClick={handleSendAgain}>
          <Text
            size={'md'}
            textAlign={'center'}
            textcolor={theme.color.sendAgainBlue}
            weight='bold'
          >
            {t`sendAgain`}
          </Text>
        </Button>
      </Form>
    </StyledPage>
  )

}

export default Code
