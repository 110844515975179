import React, { FC } from 'react'
import styled from 'styled-components'
import { Tooltip } from '@material-ui/core'
import { saveCsvImportUserListErrors } from 'utils/csvUtils'
import { useTranslation } from 'react-i18next'
import { ListAlt } from '@material-ui/icons'
import { theme, Text, Space, IconButton as UiIconButton, Row } from 'ui'
import { observer } from 'mobx-react'
import useStore from 'store'
import type { User } from 'utils/models'
import { Attachment } from '@material-ui/icons'
import Dropzone from 'react-dropzone'
import { saveCsv } from 'utils/csvUtils'

const Container = styled.div`
    flex:1;
    display:flex;
    flex-direction:column;
    margin:10px;
`

const DropArea = styled.div`
    border-radius: 4px;
    color: rgba(0,0,0,0.6);
    background: #fafafa;
    border: 1px dashed ${theme.color.gray};
    display: flex;
    justify-content:center;
    align-items: center;
    flex:1;
`

const DropAreaContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`

const DropAreaIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const emptyUser: User = { given_name: '', family_name: '', phones: ['', ''], emails: [''], nick_name: '' }


const DropCsvView = ({ handleUpload }: { handleUpload: (fils: File[]) => void }) => {
    const { t } = useTranslation('members')
    const { uiStore } = useStore()
    return (
        <Dropzone
            onDrop={handleUpload}
            maxFiles={1}
            onDropRejected={(e) => {
                if (e && e[0]?.errors && e[0]?.errors[0]?.code === 'too-many-files') {
                    uiStore.openWarningModal({
                        text: t`fileLimit`,
                        okText: t`app:gotIt`,
                        removeCancelButton: true

                    })
                }
                if (e && e[0]?.errors && e[0]?.errors[0]?.code === 'file-invalid-type') {
                    uiStore.openWarningModal({
                        text: t`MessageDialog:notCSV`,
                        okText: t`app:gotIt`,
                        removeCancelButton: true
                    })
                }

            }}
            multiple={false}
        // accept={['text/csv']}
        >
            {({ getRootProps, getInputProps }) => (
                <DropArea {...getRootProps()}>
                    <input {...getInputProps()} />
                    <DropAreaContent>
                        <DropAreaIconsContainer>
                            <Attachment />
                        </DropAreaIconsContainer>
                        <Text align={'center'} textcolor={theme.color.black + 'A1'}>{t`dropCsv`}</Text>
                    </DropAreaContent>
                </DropArea>
            )}
        </Dropzone>
    )
}

const AddCsvScreen: FC = ({ }) => {
    const { t } = useTranslation('members')
    const { t: t2 } = useTranslation('csvHeaders')
    const { uiStore, csvStore } = useStore()

    const handleUpload = async (files: File[]) => {
        if (files.length != 1) {
            return
        }

        try {
            const { hasErrors, errors } = await csvStore.setCsvFileUsers(files[0]);

            if (hasErrors) {
                uiStore.openWarningModal({
                    text: t`MessageDialog:errorsList`,
                    secondText: "על מנת לייצא את השגיאות לקובץ חדש יש ללחוץ על כפתור ייצוא",
                    okText: 'ייצוא',
                    cancelText: "סגור",
                    cancelAction: () => {
                        csvStore.cleanCsvUsers()
                    },
                    okAction: () => {
                        saveCsvImportUserListErrors(errors, t);
                        csvStore.cleanCsvUsers()
                    }
                })    
            }
        } catch (error) {
            uiStore.openWarningModal({
                text: t`MessageDialog:notCSV`,
                okText: t`app:gotIt`,
                removeCancelButton: true

            })
            csvStore.cleanCsvUsers()
        }

    }
    return (
        <Container>
            <Row>
                <Text
                    align={'center'}
                    textcolor={theme.color.black + 99}
                    weight={'medium'}
                    size={'xl'}
                >{t`addFromCsv`}</Text>
                <div style={{ flex: 1 }} />
                <Tooltip title={t`csvHeaders:example_Format`} arrow>
                    <div>
                        <UiIconButton style={{ backgroundColor: theme.color.azureBlue }} onClick={() => {
                            saveCsv([emptyUser], t`csvHeaders:example_Format_name`, t2, true)
                        }}>
                            <ListAlt style={{ color: 'white' }} />
                        </UiIconButton>
                    </div>
                </Tooltip>
            </Row>
            <Space height={0.5} />
            <DropCsvView handleUpload={handleUpload} />
        </Container>
    )
}

export default observer(AddCsvScreen)