import React, {useLayoutEffect, useRef, useEffect, useState, useCallback} from 'react'
import { List, ListSubheader, Button } from '@material-ui/core'
import useStore from 'store'
import { theme, Space, Spinner } from 'ui'
import Text from 'ui/Text/Text'
import { GroupType, StatusType } from 'utils/types'
import type {  UserMessage } from 'utils/models'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import MessageItem from './MessageItem'
import { formatDate } from '../../../utils/date'
import { useTranslation } from 'react-i18next'
import EmptyComponent from 'components/Empty'
import { isAfter, parseISO, startOfDay } from 'date-fns'
import { Add } from '@material-ui/icons'
import { isSupportGroupOrganizer } from 'utils/roles'
import throttle from 'lodash/throttle'
import DownArrow from 'assets/arrow_dropdown_copy_2.svg'
import { autorun } from 'mobx'

const MessageList = styled(List)`
  & li > ul {
    padding: 0;
  }
  & :first-child {
    margin-top: auto;
    /* use !important to prevent breakage from child margin settings */
}
  display:flex;
  flex:1;
  flex-direction:column-reverse;
  overflow-y: auto;
  overflow-anchor: none !important;
  scroll-snap-stop: normal !important;
  overscroll-behavior: unset !important;
  scroll-behavior: unset !important;
`

const SectionHeader = styled(ListSubheader)`
  display: flex;
  align-items: center;
  justify-content: center;
`
const PointerEndOfList = styled.div`
 float: left;
 clear:both;
`
interface MessageSection {
  date: Date | number,
  messages: UserMessage[]
}

const Wrap = styled.div`
  position: relative;
  padding: 1px 1rem 1px 0;
  display:flex;
  flex:1;
  overflow:hidden;
`
const EmptyServiceCall = styled.div`
  display:flex;
  flex:1;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  white-space:pre-line;
`

const ScrollToBottomButton = styled.button<{ showButton: boolean }>`
  display:flex;
  align-items:center;
  justify-content:center;
  box-shadow: #00000080 0 0 0px 0px;
  background-color: #ffffff;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  font-size: 16px;
  height: 3rem;
  width: 3rem;
  position:fixed;
  bottom:30px;
  z-index:100;
  opacity:0;
  transform: translateY(calc(30px + 48px));
  transition: all .5s ease;
  ${p => p.showButton && `
  opacity: 1;
  transform: translateY(0);
  box-shadow: #00000080 0 0 10px 0px;
  `}
  &:active{
    opacity:0.5;
  }
`

const UnreadContainer = styled.div`
  padding:8px;
  display:flex;
  align-items:center;
  justify-content:center;
`
const UnreadText = styled.span<{ color: string }>`
  box-shadow:${theme.color.shadow} 0 0 6px 0px;
  padding:8px 15px;
  background-color:${p => p.color};
  border-radius:20px;
  color:${theme.color.black + 66};
`

const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
`

const MessagesFeed = () => {
  const { messages, groups, newMessage, user: userStore } = useStore()
  const { t } = useTranslation('common')
  const [messagesList, setMessagesList] = useState<MessageSection[]>([])
  const msgCount = messagesList.reduce((a, b) => b.messages.length + a, 0)
  const [unread, setUnread] = useState<{ unreadCount: number, unreadMessageId: number } | null>(null)
  const [showButton, setShowButton] = useState(false)
  const scrollableListRef = useRef<HTMLDivElement | null>(null)
  const groupIsEmpty = messages.messageFeedSections.length === 0
  const [isLoadNewData, setIsLoadNewData] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [pageNumber, setPageNumber] = useState(2)
  const isFetchingDone = useRef(false)
  const unreadRef = useRef<HTMLDivElement | null>(null)

  const scrollToBottom = () => {
    scrollableListRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const scrollToUnread = (ref: HTMLDivElement) => {
    if (!unreadRef.current) {
      if (ref) {
        unreadRef.current = ref;
        ref.scrollIntoView({ block: 'center' })
      }
    }
  }

  const mainColor = groups.currentGroup?.group.description?.color || 'gray'

  const getEmptyComponent = (type: any) => {
    if (type === undefined || type === GroupType.REGULAR) {
      return <EmptyComponent category={groups.currentGroup?.group?.description?.subCategoryKey} />
    } else if (type === GroupType.SUPPORT) {
      return <EmptyServiceCall>
        <Text
          style={{ paddingTop: 30, paddingBottom: 10, width: 500, textAlign: 'center' }}
          textcolor={theme.color.black + 99}
          size='xxl'
          weight='medium'>{isSupportGroupOrganizer() ? t(`serviceCall:empty_manager_group`, { groupName: groups.currentGroup?.group?.name }) : t`serviceCall:empty_page_message`}</Text>
        <Space />
        {!isSupportGroupOrganizer() && <Button
          onClick={() => {
            messages.openNewMessageDialog()
            newMessage.createNewMesage()
          }}
          size="large"
          style={{ color: 'white', backgroundColor: mainColor }}
          variant="contained"
          endIcon={<Add />}
        >
          {t`serviceCall:open_call`}
        </Button>}
      </EmptyServiceCall>
    }
  }

  const onScroll = throttle((event: React.UIEvent<HTMLUListElement, UIEvent>) => {
    const scrollTop = Math.abs(event?.nativeEvent?.target?.scrollTop)
    const scrollHeight = event?.nativeEvent?.target?.scrollHeight
    const innerHeight = window.innerHeight

    const result = scrollHeight - (scrollTop + innerHeight + 0.5)

    if (result < 0 && !isFetching && !isFetchingDone.current) {
      handleLazyloadGroupMessages();
    }

    if (Math.abs(event?.nativeEvent?.target?.scrollTop || 0) > 400) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
  }, 300)

  const handleLazyloadGroupMessages = async () => {
    setIsFetching(true)
    const data = await messages.lazyloadGroupMessages({pageNumber, pageSize: 20, groupId: groups.currentGroupId || 0 });

    if (data.messages.length <= 0) {
      isFetchingDone.current = true
    } else {
      setPageNumber(prev => prev + 1)
      const newMessageBulk = data.messages
        .map((item: any) =>  ({
          user: item.id,
          status: item.status,
          response: item.response,
          message: item,
        })
      )
      
      const doSortDateSections = []  
      let sections: Map<number, UserMessage[]> = messagesList.reduce((acc, item) => {
        acc.set(item.date, item.messages)
        return acc
      }, new Map())

      sections = newMessageBulk.reduce((acc, message) => {
        let sectionDate = startOfDay(parseISO(message.message.created)).getTime()
        
        if (isNaN(sectionDate)) {
          sectionDate = startOfDay(parseISO(message.message.created.toISOString())).getTime()
        }

        const section = acc.get(sectionDate)
        section ? section.push(message) : acc.set(sectionDate, [message])
        doSortDateSections.push(sectionDate)
        
        return acc
      }, sections)

      const result =  Array.from(sections.keys())
        .sort((a: number, b: number) => b - a)
        .map(key => {
          const section = sections.get(key)
          const sortedMessages = doSortDateSections.includes(key) 
            ? section.sort((a: any, b: any) => new Date(a.message.created).getTime() - new Date(b.message.created).getTime())
            : section
              
          return {  date: key, messages: sortedMessages }  
        })

      setMessagesList(result)
    }

    setIsFetching(false)
  };

  useEffect(() => {
    setPageNumber(2);
    setIsLoadNewData(false);
    isFetchingDone.current = false;
  }, [groups.currentGroupId]);

  useEffect(() => {
    if (!groupIsEmpty && messages.scrollFeed) {
      scrollableListRef.current?.scrollIntoView({ behavior: 'smooth' });
      messages.ScrollFeedToEnd(false);
    }
  }, [messages.scrollFeed]);

  useEffect(() => {
    const cancel = autorun(() => {
      const list = messages.messageFeedSections.slice().reverse().map(el =>
        ({ ...el, messages: el.messages.filter((el: any) => isAfter(new Date(), el.message.schedule || 0)) })).filter(el => el.messages.length)
      setMessagesList(list)
      setIsLoadNewData(true)
      const unreadMessages = messages.messageFeedSections.map(el => el.messages).flat(1).filter(el => el.status <= StatusType.RECEIVED && el.message.senderId != userStore.user?.user_id)
      const unreadMessage = Math.min(...unreadMessages.map(el => el.message.id))
      messages.markMessagesRead(unreadMessages)
      setUnread({ unreadCount: unreadMessages.length, unreadMessageId: unreadMessage })
    })
    return () => {
      cancel()
    }
  }, []);

  return (
    <>
      {groupIsEmpty ? getEmptyComponent(groups.currentGroup?.group.type) :
        <Wrap>
          {isLoadNewData && (
          <MessageList subheader={<li />} onScroll={onScroll}>
            <PointerEndOfList ref={scrollableListRef} />
            {messagesList.map((section: MessageSection) => (
              <li key={`section-${section.date}`}>
                <ul style={{ flex: 1 }}>
                  <SectionHeader>
                    <Text
                      style={{ paddingTop: 30, paddingBottom: 10 }}
                      textcolor={theme.color.black + 99}
                      size='sm'
                      weight='medium'>{section.date ? formatDate(section.date, t) : ''}</Text>
                  </SectionHeader>
                  {section.messages?.map((userMessage: UserMessage) => {
                    return (
                      <>
                        <React.Fragment key={`item-${userMessage.message.create_guid}`}>
                          {unread?.unreadMessageId === userMessage.message.id && msgCount > 1 && msgCount != unread.unreadCount && (
                            <UnreadContainer ref={scrollToUnread}>
                              <UnreadText color={mainColor + 33} >{unread.unreadCount > 1 ? t('messageItem:new_messages', { number: unread.unreadCount }) : t('messageItem:new_message')}</UnreadText>
                            </UnreadContainer>
                          )}
                          <MessageItem key={`item-${userMessage.message.create_guid}`} userMessage={userMessage} />
                        </React.Fragment>
                      </>
                    )
                  })}
                </ul>
              </li>
            ))}
          </MessageList>
            )}
          <ScrollToBottomButton showButton={showButton} onClick={scrollToBottom}><img src={DownArrow} /></ScrollToBottomButton>
          {isFetching && <SpinnerContainer><Spinner /></SpinnerContainer>}
        </Wrap>
      }
    </>
  )
}

export default observer(MessagesFeed);
