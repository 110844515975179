import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { List, Icon, Button } from '@material-ui/core'
import { theme, Space, Text } from 'ui'
import OrgDialog from 'components/Orgs/OrgDialog'
import Group from 'components/Groups/Group'
import useStore from 'store'
import { canCreateGroup, userIsSuperAdmin } from 'utils/roles'
import { useTranslation } from 'react-i18next'
import { Add } from '@material-ui/icons'
import { useLocation } from "react-router-dom";
import { Path } from 'core/Routes'
import ServiceCallListSidebar from './ServiceCallListSidebar'
import { UserGroup } from 'utils/types'

interface Props { }

const StyledSidebar = styled.div<{ borderColor: string }>`
  width: 25vw;
  max-width: 420px;
  /* min-width: 360px; */
  /* text-align: center; */
  overflow: hidden;
  /* margin-left: 10px; */
  box-shadow: 0 2px 4px 0 rgba(12, 0, 51, 0.1);
  /* border-radius: 5px; */
  background: var(--white);
  /* body[dir=rtl] & {
    margin: 19px 26px 19px 5px;
  } */
  border-left-color: ${p => p.borderColor + '33'};
    border-left-style: solid;
    border-left-width: 1px;
`

const AddCommunity = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${theme.color.sendingBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
  margin: 0px 7px 0px 7px;
`

const Scroll = styled(List)`
  height: 100%;
  overflow-y: auto;
  padding: 10px 1rem;
`
const SmallText = styled.p`
  color: ${theme.color.black};
  font-size: ${theme.fontSize.small};
`

const NewCommunityContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledAdd = styled(Add)`
  display: block;
  margin: auto;
  color:white;
`

const Sidebar: FC<Props> = () => {
  const { t } = useTranslation('sidebar')
  const { groups } = useStore()
  const userCanAddGroup = canCreateGroup()
  const openModal = () => groups.addGroup()
  const iconColor = groups.currentGroup?.group?.description?.iconColor
  const [groupsList, setGroupsList] = useState<Array<UserGroup>>([])
  const [myCallsList, setMyCallsList] = useState<Array<UserGroup>>([])

  let location = useLocation();

  useEffect(() => {
    if (location.pathname === Path.MESSAGES) {
      setGroupsList(userIsSuperAdmin() ? groups?.currentUserGroups : groups?.filteredCurrentUserGroups.filter(el => el.is_favourite))
    }
    if (location.pathname === Path.SERVICECALL) {
      const myCallsList = groups?.currentSupportGroups.filter(g => g.is_organizer === true).sort((a, b) => {
        const aTime = new Date(groups.lastMessages.get(groups.currentOpenSupportGroups.find(g => g.group.parent_id === a.group.parent_id)?.group?.id || 0)?.lastMessage.message.created || 0).getTime()
        const bTime = new Date(groups.lastMessages.get(groups.currentOpenSupportGroups.find(g => g.group.parent_id === b.group.parent_id)?.group?.id || 0)?.lastMessage.message.created || 0).getTime()
        return bTime - aTime
      })

      const serviceCalList = groups?.currentSupportGroups.filter(g => g.is_organizer === false)

      setGroupsList(serviceCalList)
      setMyCallsList(myCallsList)
    }
  }, [location, groups?.currentSupportGroups, groups?.filteredCurrentUserGroups, groups?.currentUserGroups])

  if (!groupsList.length && !myCallsList.length && !userCanAddGroup) {
    return null
  }
  return (
    <StyledSidebar borderColor={iconColor ? iconColor : theme.color.grayBackground}>
      <Scroll>
        <ButtonContainer>
          {location.pathname === Path.MESSAGES && userCanAddGroup && (
            <Button
              onClick={openModal}><NewCommunityContainer>
                <SmallText>{t`addGroup`}</SmallText>
                <AddCommunity ><Icon>{<StyledAdd />}</Icon></AddCommunity>
              </NewCommunityContainer>
            </Button>
          )}
        </ButtonContainer>
        {location.pathname === Path.SERVICECALL && <ServiceCallListSidebar list={myCallsList} serviceCallList={groupsList} />}
        {location.pathname === Path.MESSAGES && groupsList.map((g: UserGroup) => <Group key={g.group.id + g.group.name} {...g.group} />)}
      </Scroll>
      <OrgDialog />
    </StyledSidebar>
  )
}

export default observer(Sidebar)
