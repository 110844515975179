import React, { FC } from 'react'
import styled from 'styled-components'
import { IconButton, IconButtonProps } from '@material-ui/core'
import { theme } from 'ui'

const StyledButton = styled(IconButton) <IconButtonProps>`
  background: ${theme.color.buttonBackground};
  width: 30px;
  height: 30px;
  margin: 0;
  color: ${theme.color.iconButton};
  border-radius: 6px;
  margin-left: 12px;
  .MuiButton-startIcon {
    margin-left: 0px;
    margin-right: 0px;
    body[dir=rtl] & {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
  body[dir=rtl] & {
      margin-left: 0px;
      /* margin-right: 12px; */
    }
  .MuiSvgIcon-root {
    font-size: 20px;
  }
  &:hover img {
    filter: brightness(0) invert(1);
  }
  &:hover {
    background: var(--button);
    color: white;
  }
`

const Button: FC<IconButtonProps> = ({ ...props }) => <StyledButton {...props} />

export default Button
