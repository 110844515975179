const mock = {
  "success": true,
  "data": {
    "metaData": {
      "chargees": [
        {
          "id": "101",
          "name": {
            "he": "קיבוץ",
            "enUs": "Kibbutz"
          },
          "type": "kibbutz",
          "displayName": "קיבוץ"
        },
        {
          "id": "102",
          "name": {
            "he": "אגודה קהילתית",
            "enUs": "Community"
          },
          "type": "community",
          "displayName": "אגודה קהילתית"
        }
      ],
      "financialBooksEntity": [
        {
          "id": "101",
          "name": {
            "he": "קיבוץ",
            "enUs": "Kibbutz"
          },
          "type": "kibbutz",
          "displayName": "קיבוץ"
        },
        {
          "id": "102",
          "name": {
            "he": "אגודה קהילתית",
            "enUs": "Community"
          },
          "type": "community",
          "displayName": "אגודה קהילתית"
        }
      ],
      "extraCharges": [
        {
          "id": "201",
          "chargeeId": "101",
          "name": {
            "he": "מכבסה",
            "enUs": "Laundry"
          }
        },
        {
          "id": "202",
          "chargeeId": "102",
          "name": {
            "he": "חינוך",
            "enUs": "Education (kids)"
          }
        },
        {
          "id": "203",
          "chargeeId": "101",
          "name": {
            "he": "מרפאה",
            "enUs": "Medical clinic"
          }
        },
        {
          "id": "204",
          "chargeeId": "101",
          "name": {
            "he": "חדר אוכל",
            "enUs": "Dining room"
          }
        },
        {
          "id": "205",
          "chargeeId": "101",
          "name": {
            "he": "כלבו",
            "enUs": "Supermarket"
          }
        }
      ]
    },
    "transactions": [
      {
        "dateOfCharge": "09/12/2020",
        "details": "נטו מדף משכורת 12.20",
        "enUs": "",
        "totalSum": "-17503",
        "quantity": "",
        "netPrice": "",
        "docType": "480",
        "accountGroup": "130537",
        "accountNumber": "740501110",
        "budgetNumber": "740501110",
        "accountName": "ישראל ישראלי - הדגמה",
        "chargee": "101",
        "items": []
      },
      {
        "dateOfCharge": "31/12/2020",
        "details": "חלוקת רווחים 12.20",
        "enUs": "",
        "totalSum": "-1500",
        "quantity": "",
        "netPrice": "",
        "docType": "480",
        "accountGroup": "130537",
        "accountNumber": "740501110",
        "budgetNumber": "740501110",
        "accountName": "ישראל ישראלי - הדגמה",
        "chargee": "101",
        "items": []
      },
      {
        "dateOfCharge": "31/12/2020",
        "details": "גמול תפקיד 12.20",
        "enUs": "",
        "totalSum": "-2200",
        "quantity": "",
        "netPrice": "",
        "docType": "480",
        "accountGroup": "130537",
        "accountNumber": "740501110",
        "budgetNumber": "740501110",
        "accountName": "ישראל ישראלי - הדגמה",
        "chargee": "101",
        "items": []
      },
      {
        "dateOfCharge": "31/12/2020",
        "details": "מתנת חג משק",
        "enUs": "",
        "totalSum": "-400",
        "quantity": "",
        "netPrice": "",
        "docType": "480",
        "accountGroup": "130537",
        "accountNumber": "740501110",
        "budgetNumber": "740501110",
        "accountName": "ישראל ישראלי - הדגמה",
        "chargee": "101",
        "items": []
      },
      {
        "dateOfCharge": "31/12/2020",
        "details": "זיכוי ביטוח סיעודי פרטי 12.20",
        "enUs": "",
        "totalSum": "-591",
        "quantity": "",
        "netPrice": "",
        "docType": "480",
        "accountGroup": "130537",
        "accountNumber": "740501110",
        "budgetNumber": "740501110",
        "accountName": "ישראל ישראלי - הדגמה",
        "chargee": "101",
        "items": []
      },
      {
        "dateOfCharge": "09/12/2020",
        "details": "נטו בבנק",
        "enUs": "",
        "totalSum": "17503",
        "quantity": "",
        "netPrice": "",
        "docType": "480",
        "accountGroup": "130537",
        "accountNumber": "740501110",
        "budgetNumber": "740501110",
        "accountName": "ישראל ישראלי - הדגמה",
        "chargee": "101",
        "items": []
      },
      {
        "dateOfCharge": "31/12/2020",
        "details": "חינוך",
        "enUs": "Education (kids)",
        "totalSum": "2980",
        "quantity": "3",
        "netPrice": "",
        "docType": "902",
        "accountGroup": "830538",
        "accountNumber": "740501110",
        "budgetNumber": "740501110",
        "accountName": "ישראל ישראלי - הדגמה",
        "chargee": "102",
        "items": [
          {
            "dateOfCharge": "01/12/2020 08:25",
            "details": "טליה - חינוך חברתי 12.20",
            "enUs": "",
            "totalSum": "280",
            "quantity": "1",
            "netPrice": "280",
            "accountGroup": "700004",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "dateOfCharge": "01/12/2020 08:25",
                "details": "טליה - חינוך חברתי 12.20",
                "enUs": "",
                "totalSum": "280",
                "quantity": "1",
                "netPrice": "280",
                "accountGroup": "700004",
                "accountNumber": "740501110",
                "budgetNumber": "740501110",
                "accountName": "ישראל ישראלי - הדגמה"
              }
            ],
            "price": "280",
            "vat": "40.68",
            "discount": "0"
          },
          {
            "dateOfCharge": "01/12/2020 09:12",
            "details": "אורי - גן גורים 12.20",
            "enUs": "",
            "totalSum": "1500",
            "quantity": "1",
            "netPrice": "1500",
            "accountGroup": "700004",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "dateOfCharge": "01/12/2020 09:12",
                "details": "אורי - גן גורים 12.20",
                "enUs": "",
                "totalSum": "1500",
                "quantity": "1",
                "netPrice": "1500",
                "accountGroup": "700004",
                "accountNumber": "740501110",
                "budgetNumber": "740501110",
                "accountName": "ישראל ישראלי - הדגמה"
              }
            ],
            "price": "1500",
            "vat": "217.95",
            "discount": "0"
          },
          {
            "dateOfCharge": "01/12/2020 14:20",
            "details": "נעמה - גן עופרים 12.20",
            "enUs": "",
            "totalSum": "1200",
            "quantity": "1",
            "netPrice": "1200",
            "accountGroup": "700004",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "dateOfCharge": "01/12/2020 14:20",
                "details": "נעמה - גן עופרים 12.20",
                "enUs": "",
                "totalSum": "1200",
                "quantity": "1",
                "netPrice": "1200",
                "accountGroup": "700004",
                "accountNumber": "740501110",
                "budgetNumber": "740501110",
                "accountName": "ישראל ישראלי - הדגמה"
              }
            ],
            "price": "1200",
            "vat": "174.36",
            "discount": "0"
          }
        ],
        "sumPerProduct": "993.33"
      },
      {
        "dateOfCharge": "31/12/2020",
        "details": "כלבו",
        "enUs": "Supermarket",
        "totalSum": "787.54",
        "quantity": "72.3",
        "netPrice": "",
        "docType": "902",
        "accountGroup": "130537",
        "accountNumber": "740501110",
        "budgetNumber": "740501110",
        "accountName": "ישראל ישראלי - הדגמה",
        "chargee": "101",
        "items": [
          {
            "dateOfCharge": "03/12/2020 08:33",
            "details": "",
            "enUs": "",
            "totalSum": "78.73",
            "quantity": "7.25",
            "netPrice": "",
            "docType": "670",
            "accountGroup": "8020000",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "details": "גאודה טבעי",
                "enUs": "",
                "totalSum": "24.9",
                "quantity": "1.00",
                "netPrice": "24.90",
                "price": "24.90",
                "vat": "3.62",
                "discount": "0"
              },
              {
                "details": "עגבניות מרוסקות ויטה",
                "enUs": "",
                "totalSum": "20.7",
                "quantity": "3",
                "netPrice": "6.9",
                "price": "6.9",
                "vat": "3.01",
                "discount": "0"
              },
              {
                "details": "צימוקים כהים",
                "enUs": "",
                "totalSum": "15",
                "quantity": "2",
                "netPrice": "7.5",
                "price": "7.5",
                "vat": "2.18",
                "discount": "0"
              },
              {
                "details": "אורז בסמטי ארוך",
                "enUs": "",
                "totalSum": "10.9",
                "quantity": "1",
                "netPrice": "10.9",
                "price": "10.9",
                "vat": "1.58",
                "discount": "0"
              },
              {
                "details": "שום ישראלי",
                "enUs": "",
                "totalSum": "7.23",
                "quantity": "0.25",
                "netPrice": "28.92",
                "price": "28.92",
                "vat": "1.05",
                "discount": "0"
              }
            ],
            "sumPerProduct": "10.86"
          },
          {
            "dateOfCharge": "05/12/2020 08:50",
            "details": "",
            "enUs": "",
            "totalSum": "62.75",
            "quantity": "4.37",
            "netPrice": "",
            "docType": "670",
            "accountGroup": "8020000",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "details": "טונה במי מלח",
                "enUs": "",
                "totalSum": "49",
                "quantity": "2",
                "netPrice": "24.5",
                "price": "24.5",
                "vat": "7.12",
                "discount": "0"
              },
              {
                "details": "בצל יבש",
                "enUs": "",
                "totalSum": "13.75",
                "quantity": "2.37",
                "netPrice": "5.8",
                "price": "5.8",
                "vat": "2",
                "discount": "0"
              }
            ],
            "sumPerProduct": "14.36"
          },
          {
            "dateOfCharge": "06/12/2020 11:16",
            "details": "",
            "enUs": "",
            "totalSum": "42.5",
            "quantity": "1",
            "netPrice": "",
            "docType": "670",
            "accountGroup": "8020000",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "details": "נייר טואלט פוף קומפקט",
                "enUs": "",
                "totalSum": "42.5",
                "quantity": "1",
                "netPrice": "42.5",
                "price": "42.5",
                "vat": "6.18",
                "discount": "0"
              }
            ],
            "sumPerProduct": "42.5"
          },
          {
            "dateOfCharge": "08/12/2020 09:20",
            "details": "",
            "enUs": "",
            "totalSum": "42.6",
            "quantity": "5",
            "netPrice": "",
            "docType": "670",
            "accountGroup": "8020000",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "details": "מיץ תפוזים 2 ליטר פרימור",
                "enUs": "",
                "totalSum": "25.6",
                "quantity": "1",
                "netPrice": "25.6",
                "price": "25.6",
                "vat": "3.72",
                "discount": "0"
              },
              {
                "details": "מסיר שומנים ויש",
                "enUs": "",
                "totalSum": "9.9",
                "quantity": "1",
                "netPrice": "9.9",
                "price": "9.9",
                "vat": "1.44",
                "discount": "0"
              },
              {
                "details": "אטריות אורז רחבות",
                "enUs": "",
                "totalSum": "6.9",
                "quantity": "1",
                "netPrice": "6.9",
                "price": "6.9",
                "vat": "1",
                "discount": "0"
              },
              {
                "details": "שקית נילון",
                "enUs": "",
                "totalSum": "0.2",
                "quantity": "2",
                "netPrice": "0.1",
                "price": "0.1",
                "vat": "0.03",
                "discount": "0"
              }
            ],
            "sumPerProduct": "8.52"
          },
          {
            "dateOfCharge": "11/12/2020 17:10",
            "details": "",
            "enUs": "",
            "totalSum": "78.74",
            "quantity": "6.94",
            "netPrice": "",
            "docType": "670",
            "accountGroup": "8020000",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "details": "גבינה מגורדת GOLDEN",
                "enUs": "",
                "totalSum": "18.45",
                "quantity": "1",
                "netPrice": "18.45",
                "price": "18.45",
                "vat": "2.68",
                "discount": "0"
              },
              {
                "details": "טחינה בארכה 500 גרם",
                "enUs": "",
                "totalSum": "14.31",
                "quantity": "1",
                "netPrice": "14.31",
                "price": "14.31",
                "vat": "2.08",
                "discount": "0"
              },
              {
                "details": "מיונז תלמה 500 גרם",
                "enUs": "",
                "totalSum": "21.42",
                "quantity": "2",
                "netPrice": "10.71",
                "price": "10.71",
                "vat": "3.11",
                "discount": "0"
              },
              {
                "details": "כנפיים קפואות עוף",
                "enUs": "",
                "totalSum": "9.22",
                "quantity": "0.94",
                "netPrice": "9.81",
                "price": "9.81",
                "vat": "1.34",
                "discount": "0"
              },
              {
                "details": "ביצים 1/12 L",
                "enUs": "",
                "totalSum": "9.04",
                "quantity": "1",
                "netPrice": "9.04",
                "price": "9.04",
                "vat": "1.31",
                "discount": "0"
              },
              {
                "details": "נבטים סיניים עבים",
                "enUs": "",
                "totalSum": "6.3",
                "quantity": "1",
                "netPrice": "6.3",
                "price": "6.3",
                "vat": "0.92",
                "discount": "0"
              }
            ],
            "sumPerProduct": "11.35"
          },
          {
            "dateOfCharge": "14/12/2020 16:48",
            "details": "",
            "enUs": "",
            "totalSum": "26.82",
            "quantity": "2",
            "netPrice": "",
            "docType": "670",
            "accountGroup": "8020000",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "details": "גאודה גדות",
                "enUs": "",
                "totalSum": "22.41",
                "quantity": "1",
                "netPrice": "22.41",
                "price": "22.41",
                "vat": "3.26",
                "discount": "0"
              },
              {
                "details": "נרות שולחן 1/8",
                "enUs": "",
                "totalSum": "4.41",
                "quantity": "1",
                "netPrice": "4.41",
                "price": "4.41",
                "vat": "0.64",
                "discount": "0"
              }
            ],
            "sumPerProduct": "13.41"
          },
          {
            "dateOfCharge": "16/12/2020 09:49",
            "details": "",
            "enUs": "",
            "totalSum": "63.2",
            "quantity": "6.08",
            "netPrice": "",
            "docType": "670",
            "accountGroup": "8020000",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "details": "עגבניות מרוסקות ויטה",
                "enUs": "",
                "totalSum": "12.42",
                "quantity": "2",
                "netPrice": "6.21",
                "price": "6.21",
                "vat": "1.8",
                "discount": "0"
              },
              {
                "details": "מלח גס לבישול סלית",
                "enUs": "",
                "totalSum": "2.1",
                "quantity": "1",
                "netPrice": "2.1",
                "price": "2.1",
                "vat": "0.31",
                "discount": "0"
              },
              {
                "details": "גויאבה",
                "enUs": "",
                "totalSum": "22.94",
                "quantity": "1.21",
                "netPrice": "18.96",
                "price": "18.96",
                "vat": "3.33",
                "discount": "0"
              },
              {
                "details": "ירכיים קפואות עוף",
                "enUs": "",
                "totalSum": "14.49",
                "quantity": "0.87",
                "netPrice": "16.65",
                "price": "16.65",
                "vat": "2.11",
                "discount": "0"
              },
              {
                "details": "חומוס לבנוני 400 צבר",
                "enUs": "",
                "totalSum": "11.25",
                "quantity": "1",
                "netPrice": "11.25",
                "price": "11.25",
                "vat": "1.63",
                "discount": "0"
              }
            ],
            "sumPerProduct": "10.39"
          },
          {
            "dateOfCharge": "20/12/2020 10:08",
            "details": "",
            "enUs": "",
            "totalSum": "87.54",
            "quantity": "10.61",
            "netPrice": "",
            "docType": "670",
            "accountGroup": "8020000",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "details": "ביצים 1/12 L",
                "enUs": "",
                "totalSum": "22.6",
                "quantity": "2",
                "netPrice": "11.3",
                "price": "11.3",
                "vat": "3.28",
                "discount": "0"
              },
              {
                "details": "קיווי",
                "enUs": "",
                "totalSum": "14.55",
                "quantity": "0.91",
                "netPrice": "15.99",
                "price": "15.99",
                "vat": "2.11",
                "discount": "0"
              },
              {
                "details": "שוקולד קרמל לינדט",
                "enUs": "",
                "totalSum": "11.25",
                "quantity": "1",
                "netPrice": "11.25",
                "price": "11.25",
                "vat": "1.63",
                "discount": "0"
              },
              {
                "details": "צימוק כהה זארובי",
                "enUs": "",
                "totalSum": "8.1",
                "quantity": "1",
                "netPrice": "8.1",
                "price": "8.1",
                "vat": "1.18",
                "discount": "0"
              },
              {
                "details": "בלונים 1/20 שמחה",
                "enUs": "",
                "totalSum": "18.63",
                "quantity": "3",
                "netPrice": "6.21",
                "price": "6.21",
                "vat": "2.71",
                "discount": "0"
              },
              {
                "details": "בטטה",
                "enUs": "",
                "totalSum": "5.21",
                "quantity": "0.7",
                "netPrice": "7.44",
                "price": "7.44",
                "vat": "0.76",
                "discount": "0"
              },
              {
                "details": "רסק עגבניות יכין 260 גרם",
                "enUs": "",
                "totalSum": "7.2",
                "quantity": "2",
                "netPrice": "3.6",
                "price": "3.6",
                "vat": "1.05",
                "discount": "0"
              }
            ],
            "sumPerProduct": "8.25"
          },
          {
            "dateOfCharge": "22/12/2020 17:57",
            "details": "",
            "enUs": "",
            "totalSum": "102.18",
            "quantity": "9.32",
            "netPrice": "",
            "docType": "670",
            "accountGroup": "8020000",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "details": "פסטרמה בדבש טהור 300 גרם",
                "enUs": "",
                "totalSum": "22.41",
                "quantity": "1",
                "netPrice": "22.41",
                "price": "22.41",
                "vat": "3.26",
                "discount": "0"
              },
              {
                "details": "שקיות אשפה עם שרוך 52X65",
                "enUs": "",
                "totalSum": "13.41",
                "quantity": "1",
                "netPrice": "13.41",
                "price": "13.41",
                "vat": "1.95",
                "discount": "0"
              },
              {
                "details": "בטטה",
                "enUs": "",
                "totalSum": "11.25",
                "quantity": "1.48",
                "netPrice": "7.6",
                "price": "7.6",
                "vat": "1.63",
                "discount": "0"
              },
              {
                "details": "מיונז תלמה 500 גרם",
                "enUs": "",
                "totalSum": "10.71",
                "quantity": "1",
                "netPrice": "10.71",
                "price": "10.71",
                "vat": "1.56",
                "discount": "0"
              },
              {
                "details": "קטשופ אוסם מופחת סוכר",
                "enUs": "",
                "totalSum": "20.7",
                "quantity": "2",
                "netPrice": "10.35",
                "price": "10.35",
                "vat": "3.01",
                "discount": "0"
              },
              {
                "details": "פריכונים אורז מלא 1/2",
                "enUs": "",
                "totalSum": "10.37",
                "quantity": "1",
                "netPrice": "10.37",
                "price": "10.37",
                "vat": "1.51",
                "discount": "0"
              },
              {
                "details": "שקית קומפוסט למיכל 7 ליטר",
                "enUs": "",
                "totalSum": "5.4",
                "quantity": "1",
                "netPrice": "5.4",
                "price": "5.4",
                "vat": "0.78",
                "discount": "0"
              },
              {
                "details": "דלורית",
                "enUs": "",
                "totalSum": "5.25",
                "quantity": "0.72",
                "netPrice": "7.29",
                "price": "7.29",
                "vat": "0.76",
                "discount": "0"
              },
              {
                "details": "שום ישראלי",
                "enUs": "",
                "totalSum": "2.68",
                "quantity": "0.12",
                "netPrice": "22.31",
                "price": "22.31",
                "vat": "0.39",
                "discount": "0"
              }
            ],
            "sumPerProduct": "10.96"
          },
          {
            "dateOfCharge": "23/12/2020 12:26",
            "details": "",
            "enUs": "",
            "totalSum": "21.92",
            "quantity": "5.25",
            "netPrice": "",
            "docType": "670",
            "accountGroup": "8020000",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "details": "עגבניות",
                "enUs": "",
                "totalSum": "11.52",
                "quantity": "2.25",
                "netPrice": "5.12",
                "price": "5.12",
                "vat": "1.67",
                "discount": "0"
              },
              {
                "details": "אפרסק 500 FUZE Tea",
                "enUs": "",
                "totalSum": "6.3",
                "quantity": "1",
                "netPrice": "6.3",
                "price": "6.3",
                "vat": "0.92",
                "discount": "0"
              },
              {
                "details": "מקופלת חלב",
                "enUs": "",
                "totalSum": "3.6",
                "quantity": "1",
                "netPrice": "3.6",
                "price": "3.6",
                "vat": "0.52",
                "discount": "0"
              },
              {
                "details": "קירור פחית/חצי ליטר",
                "enUs": "",
                "totalSum": "0.5",
                "quantity": "1",
                "netPrice": "0.5",
                "price": "0.5",
                "vat": "0.07",
                "discount": "0"
              }
            ],
            "sumPerProduct": "4.18"
          },
          {
            "dateOfCharge": "24/12/2020 15:28",
            "details": "",
            "enUs": "",
            "totalSum": "21.98",
            "quantity": "3.48",
            "netPrice": "",
            "docType": "670",
            "accountGroup": "8020000",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "details": "חלה תבנית מרובעת",
                "enUs": "",
                "totalSum": "10.34",
                "quantity": "2",
                "netPrice": "5.17",
                "price": "5.17",
                "vat": "1.5",
                "discount": "0"
              },
              {
                "details": "חלה קלועה מתוקה",
                "enUs": "",
                "totalSum": "7.65",
                "quantity": "1",
                "netPrice": "7.65",
                "price": "7.65",
                "vat": "1.11",
                "discount": "0"
              },
              {
                "details": "פלפל צהוב",
                "enUs": "",
                "totalSum": "3.99",
                "quantity": "0.48",
                "netPrice": "8.31",
                "price": "8.31",
                "vat": "0.58",
                "discount": "0"
              }
            ],
            "sumPerProduct": "6.32"
          },
          {
            "dateOfCharge": "26/12/2020 07:45",
            "details": "",
            "enUs": "",
            "totalSum": "44.82",
            "quantity": "2",
            "netPrice": "",
            "docType": "670",
            "accountGroup": "8020000",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "details": "גאודה טבעי",
                "enUs": "",
                "totalSum": "44.82",
                "quantity": "2",
                "netPrice": "22.41",
                "price": "22.41",
                "vat": "6.51",
                "discount": "0"
              }
            ],
            "sumPerProduct": "22.41"
          },
          {
            "dateOfCharge": "27/12/2020 11:21",
            "details": "",
            "enUs": "",
            "totalSum": "103.05",
            "quantity": "8",
            "netPrice": "",
            "docType": "670",
            "accountGroup": "8020000",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "details": "נייר טואלט פוף קומקט",
                "enUs": "",
                "totalSum": "38.25",
                "quantity": "1",
                "netPrice": "38.25",
                "price": "38.25",
                "vat": "5.56",
                "discount": "0"
              },
              {
                "details": "סלט טונה 160 גרם",
                "enUs": "",
                "totalSum": "23.4",
                "quantity": "4",
                "netPrice": "5.85",
                "price": "5.85",
                "vat": "3.4",
                "discount": "0"
              },
              {
                "details": "מיץ תפוזים 2 ליטר פרימור",
                "enUs": "",
                "totalSum": "23.04",
                "quantity": "1",
                "netPrice": "23.04",
                "price": "23.04",
                "vat": "3.35",
                "discount": "0"
              },
              {
                "details": "פלמוליב כלים ירוק קלאסי",
                "enUs": "",
                "totalSum": "10.71",
                "quantity": "1",
                "netPrice": "10.71",
                "price": "10.71",
                "vat": "1.56",
                "discount": "0"
              },
              {
                "details": "אורז בסמטי הימלאיה",
                "enUs": "",
                "totalSum": "7.65",
                "quantity": "1",
                "netPrice": "7.65",
                "price": "7.65",
                "vat": "1.11",
                "discount": "0"
              }
            ],
            "sumPerProduct": "12.88"
          },
          {
            "dateOfCharge": "28/12/2020 16:12",
            "details": "",
            "enUs": "",
            "totalSum": "10.71",
            "quantity": "1",
            "netPrice": "",
            "docType": "670",
            "accountGroup": "8020000",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "details": "סוכר דמררה בהיר",
                "enUs": "",
                "totalSum": "10.71",
                "quantity": "1",
                "netPrice": "10.71",
                "price": "10.71",
                "vat": "1.56",
                "discount": "0"
              }
            ],
            "sumPerProduct": "10.71"
          }
        ],
        "sumPerProduct": "10.89"
      },
      {
        "dateOfCharge": "31/12/2020",
        "details": "חשמל 12.20",
        "enUs": "",
        "totalSum": "510.6",
        "quantity": "851",
        "netPrice": "0.60",
        "docType": "902",
        "accountGroup": "130537",
        "accountNumber": "740501110",
        "budgetNumber": "740501110",
        "accountName": "ישראל ישראלי - הדגמה",
        "chargee": "101",
        "items": [],
        "price": "0.60",
        "vat": "74.19",
        "discount": "0"
      },
      {
        "dateOfCharge": "31/12/2020",
        "details": "חדר אוכל",
        "enUs": "Dining room",
        "totalSum": "612",
        "quantity": "34",
        "netPrice": "",
        "docType": "902",
        "accountGroup": "130537",
        "accountNumber": "740501110",
        "budgetNumber": "740501110",
        "accountName": "ישראל ישראלי - הדגמה",
        "chargee": "101",
        "items": [
          {
            "dateOfCharge": "01/12/2020 12:45",
            "details": "ארוחה",
            "enUs": "",
            "totalSum": "18",
            "quantity": "1.00",
            "netPrice": "18.00",
            "accountGroup": "700006",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "dateOfCharge": "01/12/2020 12:45",
                "details": "ארוחה",
                "enUs": "",
                "totalSum": "18",
                "quantity": "1.00",
                "netPrice": "18.00",
                "accountGroup": "700006",
                "accountNumber": "740501110",
                "budgetNumber": "740501110",
                "accountName": "ישראל ישראלי - הדגמה"
              }
            ],
            "price": "18.00",
            "vat": "2.62",
            "discount": "0"
          },
          {
            "dateOfCharge": "02/12/2020 12:17",
            "details": "ארוחה",
            "enUs": "",
            "totalSum": "18",
            "quantity": "1.00",
            "netPrice": "18.00",
            "accountGroup": "700006",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "dateOfCharge": "02/12/2020 12:17",
                "details": "ארוחה",
                "enUs": "",
                "totalSum": "18",
                "quantity": "1.00",
                "netPrice": "18.00",
                "accountGroup": "700006",
                "accountNumber": "740501110",
                "budgetNumber": "740501110",
                "accountName": "ישראל ישראלי - הדגמה"
              }
            ],
            "price": "18.00",
            "vat": "2.62",
            "discount": "0"
          },
          {
            "dateOfCharge": "03/12/2020 12:40",
            "details": "ארוחה",
            "enUs": "",
            "totalSum": "18",
            "quantity": "1.00",
            "netPrice": "18.00",
            "accountGroup": "700006",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "dateOfCharge": "03/12/2020 12:40",
                "details": "ארוחה",
                "enUs": "",
                "totalSum": "18",
                "quantity": "1.00",
                "netPrice": "18.00",
                "accountGroup": "700006",
                "accountNumber": "740501110",
                "budgetNumber": "740501110",
                "accountName": "ישראל ישראלי - הדגמה"
              }
            ],
            "price": "18.00",
            "vat": "2.62",
            "discount": "0"
          },
          {
            "dateOfCharge": "04/12/2020 12:32",
            "details": "ארוחה",
            "enUs": "",
            "totalSum": "18",
            "quantity": "1.00",
            "netPrice": "18.00",
            "accountGroup": "700006",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "dateOfCharge": "04/12/2020 12:32",
                "details": "ארוחה",
                "enUs": "",
                "totalSum": "18",
                "quantity": "1.00",
                "netPrice": "18.00",
                "accountGroup": "700006",
                "accountNumber": "740501110",
                "budgetNumber": "740501110",
                "accountName": "ישראל ישראלי - הדגמה"
              }
            ],
            "price": "18.00",
            "vat": "2.62",
            "discount": "0"
          },
          {
            "dateOfCharge": "06/12/2020 12:54",
            "details": "ארוחה",
            "enUs": "",
            "totalSum": "36",
            "quantity": "2.00",
            "netPrice": "18.00",
            "accountGroup": "700006",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "dateOfCharge": "06/12/2020 12:54",
                "details": "ארוחה",
                "enUs": "",
                "totalSum": "36",
                "quantity": "2.00",
                "netPrice": "18.00",
                "accountGroup": "700006",
                "accountNumber": "740501110",
                "budgetNumber": "740501110",
                "accountName": "ישראל ישראלי - הדגמה"
              }
            ],
            "price": "18.00",
            "vat": "5.23",
            "discount": "0"
          },
          {
            "dateOfCharge": "07/12/2020 12:30",
            "details": "ארוחה",
            "enUs": "",
            "totalSum": "36",
            "quantity": "2.00",
            "netPrice": "18.00",
            "accountGroup": "700006",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "dateOfCharge": "07/12/2020 12:30",
                "details": "ארוחה",
                "enUs": "",
                "totalSum": "36",
                "quantity": "2.00",
                "netPrice": "18.00",
                "accountGroup": "700006",
                "accountNumber": "740501110",
                "budgetNumber": "740501110",
                "accountName": "ישראל ישראלי - הדגמה"
              }
            ],
            "price": "18.00",
            "vat": "5.23",
            "discount": "0"
          },
          {
            "dateOfCharge": "08/12/2020 12:24",
            "details": "ארוחה",
            "enUs": "",
            "totalSum": "18",
            "quantity": "1.00",
            "netPrice": "18.00",
            "accountGroup": "700006",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "dateOfCharge": "08/12/2020 12:24",
                "details": "ארוחה",
                "enUs": "",
                "totalSum": "18",
                "quantity": "1.00",
                "netPrice": "18.00",
                "accountGroup": "700006",
                "accountNumber": "740501110",
                "budgetNumber": "740501110",
                "accountName": "ישראל ישראלי - הדגמה"
              }
            ],
            "price": "18.00",
            "vat": "2.62",
            "discount": "0"
          },
          {
            "dateOfCharge": "09/12/2020 12:47",
            "details": "ארוחה",
            "enUs": "",
            "totalSum": "36",
            "quantity": "2.00",
            "netPrice": "18.00",
            "accountGroup": "700006",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "dateOfCharge": "09/12/2020 12:47",
                "details": "ארוחה",
                "enUs": "",
                "totalSum": "36",
                "quantity": "2.00",
                "netPrice": "18.00",
                "accountGroup": "700006",
                "accountNumber": "740501110",
                "budgetNumber": "740501110",
                "accountName": "ישראל ישראלי - הדגמה"
              }
            ],
            "price": "18.00",
            "vat": "5.23",
            "discount": "0"
          },
          {
            "dateOfCharge": "10/12/2020 12:40",
            "details": "ארוחה",
            "enUs": "",
            "totalSum": "18",
            "quantity": "1.00",
            "netPrice": "18.00",
            "accountGroup": "700006",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "dateOfCharge": "10/12/2020 12:40",
                "details": "ארוחה",
                "enUs": "",
                "totalSum": "18",
                "quantity": "1.00",
                "netPrice": "18.00",
                "accountGroup": "700006",
                "accountNumber": "740501110",
                "budgetNumber": "740501110",
                "accountName": "ישראל ישראלי - הדגמה"
              }
            ],
            "price": "18.00",
            "vat": "2.62",
            "discount": "0"
          },
          {
            "dateOfCharge": "11/12/2020 13:00",
            "details": "ארוחה",
            "enUs": "",
            "totalSum": "36",
            "quantity": "2.00",
            "netPrice": "18.00",
            "accountGroup": "700006",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "dateOfCharge": "11/12/2020 13:00",
                "details": "ארוחה",
                "enUs": "",
                "totalSum": "36",
                "quantity": "2.00",
                "netPrice": "18.00",
                "accountGroup": "700006",
                "accountNumber": "740501110",
                "budgetNumber": "740501110",
                "accountName": "ישראל ישראלי - הדגמה"
              }
            ],
            "price": "18.00",
            "vat": "5.23",
            "discount": "0"
          },
          {
            "dateOfCharge": "14/12/2020 12:32",
            "details": "ארוחה",
            "enUs": "",
            "totalSum": "36",
            "quantity": "2.00",
            "netPrice": "18.00",
            "accountGroup": "700006",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "dateOfCharge": "14/12/2020 12:32",
                "details": "ארוחה",
                "enUs": "",
                "totalSum": "36",
                "quantity": "2.00",
                "netPrice": "18.00",
                "accountGroup": "700006",
                "accountNumber": "740501110",
                "budgetNumber": "740501110",
                "accountName": "ישראל ישראלי - הדגמה"
              }
            ],
            "price": "18.00",
            "vat": "5.23",
            "discount": "0"
          },
          {
            "dateOfCharge": "15/12/2020 13:09",
            "details": "ארוחה",
            "enUs": "",
            "totalSum": "36",
            "quantity": "2.00",
            "netPrice": "18.00",
            "accountGroup": "700006",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "dateOfCharge": "15/12/2020 13:09",
                "details": "ארוחה",
                "enUs": "",
                "totalSum": "36",
                "quantity": "2.00",
                "netPrice": "18.00",
                "accountGroup": "700006",
                "accountNumber": "740501110",
                "budgetNumber": "740501110",
                "accountName": "ישראל ישראלי - הדגמה"
              }
            ],
            "price": "18.00",
            "vat": "5.23",
            "discount": "0"
          },
          {
            "dateOfCharge": "16/12/2020 12:58",
            "details": "ארוחה",
            "enUs": "",
            "totalSum": "18",
            "quantity": "1.00",
            "netPrice": "18.00",
            "accountGroup": "700006",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "dateOfCharge": "16/12/2020 12:58",
                "details": "ארוחה",
                "enUs": "",
                "totalSum": "18",
                "quantity": "1.00",
                "netPrice": "18.00",
                "accountGroup": "700006",
                "accountNumber": "740501110",
                "budgetNumber": "740501110",
                "accountName": "ישראל ישראלי - הדגמה"
              }
            ],
            "price": "18.00",
            "vat": "2.62",
            "discount": "0"
          },
          {
            "dateOfCharge": "17/12/2020 12:13",
            "details": "ארוחה",
            "enUs": "",
            "totalSum": "36",
            "quantity": "2.00",
            "netPrice": "18.00",
            "accountGroup": "700006",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "dateOfCharge": "17/12/2020 12:13",
                "details": "ארוחה",
                "enUs": "",
                "totalSum": "36",
                "quantity": "2.00",
                "netPrice": "18.00",
                "accountGroup": "700006",
                "accountNumber": "740501110",
                "budgetNumber": "740501110",
                "accountName": "ישראל ישראלי - הדגמה"
              }
            ],
            "price": "18.00",
            "vat": "5.23",
            "discount": "0"
          },
          {
            "dateOfCharge": "19/12/2020 12:51",
            "details": "ארוחה",
            "enUs": "",
            "totalSum": "36",
            "quantity": "2.00",
            "netPrice": "18.00",
            "accountGroup": "700006",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "dateOfCharge": "19/12/2020 12:51",
                "details": "ארוחה",
                "enUs": "",
                "totalSum": "36",
                "quantity": "2.00",
                "netPrice": "18.00",
                "accountGroup": "700006",
                "accountNumber": "740501110",
                "budgetNumber": "740501110",
                "accountName": "ישראל ישראלי - הדגמה"
              }
            ],
            "price": "18.00",
            "vat": "5.23",
            "discount": "0"
          },
          {
            "dateOfCharge": "20/12/2020 12:00",
            "details": "ארוחה",
            "enUs": "",
            "totalSum": "36",
            "quantity": "2.00",
            "netPrice": "18.00",
            "accountGroup": "700006",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "dateOfCharge": "20/12/2020 12:00",
                "details": "ארוחה",
                "enUs": "",
                "totalSum": "36",
                "quantity": "2.00",
                "netPrice": "18.00",
                "accountGroup": "700006",
                "accountNumber": "740501110",
                "budgetNumber": "740501110",
                "accountName": "ישראל ישראלי - הדגמה"
              }
            ],
            "price": "18.00",
            "vat": "5.23",
            "discount": "0"
          },
          {
            "dateOfCharge": "21/12/2020 12:39",
            "details": "ארוחה",
            "enUs": "",
            "totalSum": "36",
            "quantity": "2.00",
            "netPrice": "18.00",
            "accountGroup": "700006",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "dateOfCharge": "21/12/2020 12:39",
                "details": "ארוחה",
                "enUs": "",
                "totalSum": "36",
                "quantity": "2.00",
                "netPrice": "18.00",
                "accountGroup": "700006",
                "accountNumber": "740501110",
                "budgetNumber": "740501110",
                "accountName": "ישראל ישראלי - הדגמה"
              }
            ],
            "price": "18.00",
            "vat": "5.23",
            "discount": "0"
          },
          {
            "dateOfCharge": "22/12/2020 12:22",
            "details": "ארוחה",
            "enUs": "",
            "totalSum": "18",
            "quantity": "1.00",
            "netPrice": "18.00",
            "accountGroup": "700006",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "dateOfCharge": "22/12/2020 12:22",
                "details": "ארוחה",
                "enUs": "",
                "totalSum": "18",
                "quantity": "1.00",
                "netPrice": "18.00",
                "accountGroup": "700006",
                "accountNumber": "740501110",
                "budgetNumber": "740501110",
                "accountName": "ישראל ישראלי - הדגמה"
              }
            ],
            "price": "18.00",
            "vat": "2.62",
            "discount": "0"
          },
          {
            "dateOfCharge": "23/12/2020 12:23",
            "details": "ארוחה",
            "enUs": "",
            "totalSum": "18",
            "quantity": "1.00",
            "netPrice": "18.00",
            "accountGroup": "700006",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "dateOfCharge": "23/12/2020 12:23",
                "details": "ארוחה",
                "enUs": "",
                "totalSum": "18",
                "quantity": "1.00",
                "netPrice": "18.00",
                "accountGroup": "700006",
                "accountNumber": "740501110",
                "budgetNumber": "740501110",
                "accountName": "ישראל ישראלי - הדגמה"
              }
            ],
            "price": "18.00",
            "vat": "2.62",
            "discount": "0"
          },
          {
            "dateOfCharge": "26/12/2020 13:09",
            "details": "ארוחה",
            "enUs": "",
            "totalSum": "36",
            "quantity": "2.00",
            "netPrice": "18.00",
            "accountGroup": "700006",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "dateOfCharge": "26/12/2020 13:09",
                "details": "ארוחה",
                "enUs": "",
                "totalSum": "36",
                "quantity": "2.00",
                "netPrice": "18.00",
                "accountGroup": "700006",
                "accountNumber": "740501110",
                "budgetNumber": "740501110",
                "accountName": "ישראל ישראלי - הדגמה"
              }
            ],
            "price": "18.00",
            "vat": "5.23",
            "discount": "0"
          },
          {
            "dateOfCharge": "27/12/2020 12:24",
            "details": "ארוחה",
            "enUs": "",
            "totalSum": "18",
            "quantity": "1.00",
            "netPrice": "18.00",
            "accountGroup": "700006",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "dateOfCharge": "27/12/2020 12:24",
                "details": "ארוחה",
                "enUs": "",
                "totalSum": "18",
                "quantity": "1.00",
                "netPrice": "18.00",
                "accountGroup": "700006",
                "accountNumber": "740501110",
                "budgetNumber": "740501110",
                "accountName": "ישראל ישראלי - הדגמה"
              }
            ],
            "price": "18.00",
            "vat": "2.62",
            "discount": "0"
          },
          {
            "dateOfCharge": "28/12/2020 12:40",
            "details": "ארוחה",
            "enUs": "",
            "totalSum": "36",
            "quantity": "2.00",
            "netPrice": "18.00",
            "accountGroup": "700006",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "dateOfCharge": "28/12/2020 12:40",
                "details": "ארוחה",
                "enUs": "",
                "totalSum": "36",
                "quantity": "2.00",
                "netPrice": "18.00",
                "accountGroup": "700006",
                "accountNumber": "740501110",
                "budgetNumber": "740501110",
                "accountName": "ישראל ישראלי - הדגמה"
              }
            ],
            "price": "18.00",
            "vat": "5.23",
            "discount": "0"
          }
        ],
        "sumPerProduct": "18"
      },
      {
        "dateOfCharge": "31/12/2020",
        "details": "מס קהילה 12.20",
        "enUs": "",
        "totalSum": "680",
        "quantity": "2",
        "netPrice": "340.00",
        "docType": "902",
        "accountGroup": "830538",
        "accountNumber": "740501110",
        "budgetNumber": "740501110",
        "accountName": "ישראל ישראלי - הדגמה",
        "chargee": "102",
        "items": [],
        "price": "340.00",
        "vat": "98.8",
        "discount": "0"
      },
      {
        "dateOfCharge": "31/12/2020",
        "details": "מכבסה",
        "enUs": "Laundry",
        "totalSum": "429.51",
        "quantity": "45.15",
        "netPrice": "",
        "docType": "902",
        "accountGroup": "130537",
        "accountNumber": "740501110",
        "budgetNumber": "740501110",
        "accountName": "ישראל ישראלי - הדגמה",
        "chargee": "101",
        "items": [
          {
            "dateOfCharge": "05/12/2020 12:48",
            "details": "",
            "enUs": "",
            "totalSum": "77.61",
            "quantity": "8.3",
            "netPrice": "",
            "docType": "670",
            "accountGroup": "700003",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "details": "כביסה במשקל",
                "enUs": "",
                "totalSum": "77.61",
                "quantity": "8.30",
                "netPrice": "9.35",
                "price": "9.35",
                "vat": "11.28",
                "discount": "0"
              }
            ],
            "sumPerProduct": "9.35"
          },
          {
            "dateOfCharge": "10/12/2020 10:41",
            "details": "",
            "enUs": "",
            "totalSum": "60.33",
            "quantity": "6.65",
            "netPrice": "",
            "docType": "670",
            "accountGroup": "700003",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "details": "כביסה במשקל",
                "enUs": "",
                "totalSum": "52.83",
                "quantity": "5.65",
                "netPrice": "9.35",
                "price": "9.35",
                "vat": "7.68",
                "discount": "0"
              },
              {
                "details": "גיהוץ חולצה",
                "enUs": "",
                "totalSum": "7.5",
                "quantity": "1.00",
                "netPrice": "7.50",
                "price": "7.50",
                "vat": "1.09",
                "discount": "0"
              }
            ],
            "sumPerProduct": "9.07"
          },
          {
            "dateOfCharge": "18/12/2020 14:09",
            "details": "",
            "enUs": "",
            "totalSum": "74.72",
            "quantity": "6.5",
            "netPrice": "",
            "docType": "670",
            "accountGroup": "700003",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "details": "כביסה במשקל",
                "enUs": "",
                "totalSum": "42.08",
                "quantity": "4.50",
                "netPrice": "9.35",
                "price": "9.35",
                "vat": "6.11",
                "discount": "0"
              },
              {
                "details": "מעיל פליז",
                "enUs": "",
                "totalSum": "32.64",
                "quantity": "2.00",
                "netPrice": "16.32",
                "price": "16.32",
                "vat": "4.74",
                "discount": "0"
              }
            ],
            "sumPerProduct": "11.5"
          },
          {
            "dateOfCharge": "24/12/2020 13:09",
            "details": "",
            "enUs": "",
            "totalSum": "89.65",
            "quantity": "9.7",
            "netPrice": "",
            "docType": "670",
            "accountGroup": "700003",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "details": "כביסה במשקל",
                "enUs": "",
                "totalSum": "62.65",
                "quantity": "6.70",
                "netPrice": "9.35",
                "price": "9.35",
                "vat": "9.1",
                "discount": "0"
              },
              {
                "details": "גיהוץ חולצה",
                "enUs": "",
                "totalSum": "15",
                "quantity": "2.00",
                "netPrice": "7.50",
                "price": "7.50",
                "vat": "2.18",
                "discount": "0"
              },
              {
                "details": "גיהוץ מכנסיים",
                "enUs": "",
                "totalSum": "12",
                "quantity": "1.00",
                "netPrice": "12.00",
                "price": "12.00",
                "vat": "1.74",
                "discount": "0"
              }
            ],
            "sumPerProduct": "9.24"
          },
          {
            "dateOfCharge": "28/12/2020 13:46",
            "details": "",
            "enUs": "",
            "totalSum": "127.2",
            "quantity": "14",
            "netPrice": "",
            "docType": "670",
            "accountGroup": "700003",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "details": "כביסה במשקל",
                "enUs": "",
                "totalSum": "112.2",
                "quantity": "12.00",
                "netPrice": "9.35",
                "price": "9.35",
                "vat": "16.3",
                "discount": "0"
              },
              {
                "details": "גיהוץ חולצה",
                "enUs": "",
                "totalSum": "15",
                "quantity": "2.00",
                "netPrice": "7.50",
                "price": "7.50",
                "vat": "2.18",
                "discount": "0"
              }
            ],
            "sumPerProduct": "9.09"
          }
        ],
        "sumPerProduct": "9.51"
      },
      {
        "dateOfCharge": "31/12/2020",
        "details": "מרפאה",
        "enUs": "Medical clinic",
        "totalSum": "219.3",
        "quantity": "3",
        "netPrice": "",
        "docType": "902",
        "accountGroup": "130537",
        "accountNumber": "740501110",
        "budgetNumber": "740501110",
        "accountName": "ישראל ישראלי - הדגמה",
        "chargee": "101",
        "items": [
          {
            "dateOfCharge": "10/12/2020 09:53",
            "details": "",
            "enUs": "",
            "totalSum": "92",
            "quantity": "2",
            "netPrice": "",
            "docType": "670",
            "accountGroup": "700005",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "details": "שיננית",
                "enUs": "",
                "totalSum": "80",
                "quantity": "1.00",
                "netPrice": "80.00",
                "price": "80.00",
                "vat": "11.62",
                "discount": "0"
              },
              {
                "details": "מברשת שיניים רכה",
                "enUs": "",
                "totalSum": "12",
                "quantity": "1.00",
                "netPrice": "12.00",
                "price": "12.00",
                "vat": "1.74",
                "discount": "0"
              }
            ],
            "sumPerProduct": "46"
          },
          {
            "dateOfCharge": "18/12/2020 11:08",
            "details": "",
            "enUs": "",
            "totalSum": "127.3",
            "quantity": "1",
            "netPrice": "",
            "docType": "670",
            "accountGroup": "700005",
            "accountNumber": "740501110",
            "budgetNumber": "740501110",
            "accountName": "ישראל ישראלי - הדגמה",
            "items": [
              {
                "details": "מודולן",
                "enUs": "",
                "totalSum": "127.3",
                "quantity": "1.00",
                "netPrice": "127.30",
                "price": "127.30",
                "vat": "18.5",
                "discount": "0"
              }
            ],
            "sumPerProduct": "127.3"
          }
        ],
        "sumPerProduct": "73.1"
      },
      {
        "dateOfCharge": "31/12/2020",
        "details": "ארנונה 12.20",
        "enUs": "",
        "totalSum": "213.71",
        "quantity": "71",
        "netPrice": "3.01",
        "docType": "902",
        "accountGroup": "130537",
        "accountNumber": "740501110",
        "budgetNumber": "740501110",
        "accountName": "ישראל ישראלי - הדגמה",
        "chargee": "101",
        "items": [],
        "price": "3.01",
        "vat": "31.05",
        "discount": "0"
      },
      {
        "dateOfCharge": "31/12/2020",
        "details": "ביוב 12.20",
        "enUs": "",
        "totalSum": "91.2",
        "quantity": "19",
        "netPrice": "4.80",
        "docType": "902",
        "accountGroup": "130537",
        "accountNumber": "740501110",
        "budgetNumber": "740501110",
        "accountName": "ישראל ישראלי - הדגמה",
        "chargee": "101",
        "items": [],
        "price": "4.80",
        "vat": "13.25",
        "discount": "0"
      },
      {
        "dateOfCharge": "31/12/2020",
        "details": "מים 12.20",
        "enUs": "",
        "totalSum": "81.69",
        "quantity": "21",
        "netPrice": "3.89",
        "docType": "902",
        "accountGroup": "130537",
        "accountNumber": "740501110",
        "budgetNumber": "740501110",
        "accountName": "ישראל ישראלי - הדגמה",
        "chargee": "101",
        "items": [],
        "price": "3.89",
        "vat": "11.87",
        "discount": "0"
      },
      {
        "dateOfCharge": "31/12/2020",
        "details": "תקשורת קבוע 12.20",
        "enUs": "",
        "totalSum": "30",
        "quantity": "",
        "netPrice": "",
        "docType": "902",
        "accountGroup": "130537",
        "accountNumber": "740501110",
        "budgetNumber": "740501110",
        "accountName": "ישראל ישראלי - הדגמה",
        "chargee": "101",
        "items": []
      },
      {
        "dateOfCharge": "31/12/2020",
        "details": "ביטוח דירה 12.20",
        "enUs": "",
        "totalSum": "27",
        "quantity": "",
        "netPrice": "",
        "docType": "902",
        "accountGroup": "130537",
        "accountNumber": "740501110",
        "budgetNumber": "740501110",
        "accountName": "ישראל ישראלי - הדגמה",
        "chargee": "101",
        "items": []
      }
    ],
    "summary": [
      {
        "chargeeId": "101",
        "chargingSum": "-1688.45"
      },
      {
        "chargeeId": "102",
        "chargingSum": "3660"
      }
    ],
    "period": [
      2020,
      12
    ]
  }
}


export default mock