import React, { FC, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Menu as MuiMenu, MenuItem } from '@material-ui/core'
import { Menu, Apple, Android, VisibilityOff } from '@material-ui/icons'
import { observer } from 'mobx-react'
import useStore from 'store'
import { useTranslation } from 'react-i18next'
import { theme, Text, Space, Row } from 'ui'
import { formatIfPhoneNumber } from 'utils/converts'
import { AppType } from 'utils/models'
import WordsHighlighter from 'components/WordsHighlighter'
import { FixedSizeList } from 'react-window'
import AutoSizer from "react-virtualized-auto-sizer"
import { canSetUsersToCommunityDirectors, canSetUserToCommunityDirector, userIsDirectorOrSuperAdmin } from 'utils/roles'
import { ProviderEnum } from "../../utils/types";
import IdIcon from 'assets/png/id.png'

const StyledImage = styled.img`
  height: 30px;
  object-fit: contain;
`

const TableCell = styled.div`
    flex:1;
    align-items:center;
    justify-content:flex-start;
    display:flex;
    text-align:left;
`

const IndicatorIsraeliID = styled.div`
  width: 14px; 
  height: 14px; 
  border-radius: 50%; 
  background-color: green;
`

const TableHeader = styled.div`
    align-items:center;
    justify-content:center;
    display:flex;
    text-align:center;
`
const CellContainer = styled.div`
    display: flex;
    flex:1;
    flex-direction:row-reverse;
    align-items:center;
    justify-content: flex-start;
`
const HeaderItem = styled(Text)`
    font-size: ${theme.fontSize.md};;
    color: ${theme.color.darkGray};
    font-weight:500;
    flex:1;
    display:flex;
    align-items:center;
    justify-content:center;
    height:50px;
    background-color:${theme.color.white};
    border:1px solid ${theme.color.black + '1A'};
`
const GetRole = ({ member, searchText }: { member: any, searchText: string }) => {
    const { t } = useTranslation('membersTable')
    let role = ''
    if (member?.is_operator) role = t`mekome_manager`
    return <TableCell><Text
        textcolor={theme.color.black}
        weight={'normal'}
        size={'md'}
    >
        <WordsHighlighter
            searchText={searchText}
            text={`${role}`} />
    </Text></TableCell>

}
const SmallApple = styled(Apple)`
  font-size:1.2rem;
  color:${theme.color.black + 99};
`
const SmallAndroid = styled(Android)`
  font-size:1.2rem;
  color:${theme.color.black + 99};
`

const SmallVisibilityOff = styled(VisibilityOff)`
  font-size:1.2rem;
  color:${theme.color.black + 99};
`

const AppTypeComponent = ({ type }: { type: any }) => (
    <TableCell style={{ flex: 0.5 }}>
        <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
            {type == AppType.IOS && <SmallApple style={{ color: '#5eb0e5' }} />}
            {type == AppType.ANDROID && <SmallAndroid style={{ color: '#78C257' }} />}
        </div>
    </TableCell>
)

const ShowInAlphoneComponent = ({ showInAlphone = false }: { showInAlphone?: boolean }) => (
    <TableCell style={{ flex: 0.5 }}>
        <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
            {!showInAlphone && <SmallVisibilityOff />}
        </div>
    </TableCell>
)
const MediaContainer = styled.div`
  justify-content:space-around;
  align-items:center;
  justify-content:center;
  text-align:center;
  display: flex;
  flex-direction: column;
`

const MediaList = ({ list, type, searchText, isUserView }: { list: Array<string>, type?: string, searchText: string, isUserView: boolean }) => {
    if (!list) {
        return <TableCell></TableCell>
    }
    return (
        <TableCell style={{ flex: 1.5, justifyContent: (type === 'mail') ? 'flex-end' : 'flex-start' }}>
            <MediaContainer>
                {
                    list?.map((text: string) => (
                        <Row key={text}>
                            <a href={type === 'mail' ? `mailto:${text}` : `tel:${text}`} data-rel="external">
                                <Text
                                    textcolor={theme.color.blueText}
                                    style={{ marginBottom: 5, paddingRight: 10 }}
                                >
                                    <WordsHighlighter
                                        searchText={searchText}
                                        text={formatIfPhoneNumber(text) || ''} />
                                </Text>
                            </a>
                            {isUserView && <Space width={2} />}
                        </Row>
                    )
                    )
                }

            </MediaContainer>
        </TableCell>
    )
}

interface Props {
    userView?: boolean
    list: Array<any>
    searchText: string
}
const extraStyle = {
    borderBottomColor: theme.color.black + '1A',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    marginRight: 15
}

const AlphoneList: FC<Props> = ({ list, userView = false, searchText }) => {
    const { t } = useTranslation('membersTable')
    const { members, uiStore, memberStore, orgs, modulesStore } = useStore()

    const currentlist = list.filter((member) => !userView || member.is_showInAlphon == true)

    const isDeleteCache = useMemo(() => {
        const communityId = orgs.currentOrgId
        const result = modulesStore.getBudgets().find(el => el.community_ref === communityId && el.provider === ProviderEnum.MAOF)
        return result;
    }, [orgs.currentOrgId])

    const MemberView = observer(({ data, index, style }: { data: any, index: number, style: any }) => {
        const { t } = useTranslation('membersTable')
        const member = currentlist[index]
        const { israeliID, nick_name, family_name, given_name, phones, emails, user_id, app, is_showInAlphon } = member
        const [showActionArrow, setShowActionArrow] = useState(false)
        const onEnter = () => setShowActionArrow(true)
        const onLeave = () => setShowActionArrow(false)
        const fullName = `${family_name} ${nick_name ? ' (' + nick_name + ') ' : ''} ${given_name}`
        const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

        const openActionMenu = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget)
        }

        const handleClose = () => {
            setAnchorEl(null)
        }
        const changeManager = () => {
            members.changeManagerState(user_id, member?.is_operator ? true : false)
            setAnchorEl(null)
        }
        const removeFromCommunity = () => {
            uiStore.openWarningModal({
                text: `${t`remove:removeCommunity`}`,
                secondText: fullName,
                okAction: () => members.removeFromCommunity(user_id)
            })
            setAnchorEl(null)
        }

        const resetData = () => {
            uiStore.openWarningModal({
                text: `${t`remove:resetUser`}`,
                secondText: fullName,
                okAction: () => members.removeCache(user_id)
            })
            setAnchorEl(null)
        }

        return (
            <div
                style={{ ...style, ...extraStyle }}
                key={user_id}
                onMouseEnter={onEnter}
                onMouseLeave={onLeave}
            >
                <CellContainer style={{ flexDirection: 'row', display: 'flex' }}>
                    <TableCell>
                        <Space width={1}/>
                        <WordsHighlighter
                            searchText={searchText}
                            text={`${family_name}`} />
                    </TableCell>
                    <TableCell >
                        <WordsHighlighter
                            searchText={searchText}
                            text={`${given_name}`} />
                    </TableCell>
                    <TableCell >
                        <WordsHighlighter
                            searchText={searchText}
                            text={`${nick_name != null ? nick_name : ''}`}
                        />
                    </TableCell>
                    {userIsDirectorOrSuperAdmin() && <TableCell>
                        <Space width={6}/>
                        {israeliID && <StyledImage src={IdIcon} />}
                    </TableCell>}
                    <MediaList searchText={searchText} list={phones} isUserView={false} />
                    <MediaList searchText={searchText} type='mail' list={emails} isUserView={userView} />
                    {!userView && <ShowInAlphoneComponent showInAlphone={is_showInAlphon} />}
                    {!userView && <GetRole searchText={searchText} member={member} />}
                    {!userView && <AppTypeComponent type={app} />}
                    {!userView && <>
                        <TableCell
                            style={{ cursor: 'pointer', flex: 0.5, justifyContent: 'center' }}
                            onClick={openActionMenu}
                        >
                            <Menu
                                style={{ opacity: showActionArrow ? 1 : 0 }}
                                color={'primary'} />
                        </TableCell>
                    </>}
                </CellContainer>
                {showActionArrow && anchorEl && <MuiMenu
                    id="lock-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem
                        onClick={() => {
                            memberStore.editUser(user_id)
                            handleClose()
                        }}>
                        {t`editUserDetails`}
                    </MenuItem>
                    {canSetUsersToCommunityDirectors() && (
                        <MenuItem onClick={changeManager}>
                            {member?.is_operator ? t`removeCommunityOrganiser` : t`makeCommunityOrgnaiser`}
                        </MenuItem>
                    )}
                    { !!isDeleteCache && (
                        <MenuItem
                            style={{ color: theme.color.red }}
                            onClick={resetData}
                        >
                            {t`recycle`}
                        </MenuItem>
                    )}
                    <MenuItem
                        style={{ color: theme.color.red }}
                        onClick={removeFromCommunity}
                    >
                        {t`removeFromCommunity`}
                    </MenuItem>
                </MuiMenu>}
            </div >
        )
    })
    const [listHeight, setHeight] = useState<number>(0)
    const [listWidth, setWidth] = useState<number>(0)

    const handleResize = (width: number, height: number) => {
        if (height && width) {
            setHeight(height)
            setWidth(width)
        }
    }

    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <TableHeader style={{ width: listWidth }}>
                <HeaderItem>{t`lastName`}</HeaderItem>
                <HeaderItem>{t`givenName`}</HeaderItem>
                <HeaderItem>{t`nickName`}</HeaderItem>
                {userIsDirectorOrSuperAdmin() && <HeaderItem>{t`israeliID`}</HeaderItem>}
                <HeaderItem style={{ flex: 1.5 }}>{t`phone`}</HeaderItem>
                <HeaderItem style={{ flex: 1.5 }}>{t`email`}</HeaderItem>
                {!userView && <HeaderItem style={{ flex: 0.5 }}>{t`showInAlphone`}</HeaderItem>}
                {!userView && <HeaderItem>{t`role`}</HeaderItem>}
                {!userView && <HeaderItem style={{ flex: 0.5 }}>{t`appType`}</HeaderItem>}
                {!userView && <HeaderItem style={{ flex: 0.5, paddingLeft:'1rem' }}>{''}</HeaderItem>}
            </TableHeader >
            <AutoSizer
                onResize={(style) => handleResize(style.width, style.height)}
            >
                {({ height, width }: { height: number, width: number }) => (
                    <FixedSizeList
                        style={{ direction: 'rtl', flex: 1, display: 'flex', paddingBottom: '2rem', overflow:'scroll'}}
                        height={listHeight - 30}
                        itemCount={currentlist?.length}
                        itemSize={60}
                        width={listWidth}
                    >
                        {MemberView}
                    </FixedSizeList>
                )}
            </AutoSizer>
        </div>

    )
}

export default observer(AlphoneList)
