import React,{FC} from 'react'
import { Dialog, Button } from '@material-ui/core'
import styled from 'styled-components'
import { theme, Text } from 'ui'
import { Add } from '@material-ui/icons'
import PulseLoader from "react-spinners/PulseLoader";


const InfoText = styled(Text)`
  font-size:18px;
  font-weight:500;
  text-align:center;
`
const Container = styled.div`
    display:flex;
    border-radius: 50px;
    background: #FFF;
    width:400px;
    justify-content:space-around;
    align-items:center;
    flex-direction:column;
    padding:10px;
    word-break: break-word;
    padding-bottom: 20px;
`

const EmergencyContainer = styled.div`
  background: #FFF;
  padding: 30px;
  margin-top: 20px;
  width:280px;
  display: flex;
  border-bottom: 1px solid #CBCBCB;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  word-break: break-word;
`

const ConfirmButton = styled(Button) <{ bgColor: string, hoverColor?: string }>`
  &.MuiButtonBase-root {
    font-size: ${theme.fontSize.large};
    font-weight: 500;
    width: 280px;
    border-radius: 25px; 
    height: 50px;
    margin-top: 30px;
    margin-bottom: 20px;
    background:${p => p.bgColor};
    &:hover {
      background: ${p => p.hoverColor ? p.hoverColor : theme.color.darkBlue};
    }
    color: ${theme.color.white};
    &.Mui-disabled {
      opacity: 0.5;
    }
  }
`

const EmergencyDialog: FC<any> = ({ isOpen, okAction, cancelAction, singleText, loading }) => {
    return (
        <Dialog open={isOpen} onClose={cancelAction}>
            <Container>
                <EmergencyContainer>
                    <InfoText>{singleText}</InfoText>
                    <ConfirmButton
                       //endIcon={}
                       hoverColor={theme.color.dangerRed}
                       bgColor={theme.color.red}
                       onClick={okAction}
                       disabled={loading}
                    >  
                      {!loading && (
                        <>
                        <span style={{marginTop: '3px'}}>{'כן, אני רוצה'}</span>
                        <Add style={{ fontWeight: "500",  fontSize: "${theme.fontSize.large}" }} />
                        </>
                      )}

                      {loading && (
                        <PulseLoader size={10} color={"white"} loading={true} margin='3px 0px 0px 0px' />
                      )}  
                      
                    </ConfirmButton>
                </EmergencyContainer>
                <ConfirmButton
                       hoverColor={'#67a8c2'}
                       bgColor={'#2A5BA8'}
                       onClick={cancelAction}
                    >  
                        <span style={{marginTop: '3px'}}>{'ביטול'}</span>
                    </ConfirmButton>
            </Container>
        </Dialog>
    )
}

export default EmergencyDialog