import React, { FunctionComponent, useMemo } from 'react'
// @ts-ignore
import groupIcons from './groupIcons.js'
import mekome from './mekome.js'
import { isTotzeret } from '../../service/whiteLable'

export type GroupIconNames = keyof typeof groupIcons

interface IProps {
  name: GroupIconNames
  color?: string
  size?: number
  namespace?: 'groupIcons' | 'material' | 'mekome'
}

const defaults = {
  color: '#acbfff',
  size: 36,
  namespace: 'groupIcons'
}

const dict = {
  groupIcons: groupIcons,
  mekome: mekome
}

const Icon: FunctionComponent<IProps> = (
  {
    name,
    size = defaults.size,
    namespace = defaults.namespace,
    color = defaults.color,
    ...props
  }
) => {
  const specialSize = isTotzeret && namespace === 'groupIcons' && name === 'default' ? 50 : isTotzeret ? size + 25 : size
  return <img width={specialSize} height={specialSize} color={color} src={dict[namespace]?.[name]} {...props} />
}

export default Icon
