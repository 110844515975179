
import groupsStore from 'store/groups.store'
import orgsStore from 'store/orgs.store'
import messageStore from 'store/messages.store'
import userStore from 'store/user.store'
import { Message, Role, User, UserMessage } from 'utils/models'
import { GroupType } from './types'

const isOrganizer = (organizer: number | undefined) => Boolean((organizer || 0) % 2)
const isServiceCall = (duplex: number | undefined) => Boolean(duplex == 1 || duplex == 2)
const isDirector = (director: number | undefined) => Boolean((director || 0) % 2)

const MEKOME_ADMIN_GIVEN_NAME = 'מערכת'
const MEKOME_ADMIN_LAST_NAME = 'מקומי'
const MEKOME_ADMIN_ID = 1

export function canEditCommunityModules() {
    return userIsSuperAdmin()
}

//9
export function canUploadCsvFileCommunityUsers() {
    return userIsSuperAdmin()
}

//10
export function canCreateCommunity() {
    return userIsSuperAdmin()
}

export function userCanSeeAssociatedGroups() {
    return userIsSuperAdmin()
}

export function isGroupAdminOrOrgAdmin() {
    if (groupsStore.currentGroup?.is_organizer || orgsStore.currentOrg?.is_director || orgsStore.currentOrg?.is_operator) {
        return true
    }
    return false
}

export function canEditCommunityRoles() {
    return userIsSuperAdmin()
}

export function canAddGroup() {
    // return userIsDirectorOrOperator()  //This logic should be when fix server...
    return userIsDirector()
}

export function canAddGroupToCommunity(communityId: string) {
    return orgsStore.orgs[communityId]?.is_director && orgsStore.orgs[communityId]?.is_operator || userIsSuperAdmin()
}

//exists to to allow director to use service calls (MM-1614)
export function isSupportGroupOrganizer() {
    return (groupsStore.currentGroup?.is_organizer || userIsDirector())

}
export function canSeeOrgUsersAppType() {
    return userIsDirectorOrOperator()
}

export function canSeeGroupUsersAppType() {
    if (groupsStore.currentGroup?.is_organizer || orgsStore.currentOrg?.is_director || orgsStore.currentOrg?.is_operator) {
        return true
    }
}

export function userIsSuperAdmin() {
    return userStore.user?.role === Role.ADMIN
}
export function userIsOrganizer() {
    if ((groupsStore.currentGroup?.is_organizer)) {
        return true
    }
    return false
}
export function userIsDirector() {
    if (orgsStore.currentOrg?.is_director) {
        return true
    }
    return false
}

export function userIsDirectorOrOperator() {
    if (orgsStore.currentOrg?.is_director || orgsStore.currentOrg?.is_operator) {
        return true
    }
    return false
}
export function userIsDirectorOrSuperAdmin() {
    if (userIsDirectorOrOperator() || userIsSuperAdmin()) {
        return true
    }
    return false
}
export function userIsSender() {
    if (groupsStore.currentGroup?.is_sender) {
        return true
    }
    return false
}


export function canEditGroupRoles() {
    return userIsDirectorOrOperator() || userIsOrganizer()
}

export function canShowMapTab() {
    return (userIsDirectorOrOperator() && orgsStore.settings.enabled) || userIsSuperAdmin()
}

export function canEditUsersGroup() {
    return userIsDirectorOrOperator() || userIsOrganizer()
}

export function canSeeAuthoerDetails() {
    return true
}
//deprecated
export function isGroupOrganizer() {
    if (orgsStore.currentOrg?.is_director) {
        return true
    } else if ((groupsStore.currentGroup?.group.orgainzers || 0) >= 50) {
        return false
    } else if (groupsStore.currentGroup?.is_organizer) {
        return true
    }
    return false
}

//7
export function canAddUserToCommunity() {
    return userIsDirectorOrSuperAdmin()
}
//8
export function canSetUsersToCommunityDirectors() {
    return userIsSuperAdmin()
}
//8
export function canSetUserToCommunityDirector(member: User) {
    return userStore.user.user_id === 1 && member?.is_operator
}
//11
export function canEditCommunityUsers() {
    return userIsDirectorOrSuperAdmin()
}
//12
export function canHideSelfFromAlphon() {
    return true
}
//13
export function canShowAndHideUserFromAlphon() {
    return userIsDirectorOrSuperAdmin()
}
//14
export function canRemoveSelfFromCommunity() {
    return true
}
//15
export function canRemoveUserFromCommunity() {
    return userIsDirectorOrSuperAdmin()
}
//16
export function canRemoveSelfFromMekome() {
    return true
}
//17
export function canRemoveUserFromMekome() {
    return userIsSuperAdmin()
}
//18
export function canCreateGroup() {
    return userIsDirectorOrOperator()
}
//19,48
export function canEditGroup() {
    return userIsDirectorOrSuperAdmin() || (userIsOrganizer())
}
//20
export function canDeleteGroup() {
    return userIsDirectorOrSuperAdmin()
}
//21
export function canSeeOpenGroup() {
    return true
}
//22
export function canSeeAllOpenGroups() {
    return userIsDirectorOrSuperAdmin()
}
//23
export function canSeeSupportGroups() {
    return userIsDirectorOrSuperAdmin() || (userIsOrganizer())
}
//24
export function canAddUserToGroup() {
    return userIsDirectorOrSuperAdmin() || (userIsOrganizer())
}
//25
export function canDemoteOrganizerToSender() {
    return userIsSuperAdmin()
}
//26
export function canSetGroupUserToOrganizer() {
    return userIsDirectorOrSuperAdmin() || (userIsOrganizer())
}
//27
export function canSendGroupMessage() {
    return userIsDirectorOrSuperAdmin() || (userIsOrganizer()) || userIsSender()
}
//28
export function canSendScheduledGroupMessageInClosedGroup() {
    return userIsDirectorOrSuperAdmin() || (userIsOrganizer()) || userIsSender()
}
//29
export function canSendScheduledGroupMessageInOpenGroup() {
    return userIsDirectorOrSuperAdmin() || (userIsOrganizer())
}
export function canSendAndSeeScheduledGroupMessages() {
    // return groupsStore.currentGroup?.group.group_is_member ? canSendScheduledGroupMessageInOpenGroup() : canSendScheduledGroupMessageInClosedGroup()
    return canSendScheduledGroupMessageInOpenGroup()
}
//30
export function canDeleteOwnScheduledMessage() {
    return userIsDirectorOrSuperAdmin() || (userIsOrganizer()) || userIsSender()
}
//31
export function canDeleteOtherScheduledMessages() {
    return userIsDirectorOrSuperAdmin() || (userIsOrganizer())
}
//32-33 
export function canEditMessage(userMessage: UserMessage) {
    if (userMessage != null) {
        const senderId = userMessage.message.senderId
        if ((senderId === userStore.user?.user_id) || userIsDirectorOrSuperAdmin() || (userIsOrganizer())) {
            return true
        }
    }
    return false
}
//34-35
export function canDeleteMessage(userMessage: UserMessage) {
    return canEditMessage(userMessage)
}
export function canWriteMessage() {
    return canSendGroupMessage()
}
//36-37 && 38-39
export function canSeeMessageDetails(userMessage: UserMessage) {
    return canEditMessage(userMessage)
}
export function canSeeAdditionalMessageDetails() {
    return userIsDirectorOrSuperAdmin() || (userIsOrganizer())
}
//40
export function canExportGroupUsers() {
    return userIsDirectorOrSuperAdmin()
}
//41
export function canExportCommunityUsers() {
    return userIsDirectorOrSuperAdmin()
}
//42-43
export function canExportSurveyResults() {
    return userIsDirectorOrSuperAdmin() || (userIsOrganizer())
}
//44
export function canEditCommunityDetails() {
    return userIsSuperAdmin()
}
//45-46
export function canUseEula() {
    return true
}

//47
export function canSeeEulaSignatures() {
    return userIsSuperAdmin()
}
