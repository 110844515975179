import React, { FC } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'

import { TextField, Icon } from '@material-ui/core'
import { Close  } from '@material-ui/icons'
import magnifiyingGlassDark from 'assets/icons/MagnifyingGlassDark.svg'
import magnifiyingGlass from 'assets/icons/MagnifyingGlass.svg'
import { useTranslation } from 'react-i18next'
import { theme, Image } from 'ui'

interface Props {
  onChange: (arg0: string) => void
  backgroundColor?: string
  color?: "gray" | "black"
  svgIcon?: string
  searchText: string | undefined
}
const StyledClose = styled(Close)`
  body[dir='rtl'] & {
    color:  ${theme.color.black + 99}
  }
  cursor:pointer;
`
const SearchInput = styled(TextField)<{background?:string,textcolor?:string}>`
    width: 100%;
    max-width: 100%;
  .MuiOutlinedInput-root {
    font-size: ${theme.fontSize.normal};
    border-radius: 100px;
    transition: all .2s ease-in-out;
    background: ${p => p.background ? p.background : theme.color.searchGray};
    color: ${p => p.textcolor ? p.textcolor : 'unset'};
    /* &.Mui-focused {
      width: 500px;
    } */
  }
  .MuiInputLabel-formControl {
    body[dir=rtl] & {
      right: 0;
      transform: translate(-14px, 14px) scale(1);
    }
  }
  .MuiOutlinedInput-notchedOutline{
      border: 0px;
    }
`
const GlassIcon = styled(Icon)`
  display: flex;
`
const CloseIcon = styled(Icon)`
  display: flex;
`
const selectColor = (color: string) => {
  switch (color) {
    case "gray":
      return undefined
    case "black":
      return '#cccccc'
    default:
      return undefined
  }
}
const Search: FC<Props> = ({ onChange, backgroundColor, svgIcon = magnifiyingGlass, color = "gray", searchText, ...props }) => {
  const { t } = useTranslation('app')
  const textColor = selectColor(color)
  const click = () => {
    onChange('')
  }
  return (
    <SearchInput
      role='search'
      size='small'
      value={searchText}
      placeholder={t`members:contactSearch`}
      background={backgroundColor}
      textcolor={textColor}
      variant="outlined"
      InputProps={{
        startAdornment: <GlassIcon><Image src={svgIcon} /></GlassIcon>,
        endAdornment: searchText ? <StyledClose onClick={click} /> : <div/>
      }}
      onChange={e => onChange(e.currentTarget.value)}
      {...props}
    />
  )
}

export default observer(Search)
