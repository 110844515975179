import React, { FC, useState, useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { ListItem, ListItemIcon } from '@material-ui/core'
import useStore from 'store'
import { GroupType, UserGroup } from 'utils/types'
import { Icon, Text, theme, Row, Space } from 'ui'
import { useTranslation } from 'react-i18next'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { Collapse } from '@material-ui/core';
import NotificationCounter from 'core/NotificationCounter'
import { isTotzeret } from 'service/whiteLable'



const Item = styled(ListItem) <{ selected: boolean, color: string }>`
    margin: 5px 0;
    border-radius: 10px;
    background-color: ${theme.color.white};
    box-shadow:${p => p.selected ? '0px 1px 1px 1px ' + p.color : 'none'};

    display:flex;
    flex-direction:row;
    justify-content:space-between;
    border-bottom: 1px solid ${theme.color.sidebarHover};

 &.MuiListItem-root.Mui-selected{
  background-color: transparent;
  border: 1px solid ${theme.color.black + 33};

 }
  &:hover{
    background-color: ${theme.color.sidebarHover};
  }
`
const UserRow = styled(Row) <{ selected: boolean }>`
    box-shadow:${p => p.selected ? '0px 1px 1px 1px ' + p.color : 'none'};
    border-radius:12px;
    margin:5px 0;
    /* background-color: ${p => p.selected ? p.color + '1A' : 'white'}; */
  &:hover{
    background-color: ${theme.color.sidebarHover};
    border-radius:12px;
  }
  cursor:pointer;
  padding:5px 7px;
`
const IconBg = styled.div`
  height:60px;
  width:45px;
  background: ${p => isTotzeret ? 'transparent' : p.color};
  display: flex;
  align-items: ${isTotzeret ? 'center;' : 'flex-end;'}
  justify-content: center;
  padding:0.33rem 0;
  ${p => isTotzeret ? `border-left: 2px solid ${p.color}; border-radius: 0px;` : 'border-radius: 8px;'}
`

const ImgArrow = styled.div<{ color: string }>`
  border-style: solid;
  border-top-width: 7px;
  border-top-color: transparent;
  border-right-width: 8px;
  border-right-color: ${(p) => p.color};
  border-bottom-width: 7px;
  border-bottom-color: transparent;
  border-left-width: 0;
  border-left-color: transparent;
`

const CallsContainer = styled.div<{ open: boolean }>`
 height:${p => p.open ? '100%' : 0};
  transition:height  0.3s ease-out;
  opacity:${p => p.open ? 1 : 0};
  margin:7px;
  /* overflow:hidden;
transition:transform 0.3s ease-out;
 height:auto;
transform:${p => p.open ? 'scaleY(1)' : 'scaleY(0)'};
transform-origin:top; */
`
const GroupHeaderContainer = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  flex:1;
  justify-content:space-between;
`

const StyledOverflowDiv = styled.div`
  overflow:hidden;
`
interface IChildList {
  call: UserGroup,
  description: any
  onClickUser: (id: number) => void,
}
const ChildList: React.FC<IChildList> = observer(({ call, description, onClickUser }) => {
  const { groups, members } = useStore()
  const lastMessage = groups.lastMessages.get(call.group.id)
  return (
    <UserRow
      key={call.group.id}
      color={description?.iconColor || '#d9d9d9'}
      onClick={() => onClickUser(call.group.id)}
      selected={groups.currentGroupId === call.group.id}
    >
      <Text
        textcolor={theme.color.black + 66}
        weight={'md'}
        size={'xxl'}
      >{call.group.description.UserFullName}</Text>
      <Row>
        {Boolean(lastMessage?.unread) && <NotificationCounter number={lastMessage?.unread} />}
        <Space />
        {/* <ChevronLeft /> */}
      </Row>
    </UserRow>

  )

})

const ServiceCallGroup: FC<any> = ({ id, name, description, parent_id }) => {
  const { groups } = useStore()
  const { t } = useTranslation('sidebar')
  const [open, setOpen] = useState(false)
  const [usersList, setUsersList] = useState<Array<any>>([])
  const [disabled, setdisabled] = useState(false)
  const unread = usersList.reduce((a: number, b: UserGroup) => {
    return a + (groups.lastMessages.get(b.group.id)?.unread || 0)
  }, 0)
  const onClick = () => {
    setOpen(s => !s)
  }
  const onClickUser = (id: number) => {
    groups.setCurrentGroupType(GroupType.SUPPORT)
    groups.setCurrentGroupId(id)
  }
  useEffect(() => {

    const res = groups.currentOpenSupportGroups.filter(g => g.group.parent_id === parent_id)
    setUsersList([...res])
  }, [parent_id])

  useEffect(() => {
    const group = usersList.find(call => call.group.id === groups.currentGroupId)
    if (group) {
      setdisabled(true)
    } else {
      setdisabled(false)
    }
  }, [groups.currentGroupId, usersList])

  const emptyGroup = usersList.length === 0

  const emptyGroupHandler = () => {
    groups.setCurrentGroupType(GroupType.SUPPORT)
    groups.setCurrentGroupId(id)
  }

  return (
    <div>
      <Item
        selected={(emptyGroup && groups.currentGroupId === id) || (disabled && !open)}
        color={description?.iconColor + 66 || '#d9d9d9'}
        button
        onClick={emptyGroup ? emptyGroupHandler : onClick}
      >
        <Row onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => { 
          e.stopPropagation(); 
          groups.setCurrentGroupId(id);
          groups.setCurrentGroupType(GroupType.SUPPORT);
        }}>
          <ListItemIcon style={{ alignItems: 'center' }}>
            <IconBg
              color={description?.iconColor || '#d9d9d9'}
            >
              <Icon
                size={30}
                name={description?.icon || 'default'}
              />
            </IconBg>
            {isTotzeret && <ImgArrow color={description?.iconColor || '#d9d9d9'}/>}
          </ ListItemIcon>
          <Text
            textcolor={theme.color.black}
            weight={'medium'}
            size={'lg'}
          >{name}</Text>
        </Row>
        <Row>
          {Boolean(unread) && <NotificationCounter number={unread} />}
          <Space />
          {!emptyGroup && (open ? <KeyboardArrowUp /> : <KeyboardArrowDown />)}
        </Row>
      </Item>
      <Collapse in={open} >
        <div style={{ margin: 10 }}>
          {usersList.map((call: UserGroup) => {
            return (
              <React.Fragment key={call.group.id}>
                <ChildList call={call} description={description} onClickUser={onClickUser} />
              </React.Fragment>
            )
          })}
        </div>
      </Collapse>
    </div>
  )
}

export default observer(ServiceCallGroup)
