import React, { FC } from 'react'
import { Row, Space, Text, theme } from 'ui'
import useStore from 'store'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Card } from '@material-ui/core'
import Money from './Money'
import { SortTypes, BudgetTransaction, PaymentType, OuterItem } from 'store/budget.store'
import EmptyList from './EmptyList'
import Calc from 'assets/calc.svg'
import PaymentItem from './PaymentItem'
import { Transaction } from './PaymentTransactions'
import { sortByDate, dateFormat } from '../../../utils/date'
import { ChevronLeft } from '@material-ui/icons'
import { Path } from 'core/Routes'
import { useHistory } from 'react-router'

const StyledFlex = styled.div`
  display:flex;
  flex-direction:column;
  flex:1;
`
const StyledBody = styled.div<{ paddingTop?: number, isDisabled: boolean }>`
  display:flex;
  flex-direction:column;
  background-color:${p => p.isDisabled ? theme.color.back : theme.color.backgroundGray};
  flex:1;
  padding-top:${p => p.paddingTop || 0}px;
  overflow:auto;
`
const StyledSummaryItem = styled(Card) <{ disabled: boolean }>`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  cursor:${p => p.disabled ? 'auto' : 'pointer'};
  padding:12px;
  margin:8px;
`
const SummaryItemSummaryContainer = styled.div`
  display:flex;
  flex-direction:column;
  flex:1;
  justify-content:space-between;
`
const SummaryItemSummary = styled.div`
  display:flex;
  flex-direction:column;
`

const StyledRow = styled(Row)`
  margin: 5%;
`

const Title = styled.div`
  font-size: 1.3rem;
  font-weight: bold;
`

const StyledColumn = styled.div`
    display: flex; 
    flex-direction: column;
`

const StoreItem: React.FC<{ item: any }> = ({ item }) => {
  const { t } = useTranslation('budget')
  const h = useHistory()
  const { budgetStore } = useStore()
  const { title, sum, transactions, chargeeId } = item
  if (!sum && sum != 0) {
    return null
  }
  const handlePress = () => {
    h.push(Path.USER_BUDGET + '/' + chargeeId)
  }
  return (
    <StyledSummaryItem disabled={!transactions.length} onClick={handlePress}>
      <StyledColumn>
        <Text weight='bold' size='xl'>{title}</Text>
        <Space height={1} />
        <SummaryItemSummary>
          <Money amount={budgetStore.agorotToShekel(Number(sum) * -1)}
            moneyColor={Number(sum) > 0 ? theme.color.budgetRed : theme.color.budgetDarkGreen}
            agorotColor={Number(sum) > 0 ? theme.color.budgetRed : theme.color.budgetDarkGreen}
          />

        </SummaryItemSummary>
      </StyledColumn>
      <StyledColumn style={{ justifyContent: 'flex-end' }}>
        {!!transactions.length &&
          <ChevronLeft style={{ color: theme.color.darkerGray, fontSize: 8 * 5 }} />
        }
        <Text textcolor={theme.color.grey} size='xl'>{sum > 0 ? t`summery.charge` : t`summery.credit`}</Text>
      </StyledColumn>
    </StyledSummaryItem>
  )
}

const EmptyBudgetCurrenPeriod = () => {
  const { t } = useTranslation('budget')
  return <>
    <StyledRow justify='center' align='center'>
      <img src={Calc} />
    </StyledRow>
    <StyledRow justify='center' align='center'>
      <Title>{t('emptyBudgetCurrentPeriod.title')}</Title>
    </StyledRow>
    <Row justify='center' align='center'>
      <div>{t('emptyBudgetCurrentPeriod.content')}</div>
    </Row>
  </>
}

export const BudgetSumCommunity: React.FC<{ item: BudgetTransaction }> = ({ item }) => {
  const { budgetStore } = useStore()
  const { t } = useTranslation('budget')
  return (
    (Number(item.quantity) && Number(item.sumPerProduct)) ? (
      <Row justify='start'>
        <Text textcolor={theme.color.grey} align='right' size='lg'>
          {item.quantity} {t`summery.units`}&nbsp;×&nbsp;
        </Text>
        <Text textcolor={theme.color.grey} align='right' size='lg' style={{ direction: 'ltr' }}>
          {budgetStore.currencyType} {budgetStore.agorotToShekel(Number(item.sumPerProduct))}
        </Text>
      </Row>
    ) : null
  )
}

export const BudgetDebitName: React.FC<{ item: BudgetTransaction }> = ({ item }) => {
  const { budgetStore } = useStore()
  return (
    <Text textcolor={theme.color.grey} align='right' weight='bold'>
      {budgetStore.getChargeeById(item.chargee)?.name['he']}
    </Text>
  )
}

const BudgetTab: FC = () => {
  const { t } = useTranslation('budget')
  const { budgetStore } = useStore()
  const transactions = budgetStore.transactions(SortTypes.DATE)

  const getStoreList = () => {
    return budgetStore.entityChargers.map(chargee => {
      const sum = budgetStore.summary?.find(el => el.chargeeId === chargee.id)?.chargingSum || 0
      const title = chargee.name['he'];
      const chargerTransactions = transactions.filter(el => (el.chargee === chargee.id))
      const credit = chargerTransactions.filter(el => Number(el.totalSum) <= 0).reduce((a, b) => a + Number(b.totalSum), 0) * -1
      const debit = chargerTransactions.filter(el => Number(el.totalSum) > 0).reduce((a, b) => a + Number(b.totalSum), 0) * -1
      const creditDate = chargerTransactions.find(el => Number(el.totalSum) < 0)
      const debitDate = chargerTransactions.find(el => Number(el.totalSum) > 0)
      return {
        title,
        credit,
        debit,
        creditDate: creditDate?.dateOfCharge,
        debitDate: debitDate?.dateOfCharge,
        sum: sum,
        transactions: chargerTransactions,
        chargeeId: chargee.id
      }
    })
  }

  const getDateList = () => {
    let dateList: OuterItem[] = []
    transactions.forEach(t => {
      t.items?.forEach(i => {
        i.name = budgetStore.getBudgetName(t, i)
        i.description = budgetStore.getBudgetDescription(t, i)
        i.dateOfCharge = i.dateOfCharge || dateFormat(t.dateOfCharge, 'DD/MM/yyyy HH:mm')
      })
      dateList = [...dateList, ...(t.items || [])]
      if (!t.items?.length) {
        dateList.push({
          ...t as OuterItem,
          dateOfCharge: dateFormat(t.dateOfCharge, 'DD/MM/yyyy HH:mm'),
          name: t.details,
          description: budgetStore.getChargeeById(t.chargee)?.name['he']
        })
      }
    })
    dateList = sortByDate('dd/MM/yyyy HH:mm', dateList, 'dateOfCharge')
    return dateList
  }

  const isExistBudget = budgetStore._transactions && budgetStore._transactions.length
  if (isExistBudget) {
    return <StyledBody isDisabled={false} paddingTop={0}>
      {(() => {
        switch (budgetStore.sortType) {
          case SortTypes.STORE:
            return (
              <StyledFlex>
                {getStoreList().map(item => <StoreItem item={item} key={item.title} />)}
              </StyledFlex>
            )
          case SortTypes.DATE:
            return (
              <StyledFlex>
                {getDateList().map(item => <Transaction transaction={item} name={item.name || ''} description={item.description || ''} shouldCollapseDefault={true} />)}
              </StyledFlex>
            )
          case SortTypes.SUM:
            return (
              <StyledFlex>
                {budgetStore.transactions().map((item, index) => (
                  <PaymentItem
                    item={item}
                    key={index.toString()}
                    index={index}
                    total={Number(item.totalSum) * -1}
                    transactionsLength={item.items?.length}
                    title={item.details}
                    date={item.dateOfCharge}
                  />
                ))}
              </StyledFlex>
            )
        }
      })()}
    </StyledBody>
  }
  if (budgetStore.isCurrentPeriodSelected()) {
    return <EmptyBudgetCurrenPeriod />
  }
  return <EmptyList
    title={t`summery.budget_unavailable_title`}
    content={t`summery.budget_unavailable_content1`}
    moreContent={t`summery.budget_unavailable_content2`}
  />

}

export default observer(BudgetTab)