import { FC } from 'react'
import { theme } from 'ui'
import useStore from 'store'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { PosData } from 'store/budget.store'
import PaymentItem from './PaymentItem'
import { useTranslation } from 'react-i18next'
import EmptyList from './EmptyList'
import { SortTypes, PosTransaction } from 'store/budget.store'
import { sortByDate } from '../../../utils/date'
import { Transaction } from './PaymentTransactions'

const StyledFlex = styled.div`
  display:flex;
  flex-direction:column;
  flex:1;
`
const StyledBody = styled.div<{ paddingTop?: number }>`
  display:flex;
  flex-direction:column;
  background-color:${theme.color.backgroundGray};
  flex:1;
  padding-top:${p => p.paddingTop || 0}px;
  overflow:auto;
`

const PosTab: FC = () => {
  const { budgetStore } = useStore()
  const { t } = useTranslation('budget')
  if (!budgetStore.posList.length) {
    return <EmptyList
      title={t`summery.pos_unavailable_title`}
      content={t`summery.pos_unavailable_content`}
    />
  }
  let dateList: PosTransaction[] = []
  budgetStore.posList.forEach(p => {
    p.transactions.forEach(t => {
      t.name = p.name.he
      t.description = t.details
    })
    dateList = [...dateList, ...p.transactions]
  })
  dateList = sortByDate('dd/MM/yyyy HH:mm', dateList, 'dateOfCharge')
  return (
    <StyledBody paddingTop={0}>
      {(() => {
        switch (budgetStore.sortType) {
          case SortTypes.DATE:
            return (
              <StyledFlex>
                {dateList.map(item => <Transaction transaction={item} name={item.name || ''} description={item.description || ''} shouldCollapseDefault={true} />)}
              </StyledFlex>
            )
          case SortTypes.SUM:
            return (
              <StyledFlex>
                {budgetStore.pos().map((pos: PosData, index: number) => (
                  <PaymentItem
                    key={pos.name.en}
                    item={pos}
                    index={index}
                    total={Number(pos.total) * -1}
                    transactionsLength={pos.transactions.length}
                    title={pos.name.he}
                    date={budgetStore.dateTimeStr(pos.lastDate)}
                  />
                ))}
              </StyledFlex>
            )
        }
      })()}
    </StyledBody>
  )
}

export default observer(PosTab)