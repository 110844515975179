import React, {FC, useState, useEffect} from 'react'
import styled from 'styled-components'
import {IconButton, DialogTitle, Dialog, DialogContent} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import {Close} from '@material-ui/icons'
import {theme, Space} from 'ui'
import {observer} from 'mobx-react'
import useStore from 'store'
import uiStore from '../../store/ui.store'
import SupportSection from './SupportSection'
import PortalSection from './PortalSection'
import {DialogHeaderText} from './styles'
import CommunitySection from './CommunitySection'
import EmergencyManagerSection from './EmergencyManagerSection'
import {ModuleEnum, ModuleType, ProviderEnum} from '../../utils/types'
import PaymentSection from './PaymentSection';
import KehilanetSection from './KehilanetSection'
import Express106Section from './Express106Section'

const DialogContainer = styled(Dialog)`
  .MuiDialog-paperFullWidth {
    border-radius: 8px;
    width: 700px;
  }
`

const DialogHeader = styled.div`
  .MuiDialogTitle-root {
    display: flex;
    height: 64px;
    background: ${theme.color.dialogBackground};
    align-items: center;
    justify-content: flex-end;
    padding: 16px 36px;
  }

  .MuiIconButton-root {
    padding: 0;
  }
`

const DialogBody = styled(DialogContent)`
  background: ${theme.color.dialogBackground};
  border-top-width: ${p => p.isTop ? '1px' : '0px'};
  padding: 16px 36px;
`

export interface CommunityFormState {
    name: string
    description: string
    isEnable: boolean
    isSendSMS: boolean
    isSendEmail: boolean
    enableSmsUnsubscribe: boolean
    isNameFieldError: boolean
}

export interface SupportFormState {
    isVisible: boolean
    providerId: number | null
}

export interface PaymentFormState {
    isVisible: boolean
    isActiveQRCode: boolean
}

export interface EmergencyManagerFormState {
    directorPermission: boolean
}

export interface PortalFormState {
    isVisible: boolean
    link: string
    orgId: string
    provider: string | null
}

const CommunitySettingsModal: FC = () => {
    const {t} = useTranslation('communitySettings')
    const ProviderTypes = [
        {value: ProviderEnum.MAOF, text: t`maof`},
        {value: ProviderEnum.NONE, text: t`budget:none`},
        {value: ProviderEnum.MEKOME, text: t`mekome`}
    ]

    const {orgs: orgsStore, modulesStore } = useStore()
    const {currentBrand, currentAdminOrg, currentOrgId } = orgsStore

    const [isSupportFormChanged, setSupportFormChanged] = useState<boolean>(false)
    const [isCommunityFormChanged, setIsCommunityFormChanged] = useState<boolean>(false)
    const [resetForm, setResetForm] = useState<boolean>(false)
    const [savePortalChanges, setSavePortalChanges] = useState<boolean>(false)
    const [isPortalFormChanged, setIsPortalFormChanged] = useState<boolean>(false)
    const [isPaymentFormChanged, setPaymentFormChanged] = useState<boolean>(false)
    const [isEmergencyManagerFormChanged, setEmergencyManagerFormChanged] = useState<boolean>(false)
    const [emergencyManagerModule, setEmergencyManagerModule] = useState(undefined)

    const [communityFormState, setCommunityFormState] = useState<CommunityFormState>({
        name: currentAdminOrg?.name ?? '',
        isNameFieldError: false,
        description: currentAdminOrg?.description ?? '',
        isEnable: currentAdminOrg?.status === 1,
        isSendSMS: currentAdminOrg?.sendSMS ?? false,
        isSendEmail: currentAdminOrg?.sendEmail ?? false,
        enableSmsUnsubscribe: currentAdminOrg?.enableSmsUnsubscribe ?? false,
    })

    const [paymentSlikaState, setPaymentSlikaState] = useState({
        orgId: null,
        slikaModuleId: null,
        initialOrgId: null,
    })
   
    const [supportFormState, setSupportFormState] = useState<SupportFormState>({
        isVisible: false,
        providerId: orgsStore.orgProvider?.providerId ?? -1,
    })
    
    const [portalFormState, setPortalFormState] = useState<PortalFormState>({
        isVisible: false,
        link: '',
        orgId: '',
        provider: ProviderTypes[1].value,
    })
    
    const [paymentFormState, setPaymentFormState] = useState<PaymentFormState>({
        isVisible: false,
        isActiveQRCode: false,
    })

    const [emergencyManagerState, setEmergencyManagerState] = useState({
        directorPermission: false
    })

    const open = orgsStore.isCommunitySettingsModalOpen

    useEffect(() => {
        const isInitialSupportVisible = modulesStore.communityFeatures.find(el => el.featureName === 'Service')?.enabled ?? false
        const isInitialPaymentVisible = modulesStore.communityFeatures.find(el => el.featureName === 'Budget')?.enabled ?? false
        const isInitialActiveQrModule = modulesStore.communityModules.find((module) => module.type === ModuleEnum.ID_QR_CODE);
        const isInitialPortalVisible = modulesStore.communityFeatures.find(el => el.featureName === 'Portal')?.enabled ?? false
        const emergencyModule = modulesStore.communityModules.find((module) => module.type === ModuleEnum.EMERGENCY_MANAGER)

        setSupportFormState({
            isVisible: isInitialSupportVisible,
            providerId: orgsStore.orgProvider?.providerId ?? -1,
        })

        setPortalFormState({
            isVisible: isInitialPortalVisible,
            link: '',
            orgId: '',
            provider: ProviderTypes[1].value,
        })

        setPaymentFormState({
            isVisible: isInitialPaymentVisible,
            isActiveQRCode: !!isInitialActiveQrModule?.module_id,
        })

        setEmergencyManagerModule(emergencyModule)
        setEmergencyManagerState({
            directorPermission: emergencyModule === undefined ? false : emergencyModule.directorPermission
        })

        onResetFields()

    }, [modulesStore.changedOrg])

    const onPortalSave = async () => {
        if (isPortalFormChanged) {
            await toggleVisibleCommunity(portalFormState.isVisible, 'Portal')
            setIsPortalFormChanged(false)
            setSavePortalChanges(false)
        }
    }

    const onCommunitySave = async () => {
        validateCommunityForm()
        if (communityFormState.isNameFieldError) {
            return
        }
        await orgsStore.updateCommunities(currentAdminOrg!.id, communityFormState)
        setIsCommunityFormChanged(false)
    }

    const onSupportSave = async () => {
        const isInitialSupportVisible = modulesStore.communityFeatures.find(el => el.featureName === 'Service')?.enabled ?? false
        
        if (supportFormState.providerId) {
            await orgsStore.updateCommunityProvider(supportFormState.providerId!)
        }

        if (supportFormState.isVisible !== isInitialSupportVisible) {
            await toggleVisibleCommunity(supportFormState.isVisible, 'Service')
        }

        setSupportFormChanged(false)
    }

    const onEmergencyManagerDelete = async () => {
        if (emergencyManagerModule !== undefined) {
            emergencyManagerModule.enable = false
            await modulesStore.deleteModule(emergencyManagerModule.module_id);
            await modulesStore.getCommunityModulesAndFeatures(orgsStore.currentOrgId)
            setEmergencyManagerFormChanged(false)
            setEmergencyManagerModule(undefined)
        } 
    }

    const onEmergencyManagerSave = async () => {
        if (emergencyManagerModule?.directorPermission !== emergencyManagerState.directorPermission) {
            const module: ModuleType = {
                community_ref: emergencyManagerModule?.community_ref,
                module_id: emergencyManagerModule?.module_id,
                directorPermission: emergencyManagerState.directorPermission,
                type: ModuleEnum.EMERGENCY_MANAGER,
                title: null, //string && nullable
                description: null, //string && nullable
                external_url: null, //string && nullable
                icon_name: null, //string && nullable
                icon_text: null,
                icon_color: "",
                org_id: emergencyManagerModule?.org_id,
                provider: null,
                information: '',
                data: '',
                share: false,
                shareEmail: ''
            }

            await modulesStore.updateModule(module)
            await modulesStore.getCommunityModulesAndFeatures(orgsStore.currentOrgId)
            const emModule = modulesStore.communityModules.find((module) => module.type === ModuleEnum.EMERGENCY_MANAGER)
            
            setEmergencyManagerModule(emModule)
            setEmergencyManagerState({directorPermission: emModule === undefined ? false : emModule.directorPermission})
            setEmergencyManagerFormChanged(false)
        }
    } 

    const onPaymentSave = async () => {
        const isInitialPaymentVisible = modulesStore.communityFeatures.find(el => el.featureName === 'Budget')?.enabled ?? false
        const isInitialActiveQrModule = modulesStore.communityModules.find((module) => module.type === ModuleEnum.ID_QR_CODE);

        if (paymentFormState.isVisible !== isInitialPaymentVisible) {
            await toggleVisibleCommunity(paymentFormState.isVisible, 'Budget')
        }

        if (paymentFormState.isActiveQRCode !== !!isInitialActiveQrModule?.module_id && isPaymentFormChanged) {
            if (paymentFormState.isActiveQRCode) {
                const module: ModuleType = {
                    community_ref: modulesStore?.currentModule?.community_ref || currentOrgId,
                    module_id: modulesStore?.currentModule?.module_id || null,
                    type: ModuleEnum.ID_QR_CODE, //(enum: portal, budget, third, reservations, voting) + nut null
                    title: null, //string && nullable
                    description: null, //string && nullable
                    external_url: null, //string && nullable
                    icon_name: null, //string && nullable
                    icon_text: null,
                    icon_color: "",
                    org_id: modulesStore?.currentModule?.org_id || null,
                    provider:  ProviderEnum.MEKOME_QR,
                    data: '',
                    information: modulesStore?.currentModule?.information || '',
                    share: !!modulesStore?.currentModule?.share,
                    shareEmail: modulesStore?.currentModule?.shareEmail || ''
                }

                await modulesStore.addNewModule(module)
            }

            if (!paymentFormState.isActiveQRCode) {
                modulesStore.deleteModule(isInitialActiveQrModule?.module_id as number)
            }
        }


        if (paymentSlikaState.orgId !== paymentSlikaState.initialOrgId && isPaymentFormChanged) {
            const module: ModuleType = {
                community_ref: modulesStore?.currentModule?.community_ref || currentOrgId,
                module_id: paymentSlikaState.slikaModuleId,
                type: ModuleEnum.SLIKA,
                title: null, //string && nullable
                description: null, //string && nullable
                external_url: null, //string && nullable
                icon_name: null, //string && nullable
                icon_text: null,
                icon_color: "",
                org_id: paymentSlikaState.orgId,
                provider: ProviderEnum.SLIKA,
                data: '',
                information: modulesStore?.currentModule?.information || '',
                share: !!modulesStore?.currentModule?.share,
                shareEmail: modulesStore?.currentModule?.shareEmail || ''
            }

            if (!!paymentSlikaState.initialOrgId) {
                await modulesStore.updateModule(module)
            } else {
                await modulesStore.addNewModule(module)
            }
        }

        setPaymentFormChanged(false)
    }

    const onDeleteProvider = async () => {
        if (orgsStore.orgProvider) {
            orgsStore.deleteCommunityProvider().then(res => {
                orgsStore.setShowAssociateModal(false)
            }).catch(e => {
                console.log('Error', e)
                orgsStore.setShowAssociateModal(false)
            })
        }
        onResetFields()
    }

    const onCommunityChangeName = (value: string) => setCommunityFormState({
        ...communityFormState,
        name: value,
    })
    const onCommunityChangeDescription = (value: string) => setCommunityFormState({
        ...communityFormState,
        description: value,
    })
    const onCommunityChangeSendSMS = (value: boolean) => setCommunityFormState({
        ...communityFormState,
        isSendSMS: value,
    })
    const onCommunityChangeSendEmail = (value: boolean) => setCommunityFormState({
        ...communityFormState,
        isSendEmail: value,
    })
    const onCommunityChangeRemoveSMSTitle = (value: boolean) => setCommunityFormState({
        ...communityFormState,
        enableSmsUnsubscribe: value,
    })
    const onCommunityToggleEnable = (visible: boolean) => setCommunityFormState({
        ...communityFormState,
        isEnable: visible,
    })

    const onChangeProvider = (e: any) => setSupportFormState({...supportFormState, providerId: e.target.value})
    const onSupportToggleVisible = (visible: boolean) => setSupportFormState({...supportFormState, isVisible: visible})

    const onPaymentToggleVisible = (visible: boolean) => setPaymentFormState({...paymentFormState, isVisible: visible})
    const onPaymentToggleQRCode = (active: boolean) => setPaymentFormState({...paymentFormState, isActiveQRCode: active})

    const onPortalToggleVisible = (visible: boolean) => setPortalFormState({...portalFormState, isVisible: visible})
    const onPortalChangeProvider = (value: string | null) => setPortalFormState({...portalFormState, provider: value})
    const onChangeLink = (value: string) => setPortalFormState({...portalFormState, link: value})
    const onChangeOrgId = (value: string) => setPortalFormState({...portalFormState, orgId: value})
    const onEmergencyManagerToggleDirectorPermission = (checked: boolean) => setEmergencyManagerState({...emergencyManagerState, directorPermission: checked})

    const onResetCommunityField = () => setCommunityFormState({
        name: currentAdminOrg?.name ?? '',
        description: currentAdminOrg?.description ?? '',
        isEnable: currentAdminOrg?.status === 1,
        isSendSMS: currentAdminOrg?.sendSMS ?? false,
        isSendEmail: currentAdminOrg?.sendEmail ?? false,
        enableSmsUnsubscribe: currentAdminOrg?.enableSmsUnsubscribe ?? false,
        isNameFieldError: false,
    })

    const onResetSupportField = () => {
        const isInitialSupportVisible = modulesStore.communityFeatures.find(el => el.featureName === 'Service')?.enabled ?? false
        setSupportFormState({
            isVisible: isInitialSupportVisible,
            providerId: orgsStore.orgProvider?.providerId ?? -1,
        })
    }

    const onResetPaymentField = () => {
        const isInitialPaymentVisible = modulesStore.communityFeatures.find(el => el.featureName === 'Budget')?.enabled ?? false
        const isInitialActiveQrModule = modulesStore.communityModules.find((module) => module.type === ModuleEnum.ID_QR_CODE);
        setPaymentFormState({
            isVisible: isInitialPaymentVisible,
            isActiveQRCode: !!isInitialActiveQrModule?.module_id,
        })
    }

    const onResetPortalField = () => setPortalFormState({
        isVisible: modulesStore.communityFeatures.find(el => el.featureName === 'Portal')?.enabled ?? false,
        link: modulesStore.currentModule?.external_url ?? '',
        orgId: modulesStore?.currentModule?.org_id ?? '',
        provider: modulesStore?.currentModule?.provider ?? ProviderTypes[1].value,
    })

    const validateCommunityForm = () => {
        if (communityFormState.name.trim().length < 2) {
            setCommunityFormState({...communityFormState, isNameFieldError: true})
        } else {
            setCommunityFormState({...communityFormState, isNameFieldError: false})
        }
    };

    const onResetFields = () => {
        onResetCommunityField()
        onResetSupportField()
        onResetPaymentField()
        onResetPortalField()
    }

    const closeModal = async () => {
        if (isCommunityFormChanged || isSupportFormChanged || isPortalFormChanged || isPaymentFormChanged || isEmergencyManagerFormChanged) {
            uiStore.openWarningModal({
                text: t`youHaveUnsavedChanges`,
                okText: t`confirmModelNo`,
                cancelText: t`confirmModelYes`,
                okAction: () => {
                    setResetForm(true)
                },
                cancelAction: async () => {
                    if (isEmergencyManagerFormChanged) {
                        await onEmergencyManagerSave()    
                    }
                    if (isCommunityFormChanged) {
                        await onCommunitySave()
                    }
                    if (isSupportFormChanged) {
                        await onSupportSave()
                    }
                    if (isPaymentFormChanged) {
                        await onPaymentSave()
                    }
                    if (isPortalFormChanged) {
                        setSavePortalChanges(true)
                    }
                    await orgsStore.initOrgs()
                },
                onBackdropClick: () => {
                    setResetForm(true)
                }
            })
            return
        }
        if (!isCommunityFormChanged && !isSupportFormChanged && !isPaymentFormChanged && !isPortalFormChanged && !isPortalFormChanged && !isEmergencyManagerFormChanged) {
            return orgsStore.setShowCommunitySettingsModal(false)
        }
        await orgsStore.initOrgs()
        setResetForm(true)
        orgsStore.setShowCommunitySettingsModal(false)
    }
    
    const toggleVisibleCommunity = async (enabled: boolean, communityName: string) => {
        if (currentAdminOrg) {
            await orgsStore.updateCommunity(currentAdminOrg.id,
                modulesStore.communityFeatures.map(el => el.featureName === communityName ? {
                    communityId: el.communityId,
                    enabled: enabled,
                    featureName: el.featureName,
                    id: el.id,
                    status: enabled ? 1 : 2,
                } : el),)

            await modulesStore.getCommunityModulesAndFeatures(currentAdminOrg.id)
        }

    }

    useEffect(() => {
        const isInitialPortalVisible = modulesStore.communityFeatures.find(el => el.featureName === 'Portal')?.enabled ?? false

        setIsPortalFormChanged(
            isInitialPortalVisible !== portalFormState.isVisible ||
            portalFormState.link !== (modulesStore.currentModule?.external_url ?? '') ||
            portalFormState.orgId !== (modulesStore.currentModule?.org_id ?? '') ||
            portalFormState.provider !== (modulesStore?.currentModule?.provider ?? ProviderTypes[1].value),
        )
    }, [portalFormState, modulesStore?.currentModule?.provider, modulesStore.currentModule?.external_url, modulesStore.currentModule?.org_id])


    // setup initial portal values
    useEffect(() => {
        if (modulesStore?.currentModule) {
            onResetPortalField()
        }
    }, [modulesStore.currentModule])

    // setup initial support values
    useEffect(() => {
        onResetSupportField()
    }, [orgsStore.currentOrgId, orgsStore.orgProvider?.providerId])

    
    useEffect(()=> {
        const isInitialActiveQrModule = modulesStore.communityModules.find((module) => module.type === ModuleEnum.ID_QR_CODE);

        if (!isPaymentFormChanged && paymentFormState.isActiveQRCode !== !!isInitialActiveQrModule?.module_id) {
            setPaymentFormState(prev => ({...prev, isActiveQRCode: !!isInitialActiveQrModule?.module_id}))
        }

    }, [isPaymentFormChanged])

    // after open modal setup slika module
    useEffect(()=> {
        const slika = modulesStore?.communityModules.filter(i => i.type === ModuleEnum.SLIKA)

        if (open && slika.length) {
            modulesStore.setCurrentModule(slika[0].module_id)
        }

        if(open) {
            const module = modulesStore.communityModules.find((module) => module.type === ModuleEnum.EMERGENCY_MANAGER)
            setEmergencyManagerModule(module)            
            setEmergencyManagerState({
                directorPermission: module === undefined ? false : module.directorPermission
            })
        }
    }, [open])

    // if Some field has been changed -> set flag isSupportFormChanged to true
    useEffect(() => {
        const isInitialSupportVisible = modulesStore.communityFeatures.find(el => el.featureName === 'Service')?.enabled ?? false

        setSupportFormChanged(
            isInitialSupportVisible !== supportFormState.isVisible ||
            supportFormState.providerId !== (orgsStore.orgProvider?.providerId ?? -1),
        )
    }, [supportFormState.providerId, supportFormState.isVisible, orgsStore.orgProvider?.providerId])

    // if Some field has been changed -> set flag isPaymentFormChanged to true
    useEffect(() => {
        const isInitialPaymentVisible = modulesStore.communityFeatures.find(el => el.featureName === 'Budget')?.enabled ?? false
        const isInitialActiveQrModule = modulesStore.communityModules.find((module) => module.type === ModuleEnum.ID_QR_CODE);

        setPaymentFormChanged(
          isInitialPaymentVisible !== paymentFormState.isVisible ||
          !!isInitialActiveQrModule?.module_id !== paymentFormState.isActiveQRCode,
        )
    }, [paymentFormState])

    useEffect(() => {
        if (emergencyManagerModule !== undefined) {
            const currentVal = emergencyManagerModule.directorPermission == null 
              ? false 
              : emergencyManagerModule.directorPermission
            setEmergencyManagerFormChanged(currentVal != emergencyManagerState.directorPermission)
        }
    }, [emergencyManagerState])

    useEffect(() => {
        setResetForm(true)
        return () => {
        }
    }, [orgsStore.currentAdminOrg, orgsStore.currentBrand])

    useEffect(() => {
        setIsCommunityFormChanged(
            communityFormState.name !== currentAdminOrg?.name ||
            communityFormState.description !== (currentAdminOrg?.description ?? '') ||
            communityFormState.isEnable !== (currentAdminOrg?.status === 1) ||
            communityFormState.isSendSMS !== (currentAdminOrg?.sendSMS ?? false) ||
            communityFormState.isSendEmail !== (currentAdminOrg?.sendEmail ?? false) ||
            communityFormState.enableSmsUnsubscribe !== (currentAdminOrg?.enableSmsUnsubscribe ?? false)
        )
    }, [communityFormState, currentAdminOrg])

    useEffect(() => {
        validateCommunityForm()
    }, [communityFormState.name])

    useEffect(() => {
        if (resetForm) {
            onResetFields()
            orgsStore.setShowCommunitySettingsModal(false)
            setResetForm(false)
        }
    }, [resetForm, orgsStore.currentAdminOrg])

    return (
        <DialogContainer
            open={open}
            fullWidth maxWidth='md'>
            <DialogHeader>
                <DialogTitle disableTypography>
                    
                    <IconButton onClick={closeModal}>
                        <Close/>
                    </IconButton>
                </DialogTitle>
            </DialogHeader>
            <DialogBody>
                <CommunitySection
                    brandName={currentBrand.brandName}
                    currentOrgId={currentAdminOrg?.id}
                    communityFormState={communityFormState}
                    onCommunityToggleEnable={onCommunityToggleEnable}
                    onCommunityChangeName={onCommunityChangeName}
                    onCommunityChangeDescription={onCommunityChangeDescription}
                    onCommunityChangeSendSMS={onCommunityChangeSendSMS}
                    onCommunityChangeSendEmail={onCommunityChangeSendEmail}
                    onCommunityChangeRemoveSMSTitle={onCommunityChangeRemoveSMSTitle}
                    isCommunityFormChanged={isCommunityFormChanged}
                    onCommunitySave={onCommunitySave}
                />
                <Space/>
                <hr/>
                <SupportSection
                    onSupportSave={onSupportSave}
                    isSupportFormChanged={isSupportFormChanged}
                    supportFormState={supportFormState}
                    onChangeProvider={onChangeProvider}
                    onSupportToggleVisible={onSupportToggleVisible}
                    onDeleteProvider={onDeleteProvider}
                    providers={orgsStore.providers}
                    orgProvider={orgsStore.orgProvider}
                />
                <Space/>
                <hr/>
                <Space/>
                <PaymentSection
                  onPaymentSave={onPaymentSave}
                  isPaymentFormChanged={isPaymentFormChanged}
                  paymentFormState={paymentFormState}
                  onSectionToggleVisible={onPaymentToggleVisible}
                  onPaymentToggleQRCode={onPaymentToggleQRCode}
                  onPaymentFormChanged={setPaymentFormChanged}
                  onPaymentSlikaChangeState={setPaymentSlikaState}
                />
                <Space/>
                <hr/>
                <Space/>
                <PortalSection
                    onPortalSave={onPortalSave}
                    savePortalChanges={savePortalChanges}
                    isPortalFormChanged={isPortalFormChanged}
                    portalFormState={portalFormState}
                    onPortalToggleVisible={onPortalToggleVisible}
                    onPortalChangeProvider={onPortalChangeProvider}
                    onChangeLink={onChangeLink}
                    onChangeOrgId={onChangeOrgId}
                    ProviderTypes={ProviderTypes}
                    onResetPortalField={onResetPortalField}
                />

                <KehilanetSection
                    currentOrgId={currentAdminOrg?.id}
                    resetForm={resetForm}
                />

               <Express106Section
                    currentOrgId={currentAdminOrg?.id}
                    resetForm={resetForm}
                />
                {emergencyManagerModule !== undefined &&
                <>
                  <hr/>
                  <Space/>
                  <EmergencyManagerSection 
                      onEmergencyManagerSave={onEmergencyManagerSave}
                      onEmergencyManagerDelete={onEmergencyManagerDelete}
                      isEmergencyManagerFormChanged={isEmergencyManagerFormChanged}
                      emergencyManagerFormState={emergencyManagerState}
                      onEmergencyManagerToggleDirectorPermission={onEmergencyManagerToggleDirectorPermission}
                  />
                </>}
            </DialogBody>
        </DialogContainer>
    )
}

export default observer(CommunitySettingsModal)
