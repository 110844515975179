import React, { FC, useState, useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import exportDataIcon from 'assets/icons/exportDataIcon.svg'
import { Dialog } from '@material-ui/core'
import { GroupAdd, DeleteForever } from '@material-ui/icons'
import { Tooltip, Icon } from '@material-ui/core'
import { Space, theme, Row, Appear, IconButton, Image, Text, Spinner } from 'ui'
import useStore, { groups } from 'store'
import { useTranslation } from 'react-i18next'
import MembersModalList from './MembersModalList'
import Search from 'components/Search'
import { useConfirmDialog } from 'core/ConfirmDialog'
import { canEditUsersGroup, canEditGroup, canEditCommunityUsers, canExportGroupUsers } from 'utils/roles'
import MembersInGroupDialog from 'components/Members/MembersInGroupDialog'
import MessagesHeader from 'components/Messages/MessagesHeader'
import MembersCounter from 'components/MembersCounter'
import { formatIfPhoneNumber } from 'utils/converts'
import AddMembersToGroup from './AddMembersToGroup'
import pencilIcon from 'assets/icons/Pencil.svg'
import EditGroup from './EditGroup'
import { saveCsv } from 'utils/csvUtils'
import { GroupType, UserGroup } from 'utils/types'
import ExportCSV from 'assets/icons/export_csv.svg'
import { User } from 'utils/models'
import { isTotzeret } from 'service/whiteLable'


const StyledDialog = styled(Dialog)`
  align-self: center;
  justify-self: center;
  .MuiDialog-paperScrollPaper {
  height: 85vh;
  min-width: 60vw;
  }
`

const Content = styled.div`
  flex: 1;
  display:flex;
  overflow:auto;

`

const Content2 = styled.div`
  flex: 1;
  display:flex;
  justify-content:center;
  align-items:center;
`
const Container = styled.div`
  flex: 1;
  display:flex;
  //height:700px;
  //width:900px;
  overflow:hidden;
  flex-direction:column;
  border:1px solid white;
  border-radius:5px;
  @media only screen and (min-width: 1700px) {
      //height:900px;
      //width:1400px;
  }
  @media (max-width: 768px) {
    width: unset;
  }
`
const Overlay = styled.div<{ color: string }>`
  padding: 2px;
  flex: 1;
  background-color:${p => p.color};
  display:flex;
  overflow:auto;
  flex-direction:column;
`
const SpinnerContainer = styled.div<{ backgroundColor: string }>`
  display: flex;
  flex:1;
  align-items:center;
  justify-content:center;
  background-color: ${p => p.backgroundColor || 'var(--light-gray)'};

`
const SearchContainer = styled.div`
  width: 25vw;
  max-width:100%;
`
const FlexSpace = styled.div`
`
const ButtonsContainer = styled.div`
  display: flex;
`
const MemberModal: FC = () => {
  const { t } = useTranslation('home')
  const { t: t2 } = useTranslation('members')
  const { t: t3 } = useTranslation('csvHeaders')
  const { members, groups, orgs, user, uiStore } = useStore()

  const supportCallType = groups.currentGroup?.group?.type === GroupType.SUPPORT

  const userCanEditUsersGroup = canEditGroup()
  const userView = !userCanEditUsersGroup

  const isSupportChild = Boolean(groups.currentGroup?.group?.parent_id) && ((groups.currentGroup?.group?.id && Math.abs(groups.currentGroup.group.id)) != groups.currentGroup?.group?.parent_id)
  const [searchText, onChangeText] = React.useState<string>('')
  const [membersList, setMembersList] = useState<UserGroup[]>([])
  const noSearchResult = membersList.length === 0
  const noMemberInGroup = groups.groupMembers.length === 0
  const hideAddUsers = members.orgMembers.filter(member => !groups.getById(member.user_id)).length === 0
  useEffect(() => {
    if (userView) {
      const managers = groups.groupMembers.filter(u => u.is_organizer)
      setMembersList(managers)
    } else {
      setMembersList(groups.groupMembers)
    }
  }, [groups.groupMembers])


  useEffect(() => {
    onChangeText('')
  }, [groups.currentGroupId])

  //if revealed, always display default screen first
  useEffect(() => {
    if (groups.isMembersModalOpen) {
      setShow('messages')
    }
  }, [groups.isMembersModalOpen])
  useEffect(() => {
    if (searchText === '') {
      setMembersList(groups.groupMembers)
    } else {
      const splitSearchText = searchText?.toLocaleLowerCase().split(' ')
      const filterdData = groups.groupMembers.filter((userGroup: UserGroup) => {
        const user = userGroup.userData

        const splitFullName = [user?.given_name?.trim().toLowerCase(), user?.family_name?.trim().toLowerCase()]
        if (splitSearchText.every(el => splitFullName.some(el2 => el2?.includes(el)))) {
          return true
        } else if (
          user?.phones && user.phones.find((phone: string) => formatIfPhoneNumber(phone).includes(searchText)) ||
          user?.phones && user.phones.find((phone: string) => formatIfPhoneNumber(phone).replaceAll('-', '').includes(searchText)) ||
          user?.phones && user.phones.find((phone: string) => phone.includes(searchText))
        ) {
          return true
        } else if (
          user?.emails && user.emails.find((email: string) => email.toLowerCase().includes(searchText.toLowerCase()))
        ) {
          return true
        } else if (
          user?.nick_name && user.nick_name.toLowerCase().includes(searchText.toLowerCase())
        ) {
          return true
        }
        else if (
          // user.is_operator && t`membersTable:groupManager`.toLowerCase().includes(searchText) ||
          userGroup.is_organizer && t`membersTable:groupManager`.toLowerCase().includes(searchText)

        ) {
          return true
        }
      })
      setMembersList(filterdData)
    }
  }, [searchText, groups.groupMembers])



  const [show, setShow] = useState('messages')
  const openAdd = () => setShow('addMembers')
  const openEditGroup = () => {
    groups.editGroup()
    setShow('editGroup')
  }

  const onClose = () => setShow('messages')

  const closeModal = () => {
    setShow('messages')
    onChangeText('')
    groups.closeMembersModal()
  }
  // let secondTextHeader = <Row style={{ justifyContent: 'flex-start', }}> {canEditGroup() && <> <Text
  //   textcolor={theme.color.black}
  //   weight='normal'
  //   size={'lg'}
  // >{`${currentGroup?.group.group_is_member ? t`sidebar:open` : t`sidebar:close`}`}</Text>
  //   {!currentGroup?.group.group_is_subscriber && <Text
  //     textcolor={theme.color.black}
  //     weight={'normal'}
  //     size={'lg'}
  //   >{`\xa0 ${t`sidebar:withoutNotifcations`}`}</Text>}</>} <MembersCounter hide={!canEditGroup()} disabledClick textColor={theme.color.black} textSize={'lg'} /> </Row>
  let mainText = orgs.currentOrg?.name
  let secondTextHeader = groups.currentGroup?.group?.name || ''
  if (show === 'editGroup') {
    secondTextHeader = t2`members:editGroup`
    mainText = groups.currentGroup?.group?.name || ''
  }
  if (show === 'addMembers') {
    secondTextHeader = t2`members:manage`
    mainText = groups.currentGroup?.group?.name || ''
  }

  const removeAllMembers = () => {
    uiStore.openWarningModal({
      text: t2`areYouSure`,
      okAction: () => {
        groups.removeAllMembers()
      }
    })
  }
  return (
    <StyledDialog
      style={{ zIndex: 1200 }}
      open={groups.isMembersModalOpen}
      // onClose={closeModal}
      maxWidth={'xl'}>
      <Container>
        <MessagesHeader
          showChevronIcon={(show === 'addMembers' || show === 'editGroup') ? true : false}
          textVarient={'black'}
          backColor={groups.currentGroup?.group?.description?.color + 33 || '#0000001A'}
          // mainText={!userView ? (supportCallType ? ` ${orgs.currentOrg?.name} ${t`serviceCall:services`} | ${mainText} ` : orgs.currentOrg?.name + ' | ' + mainText) : currentGroup?.group?.name}
          mainText={mainText}
          secondText={secondTextHeader}
          onClose={(show === 'addMembers' || show === 'editGroup') ? onClose : closeModal}
        />

        {groups.groupMembersLoading ? <SpinnerContainer backgroundColor={groups.currentGroup?.group?.description?.color + 33 || '#0000001A'}><Spinner /></SpinnerContainer> :
          (

            <Overlay color={groups.currentGroup?.group?.description?.color + 33 || '#0000001A'}>
              {show === 'messages' && (
                <>
                  {!userView && <Row style={{ marginLeft: 10, marginRight: 10 }}>
                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                      <SearchContainer>
                        <Space height={0.5} />
                        <Search
                          onChange={onChangeText}
                          searchText={searchText}
                          backgroundColor={theme.color.whiteBackground + 'CC'}
                        />
                      </SearchContainer>
                      <Space />
                      {canEditGroup() &&
                        <div style={{ flex: 1, justifyContent: 'flex-start' }} >
                          <MembersCounter
                            hide={true}
                            disabledClick
                            textColor={theme.color.black}
                            textSize={'md'} />
                          <Row style={{ flex: 1, justifyContent: 'flex-start' }} >
                            <Text
                              textcolor={theme.color.black}
                              weight='normal'
                              size={'sm'}
                            >{`(${groups.currentGroup?.group.group_is_member ? t`sidebar:open` : t`sidebar:close`}`}</Text>
                            {!groups.currentGroup?.group.group_is_subscriber && <Text
                              textcolor={theme.color.black}
                              weight={'normal'}
                              size={'sm'}
                            >{`,\xa0 ${t`sidebar:withoutNotifcations`}`}</Text>}
                            <Text>{')'}</Text>
                          </Row>
                        </div>
                      }
                    </div>
                    <FlexSpace />
                    {(canEditGroup() && !isSupportChild) && <ButtonsContainer>
                      <Row>
                        {canEditCommunityUsers() && !groups.currentGroup?.group.group_is_member && <Tooltip title={t2`deleteAll` || ''} arrow>
                          <div>
                            <IconButton
                              disabled={noMemberInGroup}
                              style={{ backgroundColor: noMemberInGroup ? groups.currentGroup?.group?.description?.color + 33 || theme.color.black + 33 : groups.currentGroup?.group?.description?.color || theme.color.black + 66 }}
                              onClick={removeAllMembers}><DeleteForever
                                style={{ color: theme.color.whiteBackground }}
                              /></IconButton>
                          </div>
                        </Tooltip>}
                        <Space width={0.5} />
                        {canExportGroupUsers() && <Tooltip title={t2`export` || ''} arrow>
                          <div>
                            <IconButton
                              disabled={noMemberInGroup}
                              style={{ backgroundColor: noMemberInGroup ? groups.currentGroup?.group?.description?.[isTotzeret ? 'iconColor' : 'color'] + 33 || theme.color.black + 33 : groups.currentGroup?.group?.description?.[isTotzeret ? 'iconColor' : 'color'] || theme.color.black + 66 }}
                              onClick={() => {
                                saveCsv((groups.groupMembers.map(el => el.userData).filter(el => el) as User[]).sort((a, b) => !a.family_name ? 1 : (!b.family_name ? -1 : (a.family_name.localeCompare(b.family_name, 'he', { sensitivity: 'base' })))),
                                  `${t3`csvFileNameGroup`}${groups.currentGroup?.group.name.split(' ').join('_')}`, t3)
                              }}><img src={ExportCSV} /></IconButton>
                          </div>
                        </Tooltip>}
                        <Space width={0.5} />
                        <Tooltip title={t2`addFriends` || ''} arrow>
                          <div>
                            <IconButton
                              disabled={hideAddUsers}
                              style={{ backgroundColor: hideAddUsers ? groups.currentGroup?.group?.description?.[isTotzeret ? 'iconColor' : 'color'] + 66 || theme.color.black + 33 : groups.currentGroup?.group?.description?.[isTotzeret ? 'iconColor' : 'color'] || theme.color.black + 66 }}
                              onClick={openAdd} ><GroupAdd
                                style={{ color: theme.color.whiteBackground }}
                              /></IconButton>
                          </div>
                        </Tooltip>
                        <Space width={0.5} />
                        {true && <Tooltip title={t2`editGroup`} arrow>
                          <div>
                            <IconButton style={{ backgroundColor: groups.currentGroup?.group?.description?.[isTotzeret ? 'iconColor' : 'color'] || theme.color.black + 66 }} onClick={openEditGroup}><Icon ><Image src={pencilIcon} /></Icon></IconButton>
                          </div>
                        </Tooltip>}
                      </Row>
                    </ButtonsContainer>
                    }
                  </Row>}
                  <Space height={1} />
                  {(noSearchResult || noMemberInGroup) ?
                    <Content2>
                      <Text
                        weight={'normal'}
                        size={'xl'}
                        textcolor={'black'}
                      >{noMemberInGroup ? t`members:groupWitnNoFreinds` : t`members:noSearchResult`}</Text>
                    </Content2> :
                    <Content>
                      <MembersModalList
                        list={membersList}
                        userView={(userView || isSupportChild)}
                        searchText={searchText}
                      />
                      {/* <MembersInGroupDialog /> */}
                    </Content>}
                </>)}
              {show === 'addMembers' && <AddMembersToGroup onClose={onClose} />}
              {show === 'editGroup' && <EditGroup onClose={onClose} />}
            </Overlay>
          )}

      </Container>
    </StyledDialog>
  )
}

export default observer(MemberModal)
