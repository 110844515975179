import React, { FunctionComponent, ReactText } from 'react'
import { TextField, TextFieldProps } from '@material-ui/core'

interface FastInputHandle {
  clear: () => void,
  setText: (a: string) => void,
  getValue: () => string,
}
type OmittedTextFieldProps = Omit<TextFieldProps,"value">
interface LoopInputProps {
  onChange: (event: any) => void,
  className?: string,
  defaultValue?: string
}
const LoopInput: React.ForwardRefRenderFunction<FastInputHandle, LoopInputProps & OmittedTextFieldProps> = ({ onChange, className, defaultValue = '', ...props }, ref) => {
  const [text, setText] = React.useState(defaultValue)
  const handleChange = (event: any) => {
    setText(event.target?.value)
    onChange(event)
  }
  const inputRef = React.useRef<HTMLInputElement>();
  React.useImperativeHandle(ref, () => ({
    clear: () => {
      if (inputRef.current) {
        inputRef.current.value = ''
      }
    },
    setText: (text: string) => {
      if (inputRef.current) {
        inputRef.current.value = text
      }
    },
    getValue: () => inputRef?.current?.value || ''
  }));
  return <TextField
    className={className}
    inputRef={inputRef}
    onChange={handleChange}
    value={text}
    {...props}
  />
}
export default React.memo(React.forwardRef(LoopInput))