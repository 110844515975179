import React, {FC, useState, useEffect} from 'react'
import styled from 'styled-components'
import {IconButton, DialogTitle, Dialog, DialogContent} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import {Close} from '@material-ui/icons'
import {theme, Space} from 'ui'
import {observer} from 'mobx-react'
import useStore from 'store'
import uiStore from '../../store/ui.store'
import {DialogHeaderText} from './styles'
import MapSection from './MapSection'

const DialogContainer = styled(Dialog)`
  .MuiDialog-paperFullWidth {
    border-radius: 8px;
    width: 780px;
  }
`
const DialogHeader = styled.div`
  .MuiDialogTitle-root {
    display: flex;
    height: 64px;
    background: ${theme.color.dialogBackground};
    align-items: center;
    justify-content: space-between;
    padding: 16px 36px;
  }

  .MuiIconButton-root {
    padding: 0;
  }
`
const DialogBody = styled(DialogContent)`
  background: ${theme.color.dialogBackground};
  border-top-width: ${p => p.isTop ? '1px' : '0px'};
  padding: 16px 36px;
  overflow-y: visible;
`
export interface MapFormState {
    accuracy: number | null,
    enabled: boolean | null,
    emergencyEnabled: boolean | null,
    locationEnabled: boolean | null,
    fromKM: number | null,
    toKM: number | null,
    lat: number | null,
    lng: number | null,
    zoom: number | null,
    moreInformation: string | null
    geoData: string | null
}

const MapSettingsModal: FC = () => {
    const {t} = useTranslation('mapSettings')
   
    const {orgs: orgsStore, modulesStore } = useStore()
    //const {currentOrgId } = orgsStore
    const open = orgsStore.isMapSettingsModalOpen

    const [isCommunityFormChanged, setIsCommunityFormChanged] = useState<boolean>(false)
    const [resetForm, setResetForm] = useState<boolean>(false)
    const [mapFormState, setMapFormState] = useState<MapFormState>({
        accuracy: null,
        enabled: null,
        emergencyEnabled: null,
        locationEnabled: null,
        fromKM: null,
        toKM: null,
        lat: null,
        lng: null,
        zoom: null,
        moreInformation: null,
        geoData: ''
    })

    const [mapFormValidState, setMapFormValidState] = useState<any>({
        accuracy: { valid: true, rule: /^\d+(\.\d+)?$/ },
        fromKM: { valid: true, rule: /^\d+$/ },
        toKM: { valid: true, rule: /^\d+$/ },
        lat: { valid: true, rule: /^\d+(\.\d+)?$/ },
        lng: { valid: true, rule: /^\d+(\.\d+)?$/ },
        zoom: { valid: true, rule: /^\d+$/ },
    })

    const getLocationFeature = () => {
        return modulesStore
          .communityFeatures
          .find(el => el.featureName.toLowerCase() === 'location')
    }
    
    const getEmergencyFeature = () => {
        return modulesStore
          .communityFeatures
          .find(el => el.featureName.toLowerCase() === 'emergency')
    }

    useEffect(() => {
        const initData = async () => {
            if(orgsStore.currentOrgId) {
                const locationFeature = getLocationFeature()
                const emergencyFeature = getEmergencyFeature()

                const settings = await orgsStore.getCommunitySettings(orgsStore.currentOrgId)
                setMapFormState({
                    emergencyEnabled: emergencyFeature ? emergencyFeature.enabled : null,
                    locationEnabled: locationFeature ? locationFeature.enabled : null,
                    accuracy: settings.accuracy,
                    enabled: settings.enabled,
                    fromKM: settings.fromKM,
                    toKM: settings.toKM,
                    lat: settings.lat,
                    lng: settings.lng,
                    zoom: settings.zoom,
                    moreInformation: settings.moreInformation,
                    geoData: settings.geoData
                }) 
            }
        }

        initData()
        
    }, [])

     const onMapSettingsSave = async () => {
        const isValid = validateCommunityForm()
        
        if (!isValid) {
            return
        }

        const locationFeature = getLocationFeature()
        const emergencyFeature = getEmergencyFeature()
        
        await orgsStore.updateCommunitySettings(orgsStore.currentOrgId, mapFormState)
        await toggleVisibleLocationAndEmergency(
            locationFeature ? mapFormState.locationEnabled : null,
            emergencyFeature ? mapFormState.emergencyEnabled : null,
        )    

        await orgsStore.getCommunitySettings(orgsStore.currentOrgId)
        await modulesStore.getCommunityModulesAndFeatures(orgsStore.currentOrgId)
        setIsCommunityFormChanged(false)
    }

    const toggleVisibleLocationAndEmergency = async (
        locationEnabled: boolean | null,
        emergencyEnabled: boolean | null,
    ) => {
        if (orgsStore.currentOrgId) {
            await orgsStore.updateCommunity(orgsStore.currentOrgId,
                modulesStore.communityFeatures.map(el => {
                    
                    if (el.featureName.toLowerCase() === 'emergency' && emergencyEnabled !== null) {
                        return {
                            communityId: el.communityId,
                            enabled: emergencyEnabled,
                            featureName: el.featureName,
                            id: el.id,
                            status: emergencyEnabled ? 1 : 2,
                        }
                    } 

                    if (el.featureName.toLowerCase() === 'location' && locationEnabled !== null) {
                        return {
                            communityId: el.communityId,
                            enabled: locationEnabled,
                            featureName: el.featureName,
                            id: el.id,
                            status: locationEnabled ? 1 : 2,
                        }
                    } 

                    return el;
                })
            )

            return true
        }

        return true
    }

    const onResetFields = () => {
        const locationFeature = getLocationFeature()
        const emergencyFeature = getEmergencyFeature()

        setMapFormState({
            locationEnabled: locationFeature ? locationFeature.enabled : null,
            emergencyEnabled: emergencyFeature ? emergencyFeature.enabled : null,
            accuracy: orgsStore.settings.accuracy,
            enabled: orgsStore.settings.enabled,
            fromKM: orgsStore.settings.fromKM,
            toKM: orgsStore.settings.toKM,
            lat: orgsStore.settings.lat,
            lng: orgsStore.settings.lng,
            zoom: orgsStore.settings.zoom,
            moreInformation: orgsStore.settings.moreInformation,
            geoData: orgsStore.settings.geoData
        })
    }
    
    const validateCommunityForm = () => {
        let isValid = true
        const newValidateState = Object.keys(mapFormValidState).reduce((acc, key) => {
            const rule = mapFormValidState[key].rule;
            const isFieldValid = mapFormState[key] === null || rule.test(mapFormState[key])
            acc[key] = { valid: isFieldValid, rule }

            if (!isFieldValid) {
              isValid = false    
            }

           return acc 
        }, {})

        setMapFormValidState(newValidateState)

        return isValid
    };

    const closeModal = async () => {
        if (isCommunityFormChanged) {
            uiStore.openWarningModal({
                text: t`youHaveUnsavedChanges`,
                okText: t`confirmModelNo`,
                cancelText: t`confirmModelYes`,
                okAction: () => {
                    setResetForm(true)
                },
                cancelAction: async () => {
                    if (isCommunityFormChanged) {
                        await onMapSettingsSave()
                    }
                },
                onBackdropClick: () => {
                    setResetForm(true)
                }
            })
            return
        }
        if (!isCommunityFormChanged) {
            return orgsStore.setShowMapSettingsModal(false)
        }
    
        setResetForm(true)
        orgsStore.setShowMapSettingsModal(false)
    }

    useEffect(() => {
        modulesStore.getCommunityModulesAndFeatures(orgsStore.currentOrgId).then(data => {
            setResetForm(true)
        })
        
        return () => {}
    }, [orgsStore.currentOrgId])

    useEffect(() => {
        if (resetForm) {
            onResetFields()
            orgsStore.setShowMapSettingsModal(false)
            setResetForm(false)
        }
    }, [resetForm])


    useEffect(() => {
        const locationFeature = getLocationFeature()
        const locationFeatureValue = locationFeature ? locationFeature.enabled : null

        const emergencyFeature = getEmergencyFeature()
        const emergencyFeatureValue = emergencyFeature ? emergencyFeature.enabled : null

        const isFormChanged = 
        mapFormState.locationEnabled !== locationFeatureValue ||
        mapFormState.emergencyEnabled !== emergencyFeatureValue ||
        mapFormState.accuracy !== orgsStore.settings.accuracy ||
        mapFormState.enabled !== orgsStore.settings.enabled ||
        mapFormState.fromKM !== orgsStore.settings.fromKM ||
        mapFormState.toKM !== orgsStore.settings.toKM ||
        mapFormState.lat !== orgsStore.settings.lat ||
        mapFormState.lng !== orgsStore.settings.lng ||
        mapFormState.moreInformation !== orgsStore.settings.moreInformation ||
        mapFormState.zoom !== orgsStore.settings.zoom ||
        mapFormState.geoData !== orgsStore.settings.geoData
        
        setIsCommunityFormChanged(isFormChanged)
    }, [mapFormState])


    useEffect(() => {
        if (resetForm) {
            onResetFields()
            orgsStore.setShowCommunitySettingsModal(false)
            setResetForm(false)
        }
    }, [resetForm, orgsStore.currentOrgId])

    const onMapSettingsToggleEnable = (value) =>         
        setMapFormState({
        ...mapFormState,
        enabled: value == false ? null : value,
    })

    const onMapSettingsEmergencyToggleEnable = (value) =>         
    setMapFormState({
      ...mapFormState,
      emergencyEnabled: value,
    })

    const onMapSettingsLocationToggleEnable = (value) =>         
    setMapFormState({
      ...mapFormState,
      locationEnabled: value,
    })

    const onMapSettingsChangeAccuracy = (value) => setMapFormState({
        ...mapFormState,
        accuracy: value === '' ? null : value,
    })

    const onMapSettingsChangeFromKM = (value) => setMapFormState({
        ...mapFormState,
        fromKM: value === '' ? null : value,
    })

    const onMapSettingsChangeToKM = (value) => setMapFormState({
        ...mapFormState,
        toKM: value === '' ? null : value,
    })

    const onMapSettingsChangeLat = (value) => setMapFormState({
        ...mapFormState,
        lat: value === '' ? null : value,
    })

    const onMapSettingsChangeLng = (value) => setMapFormState({
        ...mapFormState,
        lng: value === '' ? null : value,
    })

    const onMapSettingsChangeZoom = (value) => {
        console.log(value)
        setMapFormState({
        ...mapFormState,
        zoom: value === '' ? null : value,
    })
}

    const onCommunityChangeInfo = (value) => setMapFormState({
        ...mapFormState,
        moreInformation:value === '' ? null : value,
    })

    const onCommunityChangeGeoData = (value) => setMapFormState({
        ...mapFormState,
        geoData: value === '' ? null : value,
    })

    return (
        <DialogContainer
            open={open}
            fullWidth maxWidth='lg'>
            <DialogHeader>
                <DialogTitle disableTypography>
                    <DialogHeaderText>{t`mapSettings`}</DialogHeaderText>
                    <IconButton onClick={closeModal}>
                        <Close/>
                    </IconButton>
                </DialogTitle>
            </DialogHeader>
            <DialogBody>
              <MapSection
                 mapFormState={mapFormState}
                 isMapFormChanged={isCommunityFormChanged}
                 mapFormValidState={mapFormValidState}
                 onMapSettingsSave={onMapSettingsSave}
                 onMapSettingsLocationToggleEnable={onMapSettingsLocationToggleEnable}
                 onMapSettingsEmergencyToggleEnable={onMapSettingsEmergencyToggleEnable}
                 onMapSettingsToggleEnable={onMapSettingsToggleEnable}
                 onMapSettingsChangeAccuracy={onMapSettingsChangeAccuracy}
                 onMapSettingsChangeFromKM={onMapSettingsChangeFromKM}
                 onMapSettingsChangeToKM={onMapSettingsChangeToKM}
                 onMapSettingsChangeLat={onMapSettingsChangeLat}
                 onMapSettingsChangeLng={onMapSettingsChangeLng}
                 onMapSettingsChangeZoom={onMapSettingsChangeZoom}
                 onCommunityChangeInfo={onCommunityChangeInfo}
                 onCommunityChangeGeoData={onCommunityChangeGeoData} 
              />
            </DialogBody>
        </DialogContainer>
    )
}

export default observer(MapSettingsModal)