import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { List, ListItem } from '@material-ui/core'
import { TextFields, Add, Remove, ColorLens, InvertColors, SettingsBackupRestore  } from '@material-ui/icons'
import {  Space,Text,theme } from 'ui'
import wheel from 'assets/icons/wheel.svg'


const Wrapper = styled.div`
  direction: ltr;
  position: fixed;
  left: ${p => p.open ? 0 : -200}px;
  bottom: 36px;
  z-index: 9999;
  display: flex;
  transition: all 0.3s ease-in-out;
  align-items: flex-end;
`

const Menu = styled.div`
  width:200px;
  padding: 0.3rem;
  background: #dedede;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  background-color:${theme.color.whiteBackground};

`

const Button = styled.div`
  background: #6969dd;
  padding: 8px;
  height: 36px;
  img {
    color: white;
  }
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);

`

const ListI = styled(ListItem)`
  display: flex;
  justify-content: space-between;
`

// document.body.style.position = 'fixed';

function makeGray() {
  const f = document.body.style.filter
  if (f === 'grayscale(1)') {
    document.body.style.filter = ''
  } else {
    document.body.style.filter = 'grayscale(1)'
  }
}

function makeInvert() {
  const f = document.body.style.filter
  if (f === 'invert(1)') {
    document.body.style.filter = ''
  } else {
    document.body.style.filter = 'invert(1)'
  }
}

function makeBigger() {
  const fz = document.body.parentNode.style.fontSize
  console.log('--- Accessibility.tsx -> makeBigger -> fz', fz)
  switch (fz) {
    case '':
    case 'calc(12px + 0.25vw)':
      return document.body.parentNode.style.fontSize = 'calc(16px + 0.5vw)'
    default:
      return document.body.parentNode.style.fontSize = 'calc(12px + 0.25vw)'
  }
}

function makeLesser() {
  const fz = document.body.parentNode.style.fontSize
  switch (fz) {
    case '':
    case 'calc(12px + 0.25vw)':
      return document.body.parentNode.style.fontSize = 'calc(10px + 0.1vw)'
    default:
      return document.body.parentNode.style.fontSize = 'calc(12px + 0.25vw)'
  }
}

function revert() {
  document.body.style.filter = ''
  document.body.parentNode.style.fontSize = 'calc(12px + .25vw)'
}

const Accessibility: FC = () => {
  const [ open, setOpen ] = useState(false)
  
  return (
    <Wrapper open={open}>
      <Menu>
        <List>
          <ListI onClick={makeBigger} role='button'>הגדל פונט <Add style={{marginLeft:10}} /></ListI>
          <ListI onClick={makeLesser} role='button'>הקטן פונט <Remove style={{marginLeft:10}}  /></ListI>
          <ListI onClick={makeGray} role='button'>שחור לבן <ColorLens style={{marginLeft:10}}  /></ListI>
          <ListI onClick={makeInvert} role='button'>ניגודיות צבעים<Space /> <InvertColors style={{marginLeft:10}}  /></ListI>
          <ListI onClick={revert} role='button'>בטל <SettingsBackupRestore style={{marginLeft:10}}  /></ListI>
        </List>
      </Menu>
      <Button onClick={() => setOpen(prev => !prev)}>
        <img src={wheel} width='18' />
      </Button>
    </Wrapper>
  )
}

export default Accessibility
