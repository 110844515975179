import React, { FC } from 'react'
import styled from 'styled-components'
import type { ModuleType } from 'utils/types'
import ThirdListItem from "./ThirdListItem";

const Container = styled.div`
    display:flex;
    flex:1;
    flex-direction:column;
    width:60vw;
    align-self:center;
`

interface props {
    list: Array<ModuleType>
}
const ThirdList: FC<props> = ({ list }) => {
    return (
        <Container>
            {list.map((item: ModuleType) => (
                <ThirdListItem item={item} />
            ))}
        </Container>
    )
}

export default ThirdList
