import React, { FC } from 'react'
import styled from 'styled-components'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import useStore from 'store'
import { useTranslation } from 'react-i18next'

import Page from 'core/Page'
import { Row, Space, theme, Text } from 'ui'
import { Button, ButtonProps } from '@material-ui/core'

import logo from 'assets/logo_with_dots.png'
import TotzeretLogo from 'assets/TotzeretSubLogoColored.png'
import { useHistory } from 'react-router'
import { Path } from 'core/Routes'
import { Redirect } from "react-router-dom"
import { WHITE_LABLE_APP_NAME, TOTZERET_NAME } from 'service/whiteLable'

const renderLogoByBrand = () => {
  switch (WHITE_LABLE_APP_NAME) {
    case TOTZERET_NAME:
      return <img src={TotzeretLogo} alt='logo totzeret' width={200} />
    default:
      return <img src={logo} alt='logo mekome' width={200} />
  }
}

const StyledPage = styled(Page)`
  background-color:${theme.color.loginBackground};
  height:100%;
  width:100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`

const StyledButton = styled(Button) <ButtonProps>`
  height:50px;
  background-color: ${theme.color.cerulean};
  border-radius: 5px;
  opacity:${p => p.opacity ? p.opacity : null};
  color:white;
  padding: 0 1rem;
  :hover {
    background-color: ${theme.color.cerulean};
  }
`

const StyledButtonOpac = styled(Button) <ButtonProps>`
  background-color: transparent;
  border-radius: 5px;
  opacity:${p => p.opacity ? p.opacity : null};
  color: #0d7fdf;
  :hover {
    background-color: transparent;
  }
`

const Name = styled.div`
  padding: 0.5rem;
  display: flex;
  font-size: 1.6rem;
`

const Dot = styled.div`
  font-size: 1.6rem;
  :last-child {
    display: none;
  }
`

const SRow = styled(Row)`
  max-width: 80vw;
  flex-wrap: wrap;
`

const Confirm: FC = () => {
  const { user, orgs } = useStore()
  const { t } = useTranslation('auth')
  const h = useHistory()

  const handleTerms = () => {
    if (user.user.eulaSigned) {
      user.setIsAuth(true)
    } else {
      h.push(Path.TERMS)
    }
  }

  if (!user.phone && !user.email) return <Redirect to={Path.LOGIN} />

  const communitiesNames = user.communitiesNames.slice(0, 10)

  return (
    <StyledPage>
      {renderLogoByBrand()}
      <Space />
      <div>
        {t`invited_to`}
      </div>
      <SRow>
        {communitiesNames.map(o => <><Name>{o}</Name><Dot>·</Dot></>)}
      </SRow>
      <Space />
      <StyledButton onClick={handleTerms} autoFocus>
        {t`enter`}
      </StyledButton>
      <Space />
      <StyledButtonOpac onClick={user.logout}>
        {t`back`}
      </StyledButtonOpac>
    </StyledPage>
  )
}

export default observer(Confirm)
