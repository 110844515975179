import React, { FC } from 'react'
import { StatusType } from 'utils/types'
import type { UserMessage } from 'utils/models'
import PulseLoader from "react-spinners/PulseLoader";
import styled from 'styled-components'
import { Icon, theme, Text, Space, Row } from 'ui'
import { useTranslation } from 'react-i18next'
import { css } from "@emotion/react";
import useStore from 'store'
import { Error } from '@material-ui/icons'
import { Button as MuiButton } from '@material-ui/core'

const SendingContainer = styled.div`
 flex-direction:row;
 display:flex;
  align-items:center;
  justify-content:center;
  background-color: ${theme.color.sendingBlue};
  border-radius:20px;
  /* align-self:center; */
  padding:2px 10px 2px 10px;
  margin:0 5px;
  width:fit-content;
  margin-bottom:10px;
  align-self: center;

`
const SendAgainContainer = styled.div`
 flex-direction:row;
 display:flex;
  align-items:center;
  justify-content:space-between;
  /* background-color: ${theme.color.white}; */
  border-radius:20px;
  /* align-self:center; */
  padding:4px 15px 4px 15px;
  margin:0 5px;
  width:100%;
  margin-bottom:10px;

`
const SignIcon = styled(Error)`
color:${theme.color.dangerRed};
`
const CustomButton = styled(MuiButton)`
  border-radius: 9px;
  background-color: ${theme.color.dangerRed};
  /* color: ${p => p.transparent ? theme.color.black : theme.color.white}; */
  /* margin-left: 5px;
  body[dir=rtl] & {
      margin-left: 0px;
      margin-right: 5px;
    } */
    padding:5px 20px;
`
interface Props {
    status: StatusType
    owner: boolean
    userMessage: UserMessage
}
const override = css`
  display: block;
  margin: 0;
`;
const MesageStatus: FC<Props> = ({ status, owner, userMessage }) => {
    const { t } = useTranslation('messageItem')
    const { newMessage } = useStore()

    const sendAgain = () => {
        newMessage.sendMessageAgain(userMessage)
    }

    if (status === StatusType.OUTGOING && owner) {
        return (
            <SendingContainer>
                <Text
                    textcolor={theme.color.white}
                    size={'sm'}
                    weight={'normal'}
                >{t('sending')}</Text>
                <Space width={1} />
                <PulseLoader
                    // width={20}
                    css={override}
                    size={10}
                    color={theme.color.white}
                    loading={true}
                />
            </SendingContainer>
        )
    }
    if (status === StatusType.FAILED) {
        return <SendAgainContainer>
            <Row>
                <SignIcon />
                <Space width={1} />
                <Text
                    textcolor={theme.color.dangerRed}
                    size={'sm'}
                    weight={'normal'}
                >{t('send_message_failed')}</Text>
            </Row>
            <CustomButton onClick={() => sendAgain()}>
                <Text
                    textcolor={theme.color.white}
                    size={'sm'}
                    weight={'normal'}
                >{t('send_again')}</Text>
            </CustomButton>
        </SendAgainContainer>
    }
    return <div />
}

export default MesageStatus
