import React from 'react'
import { Icon, Row, Space, Text, theme } from 'ui'
import useStore from 'store'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ChevronLeft } from '@material-ui/icons'
import Grow from 'ui/Grow'
import { Card, IconButton } from '@material-ui/core'
import Money from './Money'
import { useHistory } from 'react-router'
import { isAfter } from 'date-fns'
import { Path } from 'core/Routes'
import Logout from '@material-ui/icons/ExitToApp';
import { BudgetButton, Editor } from './SlikaModule'
import { canEditCommunityModules } from 'utils/roles'

const Container = styled.div`
    display:flex;
    flex:1;
    flex-direction:column;
    align-items:stretch;
    max-width:60vw;
    /* background-color:${theme.color.red}; */
`
const Wrap = styled.div`
    display:flex;
    flex:1;
    justify-content:center;
`
const StyledSummeryContainer = styled(Card)`
  display:flex;
  flex-direction:column;
  margin:10px;
  border-radius: 0.8rem;
  overflow:hidden;
  background-color:${theme.color.white};
`
const StyledButtonTopPart = styled.div`
  position:relative;
  cursor:pointer;
  display:flex;
  flex-direction:column;
  background-color:${theme.color.budgetPurple};
  padding:12px;
  flex-direction:row;
`
const StyledIconContainer = styled.div<{ backgroundColor: string }>`
  display:flex;
  background-color:${p => p.backgroundColor};
  padding-right:8px;
  align-items:center;
  flex-direction:row;
`
const StyledTopSummeryTop = styled.div`
  display:flex;  
  flex-direction:row;
`
const StyledSeperator = styled.div`
  width:1px;
  background-color:${theme.color.white};
  margin:0 32px;
`
const StyledMoneyContainer = styled.div`
  display:flex;
  flex-direction:column;
  flex:3;
  justify-content:space-between;
`
const StyledChevronLeft = styled(ChevronLeft)`
  align-self:center;
  background-color:${theme.color.footPurple};
  /* border-radius:50%; */
  color:${theme.color.white};
  align-items:center;
  text-align:center;
  display:flex;
  font-size:40px;
`
const StyledTopSummeryBottom = styled.div`
  display:flex;
  flex-direction:column;
  background-color:${theme.color.white};
  padding:8px;
  border-bottom-left-radius:10px;
  border-bottom-right-radius:10px;
`
const StyledLogoutButtonText = styled.div`
  display:flex;
  cursor:pointer;
  flex-direction:row;
  padding:0 12px;
`
const BudgetItem: React.FC = () => {
  const { budgetStore, orgs: orgsStore } = useStore()
  const h = useHistory()
  const [showActionArrow, setShowActionArrow] = React.useState(false)
  const onEnter = () => setShowActionArrow(true)
  const onLeave = () => setShowActionArrow(false)
  const totalCredits = budgetStore.credit.reduce((a, b) => (a - Number(b.totalSum)), 0)
  const totalDebits = budgetStore.debit.reduce((a, b) => (a + Number(b.totalSum)), 0)
  const canEdit = canEditCommunityModules()
  const handleLeavePress = () => {
    budgetStore.forget()
  }
  const { t } = useTranslation('budget')

  const moveToBudgetPress = () => {
    // refresh budget if time past
    if (isAfter(new Date(), budgetStore.authDates.get(orgsStore.currentOrgId || 0) || 0)) {
      budgetStore.init()
    }
    h.push(Path.USER_BUDGET)
    // navigation.navigate(Screen.Budget)
  }
  let year = new Date().getFullYear()
  let month = new Date().getMonth()
  if (!!budgetStore.availablePeriods.length) {
    const periodSelected = budgetStore.availablePeriods[budgetStore.selectedPeriodIndex]
    year = periodSelected?.[0]
    month = periodSelected?.[1] - 1
  }
  const showPosSummary = () => {
    if (!budgetStore.posList.length) {
      return false
    }
    if (budgetStore.isCurrentPeriodSelected()) {
      return true
    }
    return !budgetStore.budgetExist
  }
  return (
    <Wrap>
      <Container>
        <StyledSummeryContainer >
          {budgetStore.isDisabled ? (
            <BudgetButton
              onClick={moveToBudgetPress}
              canEdit={canEdit}
              textcolor={theme.color.black}
              backgroundColor={theme.color.blockedPurple}
              headerText={t`summery.budget_currently_unavailable`}
              headerSize='xl'
              headerWeight='bold'
              footerText={t`summery.budget_when_available`}
              footerSize='md'
              footerWeight='normal'
              isEmbedded={true}
            />) : (
            <StyledButtonTopPart
              onClick={moveToBudgetPress}
              onMouseEnter={onEnter}
              onMouseLeave={onLeave}
            >
              {canEdit && showActionArrow && <div style={{ position: 'absolute', left: 4, top: 4 }}><Editor /></div>}
              <StyledIconContainer backgroundColor=''>
                <Icon size={64 + 32} namespace='groupIcons' name={'pension'} />
              </StyledIconContainer>
              <div style={{ display: 'flex', flex: 1, flexDirection: 'column', padding: 8 }}>
                <Row>
                  {showPosSummary() && <Text size='xl' weight='bold' textcolor={theme.color.white}>{`${t('calendar:config.monthNames', { returnObjects: true })[month]} ${year} - ${t`summery.pos`}`}</Text>}
                  {!showPosSummary() && <Text size='xl' weight='bold' textcolor={theme.color.white}>{`${t('calendar:config.monthNames', { returnObjects: true })[month]} ${year} - ${t`summery.budget`}`}</Text>}
                </Row>
                {/* <Space height={1} /> */}
                <Grow />
                <StyledTopSummeryTop>
                  <Text
                    style={{ alignSelf: 'center' }}
                    textcolor={theme.color.white60}
                    size='lg'
                  >{budgetStore.isCurrentPeriodSelected() ? t`summery.current_status` : t`summery.monthly_status`}</Text>
                  <StyledSeperator />
                  {/* <Space width={1}/> */}
                  <StyledMoneyContainer>
                    {showPosSummary() &&
                      <Money
                        amount={budgetStore.posAmount}
                        adjustSizeByAmount
                        showCurrency={true}
                        dontShowAgorot
                      >
                        {totalCredits}
                      </Money>
                    }
                    {!showPosSummary() &&
                      <Money
                        amount={budgetStore.budgetAmount}
                        adjustSizeByAmount
                        showCurrency={true}
                        dontShowAgorot
                      >
                        {totalCredits}
                      </Money>
                    }
                  </StyledMoneyContainer>
                </StyledTopSummeryTop>
              </div>
              <Row>
                <IconButton>
                  <ChevronLeft style={{ color: theme.color.white, fontSize: 8 * 5 }} />
                </IconButton>
              </Row>
            </StyledButtonTopPart>
          )}
          {/* bottom logout button thing: */}
          <StyledTopSummeryBottom>
            <StyledLogoutButtonText onClick={handleLeavePress}>
              <Logout style={{ color: theme.color.budgetPurple }} />
              <Space width={1} />
              <Text
                size='md'
                weight='bold'
                textcolor={theme.color.budgetPurple}
              >{t('summery.logout')}</Text>
            </StyledLogoutButtonText>
          </StyledTopSummeryBottom>
        </StyledSummeryContainer>
      </Container>
    </Wrap>
  )
}

export default observer(BudgetItem)