import React, { FC } from 'react'
import styled from 'styled-components'
import { Button as MuiButton, ButtonProps } from '@material-ui/core'

const StyledButton = styled(MuiButton)<ButtonProps>`
  padding-top: 7px;
  padding-bottom: 7px;
  background: var(--button);
  color: white;
  .MuiButton-startIcon {
    body[dir=rtl] & {
      margin-left: 8px;
      margin-right: -4px;
    }
  }
`

const Button: FC<ButtonProps> = ({ ...props }) => <StyledButton variant='contained' {...props} />

export default Button
