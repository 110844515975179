import React, { FC, useState } from 'react'
import { RadioButtonUnchecked, CheckCircle } from '@material-ui/icons'
import { Text, theme } from 'ui'
import styled from 'styled-components'
import useStore  from 'store'

const Container = styled.div<{ editMode?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0.5rem 0;
  cursor: ${p => p.editMode ? 'pointer' : 'auto'};
  align-items:center;
justify-content:center;
:hover {
    cursor: pointer;
  }
`

const TextContainer = styled.div<{ selected?: boolean,mainColor:string }>`
  background-color: ${p => p.selected ? p.mainColor : theme.color.grayBackground};
  width: 100%;
  border-radius: 18px;
  padding:0.4rem;
  margin: 0 0 0 0.5rem;
  body[dir=rtl] & {
  margin: 0 0.5rem 0 0;
  }
  overflow-x:scroll;
`

const CheckIcon = styled(CheckCircle)<{mainColor:string}>`
  color: ${p => p.mainColor };

`
const UnCheckIcon = styled(RadioButtonUnchecked)<{mainColor:string}>`
  color: ${p => p.mainColor };
`


interface SurveyAnswerProps {
  answer?: { color: number, id: string, text: string }
  disabled: boolean
  handlePressSelection: any
  selected:boolean
}


const SurveyMessageItem: FC<SurveyAnswerProps> = ({answer, disabled,handlePressSelection,selected}) => {
  const { groups } = useStore()
  const mainColor = groups?.currentGroup?.group.description?.color ||   '#707070'
  const iconColor = groups?.currentGroup?.group.description?.iconColor || 'gray'

  const onClick = () => {
    handlePressSelection()
  }

  return (
    <Container
  
      onClick={disabled ? () => { } : onClick}>
      {selected ? <CheckIcon mainColor={mainColor} fontSize={'large'} /> : <UnCheckIcon mainColor={mainColor} fontSize={'large'} />}
      <TextContainer mainColor={mainColor} selected={selected}>
        <Text
          style={{paddingInlineStart:8}}
          size='md'
          weight='normal'
          textcolor={selected ? theme.color.white : theme.color.black}
        >{answer?.text}</Text>
      </TextContainer>
    </Container >
  )
}

export default SurveyMessageItem
