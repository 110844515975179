import React from 'react'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import { ThemeProvider } from 'styled-components'
import { StylesProvider } from '@material-ui/styles'
import { BrowserRouter as Router } from 'react-router-dom'

import ErrorBoundary from './ErrorBoundary'
import Routes from './Routes'
import GlobalStyles from './GlobalStyles'

import he from '../locale/he.json'
import en from '../locale/enUS.json'

import vls from '../utils/vls'

import { theme } from 'ui'
import ConfirmDialogProvider from 'core/ConfirmDialog'
import RTL from './RTL'
import { SnackbarProvider } from 'notistack';
import Notifier from './Notifier'
import { storageKeys } from 'utils/vls'

const LANG = storageKeys.LANG
i18next
  .use(initReactI18next)
  .init({
    resources: {
      en: en,
      he: he
    },
    fallbackLng: ['en', 'he'],
    lng: vls.get(LANG) || 'he',
    interpolation: { escapeValue: false }
  })

document.body.dir = i18next.dir()

i18next.on('languageChanged', lng => {
  vls.set(LANG, lng)
  const body = document.body
  body.dir = i18next.dir()
})

function App() {
  return (
    <RTL>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <StylesProvider injectFirst>
            <GlobalStyles />
            <ConfirmDialogProvider>
              <SnackbarProvider maxSnack={3}>
                <Notifier />
                <Router>
                  <Routes />
                </Router>
              </SnackbarProvider>
            </ConfirmDialogProvider>
          </StylesProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </RTL>
  )
}

export default App
