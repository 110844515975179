import { ExportToCsv } from './export-to-csv'
import type { MessageResponse, User } from 'utils/models'
import type { TFunction } from 'i18next'
import { AppType, UserMessage } from 'utils/models'
import { formatIfPhoneNumber } from 'utils/converts'

import { members } from 'store'
import { DeliveryType } from 'components/Messages/MessagesDialog'
import { deliveryType, StatusType } from './types'
import { extractMessagePayload } from './messageUtils'

export const saveMessageLocationDetailsCsv = (users: any[], title: string, t: TFunction) => {
  let tableHeaders = [
    t`firstName`,
    t`lastName`,
    t`phone1`,
    t`phone2`,
    t`email`,
    t`status`, 
    t`location`, 
    t`date`,
  ]

  let data = users.map(user => {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      phone1: user.phone1,
      phone2: user.phone2,
      email: user.email,
      messageStatus: statusToString(user.messageStatus, t), 
      location: user.location, 
      date: user.date,
    }
  })
 
  let currentDate = new Date().toLocaleDateString()
  const options = {
    fieldSeparator: ',',
    quoteStrings: '',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    filename: title,
    title: `${t`reportDate`} ${currentDate}`,
    useTextFile: false,
    useBom: true,
    headers: tableHeaders
  }
  const csvExporter = new ExportToCsv(options)
  csvExporter.generateCsv(data)
}

export const saveCsv = (users: User[], title: string, t: TFunction, empty?: boolean | undefined) => {
  let tableHeaders = [
    t`firstName`,
    t`lastName`,
    t`nickName`,
    t`israeliID`,
    t`phone1`,
    t`phone2`,
    t`email`,
    t`isUsingApp`
  ]

  let data = users.map(user => {
   // console.log(user)
    return {
      firstName: user.given_name,
      lastName: user.family_name,
      nickName: user.nick_name,
      israeliID: user.israeliID !== null ? user.israeliID : '',
      phone1: user.phones != null && user.phones.length > 0 ? formatIfPhoneNumber(user.phones[0]) : '',
      phone2: user.phones != null && user.phones.length > 1 ? formatIfPhoneNumber(user.phones[1]) : '',
      email: user.emails != null && user.emails.length > 0 ? user.emails[0] : '',
      isUsingApp: user.app == AppType.IOS || user.app == AppType.ANDROID ? "True" : "False"
    }
  })
  if (empty) {
    tableHeaders = [
      t`firstName`,
      t`lastName`,
      t`nickName`,
      t`israeliID`,
      t`phone1`,
      t`phone2`,
      t`email`,
    ]
    data = users.map(user => {
      //delete user.isUsingApp
      // user.isUsingApp = false;
      return user
    })
  }
  let currentDate = new Date().toLocaleDateString()
  const options = {
    fieldSeparator: ',',
    quoteStrings: '',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    filename: title,
    title: `${t`reportDate`} ${currentDate}`,
    useTextFile: false,
    useBom: true,
    headers: tableHeaders
  }
  const csvExporter = new ExportToCsv(options)
  csvExporter.generateCsv(data)
}

export const saveCsvImportUserListErrors = (data: any[], t: TFunction) => {
  let tableHeaders = [
    t`firstName`,
    t`lastName`,
    t`nickName`,
    t`israeliID`,
    t`phone1`,
    t`phone2`,
    t`email`,
    `error`,
  ]
 
  const options = {
    fieldSeparator: ',',
    quoteStrings: '',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    filename: 'import_errors',
    title: '',
    useTextFile: false,
    useBom: true,
    headers: tableHeaders
  }

  const csvExporter = new ExportToCsv(options)
  csvExporter.generateCsv(data)
}

export const saveCsvIsraeliIdErrors = (data: any[], t: TFunction) => {
  let tableHeaders = [
    t`firstName`,
    t`lastName`,
    t`nickName`,
    t`israeliID`,
    t`phone1`,
    t`phone2`,
    t`email`,
    `error`,
  ]
 
  const options = {
    fieldSeparator: ',',
    quoteStrings: '',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    filename: 'import_errors',
    title: '',
    useTextFile: false,
    useBom: true,
    headers: tableHeaders
  }

  const csvExporter = new ExportToCsv(options)
  csvExporter.generateCsv(data.map(item => {
    return {
      firstName: '',
      lastName: '',
      nickName: '',
      israeliID: item.israeliID,
      phone1: item.phone,
      phone2: '',
      email: item.email,
      error: item.error,
    }
  }))
}

type Question = {
  text: string
  color: string
}

type Answer = {
  user: number,
  response: number,
  updated: string,
}
export type Responses = {
  [a in StatusType]: MessageResponse[]
}
const statusToString = (type: StatusType, t: TFunction) => {
  // "messageDetails": "פרטי ההודעה",
  // "read": "קראו",
  // "recived": "קיבלו",
  // "send": "נשלחו",
  // "ongoing": "בשליחה",
  // "failed": "שליחה נכשלה",
  // "num_friends_in_group": "מספר חברים בקבוצה",
  // "answer_the_survey": "סה”כ ענו על הסקר:",
  // "friends": "חברים",
  // "export": "ייצוא סקר לקובץ"
  switch (type) {
    case StatusType.FAILED:
      return t('messageDetails:failed')
    case StatusType.MUTED:
      return t('messageDetails:muted')
    case StatusType.OUTGOING:
      return t('messageDetails:outgoing')
    case StatusType.READ:
      return t('messageDetails:read')
    case StatusType.RECEIVED:
      return t('messageDetails:recived')
    case StatusType.RESPONSED:
      return t('messageDetails:responded')
    case StatusType.SENT:
      return t('messageDetails:send')
    default:
      return type
  }
}
export const saveMessageDetailsCsv = (responses: Responses, userMessage: UserMessage, title: string, t: TFunction) => {
  const flatResponses: MessageResponse[] = Object.values(responses).flat()
  const answers = responses[StatusType.RESPONSED]
  const isSurvey = userMessage?.message?.msg_strategy?.startsWith('QS')
  const payload = extractMessagePayload(userMessage.message)
  // const payload = JSON.parse(userMessage.message.payload)
  const questions: Question[] = payload?.survey?.answers || []
  const questionForTitle = isSurvey ? payload.survey?.question?.split(' ').join('_') : payload.text
  const newTitle = `${title}_${questionForTitle.slice(0, 10)}${questionForTitle.length > 10 ? "..." : ""}`

  const tableHeaders = [
    t`lastName`,
    t`firstName`,
    t`phone1`,
    t`phone2`,
    t`email`,
    t`csvHeaders:status`
  ]
  if (isSurvey) {
    questions.forEach((question: Question) => {
      tableHeaders.push(question.text)
    })
  }
  tableHeaders.push(t`date`)

  const answersIndexCounters: number[] = Array(questions.length).fill(0)
  const optionsDate = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric',
    hour12: false,
  }
  const data = flatResponses.map(answer => {
    const userId = answer.user_ref
    const user = members.getById(userId)
    if (user) {
      answersIndexCounters[answer.response - 1]++
      let d = [user.family_name, user.given_name, user.phones != null && user.phones.length > 0 ? formatIfPhoneNumber(user.phones[0]) : '', user.phones != null && user.phones.length > 1 ? formatIfPhoneNumber(user.phones[1]) : '', user.emails != null && user.emails.length > 0 ? user.emails[0] : '', statusToString(answer.status, t)]
      if (isSurvey) {
        questions.forEach((question: Question, i: number) => {
          {
            d.push(answer.response == i + 1 ? '1' : '0')
          }
        })
      }
      d.push(new Intl.DateTimeFormat('en-GB', optionsDate).format(new Date(answer.updated)))
      return d
    }
  })

  let lastLine = [t`total`, flatResponses.length?.toString(), '', '', '', '']

  questions.forEach((qustion: Question, index: number) => {
    lastLine.push(answersIndexCounters[index].toString())
  })
  let currentDate = new Intl.DateTimeFormat('en-GB', optionsDate).format(new Date())
  lastLine.push(currentDate)

  data.push(lastLine)
  const options = {
    fieldSeparator: ',',
    quoteStrings: '',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    filename: newTitle,
    title: `${t`reportDate`} ${currentDate}`,
    useTextFile: false,
    useBom: true,
    headers: tableHeaders
  }

  const csvExporter = new ExportToCsv(options)
  csvExporter.generateCsv(data.filter(i => Boolean(i)))
}