import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import debounce from 'lodash.debounce';
import { ExpandMore, Send } from "@material-ui/icons";
import {Accordion, AccordionDetails, AccordionSummary, Menu as MuiMenu, MenuItem } from "@material-ui/core";
import useStore from "../../../store";
import { Row, Text, theme} from "../../../ui";
import { ModuleType } from "../../../utils/types";
import { canEditCommunityModules } from "../../../utils/roles";
import LinkText from "../../../components/Messages/LinkText";

const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

const AccordionContainer = styled.div`
  margin: 15px 0;
`

const CustomAccordion = styled(Accordion)`
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 ${theme.color.shadow};
  .MuiTypography-body1{
    margin-left: 15px;
    margin-right: 15px;
  }
  &:before {
      height: 0px;
    }
  .MuiIconButton-root {
      padding:0;
  }
  .MuiAccordionSummary-expandIcon{
    margin: auto;
  }
`
const AccordionControls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`

const SideComponentMenu = styled(Row)`
  justify-content:flex-end;
  position:absolute;
  left:0;
  width:70px;
  height:20px;
  background-color:${theme.color.white};
  cursor:pointer;
`

const StyledSendButton = styled.div`
  background: ${theme.color.white};
  display: flex;
  justify-Content: center;
  align-items: center;
  padding:10px 5px;
  border-radius: 15px;
  width: max-content;
  border: 1px solid black;
  margin: 20px auto 0;
  cursor: pointer;
`

const ButtonContainer = styled.div<{ bgColor: string }>`
  border-radius:22px;
  color:${theme.color.white};
  background-color:${p => p.bgColor};
  display:flex;
  align-items:center;
  justify-content:center;
  width:90px;
  height:20px;
`

const StyledText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 !important;
`

const IconExpandWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const StyledSendComplete = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
`

const IconExpandMore = styled.div<{ bgColor: string, isOpen: boolean }>`
  background-color:${p => p.bgColor};
  transform: ${p => p.isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-right: 5px;
`

const StyledLinkText = styled.div`
  &>p{
    white-space: pre-wrap;
  }
`
const ThirdListItem = ({ item }: {  item: ModuleType }) => {
  const { modulesStore, orgs } = useStore();
  const { t } = useTranslation(['budget']);
  const userCanEditCommunityModules = canEditCommunityModules();

  const [open, setOpen] = useState(false);
  const [infoList, setInfoList] = useState<Array<string>>([]);
  const [showActionArrow, setShowActionArrow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [isSend, setIsSend] = useState(false);

  const handleSendClick = useCallback(debounce( async () => {
    await modulesStore.sendClickModule({ moduleId: item.module_id, communityId: orgs.currentOrgId || 0 })
  }, 500), []);

  const handleExpand = () => {
    setOpen(s => !s);

    if (!open) {
      handleSendClick();
    }
    if (isSend) {
      setIsSend(false);
    }
  }

  const openActionMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }

  const openModuleModal = () => {
    modulesStore.openModuleModalEditMode(item.module_id);
    setShowActionArrow(false)
    handleClose();
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleSendShareInfo = async () => {
    setIsSend(prev => !prev);
    await modulesStore.shareInformation({ moduleId: item.module_id, communityId: orgs.currentOrgId || 0 });
  };

  useEffect(() => {
    if (item?.information) {
      const data = JSON.parse(item.information);
      const infoListData: Array<string> = Object.values(data);

      setInfoList(infoListData);
    }
  }, [item]);

  return (
    <AccordionContainer>
      <CustomAccordion
        expanded={open}
      >
        <AccordionSummary>
          <div
            style={{ flex: 1 }}
            onClick={() => !item.share && openInNewTab(item.external_url)}
          >
            <Text
              textcolor={theme.color.black + 'A1'}
              weight={'bold'}
              size={'xl'}
            >
              {item.title}
            </Text>
            { !isSend ? (
              <Text
                textcolor={theme.color.black + 80}
                weight={'normal'}
                size={'md'}
              >
                {item.description}
              </Text>
            ) : (
              <StyledSendComplete>
                <Text weight={'heavy'} style={{marginBottom: '5px'}}>הבקשה שלך נשלחה</Text>
                <Text style={{marginBottom: '25px'}}>{item.title} יצרו איתך קשר בהקדם </Text>
                <Text>תודה על הפנייה. ✓</Text>
              </StyledSendComplete>
            )}
          </div>
          <AccordionControls>
            <div
              onMouseEnter={() => setShowActionArrow(true)}
              onMouseLeave={() => setShowActionArrow(false)}
            >
              { userCanEditCommunityModules && showActionArrow ? (
                <div style={{ position: 'relative', width: 90 }}>
                  <>
                    <SideComponentMenu
                      onClick={openActionMenu}
                      aria-controls="third-menu"
                    >
                      <ExpandMore />
                    </SideComponentMenu>
                    <MuiMenu
                      id="third-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={openModuleModal}>
                        {t`edit`}
                      </MenuItem>
                    </MuiMenu>
                  </>
                </div> ) : (
                <ButtonContainer
                  bgColor={item.icon_color}
                >
                  <StyledText
                    textcolor={theme.color.white}
                    weight={'bold'}
                    size={'sm'}
                  >
                    {item.icon_text}
                  </StyledText>
                </ButtonContainer>
              )
              }
            </div>
            { item.share && (
              <IconExpandWrapper>
                <span> { open ? 'סגירה' : 'פירוט' }</span>
                <IconExpandMore
                  bgColor={item.icon_color}
                  isOpen={open}
                  onClick={handleExpand}
                >
                  <ExpandMore />
                </IconExpandMore>
              </IconExpandWrapper>
            )}
          </AccordionControls>
        </AccordionSummary>
        { !isSend && <AccordionDetails
          style={{ flexDirection: 'column', paddingRight: 30, borderTop: '1px solid gray' }}
        >
          {
            infoList.map(text => (
              <StyledLinkText>
                <LinkText messageText={text} />
              </StyledLinkText>
            ))
          }
          <StyledSendButton onClick={handleSendShareInfo}>
            <div> נשמע מעניין, צרו איתי קשר </div>
            <Send style={{transform: 'rotate(180deg)', marginRight: '10px'}}/>
          </StyledSendButton>
        </AccordionDetails>}
      </CustomAccordion>
    </AccordionContainer>
  )
}

export default ThirdListItem;
