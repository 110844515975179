import React, { FC } from 'react'
import { observer } from 'mobx-react'
import useStore from 'store'
import Group from './Group'
import { UserGroup } from 'utils/types'

interface Props {
  groups: UserGroup[]
}

const Groups: FC<Props> = ({ groups }) => {
  return (
    <>
      {groups.map(g => <Group key={g.group.id} {...g.group} />)}
    </>
  )
}

export default observer(Groups)
