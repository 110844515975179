import React, { FC, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { TextField, TableCell, TableRow, Button } from '@material-ui/core'
import { Save, Close } from '@material-ui/icons'
import styled from 'styled-components'
import api from 'service/api'
import useStore from 'store'

const Actions = styled.div`
  display:flex;
  flex-direction: row;
`
const StyledButton = styled(Button)`
  padding: 2px 4px;
  min-width: 10px;
`
const StyledTableCell = styled(TableCell)`
  direction: ltr;
`

const UserSectionNewItem: FC = ({ entityId, onClose, onRefresh }) => {
    const {t} = useTranslation('communitySettings')
    const [error, setError] = useState(null)
    const [userFormState, setUserFormState] = useState({
        name: '',
        email: '',
        phone: ''
    })

    const onCreateEvent = async () => {
        const res = await api.createEntityUser(entityId, {
            name: userFormState.name,
            emails: userFormState.email.trim() != '' ? [userFormState.email.trim()] : null,
            phones: userFormState.phone.trim() != '' ? [userFormState.phone.trim()] : null,
        })

        if (res !== null && typeof (res) === 'object' && res?.resErr && res?.error){
          setError(res.error)
        } else {
          setError(null)
          onRefresh(entityId)
          onClose()
        }
      }

      const onCloseEvent = () => {
        setError(null)
        setUserFormState({ 
          name: '',
          email: '',
          phone: ''
        })

        onClose();
      }

      const isDisabled = () => {
        return userFormState.name.trim() == '' || (userFormState.email == '' && userFormState.phone == '')
      }
   
    return (
      <>
      {error && 
        <TableRow>
            <TableCell colSpan={4} align="center"><span style={{color: 'red'}}>{error}</span></TableCell>
        </TableRow>
      }

      <TableRow>
          <TableCell> 
          <TextField
            id={'user_name'}
            required
            label={t`name`}
            variant="outlined"
            value={userFormState.name}
            onChange={({target}) => {
              setUserFormState({ ...userFormState, name: target.value })
            }}
          />
        </TableCell>   
        <StyledTableCell component="th" scope="row">
            <TextField
              id={'user_phone'}
              required
              label={t`phone`}
              variant="outlined"
              value={userFormState.phone}
              onChange={({target}) => {
                setUserFormState({ ...userFormState, phone: target.value })
              }}
            />
        </StyledTableCell>
        <TableCell>
          <TextField
            id={'user_email'}
            required
            label={t`email`}
            variant="outlined"
            value={userFormState.email}
            onChange={({target}) => {
              setUserFormState({ ...userFormState, email: target.value })
            }}
          />
        </TableCell> 
        <TableCell width={100}>
          <Actions>
          <StyledButton onClick={onCloseEvent}><Close/></StyledButton>
          <StyledButton disabled={isDisabled()} onClick={onCreateEvent}><Save/></StyledButton>
          </Actions>
        </TableCell>
      </TableRow>  
      </>
    )
}

export default observer(UserSectionNewItem)