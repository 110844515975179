import { Redirect, Route, Switch } from 'react-router'
import { Path } from 'core/Routes'
import Messages from 'pages/Home/Messages'
import Contacts from 'pages/Home/Contacts'
import Budget from 'pages/Home/Budget'
import ServiceCall from 'pages/Home/ServiceCall'
import CommunityEmptyPage from 'pages/Home/Community/CommunityEmptyPage'
import isMobile from 'utils/isMobile'
import Groups from 'pages/Home/Groups'
import Map from 'pages/Home/Map'
import styled from 'styled-components'
import UserBudget from '../Budget/UserBudget'
import useStore from 'store'
import { FeatureIds } from 'utils/types'
import { observer } from 'mobx-react'
import { canShowMapTab } from 'utils/roles'

const Content = styled.div`
  display:flex;
  flex:1;
  margin: 0 !important;
  background: white;
  border-radius: 5px;
`

const Wrap = styled.div`
  flex: 1;
  overflow:auto;
  display: flex;
`

const HomeRoutes = observer(() => {
  const { modulesStore, user } = useStore();
  const { communityFeatures } = modulesStore;

  const routesData = [
    { patch: Path.GROUPS, component: Groups, featureId: FeatureIds.MESSAGING, enabled: true },
    { patch: Path.MESSAGES, component: Messages, featureId: FeatureIds.MESSAGING, enabled: true },
    { patch: Path.CONTACTS, component: Contacts, featureId: FeatureIds.CONTACTS, enabled: true },
    { patch: Path.USER_BUDGET, component: UserBudget, featureId: FeatureIds.BUDGET, enabled: true },
    { patch: Path.BUDGET, component: Budget, featureId: FeatureIds.BUDGET, enabled: true },
    { patch: Path.SERVICECALL, component: ServiceCall, featureId: FeatureIds.SERVICE, enabled: true },
    { patch: Path.COMMUNITY, component: CommunityEmptyPage, featureId: FeatureIds.PORTAL, enabled: true },
  ];

  if (canShowMapTab()) {
    routesData.push({ patch: Path.MAP, component: Map, featureId: FeatureIds.MAP, enabled: true })
  }

  const enableRoutes = () => {
    if (!communityFeatures.length) {
      return routesData;
    }
    return routesData.map((feature) => {
      if (feature.featureId === FeatureIds.MAP) {
        return { ...feature, enabled: true }
      }

      const findedFeature = communityFeatures.find((communityFeature) => feature.featureId === communityFeature.id);
      if (!findedFeature) {
        return feature;
      }
      return { ...feature, enabled: findedFeature.enabled };
    })
  }

  return (
    <Switch>
      {enableRoutes().map((routeData) => (
        routeData.enabled ? <Route path={routeData.patch} component={routeData.component} /> : <Redirect exact from={routeData.patch} to={Path.MESSAGES} />
      ))}
      <Redirect exact from={Path.HOME} to={Path.MESSAGES} />
      {!isMobile() && <Redirect exact from={Path.GROUPS} to={Path.MESSAGES} />}
    </Switch>
  )
})

export default HomeRoutes
