import { observable, action, reaction, computed, ObservableMap } from 'mobx'
import orgs from 'store/orgs.store'
import modulesStore from 'store/modulesStore.store'
import api from 'service/api'
import type { ID } from 'utils/types'
import type { User, } from 'utils/models'
import userStore from './user.store'
import { LOADERS } from 'utils/loaders'
class MembersStore {

  constructor() {
    reaction(
      () => orgs.currentOrgId,
      id => id && this.getMembers(id),
      { fireImmediately: true }
    )
  }

  @observable loadingQueue: Set<string> = new Set<string>()

  @computed
  get isLoading() {
    return this.loadingQueue.size > 0
  }

  @action
  setLoading = (loading: boolean, id: string | LOADERS) => {
    loading ? this.loadingQueue.add(id) : this.loadingQueue.delete(id)
  }

  @observable _data: ObservableMap<number, User> = observable(new Map())
  @observable loadingMembers: boolean = false

  @observable currentMemberId: number | undefined = undefined
  @action setCurrentMemberId = (state: number | undefined) => this.currentMemberId = state

  @computed get data(): Map<number, User> {
    // return toJS(this._data) //BUGGED
    return this._data
  }
  @computed get currentMember() {
    if (this.currentMemberId) {
      return this.getById(this.currentMemberId) || { given_name: '', family_name: '', nick_name: '', phones: [], emails: [] }
    }
    return { given_name: '', family_name: '', nick_name: '', phones: [], emails: [] }
  }
  @computed get orgMembers() {
    return Array.from(this._data.values())
  }

  @computed get orgMembersSorted() {
    return Array.from(this._data.values()).sort((a, b) => !a.family_name ? 1 : (!b.family_name ? -1 : (a.family_name.localeCompare(b.family_name, 'he', { sensitivity: 'base' }))))
  }

  // @observable isMembersModalOpen = false
  // @action closeMembersModal = () => this.isMembersModalOpen = false
  // @action openMembersModal = () => this.isMembersModalOpen = true

  @action getMembers = async (id: ID) => {
    this.loadingMembers = true
    const res = await api.getCommunitiesUsers(id)
    //TODO: REMOVE FOREACH AFTER SERVER RETURNS ALL KEYS PROPERLY
    const membersMap = new Map<number, User>()
    res.forEach((el: User) => {
      membersMap.set(el.user_id, el)
      if (el.user_id === userStore.user.user_id) {
        userStore.setUser(Object.assign(userStore.user, el))
      }
    })
    orgs.getProviders(id)
    this._data.replace(membersMap)
    this.loadingMembers = false

  }

  @action changeManagerState = async (id: number, isManager: boolean) => {
    const userToUpdate = this.getById(id)
    if (userToUpdate && orgs.currentOrgId) {
      await api.updateUserCommunity(userToUpdate, orgs.currentOrgId, !isManager)
      // let userIndex = this.data.findIndex(dataUser => id === dataUser.user_id)
      // this.data[userIndex].is_director = !isManager
      // this.data[userIndex].is_operator = !isManager
      let user = this._data.get(id);
      if (user) {
        user.is_director = !isManager
        user.is_operator = !isManager
      }
    }
  }

  @action getOrgManagersCount = () => {
    return Array.from(this.data.values()).filter((member: User) => {
      return member.is_operator || member.is_director
    }).length
  }

  removeFromCommunity = async (id: ID) => {
    const data = {
      user: id,
      id: 1,
      community: orgs.currentOrgId as number,
    }
    let res
    res = await api.deleteUserCommunity(data)
    if ((!res?.status || res.status < 400) && !res?.errors?.length) {
      this.removeLocalUser(id)
    } else {
      return res
    }
  }

  removeCache = async (id?: ID) => {
    const communityId = orgs.currentOrgId

    const info = modulesStore.getBudgets().find(el => el.community_ref === communityId)
    if(info && communityId) {
      await api.resetModuleData(info.module_id, communityId, id)
    }
  }

  getById = (id: ID): User | undefined => {
    return this.data.get(id)
  }



  @action updateLocalUser(user: User) {
    if (user.user_id === userStore.user?.user_id) {
      userStore.setUser({ ...userStore.user, ...user }) // update current user locally , and overwrite existing data
    }

    this._data.set(user.user_id, user)
  }
  @action updateMyUserIsShowInAlphone(isShowInAlphone: boolean) {
    let userIndex = this._data.get(userStore.user?.user_id)
    if (userIndex) {
      userIndex.is_showInAlphon = isShowInAlphone
    }
  }
  @action private removeLocalUser(id: ID) {
    this._data.delete(id)
  }
}

const members = new MembersStore()
export default members
