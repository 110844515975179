import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Image, theme, Text } from 'ui'
import useStore from 'store'
import { observer } from 'mobx-react'
import {
    BrowserRouter as Router,
    Switch,
    useLocation
} from "react-router-dom";
import { Path } from 'core/Routes'
import { canEditCommunityModules } from 'utils/roles'
import { ExpandMore, Edit } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import vls, { storageKeys } from 'utils/vls'
import { validURL } from 'utils/validations'


const StyledIframe = styled.iframe`
    border:none;
`
const SideComponentMenu = styled.div`
    justify-content:center;
    align-items:center;
    display:flex;
    z-index:2000px;
    position:absolute;
    border-radius:12px;
    left:0;
    width:30px;
    height:30px;
    background-color:${theme.color.white};
    cursor:pointer;
`

const ItemText = styled(Text)`
    width:100%;
    padding:5px;
    align-items:center;
    justify-content:center;
    display:flex;
    :hover {
        background-color:${theme.color.black + '1A'} !important;
    }
`
const MenuContainer = styled.div`
    position:absolute;
    justify-content:center;
    align-items:center;
    display:flex;
    background-color:white;
    width:80px;
    height:80px;
    top:10px;
    left:10px;
    border-radius:12px;
`
const IframePortal: FC<{ type: string }> = ({ type }) => {
    const { modulesStore, orgs } = useStore()
    const portal = modulesStore?.communityModules.filter(i => i.type === type)
    let location = useLocation();
    const [show, setShow] = useState(false)
    useEffect(() => {
        if (location.pathname === (type === 'support' ? Path.SERVICECALL : Path.COMMUNITY)) {
            setShow(true)
        } else {
            setShow(false)
        }
    }, [location])
    const { t } = useTranslation(['budget'])
    const [showEdit, setShowEdit] = React.useState<boolean>(false)

    const userCanEditCommunityModules = canEditCommunityModules() && type !== 'portal'

    const openModuleModal = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        event.preventDefault()
        modulesStore.openModuleEditPortal(type)
        setShowEdit(false)
    }
    const onEnter = () => { setShowEdit(true) }
    const onLeave = () => { setShowEdit(false) }
    const onLoad = () => {
        // if (orgs.currentOrgId && location.pathname === Path.COMMUNITY) {
        //     const tokenCommunity = vls.get(storageKeys.tokenCommunity) || {}
        //     tokenCommunity[orgs.currentOrgId] = true
        //     vls.set(storageKeys.tokenCommunity, tokenCommunity)
        // }
    }
    return (
        <div style={{ width: show && '100%' || 0, height: show ? '100%' : 0 }}>
            {userCanEditCommunityModules && <div style={{ position: 'relative', top: 10, left: 10 }}>
                {show && <div>
                    <SideComponentMenu
                        onMouseEnter={onEnter}
                        onMouseLeave={onLeave}
                    ><Edit />
                        {showEdit && <MenuContainer>
                            <ItemText
                                textcolor={theme.color.black + 80}
                                weight={'medium'}
                                size={'md'}
                                onClick={openModuleModal}>
                                {t`edit`}
                            </ItemText>
                        </MenuContainer>}
                    </SideComponentMenu>
                </div>}
            </div>}
            <StyledIframe
                style={{ width: '100%', height: '100%', order: 0 }}
                src={validURL(portal[0]?.external_url || '') ? (portal[0]?.external_url || '') : ''}
                title="kibutz service"
                onLoad={onLoad}
            />
        </div>
    )
}


export default observer(IframePortal)