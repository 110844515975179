import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'

import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, InputAdornment } from '@material-ui/core'
import { Search as SearchIcon, ExpandMore } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { theme } from 'ui'
import useStore from 'store'
import isMobile from 'utils/isMobile'
import { UserCommunity } from 'utils/types'
import { isTotzeret } from 'service/whiteLable'

interface Props {
  options: { name: string, id: string }[]
}

type Option = { name: string, id: number, brandId: number }

const StyledAutocomplete = styled(Autocomplete)`
  .MuiOutlinedInput-notchedOutline{
    border: 0px;
  }
`

const Disabled = styled.span`
  margin: 0px 5px;
  color: red;
  font-weight: 500;
  background: white;
  padding: 4px 9px 1px 9px;
  opacity: 0.5;
  font-size: 12px;
  border-radius: 10px;
`

const Wrap = styled.div`
  //body[dir=rtl] .MuiOutlinedInput-adornedStart {
  //  padding-right: 14px !important;
  //}
  min-width: ${isMobile() ? 100 : 270}px;
  .MuiInputAdornment-positionStart {
    color: rgba(185,185,185,1);
  }
  .MuiAutocomplete-endAdornment {
    .MuiIconButton-root {
      color: rgba(185,185,185,1);
    }
  }
  .MuiInputBase-input {
    color: ${isTotzeret ? '#394248' : 'white'};
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-top: 0;
    padding-bottom: 0;
  }
  .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-right: 0;
  }
`

const StyledTextField = styled(TextField)`
  background: #646464;
  border-radius: 5em;
  color: ${isTotzeret ? '#394248' : 'white'} !important;
  body[dir=rtl] & {
    margin-right: unset;
    margin-left: 35px;
  }
  `

const Search: FC<Props> = ({ ...props }) => {
  const { orgs } = useStore()
  const { t } = useTranslation('app')
  const [value, setValue] = useState<Option | null>(null)

  const options: Option[] = orgs.data.filter(i => i.is_enabled === true).map(org => ({ name: org.name, id: org.id, brandId: org.brandId })).sort((a, b) => {
    return a.name.localeCompare(b.name)
  })
  const currIndex = options.findIndex(o => Number(o.id) === Number(orgs.currentOrgId))

  const onChange = (r: any, v: any) => orgs.handleSearchUpdate(v.id, v.brandId)

    useEffect(() => {
    if (orgs.currentOrgId) setValue(options[currIndex] as Option)
  }, [orgs.currentOrgId, orgs.currentOrg])
  return (
    <Wrap>
      <StyledAutocomplete
        onChange={onChange}
        options={options}
        getOptionLabel={(o) => o?.name || ''}
        openOnFocus
        selectOnFocus
        getOptionSelected={(option, value) => option?.id === value?.id}
        disableClearable
        value={value}
        renderInput={p => (
          <StyledTextField
            {...p}
            variant='outlined'
            style={isTotzeret ? { backgroundColor: '#f4f4f2', color: '#394248' } : {}}
            placeholder={t`search`}
            InputProps={{
              ...p.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={isTotzeret ? { color: '#394248' } : {}} />
                  {orgs.currentOrg.status === 0 && (value !== null && p.inputProps.value == value.name) && <Disabled>לא פעיל</Disabled>}
                </InputAdornment>
              )
            }}
          />
        )}
      // {...props}
      />
    </Wrap>
  )
}

export default observer(Search)
