import { observable, action, ObservableMap, computed, IObservableArray, toJS } from 'mobx'
import api from 'service/api'
import type { User, ServerResponseUser } from 'utils/models'
import { ID, StrategyType, StatusType, Group, UserGroup } from 'utils/types'
import membersStore from 'store/members.store'
import groupsStore from './groups.store'
import orgsStore from './orgs.store'
import { newGuid } from 'utils/generates'
import { parsePhoneNumberFromString, isValidNumber } from 'libphonenumber-js'
import { isNumber } from 'utils/converts'
import { formatIfPhoneNumber } from 'utils/converts'
import deepClone from 'lodash/cloneDeep'
import { OperationType, DeletePushOp } from 'utils/operation'
import { errorType } from 'utils/models'


export enum memberErrorsType {
    GivenName,
    FamilyName,
    NickName,
    phoneNumber1,
    phoneNumber2,
    email,
    israeliID
}
interface serverErrorType {
    key: string,
    description: string,
    content?: { value: string },
    userId?: number
}
class MemberStore {

    constructor() {

    }

    emptyUser: User = {
        user_id: -1,
        given_name: '',
        family_name: '',
        phones: [],
        emails: [],
        nick_name: '',
        israeliID: '',
        is_showInAlphon: true,
        is_director: false,
        is_operator: false,
        communities: [],
        device_details: '',
        fullName: '',
        displayName: '',
        is_community_enabled: false,
        sendSMS: true
    }

    @observable loadingGroups: boolean = false
    @observable loadingRequest: boolean = false
    @observable formChange: boolean = false

    @observable createEditUserModalState: boolean = false

    @observable duplicatedModalState: boolean = false

    @observable duplicatedUser: ServerResponseUser | null = null

    @observable private _currentMember: User | null = null

    @observable errors: ObservableMap<memberErrorsType, serverErrorType> = observable(new Map())

    @observable addedUserGroups: IObservableArray<UserGroup> = observable([])
    @observable removeUserGroups: IObservableArray<UserGroup> = observable([])
    @observable currentUserGroups: IObservableArray<UserGroup> | undefined = observable([])


    get getCurrentMemebr() {
        return toJS(this._currentMember)
    }

    @action setIsraeliID(value: string) {
        if (!this.formChange) {
            this.formChange = true
        }
        if (this.errors.get(memberErrorsType.israeliID)) {
            this.errors.delete(memberErrorsType.israeliID)
        }
        if (this._currentMember) {
            this._currentMember.israeliID = value
        }
    }

    @action setMemberName(value: string) {
        if (!this.formChange) {
            this.formChange = true
        }
        if (this.errors.get(memberErrorsType.GivenName)) {
            this.errors.delete(memberErrorsType.GivenName)
        }
        if (this._currentMember) {
            this._currentMember.given_name = value
        }
    }

    @action setMemberFamilyName(value: string) {
        if (!this.formChange) {
            this.formChange = true
        }
        if (this.errors.get(memberErrorsType.FamilyName)) {
            this.errors.delete(memberErrorsType.FamilyName)
        }
        if (this._currentMember) {
            this._currentMember.family_name = value
        }
    }

    @action setMemberNickName(value: string) {
        if (!this.formChange) {
            this.formChange = true
        }
        if (this.errors.get(memberErrorsType.NickName)) {
            this.errors.delete(memberErrorsType.NickName)
        }
        if (this._currentMember) {
            this._currentMember.nick_name = value
        }
    }
    @action toggleUserAlphone() {
        if (!this.formChange) {
            this.formChange = true
        }
        if (this._currentMember) {
            this._currentMember.is_showInAlphon = !this._currentMember.is_showInAlphon
        }
    }
    @action toggleSendEmail() {
        if (!this.formChange) {
            this.formChange = true
        }
        if (this._currentMember) {
            this._currentMember.sendEmail = !this._currentMember.sendEmail
        }
    }
    @action toggleSendSms() {
        if (!this.formChange) {
            this.formChange = true
        }
        if (this._currentMember) {
            this._currentMember.sendSMS = !this._currentMember.sendSMS
        }
    }

    @action setPhoneNumber1(value: string) {
        if (!this.formChange) {
            this.formChange = true
        } if (this.errors.get(memberErrorsType.phoneNumber1)) {
            if (this.errors.get(memberErrorsType.phoneNumber1)?.key === errorType.EmptyPushTokens) {
                this.errors.delete(memberErrorsType.phoneNumber2)
                this.errors.delete(memberErrorsType.email)
            }
            this.errors.delete(memberErrorsType.phoneNumber1)
        }

        if (this._currentMember) {
            if (this._currentMember.phones.length === 0) {
                this._currentMember.phones.push(value)
            } else {
                this._currentMember.phones[0] = value
            }
        }
    }

    @action setError(type: memberErrorsType, key: errorType, description: string) {
        this.errors.set(type, {
            key: key,
            description: description,
        })
    }

    @action setPhoneNumber2(value: string) {
        if (!this.formChange) {
            this.formChange = true
        }
        if (this.errors.get(memberErrorsType.phoneNumber2)) {
            if (this.errors.get(memberErrorsType.phoneNumber2)?.key === errorType.EmptyPushTokens) {
                this.errors.delete(memberErrorsType.phoneNumber1)
                this.errors.delete(memberErrorsType.email)
            }
            this.errors.delete(memberErrorsType.phoneNumber2)
        }
        if (this._currentMember) {
            if (this._currentMember.phones.length === 0) {
                this._currentMember.phones.push('')
                this._currentMember.phones.push(value)
            } else {
                if (this._currentMember.phones.length === 1) {
                    this._currentMember.phones.push(value)
                } else {
                    this._currentMember.phones[1] = value
                }
            }
        }
    }

    @action setEmail(value: string) {
        if (!this.formChange) {
            this.formChange = true
        }
        if (this.errors.get(memberErrorsType.email)) {
            if (this.errors.get(memberErrorsType.email)?.key === errorType.EmptyPushTokens) {
                this.errors.delete(memberErrorsType.phoneNumber1)
                this.errors.delete(memberErrorsType.phoneNumber2)
            }
            this.errors.delete(memberErrorsType.email)
        }
        if (this._currentMember) {
            if (this._currentMember.emails.length === 0) {
                this._currentMember.emails.push(value)
            } else {
                this._currentMember.emails[0] = value

            }
        }

    }

    @action parseErrorMessages = async (errorMessages: Array<serverErrorType>, responseUser: ServerResponseUser | undefined) => {
        const newUser = this._currentMember?.user_id === -1
        let displayName: string = ''
        let user: User | undefined = undefined
        if (responseUser) {
            displayName = `${responseUser?.family_name} ${responseUser?.nick_name ? ' (' + responseUser.nick_name + ') ' : ''} ${responseUser?.given_name}`
            user = membersStore.getById(responseUser?.userID || 0)

        }
        errorMessages.map((errMsg: serverErrorType) => {
            if (errMsg.userId) {
                const user = membersStore.getById(errMsg.userId)
                if (user) {
                    displayName = `${user?.family_name} ${user?.nick_name ? ' (' + user.nick_name + ') ' : ''} ${user?.given_name}`
                }
            }
            if (errMsg.key == errorType.PhoneNumberAlreadyExists) {

                const formatNationalErrorPhone = parsePhoneNumberFromString(errMsg.content.value)?.formatNational().replaceAll('-', '')

                if (this._currentMember?.phones && this._currentMember?.phones[0].replaceAll('-', '').localeCompare(formatNationalErrorPhone || errMsg.content.value) === 0) {
                    if (newUser && responseUser && !user) {
                        this.duplicatedUser = responseUser
                        this.openDuplicatedModal()
                    }
                    this.errors.set(memberErrorsType.phoneNumber1, { ...errMsg, description: `מספר זה שייך במערכת ל ${displayName} ולכן אין אפשרות לשמור אותו. אפשר לנסות מספר אחר.` })

                }
                if (this._currentMember?.phones[1] && this._currentMember?.phones[1].replaceAll('-', '').localeCompare(formatNationalErrorPhone || errMsg.content.value) === 0) {
                    if (newUser && responseUser && !user) {
                        this.duplicatedUser = responseUser
                        this.openDuplicatedModal()
                    }
                    this.errors.set(memberErrorsType.phoneNumber2, { ...errMsg, description: `מספר זה שייך במערכת ל ${displayName} ולכן אין אפשרות לשמור אותו. אפשר לנסות מספר אחר.` })

                }
            }
            if (errMsg.key == errorType.EmailAlreadyExists) {
                if (newUser && responseUser && !user) {
                    this.duplicatedUser = responseUser
                    this.openDuplicatedModal()
                }
                this.errors.set(memberErrorsType.email, { ...errMsg, description: `  אימייל זה שייך במערכת ל${displayName} ולכן אין אפשרות לשמור אותו. אפשר לנסות אימייל אחר` })
                // this.errors.set(memberErrorsType.email, errMsg.description)
            }
            if (errMsg.key == errorType.InvalidEmailAddress) {
                this.errors.set(memberErrorsType.email, errMsg)
            }
            if (errMsg.key == errorType.InvalidIsraeliID) {
                this.errors.set(memberErrorsType.israeliID, errMsg)
            }
            if (errMsg.key == errorType.InvalidPhoneNumber) {
                const formatNationalErrorPhone = parsePhoneNumberFromString(errMsg.content.value)?.formatNational().replaceAll('-', '')
                if (this._currentMember?.phones && this._currentMember?.phones[0].localeCompare(formatNationalErrorPhone || errMsg.content.value) === 0) {
                    this.errors.set(memberErrorsType.phoneNumber1, errMsg)
                }
                if (this._currentMember?.phones[1] && this._currentMember?.phones[1].localeCompare(formatNationalErrorPhone || errMsg.content.value) === 0) {
                    this.errors.set(memberErrorsType.phoneNumber2, errMsg)
                }
            }
            if (errMsg.key == errorType.EmptyPushTokens) {
                this.errors.set(memberErrorsType.phoneNumber1, errMsg)
                this.errors.set(memberErrorsType.phoneNumber2, errMsg)
                this.errors.set(memberErrorsType.email, errMsg)

            }
            // if (errMsg.key == 'NickNameIsMandatory') {
            //     this.errors.set(memberErrorsType.NickName, errMsg.description)
            // }
            // if (errMsg.key == 'FamilyNameIsMandatory') {
            //     this.errors.set(memberErrorsType.FamilyName, errMsg.description)
            // }
        })
    }

    @action createUpdateUser = async (showInAlphonChanged: boolean = true) => {
        this.loadingRequest = true
        if (!this._currentMember) { return }
        let response
        const isUpdate = this._currentMember.user_id > 0;
        if (this.duplicatedUser) {
            response = await api.updateUserCommunity(this._currentMember, orgsStore.currentOrgId || 0, false)
        } else {
            if (isUpdate) {
                response = await api.updateUser(orgsStore.currentOrgId || 0, this._currentMember)
            } else {
                response = await api.createUser(this._currentMember, orgsStore.currentOrgId || 0)
            }
        }
        if (showInAlphonChanged) {
            api.updateUserAlphon(orgsStore.currentOrgId || 0, this._currentMember.is_showInAlphon, this._currentMember.user_id)
        }
        if (response?.status && response.status >= 400) {
            try {
                const errorMessages = response?.data?.errorMessages?.map((e: string) => JSON.parse(e))
                if (errorMessages?.length > 0) {
                    this.parseErrorMessages(errorMessages, response?.data?.users?.[0])
                }
            } finally {
                this.loadingRequest = false
                return
            }
          }

        const newUserId = response;

        if (isNumber(newUserId)) {
            this.updateGroupsByUserId(newUserId)
        }
        this.updateUserGroup()

        if (isNumber(newUserId)) {
            this._currentMember.user_id = newUserId
        }
        membersStore.updateLocalUser(this._currentMember)
        this.loadingRequest = false
        this.closeCreateEditModal()
    }

    @action updateUserGroup = async () => {
        const arrayOfChanges = [...this.removeUserGroups, ...this.addedUserGroups]

        if (arrayOfChanges.length) {
            return await api.updateUserGroups(arrayOfChanges, true)
        }
    }

    @action removeGroup = async (userGroup: UserGroup) => {
        const localStoreGroup = this.currentUserGroups?.find(g => g.group.id === userGroup.group.id)
        if (localStoreGroup) {
            const exsitnigMember = localStoreGroup.is_member
            localStoreGroup.is_member = false
            localStoreGroup.is_organizer = false
            const addedIndex = this.addedUserGroups.findIndex(g => g.group.id === userGroup.group.id)
            if (addedIndex != -1) {
                this.addedUserGroups.splice(addedIndex, 1)
            } else {
                const removedGroup = this.removeUserGroups.find(g => g.group.id === userGroup.group.id)
                if (!removedGroup) {
                    this.removeUserGroups.push(localStoreGroup)

                }
            }
        }

    }

    @action addGroup = async (userGroup: UserGroup) => {
        const localStoreGroup = this.currentUserGroups?.find(g => g.group.id === userGroup.group.id)
        if (localStoreGroup) {
            localStoreGroup.is_member = true
            localStoreGroup.is_organizer = false

            const removedIndex = this.removeUserGroups.findIndex(g => g.group.id === userGroup.group.id)
            if (removedIndex != -1) {
                this.removeUserGroups.splice(removedIndex, 1)
            } else {
                const addedIndex = this.addedUserGroups.find(g => g.group.id === userGroup.group.id)
                if (!addedIndex) {
                    this.addedUserGroups.push(localStoreGroup)
                }
            }
        }
    }

    @action createNewUser = () => {
        this.loadingGroups = true
        this._currentMember = this.emptyUser
        this._currentMember.guid = newGuid()
        this.currentUserGroups?.replace(groupsStore.currentUserGroups.map((userGroup: UserGroup) => {
            userGroup.is_favourite = userGroup.group.group_is_favourite
            userGroup.is_member = userGroup.group.group_is_member
            userGroup.is_subscriber = userGroup.group.group_is_subscriber
            userGroup.is_sender = userGroup.group.group_is_sender
            userGroup.is_organizer = false
            userGroup.user = this.emptyUser.user_id
            return userGroup
        }))
        this.removeUserGroups.splice(0, this.removeUserGroups.length)
        this.addedUserGroups.splice(0, this.addedUserGroups.length)
        this.loadingGroups = false
    }

    @action editUser = async (id: number) => {
        this.createEditUserModalState = true
        const user = membersStore.getById(id)
        if (user) {
            this.loadingGroups = true
            this._currentMember = deepClone(user)
            this._currentMember.phones = this._currentMember.phones.map(p => formatIfPhoneNumber(p))

            const userGroups = await api.getGroups(orgsStore.currentOrgId || 0, id)
            const userGroupsMap: Map<number, UserGroup> = new Map()
            userGroups.forEach((userGroup: UserGroup) => {
                userGroupsMap.set(userGroup.group.id, userGroup)
            });

            const noneExistingGroups = toJS(groupsStore.currentUserGroups.filter(storeGroup => {
                return !userGroupsMap.get(storeGroup.group.id)
            }))
            const localStoreGroups = noneExistingGroups.map((userGroup: UserGroup) => {
                userGroup.is_favourite = userGroup.group.group_is_favourite
                userGroup.is_member = false
                userGroup.is_subscriber = userGroup.group.group_is_subscriber
                userGroup.is_sender = userGroup.group.group_is_sender
                userGroup.is_organizer = false
                userGroup.user = user.user_id
                return userGroup
            })

            // this.currentUserGroups = [...userGroups, ...localStoreGroups].sort((a, b) => !a.group.name ? 1 : (!b.group?.name ? -1 : (a.group?.name.localeCompare(b.group?.name, 'he', { sensitivity: 'base' }))))
            if (this.currentUserGroups) {
                this.currentUserGroups.replace([...userGroups, ...localStoreGroups])

            }
            this.loadingGroups = false

        }

    }

    @action importDuplicatedUser = async () => {
        if (this.duplicatedUser) {
            this.updateGroupsByUserId(this.duplicatedUser.userID)

            const newUser: User = {
                ...(deepClone(this.emptyUser)),
                ...this._currentMember,
                ...this.duplicatedUser,
                user_id: this.duplicatedUser.userID,
                phones: this.duplicatedUser.phones.map(p => formatIfPhoneNumber(p))
            }
            this._currentMember = newUser
            this.errors.clear()
            this.duplicatedModalState = false
        }

    }

    @action openDuplicatedModal() {
        this.duplicatedModalState = true
    }

    @action closeDuplicatedModal = async () => {
        this.duplicatedUser = null
        this.duplicatedModalState = false

    }

    @action openCreateModal = () => {
        this.createNewUser()
        this.createEditUserModalState = true
    }
    @action clearAll = () => {
        this._currentMember = null
        this.currentUserGroups?.splice(0, this.currentUserGroups?.length)
        this.removeUserGroups.splice(0, this.removeUserGroups.length)
        this.addedUserGroups.splice(0, this.addedUserGroups.length)
        this.createEditUserModalState = false
        this.duplicatedModalState = false
        this.duplicatedUser = null
        this.loadingGroups = false
        this.loadingRequest = false
        this.formChange = false
        this.errors.clear()
    }

    @action closeCreateEditModal = () => {
        this.clearAll()
    }

    @action updateGroupsByUserId = (userId: number) => {
        this.currentUserGroups?.map((userGroup: UserGroup) => {
            userGroup.user = userId
        })
        this.addedUserGroups?.map((userGroup: UserGroup) => {
            userGroup.user = userId
        })
        this.removeUserGroups?.map((userGroup: UserGroup) => {
            userGroup.user = userId
        })
    }

    @action deleteUserPushToken = () => {
        if (!this._currentMember) {
            return
        }
        api.deleteUserPushToken(this._currentMember.user_id)
    }
}

const memberStore = new MemberStore()
export default memberStore
