import React, { FC, useState, useEffect } from 'react'
import {  Button} from '@material-ui/core'
import SurveyMessageItem from 'components/Messages/Survey/SurveyMessageItem'
import { Text, theme, Icon, Space } from 'ui'
import styled from 'styled-components'
import useStore from 'store'
import { useTranslation } from 'react-i18next'
import { StatusType } from 'utils/types'

const SurveyFooter = styled.div<{ answered: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${p => p.answered ? theme.color.lightGrayBackground : 'transparent'};
  border-radius: 0 0 10px 10px;
  padding: 0 0.5rem;
  border-top: 1px solid ${theme.color.lightGrayBackground};

`
const Container = styled.div`
  display: flex;
  flex: 1;
flex-direction:column;
padding:5px;

`
const ChangeAnswerButton = styled(Button)`
  color: ${theme.color.blue};
  margin: 0 0 0 0.5rem;
  body[dir=rtl] & {
  margin: 0 0.5rem 0 0;
  }

`
const HandsIcon = styled(Icon)`
  margin: 0 0 0 0.5rem;
  body[dir=rtl] & {
  margin: 0 0.5rem 0 0;
  }

`

interface SurveyAnswerProps {
    answers?: any
    userMessage:any
}


const SurveyMessageContainer: FC<SurveyAnswerProps> = ({ answers, userMessage }) => {
    const { newMessage } = useStore()
    const { t } = useTranslation('messageItem')
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined)
    const [disableSelection, setDisableSelection] = useState<boolean>(selectedIndex ? true : false)
    
  const handlePressSelection = (index: number) => {
        const response = index + 1
        userMessage.response = response
        userMessage.status = StatusType.RESPONSED
        newMessage.responseMessage(userMessage.message.id,response,StatusType.RESPONSED)
        setSelectedIndex(index)
        setDisableSelection(true)
      }
      const handlePressFix = () => {
        userMessage.response = 0
        userMessage.status = StatusType.READ
        newMessage.responseMessage(userMessage.message.id,0,StatusType.READ)
        setSelectedIndex(undefined)
        setDisableSelection(false)
      }
    
      useEffect(() => {
        if (userMessage.response) {
          setSelectedIndex(userMessage.response - 1)
          setDisableSelection(true)
        } else {
          setSelectedIndex(undefined)
          setDisableSelection(false)
        }
      }, [userMessage.response])

    return (
        <Container>
            {  answers?.map((answer: any,index:number) => <SurveyMessageItem
                disabled={disableSelection}
                key={answer.id}
                answer={answer}
                selected={index === selectedIndex}
                handlePressSelection={() => handlePressSelection(index)}
            />)}
            <SurveyFooter
                answered={disableSelection}
            >
                <div style={{flexDirection:'row',display:'flex'}}><Text size='sm' weight='normal' textcolor={theme.color.black +33}>{disableSelection ? t`surveyAnswered` : t`surveyUnanswered`}</Text>
                <Space width={1} />
                    {disableSelection && <div style={{ width: 55 }}><HandsIcon namespace='mekome' name='foldedHands' size={20} /></div>}
                </div>
                <ChangeAnswerButton disabled={!disableSelection} onClick={handlePressFix}>{t`changeAnswer`}</ChangeAnswerButton>
            </SurveyFooter>
        </Container>
    )
}

export default SurveyMessageContainer
