import React, { FC, useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react'
import styled, { keyframes } from 'styled-components'
import { useHistory } from 'react-router'
import { Tab, Tabs as MuiTabs, IconButton } from '@material-ui/core'
import { Path } from 'core/Routes'
import { useTranslation } from 'react-i18next'
import { Space, theme } from 'ui'
import vls, { storageKeys } from 'utils/vls'
import useStore from 'store'
import { useLocation } from "react-router-dom";

import contacts from 'assets/icons/contacts.svg'
import messages from 'assets/icons/messages.svg'
import budget from 'assets/icons/budgetCard.svg'
import community from 'assets/icons/community.svg'
import service from 'assets/icons/service.svg'

import isMobile from 'utils/isMobile'
import icon1 from 'assets/tabAnimation/icon_1.json'
import icon2 from 'assets/tabAnimation/icon_2.json'
import icon3 from 'assets/tabAnimation/icon_3.json'
import icon4 from 'assets/tabAnimation/icon_4.json'
import icon5 from 'assets/tabAnimation/icon_5.json'
import icon6 from 'assets/tabAnimation/icon_6.json'
import Lottie from 'react-lottie-player'
import { FeatureIds } from 'utils/types'
import { getTotzeretColorsByCategory, isTotzeret } from 'service/whiteLable'
import { canShowMapTab } from 'utils/roles'

const w = window.innerWidth

const MobileTab = styled(Tab)`
  color: ${p => isTotzeret ? '#394248' : p.selected ? theme.color.white : '#ffffffcc'} !important;
  font-weight: ${p => p.selected ? 'bold' : 'normal'};
  padding: 0;
  min-width: unset;
  .MuiTab-wrapper {
    flex-direction: column;
    margin-bottom: 1em;
    & > *:first-child {
      margin-bottom: 0 !important;
    }
    svg {
      width: 1em;
      margin-bottom: 0 !important;
    }
    img {
      width: 2em;
    }
  }
  .MuiTab-labelIcon {
    min-height: auto !important;
  }
`

const NotMobileTab = styled(Tab)`
  color: ${p => isTotzeret ? '#394248' : p.selected ? theme.color.white : '#ffffffcc'} !important;
  font-weight: ${p => p.selected ? 'bold' : 'normal'};
  ${isTotzeret ? 'font-size: 16px;' : ''}
  ${isTotzeret ? 'min-height: 100%;' : ''}
  min-width: 66px;
  .MuiTab-wrapper {
    flex-direction: row;
    svg {
      width: 2em;
      margin-bottom: 0 !important;
    }
    img {
      width: 2em;
    }
  }
  .MuiTab-labelIcon {
    min-height: auto !important;
  }
`

const TabsWrap = styled(MuiTabs)`
  background: ${isTotzeret ? 'white' : theme.color.mekomiBlack};
  ${isTotzeret ? `min-height: 100%;
  .MuiTabs-scroller {
    display: flex!important;
    justify-content: center!important;
  }` : ''}

  .MuiTabs-flexContainer {
    @media(max-width: 768px) {
      justify-content: space-between;
      width: calc(100vw - 32px);
    }
  }
  .MuiTabs-indicator {
    background-color: ${p => isTotzeret ? p.indicatorColor : theme.color.whiteBackground};
    z-index:1;
    width: 45px !important;
    height: 43px;
    transform: translateX(${isMobile() ? `${w / 100}px` : '18px'}) translateY(40px) rotate(45deg);
    border-radius: 7px;
  }
  &:after{
    height: 1px;
    background: ${theme.color.black};
  }
`

const DefaultTab = isMobile() ? MobileTab : NotMobileTab

const getDefaultTab = () => {
  if (window.location.href.endsWith(Path.COMMUNITY)) {
    return FeatureIds.PORTAL
  }
  if (window.location.href.endsWith(Path.CONTACTS)) {
    return FeatureIds.CONTACTS
  }
  if (window.location.href.endsWith(Path.MESSAGES)) {
    return FeatureIds.MESSAGING
  }
  if(window.location.href.includes(Path.BUDGET)){
    return FeatureIds.BUDGET
  }
  if(window.location.href.includes(Path.MAP)){
    return FeatureIds.MAP
  }
  if (window.location.href.endsWith(Path.SERVICECALL) || window.location.href.endsWith(Path.SERVICE_GROUPS)) {
    return FeatureIds.SERVICE
  }
  return FeatureIds.MESSAGING //Defalut is Path.MESSAGES
}

const W = 50
const H = 40

const Tabs: FC = () => {
  const { t } = useTranslation('home')
  const [tab, setTab] = useState(getDefaultTab())
  const [playAnimationTab, setPlayAnimationTab] = useState(0)
  const h = useHistory()
  const { user, groups, messages, modulesStore } = useStore()
  const l = useLocation()
  const { communityFeatures } = modulesStore;

  const handleTab = (_: any, v: FeatureIds) => {
    switch (v) {
      case FeatureIds.PORTAL:
        h.push(Path.COMMUNITY)
        break;
      case FeatureIds.CONTACTS:
        h.push(Path.CONTACTS)
        break;
      case FeatureIds.MESSAGING:
        groups.setCurrentGroupId(groups?.currentUserGroups?.[0]?.group?.id)
        // We are pushing to GROUPS and only then redirecting to MESSAGES there in routes
        h.push(isMobile() ? Path.GROUPS : Path.MESSAGES);
        break;
      case FeatureIds.BUDGET:
        h.push(Path.BUDGET)
        break;
      case FeatureIds.SERVICE:
        groups.setCurrentGroupId(groups.mcEntityGroup ? groups.mcEntityGroup.id : groups?.currentSupportGroups?.[0]?.group.id)
        h.push(isMobile() ? Path.SERVICE_GROUPS : Path.SERVICECALL)
        break;
        case FeatureIds.MAP:
          h.push(Path.MAP)
          break;  
      default:
        return;
    }
    setPlayAnimationTab(v)
  }

  useEffect(() => {
    setTab(getDefaultTab());
  }, [l.pathname])

  useEffect(() => {
    if (user.userProfileOpen) {
      user.closeUserProfile()
    }
  }, [tab])

  useEffect(() => {
    if (groups?.currentGroup?.group?.duplex === 1 && l.pathname === Path.MESSAGES) {
      h.push(Path.SERVICECALL)
    }
  }, [groups.currentGroup])
  useEffect(() => {
    if (!groups?.currentGroup?.group.parent_id && groups?.currentGroup?.group?.duplex === 0 && l.pathname === Path.SERVICECALL) {
      h.push(Path.MESSAGES)
    }
  }, [groups.currentGroup])

  const getIndicatorColor = () => {
    if (l.pathname !== '/home/messages' && l.pathname !== '/home/servicecall') {
      return theme.color.whiteBackground;
    }
    if (isTotzeret) {
      return getTotzeretColorsByCategory(groups.currentGroup?.group?.description?.category).secondary
    }
    return groups.currentGroup?.group?.description?.color || theme.color.whiteBackground
  }

  const indicatorColor = getIndicatorColor();

  const tabsData = [
    { icon: icon1, lable: t`community`, tabValue: FeatureIds.PORTAL },
    { icon: icon2, lable: t`contacts`, tabValue: FeatureIds.CONTACTS },
    { icon: icon3, lable: t`messages`, tabValue: FeatureIds.MESSAGING },
    { icon: icon4, lable: t`payments`, tabValue: FeatureIds.BUDGET },
    { icon: icon5, lable: t`serviceCall`, tabValue: FeatureIds.SERVICE },
  ];
  
  // is manager
  if (canShowMapTab()) {
    tabsData.push({ icon: icon6, lable: t`map`, tabValue: FeatureIds.MAP })
  }

  const enableTabs = () => {
    if (!communityFeatures.length) {
      return [];
    }
    return tabsData.filter((feature) => {
      if (feature.tabValue === FeatureIds.MAP) {
        return true;
      }

      const findedFeature = communityFeatures.find((communityFeature) => feature.tabValue === communityFeature.id);
      if (!findedFeature) {
        return true;
      }
      return findedFeature.enabled;
    })
  }

  const renderDefaultTab = ({tabValue, lable, icon}: {tabValue: FeatureIds, lable: string, icon: any}) => (
    <DefaultTab value={tabValue} selected={tab === tabValue} label={lable} aria-label={lable} icon={!isTotzeret ?
      <Lottie
        loop={0}
        animationData={icon}
        onComplete={() => setPlayAnimationTab(-1)}
        play={playAnimationTab === tabValue}
        style={{ width: W, height: H }}
      /> : undefined
    } />
  )

  return (
    <TabsWrap value={tab} onChange={handleTab} indicatorColor={indicatorColor} component={'nav'}>
      {enableTabs().map((tabData) => renderDefaultTab(tabData))}
    </TabsWrap>
  )
}

export default observer(Tabs)
