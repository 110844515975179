import { Redirect, Route, Switch } from 'react-router'
import { Path } from 'core/Routes'
import Messages from 'pages/Home/Messages'
import Contacts from 'pages/Home/Contacts'
import styled from 'styled-components'

import Groups from 'pages/Home/Groups'
import Budget from 'pages/Home/Budget'
import ServiceCall from 'pages/Home/ServiceCall'
import useStore from 'store'
import { FeatureIds } from 'utils/types'
import { observer } from 'mobx-react'

const Content = styled.div`
  display:flex;
  flex:1;
  margin: 0 !important;
  border-radius: 5px;
`

const Wrap = styled.div`
  flex: 1;
  overflow:auto;
  display: flex;
`

const MHomeRoutes = observer(() => {
  const { modulesStore: { communityFeatures } } = useStore();

  const routesData = [
    { patch: Path.GROUPS, component: Groups, featureId: FeatureIds.MESSAGING, enabled: true },
    { patch: Path.SERVICE_GROUPS, component: Groups, featureId: FeatureIds.SERVICE, enabled: true },
    { patch: Path.MESSAGES, component: Messages, featureId: FeatureIds.MESSAGING, enabled: true },
    { patch: Path.BUDGET, component: Budget, featureId: FeatureIds.BUDGET, enabled: true },
    { patch: Path.SERVICECALL, component: ServiceCall, featureId: FeatureIds.SERVICE, enabled: true },
    { patch: Path.CONTACTS, component: Contacts, featureId: FeatureIds.CONTACTS, enabled: true },
  ];

  const enableRoutes = () => {
    if (!communityFeatures.length) {
      return routesData;
    }
    return routesData.map((feature) => {
      const findedFeature = communityFeatures.find((communityFeature) => feature.featureId === communityFeature.id);
      if (!findedFeature) {
        return feature;
      }
      return { ...feature, enabled: findedFeature.enabled };;
    })
  }
  return (
    <Content>
      <Wrap>
        <Switch>
          {enableRoutes().map((routeData) => (
            routeData.enabled ? <Route path={routeData.patch} component={routeData.component} /> : <Redirect exact from={routeData.patch} to={Path.GROUPS} />
          ))}
          <Redirect exact from={Path.HOME} to={Path.GROUPS} />
        </Switch>
      </Wrap>
    </Content>
  )
})

export default MHomeRoutes
