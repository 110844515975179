import React, { FC, useState } from 'react'
import { Button, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { Appear, Icon, Row, Space, theme } from 'ui'
import { useTranslation } from 'react-i18next'
import { Direction } from 'ui/Appear/Appear'
import { DateTimePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import { enUS, he } from 'date-fns/locale'
import { addDays, format, isAfter, isSameDay } from 'date-fns'
import { observer } from 'mobx-react'
import useStore from 'store'
import isMobile from 'utils/isMobile'

interface MessagesDateTimePickerProps {
  value: Date | null
  onValueChanged: (date: Date) => void
  showDatePicker: boolean
  setShowDatePicker: (value: boolean) => void
  text: string
  type: 'schedule' | 'expiration'
  minDate?: Date | null
  maxDate?: Date | null
}

const CustomDateTimeContainer = styled.div`
`
const CustomDateTimeHeader = styled(Typography)`
  font-size: ${theme.fontSize.small};
  opacity: 0.5;
  color: ${theme.color.accordionBody};
  margin-bottom: 0.4rem;
`

const DatePickerStartArrow = styled(ChevronLeft)`
  body[dir='rtl'] & {
    transform: scaleX(-1)
  }
`
const DatePickerEndArrow = styled(ChevronRight)`
  body[dir='rtl'] & {
    transform: scaleX(-1)
  }
`

const DateTimeButton = styled(Button)`
  color: ${theme.color.blueText};
  margin-right: 2rem;
  body[dir='rtl'] & {
    margin-right: unset;
    margin-left: 2rem;
  }
`
const StyledDatePicker = styled(DateTimePicker)`
  background-color:green !important;
  &.MuiPickersDay-daySelected{
    background-color:green !important;
  }
`

const TP = styled.div`
  @media (max-width: 768px) {
    transform: scale(0.8);
    transform-origin: center right;
    max-width: calc(100% - 24px);
    margin-left: -30px;
  }
`

const H = styled.div`
  max-width: calc(100%);
  overflow-x: hidden;
`

const HA = styled(Appear)`
  max-width: calc(100%);
  overflow-x: hidden;
`

const MessagesDateTimePicker: FC<MessagesDateTimePickerProps> = ({ showDatePicker, setShowDatePicker, value, onValueChanged, text, type, minDate, maxDate = null }) => {
  const { t, i18n } = useTranslation('messagesDateTimeSelector')
  const { user } = useStore()
  const [showDate, setShowDate] = useState(false)
  const [showTime, setShowTime] = useState(false)
  const [localTime, setlocalTime] = useState(value)

  const currentTimeRef = React.useRef(value)
  React.useEffect(() => {
    if (localTime != value) {
      setlocalTime(value)
    }
  }, [value])
  const onShowDate = () => {
    if (!showDate || !showDatePicker) {
      setShowDatePicker(true)
      setShowDate(true)
    } else {
      setShowDate(prev => !prev)
    }
    showTime && setShowTime(false)
  }
  // TODO: when time selection is needed either use this or add views to date picker
  const onShowTime = () => {
    if (!showTime || !showDatePicker) {
      setShowDatePicker(true)
      setShowTime(true)
    } else {
      setShowTime(prev => !prev)
    }
    showDate && setShowDate(false)
  }

  const handleDateChanged = (date: Date | null) => {
    // TODO: validations time is in future etc.
    onValueChanged && onValueChanged(date)
    // if (value) {
    //   setShowDatePicker(false)
    // }
  }

  return (
    <Appear direction={Direction.down}>
      <CustomDateTimeContainer>
        <Row>
          <CustomDateTimeHeader>{text}</CustomDateTimeHeader>
        </Row>
        <Row>
          <DateTimeButton
            variant='text'
            startIcon={<Icon namespace='mekome' name='schedule' size={16} />}
            onClick={onShowDate}
          >
            {value instanceof Date && (!isSameDay(value, new Date()) || type === 'schedule') ? format(value, 'dd/MM/yyyy') : t`selectDate`}
          </DateTimeButton>
          <DateTimeButton
            variant='text'
            startIcon={<Icon namespace='mekome' name='clock' size={16} />}
            onClick={onShowTime}
          >
            {value instanceof Date && (!isSameDay(value, new Date()) || type === 'schedule') ? format(value, 'H:mm') : t`selectDate`}
          </DateTimeButton>
        </Row>
        <Row>
          {/* TODO: locale props to MuiPickersUtilsProvider */}
          <MuiPickersUtilsProvider locale={user.dateFnsLocal} utils={DateFnsUtils}>
            {/* TODO: datepicker that looks like design */}
            {showDatePicker && showDate && (
              <Appear>
                <TP>
                  <StyledDatePicker
                    autoOk
                    value={value}
                    open={showDatePicker}
                    onChange={date => handleDateChanged(date)}
                    variant='static'
                    views={['date']}
                    // disablePast
                    minDate={minDate || new Date()}
                    {...(maxDate !== null ? { maxDate } : {})}
                    hiddenLabel
                    disableToolbar
                    leftArrowIcon={<DatePickerStartArrow />}
                    rightArrowIcon={<DatePickerEndArrow />}
                    cancelLabel={''}
                    okLabel={''}
                  />
                </TP>
              </Appear>
            )}
            {showDatePicker && showTime && (
              <Appear>
                <div onMouseUp={() => {
                  if (localTime != null && currentTimeRef.current != null) {
                    handleDateChanged(currentTimeRef.current)
                  }
                }}>
                  <TP>
                    <TimePicker
                      autoOk
                      ampm={false}
                      variant="static"
                      openTo="hours"
                      value={localTime}
                      onChange={date => {
                        const newDate = new Date()
                        if (isAfter(newDate, date || 0)) {
                          setlocalTime(newDate); currentTimeRef.current = newDate

                        } else {
                          setlocalTime(date); currentTimeRef.current = date
                        }
                      }}
                    />
                  </TP>
                </div>
              </Appear>
            )}
          </MuiPickersUtilsProvider>
        </Row>
        <Row>
          {/* {!showDatePicker && (
            <DateTimeButton
              variant='text'
              startIcon={<Icon namespace='mekome' name='schedule' size={16} />}
              onClick={onShowDate}
            >
              {value instanceof Date && !isSameDay(value, new Date()) ? format(value, 'dd/MM/yyyy') : t`selectDate`}
            </DateTimeButton>
          )} */}
          {/* <DateTimeButton
            variant='text'
            startIcon={<Icon namespace='mekome' name='clock' size={16} />}
            onClick={onShowTime}
          >
            {t`selectTime`}
          </DateTimeButton> */}
        </Row>
      </CustomDateTimeContainer>
    </Appear>
  )
}

export default observer(MessagesDateTimePicker)
