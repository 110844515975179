import React, { FC, useState, useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { ListItem, ListItemIcon } from '@material-ui/core'
import useStore from 'store'
import { GroupType, UserGroup } from 'utils/types'
import { Icon, Text, theme, Row, Space } from 'ui'
import { useTranslation } from 'react-i18next'
import { isTotzeret } from 'service/whiteLable'


const StyledLastMessage = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const GroupHeaderContainer = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  flex:1;
  justify-content:space-between;
`

const Item = styled(ListItem) <{ selected: boolean, color: string }>`
    margin: 5px 0;
    border-radius: 10px;
    background-color: ${theme.color.white};
    box-shadow:${p => p.selected ? '0px 1px 1px 1px ' + p.color : 'none'};

    display:flex;
    flex-direction:row;
    justify-content:space-between;
    border-bottom: 1px solid ${theme.color.sidebarHover};

 &.MuiListItem-root.Mui-selected{
  background-color: transparent;
  border: 1px solid ${theme.color.black + 33};

 }
  &:hover{
    background-color: ${theme.color.sidebarHover};
  }
`
const IconBg = styled.div`
  height:60px;
  width:45px;
  background: ${p => isTotzeret ? 'transparent' : p.color};
  display: flex;
  align-items: ${isTotzeret ? 'center;' : 'flex-end;'}
  justify-content: center;
  padding:0.33rem 0;
  ${p => isTotzeret ? `border-left: 2px solid ${p.color}; border-radius: 0px;` : 'border-radius: 8px;'}
`
const StyledOverflowDiv = styled.div`
  overflow:hidden;
  flex:1;
`

const KehilaNetGroup: FC<any> = ({ module }) => {
  const { t } = useTranslation('sidebar')
  const { modulesStore } = useStore()

  const onClick = () => {
    modulesStore.openKehilaNetModule(
      module.module_id, 
      module.community,
      module.external_url
    );
  }
  
  return (
    <div>
      <Item
        selected={false}
        color={'#d9d9d9'}
        button
        onClick={onClick}
      >
        <Row>
          <ListItemIcon style={{ alignItems: 'center' }}>
            <IconBg
              color={module.icon_color || '#d9d9d9'}
            >
              <Icon
                size={30}
                name={module.icon_name}
              />
            </IconBg>
          </ ListItemIcon>
          <StyledOverflowDiv>
          <GroupHeaderContainer>
          <Text
            textcolor={theme.color.black}
            weight={'medium'}
            size={'lg'}
          >{module.title}
          </Text>
          </GroupHeaderContainer>
          <StyledLastMessage textcolor={theme.color.osloGray}>{module.description}</StyledLastMessage>
          </StyledOverflowDiv>
        </Row>
      </Item>
    </div>
  )
}

export default observer(KehilaNetGroup)
