import React, {FC, useState, useEffect} from 'react'
import styled from 'styled-components'
import {IconButton, DialogTitle, Dialog, DialogContent} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import {Close} from '@material-ui/icons'
import {theme, Space} from 'ui'
import {observer} from 'mobx-react'
import useStore from 'store'
import uiStore from '../../store/ui.store'
import CouncilSection from './CouncilSection'

export const DialogHeaderText = styled.p`
  font-size: ${theme.fontSize.dialogHeader};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: ${theme.color.black};
`

const DialogContainer = styled(Dialog)`
  .MuiDialog-paperFullWidth {
    border-radius: 8px;
  }
`
const DialogHeader = styled.div`
  .MuiDialogTitle-root {
    display: flex;
    height: 64px;
    background: ${theme.color.dialogBackground};
    align-items: center;
    justify-content: space-between;
    padding: 16px 36px;
  }

  .MuiIconButton-root {
    padding: 0;
  }
`
const DialogBody = styled(DialogContent)`
  background: ${theme.color.dialogBackground};
  border-top-width: ${p => p.isTop ? '1px' : '0px'};
  padding: 16px 36px;
  overflow-y: visible;
`


const CouncilSettingsModal: FC = () => {
    const {t} = useTranslation('communitySettings')
   
    const {orgs: orgsStore, modulesStore } = useStore()
    const open = orgsStore.isCouncilModalOpen

    const closeModal = async () => {
      orgsStore.setShowCouncilModalModal(false)
    }

    return (
        <DialogContainer open={open} fullWidth maxWidth='lg'>
            <DialogHeader>
                <DialogTitle disableTypography>
                    <DialogHeaderText>{`הגדרות אירגון`}</DialogHeaderText>
                    <IconButton onClick={closeModal}>
                        <Close/>
                    </IconButton>
                </DialogTitle>
            </DialogHeader>
            <DialogBody>
             <CouncilSection/>
             <Space/>
            </DialogBody>
        </DialogContainer>
    )
}

export default observer(CouncilSettingsModal)