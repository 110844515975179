import { Backdrop, CircularProgress } from '@material-ui/core'
import React, { FC } from 'react'
import styled from 'styled-components'

const LoadingBackdrop = styled(Backdrop)`
  z-index: 9999;
`

const Loading: FC = () => {
  return (
    <LoadingBackdrop open>
      <CircularProgress />
    </LoadingBackdrop>
  )
}

export default Loading
