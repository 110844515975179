import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = {
  direction: 'rtl',
  unit: 'rem',
  fontFamily: 'Source sans',
  fontSize: {
    text: '1rem',
    sub: '0.8rem',
    heading1: 'calc(1.625rem + 1.6vw)',
    heading2: 'calc(1.375rem + 1.5vw)',
    heading3: 'calc(1.25rem + 1vw)',
    dialogHeader: '20px',
    accordionHeader: '18px',
    accordionBody: '14px',
    small: '14px',
    normal: '16px',
    large: '18px',
    extraLarge: '20px',
    // TEXT component - caution when changing.
    xxs: '10px',
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    xxl: '22px',
    // ---
    verySmall: '12px'
  },
  color: {
    base: '#383855',
    text: '#333',
    primary: '#195a63',
    secondary: '#f50057',
    disable: '#cccccc',
    link: '#2460c3',
    white: '#ffffff',
    red: '#c13636',
    green: '#6a9e6b',
    back: '#E8E9E8',
    gray: 'rgba(0,0,0,0.2)',
    grey: '#808080',
    blandGray: '#c1c1c1',
    pinkPurple: '#c76ee3',
    darkGray: 'rgba(0,0,0,0.6)',
    mekomiBlack: '#272727',
    searchGray: '#e0e0e0',
    searchGrayDialog: '#D8D8E5',
    searchBlack: '#515151',
    black: '#000000',
    blueText: '#007eff',
    inputGrayBorder: '#d3d3d3',
    inputBackground: '#fdfdfd',
    dialogBackground: '#f1f3f6',
    confirmButton: '#0082ff',
    accordionHeader: '#272727',
    accordionBody: '#212121',
    select: '#2997f1',
    shadow: 'rgba(0, 0, 0, 0.17)',
    tableHover: '#e1f0fb',
    tableBorder: 'rgba(224, 224, 224, 1)',
    buttonBackground: '#e6e8f4',
    sidebarHover: '#f5f6f8',
    sideTreeOrg: '#e3e3e3',
    sideTreeGroup: '#f1f3f6',
    sideTreeGroupInfo: 'rgba(39, 39, 39, 0.5)',
    generalInputBackground: '#f5f5f5',
    blue: '#0882ff',
    lightBlue: '#b5d9ff37',
    lightBlueText: '#007eff34',
    darkBlue: '#0062c7',
    iconButton: 'rgba(0, 0, 0, 0.54)',
    messageFeedOther: '#87cefa',
    messageFeedMine: '#48d1cc',
    dangerRed: '#ff3c3c',
    dangerRedLight: '#da2d2d',
    blueButton: '#2837AA',
    whiteBackground: '#F8F9FB',
    white60: '#FFFFFF99',
    lightBlueBackground: '#DDE0F2',
    lightGrayBackground: '#E5E8F4',
    lightGrey: '#efefef',
    grayBackground: '#e7e7eb',
    backgroundGray: '#f1f3f6',
    darkGreen: '#009682',
    osloGray: '#8F8E95',
    azureBlue: '#007EFF',
    sendingBlue: '#7896d2',
    mercury: '#E4E5E5',
    loginBackground: '#E8E9EF',
    cerulean: '#0279de',
    codeInfoText: "#535353",
    sendAgainBlue: "#188aff",
    blueTwo: '#1b67de',
    CommunityYellow: '#ffd63e',
    HalfSpaniceWhite: '#FFF7DA',
    Glitter: '#E9EBF6',
    serviceCallMessageBackLightGreen: '#E3F2A9',
    budgetPink: '#e3c2f0',
    budgetPurple: '#473868',
    defaultThemeColorGray: '#707070',
    budgetBlue: '#097ddf',
    loginPurple: '#6d5990',
    footPurple: '#493768',
    blockedPurple: '#9494ed',
    budgetRed: '#ff6666',
    budgetGreen: '#77ce7e',
    budgetDarkGreen: '#6dca75',
    budgetLightGreen: '#d4f7cc',
    budgetLightRed: '#ff8383',
    budgetDarkRed: '#ff6666',
    darkerGray: '#8f8e95',
    defaultThemeColorLightGray: '#d9d9d9',
    surveyBlue:'#1a90ff',
    entityBlue: '#2f3ba6',
    entityBodyBlue: '#e3e5f3',
    entityRed: '#d91d13'
  },
  fonts: {

  },
  space(...args: any[]) {
    const result = (args.length ? [...args] : [1]).reduce((str, curr) => `${str} ${curr}rem`, '')
    return result
  },
  css: {
    centered: 'position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);',
    transition: 'all 300ms ease-in-out;',
  }
};


export default theme
