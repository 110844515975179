import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { Tooltip, Popover, PopoverOrigin, Divider, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Add, Timer } from '@material-ui/icons'
import { IconButton, theme, Image, Row, Space, Text } from 'ui'
import CreateMessageNormalIcon from 'assets/icons/NormalMessage.svg';
import CreateMessageSurveyIcon from 'assets/icons/SurveyMessage.svg';
import { observer } from 'mobx-react'
import useStore from 'store'
import { MessageDialogMode } from 'utils/types'
import stopwatch from 'assets/icons/stopwatch.svg'
import { ReactComponent as Stopwatch } from 'assets/icons/stopwatch.svg';
import { isTotzeret } from 'service/whiteLable'

const StyledImage = styled(Image)`
  padding: 0px 10px 0px 10px;
  body[dir=rtl] & {
     padding: 0px 10px 0px 10px;
    }
`
const RedCounter = styled.div`
  border:1px solid ${theme.color.black + 33} ;
  border-radius:12px;
  position:absolute;
  height:20px;
  width:20px;
  z-index:1000;
  top:-10px;
  left:-7px;
  background-color:${theme.color.dangerRed};
  display:flex;
  align-items:center;
  justify-content:center;
  /* body[dir=rtl] & {
    top:-4px;
    left:-4px;
  } */
`
const PopoverContainer = styled.div`
position: absolute;
  top: 75%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  `
const ModifiedPopOver = styled(Popover)`
 .MuiPopover-paper{
   margin-top: 20px;
   overflow:unset;
   box-shadow: 0 -2px 24px 0 ${theme.color.darkGray};
   border-radius: 7px;
   /* margin-left: 5px;
   body[dir=rtl] & {
     margin-left: -5px;
    } */
  }
  `
const AddButton = styled.div<{ bgcolor: string, mainColor: string, borderColor: string,shadowColor:string }>`
  background-color:${p => p.bgcolor};
  &:hover {
    background: ${p => p.mainColor};
    box-shadow: 0 2px 8px 0 ${p => p.mainColor};

  }
  position:relative;
  border-color:${p => p.borderColor};
  border-width:1px;
  border-style:solid;
  border-radius:20px;
  width:42px;
  height:42px;
  box-shadow: 0 2px 8px 0 ${p => p.shadowColor};
  justify-content:center;
  align-items:center;
  display:flex;
  cursor:pointer;
  transition: background-color 0.2s;
`
const ContentContainer = styled.div`
  min-width: 200px;
  display: flex;
  border-radius: 7px;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  `
const CreateMessageItem = styled.div<{ isTop?: boolean, isBottom?: boolean }>`
  display: flex;
  flex-direction: row;
  border-top-right-radius: ${p => p.isTop ? '7px' : '0px'};
  border-top-left-radius: ${p => p.isTop ? '7px' : '0px'};
  border-bottom-right-radius: ${p => p.isBottom ? '7px' : '0px'};
  border-bottom-left-radius: ${p => p.isBottom ? '7px' : '0px'};
  :hover {
    background-color: rgba(0,0,0,0.1);
    cursor: pointer;
  }
  `
const CreateMessageItemText = styled.p`
  font-size: ${theme.fontSize.large};
  `

const PopoverArrow = styled.div`
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid white;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-top: 10px solid transparent;
    transform: rotate(-90deg);
    top: -20px;
    right: 10px;
    body[dir=rtl] & {
      right: unset;
      left:10px;
    }
    `

const MessagePopover: FC<{ showSchedule: boolean, scheduleCount: number, serviceCallType:boolean }> = ({ showSchedule = false, scheduleCount,serviceCallType }) => {
  const { messages, groups, newMessage } = useStore()
  const { i18n } = useTranslation()
  const { t } = useTranslation('messages')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const anchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: i18n.dir() === 'rtl' ? 'left' : 'right',
  }
  const transformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: i18n.dir() === 'rtl' ? 'left' : 'right',
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleScheduleClick = () => {
    messages.setMessageScheduleModalOpen(true)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }


  const openDialog = (mode: MessageDialogMode) => {
    messages.openNewMessageDialog(mode)
    newMessage.createNewMesage()
    if (mode === MessageDialogMode.Survey) {
      newMessage.createNewSurveyMessage()
    }
    handleClose()
  }

  const mainColor = groups.currentGroup?.group.description?.color || '#d9d9d9'
  const iconColor = isTotzeret ? groups.currentGroup?.group.description?.iconColor : groups.currentGroup?.group.description?.fabColor || '#9d9d9d'
  const borderColor = groups.currentGroup?.group.description?.fabColor || iconColor
  return (
    <PopoverContainer>
      <Row>
        <Tooltip title={''} arrow>
          <AddButton
            bgcolor={iconColor}
            mainColor={mainColor}
            borderColor={iconColor}
            shadowColor={iconColor}
            onClick={handleClick} ><Add style={{ color: 'white', transform: 'scale(1.4)' }} /></AddButton>
        </Tooltip>
        {showSchedule && (
          <>
            <Space width={4} />
            <Tooltip title={''} arrow>
              <div style={{ backgroundColor: 'white', borderRadius: 20 }}>
                <AddButton
                  bgcolor={iconColor + '33'}
                  borderColor={iconColor}
                  mainColor={mainColor}
                  shadowColor={iconColor}
                  onClick={handleScheduleClick} >
                  {scheduleCount > 0 && (
                    <RedCounter><Text style={{lineHeight:'unset'}} size='xs' textcolor={'white'} align='center'>{scheduleCount}</Text></RedCounter>
                  )}
                  <Stopwatch fill={iconColor} />
                </AddButton>
              </div>
            </Tooltip>
          </>
        )}
      </Row>
      <ModifiedPopOver
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <ContentContainer>
          <CreateMessageItem isTop onClick={() => openDialog(MessageDialogMode.Regular)}>
            <StyledImage src={CreateMessageNormalIcon} />
            <CreateMessageItemText>{t`normalMessage`}</CreateMessageItemText>
          </CreateMessageItem>
          <Divider />
      {!serviceCallType &&    <CreateMessageItem isBottom onClick={() => openDialog(MessageDialogMode.Survey)}>
            <StyledImage src={CreateMessageSurveyIcon} />
            <CreateMessageItemText>{t`surveyMessage`}</CreateMessageItemText>
          </CreateMessageItem>}
        </ContentContainer>
        <PopoverArrow />
      </ModifiedPopOver>
    </PopoverContainer>
  )
}
{/* <Timer style={{ color: iconColor, transform: 'scale(1.4)',transform: 'rotate(50deg)' }} /> */ }
export default observer(MessagePopover)
