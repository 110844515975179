import React, { FC, useState, useRef } from 'react'
import { observer } from 'mobx-react'
import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle, IconButton, DialogProps
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { Space, theme } from 'ui'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'


interface CustomProps {
  open: boolean,
  onOk: () => void,
  onClose: () => void,
  title?: string,
  canPressOk?: boolean,
  customBackground?: string,
  customHeaderBackground?: string,
  customFunction?: () => void,
  customButtonText?: string
  removeCancelButton?: boolean
  removeHeader?: boolean
  dialogSize?: string
  withFooterButtons?: boolean,
  customOkButtonText?: string,
  reverseButtonsOrder?: boolean

}
interface CustomButtonProps {
  backgroundColor?: string
  bColor?: string
}
interface DialogContainerProps {
  dialogSize?: string
}

const DialogContainer = styled(MuiDialog) <DialogContainerProps>`
  .MuiDialog-paperFullWidth{
    border-radius: 8px;
    width: "700px";
    max-width: ${p => p.dialogSize ? p.dialogSize : "700px"};
  }
`

interface BackgroudProps {
  customBackground?: string
  isTop?: boolean
  flexDirection?: string
}
const DialogHeader = styled.div<BackgroudProps>`
  .MuiDialogTitle-root {
    display:flex;
    height: 64px;
    background: ${p => p.customBackground ? p.customBackground : theme.color.dialogBackground};
    align-items: center;
    justify-content:space-between;
    /* padding: 16px 36px; */
  }
  .MuiIconButton-root{
    padding:0;
  }
`
const CustomButton = styled(Button) <CustomButtonProps> `
    &.MuiButtonBase-root {
    font-size: ${theme.fontSize.large};
    font-weight: 500;
    min-width: 140px;
    height: 50px;
    margin: 0.5rem;
    background: ${p => p.backgroundColor ? p.backgroundColor : theme.color.confirmButton};
    &:hover {
      background: ${theme.color.darkBlue};
    }
    color: ${theme.color.white};
    &.Mui-disabled {
      opacity: 0.5;
    }
  }
`

const DialogFooter = styled(DialogActions) <BackgroudProps>`
  background: ${p => p.customBackground ? p.customBackground : theme.color.dialogBackground};
  height: 84px;
  flex-direction: ${p => p.flexDirection ? p.flexDirection : 'row'};
  /* padding: 16px 36px; */
`
const DialogBody = styled.div<{ customBackground: string | undefined, isTop: boolean }>`
  background: ${p => p.customBackground ? p.customBackground : theme.color.dialogBackground};
  border-top-width: ${p => p.isTop ? '1px' : '0px'};
  /* padding: 16px 36px; */
`
const DialogHeaderText = styled.p`
  font-size: ${theme.fontSize.dialogHeader};
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: ${theme.color.black};;
`

const Dialog: FC<CustomProps & DialogProps> = ({ canPressOk = true, open, onClose, onOk, title, children, customBackground, customHeaderBackground, customFunction, customButtonText, customOkButtonText, removeCancelButton = false, removeHeader = false, dialogSize = "700px", withFooterButtons = true, reverseButtonsOrder = false, ...props }) => {
  const { t } = useTranslation('app')
  const [isTop, setIsTop] = useState(false)
  const inputRef = useRef<any>()

  return (
    <DialogContainer open={open} fullWidth maxWidth='xs' dialogSize={dialogSize} {...props} >
      {!removeHeader && <DialogHeader customBackground={customHeaderBackground}>
        <DialogTitle disableTypography>
          <DialogHeaderText>{title}</DialogHeaderText>
          <IconButton onClick={onClose} >
            <Close />
          </IconButton>
        </DialogTitle>
      </DialogHeader>}
      <DialogBody
        customBackground={customBackground}
        ref={inputRef}
        // dividers
        onScroll={() => {
          setIsTop(!!inputRef?.current?.scrollTop)
        }} isTop={isTop}>
        {children}
        <Space />
      </DialogBody>
      {withFooterButtons && <DialogFooter disableSpacing customBackground={customBackground} flexDirection={reverseButtonsOrder ? 'row-reverse' : 'row'}>
        {customFunction && <CustomButton onClick={customFunction}
          backgroundColor={theme.color.red}
        >{customButtonText ? customButtonText : t`מחק קבוצה`}</CustomButton>}
        <div style={{ flex: 1 }} />
        {!removeCancelButton && <CustomButton
          backgroundColor={'transparent'}
          bColor={theme.color.black}
          onClick={onClose}>{t`cancel`}</CustomButton>}
        <CustomButton onClick={() => {
          if (canPressOk)
            onOk()
        }}
          backgroundColor={canPressOk ? theme.color.confirmButton : theme.color.buttonBackground}
        >{customOkButtonText ? customOkButtonText : t`ok`}</CustomButton>
      </DialogFooter>
      }
    </DialogContainer>
  )
}

export default observer(Dialog)
