import React, { useEffect, useState} from 'react'
import { Text, theme } from 'ui'
import useStore from 'store'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ChevronLeft } from '@material-ui/icons'
import Grow from 'ui/Grow'
import { IconButton, MenuItem, Select } from '@material-ui/core'
import { useHistory, useParams } from 'react-router'

const StyledSelect = styled(Select)`
 && > svg {
  color:white;
  direction:rtl;
 }
 &:before{
   display:none;
 }
 &:after{
   display:none;
 }
`

const StyledHeader = styled.div`
  border-top-left-radius:12px;
  border-top-right-radius:12px;
  display:flex;
  background-color:${theme.color.loginPurple};
  width:100%;
  align-items:center;
  justify-content:center;
  flex-direction:row;
  padding:0.8rem;
`

const periodToString = (t: any, year: number, month: number) => {
  return `${t('calendar:config.monthNames', { returnObjects: true })[month]} ${year}`
}

const Header: React.FC = () => {
  const { budgetStore, user } = useStore()
  const { t } = useTranslation('budget')
  const h = useHistory()
  const isInDrillDown = (useParams() as any)?.id
  const onPressBack = () => {
    h.goBack()
  }

  const { setSelectedPeriodIndex, availablePeriods, selectedPeriodIndex } = budgetStore

  const handleChange = (event: any) => {
    if (isInDrillDown) {
      onPressBack()
    }
    setSelectedPeriodIndex(event.target.value);
  };
  const username = budgetStore.useDemo ? "ישראל ישראלי - הדגמה" : `${user.user.given_name} ${user.user.family_name}`

  return (
    <StyledHeader>
      <div>
        <Text size='xxl' textcolor={theme.color.white}>{t`summery.budget_page`} - {username}</Text>
        <StyledSelect onChange={handleChange} labelId="label" id="select" value={selectedPeriodIndex || 0} style={{ color: 'white', borderColor: 'white' }}>
          {availablePeriods.map((p, index) => <MenuItem value={index}>{periodToString(t, p[0], (p[1] - 1))}</MenuItem>)}
        </StyledSelect>
      </div>
      <Grow />
      <IconButton onClick={onPressBack}>
        <ChevronLeft style={{ color: theme.color.white, fontSize: 8 * 5 }} />
      </IconButton>
    </StyledHeader>)
}
export default observer(Header)
