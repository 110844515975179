import React, { FC, useState, useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'

import { Dialog } from '@material-ui/core'
import { Space } from 'ui'
import useStore from 'store'
import { useTranslation } from 'react-i18next'
import MessagesHeader from 'components/Messages/MessagesHeader'
import EditGroup from './EditGroup'

const Container = styled.div<{ color: string }>`
  flex: 1;
  display:flex;
  height:700px;
  width:900px;
  overflow:hidden;
  flex-direction:column;
  /* border:1px solid white; */
  border-radius:5px;
  background-color:${p => p.color};
`
const Contant = styled.div`
  flex: 1;
  padding:10px;
`
const CreateGroupModal: FC = () => {
    const { t } = useTranslation('messages')

    const { groups, orgs } = useStore()
    const closeModal = () => {
        groups.close()
    }
    const mainColor = groups?.currentGroup?.group?.description?.color

    return (
        <Dialog
            open={groups.isGroupModalOpen}
            maxWidth={'lg'}>

            <Container color={mainColor + 33}>
                <MessagesHeader
                    disableIcon
                    textVarient={'black'}
                    backColor={'transparent'}
                    mainText={orgs.currentOrg.name}
                    secondText={t`addNew`}
                    onClose={closeModal}
                />
                <Space />
                <EditGroup createGroup onClose={closeModal} />
            </Container>
        </Dialog>
    )
}

export default observer(CreateGroupModal)
