import React from 'react'
import Default from './groupIcons/default.svg'

import Account from './groupIcons/account.svg'
import Arnakd from './groupIcons/arnakd.svg'
import Avokado from './groupIcons/avokado.svg'
import Barmitzva from './groupIcons/barmitzva.svg'
import Budget from './groupIcons/budget.svg'
import Building from './groupIcons/building.svg'
import Car from './groupIcons/car.svg'
import Catering from './groupIcons/catering.svg'
import Clinic from './groupIcons/clinic.svg'
import Corona from './groupIcons/corona.svg'
import Debit from './groupIcons/debit.svg'
import DentalCare from './groupIcons/dental_care.svg'
import DinningRoom from './groupIcons/dinning_room.svg'
import Drags from './groupIcons/drags.svg'
import Elders from './groupIcons/elders.svg'
import Electricity from './groupIcons/electricity.svg'
import Factory from './groupIcons/factory.svg'
import Fire from './groupIcons/fire.svg'
import Football from './groupIcons/football.svg'
import Gadash from './groupIcons/gadash.svg'
import Gardening from './groupIcons/gardening.svg'
import GlobalMessages from './groupIcons/global_messages.svg'
import Grapes from './groupIcons/grapes.svg'
import Gym from './groupIcons/gym.svg'
import Hairdresser from './groupIcons/hairdresser.svg'
import Hosting from './groupIcons/hosting.svg'
import Hostingroom from './groupIcons/hostingroom.svg'
import Internet from './groupIcons/internet.svg'
import Kalfi from './groupIcons/kalfi.svg'
import Kindergarden from './groupIcons/kindergarden.svg'
import Kitchen from './groupIcons/kitchen.svg'
import Laundry from './groupIcons/laundry.svg'
import Library from './groupIcons/library.svg'
import Mail from './groupIcons/mail.svg'
import Marcolit from './groupIcons/marcolit.svg'
import Melonit from './groupIcons/melonit.svg'
import Messages from './groupIcons/messages.svg'
import Mizug from './groupIcons/mizug.svg'
import Pardes from './groupIcons/pardes.svg'
import Pension from './groupIcons/pension.svg'
import Phone from './groupIcons/phone.svg'
import Pool from './groupIcons/pool.svg'
import Restaurant from './groupIcons/restaurant.svg'
import Savuot from './groupIcons/savuot.svg'
import Scool from './groupIcons/scool.svg'
import Security from './groupIcons/security.svg'
import Shows from './groupIcons/shows.svg'
import Sinagogue from './groupIcons/sinagogue.svg'
import Slika from './groupIcons/slika.svg'
import Sochrim from './groupIcons/sochrim.svg'
import Soldiers from './groupIcons/soldiers.svg'
import Spa from './groupIcons/spa.svg'
import Store from './groupIcons/store.svg'
import Students from './groupIcons/students.svg'
import Support from './groupIcons/support.svg'
import SystemUpdates from './groupIcons/system_updates.svg'
import Tzachy from './groupIcons/tzachy.svg'
import Water from './groupIcons/water.svg'
import Women from './groupIcons/women.svg'
import Coffe from './groupIcons/coffe.svg'
import Birds from './groupIcons/birds.svg'
import Cows from './groupIcons/cows.svg'
import Farm from './groupIcons/farm.svg'
import Garage from './groupIcons/garage.svg'
import SafeHouse from './groupIcons/safeHouse.svg'
import SafeHouse2 from './groupIcons/safeHouse2.svg'
import Flowers from './groupIcons/flowers.svg'
import LawnMower from './groupIcons/LawnMower.svg'
import Bakery from './groupIcons/bakery.svg'
import Bakery2 from './groupIcons/bakery2.svg'
import Pastry from './groupIcons/pastry.svg'
import Bank from './groupIcons/bank.svg'
import Insurance from './groupIcons/insurance.svg'
import Chess from './groupIcons/chess.svg'
import Plays from './groupIcons/Plays.svg'
import Concerts from './groupIcons/concerts.svg'
import Music from './groupIcons/music.svg'
import Corona2 from './groupIcons/corona2.svg'
import BloodDonation from './groupIcons/bloodDonation.svg'
import Veterinarian from './groupIcons/veterinarian.svg'
import Fifty from './groupIcons/fifty.svg'
import Forty from './groupIcons/forty.svg'
import Thirty from './groupIcons/thirty.svg'
import Twenty from './groupIcons/twenty.svg'
import Welcome from './groupIcons/welcome.svg'
import Spokes from './groupIcons/spokes.svg'
import HumanResources from './groupIcons/humanResources.svg'
import Agriculturaleadership from './groupIcons/agriculturaleadership.svg'
import archives from './groupIcons/archives.svg'
import BasketBall from './groupIcons/basketBall.svg'
import Bicycle from './groupIcons/bicycle.svg'
import Netball from './groupIcons/netball.svg'
import Power from './groupIcons/power.svg'
import Run from './groupIcons/run.svg'
import Yoga from './groupIcons/yoga.svg'
import Swimming from './groupIcons/swimming.svg'
import HolocaustDay from './groupIcons/holocaustDay.svg'
import MemorialDay from './groupIcons/memorialDay.svg'
import Sabbath from './groupIcons/Sabbath.svg'
import Wedding from './groupIcons/wedding.svg'
import HighSchool from './groupIcons/highSchool.svg'
import Infancy from './groupIcons/infancy.svg'
import Hamal from './groupIcons/hamal.svg'
import Rescue from './groupIcons/rescue.svg'
import Ambulance from './groupIcons/ambulance.svg'
import Carpentry from './groupIcons/carpentry.svg'
import HomeDriver from './groupIcons/homeDriver.svg'
import Motorcycle from './groupIcons/motorcycle.svg'
import Yesodi from './groupIcons/yesodi.svg'
import Rocket from './groupIcons/rocket.svg'
import Users from './groupIcons/users.svg'
//Totzeret icons
import Blue01 from './groupIconsTotzeret/blue01.svg';
import Blue02 from './groupIconsTotzeret/blue02.svg';
import Blue03 from './groupIconsTotzeret/blue03.svg';
import Blue04 from './groupIconsTotzeret/blue04.svg';
import Blue05 from './groupIconsTotzeret/blue05.svg';
import Blue06 from './groupIconsTotzeret/blue06.svg';
import Blue07 from './groupIconsTotzeret/blue07.svg';
import Blue08 from './groupIconsTotzeret/blue08.svg';
import Blue09 from './groupIconsTotzeret/blue09.svg';
import Blue10 from './groupIconsTotzeret/blue10.svg';
import Blue11 from './groupIconsTotzeret/blue11.svg';
import Blue12 from './groupIconsTotzeret/blue12.svg';
import Blue13 from './groupIconsTotzeret/blue13.svg';
import Blue14 from './groupIconsTotzeret/blue14.svg';

import Brown01 from './groupIconsTotzeret/brown01.svg';
import Brown02 from './groupIconsTotzeret/brown02.svg';
import Brown03 from './groupIconsTotzeret/brown03.svg';
import Brown04 from './groupIconsTotzeret/brown04.svg';
import Brown05 from './groupIconsTotzeret/brown05.svg';
import Brown06 from './groupIconsTotzeret/brown06.svg';
import Brown07 from './groupIconsTotzeret/brown07.svg';
import Brown08 from './groupIconsTotzeret/brown08.svg';
import Brown09 from './groupIconsTotzeret/brown09.svg';

import Darkgreen01 from './groupIconsTotzeret/darkgreen01.svg';
import Darkgreen02 from './groupIconsTotzeret/darkgreen02.svg';
import Darkgreen03 from './groupIconsTotzeret/darkgreen03.svg';
import Darkgreen04 from './groupIconsTotzeret/darkgreen04.svg';
import Darkgreen05 from './groupIconsTotzeret/darkgreen05.svg';
import Darkgreen06 from './groupIconsTotzeret/darkgreen06.svg';
import Darkgreen07 from './groupIconsTotzeret/darkgreen07.svg';
import Darkgreen08 from './groupIconsTotzeret/darkgreen08.svg';
import Darkgreen09 from './groupIconsTotzeret/darkgreen09.svg';

import Green01 from './groupIconsTotzeret/green01.svg';
import Green02 from './groupIconsTotzeret/green02.svg';
import Green03 from './groupIconsTotzeret/green03.svg';
import Green04 from './groupIconsTotzeret/green04.svg';
import Green05 from './groupIconsTotzeret/green05.svg';
import Green06 from './groupIconsTotzeret/green06.svg';
import Green07 from './groupIconsTotzeret/green07.svg';
import Green08 from './groupIconsTotzeret/green08.svg';
import Green09 from './groupIconsTotzeret/green09.svg';
import Green10 from './groupIconsTotzeret/green10.svg';

import Grey01 from './groupIconsTotzeret/grey01.svg';
import Grey02 from './groupIconsTotzeret/grey02.svg';
import Grey03 from './groupIconsTotzeret/grey03.svg';
import Grey04 from './groupIconsTotzeret/grey04.svg';
import Grey05 from './groupIconsTotzeret/grey05.svg';
import Grey06 from './groupIconsTotzeret/grey06.svg';
import Grey07 from './groupIconsTotzeret/grey07.svg';
import Grey08 from './groupIconsTotzeret/grey08.svg';
import Grey09 from './groupIconsTotzeret/grey09.svg';

import LightGreen01 from './groupIconsTotzeret/lightgreen01.svg';
import LightGreen02 from './groupIconsTotzeret/lightgreen02.svg';
import LightGreen03 from './groupIconsTotzeret/lightgreen03.svg';
import LightGreen04 from './groupIconsTotzeret/lightgreen04.svg';
import LightGreen05 from './groupIconsTotzeret/lightgreen05.svg';
import LightGreen06 from './groupIconsTotzeret/lightgreen06.svg';
import LightGreen07 from './groupIconsTotzeret/lightgreen07.svg';
import LightGreen08 from './groupIconsTotzeret/lightgreen08.svg';

import Red01 from './groupIconsTotzeret/red01.svg';
import Red02 from './groupIconsTotzeret/red02.svg';
import Red03 from './groupIconsTotzeret/red03.svg';
import Red04 from './groupIconsTotzeret/red04.svg';
import Red05 from './groupIconsTotzeret/red05.svg';
import Red06 from './groupIconsTotzeret/red06.svg';
import Red07 from './groupIconsTotzeret/red07.svg';
import Red08 from './groupIconsTotzeret/red08.svg';
import Red09 from './groupIconsTotzeret/red09.svg';
import Red10 from './groupIconsTotzeret/red10.svg';

import Lightblue01 from './groupIconsTotzeret/lightblue01.svg';
import Lightblue02 from './groupIconsTotzeret/lightblue02.svg';
import Lightblue03 from './groupIconsTotzeret/lightblue03.svg';
import Lightblue04 from './groupIconsTotzeret/lightblue04.svg';
import Lightblue05 from './groupIconsTotzeret/lightblue05.svg';
import Lightblue06 from './groupIconsTotzeret/lightblue06.svg';
import Lightblue07 from './groupIconsTotzeret/lightblue07.svg';
import Lightblue08 from './groupIconsTotzeret/lightblue08.svg';
import Lightblue09 from './groupIconsTotzeret/lightblue09.svg';

import Yellow01 from './groupIconsTotzeret/yellow01.svg';
import Yellow02 from './groupIconsTotzeret/yellow02.svg';
import Yellow03 from './groupIconsTotzeret/yellow03.svg';
import Yellow04 from './groupIconsTotzeret/yellow04.svg';
import Yellow05 from './groupIconsTotzeret/yellow05.svg';
import Yellow06 from './groupIconsTotzeret/yellow06.svg';
import Yellow07 from './groupIconsTotzeret/yellow07.svg';
import Yellow08 from './groupIconsTotzeret/yellow08.svg';
import Yellow09 from './groupIconsTotzeret/yellow09.svg';
import Yellow10 from './groupIconsTotzeret/yellow10.svg';
import Yellow11 from './groupIconsTotzeret/yellow11.svg';

import TotzeretDefault from './groupIconsTotzeret/default.svg'

import { TOTZERET_NAME, WHITE_LABLE_APP_NAME } from 'service/whiteLable'

const DefaultGroupIcon = (brandName) => {
    switch (brandName) {
      case TOTZERET_NAME:
        return TotzeretDefault;
      default:
        return Default;
    }
}

const icons = {}
icons['default'] = DefaultGroupIcon(WHITE_LABLE_APP_NAME);
icons['account'] = Account
icons['arnakd'] = Arnakd
icons['avokado'] = Avokado
icons['barmitzva'] = Barmitzva
icons['budget'] = Budget
icons['building'] = Building
icons['car'] = Car
icons['catering'] = Catering
icons['clinic'] = Clinic
icons['corona'] = Corona
icons['debit'] = Debit
icons['dental_care'] = DentalCare
icons['dinning_room'] = DinningRoom
icons['drags'] = Drags
icons['elders'] = Elders
icons['electricity'] = Electricity
icons['factory'] = Factory
icons['fire'] = Fire
icons['football'] = Football
icons['gadash'] = Gadash
icons['gardening'] = Gardening
icons['global_messages'] = GlobalMessages
icons['grapes'] = Grapes
icons['gym'] = Gym
icons['hairdresser'] = Hairdresser
icons['hosting'] = Hosting
icons['hostingroom'] = Hostingroom
icons['internet'] = Internet
icons['kalfi'] = Kalfi
icons['kindergarden'] = Kindergarden
icons['kitchen'] = Kitchen
icons['laundry'] = Laundry
icons['library'] = Library
icons['mail'] = Mail
icons['marcolit'] = Marcolit
icons['melonit'] = Melonit
icons['messages'] = Messages
icons['mizug'] = Mizug
icons['pardes'] = Pardes
icons['pension'] = Pension
icons['phone'] = Phone
icons['pool'] = Pool
icons['restaurant'] = Restaurant
icons['savuot'] = Savuot
icons['scool'] = Scool
icons['security'] = Security
icons['shows'] = Shows
icons['sinagogue'] = Sinagogue
icons['slika'] = Slika
icons['sochrim'] = Sochrim
icons['soldiers'] = Soldiers
icons['spa'] = Spa
icons['store'] = Store
icons['students'] = Students
icons['support'] = Support
icons['system_updates'] = SystemUpdates
icons['tzachy'] = Tzachy
icons['water'] = Water
icons['women'] = Women
icons['coffe'] = Coffe
icons['birds'] = Birds
icons['cows'] = Cows
icons['farm'] = Farm
icons['garage'] = Garage
icons['safeHouse'] = SafeHouse
icons['safeHouse2'] = SafeHouse2
icons['flowers'] = Flowers
icons['LawnMower'] = LawnMower
icons['bakery'] = Bakery
icons['bakery2'] = Bakery2
icons['pastry'] = Pastry
icons['bank'] = Bank
icons['insurance'] = Insurance
icons['chess'] = Chess
icons['Plays'] = Plays
icons['concerts'] = Concerts
icons['music'] = Music
icons['corona2'] = Corona2
icons['bloodDonation'] = BloodDonation
icons['veterinarian'] = Veterinarian
icons['fifty'] = Fifty
icons['forty'] = Forty
icons['thirty'] = Thirty
icons['twenty'] = Twenty
icons['welcome'] = Welcome
icons['spokes'] = Spokes
icons['humanResources'] = HumanResources
icons['agriculturaleadership'] = Agriculturaleadership
icons['archives'] = archives
icons['basketBall'] = BasketBall
icons['bicycle'] = Bicycle
icons['netball'] = Netball
icons['power'] = Power
icons['run'] = Run
icons['yoga'] = Yoga
icons['swimming'] = Swimming
icons['holocaustDay'] = HolocaustDay
icons['memorialDay'] = MemorialDay
icons['Sabbath'] = Sabbath
icons['wedding'] = Wedding
icons['highSchool'] = HighSchool
icons['infancy'] = Infancy
icons['hamal'] = Hamal
icons['rescue'] = Rescue
icons['ambulance'] = Ambulance
icons['carpentry'] = Carpentry
icons['homeDriver'] = HomeDriver
icons['motorcycle'] = Motorcycle
icons['yesodi'] = Yesodi
icons['rocket'] = Rocket
icons['users'] = Users
//TOtzeret icons
icons['blue01'] = Blue01;
icons['blue02'] = Blue02;
icons['blue03'] = Blue03;
icons['blue04'] = Blue04;
icons['blue05'] = Blue05;
icons['blue06'] = Blue06;
icons['blue07'] = Blue07;
icons['blue08'] = Blue08;
icons['blue09'] = Blue09;
icons['blue10'] = Blue10;
icons['blue11'] = Blue11;
icons['blue12'] = Blue12;
icons['blue13'] = Blue13;
icons['blue14'] = Blue14;

icons['brown01'] = Brown01;
icons['brown02'] = Brown02;
icons['brown03'] = Brown03;
icons['brown04'] = Brown04;
icons['brown05'] = Brown05;
icons['brown06'] = Brown06;
icons['brown07'] = Brown07;
icons['brown08'] = Brown08;
icons['brown09'] = Brown09;

icons['darkgreen01'] = Darkgreen01;
icons['darkgreen02'] = Darkgreen02;
icons['darkgreen03'] = Darkgreen03;
icons['darkgreen04'] = Darkgreen04;
icons['darkgreen05'] = Darkgreen05;
icons['darkgreen06'] = Darkgreen06;
icons['darkgreen07'] = Darkgreen07;
icons['darkgreen08'] = Darkgreen08;
icons['darkgreen09'] = Darkgreen09;

icons['green01'] = Green01;
icons['green02'] = Green02;
icons['green03'] = Green03;
icons['green04'] = Green04;
icons['green05'] = Green05;
icons['green06'] = Green06;
icons['green07'] = Green07;
icons['green08'] = Green08;
icons['green09'] = Green09;
icons['green10'] = Green10;

icons['grey01'] = Grey01;
icons['grey02'] = Grey02;
icons['grey03'] = Grey03;
icons['grey04'] = Grey04;
icons['grey05'] = Grey05;
icons['grey06'] = Grey06;
icons['grey07'] = Grey07;
icons['grey08'] = Grey08;
icons['grey09'] = Grey09;

icons['lightgreen01'] = LightGreen01;
icons['lightgreen02'] = LightGreen02;
icons['lightgreen03'] = LightGreen03;
icons['lightgreen04'] = LightGreen04;
icons['lightgreen05'] = LightGreen05;
icons['lightgreen06'] = LightGreen06;
icons['lightgreen07'] = LightGreen07;
icons['lightgreen08'] = LightGreen08;

icons['red01'] = Red01;
icons['red02'] = Red02;
icons['red03'] = Red03;
icons['red04'] = Red04;
icons['red05'] = Red05;
icons['red06'] = Red06;
icons['red07'] = Red07;
icons['red08'] = Red08;
icons['red09'] = Red09;
icons['red10'] = Red10;

icons['lightblue01'] = Lightblue01;
icons['lightblue02'] = Lightblue02;
icons['lightblue03'] = Lightblue03;
icons['lightblue04'] = Lightblue04;
icons['lightblue05'] = Lightblue05;
icons['lightblue06'] = Lightblue06;
icons['lightblue07'] = Lightblue07;
icons['lightblue08'] = Lightblue08;
icons['lightblue09'] = Lightblue09;

icons['yellow01'] = Yellow01;
icons['yellow02'] = Yellow02;
icons['yellow03'] = Yellow03;
icons['yellow04'] = Yellow04;
icons['yellow05'] = Yellow05;
icons['yellow06'] = Yellow06;
icons['yellow07'] = Yellow07;
icons['yellow08'] = Yellow08;
icons['yellow09'] = Yellow09;
icons['yellow10'] = Yellow10;
icons['yellow11'] = Yellow11;

export default icons
