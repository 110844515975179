import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { AppBar, Toolbar } from '@material-ui/core'
import { observer } from 'mobx-react-lite'

import { Row } from 'ui'

import MobileProfile from 'components/Profile/MobileProfile'
import OrgsSearch from 'components/OrgsSearch'
import Tabs from 'pages/Home/Tabs'

import menu from 'assets/icons/menu_icon.svg'

const StyledAppBar = styled(AppBar)`
  box-shadow: none;
  background-color: var(--dark-back);
  color: var(--white);
`

const StyledTopbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  color: var(--white);
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const SRow = styled(Row)`
  height: 52px;
  width: 100%;
`

interface Props { }

const MAppbar: FC<Props> = () => {
  const [ open, setOpen ] = useState(false)
  const onClose = () => setOpen(false)
  
  return (
    <StyledAppBar id='app-bar' position='static'>
      <StyledTopbar>
        <SRow justify='between' grow>
          <OrgsSearch />
          <img src={menu} height={16} onClick={() => setOpen(prev => !prev)}/>
        </SRow>
        <Row>
          <Tabs />
        </Row>
      </StyledTopbar>
      <MobileProfile open={open} onClose={onClose} />
    </StyledAppBar>
  )
}

export default observer(MAppbar)
