import React, { FC } from 'react'
import styled from 'styled-components'
import { Dialog, } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Close, Send, LiveHelp } from '@material-ui/icons'
import { theme, Text, Row, Space, Icon, Spinner } from 'ui'
import { observer } from 'mobx-react'
import useStore from 'store'

import pigIcon from 'assets/icons/budgetIcon.svg'
import Switch from '@material-ui/core/Switch'
import PhoneStep from './PhoneStep'
import CodeStep from './CodeStep'
import { steps } from 'store/budget.store'

const StyledCloseButton = styled(Close)`
    cursor:pointer;
`
const ContainerDialog = styled.div<{ color: string }>`
    align-items:center;
    justify-content:flex-start;;
    flex-direction:column;
    display:flex;
    background-color:${p => p.color + '1A'};
    .ReactCollapse--collapse {
        transition: height 500ms;
        }
        overflow:auto;
        height:500px;
  `
const HeaderContainer = styled.div`
display:flex;
flex-direction:row;
background-color:${theme.color.budgetPurple};
padding:20px;
width:100%;

`


const Header = () => {
  const { budgetStore } = useStore()
  const { t } = useTranslation('budget')
  const handleClose = () => {
    budgetStore.closeBudgetLogin()
    budgetStore.clearAll()
  }
  return (
    <HeaderContainer>
      <img src={pigIcon} />
      <Space />
      <div>
        <Text
          textcolor={theme.color.white}
          weight={'medium'}
          size={'xl'}
        >{t`showBudget`}</Text>
        <Text
          textcolor={theme.color.white + 'A1'}
          weight={'normal'}
          size={'sm'}
        >{t`loginBudgetHeader`}</Text>
      </div>
      <StyledCloseButton
        onClick={handleClose}
        style={{ color: theme.color.whiteBackground }} />
    </HeaderContainer>
  )
}

const BudgetLoginModal: FC = ({ }) => {
  const { budgetStore } = useStore()
  const { t } = useTranslation('budget')
  const handleClose = () => {
    budgetStore.closeBudgetLogin()
    budgetStore.clearAll()
  }
  return (
    <Dialog
      open={budgetStore.budgetLoginModalOpen}
      onClose={handleClose}
      onBackdropClick={handleClose}
    >
      <ContainerDialog color={'purple'}>
        <Header />
        {budgetStore.authStep === steps.LOGIN_PAGE && <PhoneStep />}
        {budgetStore.authStep === steps.CODE_PAGE && <CodeStep />}
      </ContainerDialog>
    </Dialog>
  )
}

export default observer(BudgetLoginModal)


