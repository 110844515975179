import React, { FC } from 'react'
import { TextField, TextFieldProps } from '@material-ui/core'
import styled from 'styled-components'
import { theme } from 'ui'


const StyledTextField = styled(TextField)`
  background: ${theme.color.generalInputBackground};
  border-radius: 5px;
  padding: 6px 12px;
  color: var(--black);
  .MuiInputBase-input::placeholder {
    color: var(--black);
    opacity: 1;
  }

`
interface CustomTextFieldProps {
  isFocus?: boolean
  rest: TextFieldProps
}

export const GeneralInput: FC<CustomTextFieldProps> = ({ isFocus, ...props }) => {
  const inputRef = React.useRef()

  React.useEffect(() => {
    if (isFocus != null && isFocus) {
      inputRef.current?.focus()
    }
  }, [isFocus])

  const { InputProps: inProps } = props.rest || {}

  return <StyledTextField
    {...props}
    InputProps={{
      ...inProps,
      disableUnderline: true
    }}
    inputRef={inputRef}
  />

}

// TODO: remove Input and use GeneralInput everywhere
const Input: FC<TextFieldProps> = props => <TextField variant='outlined' {...props} />
export default Input
