import React, { FC } from 'react'
import styled from 'styled-components'

import { Drawer as MUIDrawer } from '@material-ui/core'
import Profile from './Profile'

interface Props {
  open: boolean
  onClose: () => void
}


const MobileProfile: FC<Props> = ({ open, onClose }) => {
  return (
    <MUIDrawer open={open} onClose={onClose}>
      <Profile onClose={onClose} />
    </MUIDrawer>
  )
}

export default MobileProfile
