import React, { FC, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { TextField, TableCell, TableRow, Link, Button } from '@material-ui/core'
import { Edit, Delete, Save, Close } from '@material-ui/icons'
import styled from 'styled-components'
import useStore from 'store'
import api from 'service/api'

const Actions = styled.div`
  display:flex;
  flex-direction: row;
`
const StyledButton = styled(Button)`
  padding: 2px 4px;
  min-width: 10px;
`
const StyledTableRow = styled(TableRow)<{ userStatus: number }>`
  opacity: ${p => p.userStatus == 0 ? '1' : '0.4'};
`
const StyledTableCell = styled(TableCell)`
  direction: ltr;
`

const UserSectionItem: FC = ({ user, entityId, onRefresh }) => {
    const {t} = useTranslation('communitySettings')
    const { groups, uiStore } = useStore()
    const [isEditMode, setEditMode] = useState(false)
    const [error, setError] = useState(null)
    const [userFormState, setUserFormState] = useState({
      name: '',
      emails: [''],
      phones: ['']
    })

    useEffect(() => {
      setEditMode(false)
      setUserFormState({
        name: '',
        emails: [''],
        phones: ['']
      })
    }, [entityId])

    const onSaveEvent = async () => {
      const res = await api.editEntityUser(user, userFormState, entityId)
      if (res !== null && typeof (res) === 'object' && res?.resErr && res?.error){
        setError(res.error)
      } else {
        setError(null)
        setEditMode(false)
        onRefresh(entityId)
      }
    }
    
    const onEditEvent = () => {
      setEditMode(true)
      setUserFormState({
        name: user.givenName + ' ' + user.familyName,
        emails: Array.isArray(user.emails) && user.emails.length > 0 ? user.emails : [''],
        phones: Array.isArray(user.phones) && user.phones.length > 0 ? user.phones : [''],
      })
    }

    const onCloseEditEvent = () => {
      setEditMode(false)
      setError(null)
    }

    const onDeleteEvent = async () => {
      uiStore.openWarningModal({
        text: t`remove:removeUser`,
        secondText: user.givenName + ' ' + user.familyName,
        okAction: async () => {
          await api.removeEntityUser(entityId, user.userId)
          onRefresh(entityId)
        }
      })
    }

    const isDisabled = () => {
      return userFormState.name.trim() == '' || (userFormState.emails[0] == '' && userFormState.phones[0] == '')
    }
   
    return (  
        <>
        {error && 
          <StyledTableRow userStatus={1}>
              <TableCell colSpan={4} align="center"><span style={{color: 'red'}}>{error}</span></TableCell>
          </StyledTableRow>
        }

        <StyledTableRow userStatus={user.status}>
          {!isEditMode &&
          <>
            <TableCell>
              {user.givenName + ' ' + user.familyName}
            </TableCell>
            <StyledTableCell component="th" scope="row">
              {Array.isArray(user.phones) ? user.phones.map(p => <a href={`tel:${p}`}>{p}</a>) : ''}
            </StyledTableCell>
            <TableCell>
              {Array.isArray(user.emails) ? user.emails.map(e => <a href={`mailto:${e}`}>{e}</a>) : ''}
            </TableCell>
  
            <TableCell width={100}>
              <Actions>
                {user.status == 0 && <StyledButton onClick={onEditEvent}><Edit/></StyledButton>}
                {user.status == 0 && <StyledButton onClick={onDeleteEvent}><Delete/></StyledButton>}
                {user.status == 2 && <>משתמש מחוק</>}
              </Actions>
            </TableCell>
          </>}

          {isEditMode &&
          <>
           <TableCell> 
              <TextField
                id={'user_name'}
                required
                label={t`name`}
                variant="outlined"
                value={userFormState.name}
                onChange={({target}) => {
                  setUserFormState({ ...userFormState, name: target.value })
                }}
              />
            </TableCell>
            <StyledTableCell component="th" scope="row">
              {userFormState.phones.map((phone, index) => 
                <TextField
                  id={`user_phone_${index}`}
                  required
                  label={t`phone`}
                  variant="outlined"
                  value={phone}
                  onChange={({target}) => {
                    let phones = userFormState.phones 
                    phones[index] = target.value 
                     
                    setUserFormState({ 
                      name: userFormState.name, 
                      emails: userFormState.emails,
                      phones: phones 
                    })
                  }}
                />
              )}
            </StyledTableCell>
            <TableCell>
              {userFormState.emails.map((email, index) => 
                <TextField
                  id={`user_email_${index}`}
                  required
                  label={t`email`}
                  variant="outlined"
                  value={email}
                  onChange={({target}) => {
                    let emails = userFormState.emails 
                    emails[index] = target.value 
                     
                    setUserFormState({ 
                      name: userFormState.name, 
                      emails: emails,
                      phones: userFormState.phones 
                    })
                  }}
              />
              )}
            </TableCell>
           
            <TableCell width={100}>
              <StyledButton onClick={onCloseEditEvent}><Close/></StyledButton>
              <StyledButton disabled={isDisabled()} onClick={onSaveEvent}><Save/></StyledButton>
            </TableCell>
          </>}
        </StyledTableRow>
      </>
    )
}

export default observer(UserSectionItem)