import React from 'react'
import { Row, Space, Text, theme } from 'ui'
import useStore from 'store'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Grow from 'ui/Grow'
import { Card } from '@material-ui/core'
import Money from './Money'
import { InnerItem, OuterItem, BudgetTransaction, PaymentType, PosTransaction, PosData, ItemPosTransaction } from 'store/budget.store'
import GlobalHeader from './Header'
import { ChevronLeft } from '@material-ui/icons'
import { Collapse } from '@material-ui/core';

const StyledContent = styled.div`
  display:flex;
  flex-direction:column;
  flex:1;
  background-color:${theme.color.dialogBackground};
  padding:16px;
  overflow:auto;
`

const StyledContainer = styled.div`
  display:flex;
  flex:1;
  flex-direction:column;
  align-self:stretch;
  background-color:${theme.color.dialogBackground};
  overflow-y:auto;
`

const StyledMoney = styled.div`
  display:flex;
  flex-direction:row;
  padding-bottom:8px;
`
const StyledTransactionContainer = styled(Card)`
  display:flex;
  flex-direction:column;
  background-color:${theme.color.white};
  margin:8px;
  overflow:visible;
`
const StyledTransactionBody = styled.div`
  display:flex;
  flex-direction:column;
  padding:12px;
`
const StyledHeader = styled.div`
    display:flex;
    background-color:${theme.color.dialogBackground};
    width:100%;
    align-items:center;
    justify-content:center;
    flex-direction:row;
    padding:0.5rem;
`
const StyledColumn = styled.div`
    display: flex; 
    flex-direction: column;
`

const StyledFooter = styled.div`
  display:flex;
  background-color:${theme.color.footPurple};
  border-top-left-radius:16px;
  border-top-right-radius:16px;
  padding:0.5rem 1rem;
  flex:1;
  flex-direction:column;
`

const StyledCollapseButton = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  cursor:pointer;
`

const StyledChevronLeft = styled(ChevronLeft) <{ collapsed: boolean }>`
  transform: rotate(${p => p.collapsed ? 90 : 270}deg);
  background-color:${theme.color.footPurple};
  border-radius:50%;
  color:${theme.color.white};
  align-items:center;
  text-align:center;
  display:flex;
`

const StyledInnerListItem = styled.div`
  display:flex;
  align-items:center;
  border-width:0px;
  border-top-width:1px;
  border-style:solid;
  border-top-color:gray;
  padding:8px 16px;
  flex-direction:row;
`
const InnerListItem: React.FC<{ item: InnerItem | ItemPosTransaction }> = ({ item }) => {
    const { t } = useTranslation('budget')
    const { budgetStore } = useStore()
    item.totalSum = Number(item.totalSum)
    const details = budgetStore.paymentType === PaymentType.BUDGET ? (item as InnerItem).details : (item as ItemPosTransaction).description
    const sumPerProduct = budgetStore.paymentType === PaymentType.BUDGET ? (item as InnerItem).netPrice : (item as ItemPosTransaction).sumPerProduct
    return (
        <StyledInnerListItem>
            <div style={{ display: 'flex', flex: 1 }}>
                <Money moneySize={28} currencySize={20} amount={budgetStore.agorotToShekel(Number(-item.totalSum))} forceShowAgorot />
            </div>
            <div style={{ flex: 3 }}>
                <Row justify='start' style={{ flexWrap: 'wrap' }}>
                    <Text weight='bold' size='xl'>{details}&nbsp;&nbsp;&nbsp;</Text>
                    <Row>
                        <Text textcolor={theme.color.grey} align='right' size='lg'>({item.quantity} {t`summery.units`}&nbsp;×&nbsp;</Text>
                        <Text textcolor={theme.color.grey} align='right' size='lg' style={{ direction: 'ltr' }}>
                            ({budgetStore.currencyType} {budgetStore.agorotToShekel(Number(sumPerProduct))}
                        </Text>

                    </Row>
                </Row>
            </div>
        </StyledInnerListItem >
    )
}

const InnerList: React.FC<{ collapsed: boolean, transaction: OuterItem | PosTransaction }> = ({ collapsed, transaction }) => {
    const { budgetStore } = useStore()
    const sortedItems = transaction.items?.slice().sort((a, b) => {
        let diff = Number(b.totalSum) - Number(a.totalSum)
        if (diff === 0) {
            diff = budgetStore.paymentType === PaymentType.BUDGET ?
                (a as InnerItem).details.localeCompare((b as InnerItem).details) :
                (a as ItemPosTransaction).description.localeCompare((b as ItemPosTransaction).description)
        }
        return diff
    }) || []
    return (
        <Collapse in={collapsed}>
            {sortedItems.map((el, index) => <InnerListItem item={el} key={'innerList' + index} />)}
        </Collapse>
    )
}

const CollapseInnerList: React.FC<{ transaction: OuterItem | PosTransaction, toggleCollapse: any, collapsed: boolean }> = ({ transaction, toggleCollapse, collapsed }) => {
    const { t } = useTranslation('budget')
    return (
        <>
            <Grow />
            {Boolean(transaction.items?.length) && (
                <Row justify='between'>
                    <StyledCollapseButton onClick={toggleCollapse}>
                        <Text textcolor={theme.color.loginPurple} weight='bold'>{t`summery.more_info`}</Text>
                        <Space width={1} />
                        <StyledChevronLeft collapsed={collapsed} />
                    </StyledCollapseButton>
                </Row>
            )}
        </>
    )
}


export const Header: React.FC<{ title: string | undefined }> = ({ title }) => {
    return (
        <StyledHeader>
            <div>
                <Text weight='bold' size='xxl' textcolor={theme.color.black}>{title}</Text>
            </div>
            <Grow />
        </StyledHeader>)
}

export const Transaction: React.FC<{ transaction: OuterItem | PosTransaction, shouldCollapseDefault: boolean, name: string, description: string }> = observer(({ transaction, shouldCollapseDefault, name, description }) => {
    const { budgetStore } = useStore()
    const { t } = useTranslation('budget')
    const [collapsed, setCollapsed] = React.useState(!shouldCollapseDefault)
    const toggleCollapse = () => {
        setCollapsed(!collapsed)
    }
    const date = budgetStore.dateTimeStr(transaction.dateOfCharge)
    return (
        <StyledTransactionContainer>
            <StyledTransactionBody>
                <Row align='stretch'>
                    <StyledColumn>
                        {transaction?.dateOfCharge && <Text weight='bold' size='lg'>{date}</Text>}
                        <Space height={0.5} />
                        <StyledMoney>
                            <Money
                                moneySize={32}
                                currencySize={20}
                                amount={budgetStore.agorotToShekel(Number(-transaction.totalSum))}
                            />
                        </StyledMoney>
                        {transaction.items?.length ?
                        <Text textcolor={theme.color.grey}>
                            {`${t('summery.items', { amount: transaction.items?.length })}`}
                        </Text> : null }
                    </StyledColumn>
                    <Row align='stretch'>
                        <StyledColumn>
                            <div>
                                <Text align={budgetStore.paymentType === PaymentType.BUDGET ? 'right' : 'left'} textcolor={theme.color.grey}>{name}</Text>
                                <Text align='right' weight='bold' textcolor={theme.color.grey}>{description}</Text>
                            </div>
                            <CollapseInnerList
                                transaction={transaction}
                                collapsed={collapsed}
                                toggleCollapse={toggleCollapse}
                            />
                        </StyledColumn>
                    </Row>
                </Row>
            </StyledTransactionBody>
            {Boolean(transaction.items?.length) && (
                <InnerList collapsed={collapsed} transaction={transaction} />
            )}
        </StyledTransactionContainer>
    )
})

const Content: React.FC<{ transactions: OuterItem[] | PosTransaction[], payment: BudgetTransaction | PosData }> = ({ transactions, payment }) => {
    const { budgetStore } = useStore()
    const shouldCollapseDefault = transactions.length != 1
    return (
        <StyledContent>
            {
                transactions.map(transaction => {
                    const name = budgetStore.paymentType === PaymentType.BUDGET ?
                        budgetStore.getBudgetName(payment as BudgetTransaction, transaction as OuterItem) :
                        (payment as PosData).name.he
                    const description = budgetStore.paymentType === PaymentType.BUDGET ?
                        budgetStore.getBudgetDescription(payment as BudgetTransaction, transaction as OuterItem) :
                        (transaction as PosTransaction).details
                    return <Transaction transaction={transaction} name={name || ''} description={description || ''} shouldCollapseDefault={shouldCollapseDefault} />
                })
            }
        </StyledContent>
    )
}

export const Footer: React.FC<{ total: string }> = ({ total }) => {
    const { t } = useTranslation('budget')
    const { budgetStore } = useStore()
    const totalSum = Number(total) * -1
    return (
        <div style={{ display: 'flex', alignSelf: 'stretch', backgroundColor: theme.color.dialogBackground }}>
            <StyledFooter>
                <Text textcolor={theme.color.white60} style={{ fontSize: 8 * 3 }} >{t`summery.sum`}</Text>
                <Money amount={budgetStore.agorotToShekel(totalSum)} />
            </StyledFooter>
        </div>
    )
}

interface IPaymentTransactions {
    payment: BudgetTransaction | PosData
    transactions: OuterItem[] | PosTransaction[]
    title: string | undefined
    total: string
}

const PaymentTransactions: React.FC<IPaymentTransactions> = ({
    payment, transactions, title, total
}) => {
    return (
        <StyledContainer>
            <GlobalHeader />
            <Header title={title} />
            <Content transactions={transactions} payment={payment} />
            <Footer total={total} />
        </StyledContainer>
    )
}

export default observer(PaymentTransactions)