import React, { FC } from 'react'
import styled from 'styled-components'

import { Table, TableBody, TableCell, TableRow, TableHead, Checkbox, IconButton } from '@material-ui/core'
import { Edit, Delete } from '@material-ui/icons'
import { theme } from 'ui'

import { canEditGroup } from 'utils/roles'

const Content = styled.div`
  padding: 2rem;
`

const CustomTableCell = ({ ...props }) => <TableCell size='small' {...props} />
export const SmallTableCell = styled(CustomTableCell)`
  overflow: hidden;
  /* max-width: 0px; */
  text-overflow: ellipsis;
`

const Actions = styled(SmallTableCell)`
  white-space: nowrap;
  overflow: hidden;
  /* max-width: 0px; */
  text-overflow: ellipsis;
`

export const NoWrap = styled(SmallTableCell)`
  white-space: nowrap;
  overflow: hidden;
  /* max-width: 0px; */
  text-overflow: ellipsis;
`

export const Wrap = styled.div`
  /* max-height: 100%; */
  max-height: max-content;
  /* height:200px; */
  overflow: auto;
  display:flex;
  flex:1;
`

export const TableCheckboxCell = styled(SmallTableCell)`
  font-weight: 400;
  background-color: ${p => p.hover ? theme.color.tableHover : 'transparent'};
  border-top-left-radius: ${p => p.hover ? '10px' : '0px'};
  border-bottom-left-radius: ${p => p.hover ? '10px' : '0px'};
  border-bottom: ${p => p.hover ? '1px solid white' : `1px solid ${theme.color.tableBorder} `};
  body[dir=rtl] & {
    border-top-left-radius: 0px;
   border-bottom-left-radius: 0px;
    border-top-right-radius: ${p => p.hover ? '10px' : '0px'};
    border-bottom-right-radius: ${p => p.hover ? '10px' : '0px'};
    }
`
export const TableFullnameCell = styled(SmallTableCell)`
  font-weight: 400;
  background-color: ${p => p.hover ? theme.color.tableHover : 'transparent'};
  border-top-left-radius: ${p => p.hover ? '10px' : '0px'};
  border-bottom-left-radius: ${p => p.hover ? '10px' : '0px'};
  border-bottom: ${p => p.hover ? '1px solid white' : `1px solid ${theme.color.tableBorder} `};
  body[dir=rtl] & {
    border-top-left-radius: 0px;
   border-bottom-left-radius: 0px;
    border-top-right-radius: ${p => p.hover ? '10px' : '0px'};
    border-bottom-right-radius: ${p => p.hover ? '10px' : '0px'};
    }
`
export const TableCenterCell = styled(NoWrap)`
  font-weight: 400;
  /* height: 48px; */
  background-color: ${p => p.hover ? theme.color.tableHover : 'transparent'};
  border-bottom: ${p => p.hover ? '1px solid white' : `1px solid ${theme.color.tableBorder} `};
`
export const TableButtonsCell = styled(NoWrap)`
  font-weight: 400;
  background-color: ${p => p.hover ? theme.color.tableHover : 'transparent'};
  border-top-right-radius: ${p => p.hover ? '10px' : '0px'};
  border-bottom-right-radius: ${p => p.hover ? '10px' : '0px'};
  border-bottom: ${p => p.hover ? '1px solid white' : `1px solid ${theme.color.tableBorder} `};
  body[dir=rtl] & {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: ${p => p.hover ? '10px' : '0px'};
    border-bottom-left-radius: ${p => p.hover ? '10px' : '0px'};
    }
`

const DataRow = props => {
  const userCanUserEdit = canEditGroup()
  return (
    <TableRow>
      <TableCell padding='checkbox'><Checkbox /></TableCell>
      {Object.values(props).map(prop => <TableCell key={prop ? prop : null }>{prop}</TableCell>)}
      <Actions>
        {userCanUserEdit && <IconButton><Edit /></IconButton>}
        <IconButton><Delete /></IconButton>
      </Actions>
    </TableRow>
  )
}

const Head = ({ data }) => (
  <TableHead>
    <TableRow>
      <TableCell></TableCell>
      <TableCell>Name</TableCell>
      <TableCell>Phone</TableCell>
      <TableCell>Email</TableCell>
      <TableCell>Info</TableCell>
      <TableCell>Role</TableCell>
      <TableCell align="center">Actions</TableCell>
    </TableRow>
  </TableHead>
)

const Body = ({ data }) => (
  <TableBody>
    {data.map(row => <DataRow key={row.id} {...row} />)}
  </TableBody>
)

const MembersTable: FC = ({ data }) => (
  <Content>
    <Table>
      <Head data={data} />
      <Body data={data} />
    </Table>
  </Content>
)

export default MembersTable
