import React,{ FC } from 'react'
import MarkdownIt from 'markdown-it'
import Editor, { Plugins }  from 'react-markdown-editor-lite'
import 'react-markdown-editor-lite/lib/index.css';

Editor.addLocale('he-iw', {
    clearTip: 'אתה בטוח שברצונך למחוק ?',
    btnHeader: 'למעלה',
    btnClear: 'מחיקה',
    btnBold: 'מודגש',
    btnItalic: 'נטוי',
    btnStrikethrough: 'קו חוצה',
    btnTable: 'טבלה',
    btnLink: 'קישור',
    btnFullScreen: 'מסך מלא',
    btnUndo: 'לבטל',
    btnRedo: 'לחזור',
    btnExitFullScreen: 'יציאה ממסך מלא',
    btnModeEditor: 'מצב עריכה',
    btnModePreview: 'תצוגה מקדימה',
    btnModeAll: 'עריכה | תצוגה מקדימה',
});

Editor.useLocale('he-iw');
Editor.unuse(Plugins.ListUnordered);
Editor.unuse(Plugins.ListOrdered)
Editor.unuse(Plugins.Image);
Editor.unuse(Plugins.BlockQuote);
Editor.unuse(Plugins.BlockWrap);
Editor.unuse(Plugins.BlockCodeInline);
Editor.unuse(Plugins.BlockCodeBlock);
Editor.unuse(Plugins.FontUnderline);
  // drop-wrap hidden
const mdParser = new MarkdownIt({ typographer: false }).disable(['image', 'list'])

interface IRichTextEditor {
  value: string
  onChange: any
  placeholder: string
  style?: object
}

const RichTextEditor: FC<IRichTextEditor> = ({
  value,
  style,
  placeholder,
  onChange }) => (
  <Editor
  style={{ height: 300, ...style }}
  value={value}
  config={{
    view: { menu: true, md: true, html: false },
    canView:{ menu: true, md: true, html: true, fullScreen: true, hideMenu: false }
  }}
  renderHTML={(text) => mdParser.render(text)}
  onChange={onChange}
  placeholder={placeholder}
  />
);

export default RichTextEditor;
