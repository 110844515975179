import React, {FC, useEffect, useRef, useState} from 'react'
import TextField from "@material-ui/core/TextField";
import {useTranslation} from 'react-i18next'
import {Switch} from '@material-ui/core'
import styled from 'styled-components'
import {observer} from 'mobx-react'
import Grow from 'ui/Grow'
import useStore from '../../store';
import {Row, Space} from '../../ui'
import {PaymentFormState} from './CommunitySettingsModal'
import {ConfirmButtonSm, DeleteButtonSm, DialogHeaderText, ModalText,  ModalRow, ButtonGroup} from './styles'

const StyledTextField = styled(TextField)`
 width:100%;
`

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 5px;
  white-space: nowrap;
`

interface ISlikaFormState {
  orgId: string | null | undefined
  initialOrgId: string | null | undefined
  slikaModuleId: number | null | undefined
}

interface PaymentSectionProps {
  onPaymentSave: () => void
  isPaymentFormChanged: boolean
  paymentFormState: PaymentFormState
  onSectionToggleVisible: (e: boolean) => void
  onPaymentToggleQRCode: (e: boolean) => void
  onPaymentFormChanged: (e: boolean) => void
  onPaymentSlikaChangeState: (e: any) => void
}

const PaymentSection: FC<PaymentSectionProps> = ({
 isPaymentFormChanged,
 paymentFormState,
 onSectionToggleVisible,
 onPaymentToggleQRCode,
 onPaymentSave,
 onPaymentFormChanged,
 onPaymentSlikaChangeState
}) => {
  const {t} = useTranslation('communitySettings')
  const { modulesStore } = useStore()
  const initialOrgId = useRef(modulesStore?.currentModule?.org_id)

  const [slikaFormState, setSlikaFormState] = useState<ISlikaFormState>({
    orgId: modulesStore?.currentModule?.org_id,
    initialOrgId: initialOrgId.current,
    slikaModuleId: modulesStore?.currentModule?.module_id
  })

  const onPaymentDeleteOrgId = () => {
    setSlikaFormState(prev => ({
      ...prev,
      orgId: '',
      initialOrgId: '',
      slikaModuleId: null
    }))
    modulesStore.deleteModule(slikaFormState.slikaModuleId as number)
  }

  const onPaymentChangeOrgId = (orgId: string) => {
    setSlikaFormState(prev => ({
      ...prev,
      orgId
    }))
  }

  useEffect(() => {
    if (slikaFormState.orgId !== initialOrgId.current) {
      onPaymentFormChanged(true)
    } else {
      onPaymentFormChanged(false)
    }

    onPaymentSlikaChangeState(slikaFormState)
  }, [slikaFormState])

  return (
    <>
      <ModalRow>
        <DialogHeaderText>{t`paymentTab`}</DialogHeaderText>
        <ButtonGroup>
        <DeleteButtonSm
          disabled={!slikaFormState.orgId}
          onClick={onPaymentDeleteOrgId}>
          {t`common:deletion`}
        </DeleteButtonSm>
        <ConfirmButtonSm
          disabled={!isPaymentFormChanged}
          onClick={onPaymentSave}>
          {t`budget:save`}
        </ConfirmButtonSm>
        </ButtonGroup>
      </ModalRow>
      
      <InfoWrapper style={{justifyContent: 'start'}}>
        <ModalText>{t`visible`}:</ModalText>
        <Switch
          color="primary"
          onChange={({target}) => onSectionToggleVisible(target.checked)}
          checked={paymentFormState.isVisible}
        />
      </InfoWrapper>

      <InfoWrapper style={{justifyContent: 'start'}}>
        <ModalText>{t`IdQRCode`}:</ModalText>
        <Switch
          color="primary"
          onChange={({target}) => onPaymentToggleQRCode(target.checked)}
          checked={paymentFormState.isActiveQRCode}
        />
      </InfoWrapper>

      <InfoWrapper style={{justifyContent: 'start'}}>
       
        <StyledTextField
          value={slikaFormState.orgId}
          id="outlined-basic"
          label={t`orgId`} variant="outlined"
          onChange={({target}) => onPaymentChangeOrgId(target.value)}
        />
      </InfoWrapper>
      <Space/>
      <Row align='stretch'>
        <Grow/>
      </Row>
    </>
  )
}

export default observer(PaymentSection)
