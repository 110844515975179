import React, { FC } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { Dialog, Divider, } from '@material-ui/core'
import { Space, theme, Row, Text } from 'ui'
import useStore from 'store'
import { useTranslation } from 'react-i18next'
import MessagesHeader from 'components/Messages/MessagesHeader'
import { formatIfPhoneNumber } from 'utils/converts'


const InfoText = styled(Text)`
  white-space: nowrap;
  font-size:18px;
  font-weight:500;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items:center;
`

const ContainerInner = styled.div <{ color: string}>`
  padding: 10px;
  width:500px;
  background-color:${p => p.color};
`

const DataRow = ({ text, header }: { text: string | undefined, header: string }) => {
  return (
    <>
      <RowContainer>
        <InfoText>{`${header} :`}</InfoText>
        <Text
          style={{ marginRight: 4 }}
          textcolor={theme.color.black}
          weight={'normal'}
          size={'md'}
        >{text || ''}</Text>
      </RowContainer>
      <Space />
      <Divider />
      <Space />
    </>
  )
}

const UserDetailsModal: FC = () => {
  const { memberStore, groups,messages, members } = useStore()
  const { t } = useTranslation('members')
  const groupColor = groups.currentGroup?.group.description?.color || groups.defaultGroupDescription.color
  const currentMember = members.getById(messages.userDetailsId || 0)
  if (!currentMember) {
    null
  }

  return (
    <Dialog
      open={messages.userDetailsModalState}
    >
      <MessagesHeader
        textVarient={'black'}
        backColor={groupColor + '1A'}
        mainText={groups.currentGroup?.group?.name || ''}
        secondText={t`memberInfo`}
        onClose={() => messages.closeUserDetailsModal()}
      />
      <ContainerInner color={groupColor + '1A'}>
        <DataRow
          header={t`firstName`}
          text={currentMember?.given_name || ''}
        />
        <DataRow
          header={t`lastName`}
          text={currentMember?.family_name || ''}
        />
        <DataRow
          header={t`nickName`}
          text={currentMember?.nick_name || ''}
        />
        <DataRow
          header={t`phone`}
          text={currentMember?.phones[0] ? formatIfPhoneNumber(currentMember?.phones[0]) : ''}
        />
        <DataRow
          header={t`phone`}
          text={currentMember?.phones[1] ? formatIfPhoneNumber(currentMember?.phones[1]) : ''}
        />
        <DataRow
          header={t`email`}
          text={currentMember?.emails[0] ? currentMember?.emails[0] : ''}
        />
      </ContainerInner>
    </Dialog >

  )
}
export default observer(UserDetailsModal)
