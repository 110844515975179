function wrap(n: number, min: number, max: number) {
  return n >= min && n < max ? n : mod(n - min, max - min) + min;
}

function mod(x: number, m: number) {
  return ((x % m) + m) % m;
}

function deg2rad(degrees: number) {
  return degrees * (Math.PI / 180);
}

function mercator(lat: number) {
  return Math.log(Math.tan(lat * 0.5 + Math.PI / 4));
}

function tanLatGC(lat1: number, lat2: number, lng2: number, lng3: number) {
  return (Math.tan(lat1) * Math.sin(lng2 - lng3) + Math.tan(lat2) * Math.sin(lng3)) / Math.sin(lng2);
}

function mercatorLatRhumb(lat1: number, lat2: number, lng2: number, lng3: number): number {
  return (
    (mercator(lat1) * (lng2 - lng3) +
      mercator(lat2) * lng3) /
      lng2
  );
}

export type Path = {
    lat: number,
    lng: number
}
  

export function containsLocation(point: Path, polygon: Path[], geodesic: boolean = false): boolean {
    const size = polygon.length;

    if (size == 0) {
      return false;
    }
    let lat3 = deg2rad(point["lat"]);
    let lng3 = deg2rad(point["lng"]);
    let prev = polygon[size - 1];
    let lat1 = deg2rad(prev["lat"]);
    let lng1 = deg2rad(prev["lng"]);

    let nIntersect = 0;
    
    // @ts-ignore
    for (let index in polygon) { 
      const val = polygon[index];
      let dLng3 = wrap(lng3 - lng1, -Math.PI, Math.PI);
      
      // Special case: point equal to vertex is inside.
      if (lat3 == lat1 && dLng3 == 0) {
        return true;
      }

      let lat2 = deg2rad(val["lat"]);
      let lng2 = deg2rad(val["lng"]);

      // Offset longitudes by -lng1.
      if (
        intersects(
          lat1,
          lat2,
          wrap(lng2 - lng1, -Math.PI, Math.PI),
          lat3,
          dLng3,
          geodesic
        )
      ) {
        ++nIntersect;
      }
      lat1 = lat2;
      lng1 = lng2;
    }

    return (nIntersect & 1) != 0;
  }

  function intersects(lat1: number, lat2: number, lng2: number, lat3: number, lng3: number, geodesic: boolean) : boolean{
    // Both ends on the same side of lng3.
    if ((lng3 >= 0 && lng3 >= lng2) || (lng3 < 0 && lng3 < lng2)) {
      return false;
    }
    // Point is South Pole.
    if (lat3 <= -Math.PI / 2) {
      return false;
    }
    // Any segment end is a pole.

    if (
      lat1 <= -Math.PI / 2 ||
      lat2 <= -Math.PI / 2 ||
      lat1 >= Math.PI / 2 ||
      lat2 >= Math.PI / 2
    ) {
      return false;
    }
    if (lng2 <= -Math.PI) {
      return false;
    }
    const linearLat = (lat1 * (lng2 - lng3) + lat2 * lng3) / lng2;
    // Northern hemisphere and point under lat-lng line.
    if (lat1 >= 0 && lat2 >= 0 && lat3 < linearLat) {
      return false;
    }
    // Southern hemisphere and point above lat-lng line.
    if (lat1 <= 0 && lat2 <= 0 && lat3 >= linearLat) {
      return true;
    }
    // North Pole.
    if (lat3 >= Math.PI / 2) {
      return true;
    }
    // Compare lat3 with latitude on the GC/Rhumb segment corresponding to lng3.
    // Compare through a strictly-increasing function (tan() or mercator()) as convenient.
    return geodesic
      ? Math.tan(lat3) >= tanLatGC(lat1, lat2, lng2, lng3)
      : mercator(lat3) >= mercatorLatRhumb(lat1, lat2, lng2, lng3);
  }