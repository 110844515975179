import React, {FC} from 'react'
import {observer} from 'mobx-react'
import {useTranslation} from 'react-i18next'
import {CommunityFormState} from './CommunitySettingsModal'
import {Space} from '../../ui'
import {Switch} from '@material-ui/core'
import {
    ModalRow,
    InfoWrapper,
    ModalText,
    InputWrapper,
    InputContainer,
    OutlinedInput,
    ButtonWrapper,
    InputErrorText,
    ConfirmButtonSm,
    DialogHeaderText,
    ButtonGroup
} from './styles'


interface CommunitySectionProps {
    brandName: string
    currentOrgId: number | undefined
    communityFormState: CommunityFormState
    isCommunityFormChanged: boolean
    onCommunitySave: () => void
    onCommunityToggleEnable: (value: boolean) => void
    onCommunityChangeName: (value: string) => void
    onCommunityChangeDescription: (value: string) => void
    onCommunityChangeSendSMS: (value: boolean) => void
    onCommunityChangeSendEmail: (value: boolean) => void
    onCommunityChangeRemoveSMSTitle: (value: boolean) => void
}

const CommunitySection: FC<CommunitySectionProps> = (props) => {
    const {t} = useTranslation('communitySettings')
    const {
        brandName,
        currentOrgId,
        communityFormState,
        isCommunityFormChanged,
        onCommunitySave,
        onCommunityToggleEnable,
        onCommunityChangeName,
        onCommunityChangeDescription,
        onCommunityChangeSendSMS,
        onCommunityChangeSendEmail,
        onCommunityChangeRemoveSMSTitle,
    } = props

    return (
        <>
        <ModalRow>
              <DialogHeaderText>{t`communitySettings`}</DialogHeaderText>
              <ButtonGroup>
              <ConfirmButtonSm
                    disabled={!isCommunityFormChanged}
                    onClick={onCommunitySave}
                    transparent
                >{t`save`}
                </ConfirmButtonSm>
              </ButtonGroup>
            </ModalRow>
            <ModalRow>
                <InfoWrapper>
                    <ModalText>{t`brand`}:</ModalText>
                    <Space/>
                    <ModalText>{brandName}</ModalText>
                </InfoWrapper>
                <InfoWrapper>
                    <ModalText>{t`numberID`}:</ModalText>
                    <Space/>
                    <ModalText>{currentOrgId}</ModalText>
                </InfoWrapper>
                <InfoWrapper>
                    <ModalText>{t`isEnable`}:</ModalText>
                    <Space/>
                    <Switch
                        color="primary"
                        value={communityFormState.isEnable}
                        onChange={({target}) => {
                            onCommunityToggleEnable(target.checked)
                        }}
                        checked={communityFormState.isEnable}
                    />
                </InfoWrapper>
            </ModalRow>
            <InputWrapper>
                <ModalText>{t`name`}:</ModalText>
                <Space/>
                <InputContainer>
                    <OutlinedInput
                        placeholder={t`name`}
                        value={communityFormState.name}
                        onChange={({target}) => {
                            onCommunityChangeName(target.value)
                        }}
                    />
                    {
                        communityFormState.isNameFieldError &&
                        <InputErrorText>{t`nameValidationError`}</InputErrorText>
                    }
                </InputContainer>
            </InputWrapper>
            <InputWrapper>
                <ModalText>{t`description`}:</ModalText>
                <Space/>
                <OutlinedInput
                    placeholder={t`description`}
                    value={communityFormState.description}
                    onChange={({target}) => {
                        onCommunityChangeDescription(target.value)
                    }}
                />
            </InputWrapper>
            <ModalRow>
                <InfoWrapper>
                    <ModalText>{t`sendSMS`}:</ModalText>
                    <Switch
                        color="primary"
                        onChange={({target}) => {
                            onCommunityChangeSendSMS(target.checked)
                        }}
                        checked={communityFormState.isSendSMS}
                    />
                </InfoWrapper>
                <InfoWrapper>
                    <ModalText>{t`sendEmail`}:</ModalText>
                    <Switch
                        color="primary"
                        onChange={({target}) => {
                            onCommunityChangeSendEmail(target.checked)
                        }}
                        checked={communityFormState.isSendEmail}
                    />
                </InfoWrapper>
                <InfoWrapper>
                    <ModalText>{t`removeSMSTitle`}:</ModalText>
                    <Switch
                        color="primary"
                        onChange={({target}) => {
                            onCommunityChangeRemoveSMSTitle(target.checked)
                        }}
                        checked={communityFormState.enableSmsUnsubscribe}
                    />
                </InfoWrapper>
            </ModalRow>
            <Space/>
            
        </>
    )
}

export default observer(CommunitySection)