import React, { FC, useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import {
    Dialog,
    IconButton,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Switch,
    Checkbox,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Close, ExpandMore } from '@material-ui/icons'
import { theme, Text, Row, Space, Icon } from 'ui'
import { observer } from 'mobx-react'
import useStore, { snackBar } from 'store'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import icons from 'components/Groups/groupCategories.json'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import type { ModuleType } from 'utils/types'
import { ModuleEnum, ProviderEnum} from 'utils/types'
import { validURL } from 'utils/validations'
import RichTextEditor from "../../../components/Messages/RichTextEditor";
import { isTotzeret } from 'service/whiteLable'
import kehilanetData from '../../../data/kehilanet.json';
import { userIsSuperAdmin } from 'utils/roles'


const StyledCheckBox = styled(Checkbox)`
  &.MuiCheckbox-root {
    color: ${theme.color.communityBlue};
  }
`
const CheckBoxContainer = styled.div`
    display:flex;
    flex-direction:row;
    width:250px;
    align-items:center;
`
const ContainerDialog = styled.div`
    align-items:center;
    justify-content:flex-start;
    flex-direction:column;
    display:flex;
    background-color:${theme.color.whiteBackground};
    padding:20px 20px;
    overflow:auto;
    max-height:900px;
    width:600px;
`
const SelectContent = styled.div`
    align-items:center;
    flex-direction:row;
    display:flex;

`
const ConfirmButton = styled(Button)`
  &.MuiButtonBase-root {
    font-size: ${theme.fontSize.large};
    font-weight: 500;
    min-width: 140px;
    height: 50px;
    margin: 0.5rem;
    background: ${theme.color.confirmButton};
    &:hover {
      background: ${theme.color.darkBlue};
    }
    color: ${theme.color.white};
    &.Mui-disabled {
      opacity: 0.5;
    }
  }
`
const DeleteButton = styled(Button)`
  &.MuiButtonBase-root {
    font-size: ${theme.fontSize.large};
    font-weight: 500;
    min-width: 140px;
    height: 50px;
    margin: 0.5rem;
    background: ${theme.color.red};
    &:hover {
      background: ${theme.color.darkBlue};
    }
    color: ${theme.color.white};
    &.Mui-disabled {
      opacity: 0.5;
    }
  }

`
const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  cursor:pointer;
`
const StyledTextField = styled(TextField)`
 width:100%;
`
const IconBg = styled.div<{ selected: boolean }>`
  background: ${p => isTotzeret ? 'transparent' : p.color};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0.33rem;
  border-radius: 8px;
  margin-right:35px;
  border-color: ${p => p.selected ? theme.color.select : theme.color.white};
  border-width: 4px;
  border-style: solid;
  body[dir=rtl] & {
    margin-right: unset;
    margin-left: 20px;
    }
`

enum ValidFields {
    information = 'information',
    shareEmail = 'shareEmail'
}

enum ValidLengthFields {
    information = 2000,
    shareEmail = 1000
}

const IconSelect: FC<{ icons: any, onChange: any, givenSelectedIcon: any }> = ({ icons, onChange, givenSelectedIcon }) => {
    const [selectedIcon, setSelectedIcon] = useState<string | undefined>(givenSelectedIcon)
    const clickHandler = (iconOptions: any) => {
        setSelectedIcon(iconOptions.icon)
        onChange(iconOptions)
    }
    return (
        <IconsContainer>
            {
                icons.map((iconOptions: any) => (
                    <IconBg selected={selectedIcon == iconOptions.icon} color={iconOptions.iconColor} onClick={() => clickHandler(iconOptions)}>
                        <Icon name={iconOptions.icon} />
                    </IconBg>
                ))
            }
        </IconsContainer >
    )
}

const StyledUserConditional = styled.div`
    display: flex; 
    align-self: flex-start; 
    align-items: center;
    margin: 10px;
`

const UserConditional = ({ checked, handleChange, text }: { checked: boolean, handleChange: () => void, text: string }) => {
    return (
      <StyledUserConditional>
          <Text>{text}</Text>
          <Switch checked={checked} onChange={handleChange} name={text} color="primary" />
      </StyledUserConditional>
    )
}
const CreateModuleModal: FC = () => {
    const { t } = useTranslation('budget')
    const { modulesStore, orgs, uiStore } = useStore()
    const open = modulesStore.moduleModalisOpen

    const types = [
        { value: ModuleEnum.THIRD, text: t`third` }, 
        { value: ModuleEnum.DELIVERY, text: t`ordering` },
        { value: ModuleEnum.KEHILANET, text: t`kehilanet` },
        { value: ModuleEnum.EXPRESS_106, text: '106 אקספרס' }
    ]

    const ProviderTypes = [{ value: ProviderEnum.MAOF, text: t`maof` }, { value: '', text: t`none` }, { value: ProviderEnum.MEKOME, text: t`mekome` }]
    const communityQrModule = modulesStore.communityModules.find((module) => module.type === ModuleEnum.ID_QR_CODE);

    const closeModal = () => {
        modulesStore.closeModuleModal()
        modulesStore.clearCurrent()
        clearAll()
    }

    const [selectedIcon, setSelectedIcon] = useState<string | undefined | null>(undefined)

    const onChangeIcon = (icon) => {
        setSelectedIcon(icon.icon)
        setButtonColor(icon.iconColor)
    }

    const [type, setType] = useState<null | ModuleEnum>(null)
    const [buttonColor, setButtonColor] = useState<string | null>('')
    const [data, setData] = useState<string | null>('')
    const [title, setTitle] = useState<string | null>(null)
    const [orgId, setOrgId] = useState<string | null>(null)
    const [enable, setEnable] = useState<boolean>(false)
    const [provider, setProvider] = useState(ProviderTypes[1].value)
    const [directorPermission, setDirectorPermission] = useState<boolean>(false)
    const [description, setDescription] = useState<string | null>(null)
    const [link, setLink] = useState<string | null>(null)
    const [iconText, setIconText] = useState<string | null>(null)
    const [editModuleText, setEditModuleText] = useState<string | null>('')
    const [share, setShare] = useState(false);
    const [shareEmail, setShareEmail] = useState<string | null>(null);
    const [information, setInformation] = useState<string>('')
    const isValidFields = useRef<Array<string>>([]);

    const handleChangeValidFields = ({ str, field }: { str: string, field: ValidFields}) => {
        const isField = isValidFields.current.some((item) => item === field)

        if (str.length > ValidLengthFields[field] && !isField) {
            isValidFields.current = [...isValidFields.current, field]
        }

        if ( isValidFields.current.length > 0  && str.length <= ValidLengthFields[field]) {
            const d = isValidFields.current.filter((item ) => item != field )
            isValidFields.current = d
        }
    }

    const handleChangeShareEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShareEmail(event.target.value);
        handleChangeValidFields({ str: event.target.value, field: ValidFields.shareEmail });
    }

    useEffect(() => {
        clearAll()
        if (modulesStore?.currentModule) {
            setTitle(modulesStore?.currentModule?.title)
            setDirectorPermission(modulesStore?.currentModule?.directorPermission)
            setDescription(modulesStore?.currentModule?.description)
            setLink(modulesStore.currentModule?.external_url)
            setIconText(modulesStore.currentModule?.icon_text)
            setButtonColor(modulesStore.currentModule?.icon_color)
            setType(modulesStore?.currentModule?.type)
            setSelectedIcon(modulesStore?.currentModule?.icon_name)
            setOrgId(modulesStore?.currentModule?.org_id)
            setProvider(modulesStore?.currentModule?.provider)
            setData(modulesStore?.currentModule?.data)
            setEnable(modulesStore?.currentModule?.enable)
            setShareEmail(modulesStore?.currentModule.shareEmail)
            setShare(!!modulesStore?.currentModule.share)

            if (modulesStore?.currentModule.information) {
                const parseInformation = JSON.parse(modulesStore?.currentModule?.information);

                setInformation(parseInformation[0])
            }

            const newModule = modulesStore?.currentModule?.module_id
            if (modulesStore?.currentModule.type === ModuleEnum.DELIVERY) {
                setEditModuleText(`${newModule ? t`editModule` : ''} ${t`ordering`}`)
            } else if (modulesStore?.currentModule.type === ModuleEnum.THIRD) {
                setEditModuleText(`${newModule ? t`editModule` : ''} ${t`third`}`)
            } else if (modulesStore?.currentModule.type === ModuleEnum.PORTAL) {
                setEditModuleText(`${newModule ? t`editModule` : t`app:add2`} ${t`portal`}`)
            } else if (modulesStore?.currentModule.type === ModuleEnum.SUPPORT) {
                setEditModuleText(`${newModule ? t`editModule` : t`app:add2`} ${t`serviceCall:services`}`)
            } else if (modulesStore?.currentModule.type === ModuleEnum.SLIKA) {
                setEditModuleText(`${newModule ? t`editModule` : t`app:add2`} ${t`slika`}`)
            }
        }

    }, [modulesStore.currentModule])

    useEffect(() => {
        if (type == ModuleEnum.EXPRESS_106 && !modulesStore?.currentModule) {
            setTitle('106 מועצה איזורית')
            setDescription('מוקד 106')
            setSelectedIcon('tzachy')
            setButtonColor('#d91c14')
            setData(`המועצה האזורית כאן בשבילך!
כדי לפתוח קריאה - יש ללחוץ על הכפתור עם הפלוס ממש כאן ולענות על כמה שאלות קצרות שייתארו את פנייתך.
על מנת שהמועצה תוכל לקבל את פנייתך
הינך מסכים/ה שנעביר אליה את הפרטים הבאים עם הלחיצה על הכפתור: שם פרטי ומשפחה, מספר טלפון נייד, ושם היישוב.
למקרים דחופים נא להתקשר ל8669*`)
            setInformation('106 מועצה איזורית')
        }
    }, [type])

    const clearAll = () => {
        setTitle(null)
        setDescription(null)
        setLink(null)
        setIconText(null)
        setButtonColor('')
        setData('')
        setType(null)
        setSelectedIcon(undefined)
        setOrgId(null)
        setProvider(null)
        setShareEmail(null)
        setInformation('')
    }
    const [disabled, setDisabled] = useState(false)

    const resolveProvider = () => {
        if (type === ModuleEnum.ID_QR_CODE)  {
            return ProviderEnum.MEKOME_QR
        } 

        if (type === ModuleEnum.KEHILANET) {
            return ProviderEnum.KEHILANET
        }

        if (type == ModuleEnum.EXPRESS_106) {
            return ProviderEnum.EXPRESS_106
        }
        
        return provider
    }

    const onSave = async () => {
        setDisabled(true)

        if (isValidFields.current.length) {
            const currentField = isValidFields.current[0]
            const length = ValidLengthFields[currentField as ValidFields]

            snackBar.showError(`שדה ${t(currentField)} לא יכול להיות ארוך מ-${length} תווים`)
            setDisabled(false)
            return
        }
        if (type != null) {
            const strInformation = JSON.stringify({ 0: information })
            const module: ModuleType = {
                community_ref: type === ModuleEnum.THIRD ? null : modulesStore?.currentModule?.community_ref || orgs.currentOrgId,
                module_id: modulesStore?.currentModule?.module_id || null,
                type: type, //(enum: portal, budget, third, reservations, voting) + nut null
                title: title, //string && nullable
                directorPermission: directorPermission, 
                description: description, //string && nullable
                external_url: link, //string && nullable
                icon_name: selectedIcon, //string && nullable
                icon_text: iconText,
                icon_color: buttonColor,
                org_id: orgId,
                enable: enable,
                provider: resolveProvider(),
                information: strInformation,
                data: data,
                share,
                shareEmail
            }

            if (modulesStore?.currentModule?.module_id) {
                await modulesStore.updateModule(module)
            } else {
                await modulesStore.addNewModule(module)
            }
            
            await modulesStore.getCommunityModulesAndFeatures(orgs.currentOrgId)
            setDisabled(false)
            closeModal()
        }
    }

    const onDelete = () => {
        let deleteText = ''
        switch (type) {
            case ModuleEnum.THIRD:
                deleteText = t`third`
                break;
            case ModuleEnum.DELIVERY:
                deleteText = t`ordering`
                break;
            case ModuleEnum.SLIKA:
                deleteText = t`slika`
                break;
            case ModuleEnum.SUPPORT:
                deleteText = t`serviceCall:services`
                break;
            case ModuleEnum.PORTAL:
                deleteText = t`portal`
                break;
            case ModuleEnum.ID_QR_CODE:
                deleteText = ModuleEnum.ID_QR_CODE
                break;
            case ModuleEnum.EXPRESS_106:
                deleteText = '106 מועצה איזורית גליל עליון'
                break;    
        }

        uiStore.openWarningModal({
            text: `${t`remove:moduleRemove`} ${deleteText}`,
            secondText: (modulesStore?.currentModule?.title && modulesStore?.currentModule?.title != '') ? `${t`remove:moduleName`} ${modulesStore?.currentModule?.title}` : '',
            okAction: () => {
                if (modulesStore?.currentModule) {
                    modulesStore.deleteModule(modulesStore?.currentModule?.module_id)
                } else if (communityQrModule) {
                    modulesStore.deleteModule(communityQrModule.module_id)
                }
                closeModal()
            }
        })

    }
    const canSave = () => {
        if (type === ModuleEnum.ID_QR_CODE) {
            return !!communityQrModule;
        }
        if (type === ModuleEnum.DELIVERY && title && link && validURL(link) && selectedIcon) {
            return false
        }
        if (type === ModuleEnum.EXPRESS_106 && link && validURL(link) && title && description && selectedIcon && buttonColor != '') {
            return false
        }
        if (type === ModuleEnum.THIRD && title && description  && iconText && buttonColor != '') {
            return false
        }
        if (type === ModuleEnum.PORTAL && link && validURL(link)) {
            return false
        }
        if (type === ModuleEnum.SUPPORT && link && validURL(link)) {
            return false
        }
        if (type === ModuleEnum.SLIKA && orgId) {
            return false
        }
        if (type === ModuleEnum.EMERGENCY_MANAGER && orgId) {
           return false
        }
        if (type === ModuleEnum.KEHILANET && orgId && link && validURL(link) ) {
           return false
        }

        return true
    }

    const canCreateService = !modulesStore.communityModules?.find(el => el.type === ModuleEnum.SUPPORT)//true if theres no support
    const canCreateEmergencyManager = !modulesStore.communityModules?.find(el => el.type === ModuleEnum.EMERGENCY_MANAGER)

    if (canCreateService) {
        types.push({ value: ModuleEnum.SUPPORT, text: t`serviceCall:services` })
    }

    if (canCreateEmergencyManager) {
        types.push({ value: ModuleEnum.EMERGENCY_MANAGER, text: t`emergency_manager` })
    }
     
    const handleEditorChange = ({ text }: { text: string }) => {
        setInformation(text)
        handleChangeValidFields({ str: text, field: ValidFields.information });
    }

    const handleDirectorPermission = (e) => {
        setDirectorPermission(e.target.checked)
    }

    return (
        <Dialog
            style={{ zIndex: 1200 }}
            open={open}
            onBackdropClick={closeModal}
        >
            <Row style={{ backgroundColor: theme.color.azureBlue + 33, padding: 10 }}>
                <Text
                    textcolor={theme.color.black + 99}
                    weight={'bold'}
                    size={'xl'}
                >{modulesStore?.currentModule ? editModuleText : t`addNewModule`}</Text>
                <IconButton onClick={closeModal} >
                    <Close />
                </IconButton>
            </Row>
            <ContainerDialog>
              {userIsSuperAdmin() && <StyledUserConditional>
                <Text>{t`isEnable`}</Text>
                <Switch 
                  checked={enable} 
                  onChange={({ target }) => { setEnable(target.checked) }}  
                  color="primary" 
                />
              </StyledUserConditional>}
                {!modulesStore?.currentModule && <><Autocomplete
                    value={types.filter(i => i.value === type)[1]}
                    onChange={(event, value) => { 
                        setType(value?.value || null)
                        setDirectorPermission(false)

                        if(value?.value == ModuleEnum.EMERGENCY_MANAGER) {
                            setOrgId(orgs.currentOrgId)

                            const emergencyModule = modulesStore
                              .communityModules
                              .find(el => el.type === ModuleEnum.EMERGENCY_MANAGER)  
                      
                            if (emergencyModule !== undefined) {
                                setDirectorPermission(emergencyModule.directorPermission)
                            }  
                        } 


                        if(value?.value == ModuleEnum.EXPRESS_106) {
                            setOrgId(orgs.currentOrgId)

                            const express106Module = modulesStore
                              .communityModules
                              .find(el => el.type === ModuleEnum.EXPRESS_106)  
                      
                            if (express106Module !== undefined) {
                                modulesStore.setCurrentModule(express106Module.module_id)
                            }  
                        } 
                    }}
                    id="combo-box-demo"
                    options={types}
                    placeholder={t`type`}
                    getOptionLabel={(option) => option.text}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} value={type} label={t`type`} variant="outlined" />}
                /><Space height={1} /></>}

                {type && type !== ModuleEnum.ID_QR_CODE && <>
                    {(type === ModuleEnum.PORTAL || type === ModuleEnum.SUPPORT) && <><Autocomplete
                        value={ProviderTypes.filter(i => i.value === provider)[0]}
                        onChange={(event, value) => { setProvider(value?.value || null) }}
                        id="combo-box-provider"
                        options={ProviderTypes}
                        placeholder={t`provider`}
                        getOptionLabel={(option) => option.text}
                        style={{ width: '100%' }}
                        renderInput={(params) => <TextField {...params} value={type} label={t`provider`} variant="outlined" />}
                    /> <Space height={1} /></>}
                   
                    {( type === ModuleEnum.KEHILANET || type === ModuleEnum.SLIKA || type === ModuleEnum.PORTAL || type === ModuleEnum.SUPPORT) && provider != ProviderEnum.MEKOME && <StyledTextField
                        value={orgId}
                        id="outlined-basic"
                        label={t`orgId`} variant="outlined"
                        onChange={(event) => setOrgId(event.target.value)}
                    />}
                    {type != ModuleEnum.PORTAL && type != ModuleEnum.EMERGENCY_MANAGER && type != ModuleEnum.SUPPORT && type != ModuleEnum.SLIKA && (
                      <><Space height={1} />
                      <StyledTextField
                        value={title}
                        id="outlined-basic"
                        label={type == ModuleEnum.EXPRESS_106 ? 'כותרת על לחצן הצד ימין' : t`title`} variant="outlined"
                        onChange={(event) => setTitle(event.target.value)}
                      /></>
                      )
                    }

                    {(type == ModuleEnum.EXPRESS_106)  &&
                      <>
                        <Space height={1} />
                        <StyledTextField
                          value={description}
                          id="outlined-basic"
                          label={'כותרת משנה'}
                          variant="outlined"
                          onChange={(event) => setDescription(event.target.value)}
                        />
                      </>
                    }

                    {(type === ModuleEnum.THIRD || type === ModuleEnum.KEHILANET)  &&
                        <>
                            <Space width={1} />
                            {description ? <Text
                                style={{ alignSelf: 'flex-start' }}
                                textcolor={theme.color.black + 99}
                                weight={'normal'}
                                size={'xs'}
                            >{t`description`}</Text> : <div style={{ height: 18 }} />}
                            <TextareaAutosize
                                style={{ width: '100%', minHeight: '80px' }}
                                rowsMin={4}
                                defaultValue={description}
                                placeholder={t`description`}
                                variant="outlined"
                                onChange={(event) => setDescription(event.target.value)}

                            />
                        </>
                    }

                    {type === ModuleEnum.KEHILANET &&
                      <>
                        <Space height={1} />
                        <Autocomplete
                          onChange={(event, value) => {
                            const baseUrl = 'https://www.kehilanet.co.il/mekome/login.asp'
                            setLink(`${baseUrl}?interfaceId=${value?.value}`)
                          }}
                          id="combo-box-provider"
                          options={kehilanetData.interfaces}
                          placeholder={t`kehilanet_module`}
                          getOptionLabel={(option) => option.text}
                          style={{width: '100%'}}
                          renderInput={(params) => <TextField {...params} label={t`kehilanet_module`} variant="outlined"/>}
                        />
                      </>
                    }
                    
                    {type != ModuleEnum.KEHILANET && type != ModuleEnum.SLIKA && type != ModuleEnum.EMERGENCY_MANAGER && <><Space height={1} /><StyledTextField
                        value={link}
                        style={{ textAlign: link ? 'start' : 'end' }}
                        id="outlined-basic"
                        label={type == ModuleEnum.EXPRESS_106 ? 'תבנית לינק' : t`url`}
                        variant="outlined"
                        onChange={(event) => setLink(event.target.value)}
                    /></>}

                    {(type == ModuleEnum.EXPRESS_106)  &&
                      <>
                        <Space width={1} />
                        {data ? <Text
                            style={{ alignSelf: 'flex-start' }}
                            textcolor={theme.color.black + 99}
                            weight={'normal'}
                            size={'xs'}
                        >{'טקסט פתיחה במרכז המסך'}</Text> : <div style={{ height: 18 }} />}
                        <TextareaAutosize
                            style={{ width: '100%', minHeight: '80px' }}
                            rowsMin={4}
                            defaultValue={data}
                            placeholder={'טקסט פתיחה במרכז המסך'}
                            variant="outlined"
                            onChange={(event) => setData(event.target.value)}
                        />
                        <Space height={1} />
                        <StyledTextField
                          value={information}
                          id="outlined-basic"
                          label={'כותרת משנה עליונה מעל שם הקבוצה'}
                          variant="outlined"
                          onChange={(event) => setInformation(event.target.value)}
                        />
                      </>
                    }
                   
                    {/* {type === 'third' && <Row style={{ alignItems: 'flex-start', }}> */}
                    {(type === ModuleEnum.THIRD) &&  <><Space height={1} /><StyledTextField
                        value={iconText}
                        id="outlined-basic"
                        label={t`iconText`}
                        variant="outlined"
                        onChange={(event) => setIconText(event.target.value)}
                    /></>}
                   
                    {type != ModuleEnum.KEHILANET && type != ModuleEnum.PORTAL && type != ModuleEnum.SUPPORT && type != ModuleEnum.SLIKA && type != ModuleEnum.EMERGENCY_MANAGER &&  <><Space width={1} /><StyledTextField
                        style={{ direction: 'ltr', textAlign: buttonColor === '' ? 'end' : 'start' }}
                        value={buttonColor}
                        id="outlined-basic"
                        label={type === 'third' ? t`buttonColor` : t`iconColor`}
                        variant="outlined"
                        onChange={(event) => setButtonColor(event.target.value)}
                    /></>}
                    {type != ModuleEnum.EXPRESS_106 && type != ModuleEnum.KEHILANET && type != ModuleEnum.PORTAL && type != ModuleEnum.SUPPORT && type != ModuleEnum.SLIKA && type != ModuleEnum.EMERGENCY_MANAGER  && (
                      <>
                        <Space width={1} />
                        <StyledTextField
                          value={shareEmail || ''}
                          style={{ direction: 'ltr', textAlign: !link ? 'start' : 'end' }}
                          id="outlined-basic"
                          label={t`shareEmail`}
                          variant="outlined"
                          name="shareEmail"
                          onChange={handleChangeShareEmail}
                        />
                        <Space width={1} />
                        <RichTextEditor
                            style={{width: '100%', minHeight: 200}}
                            value={information}
                            placeholder={t`information`}
                            onChange={handleEditorChange}
                        />
                        <Space width={1} />
                        <UserConditional checked={share} text={t`share`} handleChange={() => setShare(prevState => !prevState)} />
                      </>
                    )}

                    {(type == ModuleEnum.EXPRESS_106 || type === ModuleEnum.DELIVERY || type == ModuleEnum.KEHILANET) &&  (<><Space width={1} /><Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <SelectContent>
                                <Space width={1} />
                                {selectedIcon && <IconBg
                                    selected={false}
                                    color={buttonColor}
                                >
                                    <Icon name={selectedIcon} />
                                </IconBg>}
                                <Text>{selectedIcon ? t`switchIcon` : t`icon`}</Text>
                            </SelectContent>
                        </AccordionSummary>
                        <AccordionDetails>
                            <IconSelect
                                icons={icons}
                                onChange={onChangeIcon}
                                givenSelectedIcon={selectedIcon} />
                        </AccordionDetails>
                    </Accordion></>
                    )}

                    {type === ModuleEnum.EMERGENCY_MANAGER && (
                      <>
                        <Space height={1} />
                        <CheckBoxContainer>
                          <StyledCheckBox
                            checked={directorPermission}
                            onChange={handleDirectorPermission}
                          />
                          <Text textcolor={theme.color.black} weight={'normal'} size={'sm'}>
                            {t`allow_director_to_manage`}
                          </Text>
                        </CheckBoxContainer>
                    </>
                    )}
                </>}
            </ContainerDialog>
            <Row style={{ backgroundColor: theme.color.whiteBackground }}>
                {!!modulesStore?.currentModule || (type === ModuleEnum.ID_QR_CODE && !!communityQrModule) ? <DeleteButton onClick={onDelete}>{t`common:deletion`}</DeleteButton> : <div />}

                <ConfirmButton disabled={canSave() || disabled} onClick={onSave}>{t`save`}</ConfirmButton>
            </Row>

        </Dialog>
    )
}

export default observer(CreateModuleModal)


